// import faq from '../assets/faq.svg'
// import complianceQa from '../assets/flag.svg'
// import virtualCoach from '../assets/hint.svg'
import appConfigs from "./../../config";

const invert = function (obj) {
  var new_obj = {};

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      new_obj[obj[prop]] = prop;
    }
  }

  return new_obj;
};

const Constants = {
  HINT: "hint",
  FLAG: "flag",
  WARNING: "warning",

  INTEND: "intend",

  CATEGORIES: {
    say: "phrase",
    // feel: "emotion",
    intend: "topic",
  },
  FLAG_COUNTS: {},
  TAB_TYPE: {
    RULE: "rules",
    FAQ: "faq",
    FAQ_PHRASES: "faq-phrase",
    RUBRIC_BUILDER: "rubric-builder",
    RESOURCE: "customer-resource",
    USER_MANAGEMENT: "user_management",
    REPORT_SHARE: "report_share",
    RESOURCE_CENTER: "resource_center",
    INTENT: "intent",
    INTENT_PLAYGROUND: "intent-playground",
    INTENT_PHRASES: "intent-phrase",
    INTEGRATION: "cloud-app",
    METRIC_TAGS: "metric-tag",
    CONVERSATION_TAGS: "scripting",
    WORKSPACES: "workspaces",
    CATEGORIZATION: "categorization",
    EXTERNAL_DATA: "external-data",
    QA_CASE_ASSIGNMENT: "qa-case-assignment"
  },
  FILTER_NAMES: {
    CONVERSATION_TAGS: "conversationTags",
    METRIC_TAGS: "metricTags",
    ORGANIZATIONS: "organizations",
    CATEGORIES_LIST: "categoriesList",
    CASE_STATUS_LIST: "caseStatusList",
    CUSTOM_FILTERS: "customFilters",
    QA_STATUS: "qaStatusList",
    CALIBRATION_STATUS: "calibrationStatusList",
    // MODERATOR_LIST: "moderatorList",
    // QA_AUDITOR_LIST: "qaAuditorList",
    AGENTS_LIST: "agentsList",
    GLOBAL_USER_ROLE_LIST: "globalUserRoleList", //used when multiple roles being used
    CALL_DURATION: "callDurationList",
    AGENT_TYPE: "agentTypeList"
  },
  ROLE_CONSTANTS: {
    GLOBAL: "global",
    LOCAL: "local",
    JOIN_TYPE: {
      AND: "AND",
      OR: "OR",
    },
  },

  EMPTY_STATE_VIEWS: {
    BASIC: "basic",
    RUBRIC_VIEW: "rubric-view",
    CUSTOM_DASHBOARD_VIEW: "custom-dashboard-view",
    CALIBRATION_REPORT_VIEW: "calibration-report-view",
    BASIC_TEXT: "basic-text-view",
    EMPTY_SEARCH_VIEW: "empty-search-view"
  },
  UNIVERSAL: "universal",
};

Constants.EXPECTATION_CATEGORIES = Object.keys(Constants.CATEGORIES);
Constants.CONDITION_CATEGORIES = Constants.EXPECTATION_CATEGORIES;
Constants.INVERT_CATEGORIES = invert(Constants.CATEGORIES);
Constants.FLAG_COUNTS["1st"] = 1;
Constants.FLAG_COUNTS["2nd"] = 2;
Constants.FLAG_COUNTS["3rd"] = 3;

Constants.FLAG_KEYS = Object.keys(Constants.FLAG_COUNTS);
Constants.FLAG_VALUES = Object.values(Constants.FLAG_COUNTS);

Constants.MESSAGE_GENERATOR = {
  CUSTOMER: {
    say: "says",
    // feel: "feels",
    intend: "wants to",
  },
  AGENT: {
    say: "say",
    // feel: "imply",
    intend: "try to",
  },
};

Constants.GOOGLE_DRIVE = "google_drive";
Constants.SALESFORCE = "salesforce_service_cloud";
Constants.ZENDESK = "zendesk";

// Constants.CLIENT_ID = "356318182935-pvdroui3h2847bcviee31apmg5r220f7.apps.googleusercontent.com";
Constants.CLIENT_ID =
  "356318182935-bdqqnfj2agt7cfsp60mphr08cdglkdhg.apps.googleusercontent.com";
Constants.REDIRECT_URI = "/oauth2/callback";

Constants.INTEGRATIONS = {
  [Constants.GOOGLE_DRIVE]: {
    // CLIENT_ID: "356318182935-pvdroui3h2847bcviee31apmg5r220f7.apps.googleusercontent.com"
    CLIENT_ID:
      "356318182935-bdqqnfj2agt7cfsp60mphr08cdglkdhg.apps.googleusercontent.com",
  },
  [Constants.SALESFORCE]: {
    CLIENT_ID: appConfigs.SALESFORCE.CLIENT_ID,
  },
  [Constants.ZENDESK]: {
    CLIENT_ID: appConfigs.ZENDESK.CLIENT_ID,
  },
};

Constants.SLACK_REDIRECT_URL = "/slack/oauth/";

export default Constants;
