import { getSchemaName } from "../api/api";
import { gql } from "@apollo/client";
import {
  ACCOUNT_USER_FIELDS,
  COMMON_METRIC_FIELDS,
  ASR_LOG_FIELDS,
  AUDIO_LOG_FIELDS,
  QA_SCORES,
  INSTA_REVIEW,
  MOMENT_COUNTS,
} from "./fragments";
import { graphqlClient } from "./utilities";
import appConfigs from "../config";
import { qaStatusMap } from "../conversation-list/utilities";
import store from "../store";

const identityFn = (args) => {
  return args;
};

async function fetchGraphQL(query, customFilterValues, controller) {
  const result = graphqlClient.query({
    query: query,
    variables: customFilterValues,
    context: {
      fetchOptions: {
        signal: controller?.signal
      }
    }
  });
  return result;
}

var getConversationsQuery = (
  order_args,
  whereClause,
  queryArguments,
  limit,
  offset,
  schema
) =>
  gql`
    ${ACCOUNT_USER_FIELDS(schema)}
    ${COMMON_METRIC_FIELDS(schema)}
    ${ASR_LOG_FIELDS(schema)}
    ${AUDIO_LOG_FIELDS(schema)}
    ${MOMENT_COUNTS(schema)}
    ${QA_SCORES(schema)}
    ${INSTA_REVIEW(schema)}
    query MyQuery${queryArguments} {
        results: ${schema}_banking_bankingconversation(
        order_by: ${order_args}, 
        limit: ${limit},
        offset: ${offset},
        ${whereClause}
        ) {
        id
        created
        asr_log: level_asr_asrlog {
            ...ASR_LOG_FIELDS
            common_metrics: metric {
              ...COMMON_METRIC_FIELDS
            }
            audio: level_asr_audiolog {
              ...AUDIO_LOG_FIELDS
            }
            custom_qa_score: cfanswers {
              ...QA_SCORES
            }
            insta_review: quality_assessment_instareviewtags {
              ...INSTA_REVIEW
            }
            case_status: level_asr_asrlogtocasemappings {
              status
            }
            ...MOMENT_COUNTS
            user: accounts_user {
              ...ACCOUNT_USER_FIELDS
          }
        }
        }

        count: ${schema}_banking_bankingconversation_aggregate(${whereClause}) {
          aggregate {
            totalCount: count
          }
        }
    }
`;

var getTotalConversationCountQuery = (whereClause, queryArguments, schema) =>
  gql`
    query ConversationCountQuery${queryArguments} {
      results: ${schema}_banking_bankingconversation_aggregate(
      ${whereClause}
      ) {
        aggregate {
          totalCount: count
        }
      }
  }
`;

var getTotalDurationQuery = (whereClause, queryArguments, schema) =>
  gql`
    query TotalDurationQuery${queryArguments} {
      results: ${schema}_handling_time_view_aggregate(
      ${whereClause}
      ) {
        aggregate {
          sum {
            handling_time
          }
      }
  }
}
`;

const order_map = {
  "-id": "{id: desc}",
  id: "{id: asc}",
  "-date": "{created: desc}",
  date: "{created: asc}",
  user: "{level_asr_asrlog: {accounts_user : { first_name : asc}}}",
  "-user": "{level_asr_asrlog: {accounts_user : { first_name : desc}}}",
  conversation_tag_count:
    "{level_asr_asrlog: {conversation_tag_count_view: { conversation_tag_count : asc_nulls_first }}}",
  "-conversation_tag_count":
    "{level_asr_asrlog: {conversation_tag_count_view: { conversation_tag_count : desc_nulls_last }}}",
  negative_moments:
    "{level_asr_asrlog: {negative_moment_count_view: { negative_moments : asc_nulls_first }}}",
  "-negative_moments":
    "{level_asr_asrlog: {negative_moment_count_view: { negative_moments : desc_nulls_last }}}",
  callTime: "{level_asr_asrlog: {metric : { handling_time: asc_nulls_last }}}",
  "-callTime":
    "{level_asr_asrlog: {metric : { handling_time: desc_nulls_last }}}",
  qa_score:
    "{level_asr_asrlog: {cfanswers_aggregate: {max: {raw_score: asc_nulls_last}}}}",
  "-qa_score":
    "{level_asr_asrlog: {cfanswers_aggregate: {max: {raw_score: desc_nulls_last}}}}",
};

function getPrevAndNext({ base_url, version, filters, data, limit, offset }) {
  const totalCount = data.count.aggregate.totalCount;
  const page = filters["page"];
  const url = `${base_url}/banking/${version ? version + "/" : ""}list`;
  const next = page + 1;
  const prev = page - 1;
  var filterParamsNext = { ...filters, page: next };
  var filterParamsPrev = { ...filters, page: prev };
  
  if(Number(totalCount) - Number(offset) > Number(limit)) {
    return {
      next: url + "&" + new URLSearchParams(filterParamsNext).toString(),
    prev:
      prev == 0
        ? null
        : url + "&" + new URLSearchParams(filterParamsPrev).toString(),
    }
  }

  else {
    return {
      next: null,
      prev:
        prev == 0
          ? null
          : url + "&" + new URLSearchParams(filterParamsPrev).toString(),
    };
  }
}

function getOrderArgs(filters) {
  const order_by = filters.order_by;
  const order_args = order_by in order_map ? order_map[order_by] : "{id: desc}";
  return order_args;
}

function getQueryVariables(whereClause) {
  const variableList = Object.keys(whereClause.customFilters).map(
    (variableName) => {
      return "$" + variableName + ": jsonb";
    }
  );
  var variables = "";
  if (variableList.length > 0) {
    variables = "(" + variableList.join(",") + ")";
  }
  return variables;
}

export async function getConversationsList(base_url, version, filters, offset, controller, limit="20") {
  const whereClause = getWhereClause(filters, identityFn);
  const query = getConversationsQuery(
    getOrderArgs(filters),
    whereClause.query,
    getQueryVariables(whereClause),
    limit,
    offset,
    getSchemaName()
  );
  const { errors, data } = await fetchGraphQL(query, whereClause.customFilters, controller);

  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }
  // do something great with this precious data
  const pageCursors = getPrevAndNext({ base_url, version, filters, data, limit, offset });
  return {
    ...data,
    ...pageCursors,
  };
}

export async function fetchTotalCount(filters, controller) {
  const whereClause = getWhereClause(filters, identityFn);
  const query = getTotalConversationCountQuery(
    whereClause.query,
    getQueryVariables(whereClause),
    getSchemaName()
  );
  const { errors, data } = await fetchGraphQL(query, whereClause.customFilters, controller);
  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }
  const count = data.results.aggregate.totalCount;
  return {
    count: count,
  };
}
export const getInitFilters = (workspace) => {
  const query = gql`
      query InitFilters {
      scripting_rules:${getSchemaName()}_level_nlp_scriptingrule(where: {deleted: {_is_null: true} ${workspace ? `, workspace: {name: {_eq: "${workspace}"}}`: ""}}) {
        id
        title: rule_json(path: "title")
        expectationtype: rule_json(path: "expectation_type")
        tagType: rule_json(path: "tagType")
      }
      categories: ${getSchemaName()}_cfdanswer(distinct_on: category ${workspace ? `, where: {asrlog: {workspace: {name: {_eq: "${workspace}"}}}}`: ""}) {
        category
      }
      questions: ${getSchemaName()}_cfdanswer(distinct_on: question ${workspace ? `, where: {asrlog: {workspace: {name: {_eq: "${workspace}"}}}}`: ""}) {
        question
      }
      sections: ${getSchemaName()}_cfdanswer(distinct_on: section ${workspace ? `, where: {asrlog: {workspace: {name: {_eq: "${workspace}"}}}}`: ""}) {
        section
      }
      topics: ${getSchemaName()}_level_nlp_intenttopics(distinct_on: topics, order_by: {topics: asc}) {
        topics
      }
      users: ${getSchemaName()}_accounts_user(where: {deleted: {_is_null: true}, first_name: {_is_null: false, _neq: ""}}, order_by: {first_name: asc, last_name: asc}) {
        id
        first_name
        last_name
        is_active
        is_superuser
        email
        role
      }
      metrictagrule: ${getSchemaName()}_level_nlp_metrictagrule {
        card_message
        description
        id
        is_active
        is_active_description
        title
        tag_audit: level_nlp_ruleaudit {
          id
          is_verified
          rule_modified_at
          rule_category
          rule_verified_at
          verified_by {
            contactdetails:call_centre_contactdetails {
              client
            }
            id
            first_name
            last_name
            is_active
          }
          modified_by {
            contactdetails: call_centre_contactdetails {
              client
            }
            first_name
            last_name
            id
            is_active
          }
        }
        conditions:level_nlp_metrictagrulecondition {
          metric_name
          operator
          threshold
          threshold_options
          unit
        }
      }
      casestatus: ${getSchemaName()}_level_asr_casestatus {
        id
        integration_name
        status
      }
      selected_options: ${getSchemaName()}_cfdanswer(distinct_on: [selected_option]) {
        selected_option
      }
    }

  ` 
  return fetchGraphQL(query,{});
}
export async function conversationReviewPage(conversationId, controller) {
  const query = gql`
    query ConversationReviewPage {
      ${getSchemaName()}_banking_bankingconversation(where: {id: {_eq: ${conversationId}}}) {
        asr_log_id
        level_asr_asrlog {
          accounts_user {
            email
            first_name
            last_name
            is_active
            id
            ext_emp_id
          }
          level_asr_asrtranscriptionlog {
            active_user_id
            asr_event
            created
            ext_created_datetime
            sentiment
            speaker_tags
            text
            original_text
            id
            uuid
            timestamps
            accounts_user {
              first_name
              id
              last_name
              email
              is_active
              call_centre_contactdetails {
                client
              }
            }
          }
          metric {
            automated_compliance_score
            automated_etiquette_score
            automated_knowledge_score
            assists
            asr_log_id
            flags
            handling_time
            id
            resource_click_rate
            warnings
            qa_score
            level_asr_asrlog {
              cfanswers(limit:1,order_by: {raw_score: desc}) {
                max_score
                raw_score
                outcome
              }
            }
            quality_assessment_chatconversationmetrics {
              first_response_time
              id
              waiting_time_ratio
            }
            quality_assessment_voiceconversationmetrics {
              average_words_per_minute
              base_metrics_id
              created
              dead_air_ratio
              deleted
              id
              listen_to_talk_ratio
              modified
            }
          }
          cfanswers {
            rules_meta_data
            max_score
            outcome
            raw_score
            custom_form_id
            created
            id
            asr_log_id
            answer
            is_autoscored
            auto_instascore
            auto_max_instascore
            custom_form: quality_assessment_customform {
              id
              title
              categories: quality_assessment_customformcategory {
                id
                title
                sections: quality_assessment_customformsection {
                  id
                  title
                  questions: quality_assessment_customformquestion {
                    id
                    title
                    options
                    automated_scoring_id
                    prefill_option_index
                  }
                }
              }
            }
            level_asr_asrlog {
              metric {
                feedback_at
                agent_feedback
                accounts_user {
                  first_name
                  last_name
                }
              }
            }
            accounts_user {
              email
              first_name
              last_name
              is_active
              id
            }
          }
          quality_assessment_qadisputecomment {
              accounts_user {
                first_name
                last_name
                id
              }
              comment
              created
              modified
              deleted
              question_id
              cf_answer_id
              asr_log_id
              id
            }
          language
        }
      }
    }
  `;
  return fetchGraphQL(query, {}, controller);
}

export const getasrLogIdAndChannel = (conversationId, controller) => {
  const query = gql`
    query GetAsrIdAndChannel {
      ${getSchemaName()}_banking_bankingconversation(where: {id: {_eq: ${conversationId}}}) {
        asr_log_id
        level_asr_asrlog {
          channel
        }
        accounts_user {
          accounts_userprofile(where: {deleted: {_is_null: true}}) {
            profile
          }
        }

      }
    }
  `
  return fetchGraphQL(query,{}, controller)
}

export const getCustomForm = (data = {}, controller) => {
  // Need to fetch Channel enum from api
  let filter = `{id: {_eq: 10}}`
  const and_clauses = [];
  if(data?.channel === 1){
    and_clauses.push(`{is_call: {_eq: true}}`);
  }
  if(data?.channel === 2){
    and_clauses.push(`{is_chat: {_eq: true}}`);
  }
  if(data?.channel === 4){
    and_clauses.push(`{is_email: {_eq: true}}`);
  }

  if(data?.agentType) {
    const agentTypeClauses = data.agentType.map(type => {
      return `
      { 
        agent_type : {_contains: "${type}" }
      }`;
    });
    and_clauses.push("{_or:[" + agentTypeClauses.join(",") + "]" + "}");
  }

  and_clauses.push("{deleted: {_is_null: true}}");

  if (!and_clauses.length) {
    filter = {};
  } 
  else {
    filter = "{_and:[" + and_clauses.join(",") + "]" + "}";
  }

  const query = gql`
    query MyQuery {
      ${getSchemaName()}_quality_assessment_customform(where: ${filter}) {
        description
        id
        agent_type
        is_active
        max_score
        outcome_type
        scoring
        title
        quality_assessment_customformcategory {
          created
          form_id
          id
          modified
          title
          weightage
          quality_assessment_customformsection {
            id
            title
            quality_assessment_customformquestion {
              id
              options
              scoring
              title
              description
              automated_scoring_id
              prefill_option_index
            }
          }
        }
        quality_assessment_customformthresholds {
          created
          form_id
          from_value
          id
          modified
          outcome
          to_value
        }
      }
    }
  `;
  return fetchGraphQL(query, {}, controller) 
}
export async function fetchTotalDuration(filters, controller) {
  //debugger;
  const wrapperFn = (args) => {
    return "{conversation_id:" + args + "}";
  };
  const whereClause = getWhereClause(filters, wrapperFn);
  const query = getTotalDurationQuery(
    whereClause.query,
    getQueryVariables(whereClause),
    getSchemaName()
  );
  const { errors, data } = await fetchGraphQL(query, whereClause.customFilters, controller);
  if (errors) {
    // handle those errors like a pro
    console.error(errors);
  }
  const handling_time = data.results.aggregate.sum.handling_time;
  return {
    total_audio_duration_hours: (handling_time / 3600).toFixed(2),
  };
}

export async function fetchTotalDurationAndCount(filters, controller) {
  const [count, duration] = await Promise.all([
    fetchTotalCount(filters, controller),
    fetchTotalDuration(filters, controller),
  ]);
  //debugger;
  return { ...count, ...duration };
}

function getJsonClauses(filters, wrapperFn) {
  /*
    Jsonb needs some careful handling, we need dynamically create a variable name for each filter
    and create an OR expression for that variable.
    For multiple filters, an outer AND Clause will be used that composes all the
    OR clauses
  */
  const customFilterList = filters.customFilterList || [];
  var counter = 0;
  var customFilters = {};
  var orClause = (counter) =>
    wrapperFn(`{
    level_asr_asrlog: {
      custom_fields: {
        _contains: $customFieldsFilter${counter}
      }
    }
  }
  `);
  var andClauses = [];

  for (let i = 0; i < customFilterList?.length; i++) {
    const filterTitle = customFilterList[i]?.field_name;
    const filterType = customFilterList[i]?.field_type;

    if (filterTitle in filters) {
      const values = filters[filterTitle].split(",");
      const transformedValue = filterType === "int" ? values?.map(Number) : filterType === "str" ? values?.map(String) : filterType === "bool" ? values?.map(value => value == "true" || value == true ? true : false ) : values ;
      const or_clauses = transformedValue.map((value) => {
        const variable_name = "customFieldsFilter" + counter;
        const jsonDic = {};
        jsonDic[filterTitle] = value;
        customFilters[variable_name] = jsonDic;
        const clause = orClause(counter);
        counter += 1;
        return clause;
      });
      andClauses.push(or_clauses);
    }
  }
  const flatennedInnerClauses = andClauses.map((andClause) => {
    return "{_or:[" + andClause.join(",") + "]" + "}";
  });
  return {
    customFilters: customFilters,
    query: "{_and:[" + flatennedInnerClauses.join(",") + "]" + "}",
  };
}

function getWhereClause(filters, wrapperFn) {
  const app_user = store.getState().app_user;
  const metric_filters =
    "metric_rule" in filters ? filters["metric_rule"].split(",") : [];
  const rule_filters = "rule" in filters ? filters["rule"].split(",") : [];
  const user_filters = "user" in filters ? filters["user"].split(",") : [];
  const start_date = "start_date" in filters ? filters["start_date"] : null;
  const end_date = "end_date" in filters ? filters["end_date"] : null;
  const evaluation_start_date = "evaluation_start_date" in filters ? filters["evaluation_start_date"] : null;
  const evaluation_end_date = "evaluation_end_date" in filters ? filters["evaluation_end_date"] : null;
  const call_duration =
    "call_duration" in filters ? filters["call_duration"].split(",") : null;
  const negative_sentiment =
    "negative_moments_count_in" in filters
      ? filters["negative_moments_count_in"].split(",")
      : null;
  const followed = "followed" in filters ? filters["followed"] : null;
  const tagged_for_qa = "tagged_for_qa" in filters ? filters["tagged_for_qa"] : [];
  const insta_review_topics =
    "insta_review_topics" in filters
      ? filters["insta_review_topics"].split(",")
      : [];
  const channels = "channel" in filters ? filters["channel"].split(",") : [];
  const case_status_list =
    "case_status" in filters ? filters["case_status"].split(",") : [];
  const categories =
    "category" in filters ? filters["category"].split(",") : [];
  const qaStatus = "qa_status" in filters ? filters["qa_status"] : null;
  const organizations = "organization" in filters ? filters["organization"].split(",") : [];
  const conversationId = filters.search ? filters.search : null;
  const qa_auditor = "qa_auditor" in filters ? filters["qa_auditor"].split(",") : [];
  const agentType = "agent_type" in filters ? filters["agent_type"].split(",") : [];

  const and_clauses = [];

  const jsonClauses = getJsonClauses(
    filters,
    wrapperFn
  ); /* Clauses for custom filters */
  and_clauses.push(jsonClauses.query);

  const deleteClauses = [
    wrapperFn(`{
        deleted: {_is_null: true}
      }`),
  ];

  and_clauses.push("{_or:[" + deleteClauses.join(",") + "]" + "}");

  if (start_date && end_date) {
    const date_clauses = [
      wrapperFn(`{
        created : {_gte: "${start_date}", _lte: "${end_date}"}
      }`),
    ];
    and_clauses.push("{_or:[" + date_clauses.join(",") + "]" + "}");
  }

  if (evaluation_start_date && evaluation_end_date) {
    const date_clauses = [
      wrapperFn(`{
        level_asr_asrlog: {
          cfanswers: {
            created: {
              _gte: "${evaluation_start_date}", _lte: "${evaluation_end_date}"
            }
          }
        }
      }`),
    ];
    and_clauses.push("{_or:[" + date_clauses.join(",") + "]" + "}");
  }

  if (call_duration?.length > 0) {
    const call_duration_clauses = [
    wrapperFn(`{
      level_asr_asrlog: {
      level_asr_audiolog: {duration: {_gte: ${call_duration[0]}, _lte: ${call_duration[1]}}}},
    }`),
    wrapperFn(`
    {
      level_asr_asrlog: {
        channel: {_eq: 1}
      }
    }`)
    ];
    and_clauses.push("{_and:[" + call_duration_clauses.join(",") + "]" + "}");
  }

  if (negative_sentiment?.length > 0) {
    const negative_sentiment_clauses = [
      wrapperFn(`{
        level_asr_asrlog: {
          negative_moment_count_view: {
              negative_moments : {_gte: ${negative_sentiment[0]}, _lte: ${negative_sentiment[1]}}
   }
   }
  }`),
    ];
    and_clauses.push(
      "{_or:[" + negative_sentiment_clauses.join(",") + "]" + "}"
    );
  }

  if (case_status_list.length > 0) {
    const status_clauses = case_status_list.map((status) => {
      return wrapperFn(`{
          level_asr_asrlog: {
            level_asr_asrlogtocasemappings: {
            status: {_eq: "${status}"}
            }
          }
        }
      `);
    });
    and_clauses.push("{_or:[" + status_clauses.join(",") + "]" + "}");
  }

  if (insta_review_topics.length > 0) {
    const insta_review_clauses = insta_review_topics.map(
      (insta_review_topic) => {
        const elem =
          insta_review_topic == "Negative Conversation"
            ? "negative_conversation"
            : "rich_conversation";
        return wrapperFn(`{
        level_asr_asrlog: {
          quality_assessment_instareviewtags: {
              ${elem}: {_eq: true}
          }
        }
      }`);
      }
    );
    and_clauses.push("{_or:[" + insta_review_clauses.join(",") + "]" + "}");
  }

  if (channels.length > 0) {
    const channel_clauses = channels.map((channel) => {
      return wrapperFn(`{
        level_asr_asrlog: {
          channel: {_eq: ${channel}}
        }
      }`);
    });
    and_clauses.push("{_or:[" + channel_clauses.join(",") + "]" + "}");
  }

  if (user_filters.length > 0) {
    const filter_list = user_filters.map((user_filter) => {
      const id = user_filter;
      return wrapperFn(`{
        level_asr_asrlog: {
          accounts_user: {
                id: {_eq: "${id}"},
            }
        }
      }`);
    });
    and_clauses.push("{_or:[" + filter_list.join(",") + "]" + "}");
  }

  if (metric_filters.length > 0) {
    const filter_list = metric_filters.map((metric_filter) => {
      const title = metric_filter;
      return wrapperFn(`{
          level_asr_asrlog: {
            moments : {
                title: {_eq: "${title}"},
              }
            }
        }`);
    });
    and_clauses.push("{_or:[" + filter_list.join(",") + "]" + "}");
  }

  if (rule_filters.length > 0) {
    const filter_list = rule_filters.map((rule_filter) => {
      const title = rule_filter;
      return followed
        ? wrapperFn(`{
        level_asr_asrlog: {
          moments : {
              title: {_eq: "${title}"},
              is_visible: {_eq: true},
              followed: {_eq: "${followed}"}
            }
          }
      }`)
        : wrapperFn(`{
        level_asr_asrlog: {
          moments : {
              title: {_eq: "${title}"},
              is_visible: {_eq: true},
            }
          }
      }`);
    });
    and_clauses.push("{_or:[" + filter_list.join(",") + "]" + "}");
  }

  if (categories.length > 0) {
    const category_clauses = categories.map((category) => {
      return wrapperFn(`{
            level_asr_asrlog: {
              level_nlp_intenttopics: {
                level_nlp_category : {
                  title: {_eq: "${category}"}
                }
              }
            }
          }
        `);
    });
    and_clauses.push("{_or:[" + category_clauses.join(",") + "]" + "}");
  }

  if (organizations.length > 0) {
    const organization_clauses = organizations.map((organization) => {
      return wrapperFn(`{
        level_asr_asrlog: {
            transcript_id: { _similar: "${organization}%" }
        }
      }`);
    });
    and_clauses.push("{_or:[" + organization_clauses.join(",") + "]" + "}");
  }

  if (conversationId) {
    const conversationIdClauses = [
      wrapperFn(`{
              id: { _eq: "${conversationId}"}
        }`),
    ];
    and_clauses.push("{_or:[" + conversationIdClauses.join(",") + "]" + "}");
  }

  if (tagged_for_qa?.length) {
    const taggedForQAClauses = [
      wrapperFn(`{
      	level_asr_asrlog:{
          moments:{
            level_nlp_scriptingrule: {
              rule_json:{
                _has_key:"is_tagged_for_qa"
              }
            }
          }
        }
    }`, wrapperFn(`{
      level_asr_asrlog:{
          moments:{
            level_nlp_scriptingrule: {
              rule_json:{
                _contains:{is_tagged_for_qa:true}
              }
            }
          }
        }
    }`)),
    ];
    and_clauses.push("{_and:[" + taggedForQAClauses.join(",") + "]" + "}");
  }

  if (qaStatus) {
    const qaStatusClauses = getQAStatusFilterClause(qaStatus, wrapperFn);
    and_clauses.push("{_or:[" + qaStatusClauses.join(",") + "]" + "}");
  }

  if(qa_auditor?.length) {
    const qaAuditorClauses = qa_auditor.map(auditor => {
      return wrapperFn(`{ level_asr_asrlog: { 
        cfanswers:{ 
          user_id:{
          _eq: ${auditor}
              }
            }
         }}`);
    });
    and_clauses.push("{_or:[" + qaAuditorClauses.join(",") + "]" + "}");
  }

  if(agentType?.length) {
    const agentTypeClauses = agentType.map(type => {
      return wrapperFn(`
      { 
        level_asr_asrlog: 
        {
          accounts_user: {
            accounts_userprofile: {
              profile: {
                _contains: {
                  agent_type:"${type}"
                }
              }
            }
          }
        }
      }`);
    });
    and_clauses.push("{_or:[" + agentTypeClauses.join(",") + "]" + "}");
  }

  if (and_clauses.length == 0) {
    return "";
  }

  return {
    customFilters: jsonClauses.customFilters,
    query: "where:{_and:[" + and_clauses.join(",") + "]}",
  };
}

const getQAStatusFilterClause = (qaStatus, wrapperFn) => {
  const statusVal = qaStatusMap[qaStatus];
  if (statusVal === "PENDING_EVALUATION") {
    return [
      wrapperFn(`{
        _not: {
          level_asr_asrlog: {cfanswers: {}}
        }
      }`), wrapperFn(`
        {
          _and: [{level_asr_asrlog: {cfanswers: {}}}, {_not:{level_asr_asrlog: {cfanswers: {is_autoscored: {_eq: false}}}}}]
        }`)
    ];
  } else if (statusVal === "EVALUATED") {
    return [
      wrapperFn(`{_and: [
        {level_asr_asrlog: {cfanswers: {}}}, {level_asr_asrlog: {cfanswers: {is_autoscored: {_eq: false}}}}
      ] 
      }`),
    ];
  } else if (statusVal === "ACCEPTED") {
    return [
      wrapperFn(`{
        level_asr_asrlog: {
          metric: {
            agent_feedback: { _eq: "ACCEPT"}
          }
        }
    }`),
    ];
  }
  if (statusVal === "DISPUTED") {
    return [
      wrapperFn(`{
        level_asr_asrlog: {
          metric: {
            agent_feedback: { _eq: "REJECT"}
          }
        }
    }`),
    ];
  }
};

export const getQuestionScoreSummary = (filters) => {
  let queryFilter = 'deleted: {_is_null: true}';

  if(filters?.dateRangeValues) {
    let startDate = new Date(filters?.dateRangeValues?.from_date).toISOString();
    let endDate = new Date(filters?.dateRangeValues?.to_date).toISOString();

    queryFilter += `,created: {_gte: "${startDate}", _lte: "${endDate}"}`;
  }

  if(filters?.agents && filters?.agents.length) {
    queryFilter += `,agent_id: {_in: [${filters?.agents}]}`
  }

  if(filters?.agentType) {
    queryFilter += `,asrlog: {accounts_user: {accounts_userprofile: {profile: {_contains: {agent_type: "${filters?.agentType}"}}}}}`
  }

  const query = gql`
    query MyQuery {
      ${getSchemaName()}_cfdanswer (where: {${queryFilter}}) {
        agent_id
        category
        section
        question
        question_obj_id
        max_score
        score
        asrlog {
          accounts_user {
            first_name
            last_name
          }
        }
      }
    }
  `
  return fetchGraphQL(query,{})
}

export const getSectionScoreSummary = (filters) => {
  let queryFilter = 'deleted: {_is_null: true}';

  if(filters?.dateRangeValues) {
    let startDate = new Date(filters?.dateRangeValues?.from_date).toISOString();
    let endDate = new Date(filters?.dateRangeValues?.to_date).toISOString();

    queryFilter += `,created: {_gte: "${startDate}", _lte: "${endDate}"}`;
  }

  if(filters?.agents && filters?.agents.length) {
    queryFilter += `,agent_id: {_in: [${filters?.agents}]}`
  }

  if(filters?.agentType) {
    queryFilter += `,asrlog: {accounts_user: {accounts_userprofile: {profile: {_contains: {agent_type: "${filters?.agentType}"}}}}}`
  }

  const query = gql`
    query MyQuery {
      ${getSchemaName()}_cfdanswer (where: {${queryFilter}}) {
        agent_id
        section
        section_score
        asrlog {
          accounts_user {
            first_name
            last_name
            email
          }
        }
      }
    }
  `
  return fetchGraphQL(query,{})
}
