import Axios from "axios";
import tenantConfig from "../tenant_config";
import { getApiFilters, logoutUser } from "../utils/utilities";
import * as QueryString from "query-string";
import appConfigs from "../config";
import store from "../store";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import Constants from "../CompanySetup/utils/constants";
import { fetchTotalDurationAndCount } from "../graphqlAPI/graphqlAPI";
import { publicRoutes } from "../routes/publicRoutes/data";

const isQa = () => {
  const hostname = window.location.hostname;
  return hostname.includes("qa-dev") || hostname.includes("qa-stage");
};

export const getSchemaName = () => {
  let hostname = window.location.hostname;
  if (hostname.includes("-k8")) {
    hostname = hostname.replace("-k8", "");
  }
  // For QA env
  if (isQa()) {
    return `qa`;
  }
  if (hostname.includes("localhost") || hostname.includes("dev.dashboards")) {
    return "level";
    //return "toast";
  }
  if (
    hostname.includes("-") &&
    (hostname.includes("dev") ||
      hostname.includes("staging") ||
      hostname.includes("replica"))
  ) {
    const subdomain = hostname.split(".")[0];
    const values = subdomain.split("-");
    if (
      (values[0] === "dev" ||
        values[0] === "staging" ||
        values[0] === "replica") &&
      values[1]
    ) {
      if (values[1] === "dashboards" || values[1] === "app") {
        // dev-dashbooards.thelevel.ai
        return "level";
      }
      // dev-level.thelevel.ai
      return values[1];
    } else {
      return values[0];
    }
  } else {
    // level.thelevel.ai
    return tenantConfig[hostname.split(".")[0]]
      ? tenantConfig[hostname.split(".")[0]]
      : hostname.split(".")[0];
  }
};

export const BASE_URL = () => {
  if (process.env.REACT_APP_ENVIRONMENT) {
    let baseApiUrl = "";
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "STAGING":
        baseApiUrl = "stage";
        break;

      case "PRODUCTION":
        baseApiUrl = "prod";
        break;

      default:
        baseApiUrl = "dev";
        break;
    }
    if (baseApiUrl) return `https://${baseApiUrl}-api.thelevel.ai`;
  }
  const hostname = window.location.hostname;
  let baseUrl = "";
  // For QA env
  if (isQa()) {
    baseUrl = "qa-api";
  } else if (hostname.includes("dev-") || hostname.includes("localhost")) {
    // baseUrl = "qa-api"; //for feature development only
    // return "http://localhost:8000"; //development only
    baseUrl = "dev-api";
    // return "http://dev.dashboards.thelevel.ai:8000";
  } else if (hostname.includes("staging")) {
    baseUrl = "stage-api";
  } else if (hostname.includes("replica")) {
    baseUrl = "prod-api";
  } else {
    baseUrl = "prod-api";
  }
  //For kubernets instances only
  if (hostname.includes("-k8")) {
    baseUrl += "-k8";
  }
  // return `http://${baseUrl}.thelevel.ai`; //for feature development only
  return `https://${baseUrl}.thelevel.ai`;
};

export const getToken = () => store.getState().app_user.token;
export const getScreenrecordToken = () =>
  store.getState().app_user.screenrecord_jwt;

export const axiosInstance = Axios.create({
  baseURL: BASE_URL(),
  headers: {
    "X-DTS-SCHEMA": getSchemaName(),
  },
  withCredentials: true,
});

export const getHeaders = () => {
  const selectedWorkspace = store.getState().app_user.selectedWorkspace;
  const headers = {
    Authorization: `Token ${getToken()}`,
    "X-DTS-SCHEMA": getSchemaName(),
  };
  if (selectedWorkspace) {
    headers.workspace = selectedWorkspace;
  }
  return headers;
};

export const storeApi = () => {
  return axiosInstance({
    method: "GET",
    url: `/business-units/list/`,
  }).then((response) => {
    return response.data;
  });
};

export const associateApi = () => {
  return axiosInstance({
    method: "GET",
    url: `/accounts/role/list/`,
  }).then((response) => {
    return response.data;
  });
};

export const bankSummary = () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/summary/`,
  });
};

export const getCustomerConversations = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/customer-conversations/list/`,
    params: filters,
  });
  return response;
};

export const getBankingConversations = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/list/`,
    params: filters,
  });
  return response;
};

export const getBankingConversationDetail = async (id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/retrieve/${id}`,
  });
  return response;
};

export const ListWithFiltersApi = (queryParams) => {
  return axiosInstance({
    method: "GET",
    url: `/customer-conversations/list/?${queryParams}`,
  }).then((response) => {
    return response;
  });
};

export const getAdvisorList = () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/advisor-list/`,
  });
};

export const getKeywordDrilldownData = (keyword, queryString) => {
  return axiosInstance({
    method: "GET",
    url: `/level-nlp/keywords/mentions/?keyword=${keyword}${queryString}&limit=100`,
  }).then((response) => {
    let results = response.data.results;
    let sentenceTableData = [];
    let mentions = [];
    let totalConversations = results.length;
    let usage = {};
    // eslint-disable-next-line
    let usageGraphData = {};
    let mentionsTableData = [];
    let summary = {
      mentions: 0,
      conversations: results.length,
      associates: 0,
    };

    for (let result of results) {
      let associate = result.user.first_name;
      let sentence = result.asr_log.text;
      sentenceTableData.push({
        associate,
        sentence,
      });
      summary.mentions += result.frequency;

      if (!(associate in mentions)) {
        summary.associates += 1;
        mentions[associate] = {
          mentions: result.frequency,
          percentageOfTotal: 1 / totalConversations,
        };
      } else {
        mentions[associate] = {
          mentions: mentions[associate].mentions + result.frequency,
          percentageOfTotal:
            mentions[associate].percentageOfTotal + 1 / totalConversations,
        };
        mentions[associate].percentageOfTotal =
          Math.round(mentions[associate].percentageOfTotal * 10000) / 10000;
      }
      var d = new Date(result.created);
      let date = new Date(d.getFullYear(), d.getMonth());

      if (!(date in usage)) {
        usage[date] = 1;
      } else {
        usage[date] += 1;
      }
    }
    usageGraphData = {
      labels: Object.keys(usage).reverse(),
      data: Object.values(usage).reverse(),
    };
    for (let associate in mentions) {
      mentions[associate].percentageOfTotal =
        Math.round(mentions[associate].percentageOfTotal * 100) / 100;
      mentionsTableData.push({
        associate,
        ...mentions[associate],
      });
    }
    return {
      sentences: sentenceTableData,
    };
  });
};

export const getKeywordsList = async (flag, page) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/keywords/list/`,
  });
  return response;
};

export const getFeedbackList = async (flag, page) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/conversation-feedback-list/`,
  });
  return response;
};

export const changePassword = async (password, current_password) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/reset-password/`,
    data: {
      password,
      current_password,
    },
  });
  return response;
};

export const getSearchResults = async (search) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/knowledge-search/`,
    data: {
      text: search,
      speaker_tags: { customer: "ddd" },
    },
  });
  return response;
};

export const forgotPassword = async (email) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/users/forgot-password/`,
    data: {
      email: email,
    },
  });
  return response;
};

export const resetPassword = async (password, token) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/users/reset-password/`,
    data: {
      password: password,
      token: token,
    },
  });
  return response;
};

export const zendeskSSO = async (return_to) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/zendesk/sso/?return_to=${return_to}`,
  });
  return response;
};

export const oktaSSO = async (code, redirect_uri) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/okta/sso/`,
    data: {
      code,
      redirect_uri,
      device: {
        fcm_id: "loremipsum2019",
        device_id: "loremipsum2019",
      },
    },
  });
  return response;
};

export const isPublicRoute = () => {
  let path = window.location.pathname;
  return publicRoutes.some((item) => item.path === path);
};

export const interceptor = () => {
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401 && !isPublicRoute()) {
        logoutUser();
        const queryParams = QueryString.parse(window.location.search);
        if (window.location.href.includes("plugin/assist")) {
          const params = {
            ...queryParams,
            sessionExpired: true,
          };
          window.location.href = `/plugin/login/?${QueryString.stringify(
            params
          )}`;
        } else if (window.location.pathname !== "/") {
          window.location.href = "/?sessionExpired=true";
        }
      }
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.request.use(function (config) {
    const selectedWorkspace = store.getState().app_user.selectedWorkspace;
    config.headers["X-DTS-SCHEMA"] = getSchemaName();
    if (!config.headers.workspace) {
      if (selectedWorkspace) {
        config.headers["workspace"] = selectedWorkspace;
      } else if (config.url.includes("banking")) {
        config.headers["workspace"] = "all_workspaces";
      } else if (config.url.includes("level-nlp")) {
        config.headers["workspace"] = "global";
      }
    }
    return config;
  });
};

export const cubeJSAuthenticationToken = async (token) => {
  const response = await axiosInstance({
    method: "POST",
    url: `/accounts/analytics/auth/`,
  });
  if (response.data.jwt) {
    store.dispatch({
      type: "SET_CUBE_JWT",
      payload: {
        value: response.data.jwt,
        expiry: Date.now() + 29 * 60 * 60 * 24 * 1000,
      },
    });
  }
  return response;
};

export const hasuraAuthenticationToken = async (token) => {
  const response = await axiosInstance({
    method: "POST",
    url: `/accounts/graphql/auth/`,
  });
  if (response.data.jwt) {
    store.dispatch({
      type: "SET_HASURA_JWT",
      payload: {
        value: response.data.jwt,
        expiry: Date.now() + 29 * 60 * 60 * 24 * 1000,
      },
    });
  }
  return response;
};

export const screenRecordAuthenticationToken = async (token) => {
  if(appConfigs.ENABLE_SCREEN_RECORDING || getSchemaName() === "level") {
    const response = await axiosInstance({
      method: "POST",
      url: `/accounts/screenrecord/auth/`,
    });
    if (response.data.jwt) {
      store.dispatch({
        type: ReduxActionTypes.SET_SCREENRECORD_JWT,
        payload: {
          value: response.data.jwt,
          expiry: Date.now() + 29 * 60 * 60 * 24 * 1000,
        },
      });
    }
    return response;
  }
  return {data:""};
};

export const authorizeUser = () => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: "GET",
      url: `/accounts/me/`,
      withCredentials: true,
    })
      .then((res) => {
        Promise.all([
          cubeJSAuthenticationToken(res.data.token),
          hasuraAuthenticationToken(res.data.token),
          screenRecordAuthenticationToken(res.data.token),
        ]).then(() => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const salesforceLogin = (queryParams) => {
  return Axios({
    method: "POST",
    url: `${BASE_URL()}/accounts/salesforce/login/`,
    data: {
      auth: queryParams.authToken,
      base_url: queryParams.baseUrl,
      email: queryParams.emailId,
      device: {
        fcm_id: "loremipsum2019",
        device_id: "loremipsum2019",
      },
    },
    withCredentials: true,
    headers: {
      "X-DTS-SCHEMA": getSchemaName(),
      "Content-Type": "application/json",
    },
  });
};

export const logoutUserApi = () => {
  return axiosInstance({
    method: "POST",
    url: `/accounts/logout/`,
  });
};

export const getTotalDurationCount = async (filters, version, cancelToken) => {
  const response = await axiosInstance({
    method: "GET",
    url: `/banking/${version ? version + "/" : ""}list-duration/`,
    params: filters,
    cancelToken: cancelToken?.token,
  });
  return response;
};

export const fetchCountTotalDuration = (cancelToken, rest) => (dispatch) => {
  dispatch({
    type: ReduxActionTypes.REQUEST_COUNT_DURATION,
  });
  const filters = store.getState().dashboard.globalFilters;

  return getTotalDurationCount(
    getApiFilters(filters),
    appConfigs.versionNumber,
    cancelToken
  ).then((res) => {
    dispatch({
      type: ReduxActionTypes.RESPONSE_COUNT_DURATION,
      payload: res.data,
    });
  });
};

/*Fetch Count and Duration using GraphQL API*/
export const fetchCountTotalDurationGraphQL = (ct, rest) => (dispatch) => {
  //feature gated self serve
  dispatch({
    type: ReduxActionTypes.REQUEST_COUNT_DURATION,
  });
  const filters = store.getState().dashboard.globalFilters;
  const customFilterList = store
    .getState()
    .dashboard.customFilters?.map((item) => {
      return { field_name: item.field_name, field_type: item.field_type };
    });

  return fetchTotalDurationAndCount(
    getApiFilters({ ...filters, customFilterList }),
    rest.controller
  )
    .then((res) => {
      dispatch({
        type: ReduxActionTypes.RESPONSE_COUNT_DURATION,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: ReduxActionTypes.RESPONSE_COUNT_DURATION,
        payload: {
          count: 0,
          total_audio_duration_hours: 0,
        },
      });
    });
};

export const getIntegrationConfig = () => (dispatch) => {
  return Axios({
    method: "GET",
    url: `${BASE_URL()}/business/config/`,
    headers: {
      "X-DTS-SCHEMA": getSchemaName(),
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: ReduxActionTypes.SET_INTEGRATION_CONFIG,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      console.log("ERROR ", err);
      dispatch({ type: ReduxActionTypes.SET_INTEGRATION_CONFIG, payload: {} });
      throw new Error();
    });
};

export const getCategories = async () => {
  return new Promise(async (resolve, reject) => {
    let filtersSegmentList = store.getState().dashboard.filterSegments;
    let categoryList = filtersSegmentList?.categoriesList;
    if (categoryList) {
      resolve(categoryList);
    } else {
      try {
        let res = await axiosInstance({
          method: "GET",
          url: `/banking/most-discussed-topics/?list_all=True`,
        });
        categoryList = res.data?.results || [];
      } catch (e) {
        categoryList = [];
      }
      const categoryListFormated = categoryList
        .filter((x) => x.topic_name !== "")
        .map((item) => {
          return {
            label: item.topic_name.trim(),
            value: item.topic_name.trim(),
          };
        })
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

      const filterKey = Constants.FILTER_NAMES.CATEGORIES_LIST;
      store.dispatch({
        type: ReduxActionTypes.CACHE_FILTER_DATA,
        payload: { [filterKey]: categoryListFormated },
      });
      resolve(categoryListFormated);
    }
  });
};
