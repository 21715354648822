import { getSchemaName } from "../../api/api";
import { queryOptions } from "../dynamic-query-builder";
import { getTimezoneApprox } from "../utils";
import { applyFilters } from "./filters";
import { applyGroupBy, applyMultiGroupBy } from "./groupby";
import appConfigs from '../../config';

const { measureOptions, groupByOptions, timeGranularityOptions } = queryOptions;

const combineLocalAndGlobalFilters = (local, global) => {
  let finalFilter = { ...global };
  Object.keys(finalFilter).forEach((key) => {
    finalFilter[key] = [finalFilter[key]].flat();
  });

  Object.keys(local).forEach((key) => {
    let localValue = [local[key]].flat();
    if (finalFilter[key]) {
      finalFilter[key] = [...finalFilter[key], ...localValue];
    } else {
      finalFilter[key] = [...localValue];
    }
  });
  return finalFilter;
};

const qualityAssessmentAggregateQueryBuilder = ({
  measure,
  filterbyValues,
  globalFilters,
}) => {
  let base_query = {
    measures: [measure],
    timeDimensions: [],
    order: {},
    dimensions: [],
    filters: [],
  };

  const filters = combineLocalAndGlobalFilters(filterbyValues, globalFilters);
  base_query = applyFilters(base_query, filters);

  base_query.timezone = getTimezoneApprox();
  base_query.filters = [...new Set(base_query.filters)];

  return base_query;
};

const qualityAssessmentQueryBuilder = ({
  measure,
  tooltipMeasures,
  stackMeasures,
  stackBy,
  groupBy,
  filterbyValues,
  globalFilters,
  isTimeSeries,
  ordering,
  segment,
  removeLimit,
  addDimensions = [],
  limit,
  timeDimension
}) => {
  console.assert(Object.values(measureOptions).includes(measure));
  console.assert(Object.values(groupByOptions).includes(groupBy));
  console.assert(
    Object.values(timeGranularityOptions).includes(
      globalFilters?.timeGranularity
    )
  );


  let measures = Array.isArray(measure) ? measure : [measure];

  tooltipMeasures && tooltipMeasures.forEach((x) => {
    measures.push(`${x}`)
  })

  if (stackMeasures) {
    measures = [...stackMeasures.map((x) => 
      `${x}`
    ), measure]
  }
  
  let base_query = {
    measures,
    timeDimensions: [
      {
        dimension: `LevelAsrAsrlog.created`,
        // granularity: globalFilters?.timeGranularity,
        ...timeDimension
      },
    ],
    order: {
      [`LevelAsrAsrlog.created`]: ordering || "desc",
      [Array.isArray(measure) ? measure[0] : measure]: ordering || "desc"
    },
    dimensions: [],
    filters: [],
  };

  if (!isTimeSeries) {
    // base_query.timeDimensions = [];
    if (!removeLimit) {
      base_query.limit = limit || 50;
    }
  }

  // Add filters here
  const filters = combineLocalAndGlobalFilters(filterbyValues, globalFilters);
  base_query = applyFilters(base_query, filters, timeDimension);

  if(isTimeSeries) {
    base_query.timeDimensions[0] = {
      ...base_query.timeDimensions[0],
      granularity: globalFilters?.timeGranularity
    } 
  }
  
  base_query = Array.isArray(groupBy) && groupBy.length > 1 ? 
    applyMultiGroupBy(
      base_query,
      groupBy,
      base_query.filters,
      stackBy,
      addDimensions
    )
    : 
    applyGroupBy(
      base_query,
      Array.isArray(groupBy) ? groupBy[0] : groupBy,
      base_query.filters,
      stackBy,
      addDimensions
    );

  // Check this code
  if (segment) {
    base_query.filters = [...base_query.filters, segment];
  }

  base_query.timezone = getTimezoneApprox();
  base_query.filters = [...new Set(base_query.filters)];
  return base_query;
};

export {
  qualityAssessmentQueryBuilder,
  qualityAssessmentAggregateQueryBuilder,
};
