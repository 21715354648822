const Actions = {
    SET_TITLE: "SET_TITLE",
    SET_VALIDATION: "SET_VALIDATION",
    SET_DATA: "SET_DATA",
    END_LOADING: "END_LOADING",    
    SET_DESCRIPTION: "SET_DESCRIPTION",
    TOGGLE_ACTIVE: "TOGGLE_ACTIVE",
    SET_THRESHOLD_VALUE: "SET_THRESHOLD_VALUE",
    TOGGLE_CUSTOM_THRESHOLD: "TOGGLE_CUSTOM_THRESHOLD",
}

export const popupActions = {
    setTitle: (value, dispatch) => {
        dispatch({
            type: Actions.SET_TITLE,
            payload: value
        });
    },
    toggleActive: (value, dispatch) => {
        dispatch({
            type: Actions.TOGGLE_ACTIVE,
            payload: value
        });
    },
    setDescription: (value, dispatch) => {
        dispatch({
            type: Actions.SET_DESCRIPTION,
            payload: value
        });
    },
    setData: (data, dispatch) => {
        dispatch({
            type: Actions.SET_DATA,
            payload: data
        });
    },
    endLoading: (dispatch) => {
        dispatch({
            type: Actions.END_LOADING,
        })
    },
    setThresholdValue: (payload, dispatch) => {
        dispatch({
            type: Actions.SET_THRESHOLD_VALUE,
            payload: payload
        });
    },
    toggleCustomThreshold: (dispatch) => {
        dispatch({
            type: Actions.TOGGLE_CUSTOM_THRESHOLD,            
        });
    }
}

export const popupReducer = (state, action) => {
    if(action.type === Actions.SET_TITLE) {
        return {
            ...state,
            title: action.payload,
            modified: true,
        };
    }
    else if(action.type === Actions.SET_DESCRIPTION) {
        return {
            ...state,
            description: action.payload,
            modified: true,
        };
    }
    else if(action.type === Actions.TOGGLE_ACTIVE) {
        return {
            ...state,
            is_active: action.payload,
            modified: true,
        };
    }
    else if(action.type === Actions.SET_VALIDATION) {
        return {
            ...state,
            validation: action.payload
        };
    }
    else if(action.type === Actions.SET_DATA) {
        return {
            ...state,
            title: action.payload.title,
            loading: action.payload.loading,
            modified: false,
        };
    }
    else if(action.type === Actions.END_LOADING) {
        return {
            ...state,
            loading: false
        };
    }
    else if(action.type === Actions.SET_THRESHOLD_VALUE) {
        const conditions = state.conditions;
        conditions[0].threshold = action.payload.threshold;
        conditions[0].unit = action.payload.unit;
        return {
            ...state,
            threshold: action.payload.threshold,
            isCustomThreshold: action.payload.isCustomThreshold,
            conditions: [...conditions]
        }
    }
    else if(action.type === Actions.TOGGLE_CUSTOM_THRESHOLD) {
        return {
            ...state,
            isCustomThreshold: true,
            threshold: ""            
        }
    }
}

export const getInitialState = (ruleData) => {
    return {
        loading: false,
        title: ruleData.title || "",
        description: ruleData.description,
        validation: "",
        modified: false,
        is_active: ruleData.is_active,
        is_active_description: ruleData.is_active_description,
        unit: ruleData.conditions[0].unit.toLowerCase(),
        threshold: ruleData.conditions[0].threshold,
        isCustomThreshold: ruleData.conditions[0].threshold_options.includes(ruleData.conditions[0].threshold) ? false : true,
        threshold_options: ruleData.conditions[0].threshold_options || [],
        conditions: ruleData.conditions,
    }
}
