import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../../components/ErrorFallback";
import { StyledTable } from "../../../components/common/styled-components";
import { useColumns } from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { deleteExternalData } from "./api";
import { REQUEST_TYPE } from "../../../custom-hooks/config";
import { openNotification } from "../../../utils/utilities";


const Table = ({ loading, handleToggle, height }) => {
  const dispatch = useDispatch();
  const tableData = useSelector(state => state.selfServeExternalData.tableData);

  const { columns } = useColumns(handleToggle, handleDelete, handleEdit);
  const [_loading, setLoading] = useState(loading);

  const [callApi, cancelRequests] = useReq();

  function handleDelete (record) {
      let _tableData = [...tableData];
      _tableData = _tableData.filter(data => data.id !== record.id);
      dispatch({ type: ReduxActionTypes.SET_SELF_SERVE_CUSTOM_FILTER_DATA, payload: [..._tableData] });
      openNotification("bottomLeft", "Field deleted successfully.");
      callApi(deleteExternalData, (res) => {
      }, (err) => {

      }, {
        id: record.id
      }, () => {

      }, REQUEST_TYPE.DISPATCH_REQUEST);
  }
  function handleEdit(record) {
    dispatch({ type: ReduxActionTypes.SET_EDIT_MODE, payload: {
      is_enabled: true,
      data: record
    } });
  }
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <StyledTable
      rowKey = "id"
      dataSource = {tableData?.length ? tableData : []}
      loading = {_loading}
      columns = {columns}
      pagination = {false}
      scroll={{ y: height, x: 900}}
    />
  )
}

const TableComponent = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Table {...props} />
  </ErrorBoundary>
);

export default TableComponent;
