import styled from "@emotion/styled/macro";
import { Modal, Select, Button, Input } from "antd";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-height: 780px;
    position: relative;
  }
`;
export const ModalWrapper = styled.div`
  width: 100%;
  min-height: 640px;
  max-height: 780px;
`;
export const ModalHeaderWrapper = styled.div`
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #bdbdbd;
`;

export const SharingModalHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
`;
export const SharingModalFieldContainer = styled.div``;

export const TitleField = styled(Input)`
  margin-top: 30px;
`;

export const FrequencyField = styled(Select)`
  width: 277px;
  margin-top: 20px;
`;

export const ModalFooter = styled.div`
  position: absolute;
  bottom: 10px;
  right: 25px;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`;

export const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #0b4b93;
  box-sizing: border-box;
  border-radius: 4px;
  width: 106px;
  height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const CreateButton = styled(Button)`
  width: 73px;
  height: 32px;
  background: #0b4b93;
  border: 1px solid #0b4b93;
  color: white;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 6px;
  font-size: 14px;
  padding: 8px 16px;
  .ant-spin {
    margin: 2px !important;
  }
`;
