import React from "react";
import { ReactComponent as PositiveSentimentIcon } from "../assets/notifications/instareview-positive.svg"
import { ReactComponent as NegativeSentimentIcon } from "../assets/notifications/instareview-negative.svg"
import { ReactComponent as ShareIcon } from "../assets/notifications/share.svg"
import { ReactComponent as QAIcon } from "../assets/notifications/qa.svg"

export const getIconFromNotification = (notification) => {
    switch(notification.notification_type) {
        case "INSTA_REVIEW_TAG": {
            return <PositiveSentimentIcon />;
        }
        case "INSTA_REVIEW_TAG_NEGATIVE": {
            return <NegativeSentimentIcon />;
        }
        case "INSTA_REVIEW_TAG_RICH": {
            return <PositiveSentimentIcon />;
        }
        case "AGENT_INBOX": {
            return <ShareIcon />;
        }
        case "QA": {
            return <QAIcon />;
        }
    }
}

export const linkGenerator = (notifications) => {
    if (notifications.length != 1) {
        switch (notifications[0].notification_type) {
            case "QA_CALIBRATION":
                return `/qa-calibrations/`;
            default:
                return `/conversations/?conversation_id=${notifications.map(notification=>notification.conversation).join(",")}`;
        }
    }
    else {
        return notifications[0].redirect_url ? notifications[0].redirect_url : `/conversations/${notifications[0].conversation}?versionNumber=v2`;
    }
}