import React from "react";
import { StyledTable, StyledSwitch, TitleDescriptionWrapper } from "./../../../components/common/styled-components";
// import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import ActionMenu from "./../commons/ActionMenu";
import { LockOutlined } from '@ant-design/icons';
import styled from "@emotion/styled/macro";

// const StyledLockOutlined = styled(LockOutlined)`
//   &&& svg {
//     fill: #BDBDBD;
//     width: 12px;
//     height: 12px;
//     position: relative;
//     left: 2px;
//     margin-right: 4px;
//   }
// `;

const WorkspaceList = ({tableData, isLoading, editWorkspace, updateWorkspace, deleteWorkspace, changeWorkspaceStatus }) => {
  const columns = [
    {
      title: "Workspace Name",
      dataIndex: 'name',
      key: 'name',
      width: "45%",
      render: (value, record) => {
        return (
          <TitleDescriptionWrapper onClick={() => editWorkspace(record)}>
              <div className="rule-title">{record.name}</div>
          </TitleDescriptionWrapper>
        )
      }
    },
    // {
    //     title: "Users",
    //     dataIndex: 'users',
    //     key: 'users',
    //     render: (value, record) => {
    //       return record?.users ? record.users.length : 0;
    //     }
    // },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: "20%",
      render: (value, record) => {
        return (
          <StyledSwitch 
            size="small" 
            checked={value === undefined ? true : value} 
            onChange={(checked) => {              
              changeWorkspaceStatus(record.id, checked);
            }}
          />
        );
      },          
    },
    {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "5%",
        render: (value, record) => {
          return (
            <ActionMenu 
              menuItems={[
                {
                  title: "Delete Workspace",
                  key: "delete",
                  icon: DeleteIcon,
                },                
              ]}
              handleMenuClick={(key) => {
                if(key === "delete") { 
                  deleteWorkspace(record);
                }
              }}
            />
          )
        }
      }  
  ];
  return (
    <StyledTable 
      dataSource={tableData} 
      columns={columns} 
      loading={isLoading} 
      rowKey="id" 
      scroll={{y: "calc(100vh - 290px)"}}  
      pagination={false} 
    />    
  )      
}

export default WorkspaceList;