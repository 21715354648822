import styled from "@emotion/styled/macro";

export const AnalyticsDashboardWrapper = styled.div`
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 42px);
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #E0E0E0;
    padding: 15px 23px;
`;

export const DetailsWrapper = styled.div`
    display: flex;    
    height: calc(100vh - 162px);
    overflow: auto;
    padding: 16px 24px;
`;

export const DetailsTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #828282;      
    margin-top: 9px;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;   
    margin-top: 10px; 
`;

export const FilterComponentWrapper = styled.div`
    margin-right: 4px;
    margin-bottom: 4px;
`;

export const AnalyticMetricsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 66%;
    height: 100%;
`;

export const LoadingAnalyticMetricsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 162px);
    width: 66%;
`;

export const InteractionsWrapper = styled.div`
    border-left: 1px solid #e0e0e0;
    padding: 16px 24px;
    width: 34%;
    overflow: auto;
    height: calc(100vh - 162px);
`;

export const InteractionReviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;    
`;

export const LevelMetricsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    min-height: 120px;
`;

export const ScoreCardWrapper = styled.div`
    margin-right: 12px;
    width: 100%;
    &:last-of-type {
        margin: 0;
    }
`;

export const LineSeparator = styled.div`
    width: calc(100% - 48px);
    margin: 16px auto 0;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

export const UserMetricsWrapper = styled.div`
    display: flex;
    flex-direction: column;    
`;

export const UserMetricWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    box-sizing: border-box;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    height: 540px;
    min-height: 540px;
`

export const SelectablesWrapper = styled.div`
    display: flex;    
    border-bottom: 1px solid #F2F2F2;
`;

export const MetricDetailsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 20px;
    height: 380px;    
`;

export const TitleWrapper = styled.div`
    height: 36px;
    max-height: 36px;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
    box-sizing: border-box;
    padding: 12px 21px;
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    color: #828282;
    display: flex;
    flex: 1;
`;

export const InteractionReviewTitleWrapper = styled(TitleWrapper)`
    margin-bottom: 8px;
`;

export const TopFlagsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 16px;
`;

export const FlagCardWrapper = styled.div`
    border-bottom: 1px solid #F2F2F2;
    width: 100%;
`;

export const InteractionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 10px;
`;

export const TopFlagsLoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
`; 

export const InteractionsListLoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
`; 
