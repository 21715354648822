import React, { useState } from "react";
import { Input } from "antd";
import FilterComponent from "../../../components/common/FilterComponent";
import styled from "@emotion/styled/macro";

const RestrictedStyledInput = styled(Input)`
  &&& {
    width: 60px;
    border: 1px solid #bdbdbd;
    margin-left: 0;
    margin-right: 10px;
    padding: 4px 10px;
  }
`;

const ConditionTitleWrapper = styled.div`
  display: inline-flex;
  width: 150px;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
`;

const FilterComponentWrapper = styled.div`
  &&& .ant-select-selection {
    min-width: 80px;
  }
`;

const TimeRestriction = (props) => {
  const unitsOptions = [
    { label: "Secs", value: "secs", multiplier: 1 },
    { label: "Mins", value: "mins", multiplier: 60 },
  ];
  const percentUnitOptions=[{label:"percent", value:"percent", multiplier:1}]
  const { defaultValue } = props;
  const [value, setValue] = useState(
    defaultValue > 0 && defaultValue % 60 === 0
      ? defaultValue / 60
      : defaultValue
  );
  const [unit, setUnit] = useState(
    props.unit==='percent'?percentUnitOptions[0]:(
    defaultValue > 0 && defaultValue % 60 === 0
      ? unitsOptions[1]
      : unitsOptions[0])
  );
  const onUnitChange = (v) => {
    let newUnit = unitsOptions.filter(({ value }) => v === value)[0];
    setUnit(newUnit);
    setValue(1);
    props.onValuesChange({
      currentTarget: { value: newUnit.multiplier, name: props.name },
    });
  };

  const onValueChange = (e) => {
    setValue(e.currentTarget.value);
    props.onValuesChange({
      currentTarget: {
        value: e.currentTarget.value * unit.multiplier,
        name: e.currentTarget.name,
      },
    });
  };

  return (
    <React.Fragment>
      {/* <ConditionTitleWrapper>within</ConditionTitleWrapper>  */}
      <RestrictedStyledInput
        onChange={onValueChange}
        name={props.name}
        value={value}
      />
      {props.unit === "percent" ? (
        <span>percent</span>
      ) : (
        <FilterComponentWrapper>
          <FilterComponent
            options={unitsOptions}
            placeholder={""}
            value={unit.value}
            hideClear
            handleChange={onUnitChange}
          />
        </FilterComponentWrapper>
      )}
    </React.Fragment>
  );
};

export default TimeRestriction;
