import React, { useState, useEffect } from "react";
import { useSelector } from "../../store";
//import DynamicChartRenderer from '../dynamic-chart';
import { ChartListWrapper } from "../components/wrapper";
import { queryOptions } from "../dynamic-query-builder";
import { MultiChart } from "../multi-chart";
import { hasManagerRights } from "../config";
import { chartsTitle } from "../utils";
import { addAnalyticsEvent } from "../../utils";
import { HeapAnalyticsEvent } from "../../components/common/HeapAnalyticsEvent";

export const TeamEfficiency = (props) => {
  const { defaultChartType, timeSeries } = props;
  const isTimeSeries = timeSeries || false;
  const timeSeriesSelectable = !timeSeries;
  const restProps = {
    isConsolidated: props.isConsolidated,
    showComparisonView: props.showComparisonView,
    isChartTypeEditable: true,
    chartType: defaultChartType || "interval",
  };
  const globalFilters = useSelector((state) => state.dashboard?.globalFilters);

  useEffect(() => {
    if (!props.noHeapEvent)
      addAnalyticsEvent(
        HeapAnalyticsEvent.Detect_Team_QA_Efficiency_Page_Visit
      );
  }, []);
  return (
    <ChartListWrapper>
      <MultiChart
        queries={[
          {
            measure: queryOptions.measureOptions.CONVERSATION_COUNT,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          },
          {
            measure: queryOptions.measureOptions.FIRST_RESPONSE_TIME,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            isTimeUnit: true,
            timeSeriesSelectable,
          },
        ]}
        {...restProps}
        title={chartsTitle.Responsiveness}
        chartType={defaultChartType || "interval"}
        isChartTypeEditable
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
      ></MultiChart>

      <MultiChart
        queries={[
          {
            measure: queryOptions.measureOptions.AVERAGE_HANDLING_TIME,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            isTimeUnit: true,
            timeSeriesSelectable,
          },
          {
            measure: queryOptions.measureOptions.AVERAGE_CASE_RESOLUTION_TIME,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            isTimeUnit: true,
            timeSeriesSelectable,
          },
        ]}
        {...restProps}
        title={chartsTitle.Efficiency}
        chartType={defaultChartType || "interval"}
        isChartTypeEditable
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
      ></MultiChart>

      <MultiChart
        queries={[
          {
            measure: queryOptions.measureOptions.FOLLOWED_RATE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          },
          {
            measure: queryOptions.measureOptions.FLAGGED_RATE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          },
        ]}
        {...restProps}
        title={chartsTitle.Scripting_Adherence}
        chartType={defaultChartType || "interval"}
        isChartTypeEditable
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
      ></MultiChart>

      {globalFilters.channel === "1" && (
        <MultiChart
          queries={[
            {
              measure: queryOptions.measureOptions.DEAD_AIR_RATIO,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
            {
              measure: queryOptions.measureOptions.LISTEN_TO_TALK_RATIO,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
            {
              measure: queryOptions.measureOptions.AVERAGE_WORDS_PER_MINUTE,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
          ]}
          {...restProps}
          title={chartsTitle.Call_Specific}
          chartType={defaultChartType || "interval"}
          isChartTypeEditable
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></MultiChart>
      )}

      {globalFilters.channel === "4" && (
        <MultiChart
          queries={[
            {
              measure:
                queryOptions.measureOptions.CUSTOMER_TO_AGENT_EMAIL_RATIO,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
            {
              measure: queryOptions.measureOptions.AVERAGE_CUSTOMER_REPLY_COUNT,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
            {
              measure: queryOptions.measureOptions.AVERAGE_AGENT_REPLY_COUNT,
              groupBy: queryOptions.groupByOptions.AGENT,
              isTimeSeries,
            },
          ]}
          {...restProps}
          title={chartsTitle.Email_Specific}
          chartType={defaultChartType || "interval"}
          isChartTypeEditable
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></MultiChart>
      )}
    </ChartListWrapper>
  );
};
