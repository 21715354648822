import React from 'react'
import { Menu, Input, Spin, message } from 'antd';
import { MenuWrapper } from "./commons/wrappers";
import { uploadResource, savePublicUrl } from "../api/api"
import ActionButtons from "./ActionButtons";
import ResourceTabs from "./ResourceTabs";
import styled from "@emotion/styled/macro";

const ResourcePopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding: 24px;
`;

const StyledInput = styled(Input)`
    height: 47px;
    padding: 4px 10px;   
    font-size: 16px;
    color: #4F4F4F;
`;

const RESOURCE_TABS = {
    PUBLIC: "public",
    OFFLINE: "offline"
}

class ResourcePopup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            current: RESOURCE_TABS.PUBLIC,
            uploading: false,
            publicUrl: ""
        };

    }
    handleClick = (e) => {
        this.setState({
            current: e.key
        })
    }
    handleResourceNameChange = (e) => {
        this.setState({
            resourceName: e.currentTarget.value
        })
    }
    handleFileChange = (file) => {
        this.setState({
            file
        })
    }

    onPublicUrlChange = (url) => {
        this.setState({
            publicUrl: url
        });
    }

    handleSubmit = () => {
        if (this.state.current === RESOURCE_TABS.OFFLINE) {
            let data = new FormData();
            data.append('file', this.state.file, this.state.file.name)
            data.append('resource_name', this.state.resourceName || this.state.file.name)
            this.setState({
                uploading: true
            })
            uploadResource(data).then(_ => {
                this.setState({
                    uploading: false
                })
                this.props.onSubmit()
            })
        }
        else if(this.state.current === RESOURCE_TABS.PUBLIC) {
            let data = {
                resource_name: this.state.resourceName,
                public_url: this.state.publicUrl
            };
            if(!data.resource_name) {
                message.error("Resource Name is empty!");
            }
            else if(!data.public_url) {
                message.error("Public Source is empty!");
            }
            else {
                this.setState({
                    uploading: true
                })
                savePublicUrl(data).then(() => {
                    this.setState({
                        uploading: false
                    })
                    this.props.onSubmit();
                })
            }
        }
    }
    handleCancel = () => {
        this.props.onCancel()
    }
    render() {
        const { current, uploading } = this.state;
        return (
            <ResourcePopupWrapper>
                <StyledInput name="resource-name" placeholder="Resource Name" onChange={this.handleResourceNameChange}/>
                <MenuWrapper>
                    <Menu onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
                        <Menu.Item key={RESOURCE_TABS.PUBLIC}>
                            Public Source
                        </Menu.Item>
                        <Menu.Item key={RESOURCE_TABS.OFFLINE}>
                            Offline Material
                        </Menu.Item>
                    </Menu>
                </MenuWrapper>
                {
                    uploading ?
                    <Spin tip="Uploading...">
                    </Spin>
                    :
                    null
                }
                <ResourceTabs tab={current} onFileChange={this.handleFileChange} onPublicUrlChange={this.onPublicUrlChange}/>
                <ActionButtons onSubmit={this.handleSubmit} onCancel={this.handleCancel} />
            </ResourcePopupWrapper>
        )
    }
}

export default ResourcePopup