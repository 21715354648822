import React, { useEffect, useState, useReducer } from "react";
import DynamicChartRenderer from "./dynamic-chart";
import { ChartSettingsWrapper, MultiChartWrapper, SelectWrapper } from "./components/wrapper";
import { SelectablesWrapper } from "../conversation-analytics/wrappers";
import SelectableScoreCard from "../components/cards/SelectableScoreCard";
import { getMeasureText, queryAggregateCubeJS, roundOff } from "./utils"
import { ChartTypeToggle } from "./chart";
import { TimeSeriesSelect } from "./components/time-series-select";
import { queryOptions } from "./dynamic-query-builder";
import { Select } from "antd";
import { addAnalyticsEvent } from '../../src/utils';
import {HeapAnalyticsEvent} from '../components/common/HeapAnalyticsEvent';
import { useSelector } from "../store";


const summaryValuesReducer = (summaryValues, action) => {
    const { index, value } = action.payload;
    let newValues = [...summaryValues]
    newValues[index] = parseFloat(value).toFixed(2);
    return newValues;
}

const dividerReducer = (dividerValues, action) => {
    const { index, value } = action.payload;
    let newValues = [...dividerValues]
    newValues[index] = value
    return newValues;
}

const measureTitlesReducer = (measureTitles, action) => {
    const { index, value } = action.payload;
    let newValues = [...measureTitles]
    newValues[index] = `${newValues[index].split('(')[0]} (${value})`
    return newValues;
}

const loadingReducer = (loading, action) => {
    const { index, value } = action.payload;
    let newValues = [...loading]
    newValues[index] = value
    return newValues;
}

const timeSeriesReducer = (isTimeSeries, action) => {
    const { value } = action.payload;
    let newValues = new Array(isTimeSeries.length).fill(value)
    return newValues;
}

export const MultiChart = (props) => {
    const [ chartType, setChartType ] = useState(props.chartType);
    const [ selectedChart, selectChart ] = useState(0);
    const [isFixedToInterval, setisFixedToInterval] = useState(false);
    const [ queries, setQueries ] = useState([]);
    const [ sortBy, setSortBy ] = useState(queryOptions.sortByOptions.TOP_50.key)
    const [ summaryValues, dispatch ] = useReducer(summaryValuesReducer, new Array(props.queries.length).fill("-"));
    const [ measureTitles, dispatchMeasureTitle ] = useReducer(measureTitlesReducer, props.queries.map(q => getMeasureText(q.measure) + (q.isTimeUnit ? " (secs)" : "")));
    const [ dividers, dispatchDividers ] = useReducer(dividerReducer, new Array(props.queries.length).fill(1));
    const [ isLoadingSummary, dispatchLoading ] = useReducer(loadingReducer, new Array(props.queries.length).fill(true));
    const [ isTimeSeries, dispatchTimeSeries ] = useReducer(timeSeriesReducer, props.queries.map(q => q.isTimeSeries));
    const globalFilters = useSelector(state => state.dashboard.globalFilters)
    const selectedWorkspace = useSelector(state => state.app_user.selectedWorkspace)
    const { isChartTypeEditable } = props;
    const setSummary = (value, index, query) => {
        const { isTimeUnit } = query;

        dispatch({
            type: "SET_SUMMARY",
            payload: {
                index,
                value
            }
        })

        dispatchLoading({
            payload: {
                index,
                value: false
            }
        })

        if (isTimeUnit) {
            let unit = "secs"
            let divider = 1
            if (value > 60) {
                unit = "mins"
                divider = 60
            }
            if (value > 60*60) {
                unit = "hours"
                divider = 60*60
            }
            if (value > 24*60*60) {
                unit = "days"
                divider = 60*60*24
            }

            dispatchDividers({
                type: "SET_DIVIDERS",
                payload: {
                    index,
                    value: divider
                }
            })
    
            dispatchMeasureTitle({
                type: "SET_MEASURE_TITLE_UNIT",
                payload: {
                    index,
                    value: unit
                }
            })
        }
    }

    const runQueries = () => {
        props.queries.forEach((query, index) => {
            dispatchLoading({
                payload: {
                    index,
                    value: true
                }
            })
            queryAggregateCubeJS(
                query, 
                globalFilters,
                (value) => {
                    setSummary(value, index, query)
                }
            )
        });
        setQueries(props.queries);
    }

    useEffect(() => {
        setSortBy(queryOptions.sortByOptions.TOP_50.key)
    }, [globalFilters, selectedWorkspace])
    
    useEffect(() => {
        if (props.isConsolidated) {
            dispatchTimeSeries({
                type: "SET_TIME_SERIES",
                payload: {
                    value: false
                }
            })
        }
    }, [props.isConsolidated])

    useEffect(() => {
        runQueries()
    }, [globalFilters, selectedWorkspace])

    const handleSortingChange = (sortBy) => {
        addAnalyticsEvent(HeapAnalyticsEvent.Sort_Agents_By,{sort_Agents_By: sortBy==='asc' ? "Bottom 50" : "Top 50" });
        setSortBy(sortBy)
    }

    return (
        <MultiChartWrapper>
            <ChartSettingsWrapper>
                <p>{
                    props.title ? 
                    props.title
                    :
                    ""
                }</p>

                <SelectWrapper>

                {
                    props.queries[selectedChart].timeSeriesSelectable ?
                    <TimeSeriesSelect
                        {...props}
                        value={isTimeSeries[selectedChart]}
                        onChange={(value) => {
                            dispatchTimeSeries({
                                type: "SET_TIME_SERIES",
                                payload: {
                                    value
                                }
                            })
                        }}
                    />
                    :
                    null
                }

                {
                    !isTimeSeries[selectedChart] ?
                    <Select
                        style={{ width: 120 }}
                        onChange={handleSortingChange}
                        value={sortBy}
                    >
                        {
                            Object.keys(queryOptions.sortByOptions).map((key) => (
                                <Select.Option value={queryOptions.sortByOptions[key].key}>{queryOptions.sortByOptions[key].label}</Select.Option>
                            ))
                        }
                    </Select>
                    :
                    null
                }

                {
                    isChartTypeEditable ?
                    <ChartTypeToggle isFixedToInterval={isFixedToInterval} chartType={chartType} onChange={
                        (val) => {
                            addAnalyticsEvent(HeapAnalyticsEvent.Detect_Chart,{chartType:val});
                            setChartType(val)
                        }
                    }/>
                    :
                    null
                }
                </SelectWrapper>
            </ChartSettingsWrapper>
            <SelectablesWrapper>
            
                {
                    props.queries.map((query, index) => (
                        <SelectableScoreCard 
                            selected={index === selectedChart}
                            title= {measureTitles[index]}
                            scoreValue= {isLoadingSummary[index] ? "-" : `${roundOff(summaryValues[index]/dividers[index])}`.replace("NaN", '-')}
                            selectable= {true}
                            addAnalyticsEvent={addAnalyticsEvent}
                            updateSelection = {() => {
                                selectChart(index)
                            }}
                        />
                    ))
                }
                
            </SelectablesWrapper>
            <DynamicChartRenderer 
                {...props} 
                query={{...props.queries[selectedChart], isTimeSeries: isTimeSeries[selectedChart]}}
                chartType={isFixedToInterval ? "interval" : chartType}
                ordering={sortBy}
                selectedChart={selectedChart}
                onTimeUnitUpdate = {(mt, divider) => {
                }}
                fixIntervalChart={(e) => setisFixedToInterval(e)}
            />
        </MultiChartWrapper>
    )
}