import React, { useEffect, useState } from "react";
import InteractionsSampleBlock from "./SampleBlock/InteractionsSampleBlock";
import QaAuditorBlock from "./QaAuditorBlock";
import AgentsBlock from "./AgentsBlock";
import NameAndScheduleBlock from "./NameAndScheduleBlock";

const RuleBlocks = () => {
  return (
    <>
      <NameAndScheduleBlock/>
      <AgentsBlock />
      <InteractionsSampleBlock />
      <QaAuditorBlock />
    </>
  );
};

export default RuleBlocks;
