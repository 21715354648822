import { filter } from "@antv/util";
import { getSchemaName } from "../../api/api"
import store from "../../store"
import { hasManagerRights, hasQAAuditorRights } from "../config"
import { getSelectFilterOptions, queryConfigs } from "../query-config";

export const FilterOperator = {
    EQUALS: "equals",
    NOT_EQUALS: "notEquals",
    GTE: "gte",
    LTE: "lte",
    CONTAINS: "contains",
    IN_DATE_RANGE: "inDateRange",
    TS_QUERY: "tsQuery",
    SET: "set",
    NOT_SET: "notSet"
}

const FILTER_DIMENSIONS = queryConfigs.filterOptions

const removeFilter = (dimension, baseFilter, operator = FilterOperator.EQUALS)  => {
    return [
        ...baseFilter.filter(x => (
            !(x.dimension === dimension && x.operator === operator)
        ))
    ]
}

const addDateFilter = (from_date, to_date, dimension, baseFilter) => {
    const finalFilter = [
        ...removeFilter(dimension, baseFilter, FilterOperator.IN_DATE_RANGE),
        {
            "dimension": dimension,
            "operator": FilterOperator.IN_DATE_RANGE,
            "values": [from_date, to_date]
        },
    ]
    return finalFilter
}

const addFilter = (_values, dimension, baseFilter, operator = FilterOperator.EQUALS) => {
    const values = [_values];

    let finalFilter = [
        ...baseFilter
    ]

    if(operator === FilterOperator.SET || operator === FilterOperator.NOT_SET) {
        finalFilter = [
            ...finalFilter,
            {
                "dimension": dimension,
                "operator": operator
            }
        ];

        return finalFilter;
    }

    if (values.flat().filter(
        x=>(x!==undefined && x !== null && x !== "" && x != false)
    ).length === 0) return finalFilter;

    finalFilter = [
        ...finalFilter,
        {
            "dimension": dimension,
            "operator": operator,
            "values": [...new Set(values.flat())]
        }
    ]
    return finalFilter
}


const addWorkspaceFilter = (baseFilter) => {
    const workspace = store.getState().app_user.selectedWorkspace;
    let convertedFilters = [...baseFilter];
    if (workspace != undefined && workspace != "") {
        convertedFilters = addFilter(workspace, "Workspace.subdomain", convertedFilters)
    }  else {
        convertedFilters = removeFilter("Workspace.subdomain", convertedFilters)
    }
    return convertedFilters
}

export const applyFilters = (query, filters, timeDimension=null) => {

    const filterOptions = getSelectFilterOptions();
    let filtersSkew = [];           // TO TRACK FILTERS APPLIED WITH COMPLEX LOGIC HERE & SKIP IN GENERAL APPLY
    let convertedFilters = [];

    // SPECIFIC FILTERS APPLY
    if (!hasManagerRights() && !hasQAAuditorRights() && !window.location.pathname.includes('query-builder/view')) {
        convertedFilters = addFilter([`${store.getState().app_user.id}`], "AccountsUser.id", convertedFilters);
        filtersSkew.push('agents');
    } else {
        if (filters.agents) {
            convertedFilters = addFilter(filters.agents, "AccountsUser.id", convertedFilters);
            filtersSkew.push('agents');
        } else {
            convertedFilters = removeFilter("AccountsUser.id", convertedFilters)
            filtersSkew = removeFilterFromSkew(filtersSkew, 'agents');
        }
    }

    if (filters.dateRangeValues && filters.dateRangeValues[0] && !timeDimension) {
        // convertedFilters = addDateFilter(
        //     filters.dateRangeValues[0].from_date, 
        //     filters.dateRangeValues[0].to_date,
        //     "LevelAsrAsrlog.created",
        //     convertedFilters
        // )
        query.timeDimensions = [{
            dimension: "LevelAsrAsrlog.created",
            dateRange: [ 
                new Date(filters.dateRangeValues[0].from_date).toISOString().split('T')[0], 
                new Date(filters.dateRangeValues[0].to_date).toISOString().split('T')[0],
            ]
        }];

        filtersSkew.push('dateRangeValues');
    }

    if (filters?.conversation_tag || filters?.conversation_tag_id || filters?.mostUnfollowedtags?.length) {
      if (filters?.conversation_tag_id?.length) {
          if (filters?.followed?.length && filters.followed[0]) {
              convertedFilters = addFilter(filters.conversation_tag_id.map(x=>` ${x} ${filters.followed} `), "LevelAsrAsrlog.conversationTags", convertedFilters, FilterOperator.CONTAINS);
          } 
          else {
              convertedFilters = addFilter(filters.conversation_tag_id.map(x=>` ${x} `), "LevelAsrAsrlog.conversationTags", convertedFilters, FilterOperator.CONTAINS);
          }
          filtersSkew.push('conversation_tag_id');
      }
      else {
        if (filters.followed) {
            convertedFilters = addFilter([` ${filters.followed} `], "LevelAsrAsrlog.conversationTags", convertedFilters, FilterOperator.CONTAINS);
            filtersSkew.push('followed');
        }
      }
    }
    else {
          convertedFilters = removeFilter("LevelAsrAsrlog.conversationTags", convertedFilters);
          filtersSkew = removeFilterFromSkew(filtersSkew, 'conversation_tag_id');

    }

    if (filters.insta_review) {
        if (filters.insta_review.includes("Negative Conversation")) {
            convertedFilters = addFilter(["true"], "InstaReviewTags.negative_conversation", convertedFilters)
            convertedFilters = removeFilter("InstaReviewTags.rich_conversation", convertedFilters)
        } else if (filters.insta_review.includes("Rich Conversation")) {
            convertedFilters = addFilter(["true"], "InstaReviewTags.rich_conversation", convertedFilters)
            convertedFilters = removeFilter("InstaReviewTags.negative_conversation", convertedFilters)
        }
        filtersSkew.push('insta_review');
    } else {
        convertedFilters = removeFilter("InstaReviewTags.negative_conversation", convertedFilters);
        convertedFilters = removeFilter("InstaReviewTags.rich_conversation", convertedFilters);
        filtersSkew = removeFilterFromSkew(filtersSkew, 'insta_review');
    }
    

    if (filters.negative_sentiment) {
        convertedFilters = addFilter(filters.negative_sentiment, "MomentNegativeSentiment.title", convertedFilters);
        filtersSkew.push('negative_sentiment');
    }
    else {
        convertedFilters = removeFilter("MomentNegativeSentiment.title", convertedFilters);
        filtersSkew = removeFilterFromSkew(filtersSkew, 'negative_sentiment');
    }

    if(filters.expectationtype?.length && (filters.mostUnfollowedtags?.length)) {
        convertedFilters = addFilter(filters.expectationtype, "MFTags.expectation_type", convertedFilters);
        filtersSkew.push('expectationtype');
    }
    else {
      convertedFilters = removeFilter("MFTags.expectation_type", convertedFilters);
      filtersSkew = removeFilterFromSkew(filtersSkew, 'expectationtype');
    }

    if(filters.qa_status) {
        convertedFilters = addFilter(filters.qa_status, "QA_STATUS.name", convertedFilters)
        filtersSkew.push('qa_status');
    }
    else {
        convertedFilters = addFilter(filters.qa_status, "QA_STATUS.name", convertedFilters);
        filtersSkew = removeFilterFromSkew(filtersSkew, 'qa_status');
    }

    if (filters.categories) {
        convertedFilters = addFilter(filters.categories, "CFDAnswer.category", convertedFilters)
        filtersSkew.push('categories');
    } else {
        convertedFilters = removeFilter("CFDAnswer.category", convertedFilters)
        filtersSkew = removeFilterFromSkew(filtersSkew, 'categories');
    }

    if (filters.negative_sentiments) {
        filters.negative_sentiments.forEach((filterValue, index) => {
            if(filterValue == "Yes") {
                convertedFilters = addFilter([], FILTER_DIMENSIONS['negative_sentiments'], convertedFilters, FilterOperator.SET);
            } else {
                convertedFilters = addFilter([], FILTER_DIMENSIONS['negative_sentiments'], convertedFilters, FilterOperator.NOT_SET);
            }
        })
        
        filtersSkew.push('negative_sentiments');
    }
    else {
        convertedFilters = removeFilter(FILTER_DIMENSIONS['negative_sentiments'], convertedFilters);
        filtersSkew = removeFilterFromSkew(filtersSkew, 'negative_sentiments');
    }

    // GENERAL FILTERS APPLY
    Object.keys(filters).forEach((key, index) => {
        if(!filtersSkew.includes(key)) {
            let filterObject = filters[key];
            let filterDimension = filterOptions.filter(el => el.key === key)[0]?.value;

            if(filterObject && filterDimension) {
                convertedFilters = addFilter(filterObject, filterDimension, convertedFilters)
            }
        }
    });

    if(filters.campaign) {
        convertedFilters = addFilter(filters.campaign, "AccountsUser.campaign", convertedFilters)
    }
    else {
        convertedFilters = removeFilter("AccountsUser.campaign", convertedFilters)
    }

    if(filters.location) {
        convertedFilters = addFilter(filters.location, "AccountsUser.location", convertedFilters)
    }
    else {
        convertedFilters = removeFilter("AccountsUser.location", convertedFilters)
    }

    Object.keys(filters).forEach(key => {
        if (key.startsWith("CustomColumns.")) {
            convertedFilters = addFilter(filters[key], key, convertedFilters)
        }
    })

    convertedFilters = [
        ...convertedFilters,
        {
            dimension: "AccountsUser.firstName",
            operator: "notEquals",
            values: ["Bot", "System"]
        }
    ]

    convertedFilters = addWorkspaceFilter(convertedFilters);
    query.filters = convertedFilters;
    
    return query;
}

const removeFilterFromSkew = (filtersSkew, value) => {
    let filterIndex = filtersSkew.findIndex(el => el === value);

    if(filterIndex > -1) {
        filtersSkew.splice(filterIndex, 1);
    }

    return filtersSkew;
}