import React from 'react';
import AgentsFilter from "../../../components/common/AgentsFilter";
import { ChannelFilter } from "../../../components/common/ChannelFilter";
import ConversationTagFilter from "../../../components/common/ConversationTagFilter";
import InstaReviewFilter from "../../../components/common/InstaReviewFilter";
import MetricTagsFilter from "../../../components/common/MetricTagsFilter";
import OrganizationFilter from '../../../components/common/OrganizationFilter';
import TopicsFilter from "../../../components/common/TopicsFilter";
import MultiInputComponent from './components/RuleView/MultiInputComponent';

export const ruleModes = {
  CREATE: "create",
  EDIT: "edit"
}
export const operatorOptions = {
  EQUALS: "equals",
  IS_LESS_THAN: "is less than",
  IS_MORE_THAN: "is more than",
  IS_BETWEEN: "is between"
}

export const frequencyOptions = {
  DAILY: {label: "Daily", value: 1},
  WEEKLY: {label: "Weekly", value: 7},
  MONTHLY: {label: "Monthly", value: 30}
}

export const dayOptions = {
  "1": "Monday",
  "2": "Tuesday",
  "3": "Wednesday",
  "4": "Thursday", 
  "5": "Friday",
  "6": "Saturday",
  "7": "Sunday"
}

export const daySuffix = {
  "1": "st",
  "2": "nd",
  "3": "rd"
}
export const generateDaySuffix = (day) => {
  return day == 1 || day == 2 || day == 3 ? daySuffix[day] : "th"; 
}

export const pullDataOptions = {
  [frequencyOptions.DAILY.label]: ["Last 24 hours"],
  [frequencyOptions.WEEKLY.label]: ["Last 7 days"],
  [frequencyOptions.MONTHLY.label]: ["Last 30 days"]
}

export const defaultFilterOptions = [
  // {
  //   key: "user",
  //   label: "Agents",
  //   operatorOptions: [operatorOptions.EQUALS],
  //   renderComponent: (value, onChange, { ...rest }) => {
  //     const props = {
  //       value,
  //       handleChange: onChange,
  //       style: { width: "100%" },
  //       width: 5,
  //       ...rest
  //     }
  //     return (
  //       <>
  //         <AgentsFilter {...props} />

  //       </>
  //     );
  //   },
  // },
  {
    key: "category",
    label: "Categories",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        categoryMultiSelect: true,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        ...rest
      }
      return (
        <>
          <TopicsFilter {...props} />
        </>
      );
    },
  },
  {
    key: "metric_rule",
    label: "Metric Tags",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        metricMultiSelect: true,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        ...rest
      }
      return (
        <>
          <MetricTagsFilter {...props} />
        </>
      );
    },
  },
  {
    key: "convo_tag_sampling",
    label: "Conversation Tags",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        //handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        conversationTagMultiSelect: true,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        ...rest
      }
      return (
        <>
          <ConversationTagFilter {...props} handleChange = {(labels, lb, value, expectationtype) => {
            onChange(labels, { expectationtype: expectationtype?.toString(), convo_tag_id: value?.toString() });
          }} />
        </>
      );
    },
  },
  {
    key: "channel",
    label: "Channel",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        channelMultiSelect: true,
        ...rest
      }
      return (
        <>
          <ChannelFilter {...props} />
        </>
      );
    },
  },
  {
    key: "organization",
    label: "Organization",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        organizationMultiSelect: true,
        ...rest
      }
      return (
        <>
          <OrganizationFilter {...props} />
        </>
      );
    },
  },

  {
    key: "insta_review_topics",
    label: "Insta Review",
    operatorOptions: [operatorOptions.EQUALS],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        maxTagCount : 1,
        type: "local",
        showFooter: false,
        instaReviewMultiSelect: true,
        ...rest
      }
      return (
        <>
          <InstaReviewFilter {...props} />
        </>
      );
    },
  },
  {
    key: "negative_moments_count_in",
    label: "Negative Sentiment",
    operatorOptions: [operatorOptions.IS_MORE_THAN, operatorOptions.IS_BETWEEN, operatorOptions.IS_LESS_THAN],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        unit: "",
        ...rest
      }
      return (
        <>
          <MultiInputComponent {...props} />
        </>
      );
    },
  },
  {
     key: "call_duration",
    label: "Call Duration",
    operatorOptions: [operatorOptions.IS_MORE_THAN, operatorOptions.IS_BETWEEN, operatorOptions.IS_LESS_THAN],
    renderComponent: (value, onChange, { ...rest }) => {
      const props = {
        value,
        handleChange: onChange,
        style: { width: "100%" },
        width: 5,
        unit: "mins",
        ...rest
      }
      return (
        <>
          <MultiInputComponent {...props} />
        </>
      );
    },
  },
];

export const MAX_MINUTES = 1440;

export const MAX_DAYS = 28;
