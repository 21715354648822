import { Input, Select } from "antd";
import React, { useState, useEffect } from "react";
import ConversationTagFilter from "../../../components/common/ConversationTagFilter";
import DateFilter from "../../../components/common/DateFilter";
import { InputWrapper } from "./styles";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as AddIcon } from "../../../assets/add.svg";

const Optional = ({ payload, handleChange, optional }) => {
  const [params, setParams] = useState([""]);

  useEffect(() => {
    setParams(Object.keys(optional));
  }, []);

  const { Option } = Select;
  const handleOptionalChange = (val, name) => {
    handleChange(
      {
        ...optional,
        [name]: val,
      },
      "optional"
    );
  };
  const handleParamDelete = (param) => {
    const newParams = params.filter((p) => p !== param);
    const newOptionals = { ...optional };
    delete newOptionals[param];

    handleChange({ ...newOptionals }, "optional");
    setParams(newParams);
  };
  const handleParamChange = (val, ind) => {
    const newParams = [...params];
    newParams[ind] = val;
    setParams(newParams);
  };
  const getParamOptions = (param) => {
    if (!param) {
      return <></>;
    }
    const inputType = payload.find((item) => item.name === param).inputType;
    switch (inputType) {
      case "ConversationTagFilter":
        return (
          <ConversationTagFilter
            value={optional ? optional[param] : ""}
            handleChange={(val) => handleOptionalChange(val, param)}
          />
        );
      case "DateFilter":
        return (
          <DateFilter
            value={optional ? optional[param] : ""}
            handleChange={(val) => handleOptionalChange(val, param)}
            w="200"
            mt="0"
            mr="0"
          />
        );
      default:
        return (
          <Input
            style={{ width: "200px" }}
            placeholder={`Add your input`}
            name={param}
            value={optional ? optional[param] : ""}
            onChange={(e) => handleOptionalChange(e.target.value, param)}
            onMouseDown={(e) => e.stopPropagation()}
          />
        );
    }
  };

  return (
    <div className="mt-2 d-flex align-items-end">
      <div>
        {params.map((param, ind) => (
          <InputWrapper key={param || ind}>
            <Select
              value={param}
              style={{ width: "150px", minWidth: "150px" }}
              onChange={(val) => handleParamChange(val, ind)}
              className="me-2"
            >
              {payload.map((item) =>
                params.find((param) => param === item.name) ? null : (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                )
              )}
            </Select>
            {getParamOptions(param)}
            {param !== "" && (
              <DeleteIcon
                width="25px"
                height="25px"
                onClick={() => handleParamDelete(param)}
                className="cursor-pointer"
              />
            )}
          </InputWrapper>
        ))}
      </div>
      <AddIcon
        width="25px"
        height="25px"
        onClick={() => setParams((p) => [...p, ""])}
        className="mb-2 cursor-pointer"
      />
    </div>
  );
};

export default Optional;
