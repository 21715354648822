import React from 'react';
import { Modal } from 'antd';
import styled from "@emotion/styled/macro";
import {ReactComponent as ChevronRight} from '../../../assets/chevron_right.svg'

import {CONVERSATION_TAG, METRIC_TAG, PRECONFIGURED_QUESTIONS} from './constants'

const ModalWrapper = styled.div`
  display: flex;

  .categories {
    width: 30%;
    padding: 1.5rem;
    padding-left: 0;

    div.active {
      color: #6099d9;
      background: rgba(96, 153, 217, 0.2);
      border-radius: 0 2rem 2rem 0;
    }

    div {
      color: #4f4f4f;
      padding: 0.3rem 0.6rem;
      padding-left: 1.5rem;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  .questions-list {
    padding: 1.5rem 0 1.5rem 0;
    padding-left: 0;
    width: 70%;

    .question {
      cursor: pointer;
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      padding: 0.18rem 1.5rem 0.18rem 0.4rem;
      color: black;
      align-items: center;

      &:hover {
        background: #f7f7f7;
        transition: 0.4s ease;
      }
    }
    & .more-options-wrapper {
      width: 100%;
      padding-right: 1.5rem;
    }
    .more-options {
      margin-top: 1rem;
      background: #e892440d;
      padding: 1rem 1rem 0.1rem 1rem;
      border: 1px solid #e0e0e0;
      
      h5 {
        font-weight: 500;
        font-size: 0.9rem;
        color: black;
      }
      p {
        font-size: 0.7rem;
      }
    }
  }
`;

const AutoscoreEnabledQuestionModal = props => {
  const [activeTab, setActiveTab] = React.useState(CONVERSATION_TAG);
  const categories = {
    [CONVERSATION_TAG]: {
      label: "Conversation tag based",
      questions: props.presetQuestions[CONVERSATION_TAG],
    },
    [METRIC_TAG]: {
      label: "Metric tag based",
      questions: props.presetQuestions[METRIC_TAG],
    },
    [PRECONFIGURED_QUESTIONS]: {
      label: "Preconfigured",
      questions: props.presetQuestions[PRECONFIGURED_QUESTIONS],
    },
  };

  function switchTab(key) {
    setActiveTab(key)
  }

  return (
    <Modal
      title="Add autoscore enabled question"
      visible={props.isModalVisible}
      onOk={props.toggleModal}
      onCancel={props.toggleModal}
      footer={null}
      width="50vw"
    >
      <ModalWrapper>
        <div className="categories">
          {Object.keys(categories)?.map((key) => (
            <div
              key={key}
              onClick={() => {
                switchTab(key);
              }}
              className={activeTab === key ? "active" : ""}
            >
              {categories[key]?.label}
            </div>
          ))}
        </div>

        <div className="questions-list">
          {categories[activeTab]?.questions?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, {numeric: true, sensitivity: 'base'})).map((question) => (
            <div
              onClick={() => {
                props.addQuestion(question);
                props.toggleModal();
              }}
              className="question"
              key={question.id}
            >
              <div>{question.name}</div>
              <div>
                <ChevronRight />
              </div>
            </div>
          ))}
          <div className="more-options-wrapper">
            <div className="more-options">
              <h5>Looking for more options?</h5>
              <p>
                You can add a tag and once it's verified, it'll start appearing here as an option.
              </p>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
}

export default AutoscoreEnabledQuestionModal;
