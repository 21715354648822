import produce from "immer";
import { frequencyOptions, MAX_DAYS, MAX_MINUTES } from "./config";

export const mapFiltersMetaToArray = (data) => {
  let mappedData = produce(data, (_response) => {
    _response.sort((a, b) => a?.id - b?.id).forEach((interactions) => {
        interactions.filter_groups = interactions.filter_groups.map((conditionGroup) => {
            conditionGroup.filters = Object.entries(conditionGroup.filters).map(([key, value]) => {
                if(key === "convo_tag_sampling") {
                  return {
                    ...value,
                    val: value?.val?.title || null,
                    followed: value?.val?.followed || "Followed"
                  }
                }
                return value;
              }).sort((a, b) => a?.id - b?.id);
            return conditionGroup;
          }).sort((a, b) => a?.id - b?.id);
      });
  });
  return mappedData;
};

export const mapFiltersMetaToObject = (data) => {
  let mappedData = produce(data, _response => {
    _response.forEach(interactions => {
      interactions.filter_groups = interactions.filter_groups.map(conditionGroup => {
        conditionGroup.filters = conditionGroup.filters.reduce((acc, currentValue) => {
          acc = {
            ...acc,
            ...(
              currentValue.filterKey === "convo_tag_sampling" ? {
                [currentValue.filterKey]: {
                  ...currentValue,
                  val: {
                    title: currentValue.val,
                    followed: currentValue.followed
                  }
                }
              } : {
                [currentValue.filterKey]: {
                  ...currentValue
                }
              }
            )
          }
          return acc;
        }, {});
        return conditionGroup;
      })
  });
});
return mappedData;
}

export const getFrequencyValues = (data, minutesWithOffset) => {
  let day_of_week = data.day_of_week;
  let day_of_month = data.day_of_month;
  if(data.frequency === frequencyOptions.WEEKLY.label) {
    if(minutesWithOffset < 0) {
      day_of_week = (parseInt(data.day_of_week) - 1) % 7;
    }
    else if(minutesWithOffset > MAX_MINUTES) {
      day_of_week = (parseInt(data.day_of_week) + 1) % 7;
    }
    if(parseInt(day_of_week) === 0) {
      day_of_week = 7;
    }
  }
  else if(data.frequency === frequencyOptions.MONTHLY.label) {
    if(minutesWithOffset < 0) {
      day_of_month = (parseInt(data.day_of_month) - 1) % MAX_DAYS;
    }
    else if(minutesWithOffset > MAX_MINUTES) {
      day_of_month = (parseInt(data.day_of_month) + 1) % MAX_DAYS;

    }
    if(parseInt(day_of_month) === 0) {
      day_of_month = MAX_DAYS;
    }
  }

  return {
    day_of_week,
    day_of_month
  }
};
