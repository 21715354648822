import React, { useState,useEffect } from "react";
import { Select, Tooltip } from 'antd';
import styled from "@emotion/styled/macro";
import { ReactComponent as SelectIcon } from "./../../assets/select-icon.svg";
import { ReactComponent as SearchIcon } from "./../../assets/search-icon.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";
import { getInitFilters } from "../../graphqlAPI/graphqlAPI";
//import { Tooltip } from "@material-ui/core";
const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection{
    width: auto;
    min-height: 32px;
    min-width: ${props => props.minWidth ? props.minWidth : 150}px;
    border-color: ${props => props.defaultValue || props.value ? "#BDBDBD" : "#BDBDBD"};
  }
  .ant-select-selection__rendered {
    line-height: 32px;
    margin-right: 32px;
    margin-left: ${props => props.showSearchIcon ? 32 : 10}px;
  }
  .ant-select-selection-selected-value {
    position: absolute;
    top: 20%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    line-height: 18px;
  }
  .ant-select-selection__placeholder {
    line-height: 18px;
    //display: ${props => props.defaultValue || props.value ? "none" : "block !important"};
  }
  .ant-select-arrow {
    top: 16px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const SearchIconWrapped = styled(SearchIcon)`
  position: absolute;
  left: 12px;
  top: 10px;
  width: 12px;
  height: 12px;
  z-index: 1;
  path {
      fill: #C0C0C6;
  }
`;

const TextOnlyWrapper = styled.div``;

const Filter = (props) => {
  const [options,setOptions] = useState([]);

  const renderTextOnly = () => {
    let labelsList = [];
    let valueList = props.value;
    if(!Array.isArray(props.value)) {
      valueList = [props.value];
    }
    valueList.forEach(_value => {
      options && options.forEach(option => {
        const { value, label } = typeof(option) !== 'object' ? { value: option, label: option }: option;
        if(value == _value) {
          labelsList.push(label);
        }
      })
    })
    return labelsList?.join(', ') || "";
  }
  useEffect(()=>{
    const options = props.options?.options ? props.options.options : props.options;
    setOptions(options);
  },[props.options])
  
  return (
    <>
    {!props.showTextOnly ? <div style={{position:"relative"}}>
      {props.showSearchIcon && <SearchIconWrapped/>}
    <StyledSelect
      disabled={props.disabled}
      placeholder={props.placeholder}
      defaultValue={props.value !== undefined && props.value!=="" ? props.value : undefined}
      onChange={props.handleChange}
      style = {props.style}
      value={props.value !== undefined && props.value!=="" ? props.value : undefined}
      maxTagPlaceholder = {(omitted) => {
        let omittedItemsLabel = [];
        parseInt(omitted[0]) ? omitted.forEach(item => options.find(items => (parseInt(item) === parseInt(items.value)) && omittedItemsLabel.push(items.label) )) : omittedItemsLabel = [...omitted];
        return <Tooltip title ={<div>{ omittedItemsLabel.map(item => item).join(", ") }</div>}>+{omitted.length} ..</Tooltip>
      }}
      maxTagCount= {props.maxTagCount || 1}
      suffixIcon={<SelectIcon />}
      prefixIcon={props.prefixIcon}
      allowClear={!props.hideClear}
      minWidth={props.width}
      showSearch={props.showSearch}
      mode={props.multiSelect ? "multiple" : "default"}
      filterOption={(value, option) => {
        if(option.key === "all" || option.key ==="none") {
          return;
        }
        return option.props.children.toUpperCase().includes(value.toUpperCase());
      }}
      showSearchIcon={props.showSearchIcon}
      loading={props.loading || false}
    >
    {props.selectAll && <Option className="select-all" key={props.selectAllEnabled ? "all" : "none"} value={props.selectAllEnabled ? "all" : "none"}><div style={{display:'flex', justifyContent:"space-between"}}><div style={{color: `${props.selectAllEnabled ? "#6099D9" : "#DB554D"}`}}>{props.selectAllEnabled ? `Select All +` : `Unselect All -`}</div></div></Option>}
    {options?.map(option=>{
      const { value, label, is_action } = typeof(option) !== 'object' ? { value: option, label: option, is_action: false }: option;
      let optionStyle = props.capitalize ? { "textTransform": "capitalize" } : {}; 
      if(is_action) {
        optionStyle.fontStyle = "italic";
        optionStyle.fontWeight = "bold";
      }
      return (
        <Option title={label} key={value} is_action={is_action} style={optionStyle}>
           {label}
        </Option>
      )
    })}
    </StyledSelect>
    </div> : <TextOnlyWrapper style = {props.showTextStyle}>{renderTextOnly()}</TextOnlyWrapper>}
    </>
  );
}

const FilterComponent = (props) => <ErrorBoundary FallbackComponent={ErrorFallback}><Filter {...props}/></ErrorBoundary>

export default FilterComponent;
