import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled/macro";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { ReactComponent as UserIcon } from "./../../assets/user-icon.svg";
import UserMenu from "./UserMenu";
import ModifyPassword from "./ModifyPassword";
import {Link, withRouter} from 'react-router-dom';
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as ChangePasswordIcon } from "../../assets/change-password.svg";
import { ReactComponent as HelpCentreIcon } from "../../assets/help-centre.svg";
import { ReactComponent as LiveChatIcon } from "../../assets/live-chat.svg";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import WorkspaceFilter from "../../components/common/WorkspaceFilter";
import { dateRangeOptions, getDateRangeValues, logoutUser } from "../../utils/utilities";
import { zendeskSSO, logoutUserApi } from "../../api/api";
import { NotificationOutlined } from "@ant-design/icons"
import { Select } from 'antd'
import { ReactComponent as NotificationIcon } from "../../assets/notifications/notification.svg";
import { Badge } from 'antd';
import store, { useSelector } from '../../store';
import { hasManagerRights, hasQAAuditorRights } from '../../AnalyticsDashboard/config';
import { getSchemaName } from '../../api/api'
import { TenantActionTypes } from '../../constants/ReduxActionConstants';
import appConfigs from "../../config";
import { setItemInStore } from '../../utils';

const { Option } = Select;

const StyledBadge = styled(Badge)`
    .ant-badge-count {
        min-width: 16px;
        margin-top: 10px;
        height: 16px;
        border-radius: 8px;
        font-size: 11px;
        padding: 0 0px;
        line-height: 16px;
    }
`

const HeaderWrapper = styled.div`
    display: ${props => props.isVisible ? "flex" : "none"};
    justify-content: flex-end;
    align-items: center;
    height: 42px;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #E0E0E0;
    box-sizing: border-box;
    padding: 11px 22px 11px;
    top: ${props => props.top};
    position: sticky;
    z-index: 40;
    svg.user-icon {
        margin-left: 20px;
        width: 24px;
        height: 24px;
    }
`;

// const TrainingStatusWrapper = styled.div`
//     display: flex;
//     height: 42px;
//     p {
//         margin: auto;
//     }
// `

const UserName = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    display: flex;
    white-space: nowrap;
    margin-left: 4px;
    text-transform: capitalize;
`;

const FilterLabel = styled.div`
    display: flex;
    align-items: center;
    margin-right: 48px;
    span.filter-title {
        margin-right: 12px;
        color: #4F4F4F;
        font-size: 16px;
        font-weight: 400;
    }
    .ant-select-selection {
        min-width: 180px !important; 
    }
`

const UserMenuItems = [
    {
        title: "Change Password",
        key: "change-password",
        icon: ChangePasswordIcon
    },
    {
        title: "Help Center",
        key: "help-centre",
        icon: HelpCentreIcon
    },
    {
        title: "Live Chat",
        key: "live-chat",
        icon: LiveChatIcon,
        access: (user) => hasManagerRights() || hasQAAuditorRights()
    },
    {
        title: "Log Out",
        key: "logout",
        icon: LogoutIcon,        
    }
];


const Header = (props) => {
    const [ openChangePassword, toggleChangePassword ] = useState(false);
    const unreadNotificationsCount = useSelector(state => state.notifications.unreadNotificationsCount);
    const _cookies = cookie.loadAll();

    const handleMenuItem = (key) => {
        if(key === "change-password") {
            toggleChangePassword(true);
        }
        else if(key === "logout") {
            logoutUserApi().then((res) => {
                props.logoutUser();
                logoutUser();
                props.history.push("/");
            });            
        }
        else if(key === "contact-us") {
            showSupportForm();
        }
        else if(key === "live-chat") {
            window._chatlio.show({ expanded: true });
        }
        else if(key === "help-centre") {
            zendeskSSO("https://thelevelai.zendesk.com/hc/en-us").then((res) => {
                window.open(res.location, '_blank');
            })
        }
    }
    useEffect(() => {
        document.addEventListener('chatlio.ready', function(e) {
            if(hasManagerRights() || hasQAAuditorRights()) {
                window._chatlio.configure({
                    onlineTitle: "Help ?"
                });
            }
        });
    }, []);
    const showSupportForm = () => {
        window.zE.identify({
            name: `${props.user.first_name} ${props.user.last_name}`,
            email: props.user.email,
            organization: props.user.tenant,
        });
        window.olark && window.olark('api.box.shrink');
        window.zE('webWidget:on', 'close', function() {
            window.zE('webWidget', 'hide');

        });
        window.zE.activate();
    }
    const showRoutePaths = (pathname) => {
        // console.log({ props, pathname });
        return !pathname.includes("setup/metric-tag") && !pathname.includes("conversations/");
    }   

    const tenantNameList = useSelector(state => state.tenant.tenantList)

    const showTenantSwitchDropdown =
      getSchemaName() === "annotation" && tenantNameList?.length > 0;

    return (
      <HeaderWrapper top = {_cookies?.talkdesk_widget_url ? "30px" : "0px"} isVisible={props.showHeader}>
        {/* {
                !(window.location.href.includes("/setup/user-management") || window.location.href.includes("/setup/resources") || window.location.href.includes("/setup/integrations")) && window.location.href.includes("/setup/") ?
                <TrainingStatusWrapper>
                    <p>Last Training: <span id="training-timestamp">N/A</span></p>
                </TrainingStatusWrapper>
                :
                null
            } */}
        {props.user.business_type === 1 &&
        showRoutePaths(props.location.pathname) ? (
          <FilterLabel>
            <span className="filter-title">Workspace</span>
            <WorkspaceFilter
              value={props.user.selectedWorkspace}
              handleChange={props.handleWorkspaceChange}
            />
          </FilterLabel>
        ) : null}
        {showTenantSwitchDropdown && (
          <>
            <Select
              defaultValue="level"
              style={{ width: 120 }}
              onChange={(value) => {
                store.dispatch({
                  type: TenantActionTypes.SET_SCHEMA,
                  payload: value,
                });
              }}
            >
              {tenantNameList.map(tenant => <Option key={tenant} value={tenant}>{tenant}</Option>)}
            </Select>
          </>
        )}
        <Link to="/notifications">
          <StyledBadge size="small" count={unreadNotificationsCount}>
            <NotificationIcon style={{ height: 27, width: 27, marginTop: 5 }} />
          </StyledBadge>
        </Link>
        <UserMenu
          menuItems={UserMenuItems}
          handleMenuItem={handleMenuItem}
          user={props.user}
        >
          <React.Fragment>
            <UserIcon className="user-icon" />
            <UserName>
              {props.user.first_name} {props.user.last_name}
            </UserName>
          </React.Fragment>
        </UserMenu>
        <ModifyPassword
          show={openChangePassword}
          onClose={() => toggleChangePassword(false)}
          onSubmit={() => toggleChangePassword(false)}
        />
      </HeaderWrapper>
    );
}

const mapStateToProps = state => ({ user: state.app_user });

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => {
        dispatch({ 
            type: ReduxActionTypes.LOGOUT_USER,        
        })
        
        dispatch({ 
          type: ReduxActionTypes.SET_REDIRECT,
          payload: "/dashboard"
        });

        dispatch({ 
          type: ReduxActionTypes.RESET_TASK_QUEUE, 
        });
    },
    handleWorkspaceChange: (value) => {
        dispatch({ 
            type: ReduxActionTypes.CHANGE_SELECTED_WORKSPACE,
            selectedWorkspace: value,        
        });
        try {
            setItemInStore('selectedWorkspace', value);
        } catch(err) {}
    },
  })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
