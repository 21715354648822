import React, { useEffect, useState } from "react";
import { InputAdornment } from "@material-ui/core";
import { InputWrapper, InputNumbersWrapper, StyledTextField, Text, ReportView } from '../../wrappers';

const MultiInputComponent = ({ value, handleChange, operator, disabled, unit, showTextOnly }) => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [min] = useState(0);
  const [max] = useState(15000);
  const [_operator, setOperator] = useState("");

  const handleInputChange = (val, inputType) => {
    val = Math.floor(val);
    let valueList = [];
    if(val > max) {
      val = max;
    }
      if (inputType === "input1") {
        if(val > input2) {
          setInput1(input2);
          setInput2(val);
          valueList = [input2, val];
        }
        else {
          setInput1(val);
          valueList = [val, input2];
        }
      } else if (inputType === "input2") {
        if(val < input1) {
          setInput1(val);
          setInput2(input1);
          valueList = [val, input1];
        }
        else {
          setInput2(val);
          valueList = [input1, val];
        }
      }
    if(unit === "mins") {
      valueList = valueList?.map(value => parseInt(value) * 60);
    }
    handleChange(valueList?.length ? valueList.toString() : '');
  };

  const handleSingleInputChange = (val) => {
    let valueList = [];
    val = Math.floor(val);
    if(val > max) {
      val = max;
    }
    setInput1(val);
    if(unit === "mins") {
      val = val * 60;
    }
    if(_operator === "is more than") {
      valueList = [val, 'MAX'];
    }
    else if(_operator === "is less than") {
      valueList = [0, val];
    }
    handleChange(valueList?.length ? valueList.toString() : '');
  }
  useEffect(() => {
    if (value !== null && value !== undefined) {
      if(operator === "is more than" || operator === "is between") {
        setInput1(value?.length ? (unit === "mins" ? parseInt(value[0]) / 60 : value[0]) : "");
      }
      else if(operator === "is less than") {
        setInput1(value?.length ? (unit === "mins" ? parseInt(value[1]) / 60 : value[0]) : "");
      }

      operator === "is between" && setInput2(value?.length ? (unit === "mins" ? parseInt(value[1]) / 60 : value[1]) : "");
    } else {
      setInput1("");
      setInput2("");
    }
    setOperator(operator);
  }, [value, operator, unit]);

  return (
    <>
      <InputWrapper className="d-flex">
        { !showTextOnly ?  <InputNumbersWrapper>
          <StyledTextField
            className="d-flex justify-content-between align-items-center"
            type="number"
            placeholder={operator !== "is between" ? "Enter value" : ""}
            InputProps={{
              inputProps: {
                max: max,
                min: min,
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
            disabled={disabled}
            value={input1}
            style={{
              width: "100%",
              height: "32px",
              padding: "0px 12px",
              background: disabled ? "#f5f5f5" : "#ffffff"
            }}
            onChange={(e) => {
              e.persist();
              _operator === "is between" ? handleInputChange(e.target.value, "input1") : handleSingleInputChange(e.target.value);
            }}
          /> 
        </InputNumbersWrapper> : <ReportView className = "d-flex align-items-center">{input1} {unit}</ReportView>}
        {_operator === "is between" ? (
           !showTextOnly ? <>
            <Text className="d-flex justify-content-center">-</Text>
            <InputNumbersWrapper>
              <StyledTextField
                placeholder={operator !== "is between" ? "Enter value" : ""}
                type="number"
                InputProps={{
                  inputProps: {
                     max: max,
                     min: min,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                }}
                disabled={disabled}
                value={input2}
                style={{ width: "100%", height: "32px", padding: "0px 12px", background: disabled ? "#f5f5f5" : "#fffff" }}
                onChange={(e) => {
                  e.persist();
                  handleInputChange(e.target.value, "input2");
                }}
              />
            </InputNumbersWrapper>
          </> : <ReportView>and {input2} {unit}</ReportView>
        ) : null}
      </InputWrapper>
    </>
  );
};

export default MultiInputComponent;
