import Axios from "axios";
import { axiosInstance, getSchemaName, getToken, BASE_URL } from "../../../api/api";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import { createFilterString } from '../../api/api';
import { ERROR_OPTONS } from "./config";

export const getQaAssignmentTableData = (cancelToken, rest) => () => {
  //const filterString = rest.userFilters ? createFilterString(rest.userFilters) : "";
  return axiosInstance({
    method: "GET",
    url: `/qa/automation/rule/`,
    params: rest.userFilters,
     
    cancelToken: cancelToken?.token
}).then(response => {
  return response.data;
}).catch(err => {
  if (Axios.isCancel(err)) {
    throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
  }
  throw new Error();
});
};

export const deleteQaAssignmentData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "DELETE",
    url: `/qa/automation/rule/${rest.id}/`,
     
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const editQaAssignmentData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "PATCH",
    url: `/qa/automation/rule/${rest.id}/`,
    data: rest.data,
     
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const createRule = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "POST",
    url: `/qa/automation/rule/`,
    data: rest.data,
     
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
};

export const editRule = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "PUT",
    url: `/qa/automation/rule/${rest.id}`,
    data: rest.data,
     
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const getRuleData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/qa/automation/rule/${rest.id}`,
     
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}
