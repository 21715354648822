import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";
import { connect } from "react-redux";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

const AgentsFilter = ({
  value,
  handleChange,
  agentMultiSelect,
  selectedWorkspace,
  segments,
  style = {},
  width,
  disabled = false,
  ...rest
}) => {
  //const [agents, updateAgents] = useState([]);
  //leave single select logic as it is for now
  const [agent, updateAgent] = useState(value);
  const parseUsers = (user) => {
    return {
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    };
  };

  useEffect(() => {
    updateAgent(Array.isArray(value) && value[0] === undefined ? "" : value);
  }, [value]);

  return (
   !agentMultiSelect ? 
   <FilterComponent
            width={width || 180}    
            placeholder={agentMultiSelect ? "Select Agents" : "Select Agent"}
            value={agent}
            maxTagCount= {1}
            showSearchIcon={true}
            capitalize={true}
            handleChange={(value) => {
                updateAgent(value);
                handleChange(value);
            }}
            options={segments? segments.agents?.map(parseUsers) : []}
            showSearch={true}
            hideClear={false}
            disabled = {disabled}
            style = {style}
            {...rest}
        />  :
    <CustomFilterComponent
      width={180}
      placeholder={agentMultiSelect ? "Select Agents" : "Select Agent"}
      value={agent}
      maxTagCount={1}
      multiSelect={true}
      disabled = {disabled}
      handleChange={(value) => {
        updateAgent(value);
        handleChange(value);
      }}
      options={segments ? segments.agents?.map(parseUsers) : []}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.app_user.selectedWorkspace,
  segments: state.dashboard.segments,
});
export default connect(mapStateToProps)(AgentsFilter);
