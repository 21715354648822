import {
    gql
  } from "@apollo/client";


export const QA_SCORES = (schema) => gql`
  fragment QA_SCORES on ${schema}_cfanswer {
    raw_score
    outcome
    max_score
    is_autoscored
    auto_instascore
    auto_max_instascore
    created
  }
`;

export const INSTA_REVIEW = (schema) => gql `
  fragment INSTA_REVIEW on ${schema}_quality_assessment_instareviewtags {
    positive_conversation
    negative_conversation
    rich_conversation
    review_payload
  }
`

export const ACCOUNT_USER_FIELDS = (schema) => gql `
  fragment ACCOUNT_USER_FIELDS on ${schema}_accounts_user {
    id
    first_name
    last_name
    email
    is_active
    accounts_userprofile(where: {deleted: {_is_null: true}}) {
      id
      profile
      deleted
    }
  }
`;

export const COMMON_METRIC_FIELDS = (schema) => gql `
  fragment COMMON_METRIC_FIELDS on ${schema}_metrics {
    id
    assists
    flags
    warnings
    automated_compliance_score
    automated_knowledge_score
    automated_etiquette_score
    handling_time
    asr_log_id
    qa_score
    resource_click_rate
    agent_feedback
  }
`;

export const ASR_LOG_FIELDS = (schema) => gql `
  fragment ASR_LOG_FIELDS on ${schema}_level_asr_asrlog {
    id
    channel
    text
    summary
    version
    created
    custom_fields
  }
`;

export const AUDIO_LOG_FIELDS = (schema) => gql `
  fragment AUDIO_LOG_FIELDS on ${schema}_level_asr_audiolog {
    duration
    file
  }
`;

export const MOMENT_COUNTS = (schema) => gql `
  fragment MOMENT_COUNTS on ${schema}_level_asr_asrlog {
    conversation_tag_count: conversation_tag_count_view {
      conversation_tag_count
    }
    negative_moments: negative_moment_count_view {
      negative_moments
    }
  }
`;
