import React from 'react'
import styled from "@emotion/styled/macro";
import { ReactComponent as UserIcon } from "../../../assets/setup/User.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/setup/Verified.svg";
import moment from "moment";

const CreatedDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 100px;
    svg {
        width: 36px;
        height: 36px;
    }
    .text {
        color: #828282;
        font-size: 12px;
        line-height: 14px;
    }
    .meta-details {
        margin-top: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .details {
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .user-name {
                color: #4F4F4F;
                font-size: 16px;
                line-height: 19px;
                font-weight:  500;
            }
            .created-date {
                color: #828282;
                font-size: 14px;
                line-height: 16px;
                margin-top: 2px;
            }
        }
    }
`;

const CreatedDetails = ({ heading, user, created, isEdited }) => {
    return (
        <CreatedDetailsWrapper>
            <div className="text">{heading}</div>
            <div className="meta-details">
                {isEdited ? <UserIcon /> : <VerifiedIcon />}
                <div className="details">
                    <div className="user-name">{user ? `${user.first_name} ${user.last_name}` : "-"}</div>  
                    {created ? <div className="created-date">{moment(created).format("MM/DD/YY, h:mm a")}</div> : "-"}
                </div>
            </div>
        </CreatedDetailsWrapper>
    )
}

export default CreatedDetails;