import Axios from "axios";
import { axiosInstance, getSchemaName, getToken, BASE_URL } from "../../../api/api";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import { createFilterString } from '../../api/api';
import { ERROR_OPTONS } from "./config";
import appConfigs from '../../../config'; 

export const getSelfServeFilterTableData = (cancelToken, rest) => () => {
  const filterString = rest.userFilters ? createFilterString(rest.userFilters) : "";
  return axiosInstance({
    method: "GET",
    url: `/integration/custom_fields/${
      filterString ? "?" + filterString : ""
    }`,
    cancelToken: cancelToken?.token
}).then(response => {
  return response.data;
}).catch(err => {
  if (Axios.isCancel(err)) {
    throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
  }
  throw new Error();
});
};

export const getDataSources = (cancelToken) => () => {
  return axiosInstance({
    method: "GET",
    url: appConfigs.ENABLE_CUSTOM_FIELDS_V2_API ? `/integration/list/v2/custom_fields/` : `/integration/list/custom_fields/`,
    //cancelToken: cancelToken?.token
}).then(response => {
  return response.data;
}).catch(err => {
  if (Axios.isCancel(err)) {
    throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
  }
  throw new Error();
});
}

export const createExternalData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "POST",
    url: `/integration/custom_fields/`,
    data: rest.data,
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error(err?.response?.data?.non_field_errors ? ERROR_OPTONS.NON_FIELD_ERROR : "");
});
}
export const deleteExternalData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "DELETE",
    url: `/integration/custom_fields/${rest.id}/`,
    data: rest.data,
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const editExternalData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "PATCH",
    url: `/integration/custom_fields/${rest.id}/`,
    data: rest.data,
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}
