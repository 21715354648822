// const categories = [{
//     sections: [{
//         questions: [{
//             options: [2]
//         }]
//     }],
// }]

// const newSection = {
//     questions: [{
//         options: [2]
//     }]
// }

// const newQuestion = {
//     title: "",
//     options: []
// }

export const outcomes = {
    "pass_fail": [
        {
            key: "PASS",
            label: "Pass"
        },
        {
            key: "FAIL",
            label: "Fail"
        },
    ],
    "buckets": [
        {
            key: "OUTSTANDING",
            label: "Outstanding"
        },
        {
            key: "GOOD",
            label: "Good"
        },
        {
            key: "NEEDS IMPROVEMENT",
            label: "Needs Improvement"
        },
        {
            key: "FAIL",
            label: "Fail"
        },
    ],
    "raw": []
}

export const criticalQuestions = [
    {
        key: "auto_fail",
        label: "Auto-Fail"
    },
    {
        key: "zero_out_section",
        label: "Zero Out Section"
    },
]

export const questionScoring = [
    {
        key: 0,
        value: "Normal",
    },
    // {
    //     key: 1,
    //     value: "Critical"
    // }
    {
      key: 2,
      value: "Zero Out Section",
    },

    {
    key: 3,
    value: "Auto-Fail",
    },
]

export const NA = {title: "N/A"}

export const criticalOptions = [
    {
        title: "Pass",
        description: "",
        score: 10,
    },
    {
        title: "Fail",
        description: "",
        score: 0,
    },
]
export const normalOptions = [
  {
      title: "Yes",
      description: "",
      score: 1,
  },
  {
      title: "No",
      description: "",
      score: 0,
  },
]
