import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Modal } from "antd";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    border-bottom: none;
    & .ant-modal-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0015em;
      color: #4f4f4f;
    }
  }

  & .ant-modal-footer {
    border-top: none;
  }

  & .ant-modal-body {
    padding: 0px 24px 20px 25px;
    line-height: 25px;
    letter-spacing: 0.0025em; 
    color: #4F4F4F;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }
`;

const Alert = ({
  cancelButtonName,
  okButtonName,
  heading,
  renderBody,
  onCancelClick,
  onOkClick,
  visible,
  closable,
  isCancelButtonDisabled = false,
  isOkButtonDisabled = false,
  isOkButtonLoading = false,
  okButonStyle = {},
  cancelButtonStyle = {},
  footer = [
    <Button style = {{ background: "#F2F2F2", border: "none", ...cancelButtonStyle }} disabled = {isCancelButtonDisabled} onClick={onCancelClick} type="default">{cancelButtonName}</Button>,
    <Button loading={isOkButtonLoading} style={{ ...okButonStyle }} disabled = {isOkButtonDisabled} onClick={onOkClick} type="primary">{okButtonName}</Button>,
  ],
}) => {
  const [_visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);
  return (
    <StyledModal
      title={heading}
      closable={closable}
      cancelButtonProps = {{ style: { background: "#F2F2F2", border: "none", ...cancelButtonStyle }, disabled: isCancelButtonDisabled }}
      cancelText={cancelButtonName}
      okButtonProps = {{ loading: isOkButtonLoading, style: { ...okButonStyle }, disabled: isOkButtonDisabled }}
      okText={okButtonName}
      onCancel={onCancelClick}
      onOk={onOkClick}
      visible={_visible}
      footer = {footer || null}
    >
      {renderBody ? renderBody() : ""}
    </StyledModal>
  );
};

export default Alert;
