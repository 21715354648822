import React from "react";
import { Link } from "react-router-dom";
import { StyledSwitch } from "../../../components/common/styled-components";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";

export const useColumns = (handleToggle, handleDelete) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "25%",
      render: (value, record) => (
        <Link to={`/setup/automation/create?id=${record.id}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "30%",
      render: (value, record) => record.description,
    },
    {
      title: "Trigger",
      dataIndex: "trigger",
      key: "trigger",
      render: (value, record) => record.trigger_type,
    },
    {
      title: "Enable",
      dataIndex: "enable",
      key: "enable",
      render: (value, record) => {
        return (
          <StyledSwitch
            key={record.id}
            size="small"
            checked={value === undefined ? record.enabled : value}
            onChange={(checked) => handleToggle(checked, record)}
          />
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (value, record) => {
        return (
          <DeleteIcon
            onClick={() => handleDelete(record.id)}
            width="25px"
            height="25px"
            fill="red"
            className="cursor-pointer"
          />
        );
      },
    },
  ];
  return { columns };
};
