import {
  axiosInstance,
  BASE_URL,
  getSchemaName,
  getToken,
} from "../../../api/api";

export const enableWorkflow = (workflow, enable) => {
  return axiosInstance({
    method: "PATCH",
    url: `/automation/${workflow.id}/`,
    data: {
      ...workflow,
      enabled: enable,
    },
    
  });
};

export const deleteWorkflow = (id) => {
  return axiosInstance({
    method: "DELETE",
    url: `/automation/${id}/`,
    
  });
};
