import React from "react";
import { Chart } from "./chart";
import { queryOptions } from './dynamic-query-builder';
import { Constants } from "./query-builder/config";
import { showTeamDashboard, getCurrentUser, role_config, agent_filter } from './utils';

export const ConversationChart = () => {
    const role = showTeamDashboard() ? "manager" : "default";
  
    const custom_filters = showTeamDashboard() ? {
          stackMeasures: [
            Constants.CONVERSATION.unflaggedCount,
            Constants.CONVERSATION.flaggedCount,
          ],
          showPercentOfFilter: true,
        } : {
          agentMultiSelect: false,
          showTimeGranularity: true,
        };
  
    return (
      <Chart
        query={{
          measure: queryOptions.measureOptions.CONVERSATION_COUNT,
          groupBy: queryOptions.groupByOptions.AGENT,
          isTimeSeries: !Boolean(showTeamDashboard()),
          ...custom_filters,
        }}
        title={role_config[role].ConversationChart.title}
        isChartTypeEditable={false}
        filterBy={queryOptions.filterByOptions.NONE}
        chartType={role_config[role].ConversationChart.chart_type}
        drilldownQueries={[]}
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
        />
    );
};
  
export const HandlingTimeChart = () => {
    const role = showTeamDashboard() ? "manager" : "default";
    const custom_filters = showTeamDashboard()
      ? {}
      : {
          agentMultiSelect: false,
        };
  
    return (
      <Chart
        query={{
          measure: queryOptions.measureOptions.AVERAGE_HANDLING_TIME,
          groupBy: queryOptions.groupByOptions.AGENT,
          isTimeSeries: !Boolean(showTeamDashboard()),
          isTimeUnit: true,
          onTimeUnitUpdate: () => {},
          ...custom_filters,
        }}
        title={role_config[role].HandlingTimeChart.title}
        filterBy={queryOptions.filterByOptions.NONE}
        chartType={role_config[role].HandlingTimeChart.chart_type}
        drilldownQueries={[]}
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
      />
    );
};

export const QAEvaluationsChart = () => {
    const role = showTeamDashboard() ? "manager" : "default";
    const custom_filters = showTeamDashboard() ? {
      stackMeasures: [
        Constants.QA_EVALUATIONS.chatCount,
        Constants.QA_EVALUATIONS.callCount,
        Constants.QA_EVALUATIONS.smsCount,
        Constants.QA_EVALUATIONS.emailCount,
        Constants.QA_EVALUATIONS.slackCount
      ],
      showPercentOfFilter: false,
    } : {
      agentMultiSelect: false
    };

    return (
      <Chart
        query={{
          measure: queryOptions.measureOptions.EVALUATIONS,
          groupBy: queryOptions.groupByOptions.AGENT,
          isTimeSeries: !Boolean(showTeamDashboard()),
          isTimeUnit: false,
          onTimeUnitUpdate: () => {},
          disableDrilldown: true,
          ...custom_filters
        }}
        title={"QA Evaluations"}
        filterBy={queryOptions.filterByOptions.NONE}
        chartType={role_config[role].HandlingTimeChart.chart_type}
        drilldownQueries={[]}
        //drilldownBy = { queryOptions.drilldownByOptions.USER }
      />
    );
}
  
export const QAScoreChart = () => {
    const role = showTeamDashboard() ? "manager" : "default";
    const custom_filters = showTeamDashboard()
      ? {} : {
          agentMultiSelect: false,
        };
  
    return (
      <Chart
        query={{
          measure: queryOptions.measureOptions.RAW_SCORE,
          groupBy: queryOptions.groupByOptions.AGENT,
          isTimeSeries: !Boolean(showTeamDashboard()),
          tooltipMeasures: [
            queryOptions.measureOptions.PASS_RATE,
            queryOptions.measureOptions.FAIL_RATE,
            queryOptions.measureOptions.POOR_RATE,
            queryOptions.measureOptions.GOOD_RATE,
            queryOptions.measureOptions.OUTSTANDING_RATE,
            queryOptions.measureOptions.PERCENT_SCORE
          ],
          ...custom_filters,
        }}
        title={role_config[role].QAScoreChart.title}
        filterBy={queryOptions.filterByOptions.NONE}
        chartType={role_config[role].QAScoreChart.chart_type}
        tooltip={queryOptions.tooltipOptions.OUTCOME_PERCENT}
        drilldownQueries={[]}
      />
    );
};
