import produce from "immer";
import { calculateUpdatedTarget, updateIfConditionOnTarget } from "./utils";

export const createNodeUpdateSlice = (set, get) => ({
  updateNode: (updatedData, nodeId, patch, fromNodeType) => {
    const elements = get().elements;
    const selectNode = get().selectNode;
    let nodeIndex = elements.findIndex((ele) => ele.id === nodeId);
    let updatedNode = updatedData;
    if (nodeIndex !== -1) {
      if (!updatedData && patch) {
        set(
          produce((state) => {
            state.elements[nodeIndex] = {
              ...state.elements[nodeIndex],
              ...patch,
            };
            updatedNode = {
              ...state.elements[nodeIndex],
              ...patch,
            };
          })
        );
        return;
      } else {
        set(
          produce((state) => {
            state.elements[nodeIndex] = updatedData;
          })
        );
      }
      // temporary logic needs to be removed
      if (
        elements[nodeIndex].type !== "condition" &&
        fromNodeType !== "condition" &&
        fromNodeType !== "donotselect"
      ) {
        selectNode(elements[nodeIndex].id);
      }
    }
    return updatedNode;
  },
  updateConditionNode: (nodeId, patch) => {
    const elements = get().elements;
    const findTarget = get().findTarget;
    const updateNode = get().updateNode;
    let nodeIndex = elements.findIndex((ele) => ele.id === nodeId);
    let source = elements[nodeIndex];
    source = { ...source, data: { ...source.data, ...patch } };
    set(
      produce((state) => {
        state.elements[nodeIndex] = {
          ...state.elements[nodeIndex],
          data: {
            ...state.elements[nodeIndex].data,
            ...patch,
          },
        };
      })
    );
    let leftNode = findTarget(source, "left");
    let rightNode = findTarget(source, "right");
    if (leftNode) {
      leftNode = updateIfConditionOnTarget("left", source, leftNode);
      updateNode(leftNode, leftNode.id, null, "condition");
    }
    if (rightNode) {
      rightNode = updateIfConditionOnTarget("right", source, rightNode);
      updateNode(rightNode, rightNode.id, null, "condition");
    }
  },
  recursivelyUpdateTargetVariable: (source) => {
    //Need to change this logic temporary fix
    const findTarget = get().findTarget;
    const target = findTarget(source, "bottom");
    const targetLeft = findTarget(source, "left");
    const targetRight = findTarget(source, "right");
    const updateNode = get().updateNode;
    const recursivelyUpdateTargetVariable =
      get().recursivelyUpdateTargetVariable;

    if (target) {
      let updatedTarget = calculateUpdatedTarget(source, target);
      recursivelyUpdateTargetVariable(
        updateNode(updatedTarget, target.id, null, "donotselect")
      );
    }
    if (targetLeft) {
      let updatedTarget = calculateUpdatedTarget(source, targetLeft);
      recursivelyUpdateTargetVariable(
        updateNode(updatedTarget, targetLeft.id, null, "donotselect")
      );
    }
    if (targetRight) {
      let updatedTarget = calculateUpdatedTarget(source, targetRight);
      recursivelyUpdateTargetVariable(
        updateNode(updatedTarget, targetRight.id, null, "donotselect")
      );
    }
  },
  updateGeneratedPayload: (nodeId, generatedPayload) => {
    const findNodeById = get().findNodeById;
    const updateNode = get().updateNode;
    let node = findNodeById(nodeId);
    let recursivelyUpdateTargetVariable = get().recursivelyUpdateTargetVariable;

    node = produce(node, (draft) => {
      draft.data.generatedPayload = { ...generatedPayload };
    });

    updateNode(node, nodeId);
    recursivelyUpdateTargetVariable(node);
  },
});
