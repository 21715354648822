import React from "react";
import { StyledTable, StyledSwitch, TitleDescriptionWrapper } from "./../../../components/common/styled-components";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
// import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import ActionMenu from "./../commons/ActionMenu";
import { LockOutlined } from '@ant-design/icons';
import styled from "@emotion/styled/macro";
import { UserRoles } from './config';
import { hasManagerRights } from '../../../AnalyticsDashboard/config';

const StyledLockOutlined = styled(LockOutlined)`
  &&& svg {
    fill: #BDBDBD;
    width: 12px;
    height: 12px;
    position: relative;
    left: 2px;
    margin-right: 4px;
  }
`;

const UsersList = ({tableData, isLoading, editProfile, updateUser, deleteUser, resendPassword, roles, viewOnly }) => {
  const columns = [
    {
      title: "User Name",
      dataIndex: 'user_name',
      key: 'user_name',
      width: "45%",
      render: (value, record) => {
        return (
            <TitleDescriptionWrapper onClick={() => editProfile(record)}>
                <div className="rule-title">{record.user_name}</div>
            </TitleDescriptionWrapper>
        )
    }
    },
    {
      title: 'Roles',
      dataIndex: 'groups',
      key: 'groups',
      width: "30%",
      render: (text, record) => {
        const roleDisplayNames = record.role.map(parentItem => roles.find(childItem => parentItem === childItem.value) ?.label).join(', ');
        return roleDisplayNames;
      }
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: "20%",
      render: (value, record) => {
        return (
          <StyledSwitch 
            size="small" 
            disabled = {viewOnly}
            checked={value === undefined ? true : value} 
            onChange={(checked) => {
              if(viewOnly) {
                return;
              }
              const { first_name, last_name, email, id, groups, client_name, role } = record;
                const userData = {
                  first_name,
                  last_name,
                  role,
                  email,
                  id,
                  is_active: checked,
                  client_name: client_name, 
                  add_to_twilio: client_name ? true : false,                  
                };
                updateUser(userData);
            }}
          />
        );
      },          
    },
    {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "5%",
        render: (value, record) => {
          return (
            !viewOnly && <ActionMenu 
              menuItems={[
                {
                  title: "Edit User",
                  key: "edit",
                  icon: EditIcon,
                },
                // {
                //   title: "Delete User",
                //   key: "delete",
                //   icon: DeleteIcon,
                // },
                {
                  title: "Resend Password",
                  key: "resend",
                  icon: StyledLockOutlined
                }
              ]}
              handleMenuClick={(key) => {
                if(key === "delete") { 
                  deleteUser(record);
                }
                else if(key === "edit") {
                  editProfile({...record});
                }
                else if(key === "resend") {
                  resendPassword(record);
                }
              }}
            />
          )
        }
      }  
  ];
  return (
    <StyledTable 
      dataSource={tableData} 
      columns={columns} 
      loading={isLoading} 
      rowKey="id" 
      scroll={{y: "calc(100vh - 290px)"}}  
      pagination={false} 
    />    
  )      
}

export default UsersList;