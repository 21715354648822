import {
    axiosInstance
} from '../../api/api';

export const createFeedback = (data) => {
  return axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/feedback/`,
    data
  });
};

export const createMomentFeedback = (data) => {
  return axiosInstance({
    method: "POST",
    url: `/level-nlp/moments/feedback/`,
    data
  });
};

export const updateFeedback = (data, id) => {
  return axiosInstance({
    method: "PUT",
    url: `/level-nlp/v2/feedback/${id}`,
    data
  });
};

export const updateMomentFeedback = (data, id) => {
  return axiosInstance({
    method: "PUT",
    url: `/level-nlp/moments/feedback/${id}`,
    data,
     
  });
};

export const deleteFeedback = (id) => {
  return axiosInstance({
    method: "DELETE",
    url: `/level-nlp/feedback/retrieve-destroy/${id}`,
     
  });
};

export const conversationAnalysis = (id) => {
  return axiosInstance({
    method: "GET",
    url: `/banking/analyze/${id}`,
     
  });
};

export const getConversationData = (platform, platformId) => {
  return axiosInstance({
    method: "GET",
    url: `/banking/v2/multitab/${platform}/${platformId}/`,
  });
}

export const getLayoutData = async () => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/layout/`,
     
  });
  return response;
}
