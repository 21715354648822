import React from "react";
import { StyledSwitch } from "../../../../components/common/styled-components";

export const useColumns = (handleToggle) => {
  const columns = [
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      width: "65%",
      render: (value, record) => {
        switch (record.user_type) {
          case "ADMIN":
            return "Admin";
          case "MANAGER":
            return "Manager";
          case "QA_AUDITOR":
            return "QA Auditor";
          case "AGENT":
            return "Agent";
          default:
            return record.user_type;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "35%",
      render: (value, record) => {
        return (
          <StyledSwitch
            key={record.id}
            size="small"
            checked={value === undefined ? record.send_notification : value}
            onChange={(checked) => handleToggle(checked, record)}
          />
        );
      },
    },
  ];
  return { columns };
};
