import styled from "@emotion/styled/macro";
import React, { useEffect, useState } from "react";
import { ChartDashboard } from "./chart.js";
import SetupHeader from "../CompanySetup/components/commons/SetupHeader";
import { PrimaryStyledButton } from "../components/common/styled-components";
import {
  getDashboard,
  removeFromDashboard,
} from "../AnalyticsDashboard/api.js";
import ActionMenu from "../CompanySetup/components/commons/ActionMenu.js";
import { ReactComponent as DeleteIcon } from "../CompanySetup/assets/delete-icon.svg";
import { ReactComponent as EditIcon } from "../CompanySetup/assets/edit-icon.svg";
import { ReactComponent as ShareIcon } from "../CompanySetup/assets/share.svg";
import { Spin } from "antd";
import DateFilter from "../components/common/DateFilter.js";
import { ReduxActionTypes } from "../constants/ReduxActionConstants.js";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import ShareQueryChart from "../AnalyticsDashboard/components/query-chart-share.js";
import {
  SpaceBetweenWrapper,
  LineSeparator,
} from "../CompanySetup/components/commons/wrappers.js";
import EmptyState from "../components/common/EmptyState.js";
import { EmptyStateWrapper } from "../AnalyticsDashboard/components/wrapper.js";
import Constants from "../CompanySetup/utils/constants.js";
import { usePagination2 } from "../custom-hooks/usePagination2.js";

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 42px);
  width: 100%;
  padding: 20px;
  overflow-y: auto;
`;

const DashboardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 96%;
`;
const EmptyStatesWrapper = styled(EmptyStateWrapper)`
  height: 65vh;
  width: 100%;
`;
const GlobalDashboard = (props) => {
  const padding = "20px 20px 0px 20px";
  const width = "47%";
  const margin = "20px 20px 20px 0px";
  const [charts, setCharts] = useState();
  const [dateFilter, setDateFilter] = useState("30");
  const [selectedChart, setSelectedChart] = useState(null);
  const [openChartShareModal, setOpenChartShareModal] = useState(false);
  const [reseting, setResseting] = useState(false);

  const getCharts = (page, cb, reset) => {
    let pg = page;
    if (reset) {
      setResseting(true);
      pg = 1;
    }
    getDashboard(pg).then((res) => {
      if (res.next === null) {
        stopPagination("custom-dashboard");
      }
      if (reset) {
        setCharts(res.results || []);
        setResseting(false);
      } else {
        setCharts((p) => {
          if (!p || !p.length) {
            return res.results || [];
          }
          return [...p, ...res.results];
        });
      }
      cb && cb();
    });
  };

  const { startPagination, stopPagination, loading } =
    usePagination2(getCharts);

  const history = useHistory();

  const onItemShare = (chart) => {
    setSelectedChart({ ...chart, id: chart.query, title: chart.title });
    setOpenChartShareModal(true);
  };

  useEffect(() => {
    startPagination("custom-dashboard");
  }, []);

  return (
    <DashboardContainer id="custom-dashboard">
      <div>
        <DashboardHeaderWrapper>
          <SetupHeader
            title="Custom Dashboard"
            icon="user"
            description="Add charts to your custom dashboard"
            breadCrumbItems={[]}
            openInfo={() => this.setState({ showInfo: true })}
          />
        </DashboardHeaderWrapper>
        <br />
        <SpaceBetweenWrapper>
          <DateFilter
            value={dateFilter}
            handleChange={(value) => {
              setDateFilter(value);
            }}
          />
          <PrimaryStyledButton
            type="primary"
            onClick={() => {
              history.push(`/analytics/query-builder/?newDashboardChart=true`);
            }}
          >
            + Add Chart
          </PrimaryStyledButton>
        </SpaceBetweenWrapper>
        <br />
        <LineSeparator />
      </div>
      {reseting ? (
        <SpinnerWrapper>
          <Spin />
        </SpinnerWrapper>
      ) : (
        <DashboardWrapper>
          {openChartShareModal && (
            <ShareQueryChart
              title={selectedChart.title}
              chartId={selectedChart.id}
              openChartShareModal={openChartShareModal}
              setOpenChartShareModal={setOpenChartShareModal}
            />
          )}

          {charts?.length
            ? charts.map((chart) => (
                <ChartDashboard
                  key={chart.id}
                  chartId={chart.query}
                  width={width}
                  padding={padding}
                  margin={margin}
                  dateFilter={dateFilter}
                  actionMenu={() => (
                    <ActionMenu
                      menuItems={[
                        {
                          title: "Edit",
                          key: "edit",
                          icon: EditIcon,
                        },
                        {
                          title: "Share",
                          key: "share",
                          icon: ShareIcon,
                        },
                        {
                          title: "Remove",
                          key: "delete",
                          icon: DeleteIcon,
                          hoverColor: "#ff4d4f",
                          isPopConfirm: true,
                        },
                      ]}
                      handleMenuClick={(key) => {
                        if (key === "edit") {
                          history.push(
                            `/analytics/query-builder/${chart.query}/?isDashboardEdit=true`
                          );
                        } else if (key === "delete") {
                          removeFromDashboard(chart.id).then((res) => {
                            getCharts(null, null, true);
                          });
                        } else if (key == "share") {
                          onItemShare(chart);
                        }
                      }}
                    />
                  )}
                ></ChartDashboard>
              ))
            : charts &&
              charts.length === 0 && (
                <EmptyStatesWrapper>
                  <EmptyState
                    type={Constants.EMPTY_STATE_VIEWS.CUSTOM_DASHBOARD_VIEW}
                    parentText="Add Charts to the dashboard"
                    link="/analytics/query-builder"
                  />
                </EmptyStatesWrapper>
              )}
          {loading && (
            <SpinnerWrapper>
              <Spin />
            </SpinnerWrapper>
          )}
        </DashboardWrapper>
      )}
    </DashboardContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setIsDashboardEdit: () => {
    dispatch({
      type: ReduxActionTypes.CHART_DASHBOARD_EDIT,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GlobalDashboard));
