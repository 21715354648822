import React, { useState } from "react";
import { PrimaryStyledButton } from "../../components/common/styled-components";
import { message } from "antd";
import { debounce } from "lodash/debounce";
import { createSharingReport } from "../api";
import { ReactComponent as MailIcon } from "../../assets/ShareReportMailIcon.svg";
import { useSelector } from "../../store";
import ShareReportModal from "../../components/common/ShareReportModal";

const ShareReport = (props) => {
  const [openReportShareModal, setOpenReportShareModal] = useState(false);
  const filters = useSelector((state) => state.dashboard.globalFilters);

  // const onChange = ({ id, emails }, text) => {
  //   let updatedEmails = emails.map((item) => {
  //     if (item.id === id) {
  //       item.text = text;
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setEmails(updatedEmails);
  // };
  // const debounceHandleChange = useCallback(debounce(onChange, 800), []);
  const onCreate = (data) => {
    const _filters = {};
    filters && Object.entries(filters).forEach(([key, value]) => {
      if(value && value.length) {
        _filters[key] = value;
      }
    });
    let reportData = {
      report_name: data.title,
      frequency: data.frequency,
      sender: props.user.id,
      is_active: true,
      recipients: data.recipients,
      filter_set: _filters,
      created_from: window.location.pathname,
    };
    createSharingReport(reportData)
      .then((res) => {
        message.success("Report Shared Successfully!");
        setOpenReportShareModal(false);
      })
      .catch((err) => {
        message.error(`${err.message}`);
      });
  };

  return (
    <>
      <PrimaryStyledButton
        className="share-report-button"
        style={{ marginTop: "8px" }}
        onClick={() => setOpenReportShareModal(true)}
      >
        <MailIcon style={{ marginRight: "5px" }} /> Share
      </PrimaryStyledButton>

      {openReportShareModal && (
        <ShareReportModal
          onCreate={onCreate}
          visible={openReportShareModal}
          closeModal={() => setOpenReportShareModal(false)}
        />
      )}
    </>
  );
};

export default ShareReport;
