import React, { useReducer } from 'react'
import { Button } from 'antd';
import ValidationInput from '../../../components/common/ValidationInput';
import { getInitialState, popupActions, popupReducer } from "./reducer";
import styled from "@emotion/styled/macro";
import { addConversationTag } from '../../api/api';
import { TagTypes } from "../conversation-tags/config";
import { connect } from "react-redux";
//import moment from "moment";

const RuleTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    margin: 16px 24px 24px;
    input {
        height: 36px;
        padding: 4px 10px;
        border: 1px solid #BDBDBD;
        margin-top: 8px;
        font-size: 16px;
        color: #4F4F4F;
    }
`;

const LineSeparator = styled.div`
    width: 100%;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

const PopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InnerDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 210px;
    padding: 16px 16px 0px;
    margin-bottom: 0;    
`;

const TagTypePropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        margin-left: 24px;
        text-align: left;
    }
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px;
    .buttons {
        display: flex;
        align-items: center;
        button {
            width: 106px;
        }
        button.submit {
            margin-left: 4px;            
        }
    }
`;

const TagTypeWrapper = styled.div`
    cursor: pointer;
    width: 248px;
    height: 187px;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid ${props => props.borderColor};
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6px;
    .tag-type-title {
        margin-top: 8px;
        color: #4F4F4F;
        font-size: 16px;
        line-height: 19px;
    }
`;

const TagType = ({ selected, label,  value, onSelect, icon: TagTypeIcon }) => {
    return (
        <TagTypeWrapper borderColor={selected ? "#27AE60" : "#BDBDBD"} onClick={() => onSelect(value)}>
            <TagTypeIcon />
            <div className="tag-type-title">{label}</div>
        </TagTypeWrapper>
    )
}

const ConversationTag = (props) => {
    const [ ruleData , dispatch ] = useReducer(popupReducer, getInitialState(props.data));
    const handleChange = (e) => {
        switch(e.currentTarget.name) {
            case "title": 
                popupActions.setTitle(e.currentTarget.value, dispatch)
                break;
            case "description": 
                popupActions.setDescription(e.currentTarget.value, dispatch)
                break;
            default: 
                break;
        }        
    }
    const selectTagType = (tagType) => {
        popupActions.setTagType(tagType, dispatch);
    }
    const validate = () => {
        let errors = [];
        const { title, description, tagType } = ruleData;
        if(title === "") {
            errors.push("title")
        }
        if(description === "") {
            errors.push("description")
        }
        if (tagType === "") {
            errors.push("tagType")
        }
        return errors;
    }
    const onSubmit = () => {
        const { title, description, tagType } = ruleData;
        const data = { 
            rule_json: { 
                title, 
                description,
                tagType,
            }
        };
        const errors = validate();
        if(errors.length === 0) {
            addConversationTag(data).then((res) => {
                props.onSubmit({
                    ...res
                });
            }, (err) => {
                console.log({ err });
            });
        }
        else {
            popupActions.setErrors(errors, dispatch);
        }
    }

    const { title, description, tagType, errors } = ruleData;
    return (
        <PopupWrapper>
            <RuleTitleWrapper>
                <ValidationInput 
                    placeholder="Name" 
                    name="title" 
                    onChange={handleChange} 
                    defaultValue={title}
                    value={title} 
                    error={errors} 
                />         
                <ValidationInput 
                    placeholder="Description" 
                    name="description" 
                    onChange={handleChange} 
                    defaultValue={description}
                    value={description} 
                    error={errors} 
                />       
            </RuleTitleWrapper>
            <LineSeparator />
            <TagTypePropertyWrapper>
                <InnerDetailsWrapper>
                    {TagTypes.map((item, index) => <TagType selected={tagType === item.value} key={index} {...item} onSelect={selectTagType} />)}
                </InnerDetailsWrapper>
                {errors.includes("tagType") ? <div className="error-message">Please choose tag type</div> : null}
            </TagTypePropertyWrapper>
            <ActionButtonsWrapper>
                <div className="buttons">
                    <Button style={{float: "right"}} onClick={props.onClose}>Cancel</Button>                
                    <Button style={{float: "right"}} className="submit" onClick={onSubmit}>Build</Button>                    
                </div>
            </ActionButtonsWrapper>                           
        </PopupWrapper>
    )
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps)(ConversationTag);