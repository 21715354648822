import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StyledTable } from "../../components/common/styled-components";
import { SetupWrapper } from "./../../CompanySetup/components/commons/wrappers";
import SetupHeader from "./../../CompanySetup/components/commons/SetupHeader";
import { getSavedQueries, deleteSavedQuery } from "../api";
import { Link } from "react-router-dom";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../CompanySetup/assets/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../CompanySetup/assets/edit-icon.svg";
import { ReactComponent as ShareIcon } from "../../CompanySetup/assets/share.svg";
import { message } from "antd";
import ActionMenu from "../../CompanySetup/components/commons/ActionMenu";
import ShareQueryChart from "../components/query-chart-share";

const onItemDelete = (record, onDelete) => {
  deleteSavedQuery(record.id).then(() => {
    message.success(`${record.title} Deleted Successfully`);
    onDelete();
  });
};

export const QueriesList = (props) => {
  const [dataSource, setdataSource] = useState([]);
  const [loading, setloading] = useState(false);
  const [openChartShareModal, setOpenChartShareModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const history = useHistory();

  const onItemClick = (record) => {
    history.push(`/analytics/query-builder/${record.id}`);
  };
  const onItemShare = (chart) => {
    setSelectedChart(chart);
    setOpenChartShareModal(true);
  };

  const reload = () => {
    setloading(true);
    getSavedQueries().then((res) => {
      setdataSource(res.results);
      setloading(false);
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => {
        return (
          <Link to={`/analytics/query-builder/${record.id}`}>{value}</Link>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      render: (value, record, index) => {
        return moment(record.created).format("MM/DD/YY, h:mm a");
      },
    },
    {
      title: "Created By",
      dataIndex: "user",
      key: "user",
      render: (value, record, index) => {
        return `${value.first_name} ${value.last_name}`;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (value, record, index) => {
        return (
          <ActionMenu
            menuItems={[
              {
                title: "Edit",
                key: "edit",
                icon: EditIcon,
              },
              {
                title: "Share",
                key: "share",
                icon: ShareIcon,
              },
              {
                title: "Delete",
                key: "delete",
                icon: DeleteIcon,
                hoverColor: "#ff4d4f",
                isPopConfirm: true,
              },
            ]}
            handleMenuClick={(key) => {
              if (key === "edit") {
                onItemClick(record);
              } else if (key === "delete") {
                onItemDelete(record, reload);
              } else if (key == "share") {
                onItemShare(record);
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    reload();
  }, []);

  return (
    <SetupWrapper className="setup-tab-user">
      <SetupHeader
        title="Saved Queries"
        icon="user"
        description="Save and organize your queries."
      />
      <br />
      {openChartShareModal && (
        <ShareQueryChart
          title={selectedChart.title}
          chartId={selectedChart.id}
          openChartShareModal={openChartShareModal}
          setOpenChartShareModal={setOpenChartShareModal}
        />
      )}

      <StyledTable
        loading={loading}
        dataSource={dataSource}
        columns={columns}
      />
    </SetupWrapper>
  );
};
