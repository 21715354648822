import React from "react";
import GenericNode from "./genericNode";
import { StyledNodeHead } from "./styles";
import { ReactComponent as PlayIcon } from "../../assets/play.svg";

const TriggerNode = ({ data }) => {
  return (
    <GenericNode nodeData={data} nodeType="trigger" noInput={true}>
      <StyledNodeHead>
        <div className="start">
          <PlayIcon />
        </div>
        {data.display_name}
      </StyledNodeHead>
    </GenericNode>
  );
};

export default TriggerNode;
