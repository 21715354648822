import React from "react";
import GenericNode from "./genericNode";
import { StyledNodeHead } from "./styles";
import { ReactComponent as OperationIcon } from "../../assets/operation.svg";

const OperationNode = ({ data }) => {
  return (
    <GenericNode nodeData={data} nodeType="operation">
      <StyledNodeHead>
        <div className="operation">
          <OperationIcon />
        </div>
        {data.display_name}
      </StyledNodeHead>
    </GenericNode>
  );
};

export default OperationNode;
