export const formatDate = (date) => {
  let new_date = new Date(date);
  return months[new_date.getMonth()] + " " + new_date.getDate();
};

export const formatFullDate = (date) => {
  let new_date = new Date(date);
  return (
    months[new_date.getMonth()] +
    " " +
    new_date.getDate() +
    " " +
    new_date.getFullYear()
  );
};

export const formatTime = (date) => {
  date = new Date(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const isTodaysDate = (date) => {
  return formatFullDate(new Date()) === formatFullDate(date);
};

export const secondsToTime = (secs, readable) => {
  if (!secs) {
    return "00:00:00";
  }
  var hours = Math.floor(secs / (60 * 60));
  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);
  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);
  if (readable) {
    return `${hours ? hours + ":" : ""}${minutes}:${seconds} mins`;
  }
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const addAnalyticsEvent = (event, data) => {
  if (window.heap && window.heap.track) {
    window.heap.track(event, data);
  } else {
    document.addEventListener("heap.ready", (e) => {
      window.heap.track(event, data);
    });
  }
};

export const validateEmail = (validateEmail) => {
  const checkValidationStatus = [];
  const EmailValidateRe =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  validateEmail.map((item) =>
    checkValidationStatus.push(EmailValidateRe.test(item))
  );
  if (checkValidationStatus.includes(false)) {
    return false;
  } else {
    return true;
  }
};

export const throttle = (callback, limit) => {
  var waiting = false;
  return function () {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  };
};

export const debounce = (callback, limit) => {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, limit);
  };
};
export const euclideanDistance = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

// Function to add pollyfills
export const addPollyfills = () => {
  Object.compare = function (obj1, obj2) {
    if (!obj1 && !obj2) {
      return true;
    }
    if (!obj1 || !obj2) {
      return false;
    }
    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

      switch (typeof obj1[p]) {
        //Deep compare objects
        case "object":
          if (!Object.compare(obj1[p], obj2[p])) return false;
          break;
        //Compare function code
        case "function":
          if (
            typeof obj2[p] == "undefined" ||
            (p != "compare" && obj1[p].toString() != obj2[p].toString())
          )
            return false;
          break;
        //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
      }
    }

    //Check object 2 for any extra properties
    for (var p in obj2) {
      if (typeof obj1[p] == "undefined") return false;
    }
    return true;
  };
};

export const setItemInStore = (key,data,useLocal=false) => {
  // encrypt data before storing
  let storage = useLocal  ? localStorage : sessionStorage
  let stringData = JSON.stringify(data);
  storage.setItem(key,stringData);

  return stringData
}

export const getItemFromStore = (key,useLocal=false) => {
  // decrypt data after fetching
  let storage = useLocal  ? localStorage : sessionStorage
  let data = storage.getItem(key);
  if(!data) {
    return null;
  }
  return JSON.parse(data);
}

export const removeItemFromStore = (key,useLocal=false) => {
  let storage = useLocal  ? localStorage : sessionStorage
  let item = getItemFromStore(key,useLocal);
  storage.removeItem(key);
  return item;
}

export const toTitleCase = (phrase) => {
  if(!phrase) {
    return "";
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatTimeDuration = (time) => {
  if(time < 60) {
    return time + 'seconds'
  }
  else if(time < 3600) {
    return (time/60).toFixed(2) + 'mins'
  }
  else {
    return (time/3600).toFixed(2) + 'hours'
  }
}
