import React from "react";
import View from "../../AnalyticsDashboard/query-builder/view";
import { ScreenRecordingNewTab } from "../../conversation-details/ScreenRecording";
import GlobalDashboard from "../../GlobalDashboard";
import { InAppNotifications } from "../../InAppNotifications";
import { componentLoader } from "../utilities";
const DetailTable = React.lazy(() =>
  componentLoader(() => import("../../Dashboard/components/DetailTable"))
);
const UserDashboard = React.lazy(() =>
  componentLoader(() => import("../../user-dashboard"))
);
const TaskQueue = React.lazy(() =>
  componentLoader(() => import("../../user-dashboard/TaskQueue"))
);
const KeywordAnalysis = React.lazy(() =>
  componentLoader(() => import("../../keyword/analysis"))
);
const KeywordDrillDown = React.lazy(() =>
  componentLoader(() => import("../../keyword/drilldown"))
);
const SearchConversationList = React.lazy(() =>
  componentLoader(() => import("../../conversation-list/transcript-search"))
);
const ConversationList = React.lazy(() =>
  componentLoader(() => import("../../conversation-list"))
);
const ConversationDetails = React.lazy(() =>
  componentLoader(() => import("../../conversation-details"))
);
const LiveAssist = React.lazy(() =>
  componentLoader(() => import("../../bankingAssist"))
);
const dashboard = React.lazy(() =>
  componentLoader(() => import("../../AnalyticsDashboard/components"))
);
const ConversationFeedbackList = React.lazy(() =>
  componentLoader(() => import("../../feedback"))
);
const GoogleDriveIntegrationSuccess = React.lazy(() =>
  componentLoader(() =>
    import(
      "../../CompanySetup/components/integration/GoogleDriveIntegrationSuccess"
    )
  )
);
const SlackIntegrationSuccess = React.lazy(() =>
  componentLoader(() =>
    import("../../CompanySetup/components/integration/SlackIntegrationSuccess")
  )
);
const CompanySetup = React.lazy(() =>
  componentLoader(() => import("../../CompanySetup/components"))
);
const DebugConversations = React.lazy(() =>
  componentLoader(() =>
    import("../../Dashboard/components/DebugConversationsList")
  )
);
const ConversationReplay = React.lazy(() =>
  componentLoader(() => import("../../Dashboard/components/ConversationReplay"))
);
const ConversationDebugLogs = React.lazy(() =>
  componentLoader(() =>
    import("../../Dashboard/components/ConversationDebugLogs")
  )
);
const QaCalibration = React.lazy(() =>
  componentLoader(() => import("../../QaCalibration"))
);

const QaCalibrationReportView = React.lazy(() =>
  componentLoader(() => import("../../QaCalibration/components/ReportView/index"))
);

const DetailsTable = (props) => (
  <DetailTable {...props} title="Interaction History" />
);
const ReasonDetailsTable = (props) => (
  <DetailTable {...props} title="Flag Drilldown" />
);
const TopicDetailsTable = (props) => (
  <DetailTable {...props} title="Topic Drilldown" />
);
const ConversationTagsTable = (props) => (
  <DetailTable {...props} title="Conversation Tags Drilldown" />
);

export const protectedRoutes = [
  {
    exact: true,
    path: "/notifications/",
    component: InAppNotifications,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations/advisor/:id",
    component: DetailsTable,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations/reason/:id",
    component: ReasonDetailsTable,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations/topics/:id",
    component: TopicDetailsTable,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations/conversation-tag/:id",
    component: ConversationTagsTable,
    showHeader: true,
  },
  {
    exact: true,
    path: "/dashboard",
    component: UserDashboard,
    showHeader: true,
  },
  {
    exact: true,
    path: "/dashboard/tasks",
    component: TaskQueue,
    showHeader: true,
  },
  {
    exact: true,
    path: "/tasks/conversation/:id",
    component: ConversationDetails,
    showHeader: true,
  },
  {
    exact: true,
    path: "/keyword-analysis",
    component: KeywordAnalysis,
    showHeader: true,
  },
  {
    exact: true,
    path: "/keyword-drilldown/:id",
    component: KeywordDrillDown,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversation/transcript-search/:search",
    component: SearchConversationList,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations",
    component: ConversationList,
    showHeader: true,
  },
  {
    exact: true,
    path: "/conversations/:id",
    component: ConversationDetails,
    showHeader: true,
  },
  {
    exact: true,
    path: "/live-assist",
    component: LiveAssist,
    showHeader: true,
  },
  {
    exact: true,
    path: "/v2/dashboard",
    component: GlobalDashboard,
    showHeader: true,
  },
  {
    exact: true,
    path: "/analytics/:type?/:id?",
    component: dashboard,
    showHeader: true,
  },
  {
    exact: true,
    path: "/feedback",
    component: ConversationFeedbackList,
    showHeader: true,
  },
  {
    exact: true,
    path: "/oauth2/callback",
    component: GoogleDriveIntegrationSuccess,
    showHeader: true,
  },
  {
    exact: true,
    path: "/slack/oauth/",
    component: SlackIntegrationSuccess,
    showHeader: true,
  },
  {
    exact: true,
    path: "/setup/:type?/:subtType?/:id?",
    component: CompanySetup,
    showHeader: true,
  },
  {
    exact: true,
    path: "/query-builder/view/:id",
    component: View,
    showHeader: true,
  },
  {
    exact: true,
    path: "/debug",
    component: DebugConversations,
    showHeader: true,
  },
  {
    exact: true,
    path: "/debug/conversation-replay",
    component: ConversationReplay,
    showHeader: true,
  },
  {
    exact: true,
    path: "/debug/:id",
    component: ConversationDebugLogs,
    showHeader: true,
  },
  {
    exact: true,
    path: "/qa-calibrations",
    component: QaCalibration,
    showHeader: true,
  },
  {
    exact: true,
    path: "/qa-calibrations/:id",
    component: QaCalibrationReportView,
    showHeader: true,
  },
  {
    exact: true,
    path: "/qa-calibrations/response/:id",
    component: ConversationDetails,
    showHeader: true,
  },
  {
    exact: true,
    path: "/screen-recording/:convoId/:agentId/:caseId/",
    component: ScreenRecordingNewTab,
    showHeader: false,
  },
];
