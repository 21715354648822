import React from "react";
import styled from "@emotion/styled/macro";
import { addAnalyticsEvent } from "../utils";
import { StyledLinkButton } from "../components/common/styled-components"; 
import StringShortener from "../components/common/StringShortener";

const SalesforceRecentCasesWrapper = styled.div`
    width: 100%;
    p {
        color:#828282; font-size: 12px;
    }
    .sf-account-limit-alert {
        color: #828282;        
        margin: 0;
        line-height: 14px;
    }
    .sf-note {
        margin: 6px 0;    
        .sf-note-title {
            color: #828282; 
            font-size: 12px; 
            line-height: 14px;
            word-break: break-word;
        }    
        .sf-note-description {
            color: #828282; 
            font-size: 12px; 
            line-height: 14px;
            margin-top: 12px;
            word-break: break-word;
        }
    }
    .snowflake-data {
        font-size: 12px;
        text-transform: capitalize;
        margin-top: 4px;
        color: #828282;
        span {
            color: #4F4F4F;
        }
    }
    .string-shortener-wrapper {
        font-size: 12px;
        color: rgb(130, 130, 130);
    }
`;

const RenderCasesWrapper = styled.figure`
    margin: 16px 0; 
    width: 100%;
    figcaption {
        color:#BDBDBD; 
        font-size: 10px;
        text-transform: uppercase;
    }
    ul {
        list-style-type:none; 
        margin: 6px 0; 
        padding: 0;
        li {
            margin: 6px 0 0; 
            display: flex; 
            align-items: center;
            img {
                width: 12px;
            }
            .link {
                color: #6099D9; 
                font-size: 12px; 
                line-height: 14px; 
                margin-left: 8px; 
                text-decoration: none; 
                white-space: nowrap; 
                overflow: hidden; 
                text-overflow: ellipsis; 
                width: calc(100% - 20px);
                display: inline-block;
                cursor: pointer;
            }
        }
    }        
`;

const StyledAnchor = styled.span`
    font-size: 12px;
    line-height: 14px;
    text-decoration: underline;
    margin: 0;
    white-space: normal;
    color: #6099D9;
    &:hover {
        color: #6099D9;
        text-decoration: none;
    }  
    &:visited {
        color: #BB6BD9;
    }
`; 

const getReadableString = (str) => {
    return str.replace(/_/g," ").replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
}

const SnowFlakeCard = ({ title,category,data, conversationId, conversation_status }) => {
    const [isCollapsed, setCollapsed] = React.useState(true);
    const items = Object.keys(data);
    const cardItems = isCollapsed ? [items[0], items[1]] : [...items];
    const handleCollapseClick = () => {
        if(isCollapsed) {
          addAnalyticsEvent('Live Conversation', { 
            conversationId: conversationId, 
            type: "CLICK_MORE", 
            title: title,
            category: category,
            conversationStatus: conversation_status,
          });
            // addAnalyticsEvent('Live Conversation', { conversationId: conversationId, isLiveConversation: conversation_status === "1", conversationStatus: conversation_status, type: "SHOW_MORE_SNOWFLAKE_DATA_POINTS" });
        }
        setCollapsed(!isCollapsed);
    }
    return (
        <SalesforceRecentCasesWrapper>
            {cardItems.map((item, index) => {
                const dataKey = getReadableString(item);
                if(data[item] === "None") return null;
                const dataValue = getReadableString(data[item]);
                if(!isNaN(dataValue)) {
                    return <div key={index} className="snowflake-data">{dataKey}: <span>{Number(dataValue).toFixed(2)}</span></div>;
                }
                return (
                    <div key={index} className="snowflake-data">{dataKey}: <span>{dataValue}</span></div>                            
                );
            })}  
            <StyledLinkButton type="link" onClick={handleCollapseClick}>{isCollapsed ? "More" : "Less"}</StyledLinkButton>            
        </SalesforceRecentCasesWrapper>
    )
}

const RenderCases = ({ data, title, isAgent, eventName, conversationId, isLiveConversation, conversation_status }) => {
    const openTab = (link) => {
        addAnalyticsEvent('Live Conversation', { conversationId: conversationId, isLiveConversation: conversation_status === "1", conversationStatus: conversation_status, type: "OPEN_RECENT_CASE" });   
        //eslint-disable-next-line
        parent.postMessage(link, "*");        
    };
    const onLinkClick = (subject) => {
      addAnalyticsEvent('Live Conversation', { conversationId: conversationId, isLiveConversation: conversation_status === "1", conversationStatus: conversation_status,type:'RECENT_CASE' , subject:subject});   
    }
    return (
        <RenderCasesWrapper>
            <figcaption>{title}</figcaption>
            <ul>
                {data.map((item, index) => {
                    return (
                        <li key={index}>
                            <img alt="icon" src="https://res.cloudinary.com/dxgsoqg9g/image/upload/v1600671638/case-icon.png" />
                            {isAgent ? <div className="link" onClick={openTab.bind(this, item.preview_link)}>{item.subject}</div>
                            : <a href={item.preview_link} target="_blank" className="link" onClick={()=>onLinkClick(item.subject)}>{item.subject}</a>}
                        </li>  
                    );
                })}
            </ul>
        </RenderCasesWrapper>
    )
}

const SalesforceRecentCases = ({ title,category,records, total, isAgent, eventName, conversationId, isLiveConversation, conversation_status, cardData }) => {
    if(eventName === "HS_SALESFORCE_CASE_PUSH") {
        return (
            <SalesforceRecentCasesWrapper>
                <p>This customer has {total} recent cases</p>   
                {records.map((item, index) => {
                    return (
                        <RenderCases 
                            key={index} 
                            data={item.data} 
                            title={item.status} 
                            isAgent={isAgent} 
                            conversationId={conversationId} 
                            isLiveConversation={isLiveConversation} 
                            conversation_status={conversation_status}
                        />
                    )
                })}                 
            </SalesforceRecentCasesWrapper>
        );
    }
    else if(eventName === "SF_PUSH_ACCOUNT_NOTES") {
        return (
            <SalesforceRecentCasesWrapper>
                {records.map((item, index) => {
                    //const noteTitle = item.title.replace("(Click here)", "");
                    return (
                        <div key={index} className="sf-note">
                            <div className="sf-note-title">{item.title}</div>
                            <div className="sf-note-description">{item.body}</div>
                        </div>                        
                    );
                })}                 
            </SalesforceRecentCasesWrapper>
        )
    }
    else if(eventName === "SF_ACCOUNT_LIMIT_ALERT") {
        return (
            <SalesforceRecentCasesWrapper>
                {records && records.length ? <p className="sf-account-limit-alert">{records[0].message.replace("(Click here)", "")}</p> : null}
                {records && records.length ? <StyledAnchor onClick={(e) => {
                    addAnalyticsEvent('Live Conversation', { conversationId: conversationId, isLiveConversation: isLiveConversation, conversationStatus: conversation_status, type: "RETOOL_LINK_OPEN" });
                    window.open(records[0].link,'_blank');
                }}>Click here to view in Retool</StyledAnchor>
                : null}
            </SalesforceRecentCasesWrapper>
        )
    }
    else if(eventName === "SNOWFLAKE_CASE_PUSH" && cardData.custom_data) {
        const data = cardData.custom_data;
        return (
            <SnowFlakeCard title={title} category={category} data={cardData.custom_data} conversationId={conversationId} conversation_status={conversation_status} />
        );
    }
    else if(eventName === "SNOWFLAKE_ACCOUNT_INFO" && cardData.custom_data) {
        return <SalesforceRecentCasesWrapper>
          <StringShortener 
            showMoreColor= "rgb(96, 153, 217)" 
            showLessColor= "rgb(96, 153, 217)" 
            showMoreText = "More" 
            showLessText= "Less" 
            shortenLimit= {100} 
            onMoreClick={() => {
              addAnalyticsEvent('Live Conversation', { 
                conversationId: conversationId, 
                type: "CLICK_MORE", 
                title: title,
                category: category,
                conversationStatus: conversation_status,
              });
            }}
          >{cardData.custom_data.text}
          </StringShortener>
          </SalesforceRecentCasesWrapper>
    }
    else {
        return null;
    }
}

export default SalesforceRecentCases;
