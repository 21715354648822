import React, { useState } from "react";
import { useSelector } from "../../../../store";
import OktaSamlMetadata from "./okta-saml-metadata";
import appConfigs from "../../../../config";

const OktaSaml = () => {
  const user = useSelector(state => state.app_user);  

  return (
    <>
      {(appConfigs.ENABLE_OKTA && user?.is_superuser) && <OktaSamlMetadata/>}
    </>
  );
};

export default OktaSaml;
