import React from 'react';
import { Redirect } from 'react-router';
import TriggerableRoute from './TriggerableRoute';

import {isAuthorized,isDashboardUser,isRouteAccessible} from './utilities.js';

const PrivateRoute = ({ component, ...rest }) => {
  //Check if user is authorized
  if(isAuthorized(rest.app_user)) {
    //If dashboard user and has access to route
    if(isDashboardUser(rest.app_user) && isRouteAccessible(rest.app_user, rest.path)) {  
      return <TriggerableRoute component={component} {...rest} />    
    }
    //Check if annotation tool user 
    else if(!isDashboardUser(rest.app_user)){
      return <Redirect to="/annotation/" />
    }
    else {
      return <Redirect to ="/404"/>
    }
  }
  else {
    if (rest.path !== '/') {
      rest.setRedirectUrl(rest.location.pathname.concat(rest.location.search));
    }
    return <Redirect to={'/'} />
  }
}

export default PrivateRoute
