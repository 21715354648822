import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ScreenRecordingPlayer from "../components/ScreenRecorderPlayer";
import { ReactComponent as RecorderLogo } from "../assets/recorder.svg";
// import { formatTimeDuration } from "../utils";
import styled from "@emotion/styled/macro";
import { message } from "antd";

const TextWrapper = styled.p`
  color: #6099d9;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;

  & svg {
    height: 14px;
    width: 14px;
    margin-right: 5px;
  }
`;

const ScreenRecording = ({ previewLink, extEmpId, convoId }) => {
  const [show, setShow] = useState(false);
  // const [duration, setDuration] = useState();

  return (
    <div style={{ marginLeft: "6px", height: "14px" }}>
      {
        <TextWrapper onClick={() => setShow((p) => !p)}>
          <RecorderLogo /> Case Recording
        </TextWrapper>
      }
      <ScreenRecordingPlayer
        caseID={previewLink?.split("/Case/")[1].split("/view")[0]}
        agentId={extEmpId}
        show={show}
        convoId={convoId}
        closePlayer={() => setShow(false)}
      />
    </div>
  );
};

export const ScreenRecordingNewTab = () => {
  const { agentId, caseId, convoId } = useParams();

  useEffect(() => {
    console.log(agentId, caseId);
    if (!agentId || !caseId) {
      message.error("incorrect url", 0);
    }
  }, [agentId, caseId]);

  return (
    <>
      {agentId && caseId && (
        <ScreenRecordingPlayer
          caseID={caseId}
          agentId={agentId}
          show={true}
          openInNewTab={true}
          convoId={convoId}
        />
      )}
    </>
  );
};
export default ScreenRecording;
