import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";

const StyledBtn = styled.div`
    font-size: 12px;
    padding: 0 10px;
    text-align: center;
    line-height: 26px;
    height: 26px;
    border: none;
    background: ${props => props.background};
    color: ${props => props.color};
    border: ${props => props.isFlag && "1px solid #ffffff"};
    border-radius: 100px;
    margin-left: 6px;
    margin-top: 12px;
    transition: all 0.3s ease;
    cursor: pointer;
    white-space: normal;
    &:first-of-type {
        margin-left: 0;
    }
    &:hover, &:active {
        color: ${props => props.activeColor};
        background: ${props => props.activeBackground};
    }
`;

const FeedbackReasonsWrapper = styled.div`
    background: ${props => props.isFlag ? "transparent" : "#ffffff"};
    width: 100%;
    display: ${props => props.showReasons ? "flex" : "none"};
    flex-wrap: wrap;
    opacity: ${props => props.showReasons ? "1" : "0"};
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease;
    border-top: 1px solid ${props => props.isFlag ? "#ffffff" : "#E0E0E0"};
    padding: 0;
    margin-top: 6px;    
`;

export const feedBackReasons = [
    {
        label: "Wrong Info",
        value: "WRONG"
    },
    {
        label: "Too delayed",
        value: "DELAYED"
    },
    {
        label: "Irrelevant",
        value: "IRRELEVANT"
    }
];

const FeedbackReasons = ({ isFlag, reason, showFeedbackReason, handleReasonSelection}) => {
    const [ showReasons, toggleShowReasons ] = useState(false);
    const [ selectedReason, selectReason ] = useState(reason);
    useEffect(() => {
        toggleShowReasons(showFeedbackReason);
    }, [showFeedbackReason])
    useEffect(() => {
        selectReason(reason);
    }, [reason])
    return (
        <FeedbackReasonsWrapper showReasons={showReasons} isFlag={isFlag}>
            {feedBackReasons.map((item, index) => {
                const selected = item.value === selectedReason;
                let backgroundColor = "transparent";
                let color = "#ffffff";
                let activeColor = "#DB554D";
                let activeBackground = "#ffffff";
                if(isFlag) {
                    if(selected) {
                        backgroundColor = "#ffffff";
                        color = "#DB554D";
                    }
                }
                else {
                    activeColor = "#ffffff";
                    activeBackground = "#DB554D";
                    if(selected) {
                        backgroundColor = "#DB554D";                        
                    }
                    else {
                        backgroundColor = "#F2F2F2";
                        color = "#4F4F4F";
                    }
                }
                return (
                    <StyledBtn 
                        background={backgroundColor}
                        color={color}
                        activeColor={activeColor}
                        activeBackground={activeBackground}
                        key={index}
                        selected={selected}
                        isFlag={isFlag}
                        onClick={(e) => {
                            selectReason(item.value);
                            handleReasonSelection(item.value)
                            e.stopPropagation();
                        }}
                    >{item.label}</StyledBtn>
                )
            })}
        </FeedbackReasonsWrapper>
    )
}

export default FeedbackReasons;