import React, { useState, useRef } from "react";
import { Input } from "antd";
import useStore from "../../store";
import { InputWrapper } from "./styles";

const KwargsName = ({ name, onNameChange, nodeId }) => {
  const inputRef = useRef();
  const [error, setError] = useState();
  const isValidName = useStore((store) => store.isValidName);

  const handleNameChange = (name) => {
    if (name === "") {
      setError("Required");
    } else if (isValidName(name, nodeId)) {
      setError(null);
    } else {
      setError("Name already taken");
    }
    onNameChange(name);
  };
  return (
    <>
      <div className="d-flex align-items-center mt-2">
        <label className="me-2">Name </label>
        <InputWrapper error={error}>
          <Input
            ref={inputRef}
            onChange={(e) => handleNameChange(e.target.value)}
            onMouseDown={(e) => e.stopPropagation()}
            value={name}
          />
          {error && <p className="error">{error}</p>}
        </InputWrapper>
      </div>
    </>
  );
};

export default KwargsName;
