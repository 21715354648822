import { message, Spin } from "antd";
import React from "react";
import useSWR, { useSWRConfig } from "swr";
import fetcher from "../../../../utils/fetcher";
import { NOTIFICATION_ACCESS, updateAccess } from "../apis";
import { StyledTable } from "../../../../components/common/styled-components";
import { useColumns } from "./columns";
import { BASE_URL } from "../../../../api/api";

const RolesStatus = () => {
  const { mutate } = useSWRConfig();
  const url = BASE_URL() + NOTIFICATION_ACCESS;
  const { data, error } = useSWR(url, fetcher);

  const handleToggle = async (checked, record) => {
    const index = data.findIndex((item) => item.id === record.id);
    const newData = [...data];
    newData[index] = { ...data[index], send_notification: checked };
    mutate(url, [...newData], false);
    await updateAccess(record.id, checked);
    mutate(url);
  };

  const { columns } = useColumns(handleToggle);

  if (!data) return <Spin />;
  if (error) return message.error("Error while fetching data try again!");

  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      loading={!data}
      rowKey={(obj) => obj.id}
      pagination={false}
    />
  );
};

export default RolesStatus;
