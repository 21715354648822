import React, { useState, useEffect, useCallback } from "react";
import {getQuestionScoreSummary, getSectionScoreSummary} from "../../../graphqlAPI/graphqlAPI";
import {buildTeamQAReport} from '../../utility/questions-report.builder';
import CSVDownloadButton from "../../../components/CSVDownloadButton/CSVDownloadButton";
import { useSelector } from "../../../store";
import { getSchemaName } from "../../../api/api";
import { buildSectionScoreReport } from "../../utility/sections-report.builder";

const CONSTANTS = {
  "QUESTION": "question",
  "SECTION": "section"
}

const ExportReportButton = ({
  report   // [QUESTION, SECTION]
}) => {
  const filters = useSelector((state) => state.dashboard.globalFilters);

  const fetchQuestionScore = () => {
    return getQuestionScoreSummary(filters).then(response => {
      let data = {
        rows: response.data[`${getSchemaName()}_cfdanswer`]
      };

      return buildTeamQAReport(data);
    })
  }

  const fetchSectionScore = () => {
    return getSectionScoreSummary(filters).then(response => {
      let data = {
        rows: response.data[`${getSchemaName()}_cfdanswer`]
      };

      return buildSectionScoreReport(data);
    })
  }

  const dataFunc = () => {
    return new Promise((resolve, reject) => {
      if(report === CONSTANTS.QUESTION) {
        fetchQuestionScore().then(data => resolve(data)).catch(error => reject(error));
      } else if(report === CONSTANTS.SECTION) {
        fetchSectionScore().then(data => resolve(data)).catch(error => reject(error));
        // let data = buildSectionScoreReport({
        //   rows: rows
        // });
        // resolve(data);
      } else {
        reject();
      }
    })
  }

  return (
    <div>
        <CSVDownloadButton 
          alreadyParsed={true}
          chartButton={true}
          dataFunc={dataFunc}
          getTotalCount={() => 5000}
          text="Details"
        />
    </div>
  );
};

export default ExportReportButton;
