import { axiosInstance, getSchemaName, getToken, BASE_URL } from "./../api/api";
import cubejs from "@cubejs-client/core";
import appConfig from "../config";
import store from "../store";

export const cubejsApi = cubejs(
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRJZCI6ImxldmVsIiwidXNlcl9pZCI6MTgsImV4cCI6MTYxNzA5NDc5NH0.F6LqzzgfOpNLWSfnttLPChhoWHK3n2Y8nqRIfvq0WpM",
  { apiUrl: "http://34.105.93.115:4000/cubejs-api/v1" }
);

export const getCubeToken = () => {
  if (store.getState().app_user.cubejs_jwt_exp < Date.now()) {
    return;
  }
  return store.getState().app_user.cubejs_jwt;
};

export const getAnalyticsSegmentsFromRedux = () => {
  if (store.getState().dashboard.segments) {
    return store.getState().dashboard.segments;
  }
  return;
};

const cubeJSApi = (cancelToken) => {
  return new Promise(async (resolve, reject) => {
    let token = getCubeToken();

    if (!token) {
      const response = await axiosInstance({
        method: "POST",
        url: `/accounts/analytics/auth/`,
        cancelToken: cancelToken?.token
      });
      const jwt = response.data.jwt;

      store.dispatch({
        type: "SET_CUBE_JWT",
        payload: {
          value: jwt,
          expiry: Date.now() + 29 * 60 * 60 * 24 * 1000,
        },
      });
    }

    resolve(cubejs(token, { apiUrl: appConfig.CUBEJS_BASE_URL }));
  });
};

export const cubeApi = () => {
  const getCubeToken = () => {
      if (store.getState().app_user.cubejs_jwt_exp < Date.now()) {
        return;
      }
      return store.getState().app_user.cubejs_jwt;
  };
  let token = getCubeToken();

  return cubejs(token, { apiUrl: appConfig.CUBEJS_BASE_URL });
};

export { cubeJSApi };

export const getAnalyticsSummary = async (id) => {
  const response = await axiosInstance({
    method: "GET",
    url: `/qa/analytics/summary/${id}`,
    
  });
  return response;
};

export const createSharingReport = async (data) => {
  const response = await axiosInstance({
    method: "POST",
    url: `/banking/analytics-report/create/`,
    data: {
      report_name: data.report_name,
      frequency: data.frequency,
      sender: data.sender,
      is_active: data.is_active,
      recipients: data.recipients,
      filter_set: data.filter_set,
      created_from: data.created_from,
    },
    
  });
  return response;
};

export const getSavedQueries = () => {
  return axiosInstance({
    method: "GET",
    url: `/analytics/list/`,
    
  }).then((response) => {
    return response.data;
  });
};

export const postQuery = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/analytics/create/`,
    
  });
  return response;
};

export const getQuery = (id) => {
  return axiosInstance({
    method: "GET",
    url: `/analytics/${id}/`,
    
  }).then((response) => {
    return response.data;
  });
};

export const updateQuery = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data,
    url: `/analytics/${id}/`,
    
  });
  return response;
};

export const deleteSavedQuery = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/analytics/${id}/`,
    
  });
  return response;
};

export const postToDashboard = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/analytics/dashboard/create/`,
    
  });
  return response;
};

export const removeFromDashboard = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/analytics/dashboard/${id}/`,
    
  });
  return response;
};

export const getDashboard = async (page) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/analytics/dashboard/list/`,
    
    params: {
      page
    }
  });
  return response;
};

export const getAnalyticsCustomFilters = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/analytics/custom_filters/`,
    
  });
  return response;
};

export const shareQueryChart = async (payload) => {
  const response = await axiosInstance({
    method: "POST",
    url: `/analytics/querybuilder/share/`,
    data: {
      chart_name: payload.title,
      recipients: payload.recipients,
      sender: payload.sender,
      id: payload.id,
    },
    
  });
  return response;
};

export const getAgentFilterOptions = () => {
  return axiosInstance({
    method: "GET",
    url: `/accounts/users/profile/list`
  });
}
