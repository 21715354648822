import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { StyledSwitch } from '../../../components/common/styled-components';
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import ActionMenu from '../commons/ActionMenu';
import { Link, useHistory, useParams } from 'react-router-dom';
import { spliceText } from '../../../components/common/CustomFilterComponent/utils';
import { MemoizedAvatar } from '../../../components/common/UserInitialsAvatar';
import styled from '@emotion/styled';
import moment from 'moment';
import { useReq } from '../../../custom-hooks/useDispatchReq';
import { REQUEST_TYPE } from '../../../custom-hooks/config';
import { ReduxActionTypes } from '../../../constants/ReduxActionConstants';
import produce from 'immer';
import { frequencyOptions, MAX_MINUTES, ruleModes } from './config';
import { createRule, editRule } from './api';
import { openNotification } from '../../../utils/utilities';
import { convertToUtc_Local_Mins, scrollToElement } from './utils';
import { getFrequencyValues, mapFiltersMetaToObject } from './helpers';

export const NameWrapper = styled.div`
display: flex;
align-items: baseline;
justify-content: start;
`;

export const useColumns = (handleToggle, handleDelete) => {
  const dispatch = useDispatch();
  const tableData = useSelector(state => state.qaCaseAssignmentData.tableData);
  const debounceToggleCall= useCallback(debounce(handleToggle, 250), [tableData]);

  const history = useHistory();

  const handleEdit = (record) => {
    if (!record) {
      return;
    }
    dispatch({
      type: ReduxActionTypes.SET_QA_IS_EDIT_ENABLED,
      payload: true
    });
    history.push(`/setup/qa-case-assignment/edit-rule/${record.id}`);
  }

  const columns = [
    {
      title: "Rule name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (value, record) => (
       <Link style = {{ color: "#4f4f4f" }} to = {`/setup/qa-case-assignment/edit-rule/${record.id}`}>{ spliceText(record.name, 25, true) }</Link>
      ),
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      width: "25%",
      render: (value, record) => (
          <NameWrapper><MemoizedAvatar key={record.id} name={`${record.created_by.first_name + record.created_by.last_name}` + " "} radius='1.5rem' margin="0 0.4rem 0 0"/><div>{record.created_by.first_name + " " + record.created_by.last_name}</div></NameWrapper>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created",
      key: "created",
      width: "25%",
      render: (value, record) => (
       <div>{moment(record.created).format("MMM D, YYYY")}</div>
      ),
    },
    {
      title: "Last assignment",
      dataIndex: "last_assignment",
      key: "last_assignment",
      width: "25%",
      render: (value, record) => (
        <div>{ record.last_run_at ? moment(record.last_run_at).format("MMM D, YYYY [at] h:mm a") : "" }</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "25%",
      align: "center",
      render: (value, record) => (
        <div>
        <StyledSwitch size = {"small"} checked = {record.active} defaultChecked={record.active} onChange={(val) => { debounceToggleCall(val, "active", {...record}) }}/>
      </div>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (value, record) => {
        return (
          <ActionMenu 
            menuItems={[
              {
                title: "Edit",
                key: "edit",
                icon: EditIcon,
              },

              {
                title: "Delete",
                key: "delete",
                icon: DeleteIcon
              }
            ]}
            handleMenuClick={(key) => {
              if(key === "delete") { 
                handleDelete({...record});
              }
              else if(key === "edit") {
                handleEdit({...record});
              }
            }}
          />
        )
      }
    }  
  ];

  return { columns };
}

export const useSaveRule = ({ mode, setLoading }) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.qaCaseAssignmentData.tableData)
  const ruleBlockData = useSelector((state) => state.qaCaseAssignmentData.ruleBlocksData);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);

  const [callApi, cancelRequests] = useReq();
  const history = useHistory();
  const { id } = useParams();

  const saveRule = () => {
    if(ruleBlockData.nameAndScheduleObject) {
      if(!ruleBlockData.nameAndScheduleObject.ruleName) {
        dispatch({
          type: ReduxActionTypes.SET_QA_ERROR_STATE
        })
        return scrollToElement('qa-name-schedule-block');
      }
    }

    if(ruleBlockData.agentsObject) {
      if(!ruleBlockData.agentsObject.agentsGroup?.length && !ruleBlockData.agentsObject.agentsList?.length) {
        dispatch({
          type: ReduxActionTypes.SET_QA_ERROR_STATE
        });
        return scrollToElement('qa-agents-list-block');
      }
    }
    //check for interaction sample block
    if(ruleBlockData.filters_meta) {
      let isError = [];
      ruleBlockData.filters_meta.forEach(interaction => {
        if(interaction.limit === "") {
          isError.push(interaction.id);
        }
        interaction.filter_groups.forEach(conditionGroup => {
          conditionGroup.filters.forEach(filter => !filter.filterKey || filter.val === null ?isError.push(interaction.id) : null );
        })
      })

      if(isError?.length) {
        dispatch({
          type: ReduxActionTypes.SET_QA_ERROR_STATE
        });
        return scrollToElement('qa-interactions-sample-block');
      }
    }

    if(ruleBlockData.qaAuditorsList) {
      if(!ruleBlockData.qaAuditorsList?.length) {
        dispatch({
          type: ReduxActionTypes.SET_QA_ERROR_STATE
        });
        document.getElementById('qa-auditors-list-block').scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        })
        return scrollToElement('qa-auditors-list-block');
      } 
    }
    setLoading(true);
    
    let filters_meta = mapFiltersMetaToObject(ruleBlockData.filters_meta); 

    let _cron_schedule = {
      "minute": "*",
      "hour": "*",
      "day_of_week": "*",
      "day_of_month": "*",
      "month_of_year": "*"
    };
    if(ruleBlockData.nameAndScheduleObject.frequency) {
      const nameAndScheduleObject = ruleBlockData.nameAndScheduleObject;
      const { minutesWithOffset, hour, minute } = convertToUtc_Local_Mins(nameAndScheduleObject.time);
      let { day_of_week, day_of_month } = getFrequencyValues(nameAndScheduleObject, minutesWithOffset);
      _cron_schedule = {
        ..._cron_schedule,
        "minute": minute,
        "hour": hour,
        ...(nameAndScheduleObject.frequency === frequencyOptions.WEEKLY.label ? { "day_of_week": `${day_of_week}` } : nameAndScheduleObject.frequency === frequencyOptions.MONTHLY.label ? { "day_of_month": `${day_of_month}` } : {} )
      };
    }

    const data = {
      name: ruleBlockData.nameAndScheduleObject.ruleName,
      sample: {
        filters_meta: [...filters_meta]
      },
      cron_schedule: {
        ..._cron_schedule
      }, 
      agents: ruleBlockData.agentsObject.agentsList,
      agent_group: ruleBlockData.agentsObject.agentsGroup,
      auditors: ruleBlockData.qaAuditorsList,
      data_pull_range: ruleBlockData.nameAndScheduleObject.frequency === frequencyOptions.WEEKLY.label ? frequencyOptions.WEEKLY.value : ruleBlockData.nameAndScheduleObject.frequency === frequencyOptions.MONTHLY.label ? frequencyOptions.MONTHLY.value : frequencyOptions.DAILY.value
    }

    callApi(isEditEnabled && mode === ruleModes.EDIT ? editRule : createRule, (res) => {
      openNotification("bottomLeft", `Rule ${isEditEnabled && mode === ruleModes.EDIT ? "edited" : "created" } successfully.`);
      history.push(`/setup/qa-case-assignment/`);
    }, (err) => {
      setLoading(false);
    }, {
      data,
      ...(isEditEnabled && mode === ruleModes.EDIT ? { id } : {})
    }, () => {
    }, REQUEST_TYPE.DISPATCH_REQUEST)
  };
  return { saveRule };

}
