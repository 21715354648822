/**
 * 
 * @param {object} data - GraphQL queried data  
 */
 export function buildTeamQAReport(data) {
    const {rows} = data;

    let agents = {};

    rows.forEach((row, index) => {
        if(agents[row.agent_id]) {
            if(agents[row.agent_id][row.section]) {
                if(agents[row.agent_id][row.section][row.question]) {
                    agents[row.agent_id][row.section][row.question]['cummulative_score'] += parseFloat(row.score);
                    agents[row.agent_id][row.section][row.question]['count'] += 1
                } else {
                    agents[row.agent_id][row.section][row.question] = {
                        cummulative_score: parseFloat(row.score),
                        max_score: parseFloat(row.max_score),
                        count: 1
                    }
                }
            } else {
                agents[row.agent_id][row.section] = {
                    [row.question]: {
                        cummulative_score: parseFloat(row.score),
                        max_score: parseFloat(row.max_score),
                        count: 1
                    }
                } 
            }
        } else {
            agents[row.agent_id] = {
                name: `${row.asrlog.accounts_user.first_name} ${row.asrlog.accounts_user.last_name}`,
                [row.section]: {
                    [row.question]: {
                        cummulative_score: parseFloat(row.score),
                        max_score: parseFloat(row.max_score),
                        count: 1
                    }
                }
            }
        }
    });

    return processAverageAndPercentage(agents);
}

/**
 * 
 * @param {object} data - cummulative user processed data as follows
 *  {
 *    agent: {
 *      section: {
 *        question: {
 *            cummulative_score: number,
 *            max_score: number,
 *            count: number
 *        }
 *      }
 *    }
 *  } 
 */
function processAverageAndPercentage(data) {
    for (const key in data) {
        for(const section of Object.keys(data[key])) {
            if(!(section === 'name')) {
                for(const question in data[key][section]) {
                    data[key][section][question]['average'] = data[key][section][question]['count'] ? (data[key][section][question]['cummulative_score'] / data[key][section][question]['count']) : 0;
                    data[key][section][question]['percentage'] = data[key][section][question]['max_score'] ? (data[key][section][question]['average'] / data[key][section][question]['max_score']) * 100 : 0;
                }
            }
        }
    }

    return buildCSVReport(data);
}

/**
 * 
 * @param {object} data - cummulative user processed data as follows
 *  {
 *    agent: {
 *      section: {
 *        question: {
 *            cummulative_score: number,
 *            max_score: number,
 *            count: number,
 *            average: number,
 *            percentage: number
 *        }
 *      }
 *    }
 *  } 
 */
function buildCSVReport(data) {
    let groups = {};

    Object.keys(data).forEach((key, i) => {
        Object.keys(data[key]).forEach((section, j) => {
            if(!groups[section]) {
                groups[section] = {};
            }

            Object.keys(data[key][section]).forEach((question, k) => {
                if(!groups[section][question]) {
                    groups[section][question] = 1
                }
            })
        })
    });

    // Section Row
    let sectionHeader = ",";
    let sectionsMap = [];
    Object.keys(groups).forEach((group) => {
        if(!(group === 'name')) {
            let uniqueQuestionsCount = Object.keys(groups[group]).length;
            sectionHeader += group;
            sectionsMap.push(group);
            for(let i = 0; i < uniqueQuestionsCount; i++) {
                sectionHeader += ",";
            }
        }
    });
    sectionHeader += '\r\n';

    let questionHeader = 'agent,';
    let sectionQuestionMap = {};
    Object.keys(groups).forEach((group) => {
        if(!sectionQuestionMap[group]) {
            sectionQuestionMap[group] = [];
        }

        if(!(group === 'name')) {
            Object.keys(groups[group]).forEach(question => {
                sectionQuestionMap[group].push(question);
                questionHeader += `\"${question}\"`;
                questionHeader += ',';
            });
        }
    });
    questionHeader += '\r\n';

    let csv = '';
    // if(dateRange.length) {
    //     if(dateRange.length === 2) {
    //         csv = 'Custom' + ',' + `Start: ${dateRange[0]}` + ',' + `End: ${dateRange[1]}`; 
    //     } else if(dateRange.length === 1) {
    //         csv = `Date Range` + ',' + 'All'; 
    //     }
    // } else {
    //     csv = 'Time'+','+'Last 7 days';
    // }
    // csv += '\r\n' + '\r\n';
    csv += sectionHeader + questionHeader + '\r\n';

    // Data Processing
    Object.keys(data).forEach((key, index) => {
        csv += data[key]['name'] + ',';

        sectionsMap.forEach(section => {
            if(!(section == 'name')) {
                if(data[key][section]) {
                    for(let question of sectionQuestionMap[section]) {
                        if(data[key][section][question]) {
                            csv += `${data[key][section][question]['percentage']}%` + ',';
                        } else {
                            csv += ',';
                        }
                    }
                }
                else {
                    for(let _ of sectionQuestionMap[section]) {
                        csv += ',';
                    }
                }
            }
        })

        csv += '\r\n';
    });

    return [csv, Object.keys(data).length];
}