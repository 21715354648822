import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { Radio } from "antd";
import {
  StyledInput,
} from "../../../../components/common/styled-components";
import { enableFiltersOptions } from "../config";
import CustomFilterComponent from "../../../../components/common/CustomFilterComponent/CustomFilterComponent";
import {
  FieldWrapper,
  DataSourceWrapper,
  FieldSourceWrapper,
  DisplayNameWrapper,
  EnableFiltersWrapper,
  LabelWrapper,
  RadioItems,
  EmptySpan
} from "../wrappers";
import {
  onDataSourceChange,
  onEnableFiltersChange,
  onDisplayNameChange,
  onSourceFieldChange,
} from "../helpers";

const FieldComponents = ({ dataSourceOptions, buttonLoader, dataSource, sourceFieldOptions, sourceField, displayName, enableFilter, setDataSource, setSourceField, setSourceFieldOptions, setEnableFilter, setDisplayName }) => {
  const isEditMode = useSelector(state => state.selfServeExternalData.isEditMode);
  const renderFields = {
    DATASOURCE: (
      <DataSourceWrapper>
        <LabelWrapper>Select data source</LabelWrapper>
        <Radio.Group
          onChange={(e) => {
            onDataSourceChange(
              e,
              dataSourceOptions,
              setDataSource,
              setSourceField,
              setSourceFieldOptions
            );
          }}
          disabled={isEditMode.is_enabled || buttonLoader}
          value={dataSource}
        >
          <RadioItems>
            {dataSourceOptions ?
              Object.keys(dataSourceOptions).map((optionKey) => (
                <Radio key={optionKey} value={optionKey}>
                  {optionKey?.replace(/_/g, " ")}
                </Radio>
              )) :
                <EmptySpan>No data sources found</EmptySpan>
              }
          </RadioItems>
        </Radio.Group>
      </DataSourceWrapper>
    ),
    FIELD_AT_SOURCE: (
      <FieldSourceWrapper>
        <LabelWrapper>Select field at source</LabelWrapper>
        <CustomFilterComponent
          disabled={isEditMode.is_enabled || buttonLoader}
          options={sourceFieldOptions?.map(option => {
            return {
              label: option,
              value: option?.toString()
            }
          })?.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase()))}
          placeholder="Select field"
          multiSelect={false}
          style={{ width: "350px" }}
          value={sourceField}
          handleChange={(val) => {
            onSourceFieldChange(val, setSourceField);
          }}
        />
      </FieldSourceWrapper>
    ),
    DISPLAY_NAME: (
      <DisplayNameWrapper>
        <LabelWrapper>Set display name</LabelWrapper>
        <StyledInput
          disabled={buttonLoader}
          placeholder={"Enter name"}
          onChange={(e) => {
            onDisplayNameChange(e, setDisplayName);
          }}
          value={displayName}
          style={{ marginBottom: "0", height: "32px", width: "350px" }}
        />
      </DisplayNameWrapper>
    ),
    ENABLE_FILTERS: (
      <EnableFiltersWrapper>
        <LabelWrapper>Enable filters</LabelWrapper>
        <Radio.Group
          disabled={isEditMode.is_enabled || buttonLoader}
          onChange={(e) => {
            onEnableFiltersChange(e, setEnableFilter);
          }}
          value={enableFilter}
        >
          <RadioItems>
            {enableFiltersOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </RadioItems>
        </Radio.Group>
      </EnableFiltersWrapper>
    ),
  };
  return (
    <>
      <FieldWrapper>
        {renderFields["DATASOURCE"]}
        {renderFields["FIELD_AT_SOURCE"]}
        {renderFields["DISPLAY_NAME"]}
        {renderFields["ENABLE_FILTERS"]}
      </FieldWrapper>
    </>
  );
};

export default FieldComponents;
