import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomFilterComponent from "../../../../../components/common/CustomFilterComponent/CustomFilterComponent";
import { ReduxActionTypes } from "../../../../../constants/ReduxActionConstants";
import { UserRoles } from "../../../user-management/config";
import BlockComponent from "../BlockComponent";
import { QaAuditorWrapper, QaAuditorUser } from "../../wrappers";
import { ruleModes } from "../../config";

const QaAuditorBlock = () => {
  const dispatch = useDispatch();
  const userOptions = useSelector(
    (state) => state.dashboard.filterSegments.globalUserRoleList
  );
  const showErrorState = useSelector(
    (state) => state.qaCaseAssignmentData.showErrorState
  );
  const qaAuditorList = useSelector(
    (state) => state.qaCaseAssignmentData.ruleBlocksData.qaAuditorsList
  );
  const mode = useSelector((state) => state.qaCaseAssignmentData.mode);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);

  const onAddQaAuditors = (value) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_QA_AUDITORS_LIST,
      payload: [...value],
    });
  };
  return (
    <>
      <BlockComponent
        _key = "qa-auditors-list-block"
        id="qa-auditors-list-block"
        errorObject = {{ isError: showErrorState && !qaAuditorList?.length, errorText: "Select at least one QA auditor" }}
        heading="QA auditors assigned"
        subHeading="Interactions will be assigned to these QA auditors"
        renderBody={() => (
          <QaAuditorWrapper className = "d-flex flex-column">
              <CustomFilterComponent
              //iserror = {showErrorState && !qaAuditorList?.length ? true : false}
              showTextOnly = {mode === ruleModes.CREATE || isEditEnabled ? false : true}
              textOnlyFormat = "block"
              textOnlyStyle = {{ color: "#4F4F4F", rowGap: "16px" }}
              maxTagCount = {4}
              disabled={false}
              type={"local"}
              showFooter={false}
              dropdownStyle = { { top: "-360px" } }
              options={
                userOptions
                  ?.filter(
                    (user) =>
                      user.role.includes(UserRoles.Qa_Auditor) ||
                      user.role.includes(UserRoles.Manager)
                  )
                  .map((item) => {
                    return {
                      value: item.id,
                      label: item.first_name + " " + item.last_name,
                    };
                  })
                  .sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                  ) || []
              }
              placeholder="Select one or more QA auditors"
              multiSelect={true}
              style={{ width: "80%" }}
              value={qaAuditorList?.length ? qaAuditorList : []}
              handleChange={(val) => {
                onAddQaAuditors(val);
              }}
            />
          </QaAuditorWrapper>
        )}
      />
    </>
  );
};

export default QaAuditorBlock;
