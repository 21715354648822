import { getSchemaName } from "../api/api";

const zendeskUrl = (subdomainValue) => `https://${subdomainValue}.zendesk.com/`;

export default {
  SALESFORCE: {
    CLIENT_ID:
      window.location.hostname.includes("affirm") ? "3MVG9l2zHsylwlpT.6JDTp5dw1rU_W6IfKjOgFwZgh9AS1702LgvBBE7G3bYHePDzdoySD9.7DXa2MhPBN6Vy" : "3MVG9l2zHsylwlpT.6JDTp5dw1jpHEy0OoAfIGuyJdqgzJW1co8VFkME8SATM_HlMcsNoCih.xa40aAnSvmYK",
    URL: "https://login.salesforce.com/",
    SCOPE: window.location.hostname.includes("affirm") ? "refresh_token%20content%20api" : "refresh_token%20api%20full",
  },
  ZENDESK: {
    CLIENT_ID: "zdg-level_ai_agent_assist_app",
    URL: zendeskUrl,
    SCOPE: "read",
  },
  RING_CENTRAL: {
    IS_ENABLED: true,
  },
  CONVERSATION_SEARCH: {
    showConversationSearchInFilter: true,
    showConversationTranscriptSearch: true,
  },
  SENTRY_KEY:
    "https://c553a8070d2d4f869f21545b1bb9abf6@o1048171.ingest.sentry.io/6025272",
  HEAP_APPLICATION_ID: "2008543124",
  OKTA_BASE_URL: "https://dev-7105105.okta.com/oauth2/default/v1/authorize",
  OKTA_CLIENT_ID: "0oajcbcrpwOeOx6gD5d5",
  //DEFAULT_CHANNEL : window.location.hostname.includes("brex") ? "2" : "",
  DEFAULT_CHANNEL: "",
  SHOW_ORGANIZATION_FILTER: window.location.hostname.includes("infocision"),
  DEFAULT_DATE_FILTER: "30",
  SHOW_TIP_FOLLOW: false,
  SHOW_RESOURCE_FOLLOW: false,
  showIntentConflict: true,
  showWorkspaceTab: false,
  versionNumber: "v2",
  enablePlaygroundUI: true,
  enableMetricTagFilter: false,
  CUBEJS_BASE_URL: "https://cube-production.thelevel.ai/cubejs-api/v1",
  HASURA_BASE_URL: "https://hasura-production.thelevel.ai/v1/graphql",
  ENABLE_CUSTOM_COLUMNS: true,
  ENABLE_INSTA_SCORE:
    window.location.hostname.includes("brex") ||
    window.location.hostname.split(".")?.[0] === "level" || window.location.hostname.includes("demo"),
  SHOW_QA_EVAL_TABLE: false,
  USE_GRAPHQL_API: true,
  ENABLE_QA_CALIBRATION: true,
  ENABLE_OKTA: true,
  showSlackIntegration: false,
  showAutomationTab: false,
  ENABLE_FILTER_UI: true, //used for new filter UI
  ENABLE_CHANNEL_BASED_CONVERSATION_TAGS: true,
  ENABLE_SELF_SERVE_FILTERS: true,
  ENABLE_SCREEN_RECORDING: false,
  ENABLE_SAMPLING_TASK_QUEUE: true,
  ENABLE_RUBRIC_AGENT_TYPE: window.location.hostname.includes("toast") || window.location.hostname.includes("carta"),
  ENABLE_AUTH0: true,
  ENABLE_ONE_LOGIN: true,
  ENABLE_CUSTOM_FIELDS_V2_API: false,
  ENABLE_IS_CHECKMARKED: false
};
