import styled from "@emotion/styled/macro";

export const SetupWrapper = styled.div`
  padding-top: 16px;
  padding-right: 52px;
  padding-bottom: 16px;
  padding-left: 24px;
  margin: 0;
  height: calc(100vh - 42px);
  width: 100%;
  overflow-x: hidden;
  overflow: auto;
  background: #fafafa;
  &.setup-tab-conversation-tags {
    background: #fafafa;
  }
`;

export const SetupActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.h2`
  color: #828282;
  font-size: 28px;
  font-weight: bold;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 18px 0 16px;
`;

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const LineSeparator = styled.div`
  width: 100%;
  margin: 0 0 16px;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TableLoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuWrapper = styled.div`
  .ant-menu-item:hover {
    color: #0b4b93;
    border-bottom: 2px solid #0b4b93;
  }
  .ant-menu-item-selected {
    color: #0b4b93;
    border-bottom: 2px solid #0b4b93;
  }
`;

export const IntegrationWrapper = styled.div`
  .service-card {
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    margin: 10px;
    display: inline-block;
    height: 140px;
    width: 160px;
    overflow: hidden;
    cursor: pointer;
    .integration-icon {
      height: 111px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 58px;
      width: 66px;
    }
    .integration-title {
      margin: 0px;
      background: #f2f2f2;
      border-top: 1px solid #bdbdbd;
      color: #828282;
      font-size: 16px;
      text-align: center;
      height: 28px;
    }
  }
  .connect:hover {
    border: 1px solid #27ae60;
    .integration-title {
      background: #27ae60;
      color: white;
      font-size: 12px;
      line-height: 28px;
    }
    .integration-title::before {
      content: "Connect \n";
    }
  }
  .disconnect {
    position: relative;
    .isConnected {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 46px;
      height: 31px;
      background: #27ae60;
      border-radius: 0px 5px;
    }
  }
  .disconnect:hover {
    border: 1px solid red;
    .integration-title {
      background: red;
      color: white;
    }
    .integration-title::before {
      content: "Disconnect \n";
    }
  }
  flex-direction: row;
`;

export const IntegrationSuccess = styled.div`
  svg {
    width: 50px;
    position: relative;
    left: calc(50% - 25px);
  }
  p {
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
  .title {
    font-size: 25px;
  }
  .description {
    font-size: 15px;
  }
  .ant-btn-primary {
    background-color: #0b4b93;
    border-color: #0b4b93;
  }
`;
