import React, { useState, useEffect, useReducer, memo, useCallback } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { DeleteFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import appConfigs from "../../../config";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { getCustomFilters } from "../../../conversation-list/api";
import { REQUEST_TYPE } from "../../../custom-hooks/config";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import FilterInput from './filter-input';
import { getSelectMeasureOptions } from "../../query-config";
import { getMeasureTextCustomColumns } from "../../utils";
import { MeasureWrapper, DeleteMeasureButton, IconButtonWrapper } from "./style";
import { useSelector } from 'react-redux';
import { Select } from "antd";

const Measures = ({ value, onChange, onCheckMeasures, setIsTimeSeries, isCompareDateRange }) => {
    const customMeasures = useSelector(state => state.dashboard.customColumnsMeasures);

    const [measures, updateMeasures] = useState([undefined]);

    const getMeasureOptions = useCallback(() => {
        let customMeasuresSafe = customMeasures || []
        
        return (
            [ 
                getSelectMeasureOptions().map((option, index) => (
                    <Select.Option key={index} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))
                ,
                ...customMeasuresSafe.map((measure, index) => (
                        <Select.Option key={index} value={measure.name}>
                            {getMeasureTextCustomColumns(measure.shortTitle)}
                        </Select.Option>
                    )
                )
            ]
        )
    },[customMeasures]);

    useEffect(() => {
        // if(measures.length > 1 && measures.every(e => e)) {
        //     setIsTimeSeries(false);
        // }
    }, [measures])

    // METHODS
    const handleAddNewMeasure = () => {
        updateMeasures(state => [
            ...state,
            undefined
        ])
    };

    const handleRemoveMeasure = (index) => {
        let measureValues = [...measures];
        measureValues.splice(index, 1);

        updateMeasures(measureValues);
        onChange(measureValues)
    };

    const handleOnChangeMeasure = (value, index) => {
        let measureValues = [...measures];
        measureValues[index] = value;
        updateMeasures(measureValues);
        onChange(measureValues);
    }

    return (
        <MeasureWrapper>
            {
                measures.map((measure, index) => (
                    <div className="measure-row-container">
                        <Select
                            className="select-measure-input"
                            onChange={(value) => handleOnChangeMeasure(value, index)}
                            value={measure}
                            placeholder="Select Measure"
                            style={{
                                margin: "0px",
                            }}
                        >
                            {getMeasureOptions()}
                        </Select>

                        {
                            measures.length !== 1 &&
                                <IconButtonWrapper>
                                    <Link
                                        onClick={() => handleRemoveMeasure(index)}
                                    >
                                        <DeleteFilled />
                                    </Link>
                                </IconButtonWrapper>
                        }
                    </div>
                ))
            }

            <Tooltip title={isCompareDateRange ? "Multiple measure charts are not supported with date comparison" : undefined} placement="right" overlayStyle={{width: "200px"}}>
                <Button className={measures.length === 2 || isCompareDateRange ? null : "add-measure-btn"} onClick={handleAddNewMeasure} disabled={measures.length === 2 || isCompareDateRange}>
                    <PlusCircleOutlined />
                    Add another measure
                </Button>
            </Tooltip>
        </MeasureWrapper>
    );
};

export default memo(Measures);
