import React, { useEffect, useState } from "react";
import {
  PrimaryButton,
} from "./styled-components";
import { Radio, Select } from "antd";
import CustomSwitch from "../../CompanySetup/components/QaCaseAssignment/components/RuleView/CustomSwitch";
import { ReactComponent as DeleteIcon } from "../../CompanySetup/assets/delete.svg";
import {
  ConditionGroupSubWrapper,
  ConditionGroup,
  StyledRadioGroup,
  ConditionGroupHead,
  ConditionGroupBody,
  ConditionGroupOperator,
  FiltersInculcateWrapper,
  FiltersInculcateSubWrapper,
  Filters,
  SelectWrapper,
  FilterLogicalOperator,
  HorizontalLine,
  OperatorBlock,
  FilterAddCondition,
  ConversationTagSwitch,
  ErrorState,
  CustomSelect,
} from "../../CompanySetup/components/QaCaseAssignment/wrappers";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";

const { Option } = Select;

const ConditionGroupComponent = ({ conditionGroupData = {}, textOnly = false, filterOptions = [], showErrorState = false, condtionGroupIdx = 0, interactionIdx = 0, interaction = null, fn }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(conditionGroupData || {});
  }, [conditionGroupData]);

  return (
      data && Object.keys(data)?.length ? (
          <ConditionGroupSubWrapper
            className="d-flex flex-column"
            key={data.id}
          >
            <ConditionGroup className="d-flex flex-column">
              {!textOnly ? (
                <ConditionGroupHead className="d-flex">
                  <StyledRadioGroup
                    onChange={(e) => {
                      fn.onChangeConditionGroupLogicalOperator(
                        e,
                        data.id,
                        interaction?.id,
                      );
                    }}
                    value={data.logical_op}
                  >
                    <Radio value={"OR"}>OR</Radio>
                    <Radio value={"AND"}>AND</Radio>
                  </StyledRadioGroup>
                </ConditionGroupHead>
              ) : null}
              <ConditionGroupBody className="d-flex flex-column">
                <FiltersInculcateWrapper className="d-flex flex-column">
                  {data?.filters?.length &&
                    data.filters.map((filter, filterIdx) => (
                      <>
                        <FiltersInculcateSubWrapper
                          key={filter.id}
                          className="d-flex flex-column"
                        >
                          <Filters
                            className={`d-flex ${
                              !textOnly ? "" : "align-items-baseline"
                            }`}
                          >
                            <SelectWrapper
                              textOnly={textOnly}
                              className="d-flex flex-column"
                            >
                              <CustomSelect
                                disabled = {textOnly}
                                showTextOnly = {textOnly}
                                allowClear = {true}
                                showSearch = {true}
                                //isError = {showErrorState && !filter.filterKey}
                                value={filter.filterKey || undefined}
                                onChange={(value) => {
                                  fn.onChangeFilter(
                                    value,
                                    "filterKey",
                                    data.id,
                                    filter.id,
                                    interaction?.id,
                                  );
                                }}
                                style={{ width: "100%" }}
                                placeholder="Select Filter"
                              >
                                {filterOptions
                                  ?.sort((a, b) =>
                                    a.label
                                      .toLowerCase()
                                      .localeCompare(b.label.toLowerCase())
                                  )
                                  .map((option) => (
                                    <Option disabled = {data?.filters?.filter(_filter => _filter.filterKey === option.key)?.length && filter.filterKey !== option.key}  key={option.key}>
                                      {option.label}
                                    </Option>
                                  ))}
                              </CustomSelect>
                              {showErrorState && !filter.filterKey ? (
                                <ErrorState>
                                  <ErrorRoundedIcon className="css-qa-error-state-icon" />
                                  <span>Filter is required</span>
                                </ErrorState>
                              ) : null}
                            </SelectWrapper>
                            {filter.filterKey ? (
                              <SelectWrapper textOnly={textOnly}>
                                <CustomSelect
                                  disabled={textOnly}
                                  showTextOnly={textOnly}
                                  onChange={(value) => {
                                    fn.onChangeFilter(
                                      value,
                                      "operator",
                                      data.id,
                                      filter.id,
                                      interaction?.id,
                                    );
                                  }}
                                  disabled={textOnly}
                                  //showTextOnly = {textOnly ? false : true}
                                  value={
                                    filter.operator ||
                                    filterOptions?.find(
                                      (option) =>
                                        option.key === filter.filterKey
                                    )?.operatorOptions[0]
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Select Operator"
                                >
                                  {filterOptions
                                    ?.find(
                                      (option) =>
                                        option.key === filter.filterKey
                                    )
                                    ?.operatorOptions?.map((option) => (
                                      <Option key={option}>{option}</Option>
                                    ))}
                                </CustomSelect>
                              </SelectWrapper>
                            ) : null}

                            <SelectWrapper textOnly={textOnly}>
                              {filter.filterKey
                                ? filterOptions
                                    ?.find(
                                      (option) =>
                                        option.key === filter.filterKey
                                    )
                                    ?.renderComponent(
                                      filter.filterKey === "convo_tag_sampling" ? filter.convo_tag_id?.split(',') || undefined : filter.val?.split(',') || undefined,
                                      (value, rest) => {
                                        fn.onChangeFilter(
                                          value?.toString() || null,
                                          "val",
                                          data.id,
                                          filter.id,
                                          interaction?.id,
                                          rest || {}
                                        );
                                      },
                                      {
                                        disabled: textOnly,
                                        operator: filter.operator,
                                        showTextOnly: textOnly,
                                        textOnlyStyle: { marginLeft: "4px", color: "#4F4F4F" },
                                        textOnlyFormat: "inline",
                                        spliceLimit: { placeholderLimit: 15 }
                                      }
                                    )
                                : null}
                              {showErrorState &&
                              (filter.val === null ||
                                filter.val === undefined) &&
                              filter.filterKey ? (
                                <ErrorState>
                                  <ErrorRoundedIcon className="css-qa-error-state-icon" />
                                  <span>Filter value is required</span>
                                </ErrorState>
                              ) : null}
                              {filter.filterKey &&
                              filter.filterKey === "convo_tag_sampling" && !textOnly ? (
                                <ConversationTagSwitch>
                                  <CustomSwitch
                                    value={filter.followed}
                                    handleChange={(value) => {
                                      fn.onChangeFilter(
                                        value,
                                        "followed",
                                        data.id,
                                        filter.id,
                                        interaction?.id,
                                      );
                                    }}
                                  />
                                </ConversationTagSwitch>
                              ) : null}
                            </SelectWrapper>
                            {(interactionIdx === 0 &&
                              condtionGroupIdx === 0 &&
                              filterIdx === 0) ||
                            textOnly ? null : ( //need to check this
                              <DeleteIcon
                                className="css-filter-delete-icon"
                                onClick={() => {
                                  fn.onDeleteFilter(
                                    data.id,
                                    filter.id,
                                    interaction?.id,
                                  );
                                }}
                              />
                            )}
                          </Filters>
                          {
                            filter.id !== data?.filters[data?.filters?.length - 1]?.id ?
                              <FilterLogicalOperator>
                                <HorizontalLine />
                                <OperatorBlock>
                                  {data.logical_op}
                                </OperatorBlock>
                              </FilterLogicalOperator>
                            :
                            null
                          }
                        </FiltersInculcateSubWrapper>
                      </>
                    ))}
                  {
                  !textOnly ?
                  <FilterAddCondition>
                      <PrimaryButton
                        onClick={() => {
                          fn.addFilter(
                            data.id,
                            interaction?.id,
                          );
                        }}
                        style={{
                          background: "#F2F2F2",
                          border: "none",
                          marginLeft: 0,
                        }}
                      >
                        Add condition
                      </PrimaryButton>
                  </FilterAddCondition>
                  :
                  null
                  }
                </FiltersInculcateWrapper>
              </ConditionGroupBody>
            </ConditionGroup>
            {interaction && 
            data.id !== interaction?.filter_groups[interaction?.filter_groups?.length - 1]?.id ? (
              <ConditionGroupOperator>
                {
                  !textOnly ? 
                  <StyledRadioGroup
                  onChange={(e) => {
                    fn.onChangeInteractionLogicalOperator(e, interaction.id);
                  }}
                  value={interaction.logical_op}
                  >
                    <Radio value={"OR"}>OR</Radio>
                    <Radio value={"AND"}>AND</Radio>
                </StyledRadioGroup>
                :
                <FilterLogicalOperator>
                    <OperatorBlock>
                      {interaction.logical_op}
                    </OperatorBlock>
                </FilterLogicalOperator>
                }
              </ConditionGroupOperator>
            ) : null}
          </ConditionGroupSubWrapper>
        ) : (
          ""
        )
  );
};

export default ConditionGroupComponent;
