import { axiosInstance, getSchemaName, getToken, BASE_URL } from "./../api/api";
import axios from "axios";
import { getOverviewMetrics } from "./../conversation-analytics/api";
import { createBookmark, deleteBookmark } from "./../conversation-details/api";
import { DASHBOARD_OPTIONS } from "./utils";
import { getApiFilters } from "../utils/utilities";

export const getMostFrequentFlags = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/most-frequent-flags/`,
    params: filters,
    
  });
  return response;
};

export const getAgentInteractions = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/interactions/`,
    params: filters,
    
  });
  return response;
};

export const getAgentHandlingTime = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/handling-time/`,
    params: filters,
    
  });
  return response;
};

export const getKeyInteractions =
  (cancelToken, rest) => (dispatch, getState) => {
    return axiosInstance({
      method: "GET",
      url: `/banking/v2/key-interactions/`,
      params: rest.filters,
      cancelToken: cancelToken?.token,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
        }
        throw new Error();
      });
  };

export const getKeyInteractionsUnreadCount = async (filters, cancelToken) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/v2/key-interactions-unread-count`,
    params: filters,
    
    cancelToken: cancelToken?.token
  });
  return response;
};

export const markRead = async (conversationId) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/banking/read-interaction/`,
    data: { conversation: conversationId },
    
  });
  return response;
};

export const getInteractionsSummary = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    //url: `/banking/v2/interaction-summary/`, //now the count is obtained from a new api /list-duration/`
    url: `/banking/v2/list-duration/`,
    params: filters,
    
  });
  return response;
};

export const shareConversation = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/banking/inbox/`,
    data: data,
    
  });
  return response;
};

export const qaData = (cancelToken, rest) => (dispatch) => {
  return axiosInstance({
    method: "GET",
    url: `/qa/list`,
    params: rest.userFilters,
     
    cancelToken: cancelToken?.token
  }).then(res => {
    return res.data;
  }).catch(err => {
    if (axios.isCancel(err)) {
      throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
     }
    throw new Error();
  })
}

export const deleteInteraction = async (conversationID) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/banking/v2/remove-key-interaction/${conversationID}/`,
    data: {
      conversation: conversationID,
    },
    
  });
  return response;
};

export const agentFeedbackUpdate = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    url: `/qa/agent-feedback/${id}`,
    data,
    
  });
  return response;
};

export const getEvaluationData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/v2/qa/qa_count/`,
    params: rest?.filters,
    
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
       }
      throw new Error();
    });
};

export const getEvaluationTaskData = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/task/task_count`,
    //params: rest?.filters,
    
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
       }
      throw new Error();
    });
};


export const getNegativeQuestions = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/v2/qa/most-negative-questions`,
    params: getApiFilters({ ...rest?.filters }),
    
    cancelToken: cancelToken?.token
  })
    .then((res) => {
      const data= res.data?.results?.slice(0, 5).map(result => {
        return {
          id: result.question_obj_id,
          title: result.question,
          score: result.negative_ratio
        }
      })
      return data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
       }
      throw new Error();
    });
}

export { getOverviewMetrics, createBookmark, deleteBookmark };
