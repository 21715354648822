import { createReducer } from "../util";

import { ReduxActionTypes } from "../../constants/ReduxActionConstants";

const initialState = {
  meta: {},
};

const conversationMetricsReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_CONVERSATION_METRICS]: (state, action) => {
    return {
      ...state,
      meta: action.payload.meta,
    };
  },
});

export default conversationMetricsReducer;
