const testTenants = [
  "andromeda",
  "toast",
  "bpo",
  "affirm"
]

export const getSalesforceUrl = () => {
  let url = testTenants.find((tenant) => window.location.hostname.includes(tenant)) 
  ? "https://test.salesforce.com/"
  : "https://login.salesforce.com/";

  return url
}

export const is_sandbox_enabled = () => !!testTenants.find((tenant) => window.location.hostname.includes(tenant)) 
