import { createReducer } from "../util";
import {
  NotificationActionTypes  
} from "../../constants/ReduxActionConstants";

const initialState = {
  notifications: [],
  unreadNotificationsCount: 0
};

const notificationsReducer = createReducer(initialState, {
  [NotificationActionTypes.SET_UNREAD_NOTIFICATIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      unreadNotificationsCount: action.payload.count,
    };
  },

  [NotificationActionTypes.REQUEST_NOTIFICATIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      notifications: [],
      loading: true
    };
  },

  [NotificationActionTypes.SET_NOTIFICATIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      notifications: action.payload,
      loading: false
    };
  },

  [NotificationActionTypes.START_PAGINATING]: (
    state,
    action,
  ) => {
    return {
      ...state,
      paginating: true
    };
  },

  [NotificationActionTypes.PAGINATION_SET_NOTIFICATIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      notifications: [...state.notifications, ...action.payload],
      paginating: false
    };
  },

  [NotificationActionTypes.SET_NEXT_PAGE_NOTIFICATIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      nextPage: action.payload
    };
  },

  [NotificationActionTypes.NEW_NOTIFICATION]: (
    state,
    action,
  ) => {

    return {
        ...state,
        notifications: [
            action.payload,
            ...state.notifications.filter(notification => notification.id !== action.payload.id),
        ],
        unreadNotificationsCount: state.unreadNotificationsCount + 1
    };
  },

});

export default notificationsReducer;