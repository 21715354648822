import {
    BASE_URL, 
    getToken, 
    getSchemaName,
    axiosInstance
} from '../../../api/api';

export const createRubric = (data) => {
  return axiosInstance({
    method: "POST",
    url: `/qa/custom-forms/create/`,
    data,
     
  });
};

export const listRubrics = (data) => {
  return axiosInstance({
    method: "GET",
    url: `/qa/custom-forms/list/`,
     
  });
};

export const updateChannel = (rubricId, data) => {
  return axiosInstance({
    method: "PUT",
    url: `/qa/custom-forms/channel/${rubricId}`,
    data,
     
  });
};

export const updateRubricItem = (rubricId, data) => {
  return axiosInstance({
    method: "PUT",
    url: `/qa/custom-forms/update_assignment/${rubricId}`,
    data,
     
  });
};

export const getRubric = (rubricId) => {
  return axiosInstance({
    method: "GET",
    url: `/qa/custom-forms/retrieve/${rubricId}`,
     
  });
}

export const updateRubric = (rubricId, data) => {
  return axiosInstance({
    method: "PUT",
    data,
    url: `/qa/custom-forms/retrieve/${rubricId}`,
     
  });
}

export const deleteRubric = (rubricId) => {
  return axiosInstance({
    method: "DELETE",
    url: `/qa/custom-forms/retrieve/${rubricId}`,
     
  });
}


export const getAutoscoredQuestions = async (type) => {
  const response = await axiosInstance({
    method: "GET",
    url: `/qa/autoscored_questions/?question_type=${type}`,
     
  })
  return response?.data;
}
