import produce from "immer";
import {
  axiosInstance,
  BASE_URL,
  getSchemaName,
  getToken,
} from "../../api/api";

export const createSeedOperationsSlice = (set, get) => ({
  loadingElements: false,
  fetchInitElements: async (id) => {
    set(
      produce((state) => {
        state.loadingElements = true;
      })
    );
    let data = await axiosInstance({
      method: "GET",
      url: `/automation/${id}/`
    });
    data = data?.data;
    set(
      produce((state) => {
        state.title = data.title;
        state.elements = data.react_flow_json?.elements;
        state.description = data.description;
        state.nextNodeId = data.react_flow_json?.nextNodeId || 1;
        state.trigger_type = data.trigger_type;
        state.loadingElements = false;
      })
    );
  },
  addTriggerNode: (nodeData) => {
    const position = {
      x: 300,
      y: 0,
    };
    let newNode = {
      id: "conversation",
      type: "trigger",
      position,
      data: {
        id: "conversation",
        ...nodeData,
        globalVariables: [],
        variableFromLastFunc: [],
      },
      kwargs: {
        name: "conversation",
      },
    };
    set(
      produce((state) => {
        state.elements = [newNode];
      })
    );
  },
});
