import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react'
import { getAnalyticsCategories } from '../config';
import { ReactComponent as DefaultSetupIcon } from '../assets/setup-icon.svg';

const TabItemWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 222px;
  padding: 14px 20px;
  cursor: pointer;
  background: ${props => props.active ? "#FAFAFA" : "#FFFFFF"};
  span {
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.active ? "#4F4F4F" : "#828282"};
    font-weight: ${props => props.active ? 500 : "normal"};
    margin-left: 12px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;


const TabsWrapper = styled.div`
  background: white;
  width: 222px;
  display: flex;
  flex-direction: column;
  float: left;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-height: 100%;
`;

const CategoryItemWrapper = styled.div`
  width: 222px;
  padding: 8px 0 8px 18px;
  font-size: 12px;
  line-height: 14px;
  color: #BDBDBD;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  &:first-child {
    margin-top: 16px;
  }
`;

const SetupIcon = ({ icon }) => {
    switch(icon) {
        default:
            return <DefaultSetupIcon />;
    }
}

const TabItem = ({ title, icon, active, onClick}) => {
    const [isActive, toggle] = useState(active);
    useEffect(() => {
      toggle(active)
    }, [active]);
    return (
      <TabItemWrapper 
        active={isActive}
        onMouseEnter={() => toggle(true)}
        onMouseLeave={() => toggle(false||active)}
        onClick={onClick}
      >
        <SetupIcon icon={icon} active={isActive}/>
        <span>{title}</span>
      </TabItemWrapper>
    )
  }

export const NavigationTabs = (props) => {
    return (
      <TabsWrapper>
        {getAnalyticsCategories().map((item) => (
          <React.Fragment>
            <CategoryItemWrapper>{item.name}</CategoryItemWrapper>
            {item.items.map((tab) => <TabItem onClick={() => props.onTabChange(tab.key)} title={tab.title} icon={tab.icon} active={props.route === tab.key}/>)}
          </React.Fragment>
        ))}
      </TabsWrapper>
    )
  };