// import axios from "axios";
import { axiosInstance } from "../api/api";
import { BASE_URL, getSchemaName, getToken } from "../api/api";

// const colossusAxios = axios.create({
//   baseURL: `https://stage-api.thelevel.ai`,
//   timeout: 10000,
//   headers: {
//     Authorization: `Token ${getToken()}`,
//     "X-DTS-SCHEMA": getSchemaName(),
//   },
// });
export const colossusFetcher = (url) => {
  return axiosInstance
    .get(`${url}`)
    .then((res) => res.data);
};

export const getAutomationAuthToken = () => {
  return axiosInstance({
    method: "POST",
    url: `/accounts/automation/auth/`,
  });
};
