import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SetupWrapper,
  FilterWrapper,
  LineSeparator,
} from "./../commons/wrappers";
import SetupHeader from "../commons/SetupHeader";
import styled from "@emotion/styled/macro";
import { Select, message } from "antd";
import SearchComponent from "./../../../components/common/SearchComponent";
import ReportsList from "./reports-list";
//import { getUsersList } from "./../../api/api";
import { reportListApi, deleteReportApi, updateReportApi } from "../../api/api";
import { debounce } from "lodash";

const ReportSharingFrequencyDropdown = styled(Select)`
  width: 138px;
  margin: 18px 4px 0 0;
`;
const ReportSharingFields = styled.div`
  display: -webkit-inline-box;
  display: -ms-flexbox;
`;
class ReportSharing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFrequency: undefined,
      recipient_name: undefined,
      tableData: [],
      is_active: undefined,
      isLoading: true,
      page: 1,
      showInfo: false,
    };
    this.dataSource = [];
  }

  handleChangeDropdown = (value) => {
    this.setState({ reportFrequency: value }, this.applyFilters);
  };

  handleSearchValue = (value) => {
    const text = value;
    //this.setState({ name: value }, this.applyFilters);
    this.debouncingFunction(text);
  };
  debouncedSearchValue = (text) => {
    this.setState({ recipient_name: text }, this.applyFilters);
  };

  applyFilters = () => {
    this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
      this.dataSource = [];
      this.getReportsList();
    });
  };

  debouncingFunction = debounce(this.debouncedSearchValue, 500);

  deleteReport = (record) => {
    deleteReportApi(record.id).then((res) => {
      this.setState({ isLoading: true }, () => {
        this.getReportsList();
        message.success("Report deleted successfully!");
      });
    });
  };

  updateReport = (record) => {
    updateReportApi(record).then((res) => {
      this.setState({ isLoading: true }, () => {
        this.getReportsList();
        message.success("Report updated successfully!");
      });
    });
  };

  trackScrolling = () => {
    const tabDocument = document.getElementsByClassName(
      "setup-tab-report-sharing"
    )[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    };
    if (node && isBottom(node)) {
      node.removeEventListener("scroll", this.trackScrolling);
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => {
          this.getReportsList();
        }
      );
    }
  };

  getReportsList = () => {
    const { page, recipient_name, reportFrequency } = this.state;
    const filters = {
      page,
      recipient_name: recipient_name ? recipient_name : undefined,
      reportFrequency:reportFrequency ? reportFrequency : undefined,
    };
    const tabDocument = document.getElementsByClassName(
      "setup-tab-report-sharing"
    )[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener("scroll", this.trackScrolling);
    reportListApi(filters).then((res) => {
      if (res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if (node) {
          node.addEventListener("scroll", this.trackScrolling);
        }
      }

      let reportsList =
        (res.results &&
          res.results.filter(
            (item) => item.sender === this.props.app_user.id
          )) ||
        [];
      let data =
        this.state.page === 1
          ? reportsList
          : this.dataSource.concat(reportsList);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });
    });
  };

  componentDidMount() {
    this.getReportsList();
  }
  render() {
    const { Option } = Select;
    return (
      <SetupWrapper className="setup-tab-report-sharing">
        <SetupHeader
          title="Report Sharing"
          icon="report-share"
          description="Choose the audience and frequency of the report being sent."
          openInfo={() => this.setState({ showInfo: true })}
        />
        <ReportSharingFields>
          <ReportSharingFrequencyDropdown
            value={this.state.reportFrequency}
            onChange={this.handleChangeDropdown}
            placeholder="Frequency"
            allowClear
          >
            <Option value="1">Daily</Option>
            <Option value="2">Weekly</Option>
            <Option value="3">Monthly</Option>
          </ReportSharingFrequencyDropdown>
          <FilterWrapper>
            <SearchComponent
              placeholder="Search Recipients"
              value=""
              handleSearchValue={this.handleSearchValue}
            />
          </FilterWrapper>
        </ReportSharingFields>
        <LineSeparator />
        <ReportsList
          tableData={this.state.tableData}
          isLoading={this.state.isLoading}
          deleteReport={this.deleteReport}
          updateReport={this.updateReport}
        />
      </SetupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app_user: state.app_user,
  selectedWorkspace: state.app_user.selectedWorkspace,
});

export default connect(mapStateToProps, null)(ReportSharing);
