import React, { useState, useEffect, useCallback } from "react";
import RemovableEntry from "./../RemovableEntry";
import { ReactComponent as CollapseIcon } from "../../assets/collapse-icon.svg";
import { ReactComponent as ConflictIcon } from "../../assets/conflict-icon.svg";
import { getDuplicateIntents } from "../../api/api";
import { debounce } from "lodash";
import { StylePhraseInputWrapper, StyledPhraseInput, ConflictsWrapper, CollapseIconWrapper, ConflictsInnerWrapper, ConflictRules, ConflictIconWrapper } from './wrappers';
import appConfig from "../../../config";

const PhraseInput = ({
  removePhrase,
  onChange,
  phrase,
  uniqueID,
  index,
  intentId,
  onValidate,
  editConflictRule,
  disabled,
  removable,
  placeholder,
  phraseList
}) => {
  const [isCollapsed, udpateIsCollapsed] = useState(true);
  const [conflicts, setConflicts] = useState(phrase.conflicts || []);
  const getConflicts = useCallback(debounce((text) => {
    if (!text?.length) {
      onValidate(uniqueID, true);
      setConflicts([]);
      return;
    }
    getDuplicateIntents([text]).then(
      (res) => {
        let conflictsList = [];
        if (res?.matched_intents?.length) {
          for (let i = 0; i < res.matched_intents.length; i++) {
            conflictsList = conflictsList.concat(res.matched_intents[i]);
          }
        }
        const filteredConflicts = conflictsList.filter(
          (item) => item.id !== intentId
        );
        onValidate(uniqueID, filteredConflicts.length === 0);
        setConflicts(filteredConflicts);
      },
      (err) => {
        onValidate(uniqueID, true);
        setConflicts([]);
      }
    );
  }, 800), []) ;
  const debounceHandleChange = useCallback(debounce(onChange, 100), [phraseList]);
  const handleChange = (event) => {
    const text = event.currentTarget.value;
    getConflicts(text);
    debounceHandleChange(uniqueID, text);
  };
  const handleDelete = () => {
    removePhrase(uniqueID);
  };
  useEffect(() => {
    setConflicts(phrase.conflicts || []);
  }, [phrase.conflicts]);
  const conflictsCount = conflicts.length
    ? conflicts.map((item) => item.phrases.length).reduce((a, b) => a + b, 0)
    : 0;
  return (
    <ConflictsWrapper key = {uniqueID}>
      <RemovableEntry
        disabled={disabled && !removable}
        onRemove={handleDelete}
      >
        <StylePhraseInputWrapper>
          <StyledPhraseInput
            id={phrase.id}
            name="phrase"
            placeholder={`${placeholder ? placeholder : "Example phrase"} ${
              index + 1
            }`}
            onChange={handleChange}
            defaultValue={phrase.text}
            conflict={conflicts.length}
            disabled={disabled}
          />
          {appConfig.showIntentConflict && conflicts.length ? (
            <ConflictIconWrapper>
              <ConflictIcon /> {conflictsCount}
            </ConflictIconWrapper>
          ) : null}
        </StylePhraseInputWrapper>
      </RemovableEntry>
      {appConfig.showIntentConflict && conflicts.length ? (
        <ConflictsInnerWrapper>
          <CollapseIconWrapper
            isCollapsed={isCollapsed}
            onClick={() => {
              udpateIsCollapsed(!isCollapsed);
            }}
          >
            <CollapseIcon />
            <div className="title">Conflicts</div>
          </CollapseIconWrapper>
          {!isCollapsed ? (
            <ConflictRules>
              {conflicts.map((item, index) => {
                return (
                  <div key={index} className="conflict-item">
                    <div className="rule-meta-details">
                      <div
                        onClick={() => editConflictRule(item.id)}
                        className="rule-title"
                      >
                        {item.description}
                      </div>
                    </div>
                    <div className="rule-phrases">
                      {item.phrases.map((conflictPhrase, phraseIndex) => {
                        return (
                          <div className="rule-phrase" key={phraseIndex}>
                            <div className="rule-phrase-title">
                              {conflictPhrase}
                            </div>
                            <div className="conflict-icon-wrapper">
                              <ConflictIcon />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </ConflictRules>
          ) : null}
        </ConflictsInnerWrapper>
      ) : null}
    </ConflictsWrapper>
  );
};

export default PhraseInput;
