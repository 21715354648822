import styled from "@emotion/styled/macro";

export const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .details-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 24px;
    }
    .title {
        font-size: 24px;
        line-height: 28px;
        color: #4F4F4F;
        padding: 0;
        margin-top: 8px;
    }
    .description {
        font-size: 16px;
        line-height: 19px;
        color: #828282;
        margin-top: 3px;
    }
`;

export const DetailsLoadingWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const RuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 687px;
`;

export const RuleDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    .ant-collapse {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        margin-top: 12px;
        width: 100%;
    }
    .ant-collapse-content {
        border: none;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: none;
    }
    .ant-collapse-content-box, .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
        padding: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {  
        padding: 20px 16px;
        padding-left: 40px;
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
        padding-top: 16px;
    }
    .group-container {
        display: flex;
        align-items: center;
        padding: 12.5px;
        height: 64px;
    }
    .collapse-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .collapse-action {
            display: flex;
            align-items: center;
            transition: opacity 0.3s;
            .collapse-answer {
                margin-right: 22px;
                color: #4F4F4F;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .collapse-action{
        opacity: 0;
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    button {
        width: 108px;
        border: 1px solid #0B4B93;
    }
    button:submit {
        background: #0B4B93;
    }
    button.submit, button.verify {
        border: none;
        font-weight: normal;
        margin-left: 4px;
    }
    button.verify {
        background: #27AE60;
        color: white;
        border: none;
    }    
`;