import { combineReducers } from "redux";
import userManagement from "./setup/userManagementReducer";
import userReducer from "./login/userReducer";
import listfilterReducers from "./conversation-list/filters";
import dashboardReducers from "./analytics/dashboard";
import notificationsReducer from "./notifications/notifications";
import keyInteractionReducer from "./dashboard/key-interactions";
import conversationMetricsReducer from "./conversation-metrics";
import qaCalibrationReducer from "./qa-calibration/qaCalibrationReducer";
import tenantReducer from './tenant';
import integrationConfigReducer from './login/integrationConfigReducer';
import selfServeCustomFilters from './selfServeCustomFilters/selfServeCustomFilters';
import qaCaseAssignmentReducer from "./setup/qaCaseAssignmentReducer";
import rubricReducer from "./conversation-details/rubricReducer";
import taskQueueReducer from "./dashboard/taskQueueReducer";

const appReducer = combineReducers({
  users: userManagement,
  app_user: userReducer,
  list_filters: listfilterReducers,
  dashboard: dashboardReducers,
  notifications: notificationsReducer,
  keyInteraction: keyInteractionReducer,
  taskQueue: taskQueueReducer,
  conversationMetrics: conversationMetricsReducer,
  qaCalibration: qaCalibrationReducer,
  tenant: tenantReducer,
  integrationConfig: integrationConfigReducer,
  selfServeExternalData: selfServeCustomFilters,
  qaCaseAssignmentData: qaCaseAssignmentReducer,
  rubricDetailsData: rubricReducer
});

export default appReducer;
