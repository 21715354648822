import React from 'react';
import { Button } from 'antd';
import { ReactComponent as RemoveIcon } from '../../assets/remove.svg';
import styled from "@emotion/styled/macro";

const RuleRowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 6px;
    justify-content: flex-start;    
    width: 100%;
    button.no-style {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
`;

const RemovableEntry = (props) => (
    <RuleRowWrapper style={props.wrapperStyle}>
        {props.children}
        <Button style={props.buttonStyle} onClick={(e) => {
            if(!props.disabled) {
                props.onRemove(props)
            }
            else {
                e.stopPropagation();
            }
        }} className="no-style">
            {!props.disabled && <RemoveIcon />}
        </Button>
    </RuleRowWrapper>
)

export default RemovableEntry;