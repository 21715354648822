const FaqActions = {
    SET_TITLE: "SET_TITLE",
    SET_PHRASE: "SET_PHRASE",
    SET_ANSWER: "SET_ANSWER",
    ADD_PHRASE: "ADD_PHRASE",
    REMOVE_PHRASE: "REMOVE_PHRASE",
    SET_VALIDATION: "SET_VALIDATION",
    SET_DATA: "SET_DATA",
    END_LOADING: "END_LOADING",
    SELECT_MENU: "SELECT_MENU",    
    REMOVE_ANNOTATED_PHRASE: "REMOVE_ANNOTATED_PHRASE",
}

export const faqActions = {
    selectMenu: (selectedMenuItem, dispatch) => {
        dispatch({
            type: FaqActions.SELECT_MENU,
            payload: selectedMenuItem
        });
    },
    setTitle: (value, dispatch) => {
        dispatch({
            type: FaqActions.SET_TITLE,
            payload: value
        });
    },
    setPhrase: (text, id, dispatch) => {
        dispatch({
            type: FaqActions.SET_PHRASE,
            payload: { text, id }
        });
    },
    setAnswer: (value, dispatch) => {
        dispatch({
            type: FaqActions.SET_ANSWER,
            payload: value
        });
    },
    addPhrase: (dispatch) => {
        dispatch({
            type: FaqActions.ADD_PHRASE
        })
    },
    removePhrase: (index, dispatch) => {
        dispatch({
            type: FaqActions.REMOVE_PHRASE,
            payload: index
        })
    },
    removeAnnotatedPhrase: (index, dispatch) => {
        dispatch({
            type: FaqActions.REMOVE_ANNOTATED_PHRASE,
            payload: index
        })
    },
    setValidation: (validation, dispatch) => {
        dispatch({
            type: FaqActions.SET_VALIDATION,
            payload: validation
        })
    },
    setData: (data, dispatch) => {
        dispatch({
            type: FaqActions.SET_DATA,
            payload: data
        });
    },
    endLoading: (dispatch) => {
        dispatch({
            type: FaqActions.END_LOADING,
        })
    }
}

export const faqReducer = (state, action) => {
    if(action.type === FaqActions.SET_TITLE) {
        return {
            ...state,
            title: action.payload,
            modified: true,
        };
    }
    else if(action.type === FaqActions.SET_PHRASE) {
        const { id, text } = action.payload;
        const phrases = state.phrases ? [...state.phrases] : [];
        let updatedPhrases = phrases.map((item) => {
            if(item.id === id) {
                item.text = text;
            }
            return item;
        })
        return {
            ...state,  
            phrases: updatedPhrases,     
            modified: true,     
        };
    }
    else if(action.type === FaqActions.SET_ANSWER) {
        return {
            ...state,
            answer: action.payload,
            modified: true,
        };
    }
    else if(action.type === FaqActions.ADD_PHRASE) {
        let phrases = state.phrases && state.phrases.length ? [...state.phrases] : [];
        const usedIndices = phrases.map((i) => {
            const index = parseInt(i.id, 10);
            return Number.isNaN(index) ? 0 : index;
        });
        const lastIndex = Math.max(...usedIndices, ...[0]);
        phrases.push({
            id: `${lastIndex+1}`,
            text: ""
        })
        return {
            ...state,
            phrases: phrases,
            modified: true,
        };
    }
    else if(action.type === FaqActions.REMOVE_PHRASE) {
        const phrases = [...state.phrases];
        phrases.splice(action.payload, 1);
        return {
            ...state,
            phrases: phrases,
            modified: true,
        };
    }
    else if(action.type === FaqActions.REMOVE_ANNOTATED_PHRASE) {
        const annotatedPhrases = [...state.annotatedPhrases];
        annotatedPhrases.splice(action.payload, 1);
        return {
            ...state,
            annotatedPhrases: annotatedPhrases,
            modified: true,
        };
    }
    else if(action.type === FaqActions.SET_VALIDATION) {
        return {
            ...state,
            validation: action.payload
        };
    }
    else if(action.type === FaqActions.SET_DATA) {
        return {
            ...state,
            title: action.payload.title,
            phrases: action.payload.phrases.map((phrase, index) => {
                return {
                    id: `${index}`,
                    text: phrase
                }
            }),
            annotatedPhrases: action.payload.annotated_phrases.map((phrase, index) => {
                return {
                    id: `${index}`,
                    text: phrase
                }
            }),
            answer: action.payload.answer,
            loading: action.payload.loading,
            rule_audit: action.payload.rule_audit,
            modified: false,
        };
    }
    else if(action.type === FaqActions.END_LOADING) {
        return {
            ...state,
            loading: false
        };
    }
    else if(action.type === FaqActions.SELECT_MENU) {
        return {
            ...state,
            selectedMenuItem: action.payload
        };
    }
}

export const getInitialState = () => {
    return {
        loading: true,
        title: "",
        phrases: [{
            id: "0",
            text: ""
        }],
        annotatedPhrases: [],
        answer: "",
        validation: "",
        selectedMenuItem: "edit",
        rule_audit: null,
        modified: false,
    }
}
