import React, { useState, useEffect } from "react";
import { Input, Select } from "antd";
import useStore from "../store";

const ConditionNodeInput = ({ data, nodeId }) => {
  const updateConditionNode = useStore((state) => state.updateConditionNode);
  const findNodeById = useStore((state) => state.findNodeById);
  const [preFilled, setPreFilled] = useState(false);

  const { Option } = Select;
  const [formData, setFormData] = useState({
    variable: undefined,
    operation: undefined,
    value: undefined,
  });

  useEffect(() => {
    const node = findNodeById(nodeId);
    if (node?.data?.conditionData) {
      setFormData({ ...node.data.conditionData });
      setPreFilled(true);
    }
  }, []);

  useEffect(() => {
    if (formData.variable && formData.operation && !preFilled) {
      let oppositeConditionData = { ...formData };
      oppositeConditionData.operation = data.operations.find(
        (oper) => oper.name === formData.operation
      )?.opposite;

      updateConditionNode(nodeId, {
        conditionData: { ...formData },
        oppositeConditionData,
      });
    }
  }, [formData, nodeId, updateConditionNode, data.operations, preFilled]);

  const handleChange = (val, name) => {
    setPreFilled(false);
    setFormData((p) => {
      return {
        ...p,
        [name]: val,
      };
    });
  };

  return (
    <div className="d-flex flex-column" onClick={(e) => e.stopPropagation()}>
      <Select
        style={{ width: "100%" }}
        className="mb-2"
        onChange={(val) => handleChange(val, "variable")}
        value={formData.variable}
        placeholder="choose variable"
        showSearch
      >
        {data?.globalVariables.map((item) =>
          item["variables"].map(
            (variable) =>
              variable.type !== "object" && (
                <Option key={variable.name} value={variable.name}>
                  {" "}
                  {variable.displayName}{" "}
                </Option>
              )
          )
        )}
      </Select>
      <Select
        style={{ width: "100%" }}
        className="mb-2"
        onChange={(val) => handleChange(val, "operation")}
        value={formData.operation}
        placeholder="operation"
      >
        {data?.operations?.map((oper) => (
          <Option key={oper.name}>{oper.name}</Option>
        ))}
      </Select>
      <Input
        placeholder="Type your input"
        onChange={(e) => handleChange(e.target.value, "value")}
        value={formData.value}
      />
    </div>
  );
};

export default ConditionNodeInput;
