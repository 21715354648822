import styled from "@emotion/styled";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const ParentWrapper = styled.div`
  height: 540px;
`;
export const RenderFilterWrapper = styled.div`
  height: 478px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
`;
export const TabsWrapper = styled.div`
  height: 100%;
  & .ant-tabs-left-bar {

    width: 35%;

    & .ant-tabs-nav-container-scrolling {
      padding: 10px 0 !important;
    }

    & .ant-tabs-tab {
      text-align: left;
      margin: 0 0 4px 0;
      padding: 8px 14px;
      color: #828282;
      &:hover {
        color: #4F4F4F;
      }
    }

    & .ant-tabs-tab-active.ant-tabs-tab {
      background: #F2F2F2;
      color: #4F4F4F;
    }

    & .ant-tabs-nav-container {
      height: 100%;

      & .ant-tabs-tab-prev {
        display: none;
      }
      & .ant-tabs-tab-next {
        display: none;
      }
      & .ant-tabs-nav-wrap {
        overflow-y: auto;
      }
    }
  }

  & .ant-tabs-content {
    height: 100%;
    & .ant-tabs-tabpane.ant-tabs-tabpane-active {
      height: 100%;
    }
  }
`;

export const TabTitleWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
& .check-circle {
  width: 14px;
  height: 16px;
  & path {
    fill: #6099D9;
  }
}
`;
export const OptionsWrapper = styled.div``;

export const FilterWrapper = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: auto;
`;
export const Wrapper = styled.div`
  //position: absolute;
  //bottom: 5%;
  width: 100%;
  padding: 5px 20px 5px 15px;
  & .reset-button {
    text-align: end;
    color: ${(props) => (props.disabled ? "#BDBDBD" : "black")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    width: 100%;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: baseline;
`;
export const ResetAllButton = styled.div`
  color: ${(props) => (props.disabled ? "#BDBDBD" : "black")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

export const CustomTabPane = styled(TabPane)`
`;

export const CheckMarkFilterWrapper = styled.div`
  max-height: 83%;
  overflow-y: auto;
  text-transform: capitalize;
  & .ant-checkbox-wrapper {
    margin-bottom: 15px;
    margin-left: 0px;
  }
  & .ant-radio-wrapper {
    margin-bottom: 15px;
  }
`;

export const SearchWrapper = styled.div`
  width: 100%;
  & .ant-input-prefix {
    top: 60%;
    left: 10px;
  }
  & .ant-input-suffix {
    top: 60%;
    right: 15px;
  }
`;

export const AdditionalFilterWrapper = styled.div`
.ant-badge-count {
  top: 5px;
}
`;

export const OverlayWrapper = styled.div`
position: absolute;
  top: 0%;
  height: 100%;
  background: aliceblue;
  width: 100%;
  z-index: 10;
  display: flex;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`
