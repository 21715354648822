import Constants from "../CompanySetup/utils/constants";
import { CALIBRATION_STATUS_TYPES } from "./config";

export const checkIfComplete = (data={}) => {
  if(!Object.keys(data).length) {
    return false;
  }
  let allComplete = true;
  //const moderatorID = data.moderator.id;
  data.participant_responses.forEach(category => {
    category.sections.forEach(section => {
      section.questions.forEach(question => {
        if(!question.options.some((option) => option.is_selected)) {
          allComplete = false;
          return false;
        }
      })
    })
  })
  return allComplete;
};

export const checkAccess = (user, data, calibrationStatus) => {
  if((user?.id === data?.moderator?.id) && (calibrationStatus && calibrationStatus !== CALIBRATION_STATUS_TYPES.COMPLETED)) {
    return true;
  }

  return false;
}

export const createCalibrationData = (data, calibrationData, setCalibrationData) => {
  if(calibrationData.length === 0) {
    setCalibrationData([data]);
  } 
  else {
    let exists = false;
    let updatedCalibrationData = calibrationData.map(item => {
      if(item.category === data.category) {
        if(item.section === data.section) {
          if(item.question === data.question) {
            exists = true;
            item.score = data.score;
            item.selected_option= data.selected_option;
            return item;
          }
        }
      }
      return item;
    });
    if(exists) {
      setCalibrationData(updatedCalibrationData);
    }
    else {
      updatedCalibrationData = updatedCalibrationData.concat(data);
      setCalibrationData(updatedCalibrationData);
    }
  }
}

export const createQueryParam = (roles, joinCondition) => {
  let queryParam = "";
  if(joinCondition === Constants.ROLE_CONSTANTS.JOIN_TYPE.OR) {
    for(let i =0; i < roles.length ; i++) {
      if(i === 0) {
        queryParam+=`role=${roles[i]}`;
      }
      else {
        queryParam+=`&role=${roles[i]}`;
      }
    }
  }
  else {
    queryParam = `role=`+String(roles);
  }

  return queryParam;
}

export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
