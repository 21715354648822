import { reduxBatch } from "@manaflair/redux-batch";
import { createStore, applyMiddleware } from "redux";
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,  
} from "react-redux";
import appReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

const sagaMiddleware = createSagaMiddleware();
export default createStore(
  appReducer,
  composeWithDevTools(reduxBatch, applyMiddleware(sagaMiddleware, thunkMiddleware), reduxBatch),
);
sagaMiddleware.run(rootSaga);

export const useSelector = useReduxSelector;
export const useDispatch = useReduxDispatch;
