import React, { useState, useEffect } from "react";
import { euclideanDistance } from "../../utils";
import CustomInput from "../components/CustomInput";
import useStore from "../store";
import { StyledHandle, StyledNode } from "./styles";

const GenericNode = ({ children, nodeData, noInput = false, nodeType }) => {
  const selectNode = useStore((state) => state.selectNode);
  const selectedNode = useStore((state) => state.selectedNode);
  const findNodeById = useStore((state) => state.findNodeById);
  const [node, setNode] = useState();

  useEffect(() => {
    setNode(findNodeById(nodeData.id));
  }, []);

  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  });

  const handleNodeClick = (e) => {
    if (euclideanDistance(e.clientX, e.clientY, pos.x, pos.y) < 10) {
      if (selectedNode && selectedNode?.id === nodeData?.id) {
        selectNode(null);
        return;
      }
      selectNode(nodeData.id);
    }
  };

  return (
    <StyledNode error={node?.error}>
      {nodeType !== "trigger" && (
        <StyledHandle type="target" position="top" id="top" source={true} />
      )}
      <div
        onMouseUp={handleNodeClick}
        onMouseDown={(e) => setPos({ x: e.clientX, y: e.clientY })}
      >
        {children}
      </div>
      {noInput === false && selectedNode && selectedNode.id === nodeData.id && (
        <div className="p-3 pt-0">
          <CustomInput />
        </div>
      )}
      {nodeType === "condition" ? (
        <>
          <StyledHandle type="source" position="left" id="left" />
          <StyledHandle type="source" position="right" id="right" />
        </>
      ) : (
        <StyledHandle type="source" position="bottom" id="bottom" />
      )}
    </StyledNode>
  );
};

export default GenericNode;
