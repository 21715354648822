import React, { useEffect, useState } from "react";
import { newNotifications, requestBulkNotifications, requestNextPageBulkNotifications } from "./api";
import { useSelector, useDispatch } from "../store";


export const useNotifications = () => {
    const dispatch = useDispatch()
    const [ page, setPage ] = useState(1)
    const notifications = useSelector(state => state.notifications.notifications)
    const paginating = useSelector(state => state.notifications.paginating)
    const loading = useSelector(state => state.notifications.loading)
    const nextPage = useSelector(state => state.notifications.nextPage)

    useEffect(()=>{
        dispatch(newNotifications())
        dispatch(requestBulkNotifications())
    }, [])

    useEffect(() => {
        if (page > 1) {
            dispatch(requestNextPageBulkNotifications(page))
        }
    }, [page])

    const loadNextPage = () => {
        setPage(page=>page+1);
    }

    return [notifications, loading, loadNextPage, paginating, nextPage];
}