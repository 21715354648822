import Pusher from "pusher-js";
import devConfigs from "./configs/dev.config";
import stagingConfigs from "./configs/staging.config";
import productionConfigs from "./configs/production.config";

export const getPusherConfig = () => {
    const env = process.env.REACT_APP_ENVIRONMENT;
    switch(env) {
        case "PRODUCTION":
            return  new Pusher('c95a031d5b40591cba91', {
                cluster: 'us3'
            });
        case "STAGING":
            return new Pusher("223a8c689830bc35063a", {
                cluster: "us2"
            });
        default:
            return new Pusher("223a8c689830bc35063a", {
                cluster: "us2"
            });
    };
};

function getConfigs(env){
    switch(env) {
        case "PRODUCTION":
            return productionConfigs;
        case "STAGING":
            return stagingConfigs;
        default:
            return devConfigs;
    }    
}

export default {
    ...getConfigs(process.env.REACT_APP_ENVIRONMENT),
}

