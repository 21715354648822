import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import FilterComponent from "./FilterComponent";
import { getWorkspaceList, storeFiltersSegment } from "./../../CompanySetup/api/api";
import { withRouter } from "react-router-dom";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";

const WorkspaceFilter = ({value, handleChange, ...restProps}) => {
    const dispatch = useDispatch();
    const [workspaces, updateWorkspaces] = useState([]);
    const [workspace, updateWorkspace] = useState(value);
    const [otherWorkspace, showOtherWorkspaceOption] = useState(!restProps.location.pathname.includes("setup"));
    const [globalWorkspace, showGloablWorkspaceOption] = useState(restProps.location.pathname.includes("setup/scenarios"));
    useEffect(() => {
        getWorkspaceList({ is_active: true, list_all: true}).then(res => {
            const users = res.results.map((workspace, index) => {
                return {
                    value: workspace.subdomain,
                    label: workspace.name
                }
            });
            updateWorkspaces(users);
        });
    }, []);
    useEffect(() => {
        updateWorkspace(value);
    }, [value]);
    useEffect(() => {
        if(restProps.location.pathname.includes("setup")) {
            if(restProps.location.pathname.includes("scenarios")) {
                showGloablWorkspaceOption(true);
            }
            else {
                showGloablWorkspaceOption(false);
            }
            showOtherWorkspaceOption(false);
            if(workspace === "unspecified") {
                handleChange("");
            }
        }        
        else {
            showGloablWorkspaceOption(false);
            showOtherWorkspaceOption(true);
        }
    }, [restProps.location.pathname]);
    const workspaceOptions = [...workspaces].filter((workspace) => {
        if(!globalWorkspace &&  workspace.value === "global") {
            return false;
        }
        if(!otherWorkspace && workspace.value === "unspecified") {
            return false;
        }        
        return true;
    });
    return (
        <FilterComponent
            options={workspaceOptions}
            placeholder="All Workspaces"
            value={workspace}
            capitalize={true}
            handleChange={(value) => {
                updateWorkspace(value);
                handleChange(value);
                //dispatch({ type: ReduxActionTypes.RESET_GLOBAL_FILTERS })
                dispatch(storeFiltersSegment());
            }}
        />    
    );
}

export default withRouter(WorkspaceFilter);