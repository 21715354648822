import React, { useEffect, useReducer, memo } from "react";
import { Row, Col, Button } from "antd";
import { DeleteFilled, PlusCircleFilled, PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import appConfigs from "../../../config";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { getCustomFilters } from "../../../conversation-list/api";
import { REQUEST_TYPE } from "../../../custom-hooks/config";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import FilterInput from './filter-input';
import { FilterWrapper, FilterRowWrapper, DeleteFilterButton, IconButtonWrapper } from "./style";

const AddFilter = ({ onClick }) => {
  return (
    <Link style={{ fontWeight: "bold" }} onClick={onClick}>
      <PlusCircleFilled className="filter-action" />
      &nbsp;New filter
    </Link>
  );
};

const blankFilter = [{}];

const filterValuesReducer = (state, action) => {
  let filters = [...(state || [])];
  const { index } = action;
  
  switch (action.type) {
    case "ADD":
      filters = [
        ...filters,
        {
          ...action.payload,
        },
      ];
      break;
    case "REMOVE":
      filters.splice(index, 1);
      break;
    case "UPDATE":
      filters[index] = { ...action.payload };
      break;
    case "SET_ALL":
      filters = [...action.payload];
      filters = filters.length === 0 ? blankFilter : filters;
      break;
  }
  action.onChange(
    filters.filter(
      (filter) => filter.key && filter.value && filter.value.length > 0
    )
  );
  return filters;
};

const Filters = ({ value, onChange, onCheckFilters }) => {
  const [filterValues, dispatch] = useReducer(filterValuesReducer, undefined);
  const [callApi, cancelRequests] = useReq();

  useEffect(() => {
    if (filterValues) return;
    if (value) {
      dispatch({
        type: "SET_ALL",
        payload: [...value],
        onChange: () => {},
      });
    }
  }, [value]);

  useEffect(() => {
    if (!appConfigs.ENABLE_CUSTOM_COLUMNS) return;
      callApi(getCustomFilters, (res) => {

      }, (err) => {
        if(err && err.message === DASHBOARD_OPTIONS.CANCEL_REQUEST) {
          return;
        }
      }, {
  
      }, () => {
  
      }, REQUEST_TYPE.DISPATCH_REQUEST);
    return () => {
      cancelRequests();
    }
  }, []);

  const filters = [...(filterValues || [])];

  return (
    <FilterWrapper>
      {
        filters.map((filterValue, index) => (
          <div className="filter-row-container">
            <FilterRowWrapper>
              <FilterInput
                  filterValue={filterValue}
                  onChange={(key, value) => {
                    dispatch({
                      type: "UPDATE",
                      payload: {
                        key,
                        value,
                        operator: "equals",
                      },
                      index,
                      onChange,
                      onCheckFilters
                    });
                  }}
              />
            </FilterRowWrapper>
            
            <IconButtonWrapper>
              <Link
                onClick={() => {
                  dispatch({
                    type: "REMOVE",
                    index,
                    onChange,
                    onCheckFilters
                  });
                }}
              >
                <DeleteFilled />
              </Link>
            </IconButtonWrapper>
          </div>
        ))
      }

      <Button 
        className="add-filter-btn" 
        onClick={() => {
          dispatch({
            type: "ADD",
            payload: {},
            onChange,
            onCheckFilters
          });
        }}
      >
        <PlusCircleOutlined />
        Add filter
      </Button>
    </FilterWrapper>
  );
};

export default memo(Filters);
