import ActionNode from "./actionNode";
import ConditionNode from "./conditionNode";
import ManipulationNode from "./ManipulationNode";
import OperationNode from "./operationNode";
import TriggerNode from "./TriggerNode";

const nodeTypes = {
  condition: ConditionNode,
  operation: OperationNode,
  manipulation: ManipulationNode,
  action: ActionNode,
  trigger: TriggerNode,
};

export default nodeTypes;
