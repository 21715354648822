import styled from "@emotion/styled/macro";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

export const ButtonSeperator = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  .lines {
    height:1px; 
    position: relative;
    width: 50%;
    background: #f5f5f5;
  }

  .text {
    margin: 0 18px;
    color: #8e8e8e;
    font-weight: 600;
    user-select: none;
  }
`;

export const TextFieldWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const FieldWrapper = styled.div`
display: flex;
flex-direction: column;
row-gap: 5px;
margin-bottom: 5px;
.field-label {
  font-weight: 500;
}

.input-field-saml {
  padding: 4px 10px !important;
}

.text-area-field-saml {
  & textarea {
    padding: 4px 10px !important;
  }
}
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  column-gap: 5px;
  //border-top: 1px solid #bdbdbd;
  button.secondary {
    margin-right: 4px;
    background: #ffffff;
    border: 1px solid #0b4b93;
    color: #0b4b93;
  }
  button.primary,
  button.secondary {
    width: 96px;
    height: 32px;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const HeaderWrapper = styled.div`
display: flex;
width: 100%;
justify-content: space-between;

& svg {
  cursor: pointer;
  & > path {
    fill: #828282;
  }
  transition: 0.5s ease;

  &:hover {
    & > path {
      fill: black;
      transition: 0.5s ease;
    }
  }
}
`;

export const HeadingWrapper= styled.div``;

export const Heading = styled.div`
display: flex;
flex-direction: column;
font-size: 18px;
margin-bottom: 5px;
font-weight: 500;
`;

export const DescriptionWrapper = styled.div`
font-size: 15px;
line-height: 20px;
margin-bottom: 20px;
color: #828282;
`;

