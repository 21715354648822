import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "./../../constants/ReduxActionConstants";

const userDetails = {}

const initialState = {
  _integrationConfig: {}
};

const integrationConfigReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_INTEGRATION_CONFIG]: (
    state,
    action,
  ) => {
    return {
      ...state,
      _integrationConfig: action.payload
    };
  }
});

export default integrationConfigReducer;
