import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import FilterComponent from "./../components/common/FilterComponent";
import AgentsFilter from "./../components/common/AgentsFilter";
// import ActionsFilter from "./../components/common/ActionsFilter";
// import FlagsFilter from "./../components/common/FlagsFilter";
import ConversationTagFilter from "./../components/common/ConversationTagFilter";
import TopicsFilter from "./../components/common/TopicsFilter";
import InstaReviewFilter from "../components/common/InstaReviewFilter";
import CustomFilter from "../components/common/CustomFilters";
import TimeGranularityFilter from "./../components/common/TimeGranularityFilter";
import StatusFilter from "./../components/common/StatusFilter";
import DateFilter from "./../components/common/DateFilter";
import MetricTagsFilter from "./../components/common/MetricTagsFilter";
import SearchComponent from "./../components/common/SearchComponent";
import { FiltersWrapper, FilterComponentWrapper } from "./wrappers";
import { StyledCheckbox } from "../components/common/styled-components";
import OrganizationFilter from "../components/common/OrganizationFilter";
import QaStatusFilter from "../components/common/QaStatusFilter";
import CalibrationStatusFilter from "../components/common/CalibrationStatusFilter";
import ModeratorFilter from "../components/common/ModeratorFilter";
import { getSchemaName } from "../api/api";
import { getCustomFilters } from "./api";
import { message } from "antd";
import styled from "@emotion/styled";
import { addAnalyticsEvent } from '../../src/utils'
import {HeapAnalyticsEvent} from '../components/common/HeapAnalyticsEvent';
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import { handleCubeFilterUpdate } from "../utils/utilities";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "../store";
import { filtersFromURLParams } from "../utils/utilities";
import { storeFilters } from './api';
import { hasManagerRights, hasQAAuditorRights } from '../AnalyticsDashboard/config';

const FilterCustomWrapper = styled(FilterComponentWrapper)`
  display: flex;
`;

const Filter = (props) => (
  <FilterComponentWrapper>
    <FilterComponent
      placeholder={props.placeholder}
      multiSelect={props.channelMultiSelect}
      value={props.value}
      maxTagCount= {1}
      showSearchIcon={props.showSearchIcon}
      width={props.width}
      handleChange={(value) => {
        props.handleChange(value);
      }}
      options={props.options}
      showSearch={props.showSearch}
      hideClear={props.hideClear}
    />
  </FilterComponentWrapper>
);

const channelOptions = [
  {
    label: "Call",
    value: "1",
  },
  {
    label: "Chat",
    value: "2",
  },
  {
    label: "Email",
    value: "4",
  },
  {
    label: "Slack",
    value: "5",
  }
];

const Filters = (props) => {
  const dispatch = useDispatch();
  const customFilters = useSelector(state => state.dashboard.customFilters);
  const hideFollowed = useSelector(state => state.dashboard.hideFollowed);
  const { 
    updateFilters,
    storeGlobalFilters,
  } = props;

  const filters = useSelector(state => state.dashboard.globalFilters);
  const selectedWorkspace = useSelector(state => state.app_user.selectedWorkspace); 
  //const customFilters = useSelector(state => state.dashboard.customFilters);
  const history = useHistory()
  
  const handleChange = (key, value, label, idValues, expectationtype) => {
    if (value !== undefined && value.length !== 0) {
      addAnalyticsEvent(HeapAnalyticsEvent.Detect_Filters,{ filterName : key })  //used to handle heap analytics
    }
    //remove idVlues once filter by id is supported on BE
    const filterValue = label ? label : value;
    const globalQAFilters = handleCubeFilterUpdate(key, filterValue, idValues, expectationtype);
    updateFilters(key, filterValue)
    dispatch(storeFilters(globalQAFilters));
  };

  useEffect(() => {
    let queryParams = {
      ...filters,
      ...(selectedWorkspace && { workspace: selectedWorkspace })
    };
    if (!filters) { return; }
    if (queryParams.date && Array.isArray(queryParams.date)) {
      queryParams.date = JSON.stringify(queryParams.date);
    }

    delete queryParams.dateRangeValues;
    delete queryParams.dateRange;

    history.push({
      search: QueryString.stringify(queryParams),
    });
  }, [filters, selectedWorkspace])

  return (
    <FiltersWrapper>
      {
      props.showDateFilter ? <DateFilter
        value={filters.date}
        handleChange={(value) => {
          handleChange("date", value);
        }}
      /> : null
      }
      {  
      props.showConversationSearchInFilter ? (
        <FilterComponentWrapper>
          <SearchComponent
            placeholder="Convo ID."
            value={filters.searchValue}
            width={160}
            handleSearchValue={(value) => {
              handleChange("searchValue", value);
            }}
          />
        </FilterComponentWrapper>
      ) : null}
      {
        ((hasManagerRights() || hasQAAuditorRights()) && props.showAgentFilter) ? (
        <FilterComponentWrapper>
          <AgentsFilter
            agentMultiSelect={props.agentMultiSelect}
            value={filters.user}
            handleChange={(value) => {
              handleChange("user", value);
            }}
          />
        </FilterComponentWrapper>
      ) : null
    }
     { props.showChannelFilter ? <Filter
        placeholder="All Channels"
        channelMultiSelect={props.channelMultiSelect}
        options={channelOptions}
        value={filters.channel}
        showSearchIcon={true}
        showSearch={true}
        hideClear={false}
        width={160}
        handleChange={(value) => {
          handleChange("channel", value);
        }}
      /> : null
      }

      {
      ((getSchemaName() == "infocision" ||
      getSchemaName() == "level" ||
      getSchemaName() == "bpo") && (props.showOrganizationFilter)) ? (
        <FilterComponentWrapper>
          <OrganizationFilter
            value={filters.organization}
            handleChange={(value) => {
              handleChange("organization", value);
            }}
            organizationMultiSelect={props.organizationMultiSelect}
          />
        </FilterComponentWrapper>
      ) : null}
      {props.showQaStatusFilter ? 
        <FilterComponentWrapper>
        <QaStatusFilter
          value={filters.qa_status}
          qaStatusMultiSelect={props.qaStatusMultiSelect}
          handleChange={(value, label) => {
            handleChange("qa_status", value, label);
          }}
        />
      </FilterComponentWrapper> : null
      }
      {props.showCaseStatusFilter ? (
        <FilterComponentWrapper>
          <StatusFilter
            value={filters.case_status}
            caseStatusMultiSelect={props.caseStatusMultiSelect}
            handleChange={(value, label) => {
              handleChange("case_status", value, label);
            }}
          />
        </FilterComponentWrapper>
      ) : null}
        {/* name needs to be changed from topics filter to category filter */}
      {props.showCategoryFilter ? (
        <FilterComponentWrapper>
          <TopicsFilter
            value={filters.category}
            categoryMultiSelect={props.categoryMultiSelect}
            handleChange={(value) => {
              handleChange("category", value);
            }}
          />
        </FilterComponentWrapper>
      ) : null}
      {props.showTimeGranularity ? (
        <FilterComponentWrapper>
          <TimeGranularityFilter
            value={filters.timeGranularity}
            handleChange={(value) => {
              handleChange("timeGranularity", value);
            }}
          />
        </FilterComponentWrapper>
      ) : null}
      {/* {props.user.is_manager && props.showListFilters && <FilterComponentWrapper>
            </FilterComponentWrapper> : null}             */}
      {/* {props.user.is_manager && props.showListFilters && <FilterComponentWrapper>
                <ActionsFilter 
                    value={filters.action} 
                    handleChange={(value) => {
                        handleChange("action", value);
                    }}
                />
            </FilterComponentWrapper>}*/}
    { props.showMetricTagFilter ? <FilterComponentWrapper>
        <MetricTagsFilter
          value={filters.metric_rule}
          metricMultiSelect={props.metricMultiSelect}
          handleChange={(value) => {
            handleChange("metric_rule", value);
          }}
        />
      </FilterComponentWrapper> : null
      }
      {props.showListFilters && (
        <FilterComponentWrapper>
          <ConversationTagFilter
            componentWidth={200}
            value={filters.conversation_tag_id}
            conversationTagMultiSelect={props.conversationTagMultiSelect}
            handleChange={(value, label, idValues, expectationtype) => {
              handleChange("conversation_tag", value, label, idValues, expectationtype);
            }}
          />
        </FilterComponentWrapper>
      )}

      {
            props.showListFilters && !hideFollowed  && (
              <>
              <FilterComponentWrapper>

                 <StyledCheckbox
                    checked={filters.followed === "Followed"}
                    onChange={(event) => {
                    handleChange(
                      "followed",
                       event.target.checked ? "Followed" : undefined
                    )}}
                 >
                  Followed
              </StyledCheckbox>

             </FilterComponentWrapper>

             <FilterComponentWrapper>

                <StyledCheckbox
                   checked={filters.followed === "Not Followed"}
                   marginLeft={5}
                   onChange={(event) => {
                   const value = event.target.checked;
                   handleChange(
                     "followed",
                      event.target.checked ? "Not Followed" : undefined
                      )}}
                >
                 Not Followed
               </StyledCheckbox>

           </FilterComponentWrapper>
              </>
            )
      }
      {props.showInstaReviewFilter && (
        <FilterComponentWrapper>
          <InstaReviewFilter
            value={filters.insta_review_topics}
            instaReviewMultiSelect={props.instaReviewMultiSelect}
            handleChange={(value) => {
              handleChange("insta_review_topics", value);
            }}
          />
        </FilterComponentWrapper>
      )}
      {
        <>
          {props.showCustomFilters && customFilters &&
            Object.keys(customFilters).length > 0 &&
            Object.entries(customFilters).map(
              ([filterKey, option]) =>
                 (
                  <div style={{ marginRight: "4px", marginTop: "10px" }}>
                    <CustomFilter
                      value = {filters[filterKey]}
                      customFilterMultiSelect = {props.customFilterMultiSelect}
                      handleChange={(value) => {
                        handleChange(filterKey, value);
                      }}
                      options={option}
                      placeholder={filterKey}
                    />
                  </div>
                )
            )}
        </>

        //2 way
        // <FilterComponentWrapper>
        //   {
        //   Object.keys(customFilters).length > 0 && (
        //     <CustomFilter
        //       filters={filters}
        //       customFilters={customFilters}
        //       handleChange={(key, value) => {
        //         handleChange(key, value);
        //       }}
        //     />
        //   )}
        // </FilterComponentWrapper>
      }

      {(hasManagerRights() || hasQAAuditorRights()) &&
        props.showListFilters &&
        !props.hideTaggedForRubric && (
          <FilterComponentWrapper>
            <StyledCheckbox
              checked={filters.tagged_for_qa === true || filters.tagged_for_qa === "true"}
              onChange={(event) => {
                const value = event.target.checked;
                handleChange("tagged_for_qa", value ? value : undefined);
              }}
            >
              Tagged For Rubric
            </StyledCheckbox>
          </FilterComponentWrapper>
        )}

        {
            props.showCalibrationStatusFilter ? (
                <FilterComponentWrapper>
                  <CalibrationStatusFilter
                    value={filters.calibration_status}
                    multiSelect={props.calibrationStatusMultiSelect}
                    handleChange={(value, label) => {
                      handleChange("calibration_status", value, label);
                    }}
                  />
                </FilterComponentWrapper>
              ) : null
        }

        {
            props.user.is_superuser && props.showModeratorFilter ? (
                <FilterComponentWrapper>
                  <ModeratorFilter
                    value={filters.moderator}
                    multiSelect={props.moderatorMultiSelect}
                    handleChange={(value, label) => {
                      handleChange("moderator", value, label);
                    }}
                  />
                </FilterComponentWrapper>
              ) : null
        }
    </FiltersWrapper>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  storeGlobalFilters: (globalFilters) => {
    dispatch({
      type: ReduxActionTypes.SET_GLOBAL_FILTERS,
      payload: globalFilters
    })
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
