import produce from "immer";
import { isNode, removeElements } from "react-flow-renderer";
import { getLayoutedElements } from "../reactFlowUtils";

export const createElementSlice = (set, get) => ({
  elements: [],
  flowError: false,
  setFlowError: (err) =>
    set(
      produce((state) => {
        state.flowError = err;
      })
    ),
  findNodeById: (id) => {
    const elements = get().elements;
    return elements.find((ele) => ele.id === id);
  },
  removeElement: (elementToRemove) => {
    const elements = get().elements;
    if (elementToRemove && elementToRemove[0].type === "trigger") {
      return;
    }
    set(
      produce((state) => {
        state.elements = removeElements(elementToRemove, elements);
      })
    );
  },
  findTarget: (source, sourceHandle) => {
    let elements = get().elements;
    let findNodeById = get().findNodeById;
    let edge = elements.find(
      (ele) =>
        !isNode(ele) &&
        ele?.source === source.id &&
        ele.sourceHandle === sourceHandle
    );

    if (edge) {
      let target = findNodeById(edge.target);
      return target;
    }
    return null;
  },
  alignNodes: (dr = "TB") => {
    const elements = get().elements;
    const selectNode = get().selectNode;
    //unselect node
    selectNode();
    set(
      produce((state) => {
        state.elements = getLayoutedElements(elements, dr);
      })
    );
  },
  isValidName: (name, nodeId) => {
    const elements = get().elements;
    if (
      elements?.length &&
      elements.find(
        (item) => item?.kwargs?.name === name?.trim() && item.id !== nodeId
      )
    )
      return false;
    return true;
  },
  isValidFlow: () => {
    const elements = get().elements;
    const setFlowError = get().setFlowError;
    const names = new Set();
    let flowError = false;

    // some is used so it breaks out of loop as soon as it finds an error
    elements.some((item) => {
      if (isNode(item)) {
        if (item?.kwargs?.name) {
          if (names.has(item.kwargs.name)) {
            flowError = `Duplicate names ,name:${item.kwargs.name} nodeId:${item.id}`;
            return true;
          }
          names.add(item.kwargs.name);
        } else {
          flowError = `Name is missing from node with id ${item.id}`;
          return true;
        }
      }
    });

    setFlowError(flowError);
    return true;
  },
  resetElements: () =>
    set(
      produce((state) => {
        if (state.elements?.length > 0) state.elements = [state.elements[0]];
        state.nextNodeId = 1;
      })
    ),
});
