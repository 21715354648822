import styled from "@emotion/styled/macro";
import { Select, Button, Modal, Input } from "antd";

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CategoryTypeDropdown = styled(Select)`
  width: 138px;
  margin: 2px 4px 0 0;
`;
export const CategoryFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled(Button)`
  padding: 2px 16px;
  background-color: #0b4b93;
  width: 151px;
  height: 32px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const TrainButtonWrapper=styled(Button)`
padding: 2px 16px;
background-color: white;
width: 106px;
height: 32px;
color: black;
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
margin-right:5px;
`

export const ConversationsWrapper = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6099d9;
  text-align: center;
`;

export const ExampleWrapper = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -0.5em;
  .example-terms {
    background: #6fcf97;
    border-radius: 10px;
    color: white;
    margin: 5px 5px 0px 5px;
    padding: 2px 6px;
    &:hover {
      transition: 0.3s ease;
      background: #6099d9;
      cursor: default;
    }
  }
`;

export const AutoDiscoveredWrapper = styled.div`
  color: #bdbdbd;
  font-size: 11px;
  text-align: center;
`;

export const CategoryNameWrapper = styled.div`
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;

  .category-name {
    margin-right: 7px;
  }
  .edit-title {
    color: #6099d9;
    font-size: 10px;
    cursor: pointer;
  }
`;

export const AutoGeneratedWrapper = styled.div`
  background: #e89244;
  border-radius: 10px;
  width: auto;
  color: white;
  font-size: 10px;
  padding: 0px 8px;
  margin-right: 5px;
`;

export const DescendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #6099d9;
    }
  }
`;

export const AscendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    path {
      fill: #6099d9;
    }
  }
`;

export const SortIconWrapper = styled.div`
  margin-left: 1px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #bdbdbd;
    }
  }
`;

export const SortableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 21px;
  cursor: pointer;
  color: ${(props) =>
    props.order === "dsc" || props.order === "asc" ? "#6099D9" : "#BDBDBD"};
`;

export const Spinner = styled.div`
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 10;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-height: 780px;
    position: relative;
  }
`;
export const ModalWrapper = styled.div`
  width: 100%;
  min-height: 640px;
  max-height: 780px;
`;

export const ModalHeaderWrapper = styled.div`
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #bdbdbd;
`;
export const ConversationModalHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6099d9;
  }
`;

export const ConversationModalFieldContainer = styled.div``;

export const CategoryTitleField = styled(Input)`
  margin-top: 30px;
`;
export const ModalBodyWrapper = styled.div`
  width: 100%;
  padding: 25px;
`;
export const ModalBodyHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #4f4f4f;
`;
export const ModalBodyText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 8px 0;
  color: #bdbdbd;
`;
export const ModalPhrases = styled.div`
max-height: 250px;
overflow-y: scroll;
`;
export const StylePhraseInputWrapper = styled.div`
  width: calc(100% - 42px);
  position: relative;
`;
export const StyledPhraseInput = styled(Input)`
  width: 100%;
  padding: 4px 10px;
  border: 1px solid;
  border-color: #bdbdbd;
  position: relative;
  &.ant-input[disabled] {
    color: #4f4f4f;
  }
`;
export const ModalFooter = styled.div`
  position: absolute;
  bottom: 20px;
  right: 25px;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`;
export const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #0b4b93;
  box-sizing: border-box;
  border-radius: 4px;
  width: 106px;
  height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;
export const CreateButton = styled(Button)`
  width: 73px;
  height: 32px;
  background: #0b4b93;
  border: 1px solid #0b4b93;
  color: white;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 6px;
  font-size: 14px;
  padding: 8px 16px;
  .ant-spin {
    margin: 2px !important;
  }
`;

export const EditTitleWrapper=styled.div`
      display:flex;
      align-items:center;
      flex-wrap: wrap;
      .title{
        margin-right:10px;
      }
`
export const LinkWrapper = styled.div `
    cursor: pointer;
`