import React from "react";
import { message, Spin } from "antd";
import useSWR, { useSWRConfig } from "swr";
import { StyledTable } from "../../../components/common/styled-components";
import { useColumns } from "./useColumns";
import { deleteWorkflow, enableWorkflow } from "./apis";
import produce from "immer";
const url = "/automation/list/";

const AutomationTable = () => {
  const { mutate } = useSWRConfig();
  const { data, error } = useSWR(url);

  const handleToggle = async (checked, record) => {
    const newState = produce(data, (draft) => {
      const index = draft.results.findIndex((item) => item.id === record.id);
      draft.results[index] = { ...draft.results[index], enabled: checked };
    });
    mutate(url, { ...newState }, false);
    await enableWorkflow(record, checked);
    mutate(url);
  };
  const handleDelete = async (id) => {
    const newState = produce(data, (draft) => {
      draft.results = draft.results.filter((item) => item.id !== id);
      draft.count = draft.count - 1;
    });
    mutate(url, { ...newState }, false);
    await deleteWorkflow(id);
    mutate(url);
  };
  const { columns } = useColumns(handleToggle, handleDelete);

  if (!data || !data.results) return <Spin />;
  if (error) return message.error("Error while fetching data try again!");

  return (
    <div>
      <StyledTable
        style={{ background: "white" }}
        dataSource={data.results}
        columns={columns}
        loading={!data}
        rowKey={(obj) => obj.id}
        pagination={false}
      />
    </div>
  );
};

export default AutomationTable;
