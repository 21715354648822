import React, { useEffect, useState } from "react";
import BlockComponent from "../../BlockComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  PrimaryButton,
} from "../../../../../../components/common/styled-components";
import { defaultFilterOptions, operatorOptions, ruleModes } from "../../../config";
import { ReduxActionTypes } from "../../../../../../constants/ReduxActionConstants";
import {
  InteractionsSampleBlockWrapper,
  InteractionsBlockFooter,
} from "../../../wrappers";
import { useReq } from "../../../../../../custom-hooks/useDispatchReq";
import { getCustomFilters } from "../../../../../../conversation-list/api";
import { DASHBOARD_OPTIONS } from "../../../../../../user-dashboard/utils";
import { REQUEST_TYPE } from "../../../../../../custom-hooks/config";
import CustomFilters from "../../../../../../components/common/CustomFilters";
import produce from "immer";
import ConditionGroupParent from "./CondtionGroupParent";
import { getRuleBlockState } from "../../../../../../reducers/setup/qaCaseAssignmentReducer";

const InteractionsSampleBlock = () => {
  const dispatch = useDispatch();
  const filtersMeta = useSelector((state) => state.qaCaseAssignmentData.ruleBlocksData.filters_meta);
  const showErrorState = useSelector((state) => state.qaCaseAssignmentData.showErrorState);
  const mode = useSelector((state) => state.qaCaseAssignmentData.mode);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);

  const [filterOptions, setFilterOptions] = useState([]);
  const [callApi, cancelRequests] = useReq();

  const addInteractions = () => {
    let _filtersMeta = produce(filtersMeta, interactions => {
      const lastID = interactions?.length ? interactions[interactions.length - 1].id + 1 : 1;
      const data = {
        ...getRuleBlockState().ruleBlocksData.filters_meta[0],
        id: lastID,
      };
      interactions.push(data);
    });

    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };

  const addConditionGroup = (data) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [...data],
    });
  };

  const addFilter = (conditionGroupID, interactionID) => {
    let _filtersMeta = produce(filtersMeta, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          interaction.filter_groups = interaction.filter_groups.map(
            (conditionGroup) => {
              if (conditionGroup.id === conditionGroupID) {
                let lastFilterID = conditionGroup.filters?.length
                  ? conditionGroup.filters[conditionGroup.filters.length - 1].id +
                    1
                  : 1;
                conditionGroup.filters = [
                  ...conditionGroup.filters,
                  {
                    id: lastFilterID,
                    val: null,
                    filterKey: "",
                    operator: "",
                  },
                ];
                return conditionGroup;
              } else {
                return conditionGroup;
              }
            }
          );
          return interaction;
        } else {
          return interaction;
        }
      });
    })
    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };

  const onDeleteInteraction = (interactionID) => {
    let { _filtersMeta } = produce({_filtersMeta : filtersMeta}, interactions => {
      interactions._filtersMeta = interactions._filtersMeta.filter(
        (interaction) => interaction.id !== interactionID
      );
    });
    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };

  const onDeleteFilter = (conditionGroupID, filterID, interactionID) => {
    let _filtersMeta = produce(filtersMeta, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          interaction.filter_groups = interaction.filter_groups?.map(
            (conditionGroup) => {
              if (conditionGroup.id === conditionGroupID) {
                conditionGroup.filters = conditionGroup?.filters?.filter(
                  (item) => item.id !== filterID
                );
                return conditionGroup;
              }
  
              return conditionGroup;
            }
          );
          interaction.filter_groups = interaction.filter_groups?.filter(
            (conditionGroup) => conditionGroup.filters?.length > 0
          );
          return interaction;
        } else {
          return interaction;
        }
      });
    })

    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta?.filter(interaction => interaction.filter_groups?.length > 0)],
    });
  };

  const onInteractionLimitChange = (e, interactionID) => {
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === "") {
      let _filtersMeta = produce(filtersMeta, interactions => {
        interactions.map((interaction) => {
          if (interaction.id === interactionID) {
            interaction.limit = e.target.value !== "" ? parseInt(e.target.value) : "";
            return interaction;
          } else {
            return interaction;
          }
        });
      });
      dispatch({
        type: ReduxActionTypes.SET_QA_FILTERS_META,
        payload: [..._filtersMeta],
      });
    } else {
      return;
    }
  };
  const onChangeInteractionLogicalOperator = (e, interactionID) => {
    let _filtersMeta = produce(filtersMeta, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          interaction.logical_op = e.target.value;
          return interaction;
        } else {
          return interaction;
        }
      });
    });

    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };
  const onChangeConditionGroupLogicalOperator = (
    e,
    conditionGroupID,
    interactionID
  ) => {
    let _filtersMeta = produce(filtersMeta, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          interaction.filter_groups = interaction.filter_groups.map(
            (conditionGroup) => {
              if (conditionGroupID === conditionGroup.id) {
                conditionGroup.logical_op = e.target.value;
                return conditionGroup;
              }
              return conditionGroup;
            }
          );
          return interaction;
        } else {
          return interaction;
        }
      });
    })
    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };

  const onChangeFilter = (
    e,
    key,
    conditionGroupID,
    filterID,
    interactionID,
    rest
  ) => {
    const _rest = rest ? { ...rest } : {};
    let _filtersMeta = produce(filtersMeta, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          interaction.filter_groups = interaction.filter_groups?.map((conditionGroup) => {
              if (conditionGroup.id === conditionGroupID) {
                conditionGroup.filters = conditionGroup?.filters?.map((item) => {
                  if (item.id === filterID) {
                    item[key] = e === undefined || e === null ? null : e;
  
                    if (key === "filterKey") {
                      item["val"] = null;
                      item["operator"] = filterOptions?.find((option) => option.key === e)?.operatorOptions[0];
                    }
  
                    if (key === "operator") {
                      item["val"] = null;
                    }
  
                    if (key === "filterKey" && e === "convo_tag_sampling") {
                      item["followed"] = "Followed";
                      item["expectationtype"] = "";
                      item["convo_tag_id"] = null
                    } 
                    else if (key === "filterKey" && e !== "convo_tag_sampling") {
                      delete item["followed"];
                      delete item["expectationtype"];
                      delete item["convo_tag_id"];
                    }

                    if(key === "val" && item.filterKey === "convo_tag_sampling") {
                      item["expectationtype"] = _rest?.expectationtype || null;
                      item["convo_tag_id"] = _rest?.convo_tag_id || null;
                    }
  
                    if ((key === "filterKey" && (e === "call_duration" || e === "negative_sentiment") && item.operator === "is more than") || (key === "operator" && (item.filterKey === "call_duration" || item.filterKey === "negative_sentiment") && e === "is more than")) {
                      item["val"] = "0,MAX";
                    } 
                    else if ((key === "filterKey" && (e === "call_duration" || e === "negative_sentiment") && item.operator !== "is more than") || (key === "operator" && (item.filterKey === "call_duration" || item.filterKey === "negative_sentiment") && e !== "is more than")) {
                      item["val"] = "0,0";
                    }
                  }
                  return item;
                });
                return conditionGroup;
              }
  
              return conditionGroup;
            }
          );
          interaction.filter_groups = interaction.filter_groups?.filter(
            (conditionGroup) => conditionGroup.filters?.length > 0
          );
          return interaction;
        } else {
          return interaction;
        }
      });
    });
  
    dispatch({
      type: ReduxActionTypes.SET_QA_FILTERS_META,
      payload: [..._filtersMeta],
    });
  };

  useEffect(() => {
    callApi(getCustomFilters,
      (res) => {
        setFilterOptions([
          ...defaultFilterOptions,
          ...res?.map((filter) => {
            return {
              key: filter.field_name,
              label: filter.pretty_name,
              operatorOptions: [operatorOptions.EQUALS],
              renderComponent: (value, onChange, { ...rest }) => {
                const props = {
                  value,
                  handleChange: onChange,
                  style: { width: "100%" },
                  customFilterMultiSelect: true,
                  width: 5,
                  maxTagCount : 1,
                  type: "local",
                  showFooter: false,
                  ...rest
                };
                return (
                  <>
                    <CustomFilters
                      key={filter.field_name}
                      options={filter.options}
                      placeholder={filter.pretty_name}
                      {...props}
                    />
                  </>
                );
              },
            };
          }),
        ]);
      },
      (err) => {
        if (err && err.message === DASHBOARD_OPTIONS.CANCEL_REQUEST) {
          return;
        }
        setFilterOptions(defaultFilterOptions);
      },
      {},
      () => {},
      REQUEST_TYPE.DISPATCH_REQUEST
    );

    return () => {
      cancelRequests();
    };
  }, []);

  return (
    <InteractionsSampleBlockWrapper>
      <BlockComponent
        _key = "qa-interactions-sample-block"
        id="qa-interactions-sample-block"
        heading="Interactions Sample"
        subHeading="No. of evaluations selected in sample will be evaluated for each agent in the agents section"
        renderBody={() => (
            <ConditionGroupParent fn = {
              {
                addConditionGroup: addConditionGroup,
                onInteractionLimitChange: onInteractionLimitChange,
                onDeleteInteraction: onDeleteInteraction,
                onChangeFilter: onChangeFilter,
                onChangeConditionGroupLogicalOperator: onChangeConditionGroupLogicalOperator,
                onChangeInteractionLogicalOperator: onChangeInteractionLogicalOperator,
                onDeleteFilter: onDeleteFilter,
                addFilter: addFilter
              }
            } 
            showSampleCount = {true}
            filterOptions = {filterOptions || []}
            showErrorState = {showErrorState}
            textOnly = {mode === ruleModes.CREATE || isEditEnabled ? false : true}
            data = {filtersMeta}
            />
        )}
      />
      {mode === ruleModes.CREATE || isEditEnabled ? <InteractionsBlockFooter>
        <PrimaryButton
          onClick={addInteractions}
          style={{ background: "#F2F2F2", border: "none" }}
        >
          Add interactions to sample
        </PrimaryButton>
      </InteractionsBlockFooter> : null}
    </InteractionsSampleBlockWrapper>
  );
};

export default InteractionsSampleBlock;
