import React, { useEffect, useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import BlockComponent from "../BlockComponent";
import { StyledInput } from "../../../../../components/common/styled-components";
import { Select, TimePicker } from "antd";
import { frequencyOptions, dayOptions, pullDataOptions, ruleModes, MAX_DAYS } from "../../config";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ReduxActionTypes } from "../../../../../constants/ReduxActionConstants";
import {
  ErrorState,
  NameScheduleWrapper,
  RuleNameWrapper,
  LabelWrapper,
  FieldWrapper,
  FrequencyWrapper,
  PullDataWrapper,
  AddtionalInfoWrapper,
  AddtionalInfoText,
  NameAndScheduleReportView,
  CustomSelect,
} from "../../wrappers";
import { spliceText } from "../../../../../components/common/CustomFilterComponent/utils";

const { Option } = Select;

const NameAndScheduleBlock = () => {
  const dispatch = useDispatch();
  const ruleBlockData = useSelector((state) => state.qaCaseAssignmentData.ruleBlocksData);
  const showErrorState = useSelector((state) => state.qaCaseAssignmentData.showErrorState);
  const mode = useSelector((state) => state.qaCaseAssignmentData.mode);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);

  const onRuleNameChange = (e) => {
    const value = e.target.value;
    dispatch({
      type: ReduxActionTypes.SET_QA_RULE_NAME,
      payload: value,
    });
  };

  const onFrequencyChange = (value) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_FREQUENCY_OBJECT,
      payload: {
        frequency: value,
        pullDataFieldValue: pullDataOptions[value][0],
        pullDataOptions: pullDataOptions[value],
        ...(value === frequencyOptions.DAILY.label
          ? {
              day_of_week: undefined,
              day_of_month: undefined,
              time: "7:00",
            }
          : value === frequencyOptions.WEEKLY.label
          ? {
              day_of_week: "1",
              day_of_month: undefined,
              time: "7:00",
            }
          : value === frequencyOptions.MONTHLY.label
          ? {
              day_of_week: undefined,
              day_of_month: "1",
              time: "7:00",
            }
          : {}),
      },
    });
  };

  const onDayChange = (value) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_FREQUENCY_OBJECT,
      payload: {
        day_of_week: value,
      },
    });
  };

  const onTimeChange = (time, timeString) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_FREQUENCY_OBJECT,
      payload: {
        time: timeString,
      },
    });
  };

  const onDateChange = (value) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_FREQUENCY_OBJECT,
      payload: {
        day_of_month: value,
      },
    });
  };
  return (
    <>
      <BlockComponent
        _key="qa-name-schedule-block"
        id="qa-name-schedule-block"
        heading="Name and Schedule"
        renderBody={() => (
          <NameScheduleWrapper className="d-flex flex-column">
            <RuleNameWrapper>
              <LabelWrapper>Rule name</LabelWrapper>
              <FieldWrapper className="flex-column">
                {mode === ruleModes.CREATE || isEditEnabled ? (
                  <StyledInput
                    iserror={
                      showErrorState &&
                      !ruleBlockData.nameAndScheduleObject.ruleName
                        ? true
                        : false
                    }
                    maxLength={150}
                    style={{ marginBottom: 0, height: "32px" }}
                    placeholder="Enter rule name"
                    onChange={onRuleNameChange}
                    value={ruleBlockData.nameAndScheduleObject.ruleName}
                  />
                ) : (
                  <NameAndScheduleReportView>{spliceText(ruleBlockData.nameAndScheduleObject.ruleName, 40, true)}</NameAndScheduleReportView>
                )}
                {showErrorState &&
                !ruleBlockData.nameAndScheduleObject.ruleName ? (
                  <ErrorState>
                    <ErrorRoundedIcon className="css-qa-error-state-icon" />{" "}
                    <span>Rule name is required</span>
                  </ErrorState>
                ) : null}
              </FieldWrapper>
            </RuleNameWrapper>
              <FrequencyWrapper>
                <LabelWrapper>Frequency</LabelWrapper>
                {mode === ruleModes.CREATE || isEditEnabled ? <FieldWrapper>
                      <Select
                        placeholder={"Select Frequency"}
                        value={ruleBlockData.nameAndScheduleObject.frequency}
                        style={{ width: 150 }}
                        onChange={onFrequencyChange}
                      >
                        {Object.entries(frequencyOptions)?.map(([optionKey, value]) => (
                          <Option key={value.label}>
                            {value.label}
                          </Option>
                        ))}
                      </Select>
                  {ruleBlockData?.nameAndScheduleObject?.frequency === frequencyOptions.WEEKLY.label ? (
                      <Select
                        placeholder={"Select Day"}
                        value={ruleBlockData.nameAndScheduleObject.day_of_week}
                        style={{ width: 140 }}
                        onChange={onDayChange}
                      >
                        {Object.entries(dayOptions)?.map(([optionKey, value]) => (
                          <Option key={optionKey}>{value}</Option>
                        ))}
                      </Select>
                  ) : ruleBlockData?.nameAndScheduleObject?.frequency === frequencyOptions.MONTHLY.label ? (
                      <Select
                        placeholder={"Select Date"}
                        value={ruleBlockData.nameAndScheduleObject.day_of_month}
                        style={{ width: 140 }}
                        onChange={onDateChange}
                      >
                        {Array.from({ length: MAX_DAYS }, (_, i) => i + 1)
                          ?.map(String)
                          ?.map((option) => (
                            <Option key={option}>{option}</Option>
                          ))}
                      </Select>
                  ) : null}
                    <TimePicker
                      allowClear={false}
                      value={moment(
                        ruleBlockData.nameAndScheduleObject.time,
                        "HH:mm"
                      )}
                      format="HH:mm"
                      focusOnOpen = {true}
                      onChange={onTimeChange}
                    />
                </FieldWrapper> : <NameAndScheduleReportView>{ruleBlockData.nameAndScheduleObject.frequency + ", "}{ruleBlockData.nameAndScheduleObject.frequency === "Weekly" ? dayOptions[ruleBlockData.nameAndScheduleObject.day_of_week] + ", " : ""}{ruleBlockData.nameAndScheduleObject.frequency === "Monthly"? ruleBlockData.nameAndScheduleObject.day_of_month + ", " : ""}{ruleBlockData.nameAndScheduleObject.time}</NameAndScheduleReportView>}
              </FrequencyWrapper>
            <PullDataWrapper>
              <LabelWrapper>Pull data from</LabelWrapper>
              <FieldWrapper>
                {mode === ruleModes.CREATE || isEditEnabled ? (
                  <Select
                    disabled={true}
                    placeholder={"Pull data from"}
                    value={
                      ruleBlockData.nameAndScheduleObject.pullDataFieldValue
                    }
                    style={{ width: 140 }}
                  >
                    {ruleBlockData.nameAndScheduleObject?._pullDataOptions?.map(
                      (option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      )
                    )}
                  </Select>
                ) : (
                  <NameAndScheduleReportView>{ruleBlockData.nameAndScheduleObject.pullDataFieldValue}</NameAndScheduleReportView>
                )}
              </FieldWrapper>
              {mode === ruleModes.CREATE || isEditEnabled ? (
                <AddtionalInfoWrapper>
                  <LockIcon className="lock-icon-qa-assignment" />{" "}
                  <AddtionalInfoText>
                    This is dependent on the frequency
                  </AddtionalInfoText>
                </AddtionalInfoWrapper>
              ) : null}
            </PullDataWrapper>
          </NameScheduleWrapper>
        )}
      />
    </>
  );
};

export default NameAndScheduleBlock;
