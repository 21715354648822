import React, { useState } from "react";
import { Spin, Select, message } from "antd";
import { connect } from "react-redux";
import FilterComponent from "./FilterComponent";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

const CustomFilter = ({
  value,
  handleChange,
  options,
  placeholder,
  customFilterMultiSelect,
  width,
  style = {},
  ...rest
}) => {
  const [customFilterValue, updatecustomFilterValue] = useState(value);
  const [optionsData] = useState(options);
  return !customFilterMultiSelect ? (
    <FilterComponent
      width={width || 180}
      options={optionsData}
      placeholder={placeholder}
      value={customFilterValue}
      multiSelect={customFilterMultiSelect}
      showSearchIcon={true}
      style={style}
      showSearch
      capitalize={true}
      handleChange={(value) => {
        updatecustomFilterValue(value);
        handleChange(value);
      }}
      {...rest}
    />
  ) : (
    <CustomFilterComponent
      width={width || 180}
      options={optionsData}
      placeholder={placeholder}
      value={customFilterValue}
      multiSelect={customFilterMultiSelect}
      style={style}
      handleChange={(value) => {
        updatecustomFilterValue(value);
        handleChange(value);
      }}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.app_user.selectedWorkspace,
});
export default connect(mapStateToProps)(CustomFilter);
