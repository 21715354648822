import { MENU_ITEMS } from "../components/NavigationBar";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import { getItemFromStore, removeItemFromStore, setItemInStore } from "../utils";

export const isAuthorized = (user) => !!user.token;

export const isDashboardUser = (user) => user?.is_agent || user?.is_manager || user?.is_superuser || user?.is_qa_auditor; 

export const isRouteAccessible = (user, path) => {
  const menuItem = MENU_ITEMS.find(i => path.includes(i.path));
  return !menuItem?.access || menuItem.access(user);
}

export const mapStateToProps = state => ({ app_user: state.app_user, redirectUrl: state.app_user.redirectUrl });

export const mapDispatchToProps = (dispatch) => ({
  setRedirectUrl: (redirectTo) => {
    dispatch({ 
      type: ReduxActionTypes.SET_REDIRECT,
      payload: redirectTo
    });
  },
})

export const checkReload = () => {
  let currentPath = window.location.href;
  try {
    if (currentPath === getItemFromStore('currentPath')) {
      setItemInStore('isReloaded', true);
    } else {
      setItemInStore('isReloaded', false);
    }
      setItemInStore('currentPath', currentPath);
  } catch(e) {}
};

/*so the chunks in code splitting sometimes break because lazy loading works on dynamic imports, and is not efficient in handling errors
hence adding this extra layer over lazy loading which will help to handle errors*/
export const componentLoader = (lazyComponent, retriesLeft=4, interval=1000) => {
  return new Promise((res, rej) => {
    lazyComponent().then(res)
    .catch(error => {
      setTimeout(()=> {
        if(retriesLeft === 1) {
          rej(error);
          return;
        }
        componentLoader(lazyComponent, retriesLeft - 1).then(res, rej);
      }, interval)
    })
  })
}

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  setItemInStore(key, item);
}

export function getWithExpiry(key) {
  const item = getItemFromStore(key);
  if (!item) return null;

  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    removeItemFromStore(key);
    return null;
  }

  return item.value;
}
