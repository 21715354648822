import React, { useState } from "react";
import { Select, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MultipleEmailInput from "../MultipleEmailInput";
import { validateEmail } from "../../../utils";
import {
  CancelButton,
  CreateButton,
  ModalFooter,
  ModalHeaderWrapper,
  ModalWrapper,
  FrequencyField,
  SharingModalFieldContainer,
  SharingModalHeading,
  TitleField,
  StyledModal,
} from "./styles";

const ShareReportModal = ({
  onCreate,
  closeModal,
  visible,
  includeFrequency = true,
  chartTitle,
  heading = "Share Report",
}) => {
  const [title, setTitle] = useState(chartTitle);
  const [frequency, setFrequency] = useState("Report Frequency");
  const [emails, setEmails] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const { Option } = Select;

  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

  const handleChangeSelect = (value) => {
    setFrequency(value);
  };

  const handleCreateClick = () => {
    setDisableButton(true);
    if (
      title === "" ||
      (includeFrequency && frequency === "Report Frequency")
    ) {
      setDisableButton(false);
      return message.error("Required Fields are missing");
    }
    const pickRecipientEmails = [];
    emails.map((item) =>
      item.text !== "" ? pickRecipientEmails.push(item.text.toLowerCase()) : ""
    );
    //need to check length of emails array and pickEmails
    if (pickRecipientEmails.length === 0) {
      setDisableButton(false);
      return message.error("No Recipient Added");
    } else {
      const validatingEmail = validateEmail(pickRecipientEmails);
      if (validatingEmail === false) {
        setDisableButton(false);
        return message.error("Email ID is invalid");
      }
      let data = {
        title: title,
        recipients: pickRecipientEmails,
      };
      if (includeFrequency) {
        data = {
          ...data,
          frequency: frequency,
        };
      }
      onCreate(data);
    }
  };

  return (
    <StyledModal visible={visible} width={680} closable={false} footer={null}>
      <ModalWrapper>
        <ModalHeaderWrapper>
          <SharingModalHeading>{heading}</SharingModalHeading>
          <SharingModalFieldContainer>
            <TitleField
              placeholder="Report Title"
              style={{ padding: "10px 12px", borderRadius: "0px" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {includeFrequency && (
              <FrequencyField value={frequency} onChange={handleChangeSelect}>
                <Option value="0">Just Once</Option>
                <Option value="1">Daily</Option>
                <Option value="2">Weekly</Option>
                <Option value="3">Monthly</Option>
              </FrequencyField>
            )}
          </SharingModalFieldContainer>
        </ModalHeaderWrapper>

        <MultipleEmailInput
          emails={emails}
          setEmails={setEmails}
          heading="Chart Audience"
          subheading="Enter the email addresses of the people who should receive this chart"
        />
        <ModalFooter>
          <CancelButton onClick={closeModal} disabled={disableButton}>
            Cancel
          </CancelButton>

          <CreateButton onClick={handleCreateClick} disabled={disableButton}>
            {disableButton && <Spin indicator={antIcon} size={"small"} />}
            Create
          </CreateButton>
        </ModalFooter>
      </ModalWrapper>
    </StyledModal>
  );
};

export default ShareReportModal;
