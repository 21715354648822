import React, { useEffect } from "react";
import { ModalBodyHeading, ModalBodyText, ModalBodyWrapper } from "./styles";
import CollapsiblePanel from "./CollapsiblePanel";
import EmailFields from "./EmailFields";

const MultipleEmailInput = ({ emails, setEmails, heading, subheading }) => {
  const mapDetailedPhrases = (email, index) => ({
    id: `${index}`,
    text: email,
  });
  const getInitialEmails = () => new Array(3).fill("").map(mapDetailedPhrases);

  useEffect(() => {
    setEmails(getInitialEmails());
  }, []);

  return (
    <ModalBodyWrapper>
      <ModalBodyHeading>{heading}</ModalBodyHeading>
      <ModalBodyText>{subheading}</ModalBodyText>
      <CollapsiblePanel emails={emails} setEmails={setEmails} />
      <EmailFields emails={emails} setEmails={setEmails} />
    </ModalBodyWrapper>
  );
};

export default MultipleEmailInput;
