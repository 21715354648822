import React, { useState, useEffect, useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Modal,
  Button,
  Input,
  Select,
  Collapse,
  Spin,
  message,
  Switch,
} from "antd";
import styled from "@emotion/styled/macro";
import RemovableEntry from "../RemovableEntry";
import AddButton from "../../../components/common/AddButton";
import { ReactComponent as RedirectIcon } from "../../assets/redirectIcon.svg";
import { debounce } from "lodash";
//import { updateReportApi } from "../../api/api";
import * as QueryString from "query-string";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-height: 780px;
    position: relative;
  }
`;
const ModalWrapper = styled.div`
  width: 100%;
  min-height: 640px;
  max-height: 780px;
`;
const ModalHeaderWrapper = styled.div`
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #bdbdbd;
`;

const ReportSharingModalHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6099d9;
  }
`;
const ReportSharingModalFieldContainer = styled.div``;

const ReportTitleField = styled(Input)`
  margin-top: 30px;
`;

const ReportFrequencyField = styled(Select)`
  width: 277px;
  margin: 20px 191px 0 0;
`;

const ReportActiveSwitch = styled(Switch)``;
const ModalBodyWrapper = styled.div`
  width: 100%;
  padding: 25px;
`;

const ModalBodyHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #4f4f4f;
`;

const ModalBodyText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 8px 0;
  color: #bdbdbd;
`;

const ModalEmailFields = styled.div`
  max-height: 230px;
  overflow-y: scroll;
  }
`;

const StylePhraseInputWrapper = styled.div`
  width: calc(100% - 42px);
  position: relative;
`;

const StyledPhraseInput = styled(Input)`
  width: 100%;
  padding: 4px 10px;
  border: 1px solid;
  border-color: #bdbdbd;
  position: relative;
  &.ant-input[disabled] {
    color: #4f4f4f;
  }
`;
const ModalFooter = styled.div`
  position: absolute;
  bottom: 10px;
  right: 25px;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #0b4b93;
  box-sizing: border-box;
  border-radius: 4px;
  width: 106px;
  height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const CreateButton = styled(Button)`
  width: 73px;
  height: 32px;
  background: #0b4b93;
  border: 1px solid #0b4b93;
  color: white;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 6px;
  font-size: 14px;
  padding: 8px 16px;
  .ant-spin {
    margin: 2px !important;
  }
`;

const ModalCollapsiblePanel = styled.div`
  .ant-collapse {
    background-color: white;
    border: none;
    border-bottom: 0;
    border-radius: 0;
    margin-left: -14px;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #4f4f4f;
    max-width: 200px;
  }
  .ant-collapse-content {
    border-top: none;
    margin: -10px 0px 0px 18px;
  }
`;

const ModalBulkImportContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 579px;

  .modal-bulk-import-text-area {
    width: 100%;
    padding-left: 10px;
    margin-top: 2px;
    margin-left: -5px;
  }
`;
const ModalBulkImportText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
`;

const ModalBulkImportButton = styled(Button)`
  float: right;
  margin: 10px 25px 25px 0px;
`;

const ShareReport = (props) => {
  const freqData = {
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
  };
  const [reportTitle, setReportTitle] = useState(
    props.recordUpdate.report_name
  );
  const [reportID, setReportID] = useState(props.recordUpdate.id);
  const [senderID, setSenderID] = useState(props.recordUpdate.sender);
  const [isActive, setIsActive] = useState(props.recordUpdate.is_active);
  const [filters, setFilters] = useState(props.recordUpdate.filter_set);
  const [reportFrequency, setReportFrequency] = useState(
    props.recordUpdate.frequency
  );
  const [emails, setEmails] = useState([]);
  const [mulEmailsText, setMulEmailsText] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [created_from, setCreatedFrom] = useState(props.recordUpdate.url_prefix);
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

  const mapDetailedPhrases = (email, index) => ({
    id: `${index}`,
    text: email,
  });
  const getInitialEmails = () => new Array(3).fill("").map(mapDetailedPhrases);

  const handleChangeSelect = (value) => {
    setReportFrequency(value);
  };

  const handleRemoveEntry = (propsRemove) => {
    let updatedEmails = emails.filter(
      (item, index) => propsRemove.id !== item.id
    );
    setEmails(updatedEmails);
  };

  const handleAddEntry = () => {
    if (emails.length !== 0) {
      const id = parseInt(emails[emails.length - 1].id) + 1;
      setEmails([
        ...emails,
        {
          id: `${id}`,
          text: "",
        },
      ]);
    } else {
      setEmails([
        {
          id: `0`,
          text: "",
        },
      ]);
    }
  };
  // const onChange = ({ id, emails }, text) => {
  //   let updatedEmails = emails.map((item) => {
  //     if (item.id === id) {
  //       item.text = text;
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   setEmails(updatedEmails);
  // };
  //const debounceHandleChange = useCallback(debounce(onChange, 800), []);

  const handleEmailChange = (e) => {
    const text = e.target.value;
    const id = e.target.id;
    //debounceHandleChange({ id, emails }, text);
    let updatedEmails = emails.map((item) => {
      if (item.id === id) {
        item.text = text;
        return item;
      } else {
        return item;
      }
    });
    setEmails(updatedEmails);
  };

  const handleMultipleEmailsChange = (e) => {
    setMulEmailsText(e.target.value);
  };

  const validateEmail = (validateEmail) => {
    const checkValidationStatus = [];
    const EmailValidateRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    validateEmail.map((item) =>
      checkValidationStatus.push(EmailValidateRe.test(item))
    );
    if (checkValidationStatus.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  const handleParseMultipleEmails = () => {
    if (mulEmailsText === "") {
      return message.error("Email field is empty");
    }
    let mulEmailsArr = mulEmailsText.split(",");
    let oldEmailsArr = [];
    let oldEmailsObjArr = [];
    let flag = 0;
    const validatingEmail = validateEmail(mulEmailsArr);
    if (validatingEmail === false) {
      return message.error("Email ID is invalid");
    }
    //check if the existing email array is empty or not
    emails.map((item) => {
      if (item.text !== "") {
        flag = 1;
        oldEmailsArr.push(item.text);
        oldEmailsObjArr.push(item);
      }
    });
    if (flag === 0) {
      let tempArr = [];
      let tempMulArr = [];
      let initialID = 0;
      mulEmailsArr.map((item) => {
        if (!tempMulArr.includes(item)) {
          tempArr.push({
            id: `${initialID}`,
            text: item,
          });
          tempMulArr.push(item);
          initialID = initialID + 1;
        }
      });
      setEmails(tempArr);
      message.success("Email ID successfully parsed!");
    } else {
      let tempArr = [...oldEmailsObjArr];
      let existingLastID = parseInt(oldEmailsObjArr[oldEmailsObjArr.length - 1].id) + 1;
      mulEmailsArr.map((newEmail) => {
        if (!oldEmailsArr.includes(newEmail)) {
          tempArr.push({
            id: `${existingLastID}`,
            text: newEmail,
          });
          oldEmailsArr.push(newEmail);
          existingLastID = existingLastID + 1;
        }
      });
      setEmails(tempArr);
      message.success("Email ID successfully parsed!");
    }
    setMulEmailsText("");
  };

  const handleSaveClick = () => {
    setDisableButton(true);
    if (reportTitle === "" || reportFrequency === "Report Frequency") {
      setDisableButton(false);
      return message.error("Required Fields are missing");
    }
    const pickRecipientEmails = [];
    emails.map((item) =>
      item.text !== "" ? pickRecipientEmails.push(item.text) : ""
    );
    if (pickRecipientEmails.length === 0) {
      setDisableButton(false);
      return message.error("No Recipient Added");
    } else {
      const validatingEmail = validateEmail(pickRecipientEmails);
      if (validatingEmail === false) {
        setDisableButton(false);
        return message.error("Email ID is invalid");
      }
      let reportData = {
        id: reportID,
        report_name: reportTitle,
        frequency: reportFrequency,
        sender: senderID,
        is_active: isActive,
        recipients: pickRecipientEmails,
        filter_set: filters,
      };
      props.updateReport(reportData);
      props.handlehideUpdateReportModal();
      setDisableButton(false);
    }
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  const visitNewPage = () => {
    if (filters.date && Array.isArray(filters.date)) {
      filters.date = JSON.stringify(filters.date);
    }
    const filterString = QueryString.stringify(filters);
    const href = `${created_from.slice(-1)==="/" ? created_from.slice(-1) : created_from}${filterString ? "?" + filterString : ""}`;
    //const href=`http://localhost:3000/analytics?${filterString}`;
    window.open(href, "_blank");
  };

  useEffect(() => {
    if (props.recordUpdate.recipients.length === 0) {
      setEmails(getInitialEmails());
    } else {
      const recipientsEmailsList = [];
      props.recordUpdate.recipients.map((email, index) =>
        recipientsEmailsList.push({ id: `${index}`, text: email })
      );
      setEmails(recipientsEmailsList);
    }
  }, []);

  return (
    <>
      <StyledModal
        visible={props.visible}
        width={680}
        style={{ height: "800px" }}
        closable={false}
        footer={null}
      >
        <ModalWrapper>
          <ModalHeaderWrapper>
            <ReportSharingModalHeading>
              Share Report{" "}
              <a onClick={visitNewPage}>
                View Report <RedirectIcon />
              </a>
            </ReportSharingModalHeading>
            <ReportSharingModalFieldContainer>
              <ReportTitleField
                placeholder="Report Title"
                style={{ padding: "10px 12px", borderRadius: "0px" }}
                value={reportTitle}
                onChange={(e) => setReportTitle(e.target.value)}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReportFrequencyField
                  defaultValue={reportFrequency}
                  onChange={handleChangeSelect}
                >
                  <Option value={1}>Daily</Option>
                  <Option value={2}>Weekly</Option>
                  <Option value={3}>Monthly</Option>
                </ReportFrequencyField>
                <div
                  style={{
                    marginTop: "19px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  <span style={{ marginRight: "8px", color: "#828282" }}>
                    Active
                  </span>
                  <ReportActiveSwitch
                    size="small"
                    checked={isActive}
                    onChange={handleSwitchChange}
                  />
                </div>
              </div>
            </ReportSharingModalFieldContainer>
          </ModalHeaderWrapper>
          <ModalBodyWrapper>
            <ModalBodyHeading>Report Audience</ModalBodyHeading>

            <ModalBodyText>
              Enter the email addresses of the people who should recieve this
              report
            </ModalBodyText>
            <ModalCollapsiblePanel>
              <Collapse ghost={true}>
                <Panel header="Bulk Import (optional)" key="1">
                  <ModalBulkImportContent>
                    <ModalBulkImportText>
                      Copy and paste comma seperated email addresses here
                    </ModalBulkImportText>
                    <TextArea
                      placeholder="Email addresses"
                      className="modal-bulk-import-text-area"
                      allowClear
                      value={mulEmailsText}
                      onChange={handleMultipleEmailsChange}
                    />
                  </ModalBulkImportContent>
                  <ModalBulkImportButton onClick={handleParseMultipleEmails}>
                    Parse email addresses
                  </ModalBulkImportButton>
                </Panel>
              </Collapse>
            </ModalCollapsiblePanel>
            <ModalEmailFields>
              {emails.length > 0 &&
                emails.map((item, index) => (
                  <RemovableEntry
                    index={index}
                    id={item.id}
                    onRemove={handleRemoveEntry}
                    key={item.id}
                  >
                    <StylePhraseInputWrapper>
                      <StyledPhraseInput
                        name="email"
                        id={item.id}
                        defaultValue={item.text}
                        value={item.text}
                        placeholder={`Enter Email Address ${index + 1}`}
                        onChange={(e) => {
                          e.persist();
                          handleEmailChange(e);
                        }}
                      />
                    </StylePhraseInputWrapper>
                  </RemovableEntry>
                ))}
            </ModalEmailFields>
            <AddButton className="add-btn" onClick={handleAddEntry}>
              {"Add Email"}
            </AddButton>
            <ModalFooter>
              <CancelButton
                onClick={() => props.handlehideUpdateReportModal()}
                disabled={disableButton}
              >
                Cancel
              </CancelButton>

              <CreateButton onClick={handleSaveClick} disabled={disableButton}>
                {disableButton && <Spin indicator={antIcon} size={"small"} />}
                Save
              </CreateButton>
            </ModalFooter>
          </ModalBodyWrapper>
        </ModalWrapper>
      </StyledModal>
    </>
  );
};

export default ShareReport;
