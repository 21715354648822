import { Select } from "antd";
import React, { useEffect, useState } from "react";
import useStore from "../../store";
import { InputWrapper } from "./styles";

const ReduceVariable = ({
  selectedNode,
  multiselect,
  name,
  variable,
  objectKeys,
  variableKeys = [],
  handleChange,
}) => {
  const [displayName, setDisplayName] = useState();
  const extarctKeysOfVariableFromNode = useStore(
    (state) => state.extarctKeysOfVariableFromNode
  );
  const generateCustomPayload = useStore(
    (state) => state.generateCustomPayload
  );
  const { Option } = Select;

  const handleVariableChange = (val) => {
    if (val) {
      const [variableName, nodeId] = val.split("__&__");
      handleChange(
        extarctKeysOfVariableFromNode(nodeId, variableName),
        "variableKeys"
      );
      handleChange(val, "variable");
      handleChange(nodeId, "from");
      handleChange(null, multiselect ? "object_keys" : "object_key");
    }
  };
  const handleObjectKeysChange = (val) => {
    if (val) {
      handleChange(val, multiselect ? "object_keys" : "object_key");
    }
  };

  useEffect(() => {
    if (name && variable && objectKeys) {
      const [variableName, nodeId] = variable.split("__&__");
      generateCustomPayload(
        selectedNode.id,
        name,
        variableName,
        nodeId,
        objectKeys
      );
    }
  }, [name, variable, objectKeys]);

  const handleDisplayName = (name) => {
    const [variableName, nodeId] = variable.split("__&__");
    const parentVarName = selectedNode?.data?.globalVariables
      .find((item) => item.nodeId === nodeId)
      ?.variables?.find((item) => item.name === variableName)?.displayName;
    const childName = variableKeys.find(
      (vari) => vari.name === name
    )?.displayName;
    handleChange(parentVarName + " " + childName, "name");
  };
  return (
    <div>
      <InputWrapper>
        <label>From Variable</label>
        <Select
          onChange={(val) => handleVariableChange(val)}
          style={{ width: "250px" }}
          value={variable}
          placeholder="Choose variable"
        >
          {selectedNode?.data?.globalVariables.map((item) =>
            item["variables"].map(
              (variable) =>
                !variable.name.startsWith("__") && (
                  <Option
                    key={variable.name}
                    value={variable.name + "__&__" + item.nodeId}
                    onClick={() => handleChange(variable.displayName, "name")}
                  >
                    {" "}
                    {variable.displayName}{" "}
                  </Option>
                )
            )
          )}
        </Select>
      </InputWrapper>
      <InputWrapper>
        <label>{multiselect ? "Object Keys" : " Object Key"}</label>
        <Select
          mode={multiselect ? "multiple" : "default"}
          onChange={(val) => handleObjectKeysChange(val)}
          style={{ width: "250px" }}
          placeholder="Choose variable members"
          value={objectKeys}
        >
          {variableKeys?.map((variable) => (
            <Option
              key={variable.name}
              value={variable.name}
              onClick={() => handleDisplayName(variable.name)}
            >
              {variable.displayName}
            </Option>
          ))}
        </Select>
      </InputWrapper>
    </div>
  );
};

export default ReduceVariable;
