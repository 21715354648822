import { Radio, Select, TimePicker } from "antd";
import { TextField } from "@material-ui/core";
import { InputNumberWrapper } from "../../../components/wrappers";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import { StyledTable } from "../../../components/common/styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TableWrapper = styled(StyledTable)`
  & td {
    font-size: 12px;
    color: #4f4f4f;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }
`;

export const RuleViewWrapper = styled.div`
  width: 100%;
`;

export const RuleHeadingWrapper = styled.div`
  font-family: Roboto;
  padding: 10px 25px;
  border-bottom: 1px solid #e0e0e0;
`;
export const RuleSubHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`;
export const RuleHeading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.0015em;
  color: #828282;
`;
export const RuleButtonWrapper = styled.div``;

export const RuleBodyWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 25px;
  column-gap: 15px;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const RuleColumnOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-height: 100%;
  //overflow-y: auto;
  //overflow-x: hidden;
  font-family: Roboto;
  font-style: normal;
`;

export const RuleColumnTwoWrapper = styled.div`
  position: sticky;
  top: 0;
  height: max-content;
`;

export const SummaryWrapper = styled.div``;

export const RuleLabelWrapper = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
letter-spacing: 0.0025em;
color: #828282;
&:not(:last-of-type) {
  margin-bottom: 24px;
}
`;

export const RuleLabelHighlight = styled.span`
color: #6099D9;
font-family: Roboto;
font-style: normal;
font-weight: bold;
margin: 0 12px 0 12px;
`;


export const NameScheduleWrapper = styled.div`
  row-gap: 35px;
`;

export const AgentsWrapper = styled.div`
  width: 100%;
  row-gap: 25px;
`;

export const AgentsHeadWrapper = styled.div``;

export const AgentsBodyWrapper = styled.div`
  row-gap: 16px;
`;

export const AgentsUser = styled.div`
  line-height: 20px;
  color: #4f4f4f;
  letter-spacing: 0.0025em;
`;

export const RuleNameWrapper = styled.div``;
export const FrequencyWrapper = styled.div``;
export const PullDataWrapper = styled.div``;
export const LabelWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #828282;
  margin-bottom: 8px;
`;

export const FieldWrapper = styled.div`
  width: 80%;
  column-gap: 10px;
  display: flex;
`;

export const AddtionalInfoWrapper = styled.div`
  display: flex;
  align-items: initial;
  column-gap: 4px;
  margin: 6px 0px;
  & .lock-icon-qa-assignment {
    height: 20px;
    width: 12px;
    & path {
      fill: #828282;
    }
  }
`;

export const AddtionalInfoText = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.004em;
  color: #828282;
`;

export const StyledTimePicker = styled(TimePicker)`
 & .ant-time-picker-panel-input {
   pointer-events: none;
 }
`;

export const NameAndScheduleReportView = styled.span`
  letter-spacing: 0.0025em;
  color: #4F4F4F;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const DeleteAnimation = keyframes`
    0%   {transform: rotate(0deg)}
    25%  {transform: rotate(10deg)}
    50%  {transform: rotate(-10deg)}
    100% {transform: rotate(0deg)}
`;

export const InteractionsSampleBlockWrapper = styled.div`
  font-family: Roboto;
`;
export const InteractionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InteractionsSubWrapper = styled.div`
  padding: 16px;
  row-gap: 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const InteractionsBlockFooter = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: solid #f0f0f0;
  border-width: 0 1px 1px 1px;
  padding: 12px 10px;
`;

export const CustomSelect = styled(Select)`
& .ant-select-selection {
  border: ${props => props.showTextOnly ? "none" : props.iserror ? "1px solid #DB554D" : "1px solid #d9d9d9"};
  background: ${props => props.showTextOnly ? "inherit" : props.disabled ? "#f5f5f5" : "#ffffff"};
  color: ${props => props.showTextOnly ? "#4F4F4F" : props.disabled ? "rgba(0, 0, 0, 0.25)" : "#4F4F4F"};
  width: ${props => props.showTextOnly ? "max-content" : "100%"};
  cursor: ${props => props.showTextOnly ? "default" : props.disabled ? "not-allowed" : "pointer"};
  & .ant-select-arrow {
    display: ${props => props.showTextOnly ? "none" : "block"};
  }
  & .ant-select-selection--single.ant-select-selection__rendered {
    margin-right: ${props => props.showTextOnly ? "0px" : "24px" };
  }
  & .ant-select-selection__rendered {
    margin-right: ${props => props.showTextOnly ? "0px" : "11px" };
    margin-left: ${props => props.showTextOnly ? "4px" : "11px" };
  }
}
`;

export const SampleCountWrapper = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  //text-align: center;
  letter-spacing: 0.0025em;
  color: #828282;
  & .css-interaction-delete-icon {
    cursor: pointer;
    position: relative;
    top: -4px;
    visibility: hidden;
    &: hover {
      animation: ${DeleteAnimation} 1s infinite;
      path {
        fill: #4f4f4f !important;
      }
    }
  }

  &:hover .css-interaction-delete-icon {
    visibility: visible;
  }
`;

export const SampleCount = styled.div``;

export const ConditionGroupWrapper = styled.div`
  width: 100%;
  row-gap: 25px;
`;

export const ConditionGroupSubWrapper = styled.div`
  width: 100%;
`;
export const ConditionGroup = styled.div`
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  & .ant-radio {
    display: none;
  }

  & .ant-radio-wrapper {
    //border: 1px solid #E0E0E0;
    box-sizing: border-box;
    //border-radius: 4px;
    //background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    letter-spacing: 0.004em;
    font-weight: normal;
    font-size: 12px;
    color: #000000;
    line-height: 16px;
    width: ${(props) => props.wrapperWidth || "50px"};
    height: 24px;
    margin-right: 0;
    transition: 0.3s ease;
    & span {
      padding: 0;
    }
  }

  & .ant-radio-wrapper-checked {
    background: #6099d9;
    border: 1px solid #6099d9;
    color: #ffffff;
    border-radius: 4px;
  }
`;
export const ConditionGroupHead = styled.div`
  padding: 12px 10px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;
export const ConditionGroupBody = styled.div`
  padding: 18px 10px;
  width: 100%;
`;
export const FiltersInculcateWrapper = styled.div`
  width: 100%;
  row-gap: 26px;
`;

export const FiltersInculcateSubWrapper = styled.div`
  row-gap: 25px;
  position: relative;
  & .css-filter-delete-icon {
    position: absolute;
    cursor: pointer;
    right: -5px;
    top: 3px;
    visibility: hidden;
    &: hover {
      animation: ${DeleteAnimation} 1s infinite;
      path {
        fill: #4f4f4f !important;
      }
    }
  }
  &:hover .css-filter-delete-icon {
    visibility: visible;
  }
`;

export const Filters = styled.div`
  column-gap: 5px;
  width: 95%;
`;

export const SelectWrapper = styled.div`
  width: ${props => props.textOnly ? "fit-content" : "33%"};
  position: relative;
`;

export const FilterLogicalOperator = styled.div`
  width: 100%;
  position: relative;
`;

export const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e0e0e0;
  padding: 0;
  margin: 0;
`;

export const OperatorBlock = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 1;
  padding: 2px 4px;
  letter-spacing: 0.004em;
  color: #828282;
  font-size: 12px;
  left: 48%;
  top: -11px;
`;

export const FilterAddCondition = styled.div``;
export const ConditionGroupOperator = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0px 0px 0px;
`;
export const AddConditionGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
`;

export const ConversationTagSwitch = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 12px 0 0 0;
`;

export const ErrorState = styled.div`
  letter-spacing: 0.004em;
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: #db554d;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 6px 0 0 0;
  font-size: 12px;
  line-height: 16px;
  & .css-qa-error-state-icon {
    width: 12px; 
    height: 20px;
  }
`;

export const Block = styled.div``;
export const BlockWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border: ${props => props.isError ? "1px solid #DB554D" : "1px solid #f0f0f0"};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

export const BlockHeaderWrapper = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid rgb(224, 224, 224);
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
export const BlockHeading = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #4f4f4f;
`;
export const BlockSubHeading = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: #828282;
`;
export const BlockBodyWrapper = styled.div`
  padding: ${(props) => props.paddingStyle};
`;

export const QaAuditorWrapper = styled.div`
  font-family: Roboto;
  width: 100%;
  row-gap: 16px;
`;

export const QaAuditorUser = styled.div`
  line-height: 20px;
  color: #4F4F4F;
  letter-spacing: 0.0025em;
`;

export const InputWrapper = styled.div`
  align-items: center;
  column-gap: 5px;
  height: 100%;
`;

export const InputNumbersWrapper = styled(InputNumberWrapper)`
  width: 100%;
  & .MuiTextField-root {
    width: 100%;
    & .MuiInput-root {
      width: 100%;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
`;
export const Text = styled.div`
  width: max-content;
`;

export const ReportView = styled.span`
letter-spacing: 0.0025em;
color: #4F4F4F;
font-size: 14px;
line-height: 20px;
margin-left: 4px;
`;

export const CustomSwitchWrapper = styled.div`
display: flex;
align-items: center;
column-gap: 8px;
& .switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
}


& .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

& .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}



& input:checked + .slider {
  background-color: #27AE60;
}

// & .custom-switch-icon {
//   position: absolute;
//   top: 10px;
// }

& input:focus + .slider {
  box-shadow: none;
}

& input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
  display: flex;
  justify-content: center;
  align-items: center;
  content:url("data:image/svg+xml,%3Csvg className = 'custom-switch-icon' width='8' height='10' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 4.75781L7.16406 0.324219L7.75 0.910156L2.75 5.91016L0.425781 3.58594L0.992188 3L2.75 4.75781Z' fill='%2327AE60'/%3E%3C/svg%3E%0A");
}

/* Rounded sliders */
& .slider.round {
  border-radius: 17px;
}

& .slider.round:before {
  border-radius: 50%;
}
`;
export const RoundSlider = styled.span`
&:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
`;

export const TextWrapper = styled.div`
letter-spacing: 0.0025em;
color: #828282;
font-weight: normal;
font-size: 14px;
line-height: 20px;
`;
