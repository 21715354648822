import React, { useState } from "react";
import { useHistory } from "react-router";
import useSWR from "swr";
import { Modal, Input, Select, Button } from "antd";

import { useAutomationFetcher } from "../../automationFetcher";
import useStore from "../../store";

const CreateAutomationModal = ({ visible, onCancel }) => {
  const { Option } = Select;
  const setMeta = useStore((state) => state.setMeta);
  const history = useHistory();
  const { fetcher } = useAutomationFetcher();
  const { data, error } = useSWR("/gettriggers", fetcher);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    trigger_type: "",
  });

  const createWorkflow = () => {
    if (!formData.title || !formData.description || !formData.trigger_type) {
      return;
    }
    setMeta(formData);
    onCancel();
    history.push({
      pathname: "/setup/automation/create",
      state: data.find(
        (trigger) => trigger.trigger_type === formData.trigger_type
      ),
    });
  };
  const handleChange = (name, val) => {
    setFormData((p) => {
      return {
        ...p,
        [name]: val,
      };
    });
  };

  return (
    <Modal
      title="Create Workflow"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={createWorkflow}>
          Submit
        </Button>,
      ]}
    >
      <div className="p-3">
        <Input
          placeholder="Title"
          onChange={(e) => handleChange("title", e.target.value)}
          className="mb-2"
          required
        />
        <Input
          placeholder="Description"
          onChange={(e) => handleChange("description", e.target.value)}
          className="mb-2"
          required
        />
        <Select
          onChange={(val) => handleChange("trigger_type", val)}
          style={{ width: "100%" }}
          placeholder="Trigger"
        >
          {data?.length &&
            data.map((trigger) => (
              <Option key={trigger.trigger_type} value={trigger.trigger_type}>
                {" "}
                {trigger.display_name}{" "}
              </Option>
            ))}
        </Select>
      </div>
    </Modal>
  );
};

export default CreateAutomationModal;
