import React from "react";
import { useSelector } from "../../store";
import { AgentPopup } from "../components/agent-popup";

export const AgentAnalytics = (props) => {
  const globalFilters = useSelector((state) => state.dashboard?.globalFilters);

  if (!globalFilters.agents) {
    return (
      <>
        <AgentPopup />
      </>
    );
  }

  return (
    <>
      <props.TeamView
        timeSeries
        defaultChartType={"line"}
        noHeapEvent={props.noHeapEvent}
      />
    </>
  );
};
