export const userCalibrationHistory = [
  {
       title: "Home", 
       link: "/"
  },
  {
       title: "Calibration History",
       link: "/qa-calibrations"
  }
];

export const userReportViewHistory = [
  {
       title: "Home", 
       link: "/"
  },
  {
    title: "Calibration History",
    link: "/qa-calibrations"
  }
];

export const FILTER_NAMES = {
  DATE_TIME_FILTER: "dateTimeFilter",
  QA_AUDITOR_FILTER: "qaAuditorFilter",
  CONVERSATION_ID_FILTER: "conversationIdFilter"
};

export const BUTTON_NAMES = {
  SEND_INVITE_BUTTON: "sendInviteButton",
  CANCEL_BUTTON: "cancelButton"
};

export const VIEW_TYPE = {
  QA_MANAGER: "qa_manager", 
  QA_AUDITOR: "qa_auditor"
};

export const REDUCER_CONSTANTS = {
  SET_SESSION_DETAILS: 'SET_SESSION_DETAILS',
  UPDATE_MODERATOR_RESPONSE: 'UPDATE_MODERATOR_RESPONSE',
  CHECK_RESPONSES: 'CHECK_RESPONSES'
};

export const CONSTANTS = {
  SESSION_COMPLETED: "SESSION_COMPLETED",
  SESSION_DELETED: "SESSION_DELETED"
};

export const CALIBRATION_STATUS_TYPES = {
  PENDING: "Pending", 
  COMPLETED: "Completed"
};
