import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { getQuery } from "../api";
import { QueryChart } from "../components/query-chart";
import { QueryBuilderWrapper } from "../tabs/query-builder";
import { Redirect, useParams } from "react-router";
import { hasManagerRights } from "../config";
import { ListTitle } from "../../conversation-list/wrappers";

const View = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [query, setquery] = useState({ timeGranularity: "week" });
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      getQuery(params.id)
        .then((res) => {
          setTitle(res.title);
          setquery({
            measure: res.measure.replace("BankingBankingconversation", "Conversation"),
            groupBy: res.dimension,
            timeGranularity: res.time_granilarity,
            isTimeSeries: res.is_time_series,
            filter: res.filters,
          });
        })
        .catch((err) =>
          message.error(
            "Sorry, It seems that the query has been deleted from Saved Queries"
          )
        )
        .finally(() => setLoading(false));
    }
  }, []);

  if (hasManagerRights()) {
    return <Redirect to={`/analytics/query-builder/${params.id}`} />;
  }
  if (loading) {
    return (
      <QueryBuilderWrapper style={{ textAlign: "center", height: "100px" }}>
        <Spin />
      </QueryBuilderWrapper>
    );
  }
  return (
    <QueryBuilderWrapper>
      <ListTitle style={{marginLeft:'10px'}}>Shared Charts</ListTitle>
      
      {query.measure && <QueryChart title={title} query={query} />}
    </QueryBuilderWrapper>
  );
};

export default View;
