import React from "react";
import styled from "@emotion/styled";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { ErrorState, Block, BlockWrapper, BlockHeaderWrapper, BlockHeading, BlockSubHeading, BlockBodyWrapper } from "../wrappers";

const BlockComponent = ({
  heading,
  subHeading,
  renderBody,
  id,
  _key,
  errorObject = null,
}) => {
  return (
    <Block key = {_key}>
      <BlockWrapper isError={errorObject?.isError || false} id={id}>
        <BlockHeaderWrapper>
          <BlockHeading>{heading}</BlockHeading>
          {subHeading && <BlockSubHeading>{subHeading}</BlockSubHeading>}
        </BlockHeaderWrapper>
        <BlockBodyWrapper
          paddingStyle={
            id !== "qa-interactions-sample-block" ? "18px 10px" : "0px"
          }
        >
          {renderBody()}
        </BlockBodyWrapper>
      </BlockWrapper>
      {errorObject?.isError ? (
        <ErrorState>
          <ErrorRoundedIcon className="css-qa-error-state-icon" />
          <span>{errorObject.errorText}</span>
        </ErrorState>
      ) : null}
    </Block>
  );
};

export default BlockComponent;
