import React from "react";

export default function ThumbsDown({ fill, height = 20, width = 20 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill || "currentColor"} d="M14.25 9.5V0.5H17.25V9.5H14.25ZM11.25 0.5C11.6478 0.5 12.0294 0.658035 12.3107 0.93934C12.592 1.22064 12.75 1.60218 12.75 2V9.5C12.75 9.9125 12.585 10.2875 12.3075 10.5575L7.3725 15.5L6.5775 14.705C6.375 14.5025 6.2475 14.225 6.2475 13.91L6.27 13.6775L6.9825 10.25H2.25C1.4175 10.25 0.75 9.575 0.75 8.75V7.25C0.75 7.055 0.7875 6.875 0.855 6.7025L3.12 1.415C3.345 0.875 3.8775 0.5 4.5 0.5H11.25ZM11.25 2H4.4775L2.25 7.25V8.75H8.835L7.9875 12.74L11.25 9.4775V2Z"/>
    </svg>

  );
}
