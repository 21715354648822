import React, { useState, useEffect, useReducer, memo, useCallback } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { DeleteFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import appConfigs from "../../../config";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { getCustomFilters } from "../../../conversation-list/api";
import { REQUEST_TYPE } from "../../../custom-hooks/config";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import FilterInput from './filter-input';
import { getSelectGroupByOptions } from "../../query-config";
import { getMeasureTextCustomColumns } from "../../utils";
import { GroupbyWrapper, DeleteGroupbyButton, IconButtonWrapper } from "./style";
import { useSelector } from 'react-redux';
import { Select } from "antd";
import { queryConfigs } from "../../query-config";

const Groupby = ({ value, onChange, onCheckGroupby, measure, isTimeSeries, isCompareDateRange }) => {
    const customFilters = useSelector(state => state.dashboard.customFilters);

    const [dimensions, updateDimensions] = useState([undefined]);
    const [disableAddGroupby, setDisableAddGroupby] = useState(false);

    // EFFECTS
    useEffect(() => {
        if(dimensions.length === 2) {
            setDisableAddGroupby(true);
        } else if((isTimeSeries && Array.isArray(measure) && measure.length > 1)) {
            setDisableAddGroupby(true);
        } else if(isCompareDateRange) {
            setDisableAddGroupby(true);
        } else {
            setDisableAddGroupby(false);
        }
    }, [dimensions, measure, isTimeSeries, isCompareDateRange])

    const getGroupbyOptions = useCallback(() => {
        let safeCustomGroupByOptions = [];

        customFilters.forEach((filterVal, index) => {
            let safeGroupByOption = queryConfigs?.CUSTOM_GROUPBY_OPTIONS?.filter(el => el.field_name === filterVal.field_name)[0];
            
            if(safeGroupByOption) {
                safeCustomGroupByOptions.push(safeGroupByOption);
            }
        });

        return (
            [ 
                getSelectGroupByOptions().map((option, index) => (
                    <Select.Option key={index} value={option.value}>{option.label}</Select.Option>
                ))
                ,
                ...safeCustomGroupByOptions.map((option, index) => (
                        <Select.Option key={index} value={option.value}>{option.label}</Select.Option>
                    )
                )
            ]
        )
    }, [customFilters])

    // METHODS
    const handleAddNewGroupby = () => {
        updateDimensions(state => [
            ...state,
            undefined
        ])
    };

    const handleRemoveGroupby = (index) => {
        let dimensionValues = [...dimensions];
        dimensionValues.splice(index, 1);

        updateDimensions(dimensionValues);
        onChange(dimensionValues);
    };

    const handleOnChnageGroupby = (value, index) => {
        let dimensionValues = [...dimensions];
        dimensionValues[index] = value;
        updateDimensions(dimensionValues);
        onChange(dimensionValues);
    }

    return (
        <GroupbyWrapper>
            {
                dimensions.map((dimension, index) => (
                    <div className="groupby-row-container">
                        <Select
                            className="select-groupby-input"
                            allowClear={dimensions.length < 2}
                            onChange={(value) => handleOnChnageGroupby(value, index)}
                            value={dimension}
                            placeholder={"Select Parameter"}
                            disabled={isTimeSeries && (Array.isArray(measure) && measure.length > 1)}
                        >
                            {
                                getGroupbyOptions()
                            }
                        </Select>

                        {
                            dimensions.length !== 1 &&
                                <IconButtonWrapper>
                                    <Link
                                        onClick={() => handleRemoveGroupby(index)}
                                    >
                                        <DeleteFilled />
                                    </Link>
                                </IconButtonWrapper>
                        }
                    </div>
                ))
            }
            
            <Tooltip title={isCompareDateRange ? "Multiple group by is not supported with date comparison" : undefined} placement="right">
                <Button className={disableAddGroupby ? null : "add-groupby-btn"} onClick={handleAddNewGroupby} disabled={disableAddGroupby}>
                    <PlusCircleOutlined />
                    Add another groupby
                </Button>
            </Tooltip>
        </GroupbyWrapper>
    );
};

export default memo(Groupby);
