import { css } from "@emotion/core";
import styled from "@emotion/styled/macro";
import { Button } from "antd";


export const StyledButton = styled(Button)`
  display: flex;
  align-items:center;
  ${({chartButton}) => chartButton && css`
    border: none;
    font-size: 14px;
    background: transparent;
  `}
`
