import { axiosInstance, getSchemaName, getToken } from "../api/api";

const fetcher = (url) => {
  return axiosInstance({
    method: "GET",
    url: `${url}`,
    
  })
    .then((res) => res.data.results)
    .catch((e) => {
      throw new Error(e);
    });
};

export default fetcher;
