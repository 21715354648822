import React from "react";
import { useSelector } from "react-redux";
import { dayOptions, daySuffix, frequencyOptions, generateDaySuffix } from "../../config";
import { SummaryWrapper, RuleLabelWrapper, RuleLabelHighlight } from '../../wrappers';
import BlockComponent from "../BlockComponent";

export const GenerateSummary = () => {
  const ruleBlockData = useSelector(
    (state) => state.qaCaseAssignmentData.ruleBlocksData
  );

  const generateAutomationText = () => {
    const nameAndScheduleObject = ruleBlockData.nameAndScheduleObject;
    return nameAndScheduleObject.frequency === frequencyOptions.DAILY.label
      ? `At ${nameAndScheduleObject.time} every day`
      : nameAndScheduleObject.frequency === frequencyOptions.WEEKLY.label
      ? `At ${nameAndScheduleObject.time} on every ${
          dayOptions[nameAndScheduleObject.day_of_week]
        }`
      : nameAndScheduleObject.frequency === frequencyOptions.MONTHLY.label
      ? `At ${nameAndScheduleObject.time} on ${
        nameAndScheduleObject.day_of_month + generateDaySuffix(nameAndScheduleObject.day_of_month)
        } day of every month`
      : "";
  };

  const sumInteractionsLimit = () => {
    const limitSum = ruleBlockData?.filters_meta?.reduce((acc, obj) => {
      if (obj?.limit !== "") {
        acc += obj.limit;
      }
      return acc;
    }, 0);

    return limitSum;
  };

  return (
    <BlockComponent
      heading="Summary"
      renderBody={() => (
        <SummaryWrapper>
          <RuleLabelWrapper>
            Run this automation
            <RuleLabelHighlight>{generateAutomationText()}</RuleLabelHighlight>
          </RuleLabelWrapper>
          <RuleLabelWrapper>
            Pull data from
            <RuleLabelHighlight>
              {ruleBlockData.nameAndScheduleObject.pullDataFieldValue}
            </RuleLabelHighlight>
          </RuleLabelWrapper>
          <RuleLabelWrapper>
            Of
            <RuleLabelHighlight>
              {ruleBlockData.agentsObject.agentsGroup?.length
                ? ruleBlockData.agentsObject.agentsGroup.join(", ")
                : ruleBlockData.agentsObject.agentsList?.length
                ? ruleBlockData.agentsObject.agentsList?.length
                : "--"}
            </RuleLabelHighlight>
            {ruleBlockData.agentsObject.agentsList?.length &&
            ruleBlockData.agentsObject.agentsList?.length === 1
              ? "agent"
              : "agents"}
          </RuleLabelWrapper>
          <RuleLabelWrapper>
            Select
            <RuleLabelHighlight>{sumInteractionsLimit()}</RuleLabelHighlight>for
            each agent
          </RuleLabelWrapper>
          <RuleLabelWrapper>
            Assign to
            <RuleLabelHighlight>
              {ruleBlockData.qaAuditorsList?.length || 0}
            </RuleLabelHighlight>
            QA auditors
          </RuleLabelWrapper>
        </SummaryWrapper>
      )}
    />
  );
};

export default GenerateSummary;
