import React, { Component } from "react";
import BreadCrumbComponent from "../../components/common/BreadCrumbComponent";
import { connect } from "react-redux";
import {
  DashboardWrapper,
  DashboardHeader,
  DashboardTitle,
  DashboardFiltersWrapper,
  DetailsWrapper,
  TabWrapper,
} from "./wrapper";
import Filters from "../../../src/conversation-list/filters";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import appConfigs from "../../../src/config";
import { NavigationTabs } from "./navigation";
import { getAnalyticsTab } from "../config";
import { AgentPopup } from "./agent-popup";
import styled from "@emotion/styled";
import ShareReport from "./report-share";
import store from "../../store";
import { hasManagerRights, hasQAAuditorRights } from '../config';
import { FILTER_CONFIGURATION, SCREEN_NAMES } from "../../Filters/configs";
import FiltersComponent from "../../Filters";
import { addAnalyticsEvent } from "../../utils";
import { HeapAnalyticsEvent } from "../../components/common/HeapAnalyticsEvent";

const DashboardFiltersWrappers = styled(DashboardFiltersWrapper)`
  justify-content: space-between;
`;

const initialState = {
  filters:{
    date: appConfigs.DEFAULT_DATE_FILTER,
    channel: appConfigs.DEFAULT_CHANNEL,
    timeGranularity: "day",
  },
  showComparisonView: false,
  selectedWorkspace: store.getState().app_user.selectedWorkspace,
};

export class dashboard extends Component {
  constructor(props) {
    super(props);
    //new filters has been added to support share report query params
    this.state = {
      ...initialState
    };
  }

  handleFiltersUpdate = (key, value) => {
    var updatedFilters = {
      ...this.state.filters,
      [key]: value,
    };
    
    if(( updatedFilters.conversation_tag && updatedFilters.conversation_tag.length <= 0 ) || ( !updatedFilters.conversation_tag )) {
      delete updatedFilters.followed;
    }

    this.setState({
      ...initialState,
      filters: updatedFilters,
    });
  };

  componentDidMount() {
    addAnalyticsEvent(HeapAnalyticsEvent.Detect_Analytics_Page_Visit);
  }
  
  onTabChange = (key) => {
    // Remove Agent Filter on change of tabs
    let filters = this.state.filters;

    if (key.includes("agent")) {
      if ([store.getState().dashboard.globalFilters.agents].flat().length > 1) {
        this.props.removeGlobalFilter("agents");
        this.props.removeGlobalFilter("user");
      }
      delete filters.user;
    }

    this.setState(
      {
        route: key,
        filters,
      },
      () => {
        this.props.history.push(`/analytics/${this.state.route}`);
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedWorkspace !== this.state.selectedWorkspace) {
      this.setState({
        selectedWorkspace: nextProps.selectedWorkspace,
      });
      if (nextProps.selectedWorkspace !== this.state.filters.workspace) {
        this.handleFiltersUpdate("workspace", nextProps.selectedWorkspace);
      }
    }
  }

  shouldShowComparison = () => {
    return !this.state.filters.user || this.state.filters.user.length === 0;
  };

  renderTabBar = () => {
    const route = this.props.match.params.type || getAnalyticsTab()[0].key;
    return <NavigationTabs route={route} onTabChange={this.onTabChange} />;
  };

  render() {
    const route = this.props.match.params.type || getAnalyticsTab()[0].key;
    const tabConfig = getAnalyticsTab().find((tab) => route === tab.key);
    if (tabConfig.rawTemplate) {
      return (
        <DashboardWrapper>
          {this.renderTabBar()}
          <TabWrapper>
            <DetailsWrapper>
              {tabConfig.renderComponent ? tabConfig.renderComponent() : null}
            </DetailsWrapper>
          </TabWrapper>
        </DashboardWrapper>
      );
    }

    return (
      <DashboardWrapper>
        {this.renderTabBar()}
        <TabWrapper>
          <DashboardHeader>
            <BreadCrumbComponent items={[]} />
            <DashboardTitle>{tabConfig.fullTitle}</DashboardTitle>
            <DashboardFiltersWrappers>
              {
              !appConfigs.ENABLE_FILTER_UI || !FILTER_CONFIGURATION[SCREEN_NAMES.USER_DASHBOARD].ENABLE_MORE_FILTERS ? <Filters
                updateFilters={this.handleFiltersUpdate}
                user={this.props.app_user}
                filters={this.state.filters}
                showTimeGranularity={tabConfig.showTimeGranularity}
                defaultTimeGranularity={"day"}
                agentMultiSelect={tabConfig.agentMultiSelect}
                showAgentFilter
                showChannelFilter
                showDateFilter
                showCategoryFilter
                showListFilters
                showMetricTagFilter
                showOrganizationFilter
                hideTaggedForRubric
                showCustomFilters={false}
              /> :
              <FiltersComponent
              configuration={FILTER_CONFIGURATION[tabConfig.screenName]}
              />
             }
              {(hasManagerRights() || hasQAAuditorRights()) ? (
                <ShareReport
                  user={this.props.app_user}
                  filters={this.state.filters}
                />
              ) : (
                ""
              )}
            </DashboardFiltersWrappers>
          </DashboardHeader>
          {this.state.showComparisonView ? (
            <AgentPopup
              isComparison
              onCancel={() => {
                this.setState({
                  showComparisonView: false,
                });
              }}
              onSelectMultiple={(agents) => {
                this.state.onCompare();
                this.handleFiltersUpdate("user", agents);
                this.setState({
                  showComparisonView: false,
                });
              }}
            />
          ) : null}

          <DetailsWrapper>
            {tabConfig.renderComponent
              ? tabConfig.renderComponent({
                  isConsolidated: this.shouldShowComparison(),
                  showComparisonView: (onCompare) => {
                    if (this.shouldShowComparison()) {
                      this.setState({
                        showComparisonView: true,
                        onCompare,
                      });
                    } else {
                      onCompare();
                    }
                  },
                  onAgentFilterUpdate: (e) => {
                    this.handleFiltersUpdate("user", e);
                  },
                })
              : null}
          </DetailsWrapper>
        </TabWrapper>
      </DashboardWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app_user: state.app_user,
  selectedWorkspace: state.app_user.selectedWorkspace,
});

const mapDispatchToProps = (dispatch) => ({
  storeNotificatonCount: (notificationCount) => {
    dispatch({
      type: ReduxActionTypes.STORE_NOTIFICATION_COUNTS,
      payload: {
        notificationCount,
      },
    });
  },
  storeAnalyticsSegments: (segments) => {
    dispatch({
      type: ReduxActionTypes.SET_ANALYTICS_SEGMENTS,
      payload: segments
    });
  },
  removeGlobalFilter: (key) => {
    dispatch({
      type: ReduxActionTypes.REMOVE_GLOBAL_FILTER,
      payload: {
        key
      }
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(dashboard);
