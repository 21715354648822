import React from "react";
import { FilterComponentWrapper } from "../../conversation-details/wrappers";
import { channelOptions } from "../../conversation-list/utilities";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";
import FilterComponent from "./FilterComponent";

export const ChannelFilter = (props) =>
  !props.channelMultiSelect ? (
    <FilterComponentWrapper>
      <FilterComponent
        {...props}
        placeholder={"All Channels"}
        value={props.value}
        disabled={props.disabled}
        width={props.width || 160}
        style={props.style}
        handleChange={(value) => {
          props.handleChange(value);
        }}
        options={channelOptions}
      />
    </FilterComponentWrapper>
  ) : (
    <CustomFilterComponent
      placeholder={"All Channels"}
      value={props.value}
      disabled={props.disabled}
      width={props.width || 160}
      style={props.style}
      handleChange={(value) => {
        props.handleChange(value);
      }}
      options={channelOptions}
      multiSelect={props.channelMultiSelect}
      {...props}
    />
  );
