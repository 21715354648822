import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from '../../../components/ErrorFallback';
import RuleView from './components/RuleView';
import TableComponent from './components/TableComponent';

const QaCaseAssignmentComponent = (props) => {
  return (
    <Switch>
      <Route exact path={`/setup/qa-case-assignment/`} render={() => <TableComponent height="calc(100vh - 290px)" {...props} />} />
      <Route exact path={`/setup/qa-case-assignment/create-rule`} render={() => <RuleView mode = "create" {...props} />} />
      <Route exact path={`/setup/qa-case-assignment/edit-rule/:id`} render={() => <RuleView mode = "edit" {...props} />} />
    </Switch>         
  )
}

const QaCaseAssignment = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <QaCaseAssignmentComponent {...props} />
  </ErrorBoundary>
)

export default QaCaseAssignment;
