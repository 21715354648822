import { Select } from "antd";
import React from "react";
import useStore from "../../store";
import { InputWrapper } from "./styles";

const MapArray = ({
  selectedNode,
  multiselect,
  variable,
  objectKeys,
  variableKeys = [],
  handleChange,
}) => {
  const extarctKeysOfVariableFromNode = useStore(
    (state) => state.extarctKeysOfVariableFromNode
  );
  const { Option } = Select;

  const handleVariableChange = (val) => {
    if (val) {
      const [variableName, nodeId] = val.split("__&__");
      handleChange(
        extarctKeysOfVariableFromNode(nodeId, variableName),
        "variableKeys"
      );
      handleChange(val, "variable");
      handleChange(nodeId, "from");
    }
  };
  const handleObjectKeysChange = (val) => {
    if (val) {
      handleChange(val, multiselect ? "object_keys" : "object_key");
    }
  };

  return (
    <div className="pt-2">
      <InputWrapper>
        <label>From Array</label>
        <Select
          onChange={(val) => handleVariableChange(val)}
          style={{ width: "250px" }}
          value={variable}
        >
          {selectedNode?.data?.globalVariables.map((item) =>
            item["variables"].map(
              (variable) =>
                variable.type === "Array" &&
                !variable.name.startsWith("__") && (
                  <Option
                    key={variable.name}
                    value={variable.name + "__&__" + item.nodeId}
                  >
                    {" "}
                    {variable.displayName}{" "}
                  </Option>
                )
            )
          )}
        </Select>
      </InputWrapper>
      <InputWrapper>
        <label>{multiselect ? "Object Keys" : " Object Key"}</label>
        <Select
          mode={multiselect ? "multiple" : "default"}
          onChange={(val) => handleObjectKeysChange(val)}
          style={{ width: "250px" }}
          placeholder="Choose variable members"
          value={objectKeys}
        >
          {variableKeys?.map((variable) => (
            <Option key={variable.name} value={variable.name}>
              {variable.displayName}
            </Option>
          ))}
        </Select>
      </InputWrapper>
    </div>
  );
};

export default MapArray;
