import React, { useState, useEffect, Suspense } from "react";
import useStore from "../../store";
import ReduceVariable from "./ReduceVariable";
import KwargsName from "./KwargsName";
import MapArray from "./MapArrayVariable";
import Optional from "./Optional";
import { Spin } from "antd";
import DefaultInput from "./DefaultInput";
const TextEditor = React.lazy(() => import("../TextEditor/TextEditor"));

const ExpectedPayload = ({ updateNode }) => {
  const [loading, setLoading] = useState(true);
  //useMemo for nodeParams
  const [nodeParams, setNodeParams] = useState(null);
  const selectedNode = useStore((store) => store.selectedNode);

  useEffect(() => {
    if (selectedNode.kwargs) {
      setNodeParams(selectedNode.kwargs);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!Object.compare(selectedNode.kwargs, nodeParams)) {
        updateNode(null, selectedNode.id, {
          kwargs: { ...selectedNode.kwargs, ...nodeParams },
        });
      }
    }
  }, [nodeParams, loading]);

  const handleChange = (val, name) => {
    setNodeParams((p) => {
      return {
        ...p,
        [name]: val,
      };
    });
  };
  if (loading) {
    return <></>;
  }
  return (
    <>
      {selectedNode?.data?.expectedPayload?.map((item, ind) => {
        switch (item.inputType) {
          case "Reduce":
            return (
              <>
                <KwargsName
                  nodeId={selectedNode.id}
                  onNameChange={(name) => handleChange(name, "name")}
                  name={nodeParams?.name}
                />
                <ReduceVariable
                  key={ind}
                  selectedNode={selectedNode}
                  multiselect={item.multiselect}
                  handleChange={handleChange}
                  name={nodeParams?.name}
                  variable={nodeParams?.variable}
                  objectKeys={nodeParams?.object_keys || nodeParams?.object_key}
                  variableKeys={nodeParams?.variableKeys}
                />
              </>
            );
          case "MapArray":
            return (
              <>
                <KwargsName
                  nodeId={selectedNode.id}
                  onNameChange={(name) => handleChange(name, "name")}
                  name={nodeParams?.name}
                />
                <MapArray
                  key={ind}
                  selectedNode={selectedNode}
                  multiselect={item.multiselect}
                  handleChange={handleChange}
                  variable={nodeParams?.variable}
                  objectKeys={nodeParams?.object_keys || nodeParams?.object_key}
                  variableKeys={nodeParams?.variableKeys}
                />
              </>
            );
          case "optional":
            return (
              <Optional
                payload={item.payload}
                handleChange={handleChange}
                optional={nodeParams.optional || {}}
              />
            );
          case "TextEditor":
            return (
              <Suspense fallback={<Spin />}>
                <TextEditor
                  slack={true}
                  onMarkDownChange={(markddown) =>
                    handleChange(markddown, item.name)
                  }
                  saveRawState={(markdown) =>
                    handleChange(markdown, "rawState")
                  }
                  initState={nodeParams.rawState}
                  message={nodeParams.message}
                />
              </Suspense>
            );
          default:
            return (
              <DefaultInput
                item={item}
                nodeParams={nodeParams}
                handleChange={handleChange}
              />
            );
        }
      })}
    </>
  );
};

export default ExpectedPayload;
