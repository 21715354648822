import React, { Component } from 'react';
import { Button } from 'antd';
import styled from "@emotion/styled/macro";
import { ReactComponent as AddBtnIcon } from "../../assets/add.svg";

const ButtonWrapper = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    svg {
        path {
            fill: #6099D9;
        }
    }
`;

export class AddButton extends Component {
    render() {
        return (
            <ButtonWrapper type="link" {...this.props}><AddBtnIcon /> {this.props.children}</ButtonWrapper>            
        )
    }
}

export default AddButton
