import React from "react";
import UserManagement from "./../components/user-management";
import WorkspaceManagement from "./../components/workspace-management";
import ConversationTags from "./../components/conversation-tags";
import IntegrationManagement from "./../components/integration-management";
import RubricBuilder from "./../components/rubric-builder";
import ReportSharing from "./../components/report-sharing";
import NotificationsSettings from "./../components/notifications";
import Automation from "../../Automation";
import PhraseAnnotation from "./../components/phrase-annotation";
import ConversationCategorization from "./../components/conversation-categorization";
import SelfServingCustomFilters from './../components/SelfServingCustomFilters';
import Constants from "./constants";
import complianceQa from "./../assets/flag.svg";
import virtualCoach from "./../assets/hint.svg";
import faq from "./../assets/faq.svg";
import intent from "./../assets/intent.svg";
import metricTag from "./../assets/metric-tags.svg";
import {
  hasAdminRights,
  hasManagerRights,
  hasQAAuditorRights,
} from "../../AnalyticsDashboard/config";
import { ReactComponent as IntentManagement } from "./../assets/illustrations/IntentManagement.svg";
import { ReactComponent as ScriptingManagement } from "./../assets/illustrations/ScriptingManagement.svg";
import { ReactComponent as FaqManagement } from "./../assets/illustrations/FaqManagement.svg";
import { ReactComponent as FlagManagement } from "./../assets/illustrations/FlagManagement.svg";
import { ReactComponent as ResourceManagement } from "./../assets/illustrations/ResourceManagement.svg";
import appConfigs from "../../config";
import QaCaseAssignment from "../components/QaCaseAssignment";

// const commonFilters = [
//   {
//     type: "checkbox",
//     title: "QA Only",
//     key: "is_tagged_for_qa"
//   },
// ];

const UserManagementConfigurations = {
  tabType: Constants.TAB_TYPE.USER_MANAGEMENT,
  key: "user-management",
  title: "Users",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "User Management",
  renderComponent: (props) => {
    return <UserManagement {...props} />;
  },
};

const ReportShareConfigurations = {
  tabType: Constants.TAB_TYPE.REPORT_SHARE,
  key: "report-share",
  title: "Report Sharing",
  icon: "report-share",
  description: "Choose the audience and frequency of the report being sent.",
  fullTitle: "Report Sharing",
  hideTab: (props) => {
    if (hasManagerRights() || hasQAAuditorRights()) {
      return false;
    } else {
      return true;
    }
  },
  renderComponent: (props) => {
    return <ReportSharing {...props} />;
  },
};

export const NotificationsConfiguration = {
  tabType: Constants.TAB_TYPE.NOTIFICATION_SETTINGS,
  key: "notification-settings",
  title: "Notifications",
  icon: "notifications",
  description:
    "Manage notifications settings for different roles in your organization.",
  fullTitle: "Notifications",
  hideTab: (props) => {
    if (hasAdminRights()) {
      return false;
    } else {
      return true;
    }
  },
  renderComponent: (props) => {
    return <NotificationsSettings {...props} />;
  },
};

export const AutomationConfiguration = {
  tabType: Constants.TAB_TYPE.AUTOMATION,
  key: "automation",
  title: "Automation",
  icon: "automation",
  description: "Create your own workflows.",
  fullTitle: "Automations",
  hideTab: (props) => {
    return !appConfigs.showAutomationTab;
  },
  renderComponent: (props) => {
    return <Automation {...props} />;
  },
};

const WorkspaceManagementConfigurations = {
  tabType: Constants.TAB_TYPE.WORKSPACES,
  key: "workspace-management",
  title: "Workspaces",
  icon: "workspace",
  description: "Manage multiple projects from one place",
  fullTitle: "Workspaces",
  hideTab: (props) => {
    return !props.is_superuser || props.business_type === 2;
  },
  renderComponent: (props) => {
    return <WorkspaceManagement {...props} />;
  },
};

const ConversationTagsManagementConfigurations = {
  tabType: Constants.TAB_TYPE.CONVERSATION_TAGS,
  key: "conversation-tags",
  title: "Conversation Tags",
  icon: "conversation-tag",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Conversation Tags",
  renderComponent: (props) => {
    return <ConversationTags {...props} />;
  },
};

const IntegrationManagementConfigurations = {
  tabType: Constants.TAB_TYPE.RESOURCE,
  title: "Integrations",
  key: "integrations",
  description:
    "Integrate with knowledgebases and ticketing systems so that the most important info is always at your agent’s fingertips.",
  icon: "integration",

  fullTitle: "Integration Management",
  popupHelpText: [
    "Integrate knowledgebases and ticketing systems",
    "Automatic search based on topic of discussion",
  ],
  renderComponent: (props) => {
    return <IntegrationManagement {...props} />;
  },
  illustration: ResourceManagement,
};

const IntentManagementConfigurations = {
  tabType: Constants.TAB_TYPE.INTENT,
  title: "Scenarios",
  createNewBtnName: "Add Scenario",
  playgroundButtonName: "Test Scenario",
  negate: false,
  key: "scenarios",
  imageUrl: intent,
  filters: [
    {
      type: "search",
      placeholder: "Search scenarios..",
      key: "description",
    },
  ],
  icon: "intent",
  itemName: "Scenario",
  showTrainBtn: true,
  description:
    "Train LevelAI to understand what customers and agents are trying to achieve based on common example phrases.",
  fullTitle: "Scenario Management",
  popupButtonName: "Add a Scenario",
  tabItemName: "Scenario",
  popupHelpText: [
    "Human-level understanding with simple setup",
    "More accurate and sophisticated dynamic scripting ",
    "Agent flexibility instead of over-prescribed verbiage",
  ],
  illustration: IntentManagement,
};

const ScriptingManagementConfigurations = {
  tabType: Constants.TAB_TYPE.RULE,
  title: "Actions",
  key: "actions",
  createNewBtnName: "Add Action",
  description:
    "Design static scripting or set up dynamic rules to guide your agent with the best responses to any scenario.",
  imageUrl: virtualCoach,
  filters: [
    {
      type: "search",
      placeholder: "Search actions..",
      key: "title",
    },
    {
      type: "checkbox",
      title: "QA Only",
      key: "is_tagged_for_qa",
    },
  ],
  icon: "tip",
  itemName: "Action",
  negate: false,
  showTrainBtn: true,
  fullTitle: "Action Management",
  popupButtonName: "Add an Action",
  tabItemName: "Action",
  popupHelpText: [
    "Script adherence tracked for <b>every conversation</b>",
    'Optional "hints" to assist agents in real time',
    "Static or dynamic based on customer scenario",
  ],
  illustration: ScriptingManagement,
};

const FlagManagementConfigurations = {
  tabType: Constants.TAB_TYPE.RULE,
  title: "Flags",
  negate: true,
  key: "compliance-rules",
  createNewBtnName: "Add Flag",
  description:
    "Forbidden behaviors that warrant official review if they are violated.",
  filters: [
    {
      type: "search",
      placeholder: "Search flags..",
      key: "title",
    },
    {
      type: "checkbox",
      title: "QA Only",
      key: "is_tagged_for_qa",
    },
  ],
  icon: "flag",
  itemName: "Flag",
  imageUrl: complianceQa,
  showTrainBtn: true,
  fullTitle: "Flag Management",
  popupButtonName: "Add a Flag",
  tabItemName: "Flag",
  popupHelpText: [
    "Violations tracked for every conversation",
    'Optional "Warnings" to guide agents in real time',
    "Static or dynamic based on customer scenario",
  ],
  illustration: FlagManagement,
};

const MetricTagsConfigurations = {
  tabType: Constants.TAB_TYPE.METRIC_TAGS,
  title: "Metric Tags",
  key: "metric-tag",
  description:
    "Tell Level AI what to look out for to help annotate conversations and guide agents.",
  imageUrl: metricTag,
  fullTitle: "Metric Tags",
  icon: "metric-tag",
  filters: [
    {
      type: "search",
      placeholder: "Search Metric..",
      key: "title",
    },
  ],
};

const RubricBuilderConfigurations = {
  tabType: Constants.TAB_TYPE.RUBRIC_BUILDER,
  key: "rubric-builder",
  title: "Rubric Builder",
  icon: "rubric",
  description: "Design and deseminate your own custom grading rubric.",
  fullTitle: "Rubric Management",
  renderComponent: (props) => {
    return <RubricBuilder {...props} />;
  },
};

const FaqManagementConfigurations = {
  tabType: Constants.TAB_TYPE.FAQ,
  title: "FAQs",
  createNewBtnName: "Add FAQ",
  key: "faq-rules",
  description:
    "Provide your agents quick, scripted responses to the most common questions they can expect to receive.",
  negate: false,
  filters: [
    {
      type: "search",
      placeholder: "Search FAQs..",
      key: "title",
    },
    {
      type: "checkbox",
      title: "Hide Disabled",
      key: "is_active",
    },
  ],
  icon: "faq",
  itemName: "FAQ",
  imageUrl: faq,
  showTrainBtn: true,
  fullTitle: "FAQ Management",
  popupButtonName: "Add a FAQ",
  tabItemName: "FAQ",
  popupHelpText: [
    "Instantenous dissemination of new information",
    "No need for dedicated training on rare scenarios",
    "Flexible by providing a set of sample phrasings",
  ],
  illustration: FaqManagement,
};

const ResourceManagementConfigurations = {
  tabType: Constants.TAB_TYPE.RESOURCE,
  title: "Resources",
  createNewBtnName: "Add Resource",
  key: "resources",
  description:
    "Locate and surface the most relevant training materials or articles based on the content of your agent’s conversation.",
  negate: false,
  filters: [
    {
      type: "search",
      placeholder: "Search resources..",
      key: "title",
    },
  ],
  icon: "resource",
  itemName: "Resource",
  imageUrl: faq,
  fullTitle: "Resource Management",
  popupButtonName: "Add a Resource",
  tabItemName: "Resource",
  popupHelpText: [
    "Upload offline <b>documents</b> or entire <b>websites</b>",
    "Integrate with <b>ticketing systems</b> and <b>knowledgebases</b>",
    "<b>Automatic search</b> based on topic of discussion",
  ],
  illustration: ResourceManagement,
};

const IntentPhrasesConfigurations = {
  tabType: Constants.TAB_TYPE.INTENT_PHRASES,
  title: "Intent Phrases",
  key: "intent-phrases",
  icon: "intent",
  itemName: "Intent Phrases",
  description: "",
  fullTitle: "Intent Phrases",
  tabItemName: "Intent Phrases",
  hideTab: (props) => {
    return !props?.is_levelai;
  },
  renderComponent: (props) => {
    return <PhraseAnnotation {...props} />;
  },
};

const CategorizationConfigurations = {
  tabType: Constants.TAB_TYPE.CATEGORIZATION,
  title: "Categories",
  key: "categorization",
  icon: "categorization",
  itemName: "Categorization",
  description: "Train LevelAI to understand how to group your conversations",
  fullTitle: "Conversation Categorization",
  tabItemName: "Categorization",
  // hideTab: (props) => {
  //   return !props.is_levelai;
  // },
  renderComponent: (props) => {
    return <ConversationCategorization {...props} />;
  },
};

const SelfServingCustomFilterConfigurations = {
  tabType: Constants.TAB_TYPE.EXTERNAL_DATA,
  title: "External data",
  key: "external-data",
  icon: "external-data",
  itemName: "External data",
  description: "Manage data from external sources",
  fullTitle: "External data",
  tabItemName: "External data",
  hideTab: (props) => {
    return !hasManagerRights() || !appConfigs.ENABLE_SELF_SERVE_FILTERS;
  },
  renderComponent: (props) => {
    return <SelfServingCustomFilters {...props} />;
  },
};

const QaCaseAssignmentConfigurations = {
  tabType: Constants.TAB_TYPE.QA_CASE_ASSIGNMENT,
  title: "QA Case Assignment",
  key: "qa-case-assignment",
  icon: "qa-case-assignment",
  itemName: "QA Case Assignment",
  description: "Manage interaction sampling and assignment automation rules for QA",
  fullTitle: "QA Case Assignment",
  tabItemName: "QA Case Assignment",
  hideTab: (props) => {
    return !hasManagerRights() || !appConfigs.ENABLE_SAMPLING_TASK_QUEUE;
  },
  renderComponent: (props) => {
    return <QaCaseAssignment {...props} />;
  },
};

const FAQPhrasesConfigurations = {
  tabType: Constants.TAB_TYPE.FAQ_PHRASES,
  title: "FAQ Phrases",
  key: "faq-phrases",
  icon: "faq",
  itemName: "FAQ Phrases",
  description: "",
  fullTitle: "FAQ Phrases",
  tabItemName: "FAQ Phrases",
  hideTab: (props) => {
    return !props?.is_levelai;
  },
  renderComponent: (props) => {
    return <PhraseAnnotation {...props} />;
  },
};

export const SetupTabs = [
  UserManagementConfigurations,
  ReportShareConfigurations,
  SelfServingCustomFilterConfigurations,
  QaCaseAssignmentConfigurations,
  NotificationsConfiguration,
  WorkspaceManagementConfigurations,
  IntentManagementConfigurations,
  ScriptingManagementConfigurations,
  FlagManagementConfigurations,
  ConversationTagsManagementConfigurations,
  AutomationConfiguration,
  MetricTagsConfigurations,
  RubricBuilderConfigurations,
  FaqManagementConfigurations,
  ResourceManagementConfigurations,
  IntegrationManagementConfigurations,
  //IntentPhrasesConfigurations,
  //FAQPhrasesConfigurations,
  CategorizationConfigurations,
];

export const SetupCategories = [
  {
    name: "ORGANIZATION",
    items: [
      UserManagementConfigurations,
      ReportShareConfigurations,
      SelfServingCustomFilterConfigurations,
      QaCaseAssignmentConfigurations,
      NotificationsConfiguration,
      WorkspaceManagementConfigurations,
    ],
  },
  {
    name: "SCRIPTING ENGINE",
    items: [
      IntentManagementConfigurations,
      ConversationTagsManagementConfigurations,
      MetricTagsConfigurations,
      CategorizationConfigurations,
      // ScriptingManagementConfigurations,
      // FlagManagementConfigurations,
      RubricBuilderConfigurations,
      AutomationConfiguration,
      //IntentPhrasesConfigurations,
    ],
  },
  {
    name: "KNOWLEDGE CENTER",
    items: [
      FaqManagementConfigurations,
      ResourceManagementConfigurations,
      IntegrationManagementConfigurations,
      //FAQPhrasesConfigurations,
    ],
  },
];

export const setupEditCategoryAPIObject = (categoryTitle, phrasesArr) => {
  let dataObj = {};
  categoryTitle && (dataObj.title = categoryTitle);
  phrasesArr.length > 0 && (dataObj.phrases = phrasesArr);
  return dataObj;
};
