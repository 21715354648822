import React from "react"
import { ReactComponent as VerifiedIcon} from "../../assets/verified-icon.svg";
import { ReactComponent as VerificationRequireIcon} from "../../assets/unverified-icon.svg";
import styled from "@emotion/styled/macro";
import moment from "moment";

const VerificationData = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .timestamp {
        margin-left: 8px;
        color: #828282;
        font-size: 12px;
    }
    .user {
        color: #BDBDBD;
        font-size: 12px;
        margin-left: 8px;
    }
`;

const VerifiedChipWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.verified ? "rgba(39, 174, 96, 0.1)" : "rgba(232, 146, 68, 0.1)"};
    color: ${props => props.verified ? "#27AE60" : "#E89244"};
    padding: 4px 10px;
    border-radius: 100px;
    min-width: 72px;
    font-size: 11px;
    height: 24px;
    margin-left: -1px;
    svg {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        position: relative;
        top: -1px;
    }
`;

const VerificationChip = ({ verified, verifiedTime, verifiedBy}) => {
    return (
        <VerificationData>
            <VerifiedChipWrapper verified={verified}>
                {verified ? <VerifiedIcon /> : <VerificationRequireIcon />}
                {verified ? "Verified" : "Verification Required"}                           
            </VerifiedChipWrapper>
            {verified && verifiedBy && <span className="user">{`By ${verifiedBy.first_name} ${verifiedBy.last_name}`}</span>}
            {verified && verifiedTime && <span className="timestamp">{moment(verifiedTime).fromNow()}</span>}
        </VerificationData>
    )
}

export default VerificationChip;