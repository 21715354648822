import React, { useState, useEffect } from "react";
import { Modal } from "antd";
//import PopUp from "./../commons/pop-up";
import SetupHeader from "../commons/SetupHeader";
import {
  getGoogleDriveIntegrationURL,
  getSalesForceIntegrationURL,
  getZendeskIntegrationURL,
  getCloudApps,
  revokeCloudApp,
  getSlackIntegrationURL,
} from "./../../api/api";
import {
  SetupWrapper,
  IntegrationWrapper,
  LineSeparator,
} from "./../commons/wrappers";
import { ReactComponent as ConnectedIcon } from "../../assets/connected.svg";
import { connect } from "react-redux";
import SetupHelper from "../commons/SetupHelper";
import appConfigs from "../../../config";
import ZendeskPrompt from "./zendesk-prompt";
import ZendeskIcon from "../../assets/zendesk-icon.png";
import appConfig from "../../../config";
import { getItemFromStore, setItemInStore } from "../../../utils";

const IntegrationManagement = (props) => {
  const [showInfo, toggleShowInfo] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const handleShowPrompt = () => setShowPrompt(!showPrompt);
  useEffect(() => {
    try {
      if (!getItemFromStore("integration-management")) {
        setItemInStore("integration-management", true);
        toggleShowInfo(true);
      }
    } catch (e) {}
  }, []);
  useEffect(() => {
    getIntegrations();
  }, []);
  const getIntegrations = () => {
    getCloudApps().then((res) => {
      setIntegrations(res.results.map((item) => item.cloudapp_name));
    });
  };

  let is_google_drive = integrations.includes("google_drive");
  let is_salesforce = integrations.includes("salesforce_service_cloud");
  let is_zendesk = integrations.includes("zendesk");
  let googleDrive = {
    className: `service-card ${is_google_drive ? " disconnect" : " connect"}`,
    href: !is_google_drive ? getGoogleDriveIntegrationURL() : null,
    revoke: () => {
      revokeCloudApp({ cloudapp_name: "google_drive" }).then(() => {
        getIntegrations();
      });
    },
  };
  let salesForce = {
    name: "salesforce_service_cloud",
    title: "Salesforce",
    className: `service-card ${is_salesforce ? " disconnect" : " connect"}`,
    href: !is_salesforce ? getSalesForceIntegrationURL() : null,
    logo_url:
      "https://res.cloudinary.com/dxirmkc1j/image/upload/v1595589332/salesforce_zdtxnk.svg",
  };
  let zenDesk = {
    name: "zendesk",
    title: "Zendesk",
    className: `service-card ${is_zendesk ? " disconnect" : " connect"}`,
    href: !is_zendesk
      ? (subDomainValue) => getZendeskIntegrationURL(subDomainValue)
      : null,
    logo_url:
      "https://res.cloudinary.com/dxirmkc1j/image/upload/v1595589332/salesforce_zdtxnk.svg",
  };
  let slack = {
    name: "slack",
    title: "Slack",
    className: `service-card connect`,
    href: getSlackIntegrationURL(),
    logo_url: "https://platform.slack-edge.com/img/add_to_slack.png",
  };
  return (
    <SetupWrapper className="setup-tab-user">
      <SetupHeader
        title={props.fullTitle}
        icon={props.icon}
        description={props.description}
        openInfo={() => toggleShowInfo(true)}
      />
      <LineSeparator style={{ marginTop: "32px" }} />
      <IntegrationWrapper>
        <div className={googleDrive.className}>
          <a
            href={googleDrive.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (!googleDrive.href) {
                revokeCloudApp({ cloudapp_name: "google_drive" }).then(() => {
                  getIntegrations();
                });
              }
            }}
          >
            <div className="integration-icon">
              <img
                alt=""
                src={
                  "https://res.cloudinary.com/dxirmkc1j/image/upload/v1593251931/drive-logo_ks8lj4.png"
                }
              />
            </div>
            <div className="integration-title">Google Drive</div>
            <ConnectedIcon className="isConnected" />
          </a>
        </div>

        <div className={salesForce.className}>
          <a
            href={salesForce.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (!salesForce.href) {
                revokeCloudApp({
                  cloudapp_name: salesForce.name,
                  sandbox: !!appConfigs.SALESFORCE.IS_SANDBOX,
                }).then(() => {
                  getIntegrations();
                });
              }
            }}
          >
            <div className="integration-icon">
              <img alt="" src={salesForce.logo_url} />
            </div>
            <div className="integration-title">{salesForce.title}</div>
            <ConnectedIcon className="isConnected" />
          </a>
        </div>

        <div className={zenDesk.className} onClick={handleShowPrompt}>
          {/* <a href={zenDesk.href} target="_blank" rel="noopener noreferrer" onClick={() => {if (!zenDesk.href) {
                        revokeCloudApp({cloudapp_name: zenDesk.name}).then(()=>{getIntegrations()})
                    }}}> */}
          <div className="integration-icon">
            <img alt="" src={ZendeskIcon} />
          </div>
          <div className="integration-title">{zenDesk.title}</div>
          <ConnectedIcon className="isConnected" />
          {/* </a> */}
        </div>
        {appConfig.showSlackIntegration && (
          <div className={slack.className}>
            <a href={slack.href} target="_blank" rel="noopener noreferrer">
              <div className="integration-icon">
                <img
                  alt=""
                  src={slack.logo_url}
                  style={{ width: "120px", objectFit: "contain" }}
                />
              </div>
              <div className="integration-title">{slack.title}</div>
              <ConnectedIcon className="isConnected" />
            </a>
          </div>
        )}
      </IntegrationWrapper>
      <Modal
        visible={showInfo}
        header={null}
        closable={false}
        title={null}
        width={500}
        footer={null}
      >
        <SetupHelper
          title={props.fullTitle}
          description={props.description}
          illustration={props.illustration}
          doneTitle="Got It!"
          showDone
          popupHelpText={props.popupHelpText}
          onDone={() => toggleShowInfo(false)}
        />
      </Modal>
      <ZendeskPrompt
        zenDesk={zenDesk}
        showPrompt={showPrompt}
        handleShowPrompt={handleShowPrompt}
        getIntegrations={getIntegrations}
      />
    </SetupWrapper>
  );
};

const mapStateToProps = (state) => ({ app_user: state.app_user });

export default connect(mapStateToProps, null)(IntegrationManagement);
