import React, { useState } from 'react';
import Dropzone from "react-dropzone";
import { Input } from 'antd';
import styled from "@emotion/styled/macro";

const StyledInput = styled(Input)`
    &&& {
        padding: 4px 10px;
    }
`;

const RESOURCE_TABS = {
    PUBLIC: "public",
    OFFLINE: "offline"
}

const ResourceTabs = ({tab, onFileChange, onPublicUrlChange}) => {

    const handleDrop = acceptedFiles => {
        setFile(acceptedFiles[0])
        onFileChange(acceptedFiles[0])
    }
    const [ file, setFile ] = useState(null);

    const [url, setUrl] = useState("");
    const handleUrlChange = (e) => {
        setUrl(e.target.value);
        onPublicUrlChange(e.target.value);
    }

    switch(tab) {
        case RESOURCE_TABS.PUBLIC: {
            return (
                <>
                    <hr />
                    <StyledInput placeholder={"https://"} value={url} onChange={handleUrlChange}/>
                    <br />
                    <hr />
                    <p>The LevelAI system will scan this web page to identify the best time to present it to your agent, based on their conversation. </p>
                </>
            )
        }
        case RESOURCE_TABS.OFFLINE: {
            const maxSize = 2*5242880;
            return (
                <>
                    <Dropzone onDrop={handleDrop}
                        accept="text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                        minSize={0}
                        maxSize={maxSize}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps, isDragReject, fileRejections }) => {
                            const errorCodes = fileRejections.length > 0 && fileRejections[0].errors.map((error) => (error.code))
                            const isFileTooLarge = errorCodes && errorCodes.includes("file-too-large")
                            const isFileTypeInvalid = errorCodes && errorCodes.includes("file-invalid-type")
                            return (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    {isFileTypeInvalid && (
                                        <div className="text-danger">
                                            We only accept Word, PDF and Text files
                                        </div>
                                    )}
                                    {isFileTooLarge && (
                                        <div className="text-danger">
                                            The file size exceeds 10MB. Please try again.
                                        </div>
                                    )}
                                    {
                                        file ?
                                        <p>File: {file.name}</p>
                                        :
                                        <>
                                        {
                                            isFileTooLarge || isFileTypeInvalid ?
                                            null
                                            :
                                            <>
                                                <p>Drag and drop file here or Browse Computer</p>
                                                <p>Word, PDF, Text</p>
                                            </>
                                        }
                                        </>
                                    }
                                    
                                </div>
                                )
                            }
                        }
                    </Dropzone>
                    <br />
                </>
            )
        }
        default: 
            return;
    }
}

export default ResourceTabs;