import { Select } from 'antd'
import React from 'react'
import { useSelector } from "../../store";


const NegavtiveSentiments = ({value,handleChange}) => {
  const { Option } = Select

  return (
    <Select value={value} onChange={(val) => handleChange(val)} placeholder="Sentiment Options">
      <Option value={"Yes"}>Yes</Option>
      <Option value={"No"}>No</Option>
    </Select>
  )
}

export default NegavtiveSentiments