import React from "react";
import styled from "@emotion/styled/macro";

const NotificationCountWrapper = styled.div`
    position: absolute;
    min-width: 18px;
    top: ${props => props.top ? props.top : -10}px;
    right: ${props => props.right ? props.right : -10}px;
    width: ${props => props.count.toString().length > 3 ? "max-content" : "18px"};
    height: ${props => props.count.toString().length > 3 ? 20 : 18}px;
    background: #DB554D;
    border-radius: 100px;
    font-size: ${props => props.count.toString().length > 3 ? 8 : 10}px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
`;

const NotificationCountChip = (props) => {
    return (
        <NotificationCountWrapper count={props.count} top={props.top} right={props.right}>
            {props.count}
        </NotificationCountWrapper>
    )
}

export default NotificationCountChip;