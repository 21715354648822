import React, { useEffect, useState } from "react";
import { Spin, Button } from "antd"; 
import { StyledTable } from "./../../../components/common/styled-components";
import { TableLoaderWrapper } from "./wrappers";
import styled from "@emotion/styled";

const PaginationButton = styled(Button)`
  background-color: rgba(96, 153, 217, 0.1);
  color: rgba(96, 153, 217, 1);
  margin: 10px 350px;
`

export const CustomTable = ({tableData, columns, isLoading, isNextPageFetching, fetchNextPage, rowClassName, showMoreButton, showMoreButtonLoading}) => {
  const trackScrolling = () => {
    const wrappedElement = document.getElementsByTagName('table')[0]
    let isBottom = (el) => {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    if(isBottom(wrappedElement)) {
      let node = document.getElementsByClassName("setup-tab")[0];
      node.removeEventListener('scroll', trackScrolling);
      fetchNextPage();
    }
  }
  return (
    <React.Fragment>
      <StyledTable 
        rowKey="id"
        dataSource={tableData}  
        columns={columns}       
        pagination={false}
        rowClassName={rowClassName}
        showLoading={isLoading}  
      />
      {(isNextPageFetching || isLoading) && (<TableLoaderWrapper>
          <Spin />
      </TableLoaderWrapper>)}
      {
        !isLoading && showMoreButton && <PaginationButton shape="round" onClick={fetchNextPage} loading={showMoreButtonLoading}>See More</PaginationButton>
      }
    </React.Fragment>
  )
}