import { getSchemaName } from "../api/api";
import { getSalesforceUrl, is_sandbox_enabled } from "./salesforceConfig";

const salesforceUrl = getSalesforceUrl();

const zendeskUrl = (subdomainValue) => `https://${subdomainValue}.zendesk.com/`;

export default {
  SALESFORCE: {
    CLIENT_ID:
    (window.location.hostname.includes('affirm') || window.location.hostname.includes('staging-dashboards')) ? "3MVG9l2zHsylwlpT.6JDTp5dw1uPnf_ZNuEUIXNL1IhH1I9ipiSCkPoFqtcb.SkIkyAWv2JyKdV6QOm.siDSe" : "3MVG9jBOyAOWY5bWMRp9IPRzEA64yR5oghLLJorHj4KATZlnxCb5NnhFRbRlGT81F3hgzYFLznRC.5P2cM5fL",
    URL: salesforceUrl,
    IS_SANDBOX: is_sandbox_enabled(),
    SCOPE: (window.location.hostname.includes('affirm') || window.location.hostname.includes('staging-dashboards')) ? "refresh_token%20content%20api" : "refresh_token%20api%20full",
  },
  ZENDESK: {
    CLIENT_ID: "zdg-level_ai_agent_assist_app",
    URL: zendeskUrl,
    SCOPE: "read",
  },
  RING_CENTRAL: {
    IS_ENABLED: true,
  },
  CONVERSATION_SEARCH: {
    showConversationSearchInFilter: true,
    showConversationTranscriptSearch: true,
  },
  SENTRY_KEY:
    "https://c553a8070d2d4f869f21545b1bb9abf6@o1048171.ingest.sentry.io/6025272",
  HEAP_APPLICATION_ID: "1564303084",
  OKTA_BASE_URL: "https://dev-7105105.okta.com/oauth2/default/v1/authorize",
  OKTA_CLIENT_ID: "0oajcbcrpwOeOx6gD5d5",
  SHOW_ORGANIZATION_FILTER: window.location.hostname.includes("bpo"),
  //DEFAULT_CHANNEL : window.location.hostname.includes("andromeda") ? "2" : "", //default channel for andromeda is removed for now(release for 14 july 2021)
  DEFAULT_CHANNEL: "",
  DEFAULT_DATE_FILTER: window.location.hostname.includes("infocision")
    ? ""
    : "30",
  SHOW_TIP_FOLLOW: true,
  SHOW_RESOURCE_FOLLOW: false,
  showIntentConflict: true,
  showWorkspaceTab: false,
  enablePlaygroundUI: true,
  versionNumber: "v2",
  enableMetricTagFilter: true,
  CUBEJS_BASE_URL: "https://cube-staging.thelevel.ai/cubejs-api/v1",
  HASURA_BASE_URL: "https://hasura-staging.thelevel.ai/v1/graphql",
  ENABLE_CUSTOM_COLUMNS: true,
  ENABLE_INSTA_SCORE: true,
  SHOW_QA_EVAL_TABLE: false,
  USE_GRAPHQL_API: true,
  ENABLE_QA_CALIBRATION: true,
  ENABLE_OKTA: true,
  showSlackIntegration: true,
  showAutomationTab: true,
  ENABLE_FILTER_UI: true, //used for new filter UI
  ENABLE_CHANNEL_BASED_CONVERSATION_TAGS: true,
  ENABLE_SELF_SERVE_FILTERS: true,
  ENABLE_SCREEN_RECORDING: true,
  ENABLE_SAMPLING_TASK_QUEUE: true,
  ENABLE_RUBRIC_AGENT_TYPE: !window.location.hostname.includes("andromeda"),
  ENABLE_AUTH0: true,
  ENABLE_ONE_LOGIN: true,
  ENABLE_CUSTOM_FIELDS_V2_API: true,
  ENABLE_IS_CHECKMARKED: true
};
