export const UserStates = [
  {
    label: "Active",
    value: "active"
  },
  {
    label: "Inactive",
    value: "inactive"
  }
];

export const UserRoles = {
  Admin: "ADMIN",
  Manager: "MANAGER",
  Agent: "AGENT",
  Qa_Auditor: "QA_AUDITOR"
}

export const Organizations = [
  {
    label: "Tech Support",
    value: "Tech Support"
  },
  {
    label: "Sales Support",
    value: "Sales Support"
  },
  {
    label: "Customer Support",
    value: "Customer Support"
  }
];

// export const Filters = ({ roles }) => {
//   return [
//     {
//       type: "search",
//       placeholder: "Search users..",
//       key: "title"
//     },
//     {
//       type: "select",
//       placeholder: "Organization",
//       key: "organization",
//       options: Organizations
//     },
//     {
//       type: "select",
//       placeholder: "Role",
//       key: "group",
//       options: roles
//     },
//     {
//       type: "checkbox",
//       title: "Hide inactive",
//       key: "is_active"
//     },
//     {
//       type: "checkbox",
//       title: "Hide archived",
//       key: "is_hidden"
//     }
//   ];
// }