import { message } from "antd";
import Axios from "axios";
import { UserRoles } from "../CompanySetup/components/user-management/config";
import Constants from "../CompanySetup/utils/constants";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import { DASHBOARD_OPTIONS } from "../user-dashboard/utils";
import { axiosInstance, getSchemaName, getToken, getHeaders, BASE_URL } from "./../api/api";
import { createQueryParam } from "./utils";

export const getQACalibrationList = (cancelToken,rest) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/qa_calibration/`,
    params: rest.userFilters,
     
    cancelToken: cancelToken?.token
  }).then(res => {
    return res.data;
  }).catch(err => {
    if (Axios.isCancel(err)) {
      throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
     }
    throw new Error();
  });
}

export const getCalibrationStatusesList = () => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/qa_calibration/status_list`,
     
  }).then(response => {
    const filterKey = Constants.FILTER_NAMES.CALIBRATION_STATUS;
    dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: response.data }});
    return response.data
  }).catch(err => {
    const filterKey = Constants.FILTER_NAMES.CALIBRATION_STATUS;
    dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: [] }});
    return []
  });
}

export const getRoleSpecificList = (roles, joinCondition=Constants.ROLE_CONSTANTS.JOIN_TYPE.OR, roleScope=Constants.ROLE_CONSTANTS.LOCAL, filterKey = "") => (dispatch, getState) => {
  //global is used for the global filters. local is used if we are trying to populate values inside a local filter.
  let queryParam = `role=`+ String(roles);
  return axiosInstance({
    method: "GET",
    url: `/accounts/users/list/?${queryParam}&list_all=true`,
     
  }).then(response => {
    roleScope === Constants.ROLE_CONSTANTS.GLOBAL && dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: response.data }});
    return response.data;
  }).catch(err => {
    roleScope === Constants.ROLE_CONSTANTS.GLOBAL && dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: [] }});
    //throw new Error();
  });
}

export const sendInvites = (filters) => () => {
  return axiosInstance({
    method: "POST",
    url: `/qa_calibration/`,
    data: filters,
     
  }).then(response => {
    return response;
  }).catch(err => {
    throw new Error();
  });
}

//to be changed
export const fetchConversation = (cancelToken ,rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/qa_calibration/conversations/list/`,
    params: { search: rest.value },
    headers: getHeaders(),
    cancelToken: cancelToken?.token
  }).then(response => {
    return response;
  }).catch(err => {
    throw new Error();
  });
}

export const getSessionDetails = (cancelToken, rest) => () =>  {
  return axiosInstance({
    method: "GET",
    url: `/qa_calibration/report/${rest.sessionID}`,
    headers: getHeaders(),
    cancelToken: cancelToken?.token
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}

export const deleteSession = (sessionId) => () => {
  return axiosInstance({
    method: "DELETE",
    url: `/qa_calibration/${sessionId}/`,
    headers: getHeaders(),
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}

export const completeSession = (sessionId) => () => {
  return axiosInstance({
    method: "PATCH",
    url: `/qa_calibration/${sessionId}/`,
    headers: getHeaders(),
    data: {
      "status": 1
    }
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}

export const postGlobalComment = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "POST",
    url: `/qa_calibration/feedback/comment/`,
    data: {
      id: rest.sessionID,
      discussion: rest.comment
    },
    headers: getHeaders(),
    cancelToken: cancelToken?.token
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}

export const postQuestionComment = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "POST",
    url: `/qa_calibration/session_report/comment/`,
    data: {
      comment: rest.comment,
      session: rest.session,
      conversation: rest.conversation,
      question: rest.question,
      user: rest.user
    },
    headers: getHeaders(),
    cancelToken: cancelToken?.token
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}
