import styled from "@emotion/styled/macro";

export const StyledKeyword = styled.p`
  border: 1px solid ${({ color }) => color || "#dddddd"};
  border-radius: 16px;
  padding: 5px 10px;
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  cursor: pointer;
`;

export const StyledHint = styled.div`
  background: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  min-height: 40px;
  width: 80%;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;
export const StyledKnowledge = styled.div`
  background: #49b36f;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  min-height: 40px;
  width: 80%;
`;

export const StyledWarning = styled.div`
  background: #e89244;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  min-height: 40px;
  width: 80%;
`;

export const StyledFlag = styled.div`
  background: #db554d;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  min-height: 40px;
  width: 80%;
`;

export const StyledConversationHeader = styled.h1`
  font-size: 18px;
  color: #707070;
`;

export const StyledTimelineItem = styled.div`
  background: #ffffff;
  border-radius: 8px;
  color: black;
  padding: 10px;
  width: 26vw;
  margin: 10px 0px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  position: relative;

  div {
    .timeline-img {
      height: 70px;
    }
  }
  .content {
    width: 90%;
  }
  .title {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #4F4F4F;
    font-size: 13px;
    margin-bottom: 3px;
  }
  .title-2 {
    color: #BDBDBD;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .description {
    font-size: 11px;
    color: #828282;
    width: calc(100% - 40px);
  }
`

export const StyledBtn = styled.button`
  height: 20px;
  display: flex;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0px 4px;
  .icon {
    height: 100%;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledTimelineIcon = styled.span`
  width: 10px;
  height: 10px;
  background: ${({ color }) => color || "#dddddd"};
  border-radius: 50%;
  display: inline-block;
`;

export const StyledCategoryIcon = styled.span`
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 10px;
  background: ${({ color }) => color || "#dddddd"};
  border-radius: 5px;
  display: inline-block;
  font-size: 10px;
  color: white
`;

export const ConversationTimeLine = styled.div`
  background: #FFFFFF;
  /* 
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05); 
  */
  border-radius: ${props => props.isAgent ? "0" : "4px"};
  width: ${props => props.isAgent ? "100%" : "calc(40% + 20px)"};
  min-width: ${props => !props.isAgent && "425px"};
  padding-bottom: ${props => props.isAgent ? "20px" : "20px"};
  .ant-timeline-item-pending {
    height: 42px; 
  }
  .ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue {
    width: 100%;
    transform: none;
    height: 42px;
}
`;

export const ConversationTimeLineTitle = styled.div`
  border-bottom: 1px solid #F2F2F2;
  padding: 12px 21px;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;

export const TimeLineItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 12.5px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${props => props.category === "FLAG" ? "#DB554D" : "#ffffff"};  
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  opacity: ${props => props.fade ? "0.3" : "1"};
  cursor: ${props => props.clickable && "pointer"};
`;

export const TimeLineItemDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  svg {
    width: 36px;
    height: 36px;
  }
  img {
    width: 36px;
    height: 36px;
    border-radius: 2px;
  }
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 46px);
  margin-left: 10px;
  position: relative;
  min-height: 48px;
`;

export const ItemPrimaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ItemSecondaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  position: relative;
  .description {
    width: ${props => props.searchOnly ? "100%" : "calc(100% - 72px)"};
    margin: 0;
    line-height: 14px;
    font-size: 12px;
    color: ${props => props.category === "FLAG" ? "#ffffff" : "#828282"};
    font-weight: ${props => props.category === "FLAG" ? "bold" : "normal"};    
  }  
  button.ant-btn-link {
    color: ${props => props.category === "FLAG" && "#ffffff"};
    &:hover {
      color: ${props => props.category === "FLAG" && "#ffffff"};
    }
  }
`;

export const FeedbackButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  z-index: 1;
  svg {
    path {
      fill: ${props => props.isFlag && "#ffffff"};
    }
  }
`;

export const PopupDescriptionWrapper = styled.div`
  width: ${props => props.searchOnly ? "100%" : "calc(100% - 72px)"};
`;

export const StyledTimeStamp = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 14px;  
  color: ${props => props.category === "FLAG" ? "#ffffff" : "#bdbdbd"};
  white-space: nowrap;
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const StyledTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  width: calc(100% - 90px);
  white-space: normal;
  color: ${props => props.category === "FLAG" ? "#ffffff" : "#4F4F4F"};
  font-weight: ${props => props.category === "FLAG" ? "bold" : "normal"};
`; 

export const StyledAnchor = styled.span`
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin: 0;
  width: calc(100% - 90px);
  white-space: normal;
  color: #6099D9;
  &:hover {
    color: #6099D9;
    text-decoration: none;
  }  
  &:visited {
    color: #BB6BD9;
  }
`; 

export const ConversationTimeLineFilters = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 7px;
    .ant-checkbox-wrapper {
        font-size: 12px;
        color: #828282;
    }
    .ant-checkbox .ant-checkbox-inner {
        width: 12px;
        height: 12px;
        border-color: #6099D9;        
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #6099D9;
    }
`;

export const CallStartDisplay = styled.div`
    background: rgba(82, 161, 166, 0.1);
    border: 1px solid #52A1A6;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    width: 100%;
    height: 27px;
    padding: 6px 0;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #52A1A6;
    span {
        font-weight: 900;
        margin-right: 5px;
    }
`;

export const AgentFilters = styled.div`
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #0C3768 23.23%, #0B55A9 93.47%);
    height: 46px;
    margin: 0;
`;

export const IconWrapper = styled.div`
    background: ${props => props.selected ? "rgba(255, 255, 255, 0.2)" : "transparent"};
    height: 100%;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;  
    cursor: pointer;    
    transition: background 0.2s ease-in;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
`;

export const IconInnerWrapper = styled.div`
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: white;
    display: flex;
    align-items: center;
    svg {
      margin-right: 7.5px;
      path {
        opacity: ${props => props.selected ? 1 : 0.6 };
      }
    }    
    transition: opacity 0.2s ease-in;
`;

export const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
  button:last-of-type {
      margin-left: 4px;
  }
`;

export const CustomCardWrapper = styled.div`
  display: flex;
  width: calc(100% - 72px);
`;

export const GroupFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  height: 32px;
  .group-filter {
    display: flex;
    flex: 1 1;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    &.selected {
      font-weight: bold;
      border-bottom: 2px solid #0B4B93;
    }
    .notification {
      background: #DB554D;
      border-radius: 100px;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F3F2F2;
  border-radius: 4px;
  padding: 12.5px;
  font-size: 12px;
  margin: 0;
  .left-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }    
  }
  .user-details {
    border-radius: 5px;
    text-align: right;
    p {
        margin: 0px;
    }
  }
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -40px;
    svg {
      height: 20px;
    }
    #conversation-status {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 9px;
      background: transparent;
      position: relative;
      left: -40px;
    }
  }
`;

export const CheckboxWrapper = styled.div `
  position: relative;
  top: 1px;
  padding: 0 0 0 7px;
  .ant-checkbox-inner {
    border-radius: 50%;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
        background: rgb(39, 174, 96);
        border: rgb(39, 174, 96);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: rgb(189, 189, 189) !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: rgb(39, 174, 96);
  }

  .ant-checkbox-checked::after {
    border-radius: 50%;
    border: rgb(39, 174, 96);
  }
`
