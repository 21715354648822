import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FilterComponent from "./FilterComponent";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

const ConversationTagFilter = ({
  value,
  handleChange,
  componentWidth,
  selectedWorkspace,
  conversationTagMultiSelect,
  filtersSegmentList,
  style = {},
  width,
  disabled = false,
  ...rest
}) => {
  const [conversationTag, updateConversationTag] = useState(value);
  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    updateConversationTag(value);
  }, [value]);
  useEffect(() => {}, [selectedWorkspace]);

  return (
    //need to deprecated once we move single select to new custom filters ui
    !conversationTagMultiSelect ? <FilterComponent
        width={width || 200}
        options={filtersSegmentList.conversationTags?.length > 0 ? [...new Set(filtersSegmentList.conversationTags.filter((item)=>item.title!=="").map((item) =>{

          return {
              value: item.id,
              label: item.title.trim(),
          }

        })
        .sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())))]  : []  }
        placeholder="Conversation Tags"
        value={conversationTag}
        maxTagCount= {1}
        showSearchIcon={true}
        handleChange={(value, option) => {
            let names = [];
            let expectationtype = [];
            if(value === undefined) {
                //getConversationTagsList();
              }
              //will require refactoring once we do id based search
              if(value) {
                if(!Array.isArray(value)) {
                  value = [value];
                }
                value.forEach(parentItem => {
                  filtersSegmentList.conversationTags.forEach(childItem => {
                      if(childItem.id === parseInt(parentItem)) {
                            names.push(childItem.title);
                            expectationtype.push(childItem.expectationtype);
                      }
                  })
                })
              }
              updateConversationTag(value);
              handleChange(names, "", value, expectationtype);
        }}
        showSearch={true}
        hideClear={false}
        disabled = {disabled}
        style = {style}
        loading={fetching}
        {...rest}
    /> :
    <CustomFilterComponent
    width={width || 200}
    options={
      filtersSegmentList.conversationTags?.length > 0
        ? [
            ...new Set(
              filtersSegmentList.conversationTags
                .filter((item) => item.title !== "")
                .map((item) => {
                  return {
                    value: item.id,
                    label: item.title.trim(),
                  };
                })
                .sort((a, b) =>
                  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                )
            ),
          ]
        : []
    }
    placeholder={conversationTagMultiSelect ? "Select Conversation Tags" : "Select Conversation Tag"}
    value={conversationTag}
    maxTagCount={1}
    disabled = {disabled}
    multiSelect={conversationTagMultiSelect}
    handleChange={(value) => {
      let names = [];
      let expectationtype = [];
      //will require refactoring once we do id based search
      if (value) {
        if (!Array.isArray(value)) {
          value = [value];
        }
        value.forEach((parentItem) => {
          filtersSegmentList.conversationTags.forEach((childItem) => {
            if (childItem.id === parseInt(parentItem)) {
              names.push(childItem.title);
              expectationtype.push(childItem.expectationtype);
            }
          });
        });
      }
      updateConversationTag(value);
      handleChange(names, "", value, expectationtype);    
    }}
    {...rest}
  />
  );
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.app_user.selectedWorkspace,
  filtersSegmentList: state.dashboard.filterSegments,
});
export default connect(mapStateToProps)(ConversationTagFilter);
