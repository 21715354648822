import React, { useEffect, useState } from "react";
import { 
    PrimaryStyledButton
} from "./../../../components/common/styled-components";
import styled from "@emotion/styled/macro";
import Properties from "./properties";
import { Collapse, Button } from "antd";
const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
    .collapse-open {
        button {
            width: 108px;
        }
    }
`;

const DetailsWrapper = styled.div`
    min-height: 48px;
    height: auto;
    padding: 0 18px 60px 40px;
    position: relative;
    .property-details {
        
    }
    .property-action {
        position: absolute;
        bottom: 16px;
        right: 16px;
        button {
            width: 108px;
        }
    }
`;

const PropertyDetails = (props) => {
    const { id } = props;
    switch(id) {
        case "scenario":
            return <Properties.ScenarioProperty {...props} />;
        case "expectations":
            return <Properties.ExpectationsProperty {...props} />;
        case "conditions":
            return <Properties.ConditionsProperty {...props} />;
        case "time_restriction":
            return <Properties.TimeRestrictionProperty {...props} />;
        case "actions":
            return <Properties.ActionsProperty {...props} />;
        case "tagType":
            return <Properties.TagTypeProperty {...props} />;
        case "channel":
            return <Properties.ChannelsProperty {...props} />;
        default:
            return null;
    }
}

const getPropertyDetails = (props) => {
    const { id } = props;
    switch(id) {
        case "tagType":
            return {
                type: props.ruleProperties?.tagType,
                label: props.options.find((item) => item.value === props.ruleProperties?.tagType).label
            }; 
        case "expectations":
            return {
                type: props?.ruleProperties?.expectation_type,
                label: props.options.find((item) => item.value === props.ruleProperties?.expectation_type)?.label,                
            };            
        case "time_restriction":
            const time_policy = props.ruleProperties?.time_restriction && props.ruleProperties?.time_restriction !== 999999999 ? "start" : "any";
            let time_restriction = props.ruleProperties?.time_restriction;
            if(time_restriction === 999999999) {
                time_restriction = "";
            }
            return {
                type: time_policy,
                value: time_restriction,
                label: time_policy === "any" ? "Any time" : "Custom Time",
            };
        case "actions":
            const actions = props.ruleProperties?.actions?.map((item) => {
                return item.category
            }) || [];
            let label = "No Hint";
            if(actions.includes("flag") && actions.includes("hint")) {
                label = "Hint & Flag"
            }
            else if(actions.includes("hint")) {
                label = "Hint";            
            }
            else if(actions.includes("flag")) {
                label = "Flag";            
            }
            return {
                label,
                actions: props.ruleProperties?.actions || [],  
                invalid: !props.ruleProperties?.actions?.length,
            };
        case "scenario":
            const expectations = props.ruleProperties?.expectations?.map((item) => {
                return item.category
            }) || [];
            const scenario = props.options.find((item) => expectations.includes(item.value))?.value;
            const scenarioValue = scenario ?  props.ruleProperties?.expectations.find((item) => item.category === scenario)?.value : "";            
            return {
                type: scenario,
                value: scenarioValue,
                speaker: props.speaker,
                invalid: scenarioValue ? false : true,
                label: props.options.find((item) => expectations.includes(item.value))?.label,
            };
        case "conditions":
            const conditions = props.ruleProperties?.conditions?.map((item) => {
                return item.category
            }) || [];
            const condition = props.options.find((item) => conditions.includes(item.value))?.value;
            const conditionValue = condition ?  props.ruleProperties?.conditions.find((item) => item.category === condition)?.value : "";
            return {
                type: condition,
                value: conditionValue,
                speaker: props.speaker,
                invalid: conditionValue ? false : true,
                label: props.options.find((item) => conditions.includes(item.value))?.label,
            };
        case "channel": 
            const channels = props.ruleProperties?.channel?.map((item) => {
              return item;
            }) || [];
            if(channels?.length && channels.length === props?.options?.length) {
              return {
                label: `All Channels`,
                value: channels
            };
            }
            else if(channels?.length && channels.length < props?.options?.length) {
              return {
                label: `${channels.join(', ')}`,
                value: channels
              }
            }
            else {
              return {
                label: ``,
                value: channels
              }
            }
        default:
          return {}
    }
}

const RuleProperty = (props) => {
    const [propertyDetails, setPropertyDetails] =  useState(getPropertyDetails(props));
    const [expand, toggleExpand] = React.useState(props.showDetails);
    const [details, setDetails] = React.useState({ id: props.id, ...propertyDetails });
    const [showValidations, toggleShowValidations] = React.useState(false);
    const handleChange = (details) => {
        setDetails({ id: props.id, ...details });
    }
    const handleNext = () => {
        toggleShowValidations(!details || !details.invalid);
        if(details && !details.invalid) {
            toggleExpand(!expand);
            props.goToNext(details);
        }
    }
    useEffect(() => {
        toggleExpand(props.showDetails);
    }, [props.showDetails])    

    useEffect(() => {
      const _propertyDetails = getPropertyDetails(props);
      setPropertyDetails(_propertyDetails);
      setDetails({ id: props.id, ..._propertyDetails })
    }, [props?.ruleProperties]);
    return (
        <Collapse onChange={() => toggleExpand(!expand)}  activeKey={expand ? ['1']: []}>
            <StyledPanel 
                showArrow 
                key="1"
                header={
                    <div className="collapse-container">
                        <div className="collapse-title">{props.title}</div>
                        <div className="collapse-action">
                            <div className="collapse-answer">{details?.label}</div>
                            <div className="collapse-open">
                                <PrimaryStyledButton type="secondary" onClick={props.onEdit}>Change</PrimaryStyledButton>
                            </div>
                        </div>
                    </div>
                }
            >
                <DetailsWrapper>
                    <div className="property-details">
                        <PropertyDetails {...props} showValidations={showValidations} handleChange={handleChange} />
                    </div>
                    <div className="property-action">
                        <Button type="primary" onClick={handleNext}>Next</Button>
                    </div>
                </DetailsWrapper>
            </StyledPanel>
        </Collapse>
    )
}

export default RuleProperty;
