import React, { Component } from 'react';
import styled from "@emotion/styled/macro";
import { message } from "antd";
import { getWorkspaceUsersList, addUsersToWorkspace } from "../../api/api";
import { StyledInput, StyledTextArea, StyledTable, StyledCheckbox } from "./../../../components/common/styled-components";

const WorkspaceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  .form-heading {
    font-size: 28px;
    line-height: 33px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: #4F4F4F;
    margin-bottom: 18px;
  }
`;

const StyledCheckboxWrapped = styled(StyledCheckbox)`
    top: 1px;
    position: relative;
    display: flex;
    span {
        width: 12px;
        height: 12px;
    }
`;

const UsersList = (props) => {
  const columns = [,
    // {
    //   title: "",
    //   dateIndex: "userId",
    //   key: "userId",
    //   width: "10%",
    //   render: (value, record, index) => <StyledCheckboxWrapped checked={props.selectedUsers.includes(record.userId)} onChange={() => props.handleUserSelection(record)}/>
    // },
    {
      title: "Agent ID",
      dataIndex: 'id',
      key: 'id',
      width: "45%"
    },
    {
      title: 'Agent Name',
      dataIndex: 'user_name',
      key: 'user_name',
      width: "30%"
    },
  ];
  return (
    <StyledTable 
      dataSource={props.usersList} 
      columns={columns} 
      loading={props.isLoading} 
      rowKey="id" 
      scroll={{y: "250px"}}  
      pagination={false} 
    />    
  )
} 

class WorkspaceSetup extends Component {
  state = {
    isLoading: true,
    usersList: [],
    selectedUsers: [],
    users: [],
  };
  updateValues = (field, value) => {
    this.props.updateWorkspaceData({
      ...this.props.workspaceData,
      [field]: value
    });
  }
  getUsers = () => {
    getWorkspaceUsersList(this.props.workspaceData.subdomain).then((res) => {
      let usersList = res.results || [];
      usersList = usersList.map((item)=>{
        item.user_name = `${item.user.first_name}${item.user.last_name ? " " + item.user.last_name : ""}`;
        item.userId = item.user.id;
        if(!item.user_name) {
          item.user_name = item.user.email;
        }
        return item;
      });
      this.setState({
        usersList: usersList,
        selectedUsers: usersList.map((item) => item.userId),
        isLoading: false
      });
    })
  }
  componentDidMount() {
    if(this.props.workspaceData.subdomain) {
      this.getUsers();
    }
  }
  componentDidUpdate(prevProps) {
    if(this.props.workspaceData.subdomain !== prevProps.workspaceData.subdomain) {
      this.setState({
        isLoading: true,
        usersList: [],
      }, () => {
        this.getUsers();
      });
    }
  }
  handleChange = (field, event) => {
    this.updateValues(field, event.target.value);
  }
  handleDropdownState = (type, value) => {
    if(type === "is_active") {
      this.updateValues(type, value === "active" ? true : false);
    }
    else {
      this.updateValues(type, value);
    }    
  }
  handleUserSelection = (user) => {
    let { selectedUsers, users } = this.state;
    const { userId } = user;
    if(selectedUsers.includes(userId)) {
      const selectedUserId = selectedUsers.indexOf(userId);
      selectedUsers = selectedUsers.splice(selectedUserId, 1)     
      users = users.splice(selectedUserId, 1)     
    }
    else {
      selectedUsers.push(userId);
      users.push(user)
    }
    this.setState({ selectedUsers, users }, () => {
      const { name, desc, subdomain, is_active, avatar, id } = this.props.workspaceData;
      if(id) {
        const data = {
          name,
          subdomain,
          desc,
          is_active,
          avatar,
          users,
        };
        addUsersToWorkspace(data).then((res) => message.success("User added to workspace"));
      }
    });
  }
  render() {
    const { workspaceData } = this.props;
    const { name, desc, id } = workspaceData;
    return (
      <WorkspaceDetailsWrapper>
        <div className="form-heading">New Workspace</div>
        <StyledInput
          placeholder="Workspace Name"
          onChange={this.handleChange.bind(this, 'name')}
          defaultValue=""
          value={name}
        />
        <StyledTextArea
          placeholder="Workspace Description"
          onChange={this.handleChange.bind(this, 'desc')}  
          defaultValue=""
          value={desc}
        />
        {id ? <UsersList usersList={this.state.usersList} selectedUsers={this.state.selectedUsers} isLoading={this.state.isLoading} handleUserSelection={this.handleUserSelection} /> : null}
      </WorkspaceDetailsWrapper>
    )
  }
}

export default WorkspaceSetup;
