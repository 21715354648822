import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Menu, Dropdown } from "antd";

const MenuItemWrapper = styled(Menu.Item)`
    display: flex;
    align-items: center;
    svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;    
    }
    &:hover {
        svg {
            path {
                fill:  ${props => props.hoverColor ? props.hoverColor : "#6099D9"};
                transition: fill 0.2s ease;
            }
        }
    }
`;

const MenuIconWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer; 
`;

const MenuWrapper = styled(Menu)`
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
    border-radius: 3px;
    width: 175px;
`;

export const UserMenu = (props) => {
    const [showMenu, toggleMenu] = useState(false);
    const onVisibleChange = (flag) => {
        toggleMenu(flag);
    }
    const handleMenuClick = (e) => {
        toggleMenu(false);
        props.handleMenuItem(e.key);  
    }
    return (
        <Dropdown 
            overlay={
                <MenuWrapper selectable={false} onClick={handleMenuClick}>
                    {props.menuItems.map(({title, icon: Icon, key, hoverColor, access}) => {
                        if(!access || access(props.user)) {
                            return (
                                <MenuItemWrapper hoverColor={hoverColor} key={key}>
                                    {Icon ? <Icon /> : null} {title}
                                </MenuItemWrapper>
                            )
                        }
                    })}
                </MenuWrapper> 
            } 
            onVisibleChange={onVisibleChange}
            visible={showMenu}
            trigger={['hover']}
        >
            <MenuIconWrapper>{props.children}</MenuIconWrapper>
        </Dropdown>
    )
}

export default UserMenu;