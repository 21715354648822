import styled from "@emotion/styled/macro";
import { Button, Input } from "antd";

export const ModalBodyWrapper = styled.div`
  width: 100%;
  padding: 25px;
`;

export const ModalBodyHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #4f4f4f;
`;

export const ModalBodyText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 8px 0;
  color: #bdbdbd;
`;

export const ModalEmailFields = styled.div`
  max-height: 230px;
  overflow-y: scroll;
`;

export const StylePhraseInputWrapper = styled.div`
  width: calc(100% - 42px);
  position: relative;
`;

export const StyledPhraseInput = styled(Input)`
  width: 100%;
  padding: 4px 10px;
  border: 1px solid;
  border-color: #bdbdbd;
  position: relative;
  &.ant-input[disabled] {
    color: #4f4f4f;
  }
`;

export const ModalBulkImportContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 579px;

  .modal-bulk-import-text-area {
    width: 100%;
    padding-left: 10px;
    margin-top: 2px;
    margin-left: -5px;
  }
`;
export const ModalBulkImportText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
`;

export const ModalBulkImportButton = styled(Button)`
  float: right;
  margin: 10px 25px 25px 0px;
`;

export const ModalCollapsiblePanel = styled.div`
  .ant-collapse {
    background-color: white;
    border: none;
    border-bottom: 0;
    border-radius: 0;
    margin-left: -14px;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #4f4f4f;
    max-width: 200px;
  }
  .ant-collapse-content {
    border-top: none;
    margin: -10px 0px 0px 18px;
  }
`;
