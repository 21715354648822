import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Radio } from 'antd';
import InputPhrases from "./InputPhrases";
import SelectIntent from "./SelectIntent";

const ConditionsPropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const ConditionsProperty = (props) => {
    const getInitialValue = (ruleProperties) => {
        let condition = "";
        let value = "";
        let phrase = "";
        let topic = "";
        const options = props.options.map((item) => item.value);
        if(ruleProperties?.conditions) {
            for(let i=0; i<ruleProperties.conditions.length; i++) {
                const category = ruleProperties.conditions[i].category;
                if(options.includes(category)) {
                    condition = category;
                    value = ruleProperties.conditions[i].value;
                    if(condition === "phrase") {
                        phrase = value;
                    }                  
                    else {
                        topic = value;
                    }  
                }
            }            
        }
        return { condition, phrase, topic };
    }
    const initialValues = getInitialValue(props.ruleProperties);
    const [condition, setCondition] = React.useState(initialValues.condition);
    const [showValidations, toggleShowValidations] = React.useState(false);
    const [invalidPropsList, setInvalidPropsList] = React.useState([]);
    const handleChange = (event) => {
        const value = event.target.value;
        setCondition(value);
        setInvalidPropsList([value]);
    }
    const onPhraseChange = (value) => {
        props.handleChange({
            type: condition,
            label: props.options.find((item) => item.value === condition).label,
            value: value,
            speaker: props.speaker,
            invalid: value ? false : true,
        })
        if(value) {
            setInvalidPropsList([]);
        }
    }
    const onIntentSelect =  (value) => {
        props.handleChange({
            type: condition,
            label: props.options.find((item) => item.value === condition).label,
            value: value,
            speaker: props.speaker,
            invalid: value ? false : true,
        })
        if(value) {
            setInvalidPropsList([]);
        }
    }
    const phrases = initialValues.phrase ? initialValues.phrase.split(",").map((text, index) => {
        return {    
            id: `${index}`,
            text: text,
        }
    }) : [];
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])
    return (
        <ConditionsPropertyWrapper>
            <Radio.Group className="radio-group" name="radiogroup" defaultValue={condition} onChange={handleChange}>
                {props.options.map((item) => {
                    return <Radio value={item.value}>{item.label}</Radio>
                })}                               
            </Radio.Group>
            {condition === "phrase" ? <InputPhrases showValidations={showValidations} phrases={phrases} onChange={onPhraseChange} /> : null}
            {condition === "topic" ? <SelectIntent showValidations={showValidations}  value={initialValues.topic} name="condition_value" onChange={onIntentSelect}/> : null}            
            {showValidations && !condition && <div className="error-message">Please choose an option</div>}
        </ConditionsPropertyWrapper>
    )
}

export default ConditionsProperty;