import React from 'react';
import { Select } from 'antd';
import { useSelector } from "../../store";

const AgentsTypeFilter = ({ value,handleChange }) => {
  const options = useSelector(
    (state) => state.dashboard.agentTypeFilter
  );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const { Option } = Select

  return (
    <Select value={value} onChange={(val) => handleChange(val)} placeholder="All Agent Type Option">
      {
        options?.length > 0 &&
          options.map((option, index) => (
            <Option key={index} value={option}>{capitalizeFirstLetter(option)}</Option>
          ))
      }
    </Select>
  )
};

export default AgentsTypeFilter
