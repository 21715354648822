import React, { useState, useEffect } from "react";
//import DynamicChartRenderer from '../dynamic-chart';
import { ChartWrapper, ChartListWrapper } from "../components/wrapper";
//import Constants from "../../CompanySetup/utils/constants";
import appConfigs from "../../../src/config";
//import { getSchemaName } from "../../api/api";
import { Chart } from "../chart";
import { ConversationChart, HandlingTimeChart, QAScoreChart, QAEvaluationsChart } from '../dashboard-charts';
import { queryOptions } from '../dynamic-query-builder';

const TeamDashboard = (props) => {
  
  return (
    <ChartListWrapper>
      {props.showOrgChart && (
        <ChartWrapper>
          <Chart
            query={{
              measure: queryOptions.measureOptions.CONVERSATION_COUNT,
              groupBy: queryOptions.groupByOptions.ORGANIZATION,
              isTimeSeries: false,
              // stackBy: queryOptions.groupByOptions.IS_FLAGGED,
              // isStack: true,
              // showPercentOfFilter: true
            }}
            title={"Interactions by Organization"}
            isChartTypeEditable={false}
            filterBy={queryOptions.filterByOptions.NONE}
            chartType="interval"
            drilldownQueries={[]}
            //drilldownBy = {queryOptions.drilldownByOptions.ORGANIZATION}
          ></Chart>
        </ChartWrapper>
      )}

      <ChartWrapper>
        <ConversationChart {...props} />
      </ChartWrapper>
      <ChartWrapper>
        <HandlingTimeChart {...props} />
      </ChartWrapper>
      <ChartWrapper>
        <QAScoreChart {...props} />
      </ChartWrapper>
      <ChartWrapper>
        <QAEvaluationsChart {...props} />
      </ChartWrapper>
    </ChartListWrapper>
  );
};

export const Dashboard = (props) => {
  const showOrgChart = appConfigs.SHOW_ORGANIZATION_FILTER;

  return (
    <>
      <TeamDashboard
        showOrgChart={showOrgChart}
      />
    </>
  );
};