import React, { Component } from 'react';
import ConversationTagsList from "./conversation-tags-list";
import ConversationTagDetails from "./conversation-tags-details";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";

export class ConversationTags extends Component {
    render() {
        return (
                <Switch>
                    <Route exact path={`/setup/conversation-tags/`} render={(props) => <ConversationTagsList {...props} />} />
                    <Route exact path={`/setup/conversation-tags/create`} render={(props) => <ConversationTagDetails {...props} />} />
                    <Route exact path={`/setup/conversation-tags/:id`} render={(props) => <ConversationTagDetails {...props} />} />
                </Switch>        
        )
    }
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps, null)(withRouter(ConversationTags));
