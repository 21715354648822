const Actions = {
    SET_TITLE: "SET_TITLE",
    SET_TAGTYPE: "SET_TAGTYPE",
    SET_DESCRIPTION: "SET_DESCRIPTION",    
    SET_ERRORS: "SET_ERRORS",
}

export const popupActions = {
    setTitle: (value, dispatch) => {
        dispatch({
            type: Actions.SET_TITLE,
            payload: value
        });
    },
    setTagType: (value, dispatch) => {
        dispatch({
            type: Actions.SET_TAGTYPE,
            payload: value
        });
    },
    setDescription: (value, dispatch) => {
        dispatch({
            type: Actions.SET_DESCRIPTION,
            payload: value
        });
    }, 
    setErrors: (errors, dispatch) => {
        dispatch({
            type: Actions.SET_ERRORS,
            payload: errors
        });
    }   
}

export const popupReducer = (state, action) => {
    const errors = [...state.errors];
    if(action.type === Actions.SET_TITLE) {
        if(action.payload && errors.includes("title")) {
            errors.splice(errors.indexOf("title"), 1);
        }
        return {
            ...state,
            errors,
            title: action.payload,
            modified: true,            
        };
    }
    else if(action.type === Actions.SET_DESCRIPTION) {
        if(action.payload && errors.includes("description")) {
            errors.splice(errors.indexOf("description"), 1);
        }
        return {
            ...state,
            errors,
            description: action.payload,
            modified: true,
        };
    }
    else if(action.type === Actions.SET_TAGTYPE) {
        if(action.payload && errors.includes("tagType")) {
            errors.splice(errors.indexOf("tagType"), 1);
        }
        return {
            ...state,
            errors,
            tagType: action.payload,
            modified: true,
        };
    }
    else if(action.type === Actions.SET_ERRORS) {
        return {
            ...state,
            errors: action.payload,
            modified: true,
        }
    }
}

export const getInitialState = (ruleData) => {
    return {
        title: ruleData.title || "",
        description: ruleData.description || "",
        tagType: ruleData.tagType || "",
        modified: false,  
        errors: [],      
    }
}
