import React from "react";
import ConditionNodeInput from "../components/ConditionNodeInput";
import GenericNode from "./genericNode";
import { StyledNodeHead } from "./styles";
import { ReactComponent as ConditionIcon } from "../../assets/condition.svg";
import useStore from "../store";

const ConditionNode = ({ data }) => {
  const selectedNode = useStore((state) => state.selectedNode);
  return (
    <GenericNode nodeData={data} nodeType="condition" noInput={true}>
      <StyledNodeHead>
        <div className="condition">
          <ConditionIcon />
        </div>
        {data.display_name}
      </StyledNodeHead>
      {selectedNode?.id === data.id && (
        <div
          className="p-3"
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ConditionNodeInput data={data} nodeId={data.id} />
        </div>
      )}
    </GenericNode>
  );
};

export default React.memo(ConditionNode);
