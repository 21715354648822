import React, { useState } from 'react'
import { Button } from 'antd'
import styled from "@emotion/styled/macro";

const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;    
    button.submit, button.verify {
        margin-left: 4px;
    }
    button.verify {
        background: #27AE60;
        color: white;
        border: none;
    }    
`;


const ActionButtons = ({ onSubmit, onCancel, onVerify, is_superuser, isLoading = false }) => {
  const [buttonType, setButtonType] = useState("");
  return (
    <ActionButtonsWrapper>
      <Button disabled = {isLoading} onClick={onCancel}>Cancel</Button>        
      <Button loading = {buttonType === "submit" ? isLoading : false} disabled = {buttonType === "verify" ? isLoading : false} className="submit" type="primary" onClick={() => {
        setButtonType("submit");
        onSubmit();
        }}>Submit</Button>
      {is_superuser ? <Button loading = {buttonType === "verify" ? isLoading : false} disabled = {buttonType === "submit" ? isLoading : false} style={{float: "right"}} className="verify" onClick={() => { 
        setButtonType("verify");
        onVerify() 
        }}>Save & Verify</Button> : null}
    </ActionButtonsWrapper>
  )
}

export default ActionButtons;
