import React,{Suspense} from 'react';
import {connect} from 'react-redux';
import cookie from "react-cookies";
import PrivateRoute from '../PrivateRoute';
import GlobalSpinner from '../../components/common/GlobalSpinner'
import { Redirect } from "react-router-dom";
import { mapStateToProps,mapDispatchToProps } from '../utilities';
import {ErrorBoundary} from 'react-error-boundary';
import { DashboardWrapper, SpinnerWrapper } from '../styles';
import {protectedRoutes} from './data';
import Header from '../../components/common/Header';
import NavigationBar from '../../components/NavigationBar';
import { Switch } from 'react-router';
import ErrorFallback from '../../components/ErrorFallback';

export const ProtectedRouteWrapper = connect(mapStateToProps, mapDispatchToProps)(({ component: Component, ...rest }) => {
  return (
    <PrivateRoute {...rest} 
        render={(props) => {
            return (
              <div className="component-layout">
                <Header {...rest} {...props} />
                <Component {...props}/>
              </div>
            )
        }}
    />
  )
});

function index(props) {
  const _cookies = cookie.loadAll();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DashboardWrapper marginTop = {_cookies?.talkdesk_widget_url ? "30px" : "0px"}>                      
      <NavigationBar {...props}/>
      <Suspense fallback={<SpinnerWrapper><GlobalSpinner/></SpinnerWrapper>}>
        <Switch>
          {protectedRoutes.map( route => <ProtectedRouteWrapper {...route}/>)}
          <Redirect to="/404"/>
        </Switch>
      </Suspense>
      </DashboardWrapper>
    </ErrorBoundary>
 
  );
}

export default index;
