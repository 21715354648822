import React from 'react';
import {
  ConverationTagBuildConfigurations,
} from "../../conversation-tags/config";
import styled from "@emotion/styled/macro";
import { CONVERSATION_TAG, METRIC_TAG } from './constants'

const PopoverBoxWrapper = styled.div`
  color: black;

  .qa {
    margin: 1rem 0;
    color: #828282;
    font-size: 0.8rem;
    max-width: 30vw;

    .qa-question {
      font-weight: 400;
    }
    .qa-answer {
      font-weight: 500;
    }
  }

  .open-in-settings {
    color: #6099d9;
    font-weight: 400;
    font-size: 0.8rem;
  }
`;

const getMetricDetails = question => {
  const { metric_tag } = question;
  const { title, is_active_description, conditions } = metric_tag;
  const { threshold, unit } = conditions[0];
  var units = unit.toLowerCase()
  if (threshold > 1) {
     units = unit.toLowerCase() + 's';
  }
  const result = [
    {
      title: `Would you like to tag conversations with ${title}? `,
      answer: metric_tag.is_active ? "Yes" : "No",
    },
    {
      title: is_active_description,
      answer: `${threshold} ${units}`,
    },
  ];

  return result;
}

const getConversationDetails = question => {
  const { tagType } = question.conversation_tag.rule_json;
  const tag = ConverationTagBuildConfigurations.find(
    (item) => item.id === tagType
  );
  const result = [
    {
      title: "What type of tag is this?",
      answer: tag.label
    },
  ];

  let label = "";

  // Conditions
  // This category only exists for the dynamic tag type
  if(tagType === "dynamic"){
    // Pick the 0th condition since 1st condition will be same for all.
    // In case category is topic, use it as it is.
    // In case category is phrase, break it with commas.
    const conditionsItem = tag.properties.find(item => item.id === "conditions");
    const condition = question.conversation_tag.rule_json.conditions[0];
    const option = conditionsItem.options.find(opt => opt.value === condition.category);
    if(condition.category === "phrase") {
      label = {
        description: option.label,
        list: condition.value.split(',')
      }
    }
    else {
      label = option.label + " - " + condition.value
    }
    result.push({
      title: conditionsItem.title,
      answer: label
    })
  }

  // Expectations
  const expectationsItem = tag.properties.find(item => item.id === "expectations")
  const expectationType = question.conversation_tag.rule_json.expectation_type;
  let option = expectationsItem.options.find(opt => opt.value === expectationType);
  result.push({
    title: expectationsItem.title,
    answer: option?.label
  })

  // Scenarios
  // Pick the 0th expectation and map it with the scenario config
  // if phrase, break it with commas
  // if topic, render as it is.
  const expectation = question.conversation_tag.rule_json.expectations[0]
  const scenarioItem = tag.properties.find(item => item.id === "scenario")
  option = scenarioItem.options.find(opt => opt.value === expectation.category);
  if (expectation.category === "phrase") {
    label = {
      description: option.label,
      list: option.value.split(","),
    };
  } else {
    label = option.label + " - " + expectation.value;
  }
  result.push({
    title: scenarioItem.title,
    answer: label,
  });


  // Time restriction
  const timeRestictionItem = tag.properties.find(
    (item) => item.id === "time_restriction"
  );
  const timeRestriction =
    question.conversation_tag.rule_json.time_restriction;
  const time_policy = question.conversation_tag.rule_json.time_policy;
  label = time_policy === "any" ? "Any time" : "Custom Time";
  result.push({
    title: timeRestictionItem.title,
    answer: label === "Any time" ? label : label + " - " + timeRestriction
  });


  // Actions
  // Actions only exist if expectationType is not neutral
  if(expectationType !== "neutral") {
    const actionsItem = tag.properties.find((item) => item.id === "actions");
    const actions = question.conversation_tag.rule_json.actions;
    const list = actions.map((action) => {
        const { category } = action;
        const option = actionsItem.options.find((opt) => opt.value === category);
        const label = option.label + " - " + action.value;
        return label;
    })
    const answer = {
      list,
      description: null
    }
    result.push({
      title: actionsItem.title,
      answer,
    });
  }
  
  return result;
}

const PopoverBox = ({ question }) => {
  const { question_type } = question;
  let details = [];
  
  switch(question_type) {
    case CONVERSATION_TAG:
      details = getConversationDetails(question); 
      break;
    case METRIC_TAG:
      details = getMetricDetails(question);
      break;
    default:
      details = []
  }

  const generateUrl = () => {
    return question_type === CONVERSATION_TAG ? `${window.location.origin}/setup/conversation-tags/${question?.conversation_tag?.id || ''}` : question_type === METRIC_TAG ? `${window.location.origin}/setup/metric-tag` : ``;
  };

    return (
      <PopoverBoxWrapper>
        {details.map((property) => (
          <div className="qa" key={property.title}>
            <div className="qa-question">{property.title}</div>
            <div className="qa-answer">
              {typeof property.answer === "string" ? (
                property.answer
              ) : (
                <>
                  {property.answer.description}
                  <ul>
                    {property.answer.list.map((option) => (
                      <li key={option}>{option}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        ))}

        {/* TODO: Change this link */}
        <a href={generateUrl()} target="_blank" className="open-in-settings">
          Open in settings
        </a>
      </PopoverBoxWrapper>
    );
};

export default PopoverBox;
