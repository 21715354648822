import { TIMELINE_TYPES } from "./Constants";
import { ReactComponent as AllIcon } from "./assets/all-icon.svg";
// import { ReactComponent as FlagIcon } from "./assets/flag-icon.svg";
//import { ReactComponent as KnowledgeIcon } from "./assets/resource-icon.svg";
// import { ReactComponent as HintIcon } from "./assets/hint-icon.svg";
// import { ReactComponent as FaqIcon } from "./assets/faq-icon.svg";

const DashboardFilterCategoryMap = {
    [TIMELINE_TYPES.HINT]: [ TIMELINE_TYPES.HINT ],
    [TIMELINE_TYPES.FLAG]: [ TIMELINE_TYPES.FLAG, TIMELINE_TYPES.WARNING ],
    [TIMELINE_TYPES.KNOWLEDGE]: [ TIMELINE_TYPES.KNOWLEDGE, TIMELINE_TYPES.RESOURCE ],
    [TIMELINE_TYPES.FAQ]: [ TIMELINE_TYPES.FAQ ],
    "ALL": [ TIMELINE_TYPES.FAQ, TIMELINE_TYPES.HINT, TIMELINE_TYPES.FLAG, TIMELINE_TYPES.WARNING, TIMELINE_TYPES.KNOWLEDGE ]
}

const AgentFilterCategoryMap = {
    "ALL": [ TIMELINE_TYPES.FAQ, TIMELINE_TYPES.HINT, TIMELINE_TYPES.FLAG, TIMELINE_TYPES.WARNING ],
    [TIMELINE_TYPES.KNOWLEDGE]: [ TIMELINE_TYPES.KNOWLEDGE, TIMELINE_TYPES.RESOURCE ],
}



const AgentFiltersList = [
    {
        filter: "ALL",
        title: "Action feed",
        icon: AllIcon
    },
    // {
    //     filter: TIMELINE_TYPES.HINT,
    //     icon: HintIcon
    // },
    // {
    //     filter: TIMELINE_TYPES.KNOWLEDGE,
    //     title: "Knowledge search",
    //     icon: KnowledgeIcon
    // },
    // {
    //     filter: TIMELINE_TYPES.FAQ,
    //     icon: FaqIcon
    // },
    // {
    //     filter: TIMELINE_TYPES.FLAG,
    //     icon: FlagIcon
    // },    
];

const GroupFiltersList = [
    {
        filter: "Main Feed",
        id: "ALL",
        categories: [ TIMELINE_TYPES.FAQ, TIMELINE_TYPES.HINT, TIMELINE_TYPES.FLAG, TIMELINE_TYPES.WARNING ]
    },
    {
        filter: "Resources",
        id: TIMELINE_TYPES.KNOWLEDGE,
        icon: [ TIMELINE_TYPES.KNOWLEDGE, TIMELINE_TYPES.RESOURCE ]
    }
];

const DashboardFiltersList = [
    {
        filter: TIMELINE_TYPES.HINT,
        title: "Assists"
    },
    {
        filter: TIMELINE_TYPES.FLAG,
        title: "Flags"
    },
    {
        filter: TIMELINE_TYPES.KNOWLEDGE,
        title: "Resources"
    },
    {
        filter: TIMELINE_TYPES.FAQ,
        title: "Faq"
    }    
]

export {
    AgentFilterCategoryMap,
    DashboardFilterCategoryMap,
    AgentFiltersList,
    DashboardFiltersList,
    GroupFiltersList
};