import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { axiosInstance, BASE_URL, getCategories, getSchemaName, getToken } from "../../api/api";
import DateFilter from "../../components/common/DateFilter";
import SearchComponent from "../../components/common/SearchComponent";
import { FilterComponentWrapper } from "../../conversation-list/wrappers";
import AgentsFilter from "../../components/common/AgentsFilter";
import FilterComponent from "../../components/common/FilterComponent";
import CheckMarkFilter from "./components/CheckmarkFilter";
import OrganizationFilter from "../../components/common/OrganizationFilter";
import QaStatusFilter from "../../components/common/QaStatusFilter";
import StatusFilter from "../../components/common/StatusFilter";
import TopicsFilter from "../../components/common/TopicsFilter";
import TimeGranularityFilter from "../../components/common/TimeGranularityFilter";
import MetricTagsFilter from "../../components/common/MetricTagsFilter";
import { StyledCheckbox } from "../../components/common/styled-components";
import InstaReviewFilter from "../../components/common/InstaReviewFilter";
import { channelOptions, instaReviewtopics, timeGranularityOptions } from "../../conversation-list/utilities";
import { hasManagerRights, hasQAAuditorRights } from "../../AnalyticsDashboard/config";
import ModeratorFilter from "../../components/common/ModeratorFilter";
import CalibrationStatusFilter from "../../components/common/CalibrationStatusFilter";
import SliderFilter from "../../components/common/SliderFilter";
import CustomFilters from "../../components/common/CustomFilters";
import ConversationTagFilter from "../../components/common/ConversationTagFilter";
import { getCallDuration, getNegativeSentiment } from "../../conversation-list/api";
import { EvaluationStatus } from "../../conversation-list/configs";
import { dateOptions } from "../configs";
import Constants from "../../CompanySetup/utils/constants";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import appConfigs from "../../config";
import CustomFilterComponent from "../../components/common/CustomFilterComponent/CustomFilterComponent";
import { UserRoles } from "../../CompanySetup/components/user-management/config";
import QaAuditorFilter from "../../components/common/QaAuditorFilter";
// import Constants from "";

export const useFilterConfig = (filters, handleChange, filtersSegmentList, { multiSelectKeys, showCustomFilters }) => {
  const user= useSelector(state => state.app_user);
  const dispatch = useDispatch()
  const customFilters = useSelector((state) => state.dashboard.customFilters);

  const renderCustomFilters = () => {
      if(customFilters?.length) {
        return customFilters.map((item) => {
          return {
            key: item.field_name,
            value: filters[item.field_name],
            customFilterMultiSelect:
              multiSelectKeys.customFilterMultiSelect || false,
            multiSelect: multiSelectKeys.customFilterMultiSelect || false,
            handleChange: (value) => {
              handleChange(item.field_name, value);
            },
            options: item.options?.map((option) => {
              return {
                label: option?.toString(),
                value: option?.toString(),
              };
            }).sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
            placeholder: item.field_name,
            show: (isIncluded = true) => isIncluded,
            isIncluded: true,
            name: item?.pretty_name,
            custom: true,
            component: (props) => {
              return (
                <FilterComponentWrapper key={props.key}>
                  <CustomFilters {...props} />
                </FilterComponentWrapper>
              );
            },
            additionalFilterConfig: {
              component: (props) => <CheckMarkFilter {...props}/>,
              onChange: (value, key, callback) => {
                callback(key, value);
              }
            }
          };
        })
      }
      else {
        return [];
      }
  };
  const hideFollowed = useSelector((state) => state.dashboard.hideFollowed);

  const filterList = [
    {
      //only configured for default view
      key: "date",
      value: filters.date,
      multiSelect: false,
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Dates",
      options: dateOptions,
      handleChange: (value) => {
        handleChange("date", value);
      },
      component: (props) => {
        return <DateFilter {...props} />;
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      //only configured for default view
      key: "searchValue",
      value: filters.searchValue,
      multiSelect: false,
      placeholder: "Convo ID.",
      value: filters.searchValue,
      width: 160,
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Conversation ID",
      options: [],
      handleSearchValue: (value) => {
        handleChange("searchValue", value);
      },
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <SearchComponent {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => null,
        // onChange: (value, key, callback) => {
        //   callback(key, value);
        // }
      }
    },
    {
      key: "user",
      value: filters.user,
      agentMultiSelect: multiSelectKeys.agentMultiSelect || false,
      multiSelect: multiSelectKeys.agentMultiSelect || false,
      show: (isIncluded = false) =>
        isIncluded && (hasManagerRights() || hasQAAuditorRights()),
      isIncluded: false,
      name: "Agents",
      options:  filtersSegmentList?.globalUserRoleList
      ?.filter((item) => item.first_name !== "" && (item.role.includes(UserRoles.Agent)))?.map((user) => {
          return {
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          };
        }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      handleChange: (value) => {
        handleChange("user", value);
      },
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <AgentsFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "channel",
      value: filters.channel,
      channelMultiSelect: multiSelectKeys.channelMultiSelect || false,
      multiSelect: multiSelectKeys.channelMultiSelect || false,
      placeholder: "All Channels",
      width: 160,
      showSearchIcon: true,
      showSearch: true,
      hideClear: false,
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Channels",
      options: channelOptions,
      handleChange: (value) => {
        handleChange("channel", value);
      },
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            {
              !props.multiSelect ? <FilterComponent multiSelect = {false} {...props} /> : <CustomFilterComponent {...props} />
            }
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "organization",
      value: filters.organization,
      organizationMultiSelect: multiSelectKeys.organizationMultiSelect || false,
      multiSelect: multiSelectKeys.organizationMultiSelect || false,
      show: (isIncluded = false) =>
        isIncluded &&
        (getSchemaName() == "infocision" ||
          getSchemaName() == "level" ||
          getSchemaName() == "bpo"),
      handleChange: (value) => {
        handleChange("organization", value);
      },
      isIncluded: false,
      name: "Organizations",
      options:
        filtersSegmentList?.organizations
          ?.filter((item) => item.name !== "")
          .map((organization) => {
            return {
              label: organization.name.trim(),
              value: organization.name.trim(),
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <OrganizationFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "qa_status",
      value: filters.qa_status,
      qaStatusMultiSelect: multiSelectKeys.qaStatusMultiSelect || false,
      multiSelect: multiSelectKeys.qaStatusMultiSelect || false,
      handleChange: (value) => {
        handleChange("qa_status", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "QA Statuses",
      options: async () => {
        return new Promise (async (resolve,reject) => {
          let qaStatus = filtersSegmentList?.qaStatusList;
          if(!qaStatus) {
            try {
              let res = await axiosInstance({
                method: "GET",
                url: `/qa/status_list`,
              })
              qaStatus = res.data
            } catch(e) {
              qaStatus = []
            }
            const filterKey = Constants.FILTER_NAMES.QA_STATUS;
            dispatch({
              type: ReduxActionTypes.CACHE_FILTER_DATA,
              payload: { [filterKey]: qaStatus },
            });
          }
          const qaStatusFormated = qaStatus.map((item) => {
            return {
              label: EvaluationStatus[item.name].label,
              value: `${item.id}`,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) 
          resolve(qaStatusFormated)
        })
      },
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <QaStatusFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "case_status",
      value: filters.case_status,
      caseStatusMultiSelect: multiSelectKeys.caseStatusMultiSelect || false,
      multiSelect: multiSelectKeys.caseStatusMultiSelect || false,
      handleChange: (value) => {
        handleChange("case_status", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Case Statuses",
      options:
        filtersSegmentList?.caseStatusList
          ?.filter((item) => item.status !== "")
          .map((item) => {
            return {
              label: item.status.trim(),
              value: item.status.trim(),
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return(
          <FilterComponentWrapper key={props.key}>
            <StatusFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "category",
      value: filters.category,
      categoryMultiSelect: multiSelectKeys.categoryMultiSelect || false,
      multiSelect: multiSelectKeys.categoryMultiSelect || false,
      handleChange: (value) => {
        handleChange("category", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Categories",
      options: async () => {
        return getCategories()
      },
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <TopicsFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "timeGranularity",
      value: filters.timeGranularity,
      timeGranularityMultiSelect:
        multiSelectKeys.timeGranularityMultiSelect || false,
      multiSelect: multiSelectKeys.timeGranularityMultiSelect || false,
      handleChange: (value) => {
        handleChange("timeGranularity", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Time Granularity",
      options: timeGranularityOptions,
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <TimeGranularityFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "metric_rule",
      value: filters.metric_rule,
      metricMultiSelect: multiSelectKeys.metricMultiSelect || false,
      multiSelect: multiSelectKeys.metricMultiSelect || false,
      handleChange: (value) => {
        handleChange("metric_rule", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Metric Tags",
      options:
        filtersSegmentList?.metricTags
          ?.filter((item) => item.title !== "")
          .map((item) => {
            return {
              label: item.title.trim(),
              value: item.title.trim(),
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <MetricTagsFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "conversation_tag",
      value: filters.conversation_tag_id,
      conversationTagMultiSelect: multiSelectKeys.conversationTagMultiSelect || false,
      multiSelect: multiSelectKeys.conversationTagMultiSelect || false,
      handleChange: (value, label, idValues, expectationtype) => {
        handleChange(
          "conversation_tag",
          value,
          label,
          idValues,
          expectationtype
        );
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Conversation Tags",
      options:
        filtersSegmentList?.conversationTags
          ?.filter((item) => item.title !== "")
          .map((item) => {
            return {
              label: item.title.trim(),
              value: item.id,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <ConversationTagFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      //only configured for default view
      key: "followed",
      value: filters.followed,
      multiSelect: false,
      //checked: filters.followed === "Followed",
      handleChange: (event) => {
        handleChange("followed", event);
      },
      show: (isIncluded = false) => isIncluded && !hideFollowed,
      isIncluded: false,
      name: "Followed/Not Followed",
      options: [{
        label: "Not Followed",
        value: "Not Followed"
      }, {
        label: "Followed",
        value: "Followed"
      }],
      component: (props) => {
        return (
            <CheckMarkFilter groupDirection={"flex-row"} componentStyle={{overflowY:"clip"}} radioBoxStyle={{margin:"15px 4px -5px 4px"}} onChange={props?.handleChange} {...props} />
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
  },
    {
      key: "insta_review_topics",
      value: filters.insta_review_topics,
      instaReviewMultiSelect: multiSelectKeys.instaReviewMultiSelect || false,
      multiSelect:  multiSelectKeys.instaReviewMultiSelect || false,
      handleChange: (value) => {
        handleChange("insta_review_topics", value);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Insta Review",
      options: instaReviewtopics || [],
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <InstaReviewFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "tagged_for_qa",
      value: filters.tagged_for_qa,
      multiSelect: true,
      checked: filters.tagged_for_qa === true || filters.tagged_for_qa === "true",
      handleChange: (event) => {
        const value = event.target.checked;
        handleChange("tagged_for_qa", value ? value : undefined);
      },
      show: (isIncluded = false) => isIncluded && (hasManagerRights() || hasQAAuditorRights()),
      isIncluded: false,
      name: "Tagged for QA",
      options: [
        {
          label: "Tagged for QA",
          value: true,
        },
      ],
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <StyledCheckbox {...props}>Tagged For Rubric</StyledCheckbox>
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value?.length ? value[0] : []);
        }
      }
    },
    {
      key: "moderator",
      value: filters.moderator,
      multiSelect: multiSelectKeys.moderatorMultiSelect || false,
      handleChange: (value, label) => {
        handleChange("moderator", value, label);
      },
      show: (isIncluded = false) => isIncluded && user.is_superuser,
      isIncluded: false,
      name: "Moderators",
      options:
        filtersSegmentList?.globalUserRoleList
          ?.filter((item) => item.first_name !== "" && (item.role.includes(UserRoles.Admin) || item.role.includes(UserRoles.Manager)))
          .map((item) => {
            return {
              label: item.first_name.concat(" " + item.last_name),
              value: item.id,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return(
          <FilterComponentWrapper key={props.key}>
            <ModeratorFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "calibration_status",
      value: filters.calibration_status,
      multiSelect: multiSelectKeys.calibrationStatusMultiSelect || false,
      handleChange: (value, label) => {
        handleChange("calibration_status", value, label);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Calibration statuses",
      options: async () => {
        return new Promise(async (resolve,reject) => {
          let calStatus = filtersSegmentList?.calibrationStatusList;
          if(!calStatus) {
            try {
              let res = await axiosInstance({
                method: "GET",
                url: `/qa_calibration/status_list`,
              })
              calStatus = res.data
            } catch(e) {
              calStatus = []
            }
            const filterKey = Constants.FILTER_NAMES.CALIBRATION_STATUS;
            dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: calStatus }});
          }

          let calStatusFormated = calStatus.map((item) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
          resolve(calStatusFormated)
        })  
      },
        
      component: (props) => {
        return (
          <FilterComponentWrapper key={props.key}>
            <CalibrationStatusFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "call_duration",
      value: filters.call_duration,
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Call Duration",
      additionalFilterConfig: {
        component: (props) => <SliderFilter sliderStyle={{color: "#6099D9", marginTop: "10px", marginLeft: "15px"}} textFieldStyle={{ width: "125px" }} showTextField apiFunction={getCallDuration} {...props}/>,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "negative_sentiment",
      value: filters.negative_sentiment,
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Negative Sentiment",
      additionalFilterConfig: {
        component: (props) => <SliderFilter sliderStyle={{color: "#6099D9", marginTop: "10px", marginLeft: "15px"}} textFieldStyle={{ width: "125px" }} showTextField apiFunction={getNegativeSentiment} {...props}/>,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "qa_auditor",
      value: filters.qa_auditor,
      multiSelect: multiSelectKeys.qaAuditorMultiSelect || false,
      handleChange: (value, label) => {
        handleChange("qa_auditor", value, label);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Qa Auditors",
      options:
        filtersSegmentList?.globalUserRoleList
          ?.filter((item) => item.first_name !== "" && (item.role.includes(UserRoles.Qa_Auditor) || item.role.includes(UserRoles.Manager)))
          .map((item) => {
            return {
              label: item.first_name.concat(" " + item.last_name),
              value: item.id,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())) || [],
      component: (props) => {
        return(
          <FilterComponentWrapper key={props.key}>
            <QaAuditorFilter {...props} />
          </FilterComponentWrapper>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    {
      key: "agent_type",
      value: filters.agent_type,
      multiSelect: multiSelectKeys.agentTypeMultiSelect || false,
      handleChange: (value, label) => {
        handleChange("agent_type", value, label);
      },
      show: (isIncluded = false) => isIncluded,
      isIncluded: false,
      name: "Agent Type",
      options: async () => {
        return new Promise(async (resolve,reject) => {
          let agentType = filtersSegmentList?.agentTypeList;
          if(!agentType) {
            try {
              let res = await axiosInstance({
                method: "GET",
                url: `/accounts/users/profile/list`,
              })
              agentType = res?.data?.agent_type || []
            } catch(e) {
              agentType = []
            }
            const filterKey = Constants.FILTER_NAMES.AGENT_TYPE;
            dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: agentType }});
          }

          let agentTypeFormated = agentType.map((item) => {
            return {
              label: item,
              value: item,
            };
          }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
          resolve(agentTypeFormated)
        })  
      },
        
      component: (props) => {
        return (
          // <FilterComponentWrapper key={props.key}>
          //   <CalibrationStatusFilter {...props} />
          // </FilterComponentWrapper>
          <>
          </>
        );
      },
      additionalFilterConfig: {
        component: (props) => <CheckMarkFilter {...props} />,
        onChange: (value, key, callback) => {
          callback(key, value);
        }
      }
    },
    //for custom filters
    //feature gated self serve filters
    ...(showCustomFilters ?  renderCustomFilters() : []),
  ];

  return { filterList };
}
