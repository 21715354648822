import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Input, Spin, Button, message } from "antd";
import shortid from "shortid";
import ActionButtons from "./../ActionButtons";
import {
  postIntent,
  getItemDetails,
  updateItem,
  getDuplicateIntents,
  getUniversalScenarios,
  getIntentDetailsAnnotator,
} from "../../api/api";
import { 
        IntentPopupWrapper, IntentPopupLoadingWrapper, ConditionTitleWrapper, ConditionSubTitleWrapper, LineSeparator, ActionsWrapper, ButtonWrapper,
        NoSimilarIntents, RuleTitleWrapper, SubLineSeparator, IntentsMessageWrapper, AnnotatedPhrasesWrapper, PhraseTabsWrapper, EmptyStatesWrapper
       } from './wrappers';
import { ReactComponent as HelpTextIcon } from "../../assets/helptext-icon.svg";
import Constants from "../../utils/constants";
import SetupPopup from "../../utils/popups";
import UserPhrases from "./UserPhrases";
import AnnotatedPhrases from "./AnnotatedPhrases";
import Tabs from "./Tabs";
import appConfig from "../../../config";
import EmptyState from "../../../components/common/EmptyState";
import { getSchemaName } from "../../../api/api";
import { useSelector } from 'react-redux';

const IntentInnerPopup = ({
  onSubmit,
  onCancel,
  isHistorical,
  id,
  type,
  tabType,
  rules,
  viewOnly,
  intentType
}) => {
  const mapDetailedPhrases = (phrase, index) => ({
    id: `${shortid.generate()}`,
    text: phrase,
  });
  const getInitialPhrases = () => new Array(3).fill("").map(mapDetailedPhrases);
  const dispatch = useDispatch();
  const [phrases, setPhrases] = useState([]);
  const [negativePhrases, setNegativePhrases] = useState([]);
  const [annotatedPhrases, setAnnotatedPhrases] = useState([]);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editRuleConflictId, toggleEditRuleConflict] = useState(null);
  const [activeTab, setActiveTab] = useState("phrases");
  const [emptyState, setEmptyState] = useState(false);

  const schemaName = useSelector((state) => state.tenant.schemaName);

  const handleSubmit = () => {
    setIsButtonLoading(true);
    if (isHistorical) {
      updateItem(Constants.TAB_TYPE.INTENT, id, {
        description,
        phrases: phrases.map((phrase) => phrase.text),
        annotated_phrases: annotatedPhrases.map((phrase) => phrase.text),
        negative_phrases: negativePhrases.map((phrase) => phrase.text),
      }).then(() => {
        onSubmit();
      }).catch(err => {
        if(err?.response?.status == 400) {
          message.error("Please fill the required fields.");
        }
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
    } else {
      postIntent({
        description,
        phrases: phrases.map((phrase) => phrase.text),
        annotated_phrases: annotatedPhrases.map((phrase) => phrase.text),
        negative_phrases: negativePhrases.map((phrase) => phrase.text),
      }).then(() => {
        onSubmit({
          description,
          type,
        });
      }).catch(err => {
        if(err?.response?.status == 400) {
          message.error("Please fill the required fields.");
        }
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
    }
  };
  const handlePhrasesChange = (uniqueID, text) => {
    //making use of prevData as race condition was taking place when we change the text and try to add a new phrase at the same time
    setPhrases(prevData => ([
      ...prevData.map(item => {
        if(item.id == uniqueID) {
          item.text = text;
          return item;
        }
        return item;
      })
    ]));
  };

  const handleConflictResolved = (uniqueID, validated) => {
    setPhrases(prevData => ([
      ...prevData.map(item => {
        if(item.id == uniqueID) {
          item.validated = validated;
          return item;
        }
        return item;
      })
    ]))
  };
  const handleChange = ({ currentTarget }) => {
    setDescription(currentTarget.value);
  };
  const addPhrase = () => {
    setPhrases(prevData => ([
      ...prevData,
      {
        id: `${shortid.generate()}`,
        text: "",
      }
    ]));
  };
  const removePhrase = (uniqueID) => {
    setPhrases(prevData => ([
      ...prevData.filter(item => item.id !== uniqueID)
    ]));
  };
  const removeAnnotatedPhrase = (uniqueID) => {
    setAnnotatedPhrases(prevData => ([
      ...prevData.filter(item => item.id !== uniqueID)
    ]));
  };

  const setStates = (res) => {
    setDescription(res.description);
    const intentPhrases = res.phrases.map(mapDetailedPhrases);
    setPhrases(intentPhrases);
    const negativePhrases = res.negative_phrases
      ? res.negative_phrases.map(mapDetailedPhrases)
      : [];
    setNegativePhrases(negativePhrases);
    setAnnotatedPhrases(res.annotated_phrases.map(mapDetailedPhrases));
    setIsLoading(false);
  } 
  const tabs = [
    {
      title: "Example Phrases",
      key: "phrases",
      renderComponent: () => {
        return (
          <UserPhrases
            phrases={phrases}
            removePhrase={removePhrase}
            handlePhrasesChange={handlePhrasesChange}
            handleConflictResolved={handleConflictResolved}
            toggleEditRuleConflict={toggleEditRuleConflict}
            viewOnly={viewOnly}
            addPhrase={addPhrase}
            isHistorical={isHistorical}
            title="What would the customer or agent say to try to achieve this intent?"
            id={id}
          />
        );
      },
    },
    {
      title: "Negative Phrases",
      key: "negative_phrases",
      renderComponent: () => {
        return (
          <UserPhrases
            phrases={negativePhrases}
            handleConflictResolved={handleConflictResolved}
            toggleEditRuleConflict={toggleEditRuleConflict}
            viewOnly={viewOnly}
            placeholder="Negative phrase"
            addPhrase={() => {
              setNegativePhrases(prevData => ([
                ...prevData,
                {
                  id: `${shortid.generate()}`,
                  text: "",
                }
              ]))
            }}
            removePhrase={(uniqueID) => {
              setNegativePhrases(prevData => ([
                ...prevData.filter(item => item.id !== uniqueID)
              ]));
            }}
            handlePhrasesChange={(uniqueID, text) => {
              setNegativePhrases(prevData => ([
                ...prevData.map(item => {
                  if(item.id == uniqueID) {
                    item.text = text;
                    return item;
                  }
                  return item;
                })
              ]))
            }}
            isHistorical={isHistorical}
            title="What would the customer or agent say to not try to achieve this intent?"
            id={id}
          />
        );
      },
    },
  ];
  useEffect(() => {
    if (isHistorical && isLoading) {
      if (appConfig.showIntentConflict && rules) {
        const rule = intentType === Constants.UNIVERSAL ? rules.find((item) => item.id === id && item.intent_type === Constants.UNIVERSAL) : rules.find((item) => item.id === id); 
        if (rule) {
          getDuplicateIntents(rule.phrases).then((res) => {
            const matched_intents = res.matched_intents;
            const phrases = matched_intents.map((conflicts, index) => {
              const filteredConflicts = conflicts.filter(
                (item) => item.id !== rule.id
              );
              return {
                id: `${shortid.generate()}`,
                text: rule.phrases[index],
                conflicts: filteredConflicts,
                validated: !filteredConflicts.length,
              };
            });
            const allValidated = phrases.filter((i) => !i.validated).length === 0;
            setValidated(allValidated);
            setPhrases(phrases);
          });
        }
      }
      if(intentType && intentType === Constants.UNIVERSAL) {
        dispatch(getUniversalScenarios(Constants.TAB_TYPE.INTENT, id)).then(res => {
          setStates(res);
        }).catch(err => {
          setIsLoading(false);
          setEmptyState(true);
        })
        return;
      }

      if (getSchemaName() === "annotation") {
        // Special case for annotation tenant, different API call
        getIntentDetailsAnnotator(id, schemaName)
          .then((res) => {
            setStates(res);
          })
          .catch((err) => {
            setIsLoading(false);
            setEmptyState(true);
          });
      }
      else {
        getItemDetails(Constants.TAB_TYPE.INTENT, id)
          .then((res) => {
            setStates(res);
          })
          .catch((err) => {
            setIsLoading(false);
            setEmptyState(true);
          });
      }
      
      
    } else {
      if (isLoading) {
        setPhrases(getInitialPhrases());
        setNegativePhrases(getInitialPhrases());
        setIsLoading(false);
      }
    }
  }, [isHistorical, isLoading, id]);

  useEffect(() => {
    const allValidated = phrases?.filter((i) => !i.validated).length === 0 || false;
    setValidated(allValidated);
  }, [phrases]);

  if (editRuleConflictId) {
    return (
      <SetupPopup
        onSubmit={() => toggleEditRuleConflict(null)}
        onPopupCancel={() => toggleEditRuleConflict(null)}
        tabType={tabType}
        popupId={editRuleConflictId}
        rules={rules}
        isHistorical
      />
    );
  } else {
    return (
      <React.Fragment>
        <IntentPopupLoadingWrapper show={isLoading}>
          <Spin />
        </IntentPopupLoadingWrapper>
        <IntentPopupWrapper show={!isLoading}>
         {
          !emptyState ? <>
          <RuleTitleWrapper>
            <ConditionSubTitleWrapper>
              The customer or agent is trying to...
            </ConditionSubTitleWrapper>
            <Input
              disabled={viewOnly}
              name="description"
              placeholder="Scenario Name"
              onChange={handleChange}
              value={description}
            />
          </RuleTitleWrapper>
          <LineSeparator />
          <PhraseTabsWrapper>
            <Tabs defaultActiveTab="phrases" tabs={tabs} onChange={(activeTabKey) => {
              setActiveTab(activeTabKey);
            }} />
          </PhraseTabsWrapper>
          {annotatedPhrases?.length && (activeTab === "phrases") ? (
            <AnnotatedPhrasesWrapper>
              <AnnotatedPhrases
                removePhrase={removeAnnotatedPhrase}
                phrases={annotatedPhrases || []}
                viewOnly={viewOnly}
              />
            </AnnotatedPhrasesWrapper>
          ) : null}
          <SubLineSeparator />
          {validated && (
            <IntentsMessageWrapper>
              <ConditionTitleWrapper>
                Similar Existing Scenarios
              </ConditionTitleWrapper>
              <NoSimilarIntents>
                <HelpTextIcon /> No similar scenario found
              </NoSimilarIntents>
            </IntentsMessageWrapper>
          )}
          <LineSeparator />
          {!viewOnly ? (
            <ActionsWrapper>
              <ActionButtons isLoading = {isButtonLoading} onSubmit={handleSubmit} onCancel={onCancel} />
            </ActionsWrapper>
          ) : (
            <ButtonWrapper>
              <Button
                className="submit"
                style={{ float: "right" }}
                onClick={onCancel}
              >
                Close
              </Button>
            </ButtonWrapper>
          )}
          </>: (
                  <EmptyStatesWrapper>
                    <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
                    <ButtonWrapper className="custom-button-wrapper">
                      <Button
                        className="submit custom-button"
                        style={{ float: "right" }}
                        onClick={onCancel}
                        >
                        Close
                      </Button>
                    </ButtonWrapper>
                  </EmptyStatesWrapper>
               )
        }
        </IntentPopupWrapper>
      </React.Fragment>
    );
  }
};

export default IntentInnerPopup;
