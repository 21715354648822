import React, { useState, useEffect } from 'react'
import {Input, message} from "antd";
import styled from "@emotion/styled/macro";
import { CategoryBuilder, ResultsBuilder, QuestionSettingsBuilder } from "./components/rubric-popup-components"
import { StyledButton } from "./../../../components/common/styled-components";
import { criticalQuestions } from "./constants";

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #BDBDBD;
  button.secondary {
    margin-right: 4px;
    background: #ffffff;
    border: 1px solid #0B4B93;
    color: #0B4B93;    
  }
  button.primary, button.secondary {
    width: 96px;
    height: 32px;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }
`;

const RubricPopupWrapper = styled.div`
    display: ${props => props.show ? "flex" : "none"};
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    .ant-radio-button-wrapper {
        color: #BDBDBD;
    }
`;

const RubricTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0px;
    input {
        width: calc(100%);
        padding: 0px 10px;
        height: 47px;
        border: 1px solid #BDBDBD;
        margin-top: 8px;
        font-size: 16px;
        color: #4F4F4F;
    }
`

const LineSeparator = styled.div`
    width: 100%;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

export const RubricPopup = (props) => {
    const initialResults = props.outcome_type ? {
        outcome: props.outcome_type,
        thresholds: [...props.threshold].sort((a, b) => (b.to_value-a.to_value)),
        scoringMethod: props.scoring
    } : undefined

    const initialValues = {
        title: props.title || "",
        description: props.description || "",
        categories: props.categories || [
            {
                title: 'Default Category',
                weightage: 100,
                sections: [{
                    questions: []
                }]
            }
        ],
        results: initialResults || {
            outcome: "pass_fail",
            scoringMethod: "percentage",
            thresholds: []
        },
        defaultOptions: props.defaultOptions || [
            {
                title: "Yes",
                description: "",
                score: 1,
                isNA: false
            },
            {
                title: "No",
                description: "",
                score: 0,
                isNA: false
            },
        ]
        //criticalQuestion: props.criticalQuestion || criticalQuestions[0].key
    }

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState(initialValues.title);
    const [description, setDescription] = useState(initialValues.description);
    const [categories, setCategories] = useState(initialValues.categories);
    const [results, setResults] = useState(initialValues.results);
    const [defaultOptions, setDefaultOptions] = useState(initialValues.defaultOptions);
    //const [criticalQuestion, setCriticalQuestion] = useState(initialValues.criticalQuestion);
    
    const getMaxScore = (categories) => {
        let maxScore = 0;
        categories.forEach((category) => {
            let categoryScore = 0;
            category.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    categoryScore += Math.max(0, ...question.options.map(x=>Number(x.score)))
                })
            })
            maxScore += categoryScore;
        })
        return maxScore;
    }

    const resetValues = () => {
        setTitle(initialValues.title);
        setDescription(initialValues.description);
        setCategories([...initialValues.categories]);
        setResults(initialValues.results);
        setDefaultOptions(initialValues.defaultOptions);
        //setCriticalQuestion(initialValues.criticalQuestion);
    }

    useEffect(() => {
        resetValues()
    }, [props.isOpen])

    const handleChange = (e) => {
        switch(e.currentTarget.name) {
            case "title":
                setTitle(e.currentTarget.value)
                break
            case "description":
                setDescription(e.currentTarget.value)
                break
        }
    }

    const validate = () => {
        let errors = []
        if (title == "") {
            errors.push({
                key: "title",
                message: "Enter a title"
            })
        }
        if (description == "") {
            errors.push({
                key: "description",
                message: "Enter a description"
            })
        }
        if (categories.length === 0) {
            errors.push({
                key: "categories",
                message: "Add categories"
            })
        }

        results.thresholds.forEach((threshold) => {
            if (Number(threshold.to_value) < Number(threshold.from_value)) {
                errors.push({
                    key: "thresholds",
                    message: "Enter a valid range"
                })
            }
        })

        if (Math.round(categories.map((cat) => Number(cat.weightage)).reduce((a, b) => a + b, 0)) != 100) {
            errors.push({
                key: "categories",
                message: "Make sure that the weightages add up to 100"
            })
        }

        if (categories.filter(cat => cat.title.length > 0).length !== categories.length) {
            errors.push({
                key: "categories",
                message: "Enter the category title"
            })
        }

        if (errors.length === 0) {
            return true;
        } else {
            message.error(errors.map((e)=>e.message).join(", ") + " to continue.");
            return false;
        }
    }

    return (
        <RubricPopupWrapper show={!isLoading}>
            <RubricTitleWrapper>
                <Input name="title" placeholder="Rubric Title" onChange={handleChange} value={title} />
                <Input name="description" placeholder="Rubric Description" onChange={handleChange} value={description} />
            </RubricTitleWrapper>
            <LineSeparator /> 
            <CategoryBuilder onChange={(e) => setCategories(e.categories)} categories={categories}/>
            <LineSeparator />
            <ResultsBuilder 
            onChange={(e) => setResults(e.results)} 
            results={results} 
            maxScore={getMaxScore(categories)} 
            />
            <LineSeparator /> 
            <QuestionSettingsBuilder 
            //criticalQuestion={criticalQuestion}
            defaultOptions={defaultOptions}
            onChange={(e) => {
                switch(e.key) {
                    case "options":
                        setDefaultOptions(e.value)
                        break
                    // case "critical_questions":
                    //     setCriticalQuestion(e.value)
                    //     break
                }
            }}/>
            <LineSeparator />

            <FooterWrapper>
              <StyledButton onClick={() => {
                    resetValues()
                    props.onCancel()
                }} className="secondary">Cancel</StyledButton>
              <StyledButton disabled={false} onClick={() => {
                    if (validate()) {
                        props.onSubmit({
                            title,
                            description,
                            categories,
                            results,
                            defaultOptions,
                            // criticalQuestion,
                            // critical_question: criticalQuestion,
                            outcome_type: results.outcome,
                            threshold: results.thresholds,
                            scoring: results.scoringMethod,
                            max_score: 0,
                        })
                    }
                }
              } className="primary">Continue</StyledButton>
            </FooterWrapper>
        </RubricPopupWrapper>
    )
}

export default RubricPopup;
