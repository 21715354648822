import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "../../constants/ReduxActionConstants";
import appConfigs from "../../config";

const initialState = {
  isChartDashboardEdit: false,
  hideFollowed: true,
  globalFilters: {
    timeGranularity: "day",
    ...(!window.location.pathname.includes("qa-calibrations") && !window.location.pathname.includes("tasks")  && !window.location.pathname.includes("conversations") ? {
      date: appConfigs.DEFAULT_DATE_FILTER
    } : {
      date: ""
    }),
    ...(appConfigs.DEFAULT_CHANNEL ? {channel: appConfigs.DEFAULT_CHANNEL} : {}),
  },
  summary: {
    count: 0,
    total_audio_duration_hours: 0
  },
  filterSegments: {
  },
  customFilters: [],
  filterSegmentsLoading: true,
  customFiltersLoading: true,
  customFiltersFetched: false,
  isCountFetched: false,
  agentTypeFilter: []
};

const dashboardReducers = createReducer(initialState, {
  [ReduxActionTypes.CHART_DASHBOARD_EDIT]: (
    state,
    action,
  ) => {
    return {
      ...state,
      isChartDashboardEdit: action.payload,
    };
  },
  [ReduxActionTypes.SET_ANALYTICS_SEGMENTS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      segments: {
        ...state.segments,
        ...action.payload.value
      }
    }
  },
  [ReduxActionTypes.SET_CUSTOM_COLUMNS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      customColumns: action.payload.value,
    }
  },
  [ReduxActionTypes.SET_CUSTOM_COLUMNS_MEASURES]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      customColumnsMeasures: action.payload.value,
    }
  },
  [ReduxActionTypes.SET_CUSTOM_FILTERS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      customFilters: action.payload.value,
      customFiltersFetched: true
    }
  },
  [ReduxActionTypes.SET_AGENT_TYPE_FILTERS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      agentTypeFilter: action.payload.options
    }
  },
  [ReduxActionTypes.CACHE_CUSTOM_FILTERS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      customFilters: action.payload.value
    }
  },
  [ReduxActionTypes.RESET_GLOBAL_FILTERS]: (
    state,
    action
  ) => {
    return {
      ...state,
      globalFilters: {
        ...initialState.globalFilters,
        dateRangeValues: state.globalFilters.dateRangeValues,
        dateRange: state.globalFilters.dateRange
      }
    }
  },
  [ReduxActionTypes.SET_GLOBAL_FILTERS]: (
    state, 
    action,
  ) => {
    let newState = {
      ...state,
      globalFilters: {
        ...state.globalFilters,
        ...action.payload
      }
    }
    let filters = action.payload;
    if(filters.conversation_tag_id && (Array.isArray(filters.conversation_tag_id) || filters.conversation_tag_id instanceof Object ) && filters.conversation_tag_id.length > 0 ) {
      
      if(filters.conversation_tag_id.length > 1) {
        newState.hideFollowed = false;
        newState.globalFilters.followed = "";
        delete newState.globalFilters.expectationtype;
        return newState;
      }
      const acceptedExpectationTypes = ["should", "should_not"];
      const results = filters.expectationtype ? filters.expectationtype.find(item => acceptedExpectationTypes.includes(item)) : [];
      if(results && results.length > 0) {
        newState.hideFollowed = false;
        newState.globalFilters.followed = "Not Followed";
      } else {
        newState.hideFollowed = true;
        delete newState.globalFilters.followed;
      }  
    } 
  
    else if (((filters.conversation_tag_id && filters.conversation_tag_id.length <= 0 ) || ( !filters.conversation_tag_id )) && (newState.globalFilters.conversation_tag_id && newState.globalFilters.conversation_tag_id.length <= 0 ) || ( !newState.globalFilters.conversation_tag_id ) ) {
      newState.hideFollowed = true;
      delete newState.globalFilters.followed;
      delete newState.globalFilters.expectationtype;
    } 

    if(filters.followed) {
      newState.hideFollowed = false;
      newState.globalFilters.followed = filters.followed;
    }
    return newState;
  },
  [ReduxActionTypes.REMOVE_GLOBAL_FILTER]: (
    state, 
    action,
  ) => {
    let updatedGlobalFilters = {...state.globalFilters};
    delete updatedGlobalFilters[action.payload.key];
    return {
      ...state,
      globalFilters: updatedGlobalFilters
    };
  },
  [ReduxActionTypes.REQUEST_COUNT_DURATION]: (
    state,
    action
  ) => {
    return {
      ...state,
      summary: {
        count: 0,
        total_audio_duration_hours: 0
      },
      isCountFetched: false
    }
  },
  [ReduxActionTypes.RESPONSE_COUNT_DURATION]: (
    state,
    action
  ) => {
    return {
      ...state,
      summary: action.payload,
      isCountFetched: true
    }
  },
  [ReduxActionTypes.START_CACHING]: (
    state,
    action
  ) => {
    return {
      ...state,
      filterSegmentsLoading: true
    };
  },

  [ReduxActionTypes.END_CACHING]: (
    state,
    action
  ) => {
    return {
      ...state,
      filterSegmentsLoading: false
    };
  },
  [ReduxActionTypes.CACHE_FILTER_DATA]: (
    state,
    action
  ) => {
    return {
      ...state,
      filterSegments : {...state.filterSegments, ...action.payload}
    };
  },
  [ReduxActionTypes.START_CUSTOM_FILTER_LOADING]: (
    state,
    action
  ) => {
    return {
      ...state,
      customFiltersLoading: true
    };
  },

  [ReduxActionTypes.END_CUSTOM_FILTER_LOADING]: (
    state,
    action
  ) => {
    return {
      ...state,
      customFiltersLoading: false
    };
  },
  [ReduxActionTypes.SET_CAMPAIGN_SEGMENT]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      segments: {
        ...state.segments,
        campaign: action.payload
      }
    }
  },
  [ReduxActionTypes.SET_LOCATION_SEGMENT]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      segments: {
        ...state.segments,
        location: action.payload
      }
    }
  },
  [ReduxActionTypes.SET_QAAUDITOR_SEGMENT]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      segments: {
        ...state.segments,
        qa_auditors: action.payload
      }
    }
  },
});

export default dashboardReducers;
