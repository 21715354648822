const buildObject = (nodeId, elements, flow) => {
  const edges = elements.filter((ele) => ele.source === nodeId);
  const source = elements.find((ele) => ele.id === nodeId);

  edges.forEach((edge) => {
    const target = elements.find((ele) => ele.id === edge.target);
    if (target) {
      let currentFlow = {
        function: target.data.functionName,
        key: target.id,
        kwargs: { ...target.kwargs, from: source?.kwargs?.name || nodeId },
        if: target.data.if,
        next: [],
      };
      if (target.kwargs.optional) {
        delete currentFlow["kwargs"].optional;
        currentFlow["kwargs"] = {
          ...currentFlow["kwargs"],
          ...target.kwargs.optional,
        };
      }
      buildObject(target.id, elements, currentFlow);
      if (nodeId === "conversation") flow["workflow"] = currentFlow;
      else flow["next"].push(currentFlow);
    }
  });
  return flow;
};

const generateFlowObject = (elements) => {
  //Start building from trigger node only add nodes attached to trigger node
  let flow = buildObject("conversation", elements, {});
  return flow;
};
export default generateFlowObject;
