import React,{useEffect} from 'react'
import { getWithExpiry, setWithExpiry } from '../routes/utilities';
import * as Sentry from '@sentry/react';

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    console.log("error fallback", error,error.name,error.message);
    
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && (error.message.toLowerCase().includes('chunk') || chunkFailedMessage.test(error.message))) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        // Sentry.captureException("ChunkError", error.message);
        Sentry.withScope(scope => {
          scope.setTags("ChunkError")
          scope.setLevel(Sentry.Severity.Fatal)
          Sentry.captureException(error.name, error.message)
        });
        window.location.reload();
      }
    } else {
      Sentry.withScope(scope => {
        scope.setTags("ComponentError")
        scope.setLevel(Sentry.Severity.Fatal)
        Sentry.captureException(error.name, error.message)
      });
    }
  }, [error]);

  return (
    <>
    </>
  );
}

export default ErrorFallback
