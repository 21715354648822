import React, { useEffect, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
} from "react-flow-renderer";

import Sidebar from "../../components/Sidebar/sidebar";

import "./dnd.scss";
import nodeTypes from "../../nodeTypes/index";
import useStore from "../../store";
import { Button, Spin } from "antd";
import { useAutomationFetcher } from "../../automationFetcher";
import { SWRConfig } from "swr";
import { useHistory, useLocation } from "react-router";

const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

const DnDFlow = () => {
  const history = useHistory();
  const location = useLocation();
  const reactFlowWrapper = useRef(null);
  const { fetcher } = useAutomationFetcher();
  const {
    onConnect,
    removeElement,
    onLoad,
    onDrop,
    elements,
    addTriggerNode,
    getMeta,
    fetchInitElements,
    resetElements,
    alignNodes,
    isValidFlow,
    loadingElements,
  } = useStore();

  useEffect(() => {
    const search = location.search;
    const id = new URLSearchParams(search).get("id");
    // If workflow opened in edit mode
    if (id) {
      fetchInitElements(id);
    }
    // If user tries to directly land to create
    if (!id && !getMeta()) {
      history.push("/setup/automation");
    }
    return () => {
      resetElements();
    };
  }, []);

  useEffect(() => {
    isValidFlow();
    if (elements && elements.length === 0) {
      const triggerNode = location.state;
      addTriggerNode(triggerNode);
    }
    console.log(elements);
  }, [elements]);

  if (loadingElements) {
    return <Spin />;
  }
  return (
    <div className="dndflow" style={{ height: "500px" }}>
      <SWRConfig
        value={{
          fetcher: fetcher,
        }}
      >
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              elements={elements}
              snapToGrid
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              onElementsRemove={removeElement}
              onLoad={onLoad}
              onDrop={(event) => onDrop(event, reactFlowWrapper)}
              onDragOver={onDragOver}
            >
              <Controls />
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </div>
          <Button className="ms-2" onClick={() => alignNodes()}>
            {" "}
            Align Nodes
          </Button>
          <Sidebar />
        </ReactFlowProvider>
      </SWRConfig>
    </div>
  );
};

export default DnDFlow;
