import React, { useReducer } from 'react'
import { Button, message, Radio } from 'antd';
//import AddButton from '../../../components/common/AddButton';
import ValidationInput from '../../../components/common/ValidationInput';
import { getInitialState, popupActions, popupReducer } from "./reducer";
import { StyledSwitch } from "../../../components/common/styled-components";
import TimeRestriction from "./../commons/time-restriction";
import styled from "@emotion/styled/macro";
import { updateMetricTag } from '../../api/api';
import { connect } from "react-redux";
//import moment from "moment";

const RuleTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    margin: 16px 24px 24px;
    input {
        height: 36px;
        padding: 4px 10px;
        border: 1px solid #BDBDBD;
        margin-top: 8px;
        font-size: 16px;
        color: #4F4F4F;
    }
`;

const LineSeparator = styled.div`
    width: 100%;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

const PopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
    height: 591px;
`;

const InnerDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    height: 365px;   
    min-height: 365px;  
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 24px 0px;
    margin-bottom: 24px;
    .custom-wrapper {
        margin-top: 12px;
        display: flex;
        align-items: center;
        width: 220px;
        .ant-select-selection {
            min-width: 100px !important;
        }
    }
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px;
    .buttons {
        display: flex;
        align-items: center;
        button.submit, button.verify {
            margin-left: 4px;
        }
        button.verify {
            background: #27AE60;
            color: white;
            border: none;
        }
    }
`;

const QuestionWrapper = styled.div`
    display: flex;
    width: 100%;    
    margin-top: 24px;
    font-size: 16px;
    color: #4F4F4F;
`;

const AnswerWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;  
    margin-bottom: 8px; 
    label {
        font-size: 12px;
    } 
    .label {
        margin-right: 12px;
    }
`;

const MetricTag = (props) => {
    const [ ruleData , dispatch ] = useReducer(popupReducer, getInitialState(props.ruleData));
    const handleChange = (e) => {
        switch(e.currentTarget.name) {
            case "title": 
                popupActions.setTitle(e.currentTarget.value, dispatch)
                break;
            case "description": 
                popupActions.setDescription(e.currentTarget.value, dispatch)
                break;
            default: 
                break;
        }        
    }
    const handleCustomThresholdChange = (e) => {
        let value = e.currentTarget.value;
        let threshold, unit;
        let isCustomThreshold = true;
        if(ruleData.unit === "second") {
            if(value > 60 && value % 60 === 0) {
                threshold = value/60;
                unit = "MINUTE";
            }
            else {
                threshold = value;
                unit = "SECOND";
            }
        }
        else if(ruleData.unit==="percent"){
                threshold=value;
                unit="PERCENT"
        }
        else {
            if(value < 60) {
                threshold = value;
                unit = "SECOND";
            }
            else {
                threshold = value/60;
                unit = "MINUTE";                
            }
        }
        popupActions.setThresholdValue({
            unit,
            threshold,
            isCustomThreshold,            
        }, dispatch);                   
    } 
    const handleThresholdChange = (e) => {
        const value = e.target.value;
        if(value === "custom") {
            popupActions.toggleCustomThreshold(dispatch);
        }
        else {
            popupActions.setThresholdValue({
                threshold: value, 
                isCustomThreshold: false,
                unit: ruleData.unit.toUpperCase(),
            }, dispatch);
        }
    }
    const onSubmit = () => {
        const { title, description, is_active, conditions } = ruleData;
        const data = { title, description, is_active, conditions };
        if(ruleData.isCustomThreshold && !ruleData.threshold) {
            message.error("Please add a threshold time");
            return ;
        }
        if(ruleData.unit==="percent"){
            if(ruleData.threshold>100){
                message.error("Percentage cannot be greater than 100.");
                return ;
            }
        }
        updateMetricTag(props.id, data).then((res) => {
            props.onSubmit();
        });
    }

    const { title, validation, description, is_active, is_active_description, unit, threshold, isCustomThreshold, threshold_options } = ruleData;
    return (
        <PopupWrapper>
            <RuleTitleWrapper>
                <ValidationInput 
                    placeholder="Metric Tag Name" 
                    name="title" 
                    onChange={handleChange} 
                    defaultValue={title}
                    value={title} 
                    error={validation} 
                />         
                <ValidationInput 
                    placeholder="Description" 
                    name="description" 
                    onChange={handleChange} 
                    defaultValue={description}
                    value={description} 
                    error={validation} 
                />       
            </RuleTitleWrapper>
            <LineSeparator />
            <InnerDetailsWrapper>
                <QuestionWrapper>Would you like to tag conversations with {title}? </QuestionWrapper>
                <AnswerWrapper><div className="label">Active</div> <StyledSwitch 
                        size="small" 
                        checked={is_active}
                        onChange={(active) => {
                            popupActions.toggleActive(active, dispatch);
                        }}
                    />
                </AnswerWrapper>
                <QuestionWrapper>{is_active_description}</QuestionWrapper>
                <AnswerWrapper>
                    <Radio.Group name="radiogroup" defaultValue={isCustomThreshold ? "custom" : threshold} onChange={handleThresholdChange}>
                        {threshold_options.map((item) => {
                            return <Radio value={item}>{item} {`${unit}${unit!=="percent" ? (item > 1 ? "s" : ""):""}`}</Radio>
                        })}
                        <Radio value="custom">Custom</Radio>
                    </Radio.Group>
                </AnswerWrapper>
                {isCustomThreshold ? <div className="custom-wrapper">
                    <TimeRestriction 
                        onValuesChange={(value) => handleCustomThresholdChange(value)} 
                        defaultValue={unit === "minute" ? 60*threshold : threshold}
                        unit={unit}                         
                    />
                </div> : null}
            </InnerDetailsWrapper>
            <LineSeparator />
            <ActionButtonsWrapper>
                <div className="buttons">
                    <Button style={{float: "right"}} onClick={() => {
                        props.onCancel();
                    }}>Cancel</Button>                
                    <Button style={{float: "right"}} className="submit" onClick={onSubmit}>Save</Button>                    
                </div>
            </ActionButtonsWrapper>                           
        </PopupWrapper>
    )
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps)(MetricTag);