import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Spin } from "antd";
import Alert from "../../../../../components/common/Alert";
import BreadCrumbComponent from "../../../../../components/common/BreadCrumbComponent";
import { PrimaryButton } from "../../../../../components/common/styled-components";
import { ReduxActionTypes } from "../../../../../constants/ReduxActionConstants";
import { REQUEST_TYPE } from "../../../../../custom-hooks/config";
import { useReq } from "../../../../../custom-hooks/useDispatchReq";
import { initialState } from "../../../../../reducers/setup/qaCaseAssignmentReducer";
import { getRuleData } from "../../api";
import RuleBlocks from "./RuleBlocks";
import { frequencyOptions, pullDataOptions, ruleModes } from "../../config";
import { decryptDataFromStorage } from "../../../../../utils/utilities";
import { SpinnerWrapper } from "../../../../../QaCalibration/wrappers";
import { RuleViewWrapper, RuleHeadingWrapper, RuleSubHeadingWrapper, RuleHeading, RuleBodyWrapper, RuleButtonWrapper, RuleColumnOneWrapper, RuleColumnTwoWrapper } from '../../wrappers';
import { spliceText } from "../../../../../components/common/CustomFilterComponent/utils";
import { useSaveRule } from "../../hooks";
import { getFrequencyValues, mapFiltersMetaToArray } from "../../helpers";
import { GenerateSummary } from "./GenerateSummary";
import { convertToUtc_Local_Mins } from "../../utils";

const RuleView = ({ mode, ...rest }) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.qaCaseAssignmentData.tableData)
  const ruleBlockData = useSelector((state) => state.qaCaseAssignmentData.ruleBlocksData);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [qaData, setQaData] = useState(null);
  const [visible, setVisible] = useState(false);

  const [callApi, cancelRequests] = useReq();
  const { saveRule } = useSaveRule({ mode, setLoading });
  const { id } = useParams();

  const discardRule = () => {
    setVisible(true);
  };

  const onOkClick = () => {
    if(isEditEnabled && mode === ruleModes.EDIT) {
      setQaStates();
      setVisible(false);
      return dispatch({
        type: ReduxActionTypes.SET_QA_IS_EDIT_ENABLED,
        payload: false
      });
    }

    history.push(`/setup/qa-case-assignment/`);
  };

  const onCancelClick = () => {
    setVisible(false);
  };

  const onEditClick = () => {
    dispatch({
      type: ReduxActionTypes.SET_QA_IS_EDIT_ENABLED,
      payload: true
    })
  }

  const setQaStates = () => {
      dispatch({
        type: ReduxActionTypes.SET_QA_QA_AUDITORS_LIST,
        payload: [...qaData.auditors]
      });
      dispatch({
        type: ReduxActionTypes.SET_QA_AGENTS,
        payload: {agentsGroup: qaData?.agent_group || [], agentsList: qaData?.agents || []}
      });
      dispatch({
        type: ReduxActionTypes.SET_QA_RULE_NAME,
        payload: qaData.name
      })
      //generate name and schedule object
      const cron_schedule = qaData.cron_schedule;
      let { generatedTime, totalMaxMinutes } = convertToUtc_Local_Mins(`${cron_schedule.hour}:${cron_schedule.minute}`, "local");
      let { day_of_week, day_of_month } = getFrequencyValues({ frequency: qaData.data_pull_range == frequencyOptions.WEEKLY.value ? frequencyOptions.WEEKLY.label : qaData.data_pull_range == frequencyOptions.MONTHLY.value ? frequencyOptions.MONTHLY.label : frequencyOptions.DAILY.label  , day_of_week: cron_schedule.day_of_week, day_of_month: cron_schedule.day_of_month }, totalMaxMinutes);
      let frequencyObject = {};
      frequencyObject = {
        time: generatedTime, 
        ...(
          qaData.data_pull_range == frequencyOptions.WEEKLY.value ? {
            day_of_week: `${day_of_week}`,
            frequency: frequencyOptions.WEEKLY.label,
            pullDataFieldValue: pullDataOptions[frequencyOptions.WEEKLY.label][0],
            _pullDataOptions: pullDataOptions[frequencyOptions.WEEKLY.label],
          } : qaData.data_pull_range === frequencyOptions.MONTHLY.value ? {
            day_of_month: `${day_of_month}`,
            frequency: frequencyOptions.MONTHLY.label,
            pullDataFieldValue: pullDataOptions[frequencyOptions.MONTHLY.label][0],
            _pullDataOptions: pullDataOptions[frequencyOptions.MONTHLY.label],
          } : {
            frequency: frequencyOptions.DAILY.label,
            pullDataFieldValue: pullDataOptions[frequencyOptions.DAILY.label][0],
            _pullDataOptions: pullDataOptions[frequencyOptions.DAILY.label],
          }
        )
      }

      dispatch({
        type: ReduxActionTypes.SET_QA_FREQUENCY_OBJECT,
        payload: {...frequencyObject}
      });

      let filters_meta = [];
      filters_meta = mapFiltersMetaToArray(qaData.sample.filters_meta);

      dispatch({
        type: ReduxActionTypes.SET_QA_FILTERS_META,
        payload: [...filters_meta]
      });
  }

  const resetQAState = () => {
    dispatch({
      type: ReduxActionTypes.RESET_QA_STATE, 
      payload: { ...initialState }
    });
  };

  const renderButtonWrapper = () => {
    return mode === ruleModes.CREATE || (isEditEnabled) ? 
    <>
      <PrimaryButton
      disabled = {loading}
      onClick={discardRule}
      style={{ background: "#F2F2F2", border: "none" }}
      >
        {isEditEnabled ? "Discard Changes" : "Discard"}
      </PrimaryButton>
      <PrimaryButton style={{ color: "#ffffff" }} loading = {loading} onClick={saveRule} type="primary">
        {isEditEnabled ? "Save Changes" : "Save and enable"}
      </PrimaryButton>
    </> : 
    id !== null && id !== undefined ? 
      <PrimaryButton style={{ background: "#F2F2F2", border: "none" }} onClick={onEditClick}>
        Edit
      </PrimaryButton> : null
  }

  useEffect(() => {
    dispatch({
      type: ReduxActionTypes.SET_QA_CASE_ASSIGNMENT_MODE,
      payload: mode
    });
    if(mode === ruleModes.EDIT) {
      if(id !== null && id !== undefined) {
        setGlobalLoader(true);
        let _qaData = {};

        if(tableData?.length) {
          _qaData = tableData.find(item => item.id === parseInt(id));
          setGlobalLoader(false);
          setQaData(_qaData);
        }
        else if(sessionStorage.getItem("qaAssignmentData")){
          const qaAssignmentData = JSON.parse(sessionStorage.getItem("qaAssignmentData"));
          _qaData = qaAssignmentData?.find(item => item.id === parseInt(id));
          setGlobalLoader(false);
          setQaData(_qaData);
        }
        else {
          callApi(getRuleData, (res) => {
            _qaData = res;
            setGlobalLoader(false);
            setQaData(_qaData);
          }, (err) => {

          }, {
            id
          }, () => {

          }, REQUEST_TYPE.DISPATCH_REQUEST);
        }
      }
      else {
        resetQAState();
      }
    }
    else if(mode === ruleModes.CREATE) {
      resetQAState();
    }
    return () => {
      resetQAState();
      cancelRequests();
    }   

  }, [mode]);

  useEffect(() => {
    if(qaData) {
      setQaStates();
    }
  }, [qaData]);
  
  return (
    !globalLoader ? 
    <RuleViewWrapper>
      <RuleHeadingWrapper>
        <BreadCrumbComponent
          items={[
            { title: "Home", link: "/" },
            { title: "Settings", link: "/setup" },
            { title: "QA assignment", link: "/setup/qa-case-assignment/" },
            { title: mode === ruleModes.CREATE ? "Create rule" : "Edit Rule" }
          ]}
        />
        <RuleSubHeadingWrapper>
          <RuleHeading>{mode === ruleModes.CREATE && !isEditEnabled ? 'New Rule' : spliceText(ruleBlockData.nameAndScheduleObject.ruleName, 40, true)}</RuleHeading>
          <RuleButtonWrapper>
            {
              renderButtonWrapper()
            }
          </RuleButtonWrapper>
        </RuleSubHeadingWrapper>
      </RuleHeadingWrapper>
      <RuleBodyWrapper>
        <RuleColumnOneWrapper className="col-7">
          <RuleBlocks />
        </RuleColumnOneWrapper>
        <RuleColumnTwoWrapper className="col-5">
          <GenerateSummary/>
        </RuleColumnTwoWrapper>
      </RuleBodyWrapper>
      <Alert
        cancelButtonName={"No, Keep editing"}
        okButtonName={"Yes, Discard"}
        onCancelClick={onCancelClick}
        onOkClick={onOkClick}
        heading={"Confirm discard"}
        renderBody={() => (
          <>
            <div>Are you sure you want to discard all the progress?</div>
            <div>
              You will not be able to restore this progress once discarded
            </div>
          </>
        )}
        closable={true}
        visible={visible}
      />
    </RuleViewWrapper> : <SpinnerWrapper><Spin/></SpinnerWrapper>
  );
};

export default RuleView;
