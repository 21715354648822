import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "../../constants/ReduxActionConstants";

const initialState = {
  date: "30",
  searchValue: "",
  sortable: {},
  date: "30",
  channel: "2",
  asrLogID: null
  //cachedFilterApiData : {},
  //isFetchingFilters: true
};

const listfilterReducers = createReducer(initialState, {
  [ReduxActionTypes.STORE_LIST_FILTERS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [ReduxActionTypes.REMOVE_LIST_FILTERS]: (
    state,
  ) => {
    return {
      ...state,
      ...initialState,
    };
  },
  [ReduxActionTypes.SET_ASR_LOG_ID]: (
    state,
    action
  ) => {
    return {
      ...state,
      asrLogID: action.payload
    };
  },
});

export default listfilterReducers;
