import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomFilterComponent from "../../../../../components/common/CustomFilterComponent/CustomFilterComponent";
import { ReduxActionTypes } from "../../../../../constants/ReduxActionConstants";
import { UserRoles } from "../../../user-management/config";
import {
  AgentsWrapper,
  AgentsHeadWrapper,
  AgentsBodyWrapper,
  AgentsUser
} from "../../wrappers";
import BlockComponent from "../BlockComponent";
import { ruleModes } from "../../config";
import { useReq } from "../../../../../custom-hooks/useDispatchReq";
import { getAgentGroupOptions } from "../../../../api/api";
import { REQUEST_TYPE } from "../../../../../custom-hooks/config";
import { DASHBOARD_OPTIONS } from "../../../../../user-dashboard/utils";
import { getSchemaName } from "../../../../../api/api";

const AgentsBlock = () => {
  const dispatch = useDispatch();
  const userOptions = useSelector((state) => state.dashboard.filterSegments.globalUserRoleList);
  const agentsObject = useSelector((state) => state.qaCaseAssignmentData.ruleBlocksData.agentsObject);
  const showErrorState = useSelector((state) => state.qaCaseAssignmentData.showErrorState);
  const mode = useSelector((state) => state.qaCaseAssignmentData.mode);
  const isEditEnabled = useSelector((state) => state.qaCaseAssignmentData.isEditEnabled);

  const [tab, setTab] = useState("Individuals");
  const [agentGroupOptions, setAgentGroupOptions] = useState([]);

  const [callApi, cancelRequests] = useReq();

  const onTabChange = (e) => {
    setTab(e.target.value);
  };
  
  const onAddAgent = (value) => {
    dispatch({
      type: ReduxActionTypes.SET_QA_AGENTS,
      payload: {
        ...(tab === "Individuals" ? { agentsList: [...value], agentsGroup: [] } : { agentsList: [], agentsGroup: [...value] })
      },
    });
  };

  const renderCustomFilterComponent = (type, value) => {
    return <CustomFilterComponent
              showTextOnly = {mode === ruleModes.CREATE || isEditEnabled ? false : true}
              textOnlyFormat = "block"
              textOnlyStyle = {{ color: "#4F4F4F", rowGap: "16px" }}
              disabled={false}
              maxTagCount = {4}
              type={"local"}
              showFooter={false}
              options={
                type === "Individuals" ?
                (userOptions
                  ?.filter((user) =>
                    user.role.includes(UserRoles.Agent)
                  )
                  .map((item) => {
                    return {
                      value: item.id,
                      label: item.first_name + " " + item.last_name,
                    };
                  })
                  .sort((a, b) =>
                    a.label
                      .toLowerCase()
                      .localeCompare(b.label.toLowerCase())
                  ) || []) : (agentGroupOptions || [])
              }
              placeholder={type === "Individuals" ? "Select one or more agents" : "Select one or more groups"}
              multiSelect={true}
              style={{ width: "80%" }}
              value={value}
              handleChange={(val) => {
                onAddAgent(val);
              }}
            />;
  }

  useEffect(() => {
    callApi(getAgentGroupOptions, (res) => {
      if(res?.agent_type?.length) {
        return setAgentGroupOptions(res.agent_type.map(option => {
          return {
            value: option,
            label: option?.charAt(0)?.toUpperCase() + option?.slice(1),
          }
        }));
      }
      return setAgentGroupOptions([]);
    }, (err) => {
      if(err && err.message === DASHBOARD_OPTIONS.CANCEL_REQUEST) {
        return;
      }
      setAgentGroupOptions([]);
    }, {

    }, () => {

    }, REQUEST_TYPE.DISPATCH_REQUEST);

    return () => {
      cancelRequests();
    }
  }, []);

  useEffect(() => {
    if(!isEditEnabled && mode === ruleModes.EDIT) {
      if(agentsObject?.agentsGroup?.length) {
        setTab("Groups");
      }
      else {
        setTab("Individuals");
      }
    }
  }, [agentsObject, mode, isEditEnabled]);

  return (
    <>
      <BlockComponent
        _key="qa-agents-list-block"
        id="qa-agents-list-block"
        errorObject={{
          isError:
            showErrorState &&
            !agentsObject.agentsList?.length &&
            !agentsObject.agentsGroup?.length,
          errorText: "Choose at least one agent or one group",
        }}
        heading="Agents"
        subHeading="Select interactions of these agents"
        renderBody={() => (
          <AgentsWrapper className="d-flex flex-column">
            {mode === ruleModes.CREATE || isEditEnabled ? (
              <AgentsHeadWrapper className="d-flex">
                <Radio.Group
                  onChange={(e) => {
                    onTabChange(e);
                  }}
                  value={tab}
                >
                  <Radio value={"Individuals"}>Individuals</Radio>
                  {(getSchemaName() === 'toast' || getSchemaName() === "level") && <Radio value={"Groups"}>Groups</Radio>}
                </Radio.Group>
              </AgentsHeadWrapper>
            ) : null}
            <AgentsBodyWrapper className = "d-flex flex-column">
              {tab === "Individuals" ? (
                <>
                    {renderCustomFilterComponent("Individuals",  agentsObject.agentsList?.length ? agentsObject.agentsList : [])}
                </>
              ) : (
                
                getSchemaName() === 'toast' || getSchemaName() === "level" ? 
                  <>
                    {renderCustomFilterComponent("Groups", agentsObject.agentsGroup?.length ? agentsObject.agentsGroup : [])}
                </>
                  : null
              )}
            </AgentsBodyWrapper>
          </AgentsWrapper>
        )}
      />
    </>
  );
};

export default AgentsBlock;
