import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import PrivateRoute from '../PrivateRoute';
import { mapDispatchToProps, mapStateToProps } from '../utilities';
import {privateRoutes} from './data';


export const PrivateRouteWrapper = connect(mapStateToProps, mapDispatchToProps)(({ component: Component, ...rest }) => {
  return (
    <PrivateRoute {...rest} 
        render={(props) => {
            return (
              <ErrorBoundary>
                    <div className="component-layout">
                      <Component {...props}/>
                    </div>
              </ErrorBoundary>
            )
        }}
    />
  )
});

function index(props) {
  return (
    <>
      {privateRoutes.map(route => <PrivateRouteWrapper {...route} />)}
    </>
  );
}

export default index;