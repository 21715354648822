import React, { useState, useEffect, useCallback, memo } from "react";
import { Form, Radio, Select, Spin, Button, Tooltip } from "antd";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector } from "../../../store";
import TimeGranularityFilter from "../../../components/common/TimeGranularityFilter";
import DateFilter from "../../../components/common/DateFilter";
import { getMeasureTextCustomColumns } from "../../utils";
import { QueryBuilderConfig } from "../../query-builder-config";
import { QueryBuilderFormWrapper, FilterInputWrapper, FormItemTitle, CompareDateRangeWrapper, TimeGranularityWrapper } from "./style";
import { cubeJSApi, getAgentFilterOptions } from "../../api";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { useDispatch } from 'react-redux';
import { getSelectMeasureOptions, getSelectGroupByOptions } from "../../query-config";
import { queryConfigs } from "../../query-config";
import CustomFilters from "../../../components/common/CustomFilters";
import { getDateRangeValues, getCompareRangeOptions } from "../../../utils/utilities";
import Filters from './filters';
import Measures from './measures';
import Groupby from './groupby';
import groupby from "./groupby";

const QueryBuilderForm = ({
  onMeasureChange,
  onGroupByChange,
  onFilterChange,
  onDateFilterChange,
  onTimeSeriesChange,
  onTimeGranularityChange,
  onCompareDateRangeChange,
  onCheckFilters,
  onCheckMeasures,
  onCheckGroupby,
  initialValues,
  loading,
  disableCompareDateRange,
  setDisableCompareDateRange
}) => {
    const dispatch = useDispatch();

    const customMeasures = useSelector(state => state.dashboard.customColumnsMeasures);
    const customFilters = useSelector(state => state.dashboard.customFilters);

    const [isTimeSeries, setIsTimeSeries] = useState(false);
    const [measure, setmeasure] = useState();
    const [groupBy, setgroupBy] = useState();
    const [filter, setfilter] = useState();
    const [isloading, setisloading] = useState(false);
    const [timeGranularity, settimeGranularity] = useState(QueryBuilderConfig.DEFAULT_TIME_GRANULARITY);
    const [dateFilterValue, setDateFilterValue] = useState("30");
    const [dateRange, onChangeDateRange] = useState();
    const [isCompareDateRange, setIsCompareDateRange] = useState(false);
    const [selectedCompareDateRange, setSelectedCompareDateRange] = useState();
    const [selectedCompareDateRangeLabel, setSelectedCompareDateRangeLabel] = useState();
    const [compareDateRangeOptions, updateCompareDateRangeOptions] = useState();
    const [timeSeriesDisable, setTimeSeriesDisable] = useState(false);

    useEffect(() => {
        if((groupBy?.length || 0) < 2 && (measure?.length || 0) < 2)
            setDisableCompareDateRange(false)
        else
            setDisableCompareDateRange(true)

        if(measure?.length > 1 && groupBy?.length > 0) {
            setTimeSeriesDisable(true);
            if(isTimeSeries) {
                onTimeSeriesChange(false);
            }
        } else {
            setTimeSeriesDisable(false);
        }
    },[groupBy,measure]);

    useEffect(() => {
        if(isTimeSeries) {
            setDisableCompareDateRange(true);
        } else {
            setDisableCompareDateRange(false);
        }
    }, [isTimeSeries])

    useEffect(() => {
        getAgentFilterOptions().then(res => {
          dispatch({ type: ReduxActionTypes.SET_AGENT_TYPE_FILTERS, payload: { options : res.data ? res.data.agent_type : [] }});
        }).catch(error => {
          dispatch({ type: ReduxActionTypes.SET_AGENT_TYPE_FILTERS, payload: { options : [] } });
        });

        // TO DO - WHILE REVAMPING, GENERALIZED FILTER WILL HAVE ITS OWN CUBE FETCH
        let campaignOptionsQuery = {"measures":[],"timeDimensions":[],"order":{},"filters":[],"dimensions":["AccountsUser.campaign"]};
        cubeJSApi().then((api) => {
            api.load(campaignOptionsQuery).then((resultSet) => {
                let campaignOptions = resultSet.pivot().map(({xValues, yValuesArray}) => xValues[0]).filter(el => el);

                dispatch({ type: ReduxActionTypes.SET_CAMPAIGN_SEGMENT, payload: campaignOptions });
            }).catch(error => {
                dispatch({ type: ReduxActionTypes.SET_CAMPAIGN_SEGMENT, payload: [] });
            })
        });

        let locationOptionsQuery = {"measures":[],"timeDimensions":[],"order":{},"filters":[],"dimensions":["AccountsUser.location"]};
        cubeJSApi().then((api) => {
            api.load(locationOptionsQuery).then((resultSet) => {
                let locationOptions = resultSet.pivot().map(({xValues, yValuesArray}) => xValues[0]).filter(el => el);

                dispatch({ type: ReduxActionTypes.SET_LOCATION_SEGMENT, payload: locationOptions });
            }).catch(error => {
                dispatch({ type: ReduxActionTypes.SET_LOCATION_SEGMENT, payload: [] });
            })
        });

        let qaAuditorOptionsQuery = {"measures":[],"timeDimensions":[],"order":{},"filters":[],"dimensions": ["QAAuditor.qa_auditor_id", "QAAuditor.first_name", "QAAuditor.last_name"]};
        cubeJSApi().then((api) => {
            api.load(qaAuditorOptionsQuery).then((resultSet) => {
                let qaAuditorOptions = resultSet.pivot().filter(({xValues, yValuesArray}) => xValues.some(v => v)).map(({xValues, yValuesArray}) => ({value: xValues[0], label: `${xValues[1]} ${xValues[2]}`}));

                dispatch({ type: ReduxActionTypes.SET_QAAUDITOR_SEGMENT, payload: qaAuditorOptions });
            }).catch(error => {
                dispatch({ type: ReduxActionTypes.SET_QAAUDITOR_SEGMENT, payload: [] });
            })
        });

        onDateFilterChange(dateFilterValue);
        onChangeDateRange(dateFilterValue);
    }, [])

    useEffect(() => {
        setisloading(loading);
    }, [loading]);

    useEffect(() => {
        if (!initialValues) return;
        setmeasure(initialValues.measure);
        setgroupBy(initialValues.groupBy);
        setIsTimeSeries(initialValues.isTimeSeries);
        settimeGranularity(initialValues.timeGranularity);
        setfilter(initialValues.filter);
    }, [initialValues]);

    useEffect(() => {
        let options = getCompareRangeOptions(dateRange, "day");
        updateCompareDateRangeOptions(options);
    }, [dateRange]);

    useEffect(() => {
        if(isCompareDateRange) {
            handleOnCompareDateRangeRemove();
        }
    }, [disableCompareDateRange]);

    const handleOnCompareDateRangeChange = (value) => {
        setSelectedCompareDateRange(value);
        let selectedOption = compareDateRangeOptions.filter(option => option.id === value)[0]
        
        onCompareDateRangeChange(selectedOption.value);
        setSelectedCompareDateRangeLabel(selectedOption?.label);
    }

    const handleOnCompareDateRangeRemove = () => {
        setSelectedCompareDateRange();
        setSelectedCompareDateRangeLabel();
        setIsCompareDateRange(false);
        onCompareDateRangeChange(null);
    }

    const compareDateRangeTooltip = () => {
        return (
            <div>
                <span>Comparison of date range is not supported in the following conditions</span>
                <ul>
                    <li>Multiple measures </li>
                    <li>Grouped charts</li>
                    <li>Time series</li>
                </ul>
            </div>
        )
    }

    if (isloading)
        return (
            <div style={{ height: "200px", display: "grid", placeContent: "center" }}>
                <Spin />
            </div>
        );

    return (
        <QueryBuilderFormWrapper>
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
            >
                <Form.Item>
                    <FormItemTitle>Measure</FormItemTitle>
                    <Measures value={measure} onChange={onMeasureChange} onCheckMeasures={onCheckMeasures} setIsTimeSeries={setIsTimeSeries} isCompareDateRange={isCompareDateRange} />
                </Form.Item>

                <Form.Item>
                    <FormItemTitle>Filters</FormItemTitle>
                    <Filters value={filter} onChange={onFilterChange} onCheckFilters={onCheckFilters} />
                </Form.Item>

                <Form.Item>
                    <FormItemTitle>Group By</FormItemTitle>
                    <Groupby value={groupBy} onChange={onGroupByChange} onCheckgroupby={onCheckGroupby} measure={measure} isTimeSeries={isTimeSeries} isCompareDateRange={isCompareDateRange}   />
                </Form.Item>

                <Form.Item>
                    <FormItemTitle>Time Series</FormItemTitle>
                    <Radio.Group
                        value={isTimeSeries || false}
                        onChange={(e) => {
                            onTimeSeriesChange(e);
                            setIsTimeSeries(e.target.value);
                        }}
                        buttonStyle="solid"
                    >
                        <Tooltip title={isCompareDateRange ? "Time series is not supported" : undefined}  placement="bottom">
                            <Radio.Button value={true} disabled={isCompareDateRange || (Array.isArray(measure) && measure.length > 1 && Array.isArray(groupBy) && groupBy.length > 0 && groupBy[0])}>Time Series</Radio.Button>
                        </Tooltip>
                        <Radio.Button value={false}>Consolidated</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {isTimeSeries && (
                    <Form.Item>
                        <FormItemTitle>Time Granularity</FormItemTitle>
                        <TimeGranularityWrapper>
                            <TimeGranularityFilter
                                value={
                                    timeGranularity || QueryBuilderConfig.DEFAULT_TIME_GRANULARITY
                                }
                                handleChange={onTimeGranularityChange}
                            />
                        </TimeGranularityWrapper>
                    </Form.Item>
                )}

                <Form.Item>
                    <FormItemTitle>Date Range</FormItemTitle>
                    <FilterInputWrapper>
                        <DateFilter
                            value={dateFilterValue}
                            handleChange={(value) => {
                                setDateFilterValue(value);
                                onChangeDateRange(value);
                                onDateFilterChange(value);
                            }}
                        />
                    </FilterInputWrapper>
                    
                    {
                        !isCompareDateRange &&
                            <Tooltip title={disableCompareDateRange ? compareDateRangeTooltip : undefined}  placement="right">
                                <Button className={disableCompareDateRange ? null : "add-compare-date-btn"} onClick={() => setIsCompareDateRange(true)} disabled={disableCompareDateRange}>
                                    <PlusCircleOutlined />
                                    Compare to previous date range
                                </Button>
                            </Tooltip>
                    }
                </Form.Item>

                {
                    isCompareDateRange && !disableCompareDateRange &&
                        <Form.Item>
                            <FormItemTitle>Compare to</FormItemTitle>
                            <CompareDateRangeWrapper>
                                <Select
                                    style={{width: "180px"}}
                                    dropdownStyle={{minWidth: "400px"}}
                                    placeholder="Select range"
                                    onChange={(e) => handleOnCompareDateRangeChange(e)}
                                    value={selectedCompareDateRangeLabel}
                                >
                                    {
                                        compareDateRangeOptions.map(option => (
                                            <Select.Option value={option?.id}>
                                                <div className="compare-date-range-option" style={{display: "flex", flexDirection: "flex-row", justifyContent: "space-between"}}>
                                                    <span>
                                                        {option.label}
                                                    </span>
                                                    <span>
                                                        {option.dateLabel}
                                                    </span>
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>

                                <CloseOutlined style={{height: "14px"}} onClick={handleOnCompareDateRangeRemove} />
                            </CompareDateRangeWrapper>
                        </Form.Item>
                }
            </Form>
        </QueryBuilderFormWrapper>
    );
};

export default memo(QueryBuilderForm);
