import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { Checkbox, Radio } from 'antd';

const ChannelProperty = styled.div`
    display: flex;
    flex-direction: column;
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const ChannelsProperty = (props) => {
    const [channel, setChannel] = useState(props?.ruleProperties?.channel);
    const [showValidations, toggleShowValidations] = useState(false);
    const handleChange = (value) => {
        setChannel(value);
        props.handleChange({
            type: "channel",
            value,
            label: value?.length && value.length === props?.options?.length ? 'All Channels' : value?.length && value.length < props?.options?.length ? value.join(', ') : ''
        });
    }
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])

    useEffect(() => {
      setChannel(props?.ruleProperties?.channel);
    }, [props?.ruleProperties?.channel]);
    return (
        <ChannelProperty>
            <Checkbox.Group className="radio-group" name="radiogroup" value = {channel} defaultValue={channel} onChange={handleChange}>
                {props?.options?.map((item) => {
                    return <Checkbox key = {item.value} value = {item.value}>{item.label}</Checkbox>
                })}                
            </Checkbox.Group>
        </ChannelProperty>
    )
}

export default ChannelsProperty;
