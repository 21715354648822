import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "./../../constants/ReduxActionConstants";


const initialState = {};
const userManagementReducer = createReducer(initialState, {
  [ReduxActionTypes.CREATE_USER]: (
    state,
    action,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});

export default userManagementReducer;
