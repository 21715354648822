import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../ErrorFallback";
import EmptyState from "../EmptyState";
import Constants from "../../../CompanySetup/utils/constants";
import { ReactComponent as EmptySearchIcon } from "../../../assets/filter-search-empty-state.svg";
import { PrimaryButton, StyledSearchInput } from "../styled-components";
import {
  CustomFilterWrapper,
  FilterWrapper,
  DropdownWrapper,
  SearchWrapper,
  OptionsWrapper,
  FooterWrapper,
  FooterSubWrapper,
  ClearAllButton,
  EmptyStateWrapper,
  OptionItem,
  TextOnlyWrapper
} from "./wrapper";
import { checkArrayOrString, handleSearch, isValueExists } from "./helpers";
import { handleClickOutside, spliceText } from "./utils";

const CustomFilter = ({
  options,
  placeholder = "Select",
  multiSelect,
  width,
  value,
  maxTagCount = 1,
  handleChange,
  disabled = false,
  style = {},
  dropdownStyle = {},
  filterHeaderStyle = {},
  textOnlyStyle = {},
  textOnlyFormat = "block",
  showFooter = true,
  type = "global",
  iserror = false,
  spliceLimit = { placeholderLimit: 200 },
  ...rest
}) => {
  //need to give single select support for date filter
  //always send option in key value pair
  const [_value, setValue] = useState([]);
  const [_options, setOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [searchEmptyState, setSearchEmptyState] = useState(false);
  const [showExpandMoreIcon, setShowExpandMoreIcon] = useState(true);
  const [enableSave, setEnableSave] = useState(false);
  const [visible, setVisible] = useState(false);
  //const debouncedOnChange = useCallback(debounce(handleChange, 150), []); //for single select
  const dropdownRef = useRef(null);

  const generateLabels = () => {
    const tempValue = [..._value];
    const labelsList = [];
    for (let i = 0; i < tempValue?.length; i++) {
      for (let k = 0; k < _options?.length; k++) {
        const { value, label } =
          typeof _options[k] !== "object"
            ? { value: _options[k], label: _options[k] }
            : _options[k];
        if (value == tempValue[i]) {
          labelsList.push(label);
        }
      }
    }
    return labelsList || [];
  }
  const generateMaxTagPlaceholder = () => {
    if (!_value?.length || !_options?.length) {
      return <span className="placeholder">{placeholder}</span>;
    }

    let labelsList = generateLabels() || [];
    const mainLabelArray = labelsList?.slice(0, maxTagCount);
    const remainingLabelsArray = labelsList?.slice(maxTagCount);
    return !mainLabelArray?.length && !remainingLabelsArray?.length ? (
      ""
    ) : (
      <div>
        {mainLabelArray?.join(", ")}{" "}
        {remainingLabelsArray?.length ? (
          <Tooltip title={<div>{remainingLabelsArray?.join(", ")}</div>}>
            +{remainingLabelsArray?.length}
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    );
  };

  const onCancelClick = () => {
    setVisible(false);
    setSearch("");
    setSearchOptions(_options);
    setSearchEmptyState(false);
    setEnableSave(false);
    checkArrayOrString(value, setValue);
  };

  const onSaveClick = () => {
    const updatedValue = _value?.length ? _value?.map(String) : [];
    handleChange(updatedValue);
    setVisible(false);
    setSearch("");
    setSearchOptions(_options);
    setSearchEmptyState(false);
    setEnableSave(false);
    //openNotification("bottomLeft", "Filters applied successfully.");
  };

  const onClearClick = () => {
    setValue([]);
    if(value || value?.length || value === 0 || value === "0") {
      setEnableSave(true);
    }
    else {
      setEnableSave(false);
    }
  };

  const handleItemChange = (val) => {
    setEnableSave(true);
    let updatedValue = _value ? [..._value] : [];
    if (multiSelect) {
      if (isValueExists(val, _value)) {
        updatedValue = updatedValue?.filter((item) => item != val);
      } else {
        
        updatedValue.push(val);
      }

      setValue(updatedValue);
      if(type === "local") {
        handleChange(updatedValue?.length ?  updatedValue?.map(String) : []);
      }
    } else {
      setVisible(false);
      if (_value?.length && _value[0] == val) {
        handleChange("");
        return setValue([]);
      }
      handleChange(typeof val !== "string" ? `${val}` : val);
      setValue([val]);
    }
  };

  const onFilterWrapperClick = () => {
    setVisible(!visible);
    setShowExpandMoreIcon(!showExpandMoreIcon);
    setEnableSave(false);
    checkArrayOrString(value, setValue);
  };


  const generatePlaceholder = () => {
    return spliceText(placeholder, spliceLimit?.placeholderLimit || 200, true);
  }

  const generateTopItems = () => {
    if(_options?.length > 7) {
      const selectedItems = _value?.filter(val => searchOptions?.find(option => option.value == val)).map(val => searchOptions?.find(option => option.value == val));

    return selectedItems?.length ? selectedItems.map(item => <OptionItem
      className={
        "option-items option-items-background"
      }
      key={item?.value}
      onClick={() => handleItemChange(item?.value)}
    >
      <div>{item?.label}</div>
        <div className="option-check-icon">
          <CheckIcon className="option-check-icon-svg" />
        </div>
    </OptionItem>) : null;
    }
    else {
      return null;
    }
  }

  const renderTextOnly = () => {
    if(!_value?.length || !_options?.length) {
      return "";
    }
    const labelsList = generateLabels();
    return labelsList?.length ? textOnlyFormat === "inline" ? spliceText(labelsList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', '), 40, true) : labelsList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(label => <span key = {label}>{label}</span>) : "";
  }

  const renderDropdownWrapper = () => {
    return (
      <DropdownWrapper
        style = {dropdownStyle || {}}
        visible={visible}
        className={
          visible
            ? `add-background-color ${
                _options?.length > 7 ? "scrollbar-css" : ""
              }`
            : ""
        }
      >
        {visible && (
          <>
            {_options?.length > 7 && (
              <SearchWrapper>
                <StyledSearchInput
                  className="searchInput"
                  placeholder={"Search.."}
                  value={search}
                  prefix={<SearchOutlined />}
                  onChange={(event) => {
                    handleSearch(
                      event,
                      _options,
                      options,
                      setSearchEmptyState,
                      setSearch,
                      setSearchOptions
                    );
                  }}
                  allowClear
                />
              </SearchWrapper>
            )}

            {searchOptions?.length ? (
              <OptionsWrapper optionCount = {_options?.length || 0}>

                <div className="d-flex flex-column">
                  {
                    generateTopItems()
                  }
                  {searchOptions?.filter(option => _options?.length > 7 ? !_value?.map(String)?.includes(option.value?.toString()) : option)?.map((option) => {
                    const { value, label } =
                      typeof option !== "object"
                        ? { value: option, label: option }
                        : option;
                    return (
                      <>
                      <OptionItem
                        className={
                          isValueExists(value, _value)
                            ? "option-items option-items-background"
                            : "option-items"
                        }
                        key={value}
                        onClick={() => handleItemChange(value)}
                      >
                        <div>{label}</div>
                        {isValueExists(value, _value) && (
                          <div className="option-check-icon">
                            <CheckIcon className="option-check-icon-svg" />
                          </div>
                        )}
                      </OptionItem>
                      </>
                    );
                  })}
                </div>
              </OptionsWrapper>
            ) : !searchEmptyState ? (
              <EmptyStateWrapper>
                <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
              </EmptyStateWrapper>
            ) : (
              <EmptyStateWrapper>
                <EmptyState
                  type={Constants.EMPTY_STATE_VIEWS.EMPTY_SEARCH_VIEW}
                  icon={<EmptySearchIcon />}
                  parentText={`No results found for "${spliceText(
                    search,
                    20
                  )}"`}
                  childText={"Try searching something else"}
                />
              </EmptyStateWrapper>
            )}
            {multiSelect && showFooter && (
              <FooterWrapper>
                <ClearAllButton
                  disabled={!_value?.length}
                  onClick={() => {
                    onClearClick();
                  }}
                >
                  Clear all
                </ClearAllButton>
                <FooterSubWrapper>
                  <PrimaryButton
                    onClick={() => {
                      onCancelClick();
                    }}
                  >
                    Cancel
                  </PrimaryButton>
                  <PrimaryButton
                    type="primary"
                    disabled = {!enableSave}
                    onClick={() => {
                      onSaveClick();
                    }}
                  >
                    Save
                  </PrimaryButton>
                </FooterSubWrapper>
              </FooterWrapper>
            )}
          </>
        )}
      </DropdownWrapper>
    );
  };

  useEffect(() => {
    const _options = options?.options ? options.options : options
    setOptions(_options);
    setSearchOptions(_options);
  }, [options]);

  useEffect(() => {
    checkArrayOrString(value, setValue);
  }, [value]);

  useEffect(() => {
    document.addEventListener("click", (event) => {
        handleClickOutside(event, dropdownRef, () => {
          onCancelClick(value);
        });
      }, true
    );
    return () => {
      document.removeEventListener("click", (event) => {
          handleClickOutside(event, dropdownRef);
        }, true
      );
    };
  }, [value, _options]);

  return (
    !rest?.showTextOnly ? <CustomFilterWrapper style = {style} ref={dropdownRef}>
      <FilterWrapper
        iserror = {iserror}
        disabled = {disabled}
        minWidth={width}
        filterHeaderStyle = {filterHeaderStyle}
        className={visible ? "add-filter-hover-style" : ""}
        onClick={() => { !disabled && onFilterWrapperClick(); }}
      >
        <span className="placeholder-span">
          {_value?.length ? ( generateMaxTagPlaceholder()) : (
            <span className="placeholder">{generatePlaceholder()}</span>
          )}
        </span>
        <span className="search-arrow">
          <ExpandMoreIcon className="expand-icon" />
        </span>
      </FilterWrapper>
      {renderDropdownWrapper()}
    </CustomFilterWrapper>
    : 
     <TextOnlyWrapper style = {textOnlyStyle} format = {textOnlyFormat}>{renderTextOnly()}</TextOnlyWrapper> 
  );
};

const CustomFilterComponent = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <CustomFilter {...props} />
  </ErrorBoundary>
);

export default CustomFilterComponent;
