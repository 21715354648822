import React, { useState, useEffect } from "react";
import QueryBuilderForm from "../components/query-builder-form/index";
import { getQuery, postToDashboard, updateQuery } from "../api";
import { QueryBuilderConfig } from "../query-builder-config";
import { Button, Input, message, Modal, Tooltip } from "antd";
import SetupHeader from "../../CompanySetup/components/commons/SetupHeader";
import { postQuery } from "../api";
import { withRouter } from "react-router";
import styled from "@emotion/styled";
import * as QueryString from "query-string";
import { QueryChart } from "../components/query-chart";
import { getDateRangeValues } from "../../utils/utilities";
import { ReactComponent as MailIcon } from "../../assets/ShareReportMailIcon.svg";
import { PrimaryStyledButton } from "../../components/common/styled-components";
import ShareQueryChart from "../components/query-chart-share";

export const QueryBuilderWrapper = styled.div`
  padding: 15px 23px;
  width: 100%;
`;

const ActionButtonsWrapper = styled.div`
  button.submit {
    margin: 10px;
  }
`;

const TitleModalWrapper = styled.div`
  padding: 20px;
`;
const QueryBuilderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitlePopup = (props) => {
  const [title, setTitle] = useState(props.title || "");
  const [visible, setVisisble] = useState(false);
  const {
    isVisible,
    getQueryParams,
    onOk,
    onCancel,
    queryId,
    isSaved,
    addToDashboard,
    newDashboardChart,
    isDashboardEdit,
  } = props;

  useEffect(() => {
    setVisisble(isVisible);
  }, [isVisible]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  return (
    <Modal
      title="Save Query"
      okText="Save"
      visible={visible}
      onOk={() => {
        const postOrUpdate = isSaved() ? updateQuery : postQuery;

        let args = [
          {
            title,
            ...getQueryParams(),
          },
        ];

        if (queryId) {
          args = [queryId, ...args];
        }
        postOrUpdate(...args).then((res) => {
          if (isDashboardEdit !== "true" && addToDashboard) {
            postToDashboard({ query: res.id }).then((res) => {
              onOk();
              message.success("Saved and added to the dashboard");
              window.open("/analytics/custom-dashboard", "_self");
            });
          } else {
            onOk();
            message.success("Saved the query");
            if (addToDashboard) {
              window.open("/analytics/custom-dashboard", "_self");
            }
          }
        });
      }}
      onCancel={onCancel}
    >
      <TitleModalWrapper>
        <Input
          value={title}
          placeholder={"Enter Title"}
          onChange={(e) => {
            setTitle(e.currentTarget.value);
          }}
        />
      </TitleModalWrapper>
    </Modal>
  );
};

const QueryBuilderComponent = (props) => {
  const [dateRangeValues, setdaterangevalues] = useState();
  const [date, setdate] = useState("");
  const [chartType, setchartType] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [title, settitle] = useState("");
  const [addToDashboard, setaddToDashboard] = useState(false);
  const [titlePopupVisible, settitlePopupVisible] = useState(false);
  const [query, setquery] = useState({ timeGranularity: "week" });
  const [openChartShareModal, setOpenChartShareModal] = useState(false);
  const [isCompareDateRange, setIsCompareDateRange] = useState(false);
  const [disableCompareDateRange, setDisableCompareDateRange] = useState(false);
  const [isMultiChart, setIsMultiChart] = useState(false);

  const isSaved = () => {
    return !(props.match.params.id === undefined);
  };

  useEffect(() => {
    if (props.match.params.id) {
      setisLoading(true);
      getQuery(props.match.params.id)
        .then((res) => {
          settitle(res.title);
          setquery({
            measure: res.measure.replace("BankingBankingconversation", "Conversation"),
            groupBy: res.dimension,
            timeGranularity: res.time_granilarity,
            isTimeSeries: res.is_time_series,
            filter: res.filters,
          });
        })
        .catch((err) =>
          message.error(
            "Sorry, It seems that the query has been deleted from Saved Queries"
          )
        )
        .finally(() => setisLoading(false));
    }
  }, []);

  const onSubmit = () => {
    settitlePopupVisible(true);
    setaddToDashboard(false);
  };

  const isDashboardFlow = () => {
    const queryParams = QueryString.parse(props.location.search);
    return (
      queryParams.isDashboardEdit === "true" ||
      queryParams.newDashboardChart === "true"
    );
  };

  const onAddToDashboard = () => {
    settitlePopupVisible(true);
    setaddToDashboard(true);
  };
  const getQueryParams = () => {
    return {
      measure: Array.isArray(query.measure) ? query.measure.join(',') : query.measure,
      dimension: Array.isArray(query.groupBy) ? query.groupBy.join(',') : query.groupBy,
      is_time_series: query.isTimeSeries,
      time_granilarity: query.timeGranularity,
      filters: query.filter || [],
    };
  };

  return (
    <QueryBuilderWrapper>
      <QueryBuilderHeader>
        <SetupHeader
          title="Query Builder"
          icon="user"
          description="Save and organize your queries"
          breadCrumbItems={[
            { title: "Home", link: "/" },
            { title: "Analytics", link: "/analytics" },
            { title: "Query Builder" },
          ]}
          openInfo={() => this.setState({ showInfo: true })}
        />
        {openChartShareModal && (
          <ShareQueryChart
            title={title}
            isSaved={isSaved}
            getQueryParams={getQueryParams}
            queryId={props.match.params.id}
            isFromCustomDashboard={true}
            openChartShareModal={openChartShareModal}
            setOpenChartShareModal={setOpenChartShareModal}
          />
        )}
        {query.measure && !isLoading && (
          <PrimaryStyledButton
            className="share-report-button"
            style={{ marginTop: "8px", display: "flex", alignItems: "center" }}
            onClick={() => setOpenChartShareModal(true)}
          >
            <MailIcon style={{ marginRight: "5px" }} />
            Share
          </PrimaryStyledButton>
        )}
      </QueryBuilderHeader>

      <TitlePopup
        queryId={props.match.params.id}
        title={title}
        isSaved={isSaved}
        addToDashboard={addToDashboard}
        {...QueryString.parse(props.location.search)}
        isVisible={titlePopupVisible}
        onOk={() => {
          settitlePopupVisible(false);
        }}
        onCancel={() => {
          settitlePopupVisible(false);
        }}
        getQueryParams={getQueryParams}
      />
      <QueryBuilderForm
        loading={isLoading}
        initialValues={query}
        onMeasureChange={(e) => {
          setquery({
            ...query,
            measure: e,
          });
        }}
        onGroupByChange={(e) => {
          setquery({
            ...query,
            groupBy: e,
          });
        }}
        onFilterChange={(e) => {
          setquery({
            ...query,
            filter: e,
          });
        }}
        onDateFilterChange={(e) => {
          let dateRange = getDateRangeValues(e);
          setdate(e);
          setdaterangevalues(getDateRangeValues(e));

          let timeDimension = {
            dimension: "LevelAsrAsrlog.created",
            dateRange: [ new Date(dateRange.from_date).toISOString().split('T')[0], new Date(dateRange.to_date).toISOString().split('T')[0] ]
          };

          if(query?.timeDimension?.compareDateRange) {
            timeDimension.compareDateRange = query?.timeDimension?.compareDateRange
          }

          setquery({
            ...query,
            timeDimension: timeDimension
          })
        }}
        onTimeSeriesChange={(e) => {
          setquery({
            ...query,
            isTimeSeries: e.target ? e?.target?.value : e,
          });
          if ((e?.target && e?.target?.value) || e) {
            setchartType(QueryBuilderConfig.LINE_CHART);
          } else {
            setchartType(QueryBuilderConfig.DEFAULT_CHART_TYPE);
          }
        }}
        onTimeGranularityChange={(e) => {
          setquery({
            ...query,
            timeGranularity: e,
          });
        }}
        onCompareDateRangeChange={(e) => {
          let timeDimension = {
            dimension: "LevelAsrAsrlog.created",
            compareDateRange: e
          }

          if(!e) {
            delete timeDimension.compareDateRange;
            timeDimension = {
              dimension: "LevelAsrAsrlog.created",
              dateRange: [ new Date(dateRangeValues.from_date).toISOString().split('T')[0], new Date(dateRangeValues.to_date).toISOString().split('T')[0] ]
            }
            setIsCompareDateRange(false);
          } else {
            setIsCompareDateRange(true);
          }

          setquery({
            ...query,
            timeDimension: timeDimension
          });
        }}
        setDisableCompareDateRange={(value) => {setDisableCompareDateRange(value)}}
        disableCompareDateRange={disableCompareDateRange}
      />
      {query.measure && !isLoading && (
        <div style={{ margin: "0 -23px" }}>
          <QueryChart
            title={title}
            query={query}
            chartType={chartType}
            dateRangeValues={dateRangeValues}
            date={date}
            showPropOnX={true}
            isCompareDateRange={isCompareDateRange}
          />
          <ActionButtonsWrapper>
            {isDashboardFlow() ? (
              <Button
                className="submit"
                type="primary"
                onClick={onAddToDashboard}
                disabled={isCompareDateRange || (Array.isArray(query.measure) && query.measure.length > 1) || (Array.isArray(query.groupBy) && query.groupBy.length > 1)}
              >
                Save & Add To Dashboard
              </Button>
            ) : (
              <>
                <Tooltip title={isCompareDateRange ? "Save is not supported for date comparison" : undefined}>
                  <Button 
                    className="submit" 
                    type="primary" 
                    onClick={onSubmit} 
                    disabled={isCompareDateRange || (Array.isArray(query.measure) && query.measure.length > 1) || (Array.isArray(query.groupBy) && query.groupBy.length > 1)}
                  >
                    Save
                  </Button>
                </Tooltip>
                
                <Button
                  className="submit"
                  type="primary"
                  onClick={onAddToDashboard}
                  disabled={isCompareDateRange || (Array.isArray(query.measure) && query.measure.length > 1) || (Array.isArray(query.groupBy) && query.groupBy.length > 1)}
                >
                  Save & Add To Dashboard
                </Button>
              </>
            )}
            <br />
          </ActionButtonsWrapper>
        </div>
      )}
    </QueryBuilderWrapper>
  );
};

export const QueryBuilder = withRouter(QueryBuilderComponent);
