import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useStore from "../store";
import { message } from "antd";

const SaveWorkflow = () => {
  const history = useHistory();
  const location = useLocation();
  const saveWorkflow = useStore((state) => state.saveWorkflow);
  const flowError = useStore((state) => state.flowError);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  useEffect(() => {
    const search = location.search;
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
  }, []);
  useEffect(() => {
    if (flowError) message.error(flowError, 2);
  }, [flowError]);
  return (
    <div>
      <Button
        onClick={() => history.push("/setup/automation")}
        className="me-3"
      >
        Cancel
      </Button>
      <Button
        onClick={async () => {
          setLoading(true);
          await saveWorkflow(id);
          setLoading(false);
          history.push("/setup/automation");
        }}
        type="primary"
        disabled={flowError}
        loading={loading}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default SaveWorkflow;
