import styled from "@emotion/styled/macro";
import { Tooltip } from 'bizcharts';
import { Button } from "antd";

export const DashboardWrapper = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 42px);
`;

export const DashboardHeader = styled.div`
  width: 100%;
  min-height: 120px;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 23px;
`;

export const DashboardTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #828282;
  margin-top: 9px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
`;

export const AnalyticMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 162px);
  overflow: auto;
  padding: 8px 16px;
  width: 66%;
`;

export const MetricsWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.isManager ? 291 : 438)}px;
  min-height: ${(props) => (props.isManager ? 291 : 438)}px;
`;

export const LoadingAnalyticMetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 162px);
  width: 66%;
`;

export const LevelMetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
`;

export const ScoreCardWrapper = styled.div`
  margin: 0 6px;
  width: 100%;
`;

export const LineSeparator = styled.div`
  width: calc(100% - 48px);
  margin: 8px auto;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const InteractionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  padding: 16px 24px;
  width: 34%;
  overflow: auto;
  height: calc(100vh - 162px);
`;

export const InteractionReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

export const InteractionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const InteractionsListLoading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;
`;

export const TitleWrapper = styled.div`
  height: 36px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 12px 21px;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;

export const DashboardChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  min-height: 400px;
  height: 400px;
`;

export const ChartWrapper = styled.div`
    padding: ${props => (props.padding || "20px 70px 0px 20px")};
    margin: ${props => (props.margin || "10px")};
    min-height: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: ${props => props.width};
`;

export const MultiChartWrapper = styled.div`
  padding: 20px 70px 0px 20px;
  height: 520px;
  width: calc(100%-60px);
  background: white;
  margin: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const ChartListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomTooltip = styled(Tooltip)`

`;

export const CustomTooltipWrapper = styled(Tooltip)`

`;

export const TooltipWrapper = styled.div`
`;

export const TooltipSubWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
`;

export const TextWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  width: max-content;
  height: max-content;
  padding: 5px;
  background: ${props => props.bgColor};
  border: none;
  margin-top: 5px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    color: white;
    background: lightgray;
    transition: 0.4s ease;
  }
` 

export const ChartTypeToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
  width: 100px;
  svg {
    margin: 5px;
    height: 20px;
    width: 20px;
  }
`;

export const ChartSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
  height: 40px;
  margin-bottom: 5px;
  p {
    font-size: 12px;
    text-align: left;
    color: #8b8b8d;
  }
`;

export const AnalyticsSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const AnalyticsSummaryCardWrapper = styled.div`
  text-align: center;
  margin: 10px;
  background-color: white;
  width: 33%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .header {
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;
    text-align: left;
    color: #8b8b8d;
    padding: 16px;
  }
  .value {
    font-size: 30px;
    color: #4f4f4f;
    font-weight: 700;
    min-height: 60px;
    padding: 20px;
  }
`;

export const TabWrapper = styled.div`
  width: calc(100% - 222px);
`;

export const LoadingChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px 40px;
  height: 400px;
`;

export const DashboardChartFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 50px;
  position: absolute;
  top: 70px;
  z-index: 1;
  &&& .ant-select-selection {
    border-color: #e0e0e0;
  }

  &&& .ant-select-selection-selected-value {
    color: #828282;
  }
`;

export const DashboardFiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline !important;
  width: 100%;
`;

export const ListAggregatedata = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #828282;
  span {
    color: #bdbdbd;
    margin-left: 3px;
  }
`;

export const SelectWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  .ant-select {
    padding: 0px;
  }
`;

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
