import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FilterComponent from "./FilterComponent";
import { getCategories } from "../../api/api";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

//name needs to be changed from topics to category
const TopicsFilter = ({
  value,
  handleChange,
  selectedWorkspace,
  categoryMultiSelect,
  filtersSegmentList,
  width,
  style = {},
  disabled = false,
  ...rest
}) => {
  const [category, updateCategory] = useState(value);
  const [categories, setCategories] = useState();

  useEffect(() => {
    updateCategory(value);
  }, [value]);
  useEffect(() => {
    getCategories().then((res) => setCategories(res || []));
  }, []);

  // useEffect(() => {
  //     //fetchTopicsList();
  // }, [selectedWorkspace])

  return !categoryMultiSelect ? (
    <FilterComponent
      width={width || 180}
      options={categories}
      placeholder="All Categories"
      value={category}
      maxTagCount={1}
      capitalize={false}
      multiSelect={categoryMultiSelect}
      showSearchIcon={true}
      handleChange={(value) => {
        updateCategory(value);
        handleChange(value);
      }}
      showSearch={true}
      hideClear={false}
      disabled={disabled}
      style={style}
      loading={!categories}
      {...rest}
    />
  ) : (
    <CustomFilterComponent
      width={width || 180}
      options={categories?.length ? categories : []}
      placeholder="All Categories"
      value={category}
      maxTagCount={1}
      multiSelect={categoryMultiSelect}
      handleChange={(value) => {
        updateCategory(value);
        handleChange(value);
      }}
      disabled={disabled}
      style={style}
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.app_user.selectedWorkspace,
  filtersSegmentList: state.dashboard.filterSegments,
});
export default connect(mapStateToProps)(TopicsFilter);
