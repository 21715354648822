import styled from "@emotion/styled";

export const MeasureWrapper = styled.div`
  margin-bottom: 15px;
  gap: 10px;

  .add-measure-btn {
    background-color: #F2F2F2;
    width: 200px;
    height: 30px;
  }

  .measure-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
  }

  .select-measure-input {
    width: 85%;
  }
`;

export const DeleteMeasureButton = styled.div`
  cursor: pointer;
`;

const FilterWrapper = styled.div`
  margin-bottom: 13px;
  
  .ant-row {
    margin-bottom: 10px;
  }

  .filter-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
  }

  .add-filter-btn {
    background-color: #F2F2F2;
    width: 200px;
    height: 30px;
  }
`;

export const FilterRowWrapper = styled.div`
  width: 85%;
`;

const FilterInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  .filter-type {
    flex: 1;
    margin: auto 8px;
  }
  .filter-value {
    flex: 1;
    width: 200px;
    line-height: 32px !important;
  }
  .filter-value > div {
    margin: 0px;
    width: 100%;
    flex: 1;
    .ant-select-selection {
      max-width: unset;
    }
  }
  .ant-select {
    margin: 0px;
  }

  & > div {
    margin: 0px;
  }
  margin-top: 0px;
`;

export const TimeGranularityWrapper = styled.div`
  width: 180px;
`;

export const CompareDateRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;

  .compare-date-range-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const DeleteFilterButton = styled.div`
  cursor: pointer;
`;

const QueryBuilderFormWrapper = styled.div`
  padding-top: 13px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  .ant-form-item {
    margin-bottom: 10px;
  }
  .add-compare-date-btn {
    background-color: #F2F2F2;
    height: 30px;
  }
`;

export const GroupbyWrapper = styled.div`
  margin-bottom: 13px;

  .groupby-row-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
  }

  .add-groupby-btn {
    background-color: #F2F2F2;
    width: 200px;
    height: 30px;
  }

  .select-groupby-input {
    width: 85%;
  }
`;

export const DeleteGroupbyButton = styled.div`
  cursor: pointer;
`;

const FormItemTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  height: 20px;
  line-height: 20px;
`;

const IconButtonWrapper = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  .filter-action {
    flex: 1;
  }
`;

export {
    FilterInputWrapper,
    FilterWrapper,
    QueryBuilderFormWrapper,
    FormItemTitle,
    IconButtonWrapper
}; 
