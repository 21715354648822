import React, { Component } from "react";
import styled from "@emotion/styled/macro";
import cookie from "react-cookies";
import MenuItems, {
  MenuItemWrapper,
  MenuItem,
} from "./common/MenuItems";
import { ReactComponent as LevelIcon } from "../assets/level-logo.svg";
import { ReactComponent as ConversationHistory } from "../assets/conversation-history.svg";
import { ReactComponent as Dashboard } from "../assets/dashboard.svg";
import { ReactComponent as Settings } from "../assets/settings.svg";
import { ReactComponent as Analytics } from "../assets/analytics.svg";
import { ReactComponent as LiveChatIcon } from "../assets/live-help.svg";
import TuneIcon from '@material-ui/icons/Tune';
import { connect } from "react-redux";
import {
  ReduxActionTypes,  
} from "../constants/ReduxActionConstants";
import { getKeyInteractionsUnreadCount } from "../user-dashboard/api";
import { withRouter } from "react-router-dom";
import appConfig from '../config';
import { initializeGlobalFilters } from "../utils/utilities";
import Axios from "axios";
import store from "../store";
import { getCustomFilters } from "../conversation-list/api";

const MenuWrapper = styled.div`
  height: 100vh;
  width: 72px;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(180deg, #0C3768 64.05%, #0B55A9 118.28%);
  position: fixed;  
  z-index: 200;
  @media only screen and (max-width: 1200px) {
    width: 72px;
  }
  @media only screen and (max-width: 800px) {
    width: 72px;
  }
  @media only screen and (max-width: 720px) {
    width: 72px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
`;

export const MENU_ITEMS = [
  {
    title: "Dashboard",
    icon: Dashboard,
    path: "dashboard"    
  },
  {
    title: "Analytics",
    icon: Analytics,
    path: "analytics"    
  },
  // {
  //   title: "Live Assist",
  //   icon: AgentAssist,
  //   path: "live-assist",
  //   access: (user) => user.is_agent
  // },
  {
    title: "Interaction History",
    icon: ConversationHistory,
    path: "conversations"
  }, 
  ...(appConfig.ENABLE_QA_CALIBRATION ? [{
    title: "Qa Calibration",
    icon: TuneIcon,
    path: "qa-calibrations",    
    access: (user) => user.is_manager || user.is_superuser || user.is_qa_auditor
    }] : []),
  {
    title: "Settings",
    icon: Settings,
    path: "setup",    
    access: (user) => user.is_manager || user.is_superuser || user.is_qa_auditor
  },

];

let axiosCancelToken;
class NavigationBar extends Component {
  state = {
    showLiveChatIcon: false,
    showLiveChatRoom: false,
  };
  componentDidMount() {
    axiosCancelToken = Axios.CancelToken.source();
    if (!this.props.location.search) {
      this.props.resetGlobalFilters(this.props.app_user);
    }
    if(this.props.app_user?.token) {
      this.getKeyInteractions();
      //this.fetchCustomFilters();
      const _cookies = cookie.loadAll();
      if (_cookies?.talkdesk_widget_url) {
        this.loadTalkdeskHeader(_cookies?.talkdesk_widget_url).then(() => {
          window.TalkdeskConnect.init();
        }
      ).catch(err => {
      });
     }
    }
  }

  componentDidUpdate(prevprops) {
    if(prevprops.location.search === this.props.location.search) {
      return;
    }
    if (!this.props.location.search) {
      this.props.resetGlobalFilters(this.props.app_user);
    }
    this.getKeyInteractions();
  }

  componentWillUnmount() {
    this.props.resetGlobalFilters();
    const talkdeskHeader = document.getElementById("td-connect");
    if(talkdeskHeader) {
      talkdeskHeader.remove();
    }
    if(axiosCancelToken) {
      axiosCancelToken.cancel('')
    }
  }

  loadTalkdeskHeader = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve('script loaded');
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  getKeyInteractions = () => {
    if(axiosCancelToken) {
      axiosCancelToken.cancel('');
    }
    axiosCancelToken = Axios.CancelToken.source();
    getKeyInteractionsUnreadCount({}, axiosCancelToken).then((res) => {
      const notificationsCount = res.unread;
      this.props.storeNotificatonCount(notificationsCount);
      // setTimeout(() => {
      //   this.getKeyInteractions();
      // }, 3 * 60 * 1000);
    });
  }

  // fetchCustomFilters = () => {
  //   try {
  //     if(localStorage.getItem("customFilterList")) {
  //       store.dispatch({ type: ReduxActionTypes.CACHE_CUSTOM_FILTERS, payload: { value : JSON.parse(localStorage.getItem("customFilterList")) }});
  //     }
  //     setTimeout(() => {
  //       this.props.storeCustomFilterList();
  //     }, 150);
  //   }
  //   catch(ex) {
  //     setTimeout(() => {
  //       this.props.storeCustomFilterList();
  //     }, 150);
  //   }
  // }

  showLiveChat = () => {     
    if(window._chatlio.isShown()) {
      window._chatlio.hide();
      this.setState({ showLiveChatRoom: false });
    }
    else {
      window._chatlio.show({ expanded: true });
      this.setState({ showLiveChatRoom: true });
    }
  }
  render() {
    const menuItems = MENU_ITEMS.filter((item)=>{
      return !item.access || item.access(this.props.app_user);
    })
    return (              
        <MenuWrapper>
          <IconWrapper>
            <LevelIcon />
          </IconWrapper>
          <MenuItems items={menuItems} groups={this.props.app_user.groups}/>          
          {this.state.showLiveChatIcon && (this.props.app_user.is_superuser || this.props.app_user.is_manager) && <MenuItemWrapper onClick={this.showLiveChat} active={this.state.showLiveChatRoom} title="Live Level Assist">
            <MenuItem>
              <LiveChatIcon />
            </MenuItem>
          </MenuItemWrapper>}
        </MenuWrapper>
    );
  }
}

const mapStateToProps = state => ({ app_user: state.app_user });

const mapDispatchToProps = (dispatch) => ({
  storeNotificatonCount: (notificationCount) => {
    dispatch({ 
      type: ReduxActionTypes.STORE_NOTIFICATION_COUNTS,
      payload: {
        notificationCount
      }
    })
  },
  resetGlobalFilters: (user) => {
    dispatch(initializeGlobalFilters(user))
  },
  storeCustomFilterList: (fn) => {
    dispatch(getCustomFilters(fn));
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBar));
