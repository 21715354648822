import produce from "immer";

export const createReactFlowSlice = (set, get) => ({
  reactFlowInstance: null,
  onLoad: (reactFlowInstance) => {
    set(
      produce((state) => {
        state.reactFlowInstance = reactFlowInstance;
      })
    );
  },
});
