import React, { Component } from "react";
import { getPusherConfig } from "../config";
import WidgetTimeline from "./WidgetTimeline";
import { getConversationData, getLayoutData } from "./api/api";
import { addAnalyticsEvent } from "../utils";
import { connect } from "react-redux";
import './scss/agent-assist.scss';

const groupCardsByContextPlatforms = ["salesforce", "zendesk"];

class AgentAssist extends Component {
  constructor(props) {
    super(props);
    const platform = props?.queryParams?.platform || "";
    this.retryCount = 0;
    this.state = {
      timelineData: [],
      isActive: false,
      transcription_uuid: null,
      conversationId: -1,
      conversation_status: "",
      groupCardsByContext: groupCardsByContextPlatforms.includes(platform),
      isLoading: true,
    };
  }

  listenPusher = (channelId) => {
    const pusher = getPusherConfig();
    const channel = pusher.subscribe(channelId);
    console.log("Subscribed to pusher, channel Id:", channelId);
    channel.bind("rules", (data) => {
      console.log("rules PUSHER", data);
      this.handleItemReceive(data);
    });
    channel.bind("faqs", (data) => {
      console.log("faq PUSHER", data);
      this.handleItemReceive(data);
    });
    channel.bind("transcript", (data) => {
      console.log("transcript PUSHER", data);
    })
    channel.bind("knowledge", (data) => {
      console.log("knowledge PUSHER", data)
      this.handleItemReceive(data);
    })
    channel.bind("resource_follow_pusher", (data) => {
      console.log("resource_follow_pusher PUSHER", data)
      this.handleFollow(data);
    })
    channel.bind("tip_follow_pusher", (data) => {
      console.log("tip_follow_pusher PUSHER", data)
      this.handleFollow(data);
    })
    const { transcription_uuid} = this.state;
    channel.bind("start", (data) => {
      console.log("start", data);
      if(!transcription_uuid) {
        this.setState({
          isActive: true,
          timelineData: []
        }, () => {
          this.updateLiveConversationStatus(true);
        });
      }      
    });

    channel.bind("end", (data) => {
      console.log("end", data);
      if(!transcription_uuid || data.uuid === transcription_uuid) { 
        this.setState({
          isActive: false,
        });
      }
    });
  }

  addMatchToTimeline = (item, match) => {
    console.log("ADDING MATCH", item,match);
    let event_name = "";
    try {
        const data = JSON.parse(match.description);
        event_name= data.event_name;
    } catch(e) {}
    const { conversationId, conversation_status } = this.state;
    console.log("CARD ADDED TO TIMELINE", item)
    
    if(item && match) {
      addAnalyticsEvent(`Live Conversation`, {  
        conversationId: conversationId, 
        category: match?.category?.toUpperCase()||"", 
        type: "LIVE_CARD",
        is_conversation_tag: !!match.rule_id,
        moment_type: match.moment_type,
        title: match?.title ,
        conversation_status 
      });
    }
    const title = match?.title || match?.category?.toUpperCase() || "";
    let link = match?.link;
    if(title.includes("Verify customer identity") && link?.includes("botuser@thelevel.ai")) {      
      link = link.replace("botuser@thelevel.ai", this.props.user.email);
    }
    const speaker = match.utterances && match.utterances.agent_context ? "Agent" : "Customer";
    this.setState({
      timelineData: [{
        category: match.category.toUpperCase(),
        title: match.title || match.category.toUpperCase(),
        description: match.description || match.value,
        timestamp: item.timestamps ? item.timestamps.median : 0,
        created: item.created,
        link: link,
        user_feedback: null,
        event_name: event_name,
        id: match.id,
        match_uuid: match.match_uuid,
        clientAddedTime: match.clientAddedTime,
        transcription_id: match.transcription_id,
        speaker: speaker,
        transcriptCreatedAt: match.transcription_created_datetime, 
        search_group_id: match.search_group_id,
        followed: match.followed === undefined ? "Insufficient Info" : match.followed,
      }, ...this.state.timelineData]
    })
  }

  handleItemReceive = (item) => {
    console.log("PUSHER: item", JSON.stringify(item));
    let { matches } = item;
    const { transcription_uuid } = this.state;
    if(!transcription_uuid || item.uuid === transcription_uuid) { 
      matches.forEach(match => {
        match.clientAddedTime = (new Date()).getTime();
        this.addMatchToTimeline(item, match)              
      });
    }
  }

  handleFollow = (item) => {
    console.log("PUSHER: resource/tip follow", JSON.stringify(item));
    let timelineData = this.state.timelineData;
    item.forEach(match => {
      timelineData = timelineData.map((timelineItem) => {
        if(timelineItem.match_uuid === match.match_uuid) {
          timelineItem.followed = match.followed ? "Followed" : "Not Followed";
        }
        return timelineItem;
      })
    });
    this.setState({ timelineData });
  }

  handleSingleItemReceive = (item) => {
    console.log("PUSHER: item", JSON.stringify(item));
    let { match } = item;
    this.addMatchToTimeline(item, match)
  }

  handleTimelineData = (asrLog, conversationId, isActive, conversation_status, cb) => {
    const assistCards = asrLog.transcript_action_log || asrLog.agent_assist_card || [];
    this.setState({
      timelineData: assistCards.map((item) => {
        let event_name = "";
        try {
            const data = JSON.parse(item.description);
            event_name= data.event_name;
        } catch(e) {}
        // if(isActive && conversation_status === "1") {
        //   addAnalyticsEvent(`Live Conversation`, { conversationId: conversationId, category: item.category.toUpperCase(), type: "LIVE_CARD" });          
        // }
        console.log('is Active',isActive);
        console.log('Conversation status', conversation_status)
        console.log(assistCards);
        const title = item.title || item.category.toUpperCase() || "";
        let link = item.link;
        if(title.includes("Verify customer identity") && link?.includes("botuser@thelevel.ai")) {      
          link = link.replace("botuser@thelevel.ai", this.props.user.email);
        }
        const speaker = item.utterances && item.utterances.trigger_speaker === "customer" ? "Customer" : "Agent";
        const userFeedback = item.assist_card_feedback?.length ? item.assist_card_feedback.find((item) => {
          return item.user === `${this.props.user.first_name} ${this.props.user.last_name}`;
        }) : null;
        return {
          category: item.category.toUpperCase(),
          title: title,
          description: item.description,
          timestamp: item.timestamps ? item.timestamps.median : 0,
          created: item.created,
          link: link,
          text: item.text,
          user_feedback: item.user_feedback || userFeedback,
          tagged_for_qa: item.tagged_for_qa,
          event_name: event_name,
          id: item.id,
          transcription_id: item.transcription_id || item.id,
          speaker: speaker,
          transcriptCreatedAt: item.transcription_created_datetime,
          search_group_id: item.search_group_id,
          followed: item.followed === undefined ? "Insufficient Info" : item.followed,
        }
      })
    }, () => {
      cb();
    })
  }

  updateLiveConversationStatus = (isLive) => {
    const conversationStatusElement = document.getElementById("conversation-status");
    if(conversationStatusElement) {
      conversationStatusElement.style.background = isLive ? "#27AE60" : "transparent";
    }
  }

  getConversationData = (platform, platformId) => {
    const channelId = this.props.user.channel;
    this.retryCount = this.retryCount + 1;
    getConversationData(platform, platformId).then((res) => {
      const data = res.data;
      const uniqueId = `${platform}#${platformId}`;
      const conversationId = res.data.banking_conversation_id || uniqueId;
      const conversation_status = res.data.conversation_status;
      const isActive = conversation_status === "0" || conversation_status === "1";
      this.handleTimelineData(data, conversationId, isActive, conversation_status, () => {
        if(conversation_status === "1") {
          addAnalyticsEvent('Live Conversation', { conversationId: `${platform}#${platformId}`, isLiveConversation: conversation_status === "1", conversationStatus: conversation_status,  uniqueId: uniqueId });          
        }
        this.updateLiveConversationStatus(isActive);
        this.setState({ isLoading: false, isActive: isActive, transcription_uuid: data.transcription_uuid, conversationId: conversationId, conversation_status: conversation_status  }, () => {
          this.listenPusher(channelId);    
        });
      });            
    }, () => {
      if(this.retryCount < 5) {
        setTimeout(() => {
          this.getConversationData(this.props.queryParams.platform, this.props.queryParams.platformId);
        }, 60*1000); //retry after 60 seconds
      }
    })
  }

  getLayout = () => {
    getLayoutData().then((res) => {
      this.setState({ showCommonTab: res.resources_first_tab, isLoading: false });      
    });
  }

  componentDidMount() {
    const channelId = this.props.user.channel;
    // this.getLayout();
    if (this.props.isHistorical) {
      if (this.props.asrLog) {
        this.handleTimelineData(this.props.asrLog);
      }
    } else {
      if(this.props.queryParams && this.props.queryParams.platform && this.props.queryParams.platformId) {
        this.getConversationData(this.props.queryParams.platform, this.props.queryParams.platformId);
      }
      else {
        this.listenPusher(channelId);
      }
    }
  }

  updateUserFeedback = (userFeedback, itemId) => {
    let timelineData = this.state.timelineData;
    this.setState({
      timelineData: timelineData.map((item) => {
        if(item.id === itemId) {
          item.user_feedback = userFeedback;
        }
        return item;
      })
    });
  }

  render() {
    const { timelineData, isActive, conversationId, conversation_status, groupCardsByContext } = this.state;
    const { isDebug, startTime, isAgent } = this.props
    // if(this.state.isLoading) {
    //   return null;
    // }
    return (
      <WidgetTimeline
        groupResults
        showCommonTab
        data={timelineData}
        isLiveConversation={isActive}
        isDebug={isDebug}
        isAgent={isAgent}
        startTime={startTime}
        conversationId={conversationId}
        conversation_status={conversation_status}
        updateUserFeedback={this.updateUserFeedback}
        groupCardsByContext={groupCardsByContext}
      />      
    );
  }
}

const mapStateToProps = state => ({ user: state.app_user });

export default connect(mapStateToProps)(AgentAssist);
