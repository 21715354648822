import React from 'react';
import moment from "moment";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import store from "../store";
import { notification } from 'antd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import appConfigs from "../config";
import { hasManagerRights, hasQAAuditorRights } from '../AnalyticsDashboard/config';
import { ReactComponent as SortIcon } from "./../assets/positive-icon.svg";
import {
  SortableHeaderWrapper,
  DescendingIconWrapper,
  SortIconWrapper,
  AscendingIconWrapper,
} from "../user-dashboard/wrappers";
import * as QueryString from 'query-string';
import { removeItemFromStore } from '../utils';
//var CryptoJS = require("crypto-js");


export const dateRange = {
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    THIS_WEEK: "This week",
    THIS_MONTH: "This month",
    THIS_YEAR: "This year",
    ALL_TIME: "All Time"
}

export const dateRangeOptions = {
    "0": dateRange.TODAY,
    "1": dateRange.YESTERDAY,
    "7": dateRange.THIS_WEEK,
    "30": dateRange.THIS_MONTH,
    "365": dateRange.THIS_YEAR,
    "": dateRange.ALL_TIME
}

export const getDateRangeValues = (values) => {
    let to_date = new Date();
    let from_date;

    if (typeof values === "string") {
        if (values === "") {
            from_date = new Date(0);
        }
        else if (values === "0") {
            from_date = new Date();
            from_date.setHours(0,0,0,0);
        } else if (values === "1") {
            to_date = new Date();
            from_date = new Date();
            to_date.setHours(0,0,0,0);
            from_date.setHours(-24,0,0,0);
        } else {
            from_date = new Date();
            from_date.setHours(-24*Number(values),0,0,0);
        }
        
    } else if (typeof values == "object") {
        to_date = values.map(x=>x.toDate())[1]
        from_date = values.map(x=>x.toDate())[0]
    }
    return {
        from_date, to_date
    }
}

export const getCompareRangeOptions = (dateRange, granularity) => {
    let dateRangeValues = getDateRangeValues(dateRange);
    let options = {}

    if(granularity === "day") {
        options = [
            {
                id: "week",
                label: "Previous week",
                compareTo: {
                    start: new Date(new Date(dateRangeValues.from_date).setHours(-24*7,0,0,0)),
                    stop: new Date(new Date(dateRangeValues.to_date).setHours(-24*7,0,0,0))
                }
            },
            {
                id: "month",
                label: "Previous month",
                compareTo: {
                    start: new Date(new Date(dateRangeValues.from_date).setHours(-24*30,0,0,0)),
                    stop: new Date(new Date(dateRangeValues.to_date).setHours(-24*30,0,0))
                }
            }
        ]

        // ADD DATE LABEL AND VALUE
        options = options.map(option => {
            let dateLabel = "";
            let value = [
                [dateRangeValues?.from_date, dateRangeValues?.to_date],
                [option?.compareTo?.start, option?.compareTo?.stop]
            ];

            if(option.compareTo.start.toDateString() == option.compareTo.stop.toDateString()) {
                let startValue = option?.compareTo?.start?.toDateString().split(" ");
                dateLabel = `${startValue[1]} ${startValue[2]}, ${startValue[3]}`
            } else {
                let startValue = option?.compareTo?.start?.toDateString().split(" ");
                let stopValue = option?.compareTo?.stop?.toDateString().split(" ");
                dateLabel = `${startValue[1]} ${startValue[2]}, ${startValue[3]} - ${stopValue[1]} ${stopValue[2]}, ${stopValue[3]}`
            }

            return {
                ...option,
                dateLabel,
                value
            }
        })
    }

    return options;
}

function getTimezoneOffset() {
    const offset = (new Date()).getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset)/60);
    const mins =  Math.abs(offset)%60;
    return `${offset<0?"-":"+"}${hours <= 9 ? '0' + hours : hours}:${mins <= 9 ? '0'+mins : mins }`;
}

export const timezone = getTimezoneOffset();

export const secondsToTime = (secs, readable, channel) => {
    if ( !secs ) {
        return "0 sec"
    }
    var hours = Math.floor(secs / (60 * 60));
    var days = Math.floor(hours/24);
    hours = hours - 24 * days;
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    if(days !==0 ) {
        return `${days>0 ? days + (days > 1 ? "days " : "day ") : ""}${hours ? hours + (hours > 1 ? "hrs " : "hr ") : ""}`;
    } 
    return `${hours ? hours + (hours > 1 ? "hrs " : "hr ") : ""}${minutes ? minutes + (minutes > 1 ? "mins " : "min ") : ""}${seconds && !hours && !minutes ? seconds + (seconds > 1 ? "secs" : "sec") : ""}`;    
}

export const hoursToTime = (hrs) => {
    const secs = hrs * 3600;
    if ( !secs || secs < 60) {
        return "0 hrs"
    }
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    return `${hours ? hours + "hrs " : ""}${minutes ? minutes + "mins " : ""}`    
}

export const formatTime = (date) => {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getColorWithOpacity = (color, opacity) => {
    color = color.slice(1);
    const val = parseInt(color, 16);
    const r = (val >> 16) & 255;
    const g = (val >> 8) & 255;
    const b = val && 255;
    return `rgba(${r},${g},${b},${opacity})`;
}

export function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}

const getTimeString = (start, end) => {
    let start_date = moment()
        .set("hour", start)
        .set("minute", 0)
        .set("second", 0)
        .toDate();
    let end_date = moment()
        .set("hour", end)
        .set("minute", 0)
        .set("second", 0)
        .toDate();
    let startTime = start_date.getUTCHours() * 3600 + start_date.getUTCMinutes() * 60 + start_date.getUTCSeconds();
    let endTime = end_date.getUTCHours() * 3600 + end_date.getUTCMinutes() * 60 + end_date.getUTCSeconds();
    return `${startTime}-${endTime}`;
}

export const getApiFilters = (filters, page) => {
    const stateFilters = { ...filters };
    let userFilters = {
        //...stateFilters,
        page
    }

    //delete the keys which are not meant to be sent in the final api call or the one's which are to be sent in array form 
    
    if(stateFilters.searchValue) {
        userFilters.search = stateFilters.searchValue;
        delete stateFilters.searchValue;  
    }
    if(stateFilters.sort_key) {
        userFilters.order_by = `${stateFilters.sort_order === "dsc" ? "-" : ""}${stateFilters.sort_key}`;
        delete stateFilters.sort_key;  
        delete stateFilters.sort_order;
    }

    if(stateFilters.call_duration && stateFilters.call_duration.length) {
      stateFilters.call_duration = [stateFilters.call_duration[0] * 60, stateFilters.call_duration[1] * 60];
    }
    if(stateFilters.negative_sentiment && stateFilters.negative_sentiment.length) {
      stateFilters.negative_moments_count_in=[stateFilters.negative_sentiment[0], stateFilters.negative_sentiment[1]];
      delete stateFilters.negative_sentiment;
    }
    const rules = [];
    if(stateFilters.action) {
        rules.push(stateFilters.action);
        delete stateFilters.action;  ////not required in the final api call
    }
    if(stateFilters.flag) {
        rules.push(stateFilters.flag);
        delete stateFilters.flag; //not required in the final api call
    }
    if(rules.length) {
        userFilters.rules = rules.join(",");
    }

    if(stateFilters.date) {
        if(Array.isArray(stateFilters.date) && stateFilters.date.length === 2) {
            userFilters = {
                ...userFilters,
                start_date: [...stateFilters.date][0].clone().set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                end_date: [...stateFilters.date][1].clone().add(1, "day").set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            };
        }
        else {
            let end = 0;
            let start = Number(stateFilters.date);
            if(stateFilters.date === "1") {
                end = -1;
            }
            end = end + 1;
            let start_date = moment()
                .subtract(start, 'days')
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .toDate();
            let end_date = moment()
                .add(end, 'days')
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .toDate(); 
            userFilters = {
                ...userFilters,
                start_date: start_date.toISOString(),
                end_date: end_date.toISOString(),
            };
        }

        delete stateFilters.date;   //to be handled in array form, won't be converted to String by Object.entries HOF.
    } 
    //not handled in a proper way(need to change)
    if(stateFilters.evaluation_date) {
      if(Array.isArray(stateFilters.evaluation_date) && stateFilters.evaluation_date.length === 2) {
          userFilters = {
              ...userFilters,
              evaluation_start_date: [...stateFilters.evaluation_date][0].clone().set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              evaluation_end_date: [...stateFilters.evaluation_date][1].clone().add(1, "day").set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          };
      }
      else {
          let end = 0;
          let start = Number(stateFilters.evaluation_date);
          if(stateFilters.evaluation_date === "1") {
              end = -1;
          }
          end = end + 1;
          let evaluation_start_date = moment()
              .subtract(start, 'days')
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .toDate();
          let evaluation_end_date = moment()
              .add(end, 'days')
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .toDate(); 
          userFilters = {
              ...userFilters,
              evaluation_start_date: evaluation_start_date.toISOString(),
              evaluation_end_date: evaluation_end_date.toISOString(),
          };
      }

      delete stateFilters.evaluation_date;   //to be handled in array form, won't be converted to String by Object.entries HOF.
  } 

    if(stateFilters.dateRange && stateFilters.dateRange.length === 2) {
        userFilters = {
            ...userFilters,
            start_date: stateFilters.dateRange[0].clone().set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            end_date: stateFilters.dateRange[1].clone().add(1, "day").set("hour", 0).set("minute", 0).set("second", 0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),            
        };
        delete stateFilters.dateRange;
    }        
    userFilters.timezone = timezone;
    if(stateFilters.time) {
        switch (stateFilters.time) {
            case "morning":
                userFilters.time_range = getTimeString(6, 11);
                break;
            case "afternoon":
                userFilters.time_range = getTimeString(11, 16);
                break;
            case "evening":
                userFilters.time_range = getTimeString(16, 21);
                break;
            case "overnight":
                userFilters.time_range = getTimeString(21, 6);
                break;
            default:
                break;
        }

        delete stateFilters.time;
    }
    
    if(stateFilters.expectationtype) {
        delete stateFilters.expectationtype;
    }
    //used to handle multiselect values, basically converts array values to string.
    Object.entries(stateFilters).forEach(( [key, value] ) => ( value  && value.length > 0 ) || ( value===true ) || (typeof(value) === "number") ? ( key==="conversation_tag" ? userFilters.rule = String( stateFilters[key] )  : key==="customFilterList" ? userFilters[key] = stateFilters[key] : userFilters[key] = String( stateFilters[key] ) ) : null);
    
    return userFilters;
};


export const logoutUser = () => {
    window.heap && window.heap.resetIdentity && window.heap.resetIdentity();
    try {
        removeItemFromStore("currentPath");
        removeItemFromStore("isReloaded");
        removeItemFromStore("cachedFilterApiData");
        removeItemFromStore("subdomain");
    } catch(e) {}
}


export const handleCubeFilterUpdate = (key, value, idValues, expectationtype) => {
    let globalQAFilters = {};
    switch(key) {
        case "user": {
            if (!Array.isArray(value)) {
                store.dispatch({
                    type: "SET_AGENT_ANALYTICS",
                    payload: {
                        value
                    }
                })
                globalQAFilters = {
                    ...globalQAFilters,
                    user: [value],
                    agents: [value]
                }
            } else {
                globalQAFilters = {
                    ...globalQAFilters,
                    user: value ? value : undefined,
                    agents: value ? value : undefined
                }
            }
            break
        }
        case "date": {
            globalQAFilters = {
                ...globalQAFilters,
                date: value,
                dateRange: value ? dateRangeOptions[value] : dateRangeOptions[0],
                dateRangeValues: getDateRangeValues(value),
                call_duration: [],
                negative_sentiment: []
            }
            break
        }

        case "conversation_tag" : {
            globalQAFilters = {
                ...globalQAFilters,
                [key]: value,
                "conversation_tag_id": idValues,
                "expectationtype": expectationtype
            }
        }
        default: {
            globalQAFilters = {
                ...globalQAFilters,
                [key]: value
            }
            break
        }
    }
    return globalQAFilters;
}

export const userHasManagerRights = (user) => {
  return user.is_manager || user.is_superuser
}

export const filtersFromURLParams = (queryParams) => {
    const {...newFilters}  = queryParams;
    if (!newFilters.date) {
        
    } else if (
      queryParams.date &&
      queryParams.date.includes("[") &&
      queryParams.date.includes("]")
    ) {
      const dates = JSON.parse(queryParams.date);
      newFilters.date = [moment(dates[0]), moment(dates[1])];
      newFilters.dateRangeValues = getDateRangeValues(newFilters.date);
    } else {
        const value = newFilters.date;
        newFilters.dateRangeValues = getDateRangeValues(value);
        newFilters.dateRange = value ? dateRangeOptions[value] : dateRangeOptions[0];
    }
    
    if (!newFilters.evaluation_date) {
        
    } else if (
      queryParams.evaluation_date &&
      queryParams.evaluation_date.includes("[") &&
      queryParams.evaluation_date.includes("]")
    ) {
      const dates = JSON.parse(queryParams.evaluation_date);
      newFilters.evaluation_date = [moment(dates[0]), moment(dates[1])];
      //newFilters.dateRangeValues = getDateRangeValues(newFilters.date);
    } else {
        const value = newFilters.evaluation_date;
        // newFilters.dateRangeValues = getDateRangeValues(value);
        // newFilters.dateRange = value ? dateRangeOptions[value] : dateRangeOptions[0];
    }

    if (newFilters.user) {
        newFilters.agents = newFilters.user;
    }

    if(newFilters.conversation_tag && !Array.isArray(newFilters.conversation_tag)) {
        newFilters.conversation_tag = [newFilters.conversation_tag];
    }

    if(newFilters.conversation_tag_id && !Array.isArray(newFilters.conversation_tag_id)) {
        newFilters.conversation_tag_id = [newFilters.conversation_tag_id];
    }

    if(newFilters.expectationtype && !Array.isArray(newFilters.expectationtype)) {
        newFilters.expectationtype = [newFilters.expectationtype];
    }

    if(newFilters.followed && newFilters.expectationtype) {
        delete newFilters.followed;
    }
    
    if(newFilters.qa_status && !Array.isArray(newFilters.qa_status)) {
      newFilters.qa_status = newFilters.qa_status;
    }

    if(newFilters.tagged_for_qa && newFilters.tagged_for_qa === "true") {
      newFilters.tagged_for_qa = true;
    }
    store.dispatch({
        type: ReduxActionTypes.SET_GLOBAL_FILTERS,
        payload: {
            ...newFilters,
        },
    })
   return newFilters;
}

export const getCalibrationConfig = () => {
  return window.location.pathname.includes("qa-calibrations");
}

export const getTaskQueueConfig = () => {
  return window.location.pathname.includes("tasks");
}

export const initializeGlobalFilters = (user) => (dispatch, getState) => {
    let dateValue= !getCalibrationConfig() && !getTaskQueueConfig() ? appConfigs.DEFAULT_DATE_FILTER : "";
    let channelValue= appConfigs.DEFAULT_CHANNEL;

    const redirectUrl= getState().app_user.redirectUrl;

    if(redirectUrl.includes("qa-calibrations")) {
      let queryParams = QueryString.parse(redirectUrl);
      if(!queryParams.date) {
        dateValue= "";
      }
    }


    dispatch({
        type: ReduxActionTypes.RESET_GLOBAL_FILTERS
    })

    let payload = {
        date: dateValue,
        dateRange: dateValue ? dateRangeOptions[dateValue] : dateRangeOptions[0],
        dateRangeValues: getDateRangeValues(dateValue),
        ...(channelValue ? {channel: channelValue} : {}),
    }

    if (user && !hasManagerRights() && !hasQAAuditorRights()) {
        payload = {
            ...payload,
            agents: [user.id],
            user: [user.id]
        }
    }
    dispatch({
        type: ReduxActionTypes.SET_GLOBAL_FILTERS,
        payload
    })
}

export const sortableHeaderColumn = (title, itemKey, sortable, updateSortable) => {
  const handleSorting = () => {
    if (sortable.key !== itemKey) {
      updateSortable(itemKey, "dsc");
    } else {
      if (sortable.order === "dsc") {
        updateSortable(itemKey, "asc");
      } else {
        updateSortable();
      }
    }
  };
  const { order, key } = sortable ? sortable : {};

  return (
    <SortableHeaderWrapper
      order={itemKey === key ? order : ""}
      onClick={handleSorting}
    >
      {title}
      {itemKey === key && order === "dsc" && (
        <DescendingIconWrapper>
          <SortIcon />
        </DescendingIconWrapper>
      )}
      {itemKey === key && order === "asc" && (
        <AscendingIconWrapper>
          <SortIcon />
        </AscendingIconWrapper>
      )}
      {(itemKey !== key || !order) && (
        <SortIconWrapper>
          <SortIcon />
        </SortIconWrapper>
      )}
    </SortableHeaderWrapper>
  );
};

const COLOR_CONSTANTS = ["#0B4B93", "#6099D9", "#FC687A", "#F2DA92", "#1EA3FA", "#44C2A8", "#27AE60", "#E89244", "#DB554D", "#BB6BD9", "#18BFB5", "#2F80ED", "#2D9CDB", "#56CCF2"];

export const getRandomColor = (str='',seed='') => {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
  h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
  const hash = 4294967296 * (2097151 & h2) + (h1>>>0);
  let colors = COLOR_CONSTANTS;
  return colors[hash%(colors.length)];
}

export const openNotification = (placement, message) => {
  notification.info({
    message: `${message}`,
    // description:
    //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    placement,
    icon : <CheckCircleIcon style={{color: "#27AE60"}}/>,
    duration: 1.5
  });
};

export const encryptAndSendToStorage = (data) => {
  // if(process.env.NODE_ENV === "development") {
  //   return JSON.stringify(data);
  // }
  return JSON.stringify(data);
  // let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_STORAGE_KEY).toString();
  // return ciphertext;
}

export const decryptDataFromStorage = (data) => {
  // if(process.env.NODE_ENV === "development") {
  //   return JSON.parse(data);
  // }
  return JSON.parse(data);
  // let bytes  = CryptoJS.AES.decrypt(data, process.env.REACT_APP_STORAGE_KEY);
  // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // return decryptedData;
}

export const removeDuplicatesFromArrayOfObject = (data, key) => {
  if(data?.length) {
    let setObj = new Set(); // create key value pair from array of array
    let result = data.reduce((acc, item)=>{
      if(!setObj.has(item[key])){
        setObj.add(item[key], item)
        acc.push(item)
      }
      return acc;
    },[]);//converting back to array from mapobject

    return result;
  }
  return [];
}
