import React, { useState, useEffect, useRef } from "react";
import { Chart, Axis, Geom, Tooltip, Interaction, Legend } from "bizcharts";
import { cubeJSApi, cubeApi } from "./api";
import {
  createStackedChart,
  getMeasureText,
  getTooltipText,
  roundOff,
  shouldEnableDrilldown,
} from "./utils";
import {
  CustomTooltipWrapper,
  TooltipWrapper,
  TooltipSubWrapper,
  TextWrapper,
  ButtonWrapper,
  EmptyStateWrapper,
} from "./components/wrapper";
import { message, Spin } from "antd";
import { qualityAssessmentQueryBuilder } from "./query-builder";
import {
  getTimeRange,
  formatMultiDimensionValue,
  formatRawValue,
  getColor,
  getMultiGraphColor,
  getLabel,
  placeHolderChartData,
  sortChartData,
  setCompareChartData
} from "./chart-renderer/config";
import EmptyState from "../components/common/EmptyState";
import { handleDrilldown } from "./utils";
import { queryOptions } from "./dynamic-query-builder";
import { useSelector } from "../store";
import { addAnalyticsEvent } from "../utils";
import { HeapAnalyticsEvent } from "../components/common/HeapAnalyticsEvent";
import Constants from "../CompanySetup/utils/constants";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import { getSchemaName } from "../api/api";
import styled from "@emotion/styled/macro";
import ExportDynamicChart from "../components/common/ExportDynamicChart";
import appConfigs from '../config';
import { checkDefinedValue, checkUserRights, userType } from "../utils/utilities";

import { QueryRenderer } from '@cubejs-client/react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Row, Col, Statistic, Table } from 'antd';
import { renderTable, exportTableData } from "./components/query-table";

const MeasureTooltipRowWrapper = styled.div`
  width: 100%;
  display: flex;
  min-width: 100px;
  margin-top: 0.5rem;
`;

const CustomTooltip = ({ title, items, isCompareDateRange, isTimeSeries, pivotConfig }) => {
  return (
    <TooltipSubWrapper>
      {items.map((item, index) => (
        <>
          {
            (isCompareDateRange && Array.isArray(pivotConfig.x) && pivotConfig.x.length > 0) ?
              <>
                <TextWrapper>
                  <div style={{marginBottom: "8px"}}>
                    {item.data.range}
                  </div>
                </TextWrapper>
                <TextWrapper>
                  <span
                    style={{
                      backgroundColor: item.color,
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: 8,
                    }}
                  ></span>
                  {getMeasureText(item.data.dimension)} : {roundOff(item.value)}
                </TextWrapper>
              </>
            :
            <>
              {
                (isTimeSeries || item?.title) && 
                  <h4>{item.title}</h4>
              }
              <TextWrapper>
                <span
                  style={{
                    backgroundColor: item.color,
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: 8,
                  }}
                ></span>
                {getMeasureText(item.name)} : {roundOff(item.value)}
              </TextWrapper>
            </>
          }
        </>
      ))}
    </TooltipSubWrapper>
  );
};

const stackedChartData = (resultSet, pivotConfig, query, segments, isTimeSeries, isCompareDateRange) => {
  let data;
  
  if(isCompareDateRange) {
    let firstCompareRange = resultSet.decompose()[0].rawData()[0].compareDateRange;
    let secondCompareRange = resultSet.decompose()[1].rawData()[0].compareDateRange;

    if(Array.isArray(pivotConfig.x) && pivotConfig.x.length > 0) {
      data = resultSet.decompose().map((set, index) => {
        return set.pivot()
          .map(({ xValues, yValuesArray }) => {
            return yValuesArray.map(([yValues, m]) => { 
              return {
                x: formatMultiDimensionValue(xValues, pivotConfig.x, query.isTimeSeries, {...segments}, true, isCompareDateRange),
                range: getTimeRange(set.rawData()[0]?.compareDateRange),
                color: set.rawData()[0]?.compareDateRange === firstCompareRange ? "1" : "2",
                dimension: set.axisValuesString(yValues, ', '),
                measure: m && Number.parseFloat(m),
              }
            })
          })
          .reduce((a, b) => a.concat(b), [])
      })
      data = data.flat();
      data = setCompareChartData(data, getTimeRange(firstCompareRange));
    } else {
      data = resultSet.decompose().map((set, index) => {
        return set.pivot()
          .map(({ xValues, yValuesArray }) => {
            return yValuesArray.map(([yValues, m]) => { 
              return {
                x: xValues?.length ? formatMultiDimensionValue(xValues, pivotConfig.x, query.isTimeSeries, {...segments}, true, isCompareDateRange) : formatMultiDimensionValue(set.rawData()[0]?.compareDateRange, pivotConfig.x, query.isTimeSeries, {...segments}, true, isCompareDateRange),
                color: set.axisValuesString(yValues, ', '),
                measure: m && Number.parseFloat(m),
              }
            })
          })
          .reduce((a, b) => a.concat(b), [])
      }) 
      data = data.flat();
    }
  } else {
    data = resultSet
      .pivot()
      .map(({ xValues, yValuesArray }) => 
        yValuesArray.map(([yValues, m]) => { 
          return {
            x: xValues?.length > 0 ? formatMultiDimensionValue(xValues, pivotConfig.x, isTimeSeries, {...segments}, true) : resultSet.axisValuesString(xValues, ', '),
            color: resultSet.axisValuesString(yValues, ', '),
            measure: m && Number.parseFloat(m),
          }
        })
      )
      .reduce((a, b) => a.concat(b), []);
  }
  
  return data;
};

const BarChartRenderer = ({ resultSet, pivotConfig, query, segments, exportCSV, isTimeSeries, isCompareDateRange }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet, pivotConfig, query, segments, isTimeSeries, isCompareDateRange),
    [resultSet.serialize()]
  );

  const stacked = !(pivotConfig.x || []).includes('measures');

  const labelFormatter = (text, item, index) => { 
    if(isCompareDateRange && Array.isArray(pivotConfig.x) && pivotConfig.x.length > 0) {
      let firstCompareRange = resultSet.decompose()[0].rawData()[0].compareDateRange;
      let secondCompareRange = resultSet.decompose()[1].rawData()[0].compareDateRange;
      let labelText = text == 1 ? firstCompareRange : secondCompareRange;
      return getTimeRange(labelText);
    }
    return getLabel(text, data, { ...segments });
  }

  const formatYValue = (val) => {
    return val > 1000 ? `${val / 1000}K` : roundOff(val);
  };

  return (
    <Chart
      animate={false}
      scale={{
        x: {
          tickCount: 30,
        },
      }}
      autoFit
      height={400}
      data={query.isTimeSeries || isCompareDateRange ? data : sortChartData(data)}
      forceFit
    >
      <div
        className="d-flex justify-content-end"
        style={{ position: "absolute", top: "-10px", right: 0 }}
      >
        <ExportDynamicChart
          fetchData={exportCSV}
          alreadyParsed={true}
        />
      </div>

      <Axis 
        name="x"
        line={{
          style: {
            stroke: "#000",
            fill: "#ffffff",
            // lineDash: [2, 2, 2],
            lineWidth: 1,
          },
        }}
        label={{
          // formatter: formatXValue,
          style: {
            fill: "#000000", // 文本的颜色
            fontSize: 10,
            textAlign: "right",
          },
          rotate: -Math.PI /3,
          autoHide: false,
          autoEllipsis: false,
          autoRotate: false,
        }}
        grid={{
          line: {
            // 当line为null时则不展示网格线
            type: "line", // 网格线类型 line circle polygon
            style: {
              stroke: "#ffffff", // 网格线的颜色
              lineWidth: 1, // 网格线的宽度复制代码
              // lineDash: [4, 4] // 网格线的虚线配置，第一个参数描述虚线的实部占多少像素，第二个参数描述虚线的虚部占多少像素
            },
          },
        }}
      />
      <Legend
        name="color"
        position="top-left"
        offsetY={"100px"}
        itemName={{ formatter: labelFormatter }}
      />
      <Axis
        name="Measure"
        label={{ formatter: formatYValue }}
        title={{
          style: {
            displat: "none",
            fontSize: 10,
            textAlign: "center",
            fill: "#999",
            fontWeight: "bold",
            margin: 10,
          },
        }}
        line={{
          style: {
            stroke: "#000",
            fill: "#ffffff",
            // lineDash: [2, 2, 2],
            lineWidth: 1,
          },
        }}
        grid={{
          line: {
            // 当line为null时则不展示网格线
            type: "line", // 网格线类型 line circle polygon
            style: {
              stroke: "#EFEFEF", // 网格线的颜色
              lineWidth: 1, // 网格线的宽度复制代码
              // lineDash: [4, 4] // 网格线的虚线配置，第一个参数描述虚线的实部占多少像素，第二个参数描述虚线的虚部占多少像素
            },
          },
        }}
      />

      <CustomTooltipWrapper
        visible={true}
        enterable={true}
        lock={true}
      >
        {(title, items) => {
          return (
            <CustomTooltip 
              title={title}
              items={items}
              isCompareDateRange={isCompareDateRange}
              pivotConfig={pivotConfig}
            />
          )
        }}
      </CustomTooltipWrapper>

      <Geom
        type="interval"
        position="x*measure"
        color={[
          "color",
          (...args) => {
            return getMultiGraphColor(args, data[0].colorKeys, query.measures, pivotConfig.x, isCompareDateRange);
          },
        ]}
        adjust={'dodge'}
      />
    </Chart>
  );
};

const LineChartRenderer = ({ resultSet, pivotConfig, query, segments, exportCSV, isTimeSeries, isCompareDateRange }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet, pivotConfig, query, segments, isTimeSeries, isCompareDateRange),
    [resultSet]
  );

  const labelFormatter = (text, item, index) => getLabel(text, data, { ...segments });

  const formatYValue = (val) => {
    return val > 1000 ? `${val / 1000}K` : roundOff(val);
  };

  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <div
        className="d-flex justify-content-end"
        style={{ position: "absolute", top: "-10px", right: 0 }}
      >
        <ExportDynamicChart
          fetchData={exportCSV}
          alreadyParsed={true}
        />
      </div>
      
      <Legend
        name="color"
        position="top-left"
        offsetY={"100px"}
        itemName={{ formatter: labelFormatter }}
      />

      <Axis name="x" />
      <Axis name="measure" />

      <CustomTooltipWrapper
        visible={true}
        enterable={true}
        lock={true}
      >
        {(title, items) => {
          return (
            <CustomTooltip 
              title={title}
              items={items}
              isCompareDateRange={isCompareDateRange}
              isTimeSeries={isTimeSeries}
              pivotConfig={pivotConfig}
            />
          )
        }}
      </CustomTooltipWrapper>

      <Geom 
        type="line" 
        position="x*measure" 
        size={2} 
        color="color" 
      />
    </Chart>
  );
};

const renderChart = ({ resultSet, error, pivotConfig, query, chartType, segments, isTimeSeries, isCompareDateRange }) => {
  const exportCSV = () => {
    query.limit = 5000;
    
    return cubeJSApi().then((api) => {
      return api
        .load(query)
        .then((resultSet) => {
          const [header, rows] = exportTableData({resultSet, pivotConfig, segments, isCompareDateRange});
          let csv = header?.join(',') + '\r\n';

          rows.forEach(row => {
            csv += row?.join(',') + '\r\n';
          });

          return [csv, rows.length];
        })
        .catch((e) => {
          console.log(e);
          message.error("Invalid Query");
          return -1;
        });
    });
  }

  if (error) {
    message.error("Invalid Query");

    return (
      <EmptyStateWrapper>
        <Spin />
      </EmptyStateWrapper>
    )
  }

  if (!resultSet) {
    return (
      <EmptyStateWrapper>
        <Spin />
      </EmptyStateWrapper>
    )
  }

  if(isCompareDateRange) {
    if(!(resultSet.decompose()[0].rawData().length || resultSet.decompose()[1].rawData().length)) {
      return (
        <EmptyStateWrapper>
          <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
        </EmptyStateWrapper>
      )
    }
  } else {
    if(!resultSet.rawData().length) {
      return (
        <EmptyStateWrapper>
          <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
        </EmptyStateWrapper>
      )
    }
  }

  if(chartType === "line") {
    return (
      <LineChartRenderer 
        resultSet={resultSet} 
        pivotConfig={pivotConfig} 
        query={query} 
        segments={segments} 
        exportCSV={exportCSV} 
        isTimeSeries={isTimeSeries}
        isCompareDateRange={isCompareDateRange}  
      />
    )
  }

  if(chartType === "table") {
    return renderTable({query, resultSet, error, pivotConfig, segments, chartType, isCompareDateRange });
  }

  return (
    <BarChartRenderer 
      resultSet={resultSet} 
      pivotConfig={pivotConfig} 
      query={query} 
      segments={segments} 
      exportCSV={exportCSV} 
      isTimeSeries={isTimeSeries}
      isCompareDateRange={isCompareDateRange}
    />
  );
};

const ChartRenderer = ({query, chartType, segments, isTimeSeries, isCompareDateRange}) => {
  const cubejsApi = cubeApi();
  
  return (
    <QueryRenderer
        query={query}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={true}
        render={(props) => renderChart({
            ...props,
            chartType: chartType,
            pivotConfig: {
              "x": query?.dimensions,
              "y": [
                  "measures"
              ],
              "fillMissingDates": true,
              "joinDateRange": false
            },
            query,
            segments,
            isTimeSeries,
            isCompareDateRange
        })}
    />
  );
};

const DynamicMultiChart = (props) => {
    const [data, setData] = useState([]);
    const [resultSet, setResultSet] = useState([]);
    const [tooltipData, setTooltipData] = useState([]);
    const segments = useSelector((state) => state.dashboard.segments);
    const [filters, setFilters] = useState(props.filters);
    const [groupBy, setGroupBy] = useState(props.groupBy);
    const [query, setQuery] = useState();
    const [isTimeSeries, setIsTimeSeries] = useState(false);

    const globalFilters = useSelector((state) => {
        if (props.overrideFilters) return props.overrideFilters;
        return state.dashboard?.globalFilters;
    });
    
    const filterSegments = useSelector((state) => state.dashboard.filterSegments);
    // const selectedWorkspace = useSelector(
    //   (state) => state.app_user?.selectedWorkspace
    // );

    const [chartType, setChartType] = useState(props.chartType);
    const [drilldownQueries, setDrillDownQueries] = useState([]);
    const [isChartValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const customFilters = useSelector(state => state.dashboard.customFilters);

    const [measureText, setMeasureText] = useState(
        getMeasureText(props.query.measure)
    );
    const [tickCount, setTickCount] = useState(30);
    const additional_filters = props.query.filters;

    useEffect(() => {
      setGroupBy(props.groupBy);
      setFilters(props.filters);
      setIsTimeSeries(props.query.isTimeSeries);
    }, [props.filters, props.groupBy, props.query.isTimeSeries]);


    useEffect(() => {
        queryAndReload();
    }, [
        props.chartType,
        filters,
        groupBy,
        props.selectedChart,
        globalFilters,
        props.ordering,
    ]);

    useEffect(() => {
      if(query) {
        setIsLoading(false);
        setIsValid(true);
      }
    }, [query]);

    const prepareQuery = (groupBy, _cubeJSQuery) => {
      const _groupBy = ((groupBy) => {
        if (!groupBy) {
          return props.query.groupBy;
        } else {
          return groupBy;
        }
      })(groupBy);
      setGroupBy(_groupBy);
  
      let cubeJSQuery =
        _cubeJSQuery ||
        qualityAssessmentQueryBuilder({
          ...props.query,
          groupBy: _groupBy,
          filterbyValues: { ...filters },
          globalFilters,
          ordering: props.ordering,
        });
  
      if (additional_filters) {
        cubeJSQuery.filters = [...cubeJSQuery.filters, ...additional_filters];
      }
      cubeJSQuery.measures = cubeJSQuery.measures.map(measure => {
        // support queries having old measure names
        return measure.replace("BankingBankingconversation", "Conversation")
      })
      return cubeJSQuery;
    };

    const queryAndReload = (groupBy, _cubeJSQuery) => {
      let cubeJSQuery = prepareQuery(groupBy, _cubeJSQuery);
  
      if (
        JSON.stringify(query) !== JSON.stringify(cubeJSQuery) ||
        props.chartType !== chartType
      ) {
        setChartType(props.chartType);
        setIsLoading(true);
        setQuery(cubeJSQuery);
      }
    };

    const segmentsLoaded = () => {
      return segments !== undefined && segments !== {};
    };

    return (
      <div style={{ paddingTop: "0.8rem", paddingBottom: "2.8rem", height: "100%" }}>
        {isChartValid && !isLoading && segmentsLoaded() ? (
          <ChartRenderer 
            query={query} 
            chartType={props.chartType} 
            segments={segments} 
            isTimeSeries={isTimeSeries}
            isCompareDateRange={props.isCompareDateRange}
          />
        ) : isLoading || !segmentsLoaded() ? (
          <EmptyStateWrapper>
            <Spin />
          </EmptyStateWrapper>
        ) : (
          <EmptyStateWrapper>
            <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
          </EmptyStateWrapper>
        )}
      </div>
    )
}

const DynamicMultiChartRenderer = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <DynamicMultiChart {...props} />
  </ErrorBoundary>
);

export default DynamicMultiChartRenderer;
