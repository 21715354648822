import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { StyledSwitch } from '../../../components/common/styled-components';
import ZendeskIcon from "../../assets/zendesk-icon.png";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import ActionMenu from '../commons/ActionMenu';
import { getApiFilters } from '../../../utils/utilities';
import { useReq } from '../../../custom-hooks/useDispatchReq';
import { DASHBOARD_OPTIONS } from '../../../user-dashboard/utils';
import { useSelector } from 'react-redux';
import { getIcon, getIcon_Title } from './helpers';
import { DataSourceIcon } from './wrappers';
import { spliceText } from '../../../components/common/CustomFilterComponent/utils';

export const useColumns = (handleToggle, handleDelete, handleEdit) => {
  const tableData = useSelector(state => state.selfServeExternalData.tableData);
  const debounceToggleCall= useCallback(debounce(handleToggle, 250), [tableData]);
  const columns = [
    {
      title: "Display name",
      dataIndex: "pretty_name",
      key: "pretty_name",
      width: "25%",
      align: "center",
      render: (value, record) => (
       <div>{ spliceText(record.pretty_name, 25, true) }</div>
      ),
    },
    {
      title: "Data source",
      dataIndex: "data_source",
      key: "data_source",
      width: "25%",
      align: "center",
      render: (value, record) => (
          <DataSourceIcon>{ getIcon_Title(record?.source) }</DataSourceIcon>
      ),
    },
    {
      title: "Field name at source",
      dataIndex: "field_name",
      key: "field_name",
      width: "25%",
      align: "center",
      render: (value, record) => (
       <div>{record.field_name}</div>
      ),
    },
    {
      title: "Filters",
      dataIndex: "filters",
      key: "filters",
      width: "25%",
      align: "center",
      render: (value, record) => (
       <div>
         <StyledSwitch size = {"small"} checked = {record.is_filter} defaultChecked={record.is_filter} onChange={(val) => { debounceToggleCall(val, "is_filter", {...record}) }}/>
       </div>
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "25%",
      align: "center",
      render: (value, record) => (
        <div>
        <StyledSwitch size = {"small"} checked = {record.is_active} defaultChecked={record.is_active} onChange={(val) => { debounceToggleCall(val, "is_active", {...record}) }}/>
      </div>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (value, record) => {
        return (
          <ActionMenu 
            menuItems={[
              {
                title: "Rename",
                key: "edit",
                icon: EditIcon,
              },

              {
                title: "Delete",
                key: "delete",
                icon: DeleteIcon
              }
            ]}
            handleMenuClick={(key) => {
              if(key === "delete") { 
                handleDelete({...record});
              }
              else if(key === "edit") {
                handleEdit({...record});
              }
            }}
          />
        )
      }
    }  
  ];

  return { columns };
}

export const usePagination = (onDataFetched, beforeStartFetching, requestType, apiFunction, filters) => {
  const pageRef = useRef();
  const [page, setPage] = useState(0);
  const [callApi, cancelRequests] = useReq();
  const [data, setData] = useState([]);

  pageRef.current = page;
  
  const trackScrolling= useCallback(() => {
    let node = document.getElementsByClassName("ant-table-body")[0];
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    };
    if (node && isBottom(node)) {
      node.removeEventListener("scroll", trackScrolling);
      if(pageRef.current === 0) {
        setPage(2);
        return;
      }
      setPage(pageRef.current + 1)
    }
  }, []);

  const setStates = (type, page) => {
    beforeStartFetching && beforeStartFetching();
    if(!type) {
      setPage(0); 
    } else {
      setPage(page);
    }
    getTableData({
      ...filters
    }, page);  
  };

  const getTableData = (filters, page) => {
    let _page = page;
    const userFilters = getApiFilters(filters, _page);
    let node = document.getElementsByClassName("ant-table-body")[0];
    node && node.removeEventListener("scroll", trackScrolling);
    callApi(apiFunction, (res) => {
      const { next, results, previous, count } = res;
      if (next) {
        let node = document.getElementsByClassName("ant-table-body")[0];
        if (node) {
          node.addEventListener("scroll", trackScrolling);
        }
      }
      let _data = (page === 1) ? results : data.concat(results);  
      setData(_data);
      onDataFetched && onDataFetched({ data: _data, next, previous, count });
    }, (err) => {
      if(err && err.message === DASHBOARD_OPTIONS.CANCEL_REQUEST) {
        return;
      }
      onDataFetched && onDataFetched({  data: data?.length ? data : [], next: null, previous: null, count: data?.length });
    }, {
      userFilters
    }, () => {

    }, requestType);
  };

  useEffect(() => {
    setStates(null, 1);
    return () => cancelRequests();
  }, [filters]);

  useEffect(() => {
    if(page === 0) { 
      return;
    }
    
    setStates(DASHBOARD_OPTIONS.PAGINATION, page);

    return () => cancelRequests();
  }, [page]);

  return [page, setPage, data, trackScrolling, getTableData]
}
