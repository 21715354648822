import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { message } from "antd";
import FilterComponent from "./FilterComponent";
import { EvaluationStatus } from '../../conversation-list/configs';
import Constants from "../../CompanySetup/utils/constants";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";

const QaStatusFilter = ({value, handleChange, selectedWorkspace, qaStatusMultiSelect, ...rest}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [qaStatus, updateQaStatus] = useState(value);
    const [qaStatusOptions, updateQaStatusOptions] = useState([]);

    useEffect(() => {
      updateQaStatus(value !==null && value !== undefined && value !== "" ? `${value}` : '');
    }, [value]);

    useEffect(() => {
        setLoading(true);
        rest.options && rest.options().then(res => {
          updateQaStatusOptions([...res]);
        }).catch(err => {

        }).finally(() => {
          setLoading(false);
        });
    }, []);

    return (
        <FilterComponent
            width={180}    
            options={qaStatusOptions?.length > 0 ? qaStatusOptions : []}
            placeholder="All QA Statuses"
            value={qaStatus}
            maxTagCount= {1}
            capitalize={false}
            //multiSelect={qaStatusMultiSelect}
            showSearchIcon={true}
            handleChange={(value) => {
                updateQaStatus(value);
                handleChange(value);
            }}
            loading = {loading}
            showSearch={true}
            hideClear={false}
        />    
    );
}

//const mapStateToProps = state => ({ selectedWorkspace: state.app_user.selectedWorkspace, filtersSegmentList: state.dashboard.filterSegments });
export default QaStatusFilter;
