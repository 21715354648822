import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Radio } from 'antd';
import InputPhrases from "./InputPhrases";
import SelectIntent from "./SelectIntent";

const ScenarioPropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const ScenarioProperty = (props) => {
    const getInitialValue = (ruleProperties) => {
        let scenario = "";
        let value = "";
        let phrase = "";
        let topic = "";
        const options = props.options.map((item) => item.value);
        if(ruleProperties?.expectations) {
            for(let i=0; i<ruleProperties.expectations.length; i++) {
                const category = ruleProperties.expectations[i].category;
                if(options.includes(category)) {
                    scenario = category;
                    value = ruleProperties.expectations[i].value;
                    if(scenario === "phrase") {
                        phrase = value;
                    }                  
                    else {
                        topic = value;
                    }  
                }
            }
        }
        return { scenario, phrase, topic };
    }
    const initialValues = getInitialValue(props.ruleProperties);
    const [scenario, setScenario] = React.useState(initialValues.scenario);
    const [showValidations, toggleShowValidations] = React.useState(false);
    const [invalidPropsList, setInvalidPropsList] = React.useState([]);
    const handleChange = (event) => {
        const value = event.target.value;
        setScenario(value);
        setInvalidPropsList([value]);
    }
    const onPhraseChange = (value) => {
        props.handleChange({
            type: scenario,
            label: props.options.find((item) => item.value === scenario).label,
            value: value,
            speaker: props.speaker,
            invalid: value ? false : true,
        })
        if(value) {
            setInvalidPropsList([]);
        }
    }
    const onIntentSelect =  (value) => {
        props.handleChange({
            type: scenario,
            label: props.options.find((item) => item.value === scenario).label,
            value: value,
            speaker: props.speaker,
            invalid: value ? false : true,
        })
        if(value) {
            setInvalidPropsList([]);
        }
    }
    const phrases = initialValues.phrase ? initialValues.phrase.split(",").map((text, index) => {
        return {    
            id: `${index}`,
            text: text,
        }
    }) : [];
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])
    return (
        <ScenarioPropertyWrapper>
            <Radio.Group className="radio-group" name="radiogroup" defaultValue={scenario} onChange={handleChange}>
                {props.options.map((item) => {
                    return <Radio value={item.value}>{item.label}</Radio>
                })}                
            </Radio.Group>
            {scenario === "phrase" ? <InputPhrases showValidations={showValidations} phrases={phrases} onChange={onPhraseChange} /> : null}
            {scenario === "topic" ? <SelectIntent showValidations={showValidations} value={initialValues.topic} name="condition_value" onChange={onIntentSelect}/> : null}
            {showValidations && !scenario && <div className="error-message">Please choose an option</div>}
        </ScenarioPropertyWrapper>
    )
}

export default ScenarioProperty;