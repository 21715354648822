import React, { Component } from 'react'
import AddButton from '../../../components/common/AddButton';
import FilterComponent from "../../../components/common/FilterComponent";
import RemovableEntry from './../RemovableEntry';
import Rule from './controller';
import Constants from '../../utils/constants';
import IntentPopup from './../IntentPopup';
import ActionButtons from './../ActionButtons';
import IntentDropdown from './../IntentDropdown';
import ValidationInput from "../../../components/common/ValidationInput"
import { Spin } from "antd";
import styled from "@emotion/styled/macro";
import TimeRestriction from "./../commons/time-restriction";
import VerificationChip from "../../../components/chips/VerificationChip";
import CreatedDetails from "../commons/CreatedDetails";
import PopupMenu from "../commons/PopupMenu";
import ActionsList from "./actions-list";
import { connect } from "react-redux";

const RuleTitleWrapper = styled.div`
    display: flex;
    width: calc(100% - 48px);
    margin: 32px 24px;
    input {
        height: 47px;
        padding: 4px 10px;
        font-size: 16px;
        color: #4F4F4F;
    }
`;

const LineSeparator = styled.div`
    width: 100%;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

const SubLineSeparator = styled.div`
    width: 100%;
    position: relative;
    margin: 16px 0;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

const RulePopupLoadingWrapper = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 400px; 
    height: 400px; 
    display: ${props => props.loading ? "flex" : "none"};
`;

const ConditionTitleWrapper = styled.div`
    display: inline-flex;
    width: 150px;
    font-size: 14px;
    line-height: 32px;
    color: #828282;    
    strong {
        margin-left: 3px;
    }
`;

const InputWrapper = styled.div`
    margin-left: 10px;
    input {
        width: 425px;
        border-color: #BDBDBD;
        padding: 4px 10px;
    }
`;

const RuleRowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 6px;
    justify-content: flex-start;
    input {
        width: 425px;
        border: 1px solid #BDBDBD;
        padding: 4px 10px;
    }
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px;
    .buttons {
        display: flex;
        align-items: center;
        button.submit, button.verify {
            margin-left: 4px;
        }
        button.verify {
            background: #27AE60;
            color: white;
            border: none;
        }
    }
`;
const RulePopupWrapper = styled.div`
    display: flex;
    width: 100%; 
    height: 591px;
`;

const PopupDetails = styled.div`
    display: ${props => props.loading ? "none" : "flex"};
    flex-direction: column;
    width: 100%;    
    height: 591px;
`;

const InnerDetailsWrapper = styled.div`
    display: ${props => !props.show ? "none" : "flex"};
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    height: 400px;   
    min-height: 400px;  
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 24px 24px;
`;

const History  = styled.div`
    display: ${props => !props.show ? "none" : "flex"};
    padding: 32px 40px;
    width: 100%;
    justify-content: flex-start;
    min-height: 400px; 
    height: 400px;    
    overflow-y: auto;
    overflow-x: hidden;   
`;

export class RulePopup extends Component {
    constructor(props) {
        super(props)
        this.state={
            title: '',
            description: '',
            trigger: null,
            expectation: {
                type: "say",
                value: ""
            },
            showHint: false,
            timeRestricton: null,
            flagViolation: false,
            action_types: [],
            actions: [],
            flagCount: 1,
            loading: true,
            showIntent: false
        }
        this.rule = new Rule();
        this.intentPromise = null;
    }

    componentDidMount() {
        this.setActions()
    }
    

    toggleTrigger = () => {
        if (this.state.trigger == null) {
            this.rule.setConditionCategory(Constants.CONDITION_CATEGORIES[0])
            this.setState(this.rule.setConditionValue(""))
        } else {
            this.rule.setConditionCategory(null, 'condition_category')
            this.setState(this.rule.setConditionValue(null, 'condition_value'))
        }
    }

    toggleTimeRestriction = () => {
        if (this.state.timeRestricton == null) {
            this.setState(this.rule.setTimeRestriction(0, 'time_restriction'))
        } else {
            this.setState(this.rule.setTimeRestriction(null, 'time_restriction'))
        }
    }

    onValuesChange = (e) => {
        this.setState(this.rule.setValue(e.currentTarget.value, e.currentTarget.name))
    }

    onSwitchChange = (toValue, e) => {
        this.setState(this.rule.setValue(toValue, e.currentTarget.name))
    }

    selectMenu = (menuItem) => {
        this.setState(this.rule.selectMenu(menuItem))
    }

    handleIntentPopup = (toValue, name) => {
        return new Promise((resolve, reject) => {
            resolve()
        })
    }
    

    onDropdownChange = (name, toValue) => {
        return new Promise((resolve, reject) => {
            this.intentPromise = this.handleIntentPopup(toValue, name).then(() => {
                this.setState(this.rule.setValue(toValue, name))
                resolve()
            })
            .catch(() => {
                reject()
                this.setState(this.rule.getRuleState())
            })
        })
    }

    onSubmit = () => {
        if (this.props.isHistorical) {
            this.rule.updateRule(this.props.ruleId).then(() => {
                this.props.onSubmit()
            }).catch(validation => {
                this.setState({validation})
            })
        } else {
            this.rule.createRule().then(() => {
                this.props.onSubmit()
            }).catch(validation => {
                this.setState({validation})
            })
        }
    }
    
    onVerify = () => {
        if (this.props.isHistorical) {
            this.rule.verifyRule(this.props.ruleId).then((res) => {                
                this.props.onSubmit()
            }).catch(validation => {
                this.setState({validation})
            })
        } else {
            this.rule.verifyRule().then((res) => {
                this.props.onSubmit()
            }).catch(validation => {
                this.setState({validation})
            })
        }
    }

    setActions = () => {
        this.setState({
            action_types: this.props.actionTypes
        }, () => {
            // generate actions after api call in case of historic hint value
            this.rule.generateActions(this.state.action_types)

            if (this.props.isHistorical) {
                this.rule.retrieve(this.props.ruleId).then(res => {
                    this.setState(res)
                })
            } else {
                this.setState(this.rule.getRuleState())
            }
        })
    }

    showIntentPopup = (name) => {
        return new Promise((resolve, reject) => {
            this.handleIntentSubmit = ({description, type}) => {
                resolve(description)
                this.setState({...this.rule.setValue(description, `${type}_value`), showIntent: false})
            }
        
            this.handleIntentCancel = () => {
                reject()
                this.setState({showIntent: false})
            }

            this.intentPopup = (
                <div className={"small-popup"}>
                    <IntentPopup onCancel={this.handleIntentCancel} onSubmit={this.handleIntentSubmit} type={name.split('_')[0]}/>
                </div>
            )

            this.setState({showIntent: true})
        })
    }

    render() {
        const {
            title, 
            trigger,
            expectation,
            timeRestricton,
            flagCount,
            action_types,
            loading,
            showIntent,
            showIntentDropdown,
            actions,
            validation,
            hint_enabled,
            flag_enabled,
            warning_enabled,
            rule_audit,
            selectedMenuItem,
            agent_assist_enabled,
        } = this.state;
        const condition = trigger;
        const { onCancel } = this.props;
        return (
            <RulePopupWrapper>
                <PopupMenu disableHistory={!this.props.isHistorical} selectMenu={this.selectMenu} selectedMenuItem={selectedMenuItem}/> 
                <RulePopupLoadingWrapper loading={loading}><Spin /></RulePopupLoadingWrapper>
                <PopupDetails loading={loading}>
                    <RuleTitleWrapper>
                        <ValidationInput 
                            placeholder={this.props.negate ? "Flag Name" : "Action Name"}
                            onChange={this.onValuesChange} 
                            name="title" 
                            defaultValue={title} 
                            value={title} 
                            error={validation}
                        />
                    </RuleTitleWrapper>
                    <LineSeparator />
                    <InnerDetailsWrapper show={selectedMenuItem === "edit"}>
                        {
                            trigger == null ?
                            <AddButton onClick={this.toggleTrigger}>Add Trigger</AddButton>
                            : 
                            <RemovableEntry onRemove={this.toggleTrigger}>
                                <ConditionTitleWrapper>If the customer</ConditionTitleWrapper>
                                <FilterComponent 
                                    options={Constants.CONDITION_CATEGORIES}
                                    placeholder={""}
                                    value={trigger?.type}
                                    hideClear
                                    handleChange={this.onDropdownChange.bind(this, "condition_category")}
                                />
                                {
                                    showIntentDropdown && showIntentDropdown.condition_category ?
                                    <IntentDropdown 
                                        name="condition_value" 
                                        handleChange={this.onValuesChange} 
                                        showIntentPopup={this.showIntentPopup} 
                                        initialValue={trigger.value} 
                                    />
                                    :
                                    <InputWrapper>
                                        <ValidationInput 
                                            onChange={this.onValuesChange} 
                                            name="condition_value" 
                                            defaultValue={condition.value} 
                                            value={condition.value} 
                                            error={validation} 
                                        />
                                    </InputWrapper>
                                }                        
                            </RemovableEntry>
                        }
                        
                        <RuleRowWrapper>
                            <ConditionTitleWrapper>The agent <strong>should{this.rule.isNegative() ? ` not`: ``}</strong></ConditionTitleWrapper>
                            <FilterComponent 
                                options={Constants.EXPECTATION_CATEGORIES}
                                placeholder={""}
                                hideClear
                                value={expectation?.type}
                                handleChange={this.onDropdownChange.bind(this, "expectation_category")}
                            />                    
                            {showIntentDropdown && showIntentDropdown.expectation_category ?
                                <IntentDropdown 
                                    name="expectation_value" 
                                    handleChange={this.onValuesChange} 
                                    showIntentPopup={this.showIntentPopup} 
                                    initialValue={expectation.value} 
                                />
                                :
                                <InputWrapper>
                                    <ValidationInput 
                                        onChange={this.onValuesChange} 
                                        name="expectation_value" 
                                        id="expectation_value" 
                                        value={expectation.value} 
                                        defaultValue={expectation.value} 
                                        error={validation} 
                                    />                    
                                </InputWrapper>
                            }                   
                        </RuleRowWrapper>
                        {timeRestricton == null
                        ? (
                                <RuleRowWrapper>
                                    <ConditionTitleWrapper>in the conversation.</ConditionTitleWrapper> 
                                    <AddButton onClick={this.toggleTimeRestriction}>Add Time Restriction</AddButton>
                                </RuleRowWrapper>
                        )
                        :  <RemovableEntry onRemove={this.toggleTimeRestriction}>
                                <TimeRestriction 
                                    onValuesChange={this.onValuesChange} 
                                    defaultValue={timeRestricton} 
                                    name="time_restriction"
                                />
                            </RemovableEntry>
                        }    
                        <SubLineSeparator />
                        <div className="actions">
                            <ActionsList 
                                onSwitchChange={this.onSwitchChange} 
                                actions={action_types} 
                                onFlagDropdownChange={this.onDropdownChange}  
                                generateMessage={this.rule.generateMessage}
                                onMessageChange={this.onValuesChange}
                                flagCount={flagCount}
                                _actions={actions}
                                warning_enabled={warning_enabled}
                                hint_enabled={hint_enabled}
                                flag_enabled={flag_enabled}
                                agent_assist_enabled={agent_assist_enabled}
                            />
                        </div>                    
                    </InnerDetailsWrapper>
                    <History show={selectedMenuItem === "history"}>
                        <CreatedDetails 
                            heading="Last Edited"
                            user={rule_audit?.modified_by}
                            created={rule_audit?.rule_modified_at || null}
                            isEdited
                        />
                        <CreatedDetails 
                            heading="Last Verified"
                            user={rule_audit?.verified_by}
                            created={rule_audit?.rule_verified_at || null}
                        />
                    </History>
                    <LineSeparator />
                    <ActionButtonsWrapper>
                        <VerificationChip 
                            verified={rule_audit?.is_verified}
                            verifiedTime={rule_audit?.rule_verified_at || null}
                            verifiedBy={rule_audit?.verified_by}
                        />
                        <ActionButtons 
                            onSubmit={this.onSubmit} 
                            onCancel={onCancel} 
                            onVerify={this.onVerify}
                            is_superuser={this.props.app_user.is_superuser}
                        />
                    </ActionButtonsWrapper>
                    {showIntent ? this.intentPopup : null}
                </PopupDetails>
            </RulePopupWrapper>
        )
    }
}

const mapStateToProps = state => ({ app_user: state.app_user });
export default connect(mapStateToProps)(RulePopup);