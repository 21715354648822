import styled from "@emotion/styled";
import { Table } from "antd";

export const RubricHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RubricDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border: 0.5px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 5px 5px 30px #e0e0e0;

  .title {
    //padding: 15px;
    color: black;
  }
  .rubric-details {
    padding: 15px;
    padding-left: 25px;
    font-size: 12px;
    color: #8b8b8d;
    margin: 0px;
  }
  .ant-input {
    padding: 4px 10px;
  }
`;

export const RubricRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.5px solid #f1f1f1;
  padding: 10px 25px 10px 10px;
  .title {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 420;
  }
  &:hover {
    background-color: #fafafa;
    color: black;
  }
`;

export const RubricChannelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  position: relative;

  button {
    margin: auto 0px;
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 8px;
    height: 20px;
    font-weight: normal;
    & span {
      position: relative;
      top: 1px;
    }
  }

  .isLive {
    background-color: #bb6bd9;
    color: white;
  }

  svg {
    margin: auto 0;
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .ant-btn {
    margin-right: 10px;
  }
`;

export const TableWrapper = styled(Table)`
& th {
  font-size: 12px;
  color: #8B8B8D !important;

}
& td {
  font-size: 14px;
  color: #4f4f4f;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

`;

export const ErrorStateWrapper = styled.div`
  font-family: Roboto;
  font-style: normal;
  row-gap: 16px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #828282;
  width: 100%;
`;

export const BlockWrapper = styled.div`
  row-gap: 8px;
  width: 100%;
`;

export const Block = styled.div`
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 0px 8px 16px;
  letter-spacing: 0.0025em;
  color: #4f4f4f;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const Bold = styled.span`
  color: #4f4f4f;
  font-weight: bold;
  text-transform: capitalize;
`;

export const Highlighted = styled.span`
  color: #b070d4;
  font-weight: bold;
  text-transform: capitalize;
`;
