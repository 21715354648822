import React, { useEffect, useState } from "react";
import { Button } from "antd";
import Alert from "../../../../components/common/Alert";
import { ErrorStateWrapper, TextWrapper, BlockWrapper, Block, Bold, Highlighted } from '../wrappers';
import { errorTypes } from "../config";

const ErrorStates = ({ errorState, onCloseErrorState }) => {
  const [errorObject, setErrorObject] = useState({});

  useEffect(() => {
    setErrorObject({ ...errorState });
  }, [errorState]);
  return errorObject && Object.keys(errorObject)?.length ? (
    <Alert
      heading={"Failed to activate"}
      renderBody={() => (
        <ErrorStateWrapper className="d-flex flex-column">
          {errorObject?.type === errorTypes.CHANNEL_ERROR ? (
            <TextWrapper>
              <Highlighted>{errorObject?.data?.channels?.map(channel => channel).join(', ')}</Highlighted> channel for{" "}
              <Bold>{errorObject?.data?.agent_type?.map(agentType => agentType).join(', ')}</Bold> agent types are already activated for
              these rubrics
            </TextWrapper>
          ) : errorObject?.type === errorTypes.AGENT_TYPE_ERROR ? (
            <TextWrapper>
              <Bold>{errorObject?.data?.agent_type?.map(agentType => agentType).join(', ')}</Bold> agent type for{" "}
              <Highlighted>{errorObject?.data?.channels?.map(channel => channel).join(', ')}</Highlighted> channel are already activated for
              these rubrics
            </TextWrapper>
          ) : null}
          <BlockWrapper className="d-flex flex-column">
            {errorObject?.data?.rubrics?.map(rubric => (
              <Block key = {rubric} className="d-flex align-items-center">{rubric}</Block>
            ))}
          </BlockWrapper>
          <TextWrapper>
            Try removing{" "}
            {errorObject?.type === errorTypes.CHANNEL_ERROR ? (
              <><Highlighted>{errorObject?.data?.channels?.map(channel => channel).join(', ')}</Highlighted>{" "} channel</>
            ) : errorObject?.type === errorTypes.AGENT_TYPE_ERROR ? (
              <><Bold>{errorObject?.data?.agent_type?.map(agentType => agentType).join(', ')}</Bold>{" "} agent type</>
            ) : null}{" "}
            from these rubrics
          </TextWrapper>
        </ErrorStateWrapper>
      )}
      closable={true}
      onCancelClick={onCloseErrorState}
      footer={[
        <Button onClick={onCloseErrorState} type="primary">
          Okay
        </Button>,
      ]}
      visible={errorObject?.show || false}
    />
  ) : null;
};

export default ErrorStates;
