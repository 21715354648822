import React, { useReducer, useEffect } from "react"
import { StyledCheckbox } from "../../components/common/styled-components";
import { 
    ConversationTimeLine, 
    ConversationTimeLineTitle, 
    ConversationTimeLineFilters,
    AgentFilters,
    IconWrapper,   
    IconInnerWrapper,
} from "../StyledComponents";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
import {
    AgentFiltersList,
    DashboardFiltersList
} from "../config";
import NotificationCountChip from "../../components/chips/NotificationCountChip";
import timelineReducer, { getInitialState } from "./reducer";
import SearchTab from "./search-tab";
import ContextBasedTimeLine from "./context-timeLine";
import AssistTimeline from "./assist-timeline";
import { addAnalyticsEvent } from "../../utils";
import { TIMELINE_TYPES } from "../Constants";

// const GroupFilters = ({ filter, filterNotificationCount, onChange, showSearchResults, showSearchTab, showCommonTab }) => {
//     const filters = GroupFiltersList.filter((item) => {
//         if(showCommonTab && item.id === TIMELINE_TYPES.KNOWLEDGE) return false;
//         return true;
//     });
//     return (
//         <GroupFiltersWrapper>
//             {filters.map((item) => {
//                 const selected = item.id === filter;
//                 const count = filterNotificationCount[item.id];
//                 return (
//                     <div className={`group-filter ${selected && "selected"}`} onClick={onChange.bind(this, item.id)}>
//                         {item.filter}
//                         {count ? <div className="notification">{count}</div> : null} 
//                     </div> 
//                 )
//             })}
//             <div className={`group-filter ${showSearchResults && "selected"}`} onClick={showSearchTab}>
//                 Knowledge Base
//             </div>                    
//         </GroupFiltersWrapper>
//     )
// }

const ConversationalTimeline = ({ data, isLiveConversation, conversationId, isDebug, startTime, isAgent, updateUserFeedback, groupCardsByContext, showCommonTab, groupResults, conversation_status }) => {
    const [ timelineData , dispatch ] = useReducer(timelineReducer, getInitialState(data, isAgent, groupCardsByContext, showCommonTab, groupResults));
    const handleFilter = (key, event) => {
        if(isLiveConversation && conversationId && conversation_status === "1") {
            addAnalyticsEvent('Live Conversation', { conversationId: conversationId, conversationStatus: conversation_status, type: "APPLY_DASHBOARD_FILTER",
                payload: {
                    key,
                    value: event.target.checked,                
                } 
            });
        }
        dispatch({
            type: "APPLY_DASHBOARD_FILTER",
            payload: {
                key,
                value: event.target.checked,                
            }
        });
    }
    const handleAgentFilter = (type) => {
        if(isLiveConversation && conversationId && conversation_status === "1") {
            addAnalyticsEvent('Live Conversation', { conversationId: conversationId, conversationStatus: conversation_status, type: "APPLY_AGENT_FILTER", filter: type });
        }
        dispatch({
            type: "APPLY_AGENT_FILTER",
            payload: {
                agentFilter: type
            },
        });                
    }

    const showSearchTab = () => {
        if(isLiveConversation && conversationId && conversation_status === "1") {
            addAnalyticsEvent('Live Conversation', { conversationId: conversationId, conversationStatus: conversation_status, type: "APPLY_AGENT_FILTER", filter: "search" });
        }
        dispatch({
            type: "SHOW_SEARCH_RESULTS"
        })
    }
    
    useEffect(() => {
        dispatch({
            type: "UPDATE_DATA",
            payload: { data, isAgent, groupCardsByContext }
        });                
    }, [data, isAgent, groupCardsByContext]);
    const filters = AgentFiltersList.filter((item) => {
        if(showCommonTab && item.filter === TIMELINE_TYPES.KNOWLEDGE) return false;
        return true;
    });
    return (
        <ConversationTimeLine isAgent={isAgent || isLiveConversation}>
            {
            <React.Fragment>
                {!isAgent && <ConversationTimeLineTitle>Assits and Flags</ConversationTimeLineTitle>}
                {isAgent ?
                    <AgentFilters>
                        {filters.map(({filter, icon: Icon, title}, index) => {
                            const count = timelineData.filterNotificationCount[filter];
                            const selected = timelineData.agentFilter === filter;
                            return (
                                <IconWrapper 
                                    key={index}
                                    onClick={handleAgentFilter.bind(this, filter)}
                                    selected={selected}
                                >
                                    <IconInnerWrapper selected={selected}>
                                        <Icon/>
                                        {title}
                                        {count ? <NotificationCountChip top={-6} right={-8} count={count}/> : null} 
                                    </IconInnerWrapper>
                                </IconWrapper>
                            )
                        })}                   
                        <IconWrapper 
                            onClick={showSearchTab}
                            selected={timelineData.showSearchResults}
                        >
                            <IconInnerWrapper selected={timelineData.showSearchResults}>
                                <SearchIcon />                            
                                Knowledge search
                            </IconInnerWrapper>
                        </IconWrapper> 
                    </AgentFilters> :
                    <ConversationTimeLineFilters>
                        {DashboardFiltersList.map((item, index) => {
                            return (
                                <StyledCheckbox 
                                    key={index} 
                                    onChange={handleFilter.bind(this, item.filter)}
                                >
                                    {item.title}
                                </StyledCheckbox>
                            );
                        })}
                    </ConversationTimeLineFilters>
                }
            </React.Fragment>
            }
            {groupCardsByContext 
                ? <ContextBasedTimeLine
                    isAgent={isAgent} 
                    isLiveConversation={isLiveConversation} 
                    data={timelineData.contextGroupedData} 
                    updateUserFeedback={updateUserFeedback} 
                    conversationId={conversationId}
                    hide={timelineData.showSearchResults}
                    conversation_status={conversation_status}
                    groupResults={false}
                  />
                : <AssistTimeline
                    isAgent={isAgent} 
                    isLiveConversation={isLiveConversation} 
                    data={timelineData.filteredData} 
                    updateUserFeedback={updateUserFeedback} 
                    conversationId={conversationId}
                    hide={timelineData.showSearchResults}
                    conversation_status={conversation_status}
                    isDebug={isDebug}
                    startTime={startTime}
                    groupResults={groupResults}
                />         
            }
            <SearchTab show={timelineData.showSearchResults} conversationId={conversationId} conversation_status={conversation_status} />
        </ConversationTimeLine>
    )
};

export default ConversationalTimeline;