import React from "react";
import { message } from "antd";
import { postQuery, shareQueryChart, updateQuery } from "../api";
import ShareReportModal from "../../components/common/ShareReportModal";
import { useSelector } from "../../store";

const ShareQueryChart = ({
  chartId,
  openChartShareModal,
  setOpenChartShareModal,
  title,
  isFromCustomDashboard = false,
  isSaved,
  getQueryParams,
  queryId,
}) => {
  const sender = useSelector(
    (state) => state.app_user.first_name + " " + state.app_user.last_name
  );
  const saveQuery = (data) => {
    const postOrUpdate = isSaved() ? updateQuery : postQuery;
    let args = [
      {
        title: data.title,
        ...getQueryParams(),
      },
    ];

    if (queryId) {
      args = [queryId, ...args];
    }
    postOrUpdate(...args)
      .then((res) => {
        onCreate({ ...data, id: res.id });
      })
      .catch((err) => message.error(`${err.message}`));
  };

  const onCreate = (data) => {
    let reportData = {
      ...data,
      sender,
    };
    if (!isFromCustomDashboard) {
      reportData = {
        ...reportData,
        id: chartId,
      };
    }
    shareQueryChart(reportData)
      .then((res) => {
        message.success("Chart is shared successfully!");
        setOpenChartShareModal(false);
      })
      .catch((err) => {
        message.error(`${err.message}`);
      });
  };

  return (
    <ShareReportModal
      onCreate={isFromCustomDashboard ? saveQuery : onCreate}
      visible={openChartShareModal}
      closeModal={() => setOpenChartShareModal(false)}
      includeFrequency={false}
      chartTitle={title}
      heading="Share Chart"
    />
  );
};

export default ShareQueryChart;
