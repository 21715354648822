import React, { useState } from "react";
import { Input, Button, Layout } from 'antd';
import Constants from '../../utils/constants';
import RemovableEntry from './../RemovableEntry';
import FilterComponent from "../../../components/common/FilterComponent";
import styled from "@emotion/styled/macro";
import { StyledSwitch } from "../../../components/common/styled-components";

const { Sider, Content } = Layout;

const StyledLayout = styled(Layout)`
    margin-top: 16px;
`;

const StyledTextArea = styled(Input.TextArea)`
    &.ant-input {
        top: 0;
        resize: none;
        border-color: #BDBDBD;    
        display: flex;
        flex: 1 1;
        padding: 5px 10px;    
        height: 72px;
        min-height: 72px;
    }
`;

const StyledSider = styled(Sider)`
    justify-content: flex-end;
    display: flex;
    background: white;
`;

const LinkButton = styled(Button)`
    padding: 0;
    margin-top: 8px;
`;

const ActionTitleWrapper = styled.div`
    display: inline-flex;
    width: 150px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #4F4F4F;
    margin-bottom: 10px;
`;

const MessageWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    white-space: normal;
    margin-top: 8px;
    color: #BDBDBD;
    opacity: ${props => props.enabled ? "1" : "0.6"};
    span {
        color: #4F4F4F;
    }
`;

const FlagOptions = Constants.FLAG_KEYS.map((item, index) => {
    return {
        label: `${item} violation`,
        value: Constants.FLAG_VALUES[index].toString()
    }
});

const Action = (props) => {
    const [isEditing, editMessage] = useState(false);
    const customMessage = props.customMessage || props.customMessageValue;
    const message = customMessage ? customMessage : props.generateMessage();
    return (
        <StyledLayout>
            <Content style={{backgroundColor: "white"}}>
                <ActionTitleWrapper>{props.title}</ActionTitleWrapper>
                {isEditing ?
                    <RemovableEntry
                        buttonStyle={{
                            height: "20px",
                            border: "none",
                            marginLeft: "0"
                        }} 
                        wrapperStyle={{
                            alignItems: "flex-start",
                        }}
                        onRemove={() => {
                            editMessage(false)
                            props.onMessageChange({currentTarget: {value: null, name: props.name+"_action_message"}})
                        }}
                    >
                        <StyledTextArea 
                            name={props.name + "_action_message"} 
                            onChange={props.onMessageChange} 
                            defaultValue={props.customMessage || props.customMessageValue}
                        />
                    </RemovableEntry>
                    :
                    <MessageWrapper enabled={props.enabled}>Message: <span>{message}.</span></MessageWrapper>
                }
                {isEditing ?
                    null :
                    <LinkButton disabled={!props.enabled} type="link" onClick={()=>{editMessage(true)}}>Customise Message</LinkButton>
                }
            </Content>
            <StyledSider>
                {props.children}                                
            </StyledSider>
        </StyledLayout>
    )
}

const ActionsList = (props) => {
    const actionMapping = {
        [Constants.HINT]: props._actions.filter(action => action.category === Constants.HINT)[0],
        [Constants.WARNING]: props._actions.filter(action => action.category === Constants.WARNING)[0],
        [Constants.FLAG]: props._actions.filter(action => action.category === Constants.FLAG)[0]
    }

    const hint = (
        <Action 
            title="Hint" 
            onMessageChange={props.onMessageChange} 
            name="hint" 
            generateMessage={props.generateMessage}
            customMessage={actionMapping[Constants.HINT]?.customMessage} 
            customMessageValue={actionMapping[Constants.HINT]?.value}
            enabled={props.agent_assist_enabled} 
        >
            <p>Enabled <StyledSwitch 
                onChange={props.onSwitchChange} 
                name="agent_assist_enabled" 
                checked={props.agent_assist_enabled} 
            /></p>
        </Action>
    )
    const warning = (
        <Action 
            title="Warning" 
            onMessageChange={props.onMessageChange} 
            name="warning" 
            generateMessage={props.generateMessage} 
            customMessage={actionMapping[Constants.WARNING]?.customMessage} 
            customMessageValue={actionMapping[Constants.WARNING]?.value}
            enabled={props.agent_assist_enabled} 
        >
            <p>Enabled <StyledSwitch 
            onChange={props.onSwitchChange} 
            name="agent_assist_enabled" 
            checked={props.agent_assist_enabled} 
        /></p>
        </Action>
    )
    const flag = (
        <Action 
            title="Flag" 
            onMessageChange={props.onMessageChange} 
            name="flag"
            generateMessage={props.generateMessage} 
            customMessage={actionMapping[Constants.FLAG]?.customMessage} 
            customMessageValue={actionMapping[Constants.FLAG]?.value}
            enabled={props.flag_enabled}
        >
            <FilterComponent 
                options={FlagOptions}
                placeholder=""
                value={props.flagCount !== undefined ? props.flagCount.toString() : ""}
                hideClear
                handleChange={(value) => props.onFlagDropdownChange("flag_count", Number(value))}
            />            
        </Action>
    );

    const propsMapping = {
        [Constants.HINT]: hint,
        [Constants.WARNING]: warning,
        [Constants.FLAG]: flag
    }
    const actions = props.actions.map((action) => {
        return propsMapping[action]
    })
    return (actions)
}

export default ActionsList;