export const calculateUpdatedTarget = (source, target, patch = {}) => {
  let updatedTarget = {};
  const generatedPayload = source.data.generatedPayload;
  const sourcePayload = {
    nodeId: source.id,
    variables: [],
  };
  Object.keys(generatedPayload).forEach((key) => {
    let type;
    if (Array.isArray(generatedPayload[key])) {
      type = "Array";
    } else if (typeof generatedPayload[key] === "object") {
      type = "object";
    } else type = generatedPayload[key];

    let displayName = key;
    if (generatedPayload[key]) {
      displayName =
        generatedPayload[key].__displayName ||
        generatedPayload[key][0]?.__displayName ||
        key;
    }
    sourcePayload.variables.push({
      name: key,
      type,
      displayName,
    });
  });
  const globalVariables = [sourcePayload, ...source.data.globalVariables];
  if (target) {
    updatedTarget = {
      ...target,
      data: {
        ...target.data,
        globalVariables,
        variableFromLastFunc: [sourcePayload],
        ...patch,
      },
    };
  }
  return updatedTarget;
};

export const updateIfConditionOnTarget = (sourceHandle, source, target) => {
  let updatedTarget = {};
  if (sourceHandle === "left") {
    updatedTarget = calculateUpdatedTarget(source, target, {
      if: { ...source.data.conditionData },
    });
  } else if (sourceHandle === "right") {
    updatedTarget = calculateUpdatedTarget(source, target, {
      if: { ...source.data.oppositeConditionData },
    });
  }
  return updatedTarget;
};

export const getChildrenAtVariableFromNode = (
  node,
  parentVar,
  variable,
  displayName
) => {
  let childrens = node?.data?.generatedPayload[parentVar][variable];
  if (!displayName) {
    return childrens;
  }
  let clonedChildren;
  if (Array.isArray(childrens)) {
    if (childrens.length && typeof childrens[0] === "object") {
      clonedChildren = [{ ...childrens[0], __displayName: displayName }];
    }
  } else if (typeof childrens === "object") {
    clonedChildren = { ...childrens, __displayName: displayName };
  } else {
    return childrens;
  }
  return clonedChildren;
};

export const getKeysOfVariable = (variable) => {
  let keys = [];
  if (Array.isArray(variable)) {
    if (typeof variable[0] === "object")
      Object.keys(variable[0]).forEach((key) => {
        if (!key.startsWith("__"))
          keys.push({
            name: key,
            displayName: variable[0][key]?.__displayName || key,
          });
      });
  } else if (typeof variable === "object") {
    Object.keys(variable).forEach((key) => {
      if (
        Array.isArray(variable[key]) &&
        variable[key]?.length &&
        typeof variable[key][0] === "object"
      ) {
        keys.push({
          name: key,
          displayName: variable[key][0]?.__displayName || key,
        });
      } else if (!key.startsWith("__"))
        keys.push({
          name: key,
          displayName: variable[key]?.__displayName || key,
        });
    });
  }
  return keys;
};
