import styled from "@emotion/styled";
import { Handle } from "react-flow-renderer";

export const StyledNode = styled.div`
  background: #fff;
  color: #4f4f4f;
  border-radius: 4px;
  min-width: 350px;
  border: 1px solid #e0e0e0;
`;

export const StyledHandle = styled(Handle)`
  border-radius: ${({ source }) => (source ? "0" : "50%")};
  background: ${({ source }) => (source ? "rgba(0,0,0,0.1)" : "#e0e0e0")};
  height: ${({ source }) => (source ? "25px" : "12px")};
  width: ${({ source }) => (source ? "100px" : "12px")};
  transform: ${({ source }) => (source ? "translate(-50%,-5px)" : "")};
`;

export const StyledNodeHead = styled.div`
  background: #f0f0f0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: center;
  padding: 0.5rem;
  position: relative;
  height: 36px;
  & div {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 36px;
    display: grid;
    place-content: center;
  }
  & .operation {
    background: #b2433b;
  }
  & .action {
    background: #6099d9;
  }
  & .manipulation {
    background: #802f9e;
  }
  & .condition {
    background: #b15d10;
  }
  & .start {
    background: #4c8564;
  }
`;
