import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Chart } from "../chart";
import { queryOptions } from "../dynamic-query-builder";
import { ChartWrapper } from "./wrapper";

const isTimeUnit = (measure) => {
    return measure === queryOptions.measureOptions.AVERAGE_HANDLING_TIME ||
        measure === queryOptions.measureOptions.FIRST_RESPONSE_TIME ||
        measure === queryOptions.measureOptions.AVERAGE_CASE_RESOLUTION_TIME
}

// this function will change when more operators are included
const translateFilter = (filter) => {
    if (!filter) return {}
    let translatedFilter = {}
    let filterArray = [...filter]
    let keys = filterArray.map((filter) => {
        return filter.key
    })
    let distinctKeys = [...new Set(keys)]
    distinctKeys.forEach((key) => {
        let values = filterArray.filter(x => { return x.key === key }).map(x=>x.value).flat().filter(x=>x!==undefined);
        translatedFilter[key] = values
    })
    return translatedFilter;
}

export const QueryChart = (props) => {
    const [ query, setquery ] = useState(props.query)
    const [ chartType, setchartType ] = useState(props.chartType)
    const [ dateRangeValues, setdateRangeValues ] = useState(props.dateRangeValues)
    const [ date, setdate ] = useState(props.date)
    const [ title, settitle ] = useState(props.title)

    useEffect(() => {
        setquery(props.query)
        setchartType(props.chartType)
        setdateRangeValues(props.dateRangeValues)
        settitle(props.title)
        setdate(props.date);
    }, [props])

    return (
        <ChartWrapper width={props.width} padding={props.padding} margin = {props.margin}>
            <Chart query={
                    {
                        measure: query.measure,
                        groupBy: query.groupBy,
                        isTimeSeries: query.isTimeSeries,
                        isTimeUnit: isTimeUnit(query.measure),
                        timeDimension: query?.timeDimension || {}
                    }
                }
                showPropOnX={props.showPropOnX}
                actionMenu={props.actionMenu}
                isChartTypeEditable={true}
                title={
                    query.id ? <Link to={`/analytics/query-builder/${query.id}/?isDashboardEdit=true`}>{title}</Link>
                    :
                    title
                }
                filterBy={queryOptions.filterByOptions.NONE}
                overrideFilters={{
                    ...translateFilter(query.filter),
                    dateRangeValues,
                    date,
                    timeGranularity: query.timeGranularity,
                }}
                chartType={chartType || "interval"}
                drilldownQueries={[]}
                isCompareDateRange={props.isCompareDateRange}
            >
            </Chart>
        </ChartWrapper>
    )
}
