import React, { useState } from 'react'
import { Tabs } from 'antd';
import styled from "@emotion/styled/macro";
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
    width: 100%;
    .ant-tabs-content {
        background: #FFFFFF;
    }
`; 

const TabPanels = ({ defaultActiveTab, tabs, onChange }) => {
    const  [activeTab, setActiveTab] = useState(defaultActiveTab);
    const onTabChange = (key) => {
        setActiveTab(key);
        onChange(key);
    }
    return (
        <StyledTabs defaultActiveKey={activeTab} onChange={onTabChange}>
            {tabs.map((tab, index) => {
                return (
                    <TabPane tab={tab.title} key={tab.key}>
                        {tab.renderComponent()}
                    </TabPane>
                )
            })}
        </StyledTabs>
    )
}

export default TabPanels;