import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { 
    SetupWrapper, 
    LineSeparator,  
} from "./../commons/wrappers";
import BreadCrumbComponent from "../../../components/common/BreadCrumbComponent";
import { getConversationTagDetails, updateConversationTagDetails, verifyConversationTagRule } from "../../api/api";
import {
    DetailsHeader,
    DetailsLoadingWrapper,
    RuleWrapper,
    RuleDetailsWrapper,
    ActionsWrapper,
} from "./wrappers";
import { Button, Spin, message } from "antd";
import { ConverationTagBuildConfigurations, userHistory } from "./config";
import RuleProperty from "./RuleProperty";
import { connect } from "react-redux";
import { hasQAAuditorRights } from "../../../AnalyticsDashboard/config";
import EmptyState from "../../../components/common/EmptyState";
import appConfigs from "../../../config";
import Constants from "../../utils/constants";
import styled from "@emotion/styled";
import { channelOptions } from '../conversation-tags/config';

const EmptyStateWrapper = styled.div`
width: 100%;
display: flex;
height: 100%;
justify-content: center;
align-items: center;
`;
export class ConversationTagDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagId: props.match.params.id,
            currentPropertyIndex: 0,
            loading: true,
            showErrorState: false
        };
    }
    componentDidMount() {
        getConversationTagDetails(this.state.tagId).then((response) => {
            this.setState({ ...response.rule_json, loading: false, showErrorState: false, channel: response?.rule_json?.channel || [...channelOptions.options.map(channel => channel.value)] });
        }, (err) => {
            this.setState({ showErrorState: true,  loading: false });
        });
    }
    onCancel = () => {
        this.props.history.push(`/setup/conversation-tags/`);
    }   
    onSave = () => {
        return new Promise((resolve, reject) => {
            const {
                actions,
                conditions,
                description,
                tagType,
                time_restriction,
                title,       
                time_policy,
                expectation_type,     
                expectations,
                is_tagged_for_qa,
                channel
            } = this.state
            const data = {
                title,            
                description,
                is_tagged_for_qa,
                expectations: [],
                is_active: true,
                actions: [],
                conditions: [],
            };
            
            const tagTypeConfigurations = ConverationTagBuildConfigurations.find((item) => item.id === this.state.tagType);
            const properties = tagTypeConfigurations?.properties || [];
            let isValid = true;
            for(let i=0; i<properties.length; i++) {
                const property = properties[i].id;
                if(!properties[i].showOnly || (properties[i].showOnly && properties[i].showOnly(this.state))) {
                    switch(property) {
                        case "scenario":
                            data.expectations = expectations;
                            isValid = isValid && expectations && expectations.length;
                            break;
                        case "expectations":
                            data.expectation_type = expectation_type;
                            isValid = isValid && expectation_type;
                            break;
                        case "conditions":
                            data.conditions = conditions;
                            isValid = isValid && conditions && conditions.length;
                            break;
                        case "time_restriction":
                            data.time_policy = time_policy;
                            data.time_restriction = time_restriction ? time_restriction : 999999999;
                            isValid = isValid && time_policy && (
                                time_policy === "any" 
                                || (time_policy === "start" && time_restriction !== undefined)
                            );
                            break;                
                        case "actions":
                            data.actions = actions;
                            isValid = isValid && actions && actions.length;
                            break;
                        case "tagType":
                            data.tagType = tagType;
                            isValid = isValid && tagType;
                            break;
                        case "channel":
                              if(!appConfigs.ENABLE_CHANNEL_BASED_CONVERSATION_TAGS) {
                                break;
                              }
                              data.channel = channel;
                              isValid = isValid && channel?.length;
                              break;
                    }
                }
            }
            if(!isValid) {
                message.error("Please add all the properties");
                return;
            }
            updateConversationTagDetails(this.state.tagId, { rule_json: data }).then((res) => {
                resolve();
            }, (err) => {
                reject();
                //console.log({ err });                
            });
        });        
    }
    onSubmit = () => {
        this.onSave().then((res) => {
            message.success("Conversation tag details is saved");
            this.props.history.push(`/setup/conversation-tags/`);
        }, (err) => {
            message.error("Failed to create conversation tag rule");
        })
    }
    onVerify = () => {
        this.onSave().then((res) => {
            verifyConversationTagRule(this.state.tagId).then((res) => {
                message.success("Conversation tag details is saved");
                this.props.history.push(`/setup/conversation-tags/`);
            }, (err) => {
                message.success("Failed to verify conversation tag");
            })
        }, () => {
            message.error("Failed to save conversation tag");
        });
    }
    savePropertyDetails = (details) => {
        const { id, label, ...restProps } = details;        
        switch(id) {
            case "scenario":
                const scenarios = [
                    {
                        category: restProps.type,
                        negate_subrule: false,
                        value: restProps.value,
                    },
                    {
                        category: "speaker",
                        negate_subrule: false,
                        value: restProps.speaker,
                    }                
                ];
                this.setState({ "expectations": scenarios });
                return;
            case "expectations":
                this.setState({ expectation_type: restProps.type });
                return;
            case "conditions":
                const conditions = [
                    {
                        category: restProps.type,
                        negate_subrule: false,
                        value: restProps.value,
                    },
                    {
                        category: "speaker",
                        negate_subrule: false,
                        value: restProps.speaker,
                    }                
                ];
                this.setState({ conditions: conditions });
                return;
            case "time_restriction":
                this.setState({
                    time_policy: restProps.type,
                    time_restriction: restProps.value
                });
                return;
            case "actions":
                this.setState({
                    actions: restProps.actions,
                })
                return;
            case "tagType":
                this.setState({ tagType: restProps.type });
                return;
            case "channel" : 
                this.setState({ channel: restProps?.value || [] })
        }        
    }
    render() {
        const tagTypeConfigurations = ConverationTagBuildConfigurations.find((item) => item.id === this.state.tagType);
        if(this.state.title) {
            userHistory[3] = {
                title: this.state.title
            };
        }
        if(this.state.loading) {
            return (
                <SetupWrapper className="setup-tab-conversation-tags">
                    <DetailsLoadingWrapper>
                        <Spin />
                    </DetailsLoadingWrapper>
                </SetupWrapper>
            )
        }

        return (
              !this.state.showErrorState ? <SetupWrapper className="setup-tab-conversation-tags">
                <BreadCrumbComponent items={userHistory}/>
                <DetailsHeader>
                    <div className="details-wrapper">
                        <div className="title">{this.state.title}</div>
                        <div className="description">{this.state.description}</div>
                    </div>
                    <div className="action-button">
                        
                    </div>
                </DetailsHeader>
                <LineSeparator style={{margin: "0 0 8px"}} />
                <RuleWrapper>
                    {tagTypeConfigurations?.properties ? <RuleDetailsWrapper>
                        {tagTypeConfigurations.properties.map((item, index) => {
                            let currentPropertyIndex = index;
                            const showDetails  = this.state.currentPropertyIndex === currentPropertyIndex;
                            if(item.showOnly && !item.showOnly(this.state)) {
                                return null;
                            }
                            return (
                                <RuleProperty 
                                    {...item} 
                                    key={index}
                                    ruleProperties={this.state}
                                    showDetails={showDetails}
                                    goToNext={(details) => {
                                        if(index !== tagTypeConfigurations.properties.length - 1) {
                                            this.setState({
                                                currentPropertyIndex: this.state.currentPropertyIndex + 1
                                            });
                                        }
                                        this.savePropertyDetails(details);
                                    }}
                                />
                            )
                        })}
                    </RuleDetailsWrapper> : null}
                    <LineSeparator />
                    <ActionsWrapper>
                        <Button onClick={this.onCancel}>Cancel</Button>
                        <Button onClick={this.onSubmit} className="submit" type="primary">Create Tag</Button>
                        {hasQAAuditorRights() ? <Button style={{float: "right"}} className="verify" onClick={this.onVerify}>Save & Verify</Button> : null}
                    </ActionsWrapper>
                </RuleWrapper>
            </SetupWrapper> : <EmptyStateWrapper><EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} /></EmptyStateWrapper>
        )
    }
} 

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps, null)(withRouter(ConversationTagDetails));
