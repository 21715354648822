import React from 'react'
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/history.svg";
import styled from "@emotion/styled/macro";

const PopupMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 55px;
    height: 100%;
    border-right: 1px solid #BDBDBD;
`;

const MenuItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 72px;
    cursor: ${props => props.disable ? "not-allowed" : "pointer"};
    pointer-events: ${props => props.disable && "none"};
    border-right: ${props => props.active && "2px solid #0B4B93"};    
    svg {
        width: 18px;
        height: 18px;
        path {
            fill: ${props => props.active ? "#0B4B93" : (props.disable ? "#eeeeee" : "#BDBDBD")};
        }        
    }    
`;

const PopupMenu = ({selectedMenuItem, selectMenu, disableHistory}) => {
    return (
        <PopupMenuWrapper>
            <MenuItem active={selectedMenuItem === "edit"} onClick={() => selectMenu("edit")}>
                <EditIcon />
            </MenuItem>
            <MenuItem disable={disableHistory} active={selectedMenuItem === "history"} onClick={() => selectMenu("history")}>
                <HistoryIcon />
            </MenuItem>
        </PopupMenuWrapper>
    )
}

export default PopupMenu;