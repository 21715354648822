import React from 'react'
import { Button } from 'antd'
import AddButton from "../../../components/common/AddButton"
import PhraseInput from "./PhraseInput";
import styled from "@emotion/styled/macro";
import { HorizontalButtonWrapper } from '../../../components/common/styled-components'

const PhrasesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .add-btn {
        margin-top: 10px;
    }
`;

const ConditionSubTitleWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;        
    margin-bottom: 10px;  
`;

const UserPhrases = ({ 
    phrases, 
    removePhrase, 
    handlePhrasesChange, 
    handleConflictResolved, 
    toggleEditRuleConflict, 
    viewOnly,
    addPhrase,
    isHistorical,
    id,
    title,    
    placeholder,
}) => {
    const [ isCollapsed, setIsCollapsed ] = React.useState(true);
    return (
        <PhrasesWrapper>
            <ConditionSubTitleWrapper>{title}</ConditionSubTitleWrapper>                
            {phrases?.slice(0, isCollapsed && isHistorical && !viewOnly ? 3 : phrases.length)?.map((phrase, index) => (
                <PhraseInput 
                    key={phrase.id}
                    uniqueID = {phrase.id}
                    index={index} 
                    phrase={phrase}
                    phraseList = {phrases}
                    removePhrase={removePhrase}
                    onChange={handlePhrasesChange} 
                    onValidate={handleConflictResolved}
                    editConflictRule={(ruleId) => toggleEditRuleConflict(ruleId)}
                    intentId={id}
                    disabled={viewOnly}
                    placeholder={placeholder}
                />
            ))}

            {!viewOnly && <HorizontalButtonWrapper>
                <AddButton className="add-btn" onClick={(e) => { addPhrase(e); setIsCollapsed(false);}}>{placeholder ? placeholder : "Example Phrase"}</AddButton>
                {
                    phrases.length > 3 && isHistorical ?
                    (isCollapsed ?
                    <Button className="add-btn right" type="link" onClick={() => setIsCollapsed(false)}>See all {phrases.length} phrases</Button>
                    :
                    <Button className="add-btn right" type="link" onClick={() => setIsCollapsed(true)}>Collapse</Button>)
                    :
                    null
                }                
            </HorizontalButtonWrapper>}                           
        </PhrasesWrapper> 
    )
}

export default UserPhrases;
