import React from "react";
import { useSelector } from 'react-redux';
import queryString from "query-string";
import moment from "moment";
import { getSchemaName } from '../api/api';
import { cubeJSApi } from './api';
import { groupBy, queryOptions } from './dynamic-query-builder';
import {HeapAnalyticsEvent} from '../components/common/HeapAnalyticsEvent';
import { qualityAssessmentAggregateQueryBuilder, qualityAssessmentQueryBuilder } from './query-builder';
import { Chart } from "./chart";
import store from "../store";
import { hasManagerRights, hasQAAuditorRights } from './config';
import { omit } from '../user-dashboard/configs';
import { EvaluationStatus } from '../conversation-list/configs';
import { getEvaluationData } from "../user-dashboard/api";
import { getApiFilters } from "../utils/utilities";
import { Constants } from "./query-builder/config";
import { ReduxActionTypes } from '../constants/ReduxActionConstants';
import { getItemFromStore } from "../utils";
import { getCustomFilters } from "../conversation-list/api";
import { queryConfigs } from "./query-config";

export const getMeasureText = (measure) => {
    measure = Array.isArray(measure) ? measure[0] : measure;
    
    switch(measure) {
        case queryOptions.measureOptions.RAW_SCORE: {
            return "QA Score"
        }
        case queryOptions.measureOptions.QUESTION_PERCENT: {
            return "Question Score (%)"
        }
        case queryOptions.measureOptions.CATEGORY_SCORE: {
            return "Category Score"
        }
        case queryOptions.measureOptions.SECTION_SCORE: {
            return "Section Score"
        }
        case queryOptions.measureOptions.QUESTION_SCORE: {
            return "Question Score"
        }
        case queryOptions.measureOptions.AVERAGE_HANDLING_TIME: {
            return "Average Handling Time"
        }
        case queryOptions.measureOptions.CASE_COUNT: {
            return "No of Cases"
        }
        case queryOptions.measureOptions.CONVERSATION_COUNT: {
            return "Total Conversations"
        }
        case queryOptions.measureOptions.FIRST_RESPONSE_TIME: {
            return "Average First Response Time"
        }
        case queryOptions.measureOptions.DEAD_AIR_RATIO: {
            return "Dead Air Ratio"
        }
        case queryOptions.measureOptions.LISTEN_TO_TALK_RATIO: {
            return "Listen to Talk Ratio"
        }
        case queryOptions.measureOptions.AVERAGE_WORDS_PER_MINUTE: {
            return "Talk Speed"
        }
        case queryOptions.measureOptions.AVERAGE_CASE_RESOLUTION_TIME: {
            return "Average Case Resolution Time"
        }
        case queryOptions.measureOptions.FOLLOWED_RATE: {
            return "Conversation Tag Follow Rate (%)"
        }
        case queryOptions.measureOptions.FLAGGED_RATE: {
            return "Conversation Flag Rate (%)"
        }
        case queryOptions.measureOptions.AVERAGE_AGENT_REPLY_COUNT: {
            return "Average Agent Reply Count"
        }
        case queryOptions.measureOptions.AVERAGE_CUSTOMER_REPLY_COUNT: {
            return "Average Customer Reply Count"
        }
        case queryOptions.measureOptions.CUSTOMER_TO_AGENT_EMAIL_RATIO: {
            return "Customer to Agent Emails Ratio"
        }
        case queryOptions.measureOptions.AVERAGE_CSAT: {
            return "Average CSAT Score"
        }
        case queryOptions.measureOptions.PASS_RATE: {
            return "QA Pass Rate (%)"
        }
        case queryOptions.measureOptions.EVALUATIONS: {
            return "QA Evaluations"
        }
        case Constants.CONVERSATION.unflaggedCount: {
            return "Unflagged Conversations"
        }
        case Constants.CONVERSATION.flaggedCount: {
            return "Flagged Conversations"
        }
        case Constants.QA_EVALUATIONS.chatCount: {
            return "Chat"
        }
        case Constants.QA_EVALUATIONS.callCount: {
            return "Call"
        }
        case Constants.QA_EVALUATIONS.emailCount: {
            return "Email"
        }
        case Constants.QA_EVALUATIONS.slackCount: {
            return "Slack"
        }
        case Constants.QA_EVALUATIONS.smsCount: {
            return "SMS"
        }
        case Constants.QA.PERCENT_SCORE: {
          return "QA Score (%)"
        }
        default: {
            return measure
        }
    }
}

export const getTableColumnText = (dataIndex) => {
    if(dataIndex.includes("LevelAsrAsrlog.created")) {
        return "Date"
    }

    if(dataIndex.includes("compareDateRange")) {
        return "Date Range"
    }

    if(queryConfigs.groupByList.includes(dataIndex)) {
        let groupByOption = queryConfigs?.GROUPBY_OPTIONS.filter(el => el.value === dataIndex)[0];
        return groupByOption?.label
    }

    return getMeasureText(dataIndex);
}

export const getMeasureTextCustomColumns = (measure) => {
  let aggregations = {
    'Avg' : 'Average ',
    'Sum' : ''
  }
  if(!measure)
    return "";
  let measureSplit = measure.split(' Measure ');
  return (measureSplit[1] ? aggregations[measureSplit[1]] : "") + measureSplit[0];
}
export const getTooltipText = (measure) => {
    switch(measure) {
        case queryOptions.measureOptions.PASS_RATE: {
            return "Pass"
        }
        case queryOptions.measureOptions.FAIL_RATE: {
            return "Fail"
        }
        case queryOptions.measureOptions.POOR_RATE: {
            return "Poor"
        }
        case queryOptions.measureOptions.GOOD_RATE: {
            return "Good"
        }
        case queryOptions.measureOptions.OUTSTANDING_RATE: {
            return "Outstanding"
        }
        case queryOptions.measureOptions.PERCENT_SCORE: {
            return "QA Score (%)"
        }
        default : {
          return "";
        }
    }
}

export const chartsTitle = {
    Average_QA_Score : "Average QA Score",
    Average_AUTO_INSTASCORE : "Average Auto Instascore",
    Average_QA_Score_Agent : "Average QA Score by Agents",
    Average_Categories_Score: "Average Categories Score",
    Average_Sections_Score: "Average Sections Score",
    Average_Questions_Score: "Average Questions Score",
    QA_Score_Over_Time: "QA Score over time",
    Responsiveness: "Responsiveness",
    Efficiency: "Efficiency",
    Scripting_Adherence: "Scripting Adherence",
    Call_Specific: "Call Specific",
    Email_Specific: "Email Specific",
    Interactions_By_Agent: "Interactions by Agent",
    Interactions_Over_Time: "Interactions over time",
    Average_Handling_Time: "Average Handling Time",
    
}

export const getChannelText = (channel) => {
    return ["Call", "Chat", "Sms", "Email", "Slack"][channel - 1]
}

export const getAgentFullName = (agents, id) => {
    const agent = agents?.filter(x=>x.id==id)[0]
    let agentName = agent ? `${agent.first_name} ${agent.last_name}` : ``
    return agentName;
}

export const getQAAuditorFullName = (agents, id) => {
    const agent = agents?.filter(x=>x.value == id)[0]
    let agentName = agent ? agent.label : ``
    return agentName;
}

export const getScriptingRuleName = (scripting_rules, id) => {
    const scripting_rule = scripting_rules?.filter(x=>x.id==id)[0]
    return scripting_rule?.title || " ";
}

export const getMedian = (measures) => {
    const len = measures.length;
    const sorted = [...measures].sort();
    const mid = Math.ceil(len / 2);
    const median = len % 2 == 0 ? (sorted[mid] + sorted[mid - 1]) / 2 : sorted[mid - 1];
    return median
}

export const roundOff = (value) => {
    return parseFloat(value).toFixed(2).replace(/\.0+$/,'').replace("NaN", "-")
}

export const queryAggregateCubeJS = (query, globalFilters, callback) => {
    if(query.measure === queryOptions.measureOptions.TOTAL_EVALUATIONS) {
      let cloneGlobalFilters = getApiFilters({...globalFilters});
      delete cloneGlobalFilters.dateRangeValues;
      delete cloneGlobalFilters.dateRange; 
      return store.dispatch(getEvaluationData("", { filters: { ...cloneGlobalFilters } })).then(res => {
        callback(res.evaluated_count === 0 ? "-" : res.evaluated_count);
      });
    }
    let cubeJSQuery = qualityAssessmentAggregateQueryBuilder({
        ...query,
        globalFilters,
        query,
        filterbyValues: {},
    })
    
    cubeJSApi().then(api => {
        api.load(
            cubeJSQuery
        ).then((resultSet) => {
            callback(resultSet.pivot()[0].yValuesArray[0][1])
        });
    })
}

export const createStackedChart = (stackBy, query, stackedChartData, onComplete) => {
    let base_query = {...query}
    delete base_query.stackBy;
    let cubeJSQuery = qualityAssessmentQueryBuilder(base_query);
    let xValues = []
    let segments = []
    
    switch(stackBy) {
        case queryOptions.groupByOptions.IS_FLAGGED: {
            segments = [
                {
                    filter: {
                        "dimension": "Metrics.flags",
                        "operator": "gte",
                        "values": ["1"]
                    }
                },
                {
                    filter: {
                        "dimension": "Metrics.flags",
                        "operator": "equals",
                        "values": ["0"]
                    }
                }
            ]
        }

        case queryOptions.groupByOptions.CHANNEL: {
            segments = [
                {
                    filter: {
                        "dimension": "LevelAsrAsrlog.channel",
                        "operator": "equals",
                        "values": ["1"]
                    }
                },
                {
                    filter: {
                        "dimension": "LevelAsrAsrlog.channel",
                        "operator": "equals",
                        "values": ["2"]
                    }
                },
                {
                    filter: {
                        "dimension": "LevelAsrAsrlog.channel",
                        "operator": "equals",
                        "values": ["3"]
                    }
                },
                {
                    filter: {
                        "dimension": "LevelAsrAsrlog.channel",
                        "operator": "equals",
                        "values": ["4"]
                    }
                }
            ]
        }
    }

    let chartDataBuilder = (resultSet, query) => stackedChartData(
        resultSet, 
        getMeasureText(query.measure), 
        query.isTimeUnit,
        query.onTimeUnitUpdate
    )
    
    cubeJSApi().then(api => {
        api.load(
            cubeJSQuery
        ).then((resultSet) => {
            let chartData = chartDataBuilder(resultSet, query)
            xValues = chartData.map(value=>value.x);
            let result = []
            let segmentsComplete = 0;
            segments.forEach((segment) => {
                let _query = {...query};
                _query.filterbyValues.agents = xValues;
                let segmentQuery = qualityAssessmentQueryBuilder({
                    ..._query,
                    segment: segment.filter,
                })

                cubeJSApi().then(api => {
                    api.load(
                        segmentQuery
                    ).then((resultSet) => {
                        let chartData = chartDataBuilder(resultSet, query)
                        result = [...result, ...chartData]
                        segmentsComplete += 1;
                        if (segmentsComplete == segments.length) {
                            onComplete(result);
                        }
                    }
                )
                })
                
            })
        });
    })
}

export const decideAnalyticsObj=(filterBy,e)=>{
    if(filterBy==="CATEGORY"){
        return {
            event:HeapAnalyticsEvent.Detect_Category,
            value:e,
            type:"Category"
        }
    } 
    else if (filterBy==="SECTION"){
        return {
            event:HeapAnalyticsEvent.Detect_Section,
            value:e,
            type:"Section"
        }
    } 
    else {
        return {}
    }
}

export const agent_filter = (app_user) => {
    return {
      dimension: `AccountsUser.id`,
      operator: "equals",
      values: [`${app_user.id}`],
    };
  };
  
export const role_config = {
    manager: {
      ConversationChart: {
        title: chartsTitle.Interactions_By_Agent,
        chart_type: "interval",
      },
      HandlingTimeChart: {
        title: chartsTitle.Average_Handling_Time,
        chart_type: "interval",
      },
      QAScoreChart: {
        title: chartsTitle.Average_QA_Score_Agent,
        chart_type: "interval",
      },
    },
    default: {
      ConversationChart: {
        title: chartsTitle.Interactions_Over_Time,
        chart_type: "interval",
      },
      HandlingTimeChart: {
        title: chartsTitle.Average_Handling_Time,
        chart_type: "interval",
      },
      QAScoreChart: {
        title: chartsTitle.QA_Score_Over_Time,
        chart_type: "interval",
      },
    },
};

export const getCurrentUser= () => {
    return store.getState().app_user;
};

export const showTeamDashboard = () => getCurrentUser().is_manager || getCurrentUser().is_superuser || getCurrentUser().is_qa_auditor;
  
export const getCustomColumns = () => {
    return new Promise((resolve, reject) => {
        if (store.getState().dashboard.customColumns) {
            return resolve(store.getState().dashboard.customColumns)
        }
        cubeJSApi().then(api => {
            api.meta().then((res) => {
                const customColumns = res.meta.cubes.filter((cube) => cube.name === `CustomColumns`)[0]?.dimensions || [];
                const customColumnsMeasures = res.meta.cubes.filter((cube) => cube.name === `CustomColumns`)[0]?.measures || [];
                customColumns.forEach((col, i) => {
                    customColumns[i].name = customColumns[i].name.replace(`CustomColumns`, `CustomColumns`);
                })
                customColumnsMeasures.forEach((col,i) => {
                  customColumnsMeasures[i].name = customColumnsMeasures[i].name.replace(`CustomColumns`, `CustomColumns`);
                })

                store.dispatch({
                    type: ReduxActionTypes.SET_CUSTOM_COLUMNS,
                    payload: {
                        value: customColumns,
                    }
                })
                store.dispatch({
                  type: ReduxActionTypes.SET_CUSTOM_COLUMNS_MEASURES,
                  payload: {
                      value: customColumnsMeasures,
                  }
                })
                resolve(customColumns)
            })
        }).catch((err) => {
            reject(err)
        })
    })
}

const filterByCategory = (additionalQueryParam, drillDownFilters,filters, segments) => {
    let filteredItem;
    filters.categories && filters.categories.length && delete drillDownFilters.channel;
    filteredItem = filters.categories && filters.categories.length ? segments.categories.find(item => item.category === filters.categories[0]) : {};
    additionalQueryParam += filteredItem?.asr_log__channel ? `&channel=${filteredItem.asr_log__channel}` : "";
    return [additionalQueryParam, drillDownFilters]
}

const filterBySection = (additionalQueryParam ,drillDownFilters, filters, segments) => {
    let filteredItem;
    filters.sections && filters.sections.length && delete drillDownFilters.channel;
    filteredItem = filters.sections && filters.sections.length ? segments.sections.find(item => item.section === filters.sections[0]) : {};
    additionalQueryParam += filteredItem?.asr_log__channel ? `&channel=${filteredItem.asr_log__channel}` : "";
    return [additionalQueryParam, drillDownFilters];
}   

const decideFilterBy = (additionalQueryParam ,drillDownFilters, filters, segments, data, item, propData) => {
        if(propData.query.stackBy) {
            if(propData.query.stackBy === queryOptions.groupByOptions.IS_FLAGGED) {
                item.data.color?.split(',')[0] === "true" ? additionalQueryParam += "&is_Flagged=True" : additionalQueryParam += "&is_Flagged=False";
            } 
        }

        if(propData.query.isTimeSeries && (hasManagerRights() || hasQAAuditorRights())) {
            try {
                if(/^\d+$/.test(item.data?.color?.split(',')[0])) {
                    drillDownFilters.user = item.data?.color?.split(',')[0];
                }
            }
            catch(ex) {}
        }

        if(queryOptions.filterByOptions.CATEGORY === propData.filterBy) {
            [additionalQueryParam, drillDownFilters] = filterByCategory(additionalQueryParam, drillDownFilters, filters, segments);
        }

        else if(queryOptions.filterByOptions.SECTION === propData.filterBy) {
            [additionalQueryParam, drillDownFilters] = filterBySection(additionalQueryParam, drillDownFilters, filters, segments);
        }

        return [additionalQueryParam, drillDownFilters];
}

export const createAdditionalParam = (additionalQueryParam ,drillDownFilters, item, filters, segments, data, propData, filterSegments) => {
    switch(propData.query.measure) {
        case queryOptions.measureOptions.RAW_SCORE : { 
                                        const qaStatusItem = filterSegments?.qaStatusList?.find(item => item.name === EvaluationStatus.EVALUATED.statusType);
                                        additionalQueryParam += `&qa_status=${qaStatusItem?.id || 1}`;
                                        [additionalQueryParam, drillDownFilters] = decideFilterBy(additionalQueryParam, drillDownFilters, filters, segments, data, item, propData);
                                        break;
                                    }                         
        default: { 
            [additionalQueryParam, drillDownFilters] = decideFilterBy(additionalQueryParam, drillDownFilters, filters, segments, data, item, propData);
            break;        
        }   
    }

    return [additionalQueryParam, drillDownFilters];
}

export const handlePathGenerator = (drillDownFilters, item, drilldownBy, removableKeysArr, filters, segments, data, propData, filterSegments) => {
    let additionalQueryParam= "";
    let updatedDrilldownFilters;
    [additionalQueryParam, updatedDrilldownFilters] = createAdditionalParam(additionalQueryParam, drillDownFilters, item, filters, segments, data, propData, filterSegments);
    if(drilldownBy === queryOptions.drilldownByOptions.DATE) {
        updatedDrilldownFilters.user && updatedDrilldownFilters.user[0] === undefined && delete updatedDrilldownFilters.user;
        let dateObj = { date : [ moment(item.data.x).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]"), moment(item.data.x).format("YYYY-MM-DDTHH:mm:ss.SSSS[Z]") ] }
        if(drillDownFilters.timeGranularity !== "day") {
          dateObj.date[1] = moment(item.data.x).add(1,drillDownFilters.timeGranularity)
        } 
        dateObj.date = JSON.stringify( dateObj.date );
        let path=`/conversations?`.concat(queryString.stringify(omit( updatedDrilldownFilters, removableKeysArr ))).concat("&"+queryString.stringify(dateObj)).concat(additionalQueryParam ? additionalQueryParam : "");
        const newWindow = window.open(path, "_blank");
        newWindow.focus();
        return;
    }
    let path=`/conversations?`.concat(queryString.stringify( omit( updatedDrilldownFilters, removableKeysArr ))).concat(`&${drilldownBy}=${item.data.x}`).concat(additionalQueryParam ? additionalQueryParam : "");
    const newWindow = window.open(path, "_blank");
    newWindow.focus();
}

export const handleDrilldown = (drillDownFilters, item, drilldownBy, filters, segments, data, propData, filterSegments,customFilters) => {
  let urlFilters = {...drillDownFilters}
    let filtersToRemove = ["agents", "dateRange", "dateRangeValues", "workspace"]

    if(drilldownBy === queryOptions.groupByOptions.AGENT.toLowerCase()) {
        drilldownBy = queryOptions.drilldownByOptions.USER;
    }

    if(drilldownBy === queryConfigs.groupByOptions.AGENT.toLowerCase()) {
        drilldownBy = queryConfigs.drilldownByOptions.USER;
    }

    store.getState().dashboard.customColumns && store.getState().dashboard.customColumns.forEach(customColumn => {
        if (customColumn.name.toLowerCase() === drilldownBy) {
            drilldownBy = customColumn.shortTitle;
        }
        if (urlFilters[customColumn.name]) {
            let cFilter = customFilters.find(cf => cf.pretty_name === customColumn.shortTitle);
            let name = cFilter?.field_name || "";
            urlFilters[name] = urlFilters[customColumn.name];
            delete urlFilters[customColumn.name];
        }
    })

    if (urlFilters.date !== "" && !urlFilters.date && urlFilters.dateRangeValues) {
        urlFilters.date = [
            urlFilters.dateRangeValues.from_date?.toISOString(),
            urlFilters.dateRangeValues.to_date?.toISOString(),
        ]
    }
    if (urlFilters.conversation_tag_id || drilldownBy === "conversation_tag_id") {
        let conversation_tag_id = urlFilters.conversation_tag_id || item.data.x
        urlFilters.conversation_tag = segments.scripting_rules.filter(rule=>rule.id==conversation_tag_id)[0]?.title;
    }
    if(urlFilters.insta_review) {
      urlFilters.insta_review_topics = urlFilters.insta_review;
      delete urlFilters["insta_review"];
    }
    
    if (urlFilters.transcript) {
        urlFilters.query = urlFilters.transcript
    }

    if (urlFilters.agents) {
        urlFilters.user = urlFilters.agents
    }

    handlePathGenerator(urlFilters, item, drilldownBy, filtersToRemove.concat(drilldownBy), filters, segments, data, propData, filterSegments);
}

export const shouldEnableDrilldown = ({query}, globalFilters = null) => {
    const groupByOption = Array.isArray(query?.groupBy) ? query?.groupBy[0] : query?.groupBy
    if(globalFilters && (globalFilters['question'] || globalFilters['section'] || globalFilters['qa_category'])) return false;
    
    if(groupByOption === "QUESTION") return false;
    
    if (query.disableDrilldown) {
        return false;
    }
    if(groupBy[groupByOption]?.drilldown === false) {
        return false;
    }
    
    if(groupByOption?.startsWith("CustomColumns.")) {
        const column = groupByOption.split("CustomColumns.")[1];
        const customFiltersList = store.getState().dashboard?.customFilters?.map(item => item.field_name) || [];
        if (customFiltersList?.length && !customFiltersList[column]) {
            return false;
        }
    }
    return true;
}


export const getTimezoneApprox = () => {
    const permittedTimezones = [
        {
            tzName: 'America/Los_Angeles',
            offset: 480
        },
        {
            tzName: 'America/Chicago',
            offset: 360
        },
        {
            tzName: 'UTC',
            offset: 0
        },
        {
            tzName: 'Asia/Kolkata',
            offset: -330
        },
        {
            tzName: 'Asia/Manila',
            offset: -480
        },
        {
            tzName: 'Australia/Melbourne',
            offset: -600
        }
    ]
    const localOffset = new Date().getTimezoneOffset();

    let nearestTimezone = permittedTimezones[0]
    let nearestTimezoneOffset = 1000;
    permittedTimezones.forEach(timezone => {
        if (Math.abs(timezone.offset - localOffset) < nearestTimezoneOffset) {
            nearestTimezone = timezone;
            nearestTimezoneOffset = Math.abs(timezone.offset - localOffset);
        }
    })
    return nearestTimezone.tzName;
}