import React, { useEffect } from "react";
import TimeRestriction from "../../commons/time-restriction";
import styled from "@emotion/styled/macro";
import { Radio } from 'antd';

const TimeRestrictionPropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
    .custom-time {
        display: flex;
        align-items: center;
        margin-top: 10px;
        div:first-of-type {
            width: 100px;
        }
        .ant-select {
            width: 100px;
        }
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const TimeRestrictionProperty = (props) => {
    const getInitialValue = (ruleProperties) => {
        if(ruleProperties.time_restriction === undefined) {
            return {
                timeRestriction: ruleProperties.time_policy,
                customTime: ""
            };
        }
        return {
            timeRestriction: ruleProperties.time_restriction && ruleProperties.time_restriction !== 999999999  ? "custom" : "any",
            customTime: ruleProperties.time_restriction === 999999999 ? "" : ruleProperties.time_restriction,
        }
    }
    const initialValues = getInitialValue(props.ruleProperties);
    const [timeRestriction, setTimeRestriction] = React.useState(initialValues.timeRestriction);
    const [showValidations, toggleShowValidations] = React.useState(false);
    const [isCustom, setIsCustom] = React.useState(initialValues.timeRestriction === "custom");
    const [customTime, setCustomTime] = React.useState(initialValues.customTime);
    const handleChange = (event) => {
        const value = event.target.value;
        setTimeRestriction(value);
        if(value === "custom") {
            setIsCustom(true);
        }
        else {
            setIsCustom(false);
            props.handleChange({
                type: value,
                label: "Any",                
            });
        }
    }
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])
    return (
        <TimeRestrictionPropertyWrapper>
            <Radio.Group className="radio-group" name="radiogroup" defaultValue={timeRestriction} onChange={handleChange}>
                {props.options.map((item) => {
                    return <Radio value={item.value}>{item.label}</Radio>
                })}                
            </Radio.Group>
            {isCustom ? <div className="custom-time">
                <TimeRestriction 
                    onValuesChange={(value) => {
                        setCustomTime(value.currentTarget.value);
                        props.handleChange({
                            type: "start",
                            label: "Custom Time",
                            value: value.currentTarget.value,
                        });
                    }} 
                    defaultValue={customTime}                         
                />
            </div> : null}
            {showValidations && !timeRestriction && <div className="error-message">Please select time restriction</div>}     
        </TimeRestrictionPropertyWrapper>
    )
}

export default TimeRestrictionProperty;