import { MAX_MINUTES } from "./config";

export const scrollToElement = (id) => {
  document.getElementById(`${id}`).scrollIntoView({
    behavior: "smooth",
    block: "nearest",
  });
};

export const convertToUtc_Local_Mins = (time, conversionType = "utc") => {
  //always send time as hh:mm
  const convertedTime = time?.split(":");
  const _hour = parseInt(convertedTime[0]);
  const _minute = parseInt(convertedTime[1]?.split(" ")[0]);
  const totalMinutes = _hour * 60 + _minute;
  let offset = new Date().getTimezoneOffset();
  let minutesWithOffset;
  if(conversionType === "utc") {
    minutesWithOffset = totalMinutes + offset
  }
  else if(conversionType === "local") {
    offset = offset > 0 ? -Math.abs(offset) : offset < 0 ?  Math.abs(offset) : 0;
    minutesWithOffset = totalMinutes + offset;
    if(minutesWithOffset > MAX_MINUTES) {
      minutesWithOffset = Math.abs(minutesWithOffset - MAX_MINUTES);
    }
  }
  let { hour, minute, generatedTime } = convertMinsToTime(minutesWithOffset);
  return {
    hour,
    minute,
    generatedTime,
    minutesWithOffset,
    totalMaxMinutes: totalMinutes + offset
  };
}

export const convertMinsToTime = (minutesWithOffset) => {
  let hour, minute, time;
  if(minutesWithOffset < 0) {
    let temp = 24 - (Math.abs(minutesWithOffset) / 60);
    hour = Math.floor(temp);
    minute = (temp * 60) % 60;
  }
  else {
    if(minutesWithOffset > MAX_MINUTES) {
      minutesWithOffset = minutesWithOffset - MAX_MINUTES;
    }
    hour =  Math.floor(Math.abs(minutesWithOffset) / 60);
    minute = Math.abs(minutesWithOffset) % 60;
  }
  let _hour = hour < 10 ? "0" + hour : hour;
  let _minute = minute < 10 ? "0" + minute : minute;
  time = _hour + ":" + _minute;
  return {
    hour, 
    minute,
    generatedTime: time
  }
}
