import { CALIBRATION_STATUS_TYPES } from "../../QaCalibration/config";
import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "./../../constants/ReduxActionConstants";


const initialState = {
  dataSourceOptions: null,
  tableData: [],
  isEditMode: {
    is_enabled: false,
    data: {}
  }
};
const selfServeCustomFilters = createReducer(initialState, {
  [ReduxActionTypes.SET_DATASOURCE_OPTIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      dataSourceOptions: action.payload
    };
  },
  [ReduxActionTypes.SET_SELF_SERVE_CUSTOM_FILTER_DATA]: (
    state,
    action,
  ) => {
    return {
      ...state,
      tableData: action.payload
    };
  },
  [ReduxActionTypes.SET_EDIT_MODE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      isEditMode: action.payload
    };
  }
});

export default selfServeCustomFilters;
