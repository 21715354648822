import { createReducer } from "../util";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import {
  decryptDataFromStorage,
  encryptAndSendToStorage,
} from "../../utils/utilities";
//var CryptoJS = require("crypto-js");

const initialState = {
  tasks: {
    count: 0,
    next: null,
    previous: null,
    tableData: null,
  },
};

const taskQueueReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_MY_TASKS]: (state, action) => {
    const { count, next, previous, tableData } = action.payload;
    sessionStorage.setItem(
      "taskQueueData",
      JSON.stringify({ tableData, count, next, previous })
    );

    return {
      ...state,
      tasks: { count, next, previous, tableData },
    };
  },
  [ReduxActionTypes.SET_TASK_QUEUE_TABLE_DATA]: (state, action) => {
    let sessionStorageData = JSON.parse(
      sessionStorage.getItem("taskQueueData")
    );
    sessionStorage.setItem(
      "taskQueueData",
      JSON.stringify({
        ...sessionStorageData,
        tableData: [...action.payload],
      })
    );
    return {
      ...state,
      tasks: {
        ...state.tasks,
        tableData: [...action.payload],
      },
    };
  },
  [ReduxActionTypes.SET_TASK_QUEUE_COUNT]: (state, action) => {
    const { count } = action.payload;

    let sessionStorageData = JSON.parse(
      sessionStorage.getItem("taskQueueData")
    );
    sessionStorage.setItem(
      "taskQueueData",
      JSON.stringify({ ...sessionStorageData, count })
    );

    return {
      ...state,
      tasks: {
        ...state.tasks,
        count: count,
      },
    };
  },
  [ReduxActionTypes.RESET_TASK_QUEUE]: (state, action) => {
    sessionStorage.removeItem("taskQueueData");

    return {
      ...state,
      tasks: { count: 0, next: null, previous: null, tableData: null },
    };
  },
});

export default taskQueueReducer;
