import React, { useState } from 'react'
import Constants from '../../utils/constants'
import RulePopup from "../../components/RulePopup";
import IntentInnerPopup from "./intent-popup";
import styled from "@emotion/styled/macro";

const PopupWrapper = styled.div`
    position: relative;
    display: ${props => props.show ? "block" : "none"};
`;

const IntentPopup = ({ onSubmit, onCancel, isHistorical, id, type, tabType, rules, viewOnly, intentType }) => {
    const [ showScriptPopup, toggleScriptPopup ] = useState(false);
    const [ showFlagPopup, toggleFlagPopup ] = useState(false);
    const [ showIntentPopup, toggleIntentPopup ] = useState(true);
    const addScriptPopup = () => {
        toggleIntentPopup(false);
        toggleScriptPopup(true);
    }
    const addFlagPopup = () => {
        toggleIntentPopup(false);
        toggleFlagPopup(true);
    }        
    return (
        <React.Fragment>
            <PopupWrapper show={showIntentPopup}>
                <IntentInnerPopup
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    isHistorical={isHistorical}
                    id={id}
                    type={type}
                    addScriptPopup={addScriptPopup}
                    addFlagPopup={addFlagPopup}
                    tabType={tabType}
                    rules={rules}
                    viewOnly={viewOnly}
                    intentType={intentType}
                />
            </PopupWrapper>
            <PopupWrapper show={showScriptPopup}>
                <RulePopup 
                    onCancel={() => {
                        toggleScriptPopup(false)
                        toggleIntentPopup(true);
                    }} 
                    onSubmit={() => {
                        toggleScriptPopup(false)
                        toggleIntentPopup(true);
                    }} 
                    actionTypes={[Constants.HINT]}
                    negate={false}
                />
            </PopupWrapper>
            <PopupWrapper show={showFlagPopup}>
                <RulePopup 
                    onCancel={() => {
                        toggleFlagPopup(false);
                        toggleIntentPopup(true);
                    }} 
                    onSubmit={() => {
                        toggleFlagPopup(false);
                        toggleIntentPopup(true);
                    }} 
                    actionTypes={[Constants.FLAG, Constants.WARNING]}
                    negate={true}
                />
            </PopupWrapper>
        </React.Fragment>
    )
}

export default IntentPopup;
