import React from "react";
import {  Button, Modal } from 'antd';
import styled from "@emotion/styled/macro";

const TitleWrapper = styled.div`
    font-size: 20px;
    line-height: 23px;
    color: #4F4F4F;
`;

const DescriptionWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-top: 8px;
    text-align: center;
    width: 100%;
`;

const StyledButton = styled(Button)`
    width: 166px;
    font-size: 16px;
    text-align: center;
    width: 100%;
`;

const ConfirmWrapper = styled.div`
    width: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .action-buttons {
        display: flex;
        width: 100%;
        margin-top: 24px;
        button {
            font-size: 16px;
            line-height: 19px;
            height: 43px;
            border: 1px solid #0B4B93;
        }
        button:last-of-type {
            margin-left: 4px;
            border: none;
        }
    }
`;

const ConfirmPopup = ({ show, title, message, onClose }) => {
    return (
        <Modal
            visible={show}
            header = {null}
            closable={false}
            title={null}
            width={388}
            footer={null}
            >
            <ConfirmWrapper>
                <TitleWrapper>{title}</TitleWrapper>
                <DescriptionWrapper>{message}</DescriptionWrapper>
                <div className="action-buttons">
                    <StyledButton onClick={() => onClose(false)}>
                        Cancel
                    </StyledButton>
                    <StyledButton type="danger" onClick={() => onClose(true)}>
                        Delete
                    </StyledButton>
                </div>
            </ConfirmWrapper>
        </Modal>
    )                
}

export default ConfirmPopup;