import React from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as SetupIcon } from "./../../assets/popup-icon.svg";
import { ReactComponent as HelpTextIcon } from "./../../assets/helptext-icon.svg";
import { Button } from "antd";

const SetupHelperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    .icon {
        svg {
            width: 324px;
            height: 129px;
        }
        width: 324px;
        height: 129px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        margin-top: 24px;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #4F4F4F;
        padding: 0;
    }
    .info {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #828282;
    }
    .footer {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        button {
            width: 166px;
            height: 42px;
            border: 1px solid #0B4B93;
        }
        .primaryButton {
            margin-left: 5px;
            border: none;
            font-weight: normal;
            background: #0B4B93;
        }
        button.full-width {
            margin: 0;
            width: 337px;
        }
    }
    .help-texts {
        margin: 6px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .help-text-item {
            margin-top: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            svg {
                margin-right: 8px;
                width: 20px;
                height: 20px;                
            }
            .help-text {
                font-size: 14px;
                line-height: 16px;
                color: #4F4F4F;
                text-align: left;
                width: 100%;                
                span {
                    color: #2D9CDB;
                }
                b {
                    font-weight: 500;        
                }
            }
        }
    }
`;

const SetupHelper = (props) => {
    const { illustration: IllustrationIcon } = props;
    return (
        <SetupHelperWrapper>
            <div className="icon">{props.illustration ? <IllustrationIcon /> : <SetupIcon />}</div>
            <div className="title">{props.title}</div>
            <div className="info">{props.description}</div>
            {props.popupHelpText?.length ? <div className="help-texts">
                {props.popupHelpText.map((text, index) => (
                    <div key={index} className="help-text-item">
                        <HelpTextIcon /><div className="help-text" key={index} dangerouslySetInnerHTML={{__html: text}} />
                    </div>
                ))}
            </div> : null}
            {!props.showDone ? <div className="footer">
                <Button onClick={props.onCancel}>Back to Setup</Button>
                {props.buttonName ? <Button className="primaryButton" onClick={props.onOpen} type="primary">{props.buttonName}</Button> : null}
            </div> 
            :
            <div className="footer">
                <Button onClick={props.onDone} className="primaryButton" type="primary full-width">{props.doneTitle}</Button>
            </div>
            }
        </SetupHelperWrapper>
    )
}

export default SetupHelper;