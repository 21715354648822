import React, { useEffect } from "react";
import { HeapAnalyticsEvent } from "../../components/common/HeapAnalyticsEvent";
import { addAnalyticsEvent } from "../../utils";
import { AgentAnalytics } from "./agent-analytics";
import { TeamQA } from "./team-qa";

export const AgentQA = (props) => {
  useEffect(() => {
    addAnalyticsEvent(HeapAnalyticsEvent.Detect_Agent_QA_Score_Page_Visit);
  }, []);
  return (
    <>
      <AgentAnalytics {...props} TeamView={TeamQA} noHeapEvent={true} />
    </>
  );
};
