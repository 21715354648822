import React, { useState, useEffect } from 'react'
import { Select } from "antd";
import {addAnalyticsEvent} from '../../../src/utils';
import {HeapAnalyticsEvent} from '../../components/common/HeapAnalyticsEvent';

export const TimeSeriesSelect = (props) => {
    const [value, setvalue] = useState(props.value)

    useEffect(() => {
        setvalue(props.value)
    }, [props.value])

    return (
        <Select
            style={{ width: 170 }}
            placeholder={"Time Series"}
            onChange={(value) => {
                addAnalyticsEvent(HeapAnalyticsEvent.Detect_View,{viewType:value ? "Time Series View" : "Consolidated View"})
                if (value) {
                    props.showComparisonView(() => {props.onChange(value)})
                } else {
                    props.onChange(value)
                }
            }}
            value={value}
        >
            <Select.Option value={true}>Time Series View</Select.Option>
            <Select.Option value={false}>Consolidated View</Select.Option>
        </Select>
    )
}