import React from "react";
import styled from "@emotion/styled/macro";
import { Button, Modal } from "antd";

export const CSVWarningModal = styled(Modal)`
  & p {
    margin-bottom: 0.5rem;
  }
  & .ant-modal-title {
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 700;
  }
  & .ant-modal-header {
    border: none;
  }
  & .ant-modal-body {
    padding: 0 24px;
    padding-bottom: 16px;
  }
  & .footer {
    margin-top: 1.5rem;
    & .secondary {
      background: #f2f2f2;
      border: none;
    }
  }
`;

const WarningModal = ({ show, downloadCsv, onClose,maxLimit,rowName="row" }) => {
  return (
    <CSVWarningModal
      title="There was a problem with the export"
      visible={show}
      onOk={downloadCsv}
      onCancel={(e)=>{e.stopPropagation();onClose()}}
      footer={null}
    >
      <div>
        <p>The data set is too large </p>
        <p>You can still export top {maxLimit.toLocaleString('en-US')} {rowName} </p>
        <p>
          Alternatively you can try applying some filters to reduce the data set
        </p>
      </div>
      <div className="footer">
        <Button
          className="ant-btn-primary me-2"
          onClick={(e) => {
            e.stopPropagation();
            downloadCsv();
            onClose();
          }}
        >
          Export top {maxLimit.toLocaleString('en-US')} {rowName}
        </Button>
        <Button
          className="secondary"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          I'll apply filters
        </Button>
      </div>
    </CSVWarningModal>
  );
};

export default WarningModal;
