import appConfigs from "../../../config";
import { ReactComponent as CustomerTagIcon } from "../../assets/conversation-tags/customer-tag.svg";
import { ReactComponent as AgentTagIcon } from "../../assets/conversation-tags/agent-tag.svg";
import { ReactComponent as DynamicTagIcon } from "../../assets/conversation-tags/dynamic-tag.svg";

export const userHistory = [
    {
         title: "Home", 
         link: "/"
    },
    {
         title: "Setup",
         link: "/setup"
    },
    {
        title: "Conversation Tags",
        link: "/setup/conversation-tags"
    },
];

export const TagTypes = [
    {
        label: "Customer Tag",
        icon: CustomerTagIcon,
        value: "customer",
    },
    {
        label: "Agent Tag",
        icon: AgentTagIcon,
        value: "agent",
    },
    {
        label: "Dynamic Tag",
        icon: DynamicTagIcon,
        value: "dynamic",
    }
];

export const channelOptions = {
  id: "channel",
  title: "What channels is this active on?",
  options: [
      {
          value: "Call",
          label: "Call"
      },
      {
          value: "Email",
          label: "Email"
      },
      {
        value: "Chat",
        label: "Chat"
      },
  ]
};
export const ConverationTagBuildConfigurations = [
    {
        id: "customer",
        label: "Customer Tag",
        properties: [
            {
                id: "tagType",
                options: TagTypes,
                title: "What type of tag is this?"
            },
            {
                id: "scenario",
                options: [
                    {
                        value: "phrase",
                        label: "Exact Match"
                    },
                    {
                        value: "topic",
                        label: "Speaker Intent"
                    }
                ],
                speaker: "customer",
                title: "What are we looking for from the customer?"
            },
            {
                id: "time_restriction",
                title: "When is this important?",
                options: [
                    {
                        value: "any",
                        label: "Any Time"
                    },
                    {
                        value: "custom",
                        label: "Custom"
                    }
                ]
            },
            ...(appConfigs.ENABLE_CHANNEL_BASED_CONVERSATION_TAGS ?  [channelOptions] : []),
        ]
    },
    {
        id: "agent",
        label: "Agent Tag",
        properties: [
            {
                id: "tagType",
                options: TagTypes,
                title: "What type of tag is this?"
            },
            {
                id: "expectations",
                options: [
                    {
                        value: "should",
                        label: "Agent Should"
                    },
                    {
                        value: "should_not",
                        label: "Agent Should Not"
                    },
                    {
                        value: "neutral",
                        label: "No, just tag when this happens"
                    }
                ],
                title: "Are there any expectations around this behavior?"
            },
            {
                id: "scenario",
                speaker: "assistant",
                options: [
                    {
                        value: "phrase",
                        label: "Exact Match"
                    },
                    {
                        value: "topic",
                        label: "Speaker Intent"
                    }
                ],
                title: "What are we looking for from the agent?"
            },
            {
                id: "time_restriction",
                title: "When is this important?",
                options: [
                    {
                        value: "any",
                        label: "Any Time"
                    },
                    {
                        value: "custom",
                        label: "Custom"
                    }
                ]
            },
            {
                id: "actions",
                title: "How should we assist the agent in real time?",
                showOnly: (props) => {
                    return props.expectation_type === "should_not" || props.expectation_type === "should";
                },
                options: [
                    { 
                        value: "hint", 
                        label: "Provide hint for Agent",
                    },
                    { 
                        value: "flag", 
                        label: "Show flag to Agent", 
                        showOnly: (props) => {
                            return props.expectation_type === "should_not";
                        },
                    }              
                ]
            },
            ...(appConfigs.ENABLE_CHANNEL_BASED_CONVERSATION_TAGS ?  [channelOptions] : []),
        ]
    },
    {
        id: "dynamic",
        label: "Dynamic Tag",
        properties: [
            {
                id: "tagType",
                options: TagTypes,
                title: "What type of tag is this?"
            },
            {
                id: "conditions",
                speaker: "customer",
                options: [
                    {
                        value: "phrase",
                        label: "Exact Match"
                    },
                    {
                        value: "topic",
                        label: "Speaker Intent"
                    }
                ],
                title: "For what customer scenario is this relevant?",
            },
            {
                id: "expectations",
                options: [
                    {
                        value: "should",
                        label: "Agent Should"
                    },
                    {
                        value: "should_not",
                        label: "Agent Should Not"
                    },
                    {
                        value: "neutral",
                        label: "No, just tag when this happens"
                    }
                ],
                title: "Are there any expectations for how to respond?",
            },
            {
                id: "scenario",
                speaker: "assistant",
                options: [
                    {
                        value: "phrase",
                        label: "Exact Match"
                    },
                    {
                        value: "topic",
                        label: "Speaker Intent"
                    }
                ],
                title: "What are we looking for from the agent?",
            },
            {
                id: "time_restriction",
                title: "When is this important?",
                options: [
                    {
                        value: "any",
                        label: "Any Time"
                    },
                    {
                        value: "custom",
                        label: "Custom"
                    }
                ]
            },
            {
                id: "actions",
                title: "How should we assist the agent in real time?",
                showOnly: (props) => {
                    return props.expectation_type === "should_not" || props.expectation_type === "should";
                },
                options: [
                    { 
                        value: "hint", 
                        label: "Provide hint for Agent",                        
                    },
                    { 
                        value: "flag", 
                        label: "Show flag to Agent",
                    }                    
                ]                
            },
            ...(appConfigs.ENABLE_CHANNEL_BASED_CONVERSATION_TAGS ?  [channelOptions] : []),
        ]
    }
];
