import React, { useState, useEffect } from "react";
import { HeapAnalyticsEvent } from "../../components/common/HeapAnalyticsEvent";
import { addAnalyticsEvent } from "../../utils";
import { Chart } from "../chart";
import { AnalyticsSummary } from "../components/analytics-summary";
import { ChartWrapper, ChartListWrapper } from "../components/wrapper";
import { queryOptions } from "../dynamic-query-builder";
import { chartsTitle } from "../utils";

export const TeamQA = (props) => {
  const { defaultChartType, timeSeries } = props;
  const isTimeSeries = timeSeries || false;
  const timeSeriesSelectable = !timeSeries;

  const restProps = {
    isConsolidated: props.isConsolidated,
    showComparisonView: props.showComparisonView,
    isChartTypeEditable: true,
    chartType: defaultChartType || "interval",
  };
  useEffect(() => {
    if (!props.noHeapEvent)
      addAnalyticsEvent(HeapAnalyticsEvent.Detect_Team_QA_Score_Page_Visit);
  }, []);
  return (
    <ChartListWrapper>
      <AnalyticsSummary
        queries={[
          {
            measure: queryOptions.measureOptions.TOTAL_EVALUATIONS,
            groupBy: queryOptions.groupByOptions.NONE,
          },
          {
            title: chartsTitle.Average_QA_Score,
            measure: queryOptions.measureOptions.RAW_SCORE,
            groupBy: queryOptions.groupByOptions.NONE,
          },
          // {
          //     measure: queryOptions.measureOptions.PASS_RATE,
          //     groupBy: queryOptions.groupByOptions.NONE,
          // },
          {
            measure: queryOptions.measureOptions.AVERAGE_CSAT,
            groupBy: queryOptions.groupByOptions.NONE,
          },
          {
            title: chartsTitle.Average_AUTO_INSTASCORE,
            measure: queryOptions.measureOptions.AUTO_INSTASCORE,
            groupBy: queryOptions.groupByOptions.NONE,
          }
        ]}
      />
      <ChartWrapper>
        <Chart
          query={{
            measure: queryOptions.measureOptions.RAW_SCORE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          }}
          {...restProps}
          isChartTypeEditable={true}
          title={chartsTitle.Average_QA_Score_Agent}
          filterBy={queryOptions.filterByOptions.NONE}
          chartType={defaultChartType || "interval"}
          drilldownQueries={[]}
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></Chart>
      </ChartWrapper>

      <ChartWrapper>
        <Chart
          query={{
            measure: queryOptions.measureOptions.CATEGORY_SCORE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          }}
          {...restProps}
          title={chartsTitle.Average_Categories_Score}
          isChartTypeEditable={true}
          filterBy={queryOptions.filterByOptions.CATEGORY}
          chartType={defaultChartType || "interval"}
          drilldownQueries={[]}
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></Chart>
      </ChartWrapper>

      <ChartWrapper>
        <Chart
          query={{
            measure: queryOptions.measureOptions.SECTION_SCORE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          }}
          {...restProps}
          title={chartsTitle.Average_Sections_Score}
          isChartTypeEditable={true}
          filterBy={queryOptions.filterByOptions.SECTION}
          chartType={defaultChartType || "interval"}
          drilldownQueries={[]}
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></Chart>
      </ChartWrapper>

      <ChartWrapper>
        <Chart
          query={{
            measure: queryOptions.measureOptions.QUESTION_SCORE,
            groupBy: queryOptions.groupByOptions.AGENT,
            isTimeSeries,
            timeSeriesSelectable,
          }}
          {...restProps}
          title={chartsTitle.Average_Questions_Score}
          isChartTypeEditable={true}
          filterBy={queryOptions.filterByOptions.NONE}
          chartType={defaultChartType || "interval"}
          drilldownQueries={[]}
          //drilldownBy = { queryOptions.drilldownByOptions.USER }
        ></Chart>
      </ChartWrapper>
    </ChartListWrapper>
  );
};
