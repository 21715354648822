import React, { useEffect } from "react";
import AgentAssist from './AgentAssist';
// import { Link } from "react-router-dom";
// import { logoutUser } from "../utils/utilities";
import "./scss/twilio-plugin.scss";
import styled from "@emotion/styled/macro";
import { ReactComponent as LevelIcon } from "./../assets/level-new-logo.svg";
import { ReactComponent as HelpCentreIcon } from "./../assets/help-centre.svg";
import {withRouter} from "react-router-dom";
import * as QueryString from "query-string";
import ErrorBoundary from "../components/ErrorBoundary";
import { salesforceLogin, getSchemaName } from "../api/api";
import {
  ReduxActionTypes,  
} from "../constants/ReduxActionConstants";
import { connect } from "react-redux";
import { zendeskSSO } from "../api/api";
import { HeaderRow } from "./StyledComponents";
import { setItemInStore } from "../utils";

const PluginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  .ant-timeline, .seach-tab-results {
    height: calc(100vh - 105px);
    min-height: calc(100vh - 105px);
    max-height: calc(100vh - 105px);
    padding: 0;
  }
`;

function addUserIdentity(data){
  if(window.heap.identify) {
    window.heap.identify(data.id);
  }
  let schema = data.schema_name;
  if(!schema) {
    schema = getSchemaName();
  }
  if(window.heap.addUserProperties) {
    window.heap.addUserProperties({
      Name: `${data.first_name} ${data.last_name}`,
      email: data.email,
      tenant: schema,   
    });
  }
}

const AssistPlugin = (props) => {
  const [loading, setLoading] = React.useState(true);
  const queryParams = QueryString.parse(props.location.search);
  const onHelp = () => {
    zendeskSSO("https://thelevelai.zendesk.com/hc/en-us").then((res) => {
      window.open(res.location, '_blank');
    });
  }
  useEffect(() => {
    if(props.authorizationRequired) {
      const params = {
        platform: queryParams?.platform,
        platformId: queryParams?.platformId,
        sessionExpired: true,
      };
      if(queryParams.authToken) {
        salesforceLogin(queryParams).then(response => {
          const data = response.data;
          if (data.token) {
            props.storeUser(data);
            if(window.heap) {
              addUserIdentity(data);
            }
            else {
              document.addEventListener('heap.ready', (e) => {
                addUserIdentity(data);
              });              
            }
            try {          
              setItemInStore('conversationState', null)
            } catch(errr) {}
          } else {
            throw new Error("incorrect");
          }
        })
        .catch(err => {
          window.location.href = `/plugin/login/?${QueryString.stringify(params)}`;     
        });
      }
      else {
        window.location.href = `/plugin/login/?${QueryString.stringify(params)}`;             
      }
    }    
    else if(props.user.token) {
      setLoading(false);
    }
  }, [props.authorizationRequired]);
  const groupCardsByContext = queryParams?.platform === "salesforce";
  return (
    <ErrorBoundary>
      <PluginWrapper>
          <HeaderRow groupCardsByContext={groupCardsByContext}>
            <div className="logo">
              <LevelIcon />
              <div id="conversation-status" />
            </div>
            {props.user.token && <div className="left-header" onClick={onHelp}><HelpCentreIcon /> Help Center</div>}
          </HeaderRow>            
          {!loading && props.user.token ? <AgentAssist isAgent={true} queryParams={queryParams}/> : null }
      </PluginWrapper>
    </ErrorBoundary>
  )
}

const mapStateToProps = state => ({ 
  user: state.app_user,
  authorizationRequired: !state.app_user.isLoading && !state.app_user.token,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch({ 
      type: ReduxActionTypes.LOGOUT_USER,        
    })
  },
  storeUser: (userDetails) => {
    dispatch({ 
      type: ReduxActionTypes.STORE_USER_DETAILS,
      payload: {
        userDetails
      }
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssistPlugin));
