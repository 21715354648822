import React from "react";
import { Button } from "antd";
import PhraseInput from "./PhraseInput";
import styled from "@emotion/styled/macro";
import { HorizontalButtonWrapper } from "../../../components/common/styled-components";

const ConditionTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #4f4f4f;
  margin-bottom: 8px;
`;

const PhrasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .add-btn {
    margin-top: 10px;
  }
`;

const AnnotatedPhrases = ({ phrases, removePhrase, viewOnly }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  if (!phrases || phrases.length === 0) return null;
  return (
    <PhrasesWrapper>
      <ConditionTitleWrapper>Level AI Phrases</ConditionTitleWrapper>
      {phrases
        .slice(0, isCollapsed ? 3 : phrases.length)
        .map((phrase, index) => (
          <PhraseInput
            key={phrase.id}
            index={index}
            phrase={phrase}
            removePhrase={removePhrase}
            disabled={true}
            removable={viewOnly === true ? false : true}
            onChange={() => {}}
          />
        ))}

      <HorizontalButtonWrapper>
        {phrases.length > 3 ? (
          isCollapsed ? (
            <Button
              className="add-btn right"
              type="link"
              onClick={() => setIsCollapsed(false)}
            >
              See all {phrases.length} phrases
            </Button>
          ) : (
            <Button
              className="add-btn right"
              type="link"
              onClick={() => setIsCollapsed(true)}
            >
              Collapse
            </Button>
          )
        ) : null}
      </HorizontalButtonWrapper>
    </PhrasesWrapper>
  );
};

export default AnnotatedPhrases;
