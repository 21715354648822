import moment from "moment";
import { getSchemaName } from "../../api/api";
import { queryOptions } from "../dynamic-query-builder";
import { queryConfigs } from '../query-config';
import { addSchemaName, Constants } from "../query-builder/config";
import { getAgentFullName, getQAAuditorFullName, getChannelText, getMeasureText, getMedian, getScriptingRuleName, roundOff } from "../utils";

export const trimLabel = (text, length = 20) => {
    var trimmedName = text?.length > length ? 
                        text?.substring(0, length - 3) + "..." : 
                        text;
    return trimmedName;
}

export const Color = {
    PRIMARY: "#2d9cdb",
    PALETTE: ["#e99243", "#2d9cdb", "#db554d", "#3CD1A4", "#3CA8D1", "#89D13C"],
    FLAGGED: "#db554d",
    UNFLAGGED: "#2d9cdb",
    CHAT: "#DB554D",
    CALL: "#6099D9",
    SMS: "#27AE60",
    SLACK: "#BB6BD9",
    EMAIL: "#E29046",
    RESOLVED: "#2d9cdb",
    UNRESOLVED: "#e99243"
}

const getColorAndLabel = (dimension, value) => {
    const mapping = {
        [addSchemaName(Constants.METRICS.IS_FLAGGED)]: {
            "true": {
                color: Color.FLAGGED,
                label: "Flagged"
            },
            "false": {
                color: Color.UNFLAGGED,
                label: "Unflagged"
            }
        },
        [addSchemaName(Constants.IS_RESOLVED)]: {
            "Resolved": {
                color: Color.RESOLVED,
                label: "Resolved"
            },
            "Unresolved": {
                color: Color.UNRESOLVED,
                label: "Unresolved"
            }
        }
    }
    if (mapping[dimension] && mapping[dimension][value]) {
        return mapping[dimension][value] || {
            label: value,
            color: Color.PRIMARY
        };
    }
    let hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
    let colorValue = hashCode(JSON.stringify(value))
    return {
        label: value,
        color: Color.PALETTE[colorValue % Color.PALETTE.length]
    }
}

export const formatRawValue = (val, groupBy, isTimeSeries, {agents, scripting_rules, qa_auditors},ellipses) => {
    let formattedValue = val;
    if (isTimeSeries) {
        formattedValue = moment(val).format("MMM DD")
    } else {
        switch(groupBy) {
            case queryOptions.groupByOptions.AGENT: {
                formattedValue = getAgentFullName(agents, val)
                break
            }
            case queryOptions.groupByOptions.CHANNEL: {
                formattedValue = getChannelText(val)
                break
            }
            case queryOptions.groupByOptions.CONVERSATION_TAG_ID: {
                formattedValue = getScriptingRuleName(scripting_rules, val)
                break
            }
            case queryOptions.groupByOptions.OUTCOME: {
                formattedValue = val === "[Empty string]" ? "N/A" : val
                break
            }
            case queryOptions.groupByOptions.IS_FLAGGED: {
                formattedValue = val === "true" ? "Flagged" : "Unflagged"
                break
            }
            // FOR NOW, NEED TO MERGE ALL CONFIGS INTO ONE
            case queryConfigs.groupByOptions.AGENT: {
                formattedValue = getAgentFullName(agents, val)
                break
            }
            case queryConfigs.groupByOptions.CHANNEL: {
                formattedValue = getChannelText(val)
                break
            }
            case queryConfigs.groupByOptions.CONVERSATION_TAG_ID: {
                formattedValue = getScriptingRuleName(scripting_rules, val)
                break
            }
            case queryConfigs.groupByOptions.OUTCOME: {
                formattedValue = val === "[Empty string]" ? "N/A" : val
                break
            }
            case queryConfigs.groupByOptions.IS_FLAGGED: {
                formattedValue = val === "true" ? "Flagged" : "Unflagged"
                break
            }
            case queryConfigs.groupByOptions.QA_AUDITOR: {
                formattedValue = getQAAuditorFullName(qa_auditors, val)
                break
            }
        }
    }
    if(ellipses){
      return trimLabel(formattedValue)
    }
    return formattedValue
}

export const formatMultiDimensionValue = (val, dimensions, isTimeSeries, {agents, scripting_rules, qa_auditors}, ellipses, isCompareDateRange=false) => {
    let formattedValue = [];
    
    if (isTimeSeries) {
        formattedValue = [moment(val[0]).format("MMM DD")]
    } else if(isCompareDateRange) {
        if(dimensions.length > 0) {
            val.forEach(xValue => {
                if(typeof xValue === "string" && xValue.includes(' - ')) {
                    let dateRangeIndex = val.findIndex(e => e.includes(' - '));
                    val.splice(dateRangeIndex, 1);
                }
            });

            formattedValue = [formatRawValue(val, dimensions[0], isTimeSeries, {agents, scripting_rules}, ellipses)];
        } else {
            if(Array.isArray(val)) {
                val.forEach(xValue => {
                    if(typeof xValue === "string" && xValue.includes(' - ')) {
                        let dateRange = xValue?.split(' - ');
                        let dateValueStart = new Date(dateRange[0]).toDateString().split(" ");
                        let dateValueEnd = new Date(dateRange[1]).toDateString().split(" ");
                        let dateValue = [`${dateValueStart[1]} ${dateValueStart[2]}, ${dateValueStart[3]}`, `${dateValueEnd[1]} ${dateValueEnd[2]}, ${dateValueEnd[3]}`].join(' - ');
                        formattedValue = [...formattedValue, dateValue];
                        let dateRangeIndex = val.findIndex(e => e.includes(' - '));
                        val.splice(dateRangeIndex, 1);
                    }
                })
    
                dimensions.forEach((dimension, index) => {
                    switch(dimension) {
                        case queryOptions.groupByOptions.AGENT: {
                            formattedValue = [...formattedValue, getAgentFullName(agents, val[index])]
                            break
                        }
                        case queryOptions.groupByOptions.CHANNEL: {
                            formattedValue = [...formattedValue, getChannelText(val[index])]
                            break
                        }
                        case queryOptions.groupByOptions.CONVERSATION_TAG_ID: {
                            formattedValue = [...formattedValue, getScriptingRuleName(scripting_rules, val[index])]
                            break
                        }
                        case queryOptions.groupByOptions.OUTCOME: {
                            formattedValue = val[index] === "[Empty string]" ? [...formattedValue, "N/A"] : [...formattedValue, val[index]]
                            break
                        }
                        case queryOptions.groupByOptions.IS_FLAGGED: {
                            formattedValue = val[index] === "true" ? [...formattedValue, "Flagged"] : [...formattedValue, "Unflagged"]
                            break
                        }
                        // FOR NOW, NEED TO MERGE ALL CONFIGS INTO ONE
                        case queryConfigs.groupByOptions.AGENT: {
                            formattedValue = [...formattedValue, getAgentFullName(agents, val[index])]
                            break
                        }
                        case queryConfigs.groupByOptions.CHANNEL: {
                            formattedValue = [...formattedValue, getChannelText(val[index])]
                            break
                        }
                        case queryConfigs.groupByOptions.CONVERSATION_TAG_ID: {
                            formattedValue = [...formattedValue, getScriptingRuleName(scripting_rules, val[index])]
                            break
                        }
                        case queryConfigs.groupByOptions.OUTCOME: {
                            formattedValue = val[index] === "[Empty string]" ? [...formattedValue, "N/A"] : [...formattedValue, val[index]]
                            break
                        }
                        case queryConfigs.groupByOptions.IS_FLAGGED: {
                            formattedValue = val[index] === "true" ? [...formattedValue, "Flagged"] : [...formattedValue, "Unflagged"]
                            break
                        }
                        case queryConfigs.groupByOptions.QA_AUDITOR: {
                            formattedValue = getQAAuditorFullName(qa_auditors, val)
                            break
                        }
                        default:
                            formattedValue = [...formattedValue, val[index]]
                    }
                    if(ellipses){
                        formattedValue[index] = trimLabel(formattedValue[index]);
                    }
                })
            } else {
                let dateRange = val?.split(' - ');
                let dateValueStart = new Date(dateRange[0]).toDateString().split(" ");
                let dateValueEnd = new Date(dateRange[1]).toDateString().split(" ");
                let dateValue = [`${dateValueStart[1]} ${dateValueStart[2]}, ${dateValueStart[3]}`, `${dateValueEnd[1]} ${dateValueEnd[2]}, ${dateValueEnd[3]}`].join(' - ');
                formattedValue = [dateValue]
            }
        }
    } else {
        dimensions.forEach((dimension, index) => {
            switch(dimension) {
                case queryOptions.groupByOptions.AGENT: {
                    formattedValue = [...formattedValue, getAgentFullName(agents, val[index])]
                    break
                }
                case queryOptions.groupByOptions.CHANNEL: {
                    formattedValue = [...formattedValue, getChannelText(val[index])]
                    break
                }
                case queryOptions.groupByOptions.CONVERSATION_TAG_ID: {
                    formattedValue = [...formattedValue, getScriptingRuleName(scripting_rules, val[index])]
                    break
                }
                case queryOptions.groupByOptions.OUTCOME: {
                    formattedValue = val[index] === "[Empty string]" ? [...formattedValue, "N/A"] : [...formattedValue, val[index]]
                    break
                }
                case queryOptions.groupByOptions.IS_FLAGGED: {
                    formattedValue = val[index] === "true" ? [...formattedValue, "Flagged"] : [...formattedValue, "Unflagged"]
                    break
                }
                // FOR NOW, NEED TO MERGE ALL CONFIGS INTO ONE
                case queryConfigs.groupByOptions.AGENT: {
                    formattedValue = [...formattedValue, getAgentFullName(agents, val[index])]
                    break
                }
                case queryConfigs.groupByOptions.CHANNEL: {
                    formattedValue = [...formattedValue, getChannelText(val[index])]
                    break
                }
                case queryConfigs.groupByOptions.CONVERSATION_TAG_ID: {
                    formattedValue = [...formattedValue, getScriptingRuleName(scripting_rules, val[index])]
                    break
                }
                case queryConfigs.groupByOptions.OUTCOME: {
                    formattedValue = val[index] === "[Empty string]" ? [...formattedValue, "N/A"] : [...formattedValue, val[index]]
                    break
                }
                case queryConfigs.groupByOptions.IS_FLAGGED: {
                    formattedValue = val[index] === "true" ? [...formattedValue, "Flagged"] : [...formattedValue, "Unflagged"]
                    break
                }
                case queryConfigs.groupByOptions.QA_AUDITOR: {
                    formattedValue = [...formattedValue, getQAAuditorFullName(qa_auditors, val[index])]
                    break
                }
                default:
                    formattedValue = [...formattedValue, val[index]]
            }
            if(ellipses){
                formattedValue[index] = trimLabel(formattedValue[index]);
            }
        })
    }
    return isCompareDateRange && !Array.isArray(val) ? formattedValue.join('-') : formattedValue.join(',');
}

export const getTimeRange = (val) => {
    let dateRange = val?.split(' - ');
    let dateValueStart = new Date(dateRange[0]).toDateString().split(" ");
    let dateValueEnd = new Date(dateRange[1]).toDateString().split(" ");
    let dateValue = [`${dateValueStart[1]} ${dateValueStart[2]}, ${dateValueStart[3]}`, `${dateValueEnd[1]} ${dateValueEnd[2]}, ${dateValueEnd[3]}`].join(' - ');
    return dateValue;
}

export const setCompareChartData = (data, primaryRange) => {
    return data.filter(el => el?.x?.length).map((item, index) => {
        return item
    }).sort((a,b) => {
        if(a.range === primaryRange && b.range === primaryRange) {
            if(a.measure - b.measure) return -1;
            else if(b.measure - a.measure) return 1;
            else return 0;
        }
    })
}

const getColorFromMeasure = (measure) => {
    const mapping = {
        [addSchemaName(Constants.CONVERSATION.flaggedCount)]: Color.FLAGGED,
        [addSchemaName(Constants.CONVERSATION.unflaggedCount)]: Color.UNFLAGGED,
        [addSchemaName(Constants.QA_EVALUATIONS.chatCount)]: Color.CHAT,
        [addSchemaName(Constants.QA_EVALUATIONS.emailCount)]: Color.EMAIL,
        [addSchemaName(Constants.QA_EVALUATIONS.callCount)]: Color.CALL,
        [addSchemaName(Constants.QA_EVALUATIONS.slackCount)]: Color.SLACK,
        [addSchemaName(Constants.QA_EVALUATIONS.smsCount)]: Color.SMS,
    }
    return(mapping[measure])
}

export const getColor = (colorValues, colorKeys) => {
    const value = colorValues[0].split(', ')[0];
    switch(colorValues[0].split(', ').length) {
        case 1:
            return getColorFromMeasure(colorValues[0])
        default:
            const colors = Color.PALETTE;
            switch(colorKeys[0].key) {
                case addSchemaName(Constants.USER.id): {
                    return colors[value % colors.length]
                }
                default: {
                    return getColorAndLabel(colorKeys[0].key, value).color
                }
            }
    }
}

export const getMultiGraphColor = (colorValues, colorKeys, measures, dimensions, isCompareDateRange) => {
    const value = colorValues[0].split(', ')[0];

    if(isCompareDateRange && Array.isArray(dimensions) && dimensions.length > 0) {
        if(value == 1) {
            return Color.PRIMARY;
        } else {
            return Color.PALETTE[0];
        }
    } else {    
        let measureIndex = measures.findIndex(el => el == value);

        if(measureIndex == 0) {
            return Color.PRIMARY;
        } else {
            return Color.PALETTE[0];
        }
    }
}

export const placeHolderChartData = [
    {
        Conversations: 3,
        color: "false, levelConversation.count",
        colorKeys: [],
        measure: 3,
        measureText: "Conversations",
        x: "13"
    }
]

export const getLabel = (text, data, segments) => {
    const labelElements = text.split(', ')
    const value = labelElements[0]
    const labelKeys = data[0].colorKeys

    if (labelElements.length === 1) {
        return getMeasureText(labelElements[0])
    }
    else if (labelElements.length === 2) {
        switch(labelKeys[0].key) {
            case addSchemaName(Constants.USER.id): {
                return getAgentFullName(segments.agents, value)
            }
            case addSchemaName(Constants.CONVERSATION_TAG.rule_id): {
                return getScriptingRuleName(segments.scripting_rules, value)
            }
            case addSchemaName(Constants.CONVERSATION.channel): {
                return getChannelText(value)
            }
            default: {
                return getColorAndLabel(labelKeys[0].key, value).label
            }
        }
    }
    return text
}

export const sortChartData = (data) => {
    let sortDS = {}
    data.forEach(el => {
        if (sortDS[el.x]) {
            sortDS[el.x] = sortDS[el.x] + el.measure
        } else {
            sortDS[el.x] = el.measure
        }
    })

    var sortable = [];
    for (var x in sortDS) {
        sortable.push([x, sortDS[x]]);
    }

    sortable = sortable.sort(function(a, b) {
        return a[1] - b[1];
    });

    const indices = sortable.map(x=>x[0])
    data = data.sort(function(a, b) {
        return indices.indexOf(a.x) - indices.indexOf(b.x)
    })
    return data
}

export const stackedChartData = (resultSet, measureText, isTimeUnit, onTimeUnitUpdate) => {
    let mt = measureText;
    let max_aht = 0;
    let median_aht = 0;

    if (isTimeUnit) {
        const measures = resultSet
        .pivot()
        .map(({ xValues, yValuesArray }) =>
            yValuesArray.map(([yValues, m]) => {
                return m
        })).map(x=>Number(x[0]))
        max_aht = Math.max(...measures)
        median_aht = getMedian([...measures])
    }

    const data = resultSet
      .pivot()
      .map(({ xValues, yValuesArray }) =>
        yValuesArray.map(([yValues, m]) => {
            let measure = m && Number(roundOff(m))
            if (isTimeUnit) {
                const unitOptions = ["mins", "hours", "days", "secs"]
                const text = measureText;
                let divider = 1;
                if (median_aht > 60 && median_aht < 60*60) {
                    mt = `${text} (${unitOptions[0]})`
                    divider = 60
                } else if (median_aht > 60*60 && median_aht < 60*60*24) {
                    mt = `${text} (${unitOptions[1]})`
                    divider = (60*60)
                } else if (median_aht > 60*60*24) {
                    mt = `${text} (${unitOptions[2]})`
                    divider = (60*60*24)
                } else {
                    mt = `${text} (${unitOptions[3]})`
                }
                measure = measure / divider;
                if (onTimeUnitUpdate) {
                    onTimeUnitUpdate(mt, divider)
                }
            }
            return {
                x: resultSet.axisValuesString(xValues),
                color: resultSet.axisValuesString([...xValues.slice(1), ...yValues], ', '),
                colorKeys: [...resultSet.tableColumns().slice(1)].map(x=>x),
                [mt]: measure,
                measure: measure,
                measureText: mt
            }
        }
        )
      )
      .reduce((a, b) => a.concat(b), []);
    return data;
};
