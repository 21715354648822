import { frequencyOptions, pullDataOptions } from "../../CompanySetup/components/QaCaseAssignment/config";
import { createReducer } from "../util";
import { ReduxActionTypes } from "./../../constants/ReduxActionConstants";

export const getRuleBlockState = () => {
  return {
    ruleBlocksData: {
      nameAndScheduleObject: {
        ruleName: "",
        frequency: frequencyOptions.WEEKLY.label,
        day_of_week: "1",
        time: "7:00",
        day_of_month: undefined,
        pullDataFieldValue: pullDataOptions[frequencyOptions.WEEKLY.label][0],
        _pullDataOptions: pullDataOptions[frequencyOptions.WEEKLY.label],
      },
      agentsObject: {
        agentsGroup: [],
        agentsList: [],
      },
      filters_meta: [
        {
          id: 1,
          limit: 3,
          logical_op: "AND",
          filter_groups: [
            {
              id: 1,
              logical_op: "AND",
              filters: [
                {
                  id: 1,
                  val: null,
                  filterKey: "",
                  operator: "",
                },
              ],
            },
          ],
        },
      ],
      qaAuditorsList: [],
    },
  }
}
export const initialState = {
  tableData: [],
  mode: "create",
  ...getRuleBlockState(),
  isEditEnabled: false,
  showErrorState: false
};
const qaCaseAssignmentReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_QA_CASE_ASSIGNMENT_TABLE_DATA]: (state, action) => {
    return {
      ...state,
      tableData: action.payload,
    };
  },
  [ReduxActionTypes.SET_QA_CASE_ASSIGNMENT_MODE]: (state, action) => {
    return {
      ...state,
      mode: action.payload,
    };
  },
  [ReduxActionTypes.SET_QA_IS_EDIT_ENABLED]: (state, action) => {
    return {
      ...state,
      isEditEnabled: action.payload,
    };
  },
  [ReduxActionTypes.SET_QA_RULE_NAME]: (state, action) => {
    //const {key, value} = action.payload;
    return {
      ...state,
      ruleBlocksData: {
        ...state.ruleBlocksData,
        nameAndScheduleObject: {
          ...state.ruleBlocksData.nameAndScheduleObject,
          ruleName: action.payload,
        }
      },
    };
  },
  [ReduxActionTypes.SET_QA_FREQUENCY_OBJECT]: (state, action) => {
    //const {key, value} = action.payload;
    return {
      ...state,
      ruleBlocksData: {
        ...state.ruleBlocksData,
        nameAndScheduleObject: {
          ...state.ruleBlocksData.nameAndScheduleObject,
          ...action.payload,
        },
      },
    };
  },
  [ReduxActionTypes.SET_QA_AGENTS]: (state, action) => {
    return {
      ...state,
      ruleBlocksData: {
        ...state.ruleBlocksData,
        agentsObject: {
          ...state.ruleBlocksData.agentsObject,
          ...action.payload
        },
      },
    };
  },
  [ReduxActionTypes.SET_QA_FILTERS_META]: (state, action) => {
    return {
      ...state,
      ruleBlocksData: {
        ...state.ruleBlocksData,
        filters_meta: [...action.payload],
      },
    };
  },
  [ReduxActionTypes.SET_QA_QA_AUDITORS_LIST]: (state, action) => {
    return {
      ...state,
      ruleBlocksData: {
        ...state.ruleBlocksData,
        qaAuditorsList: [...action.payload],
      },
    };
  },
  [ReduxActionTypes.SET_QA_ERROR_STATE]: (state) => {
    return {
      ...state,
      showErrorState: true
    };
  },
  [ReduxActionTypes.RESET_QA_STATE]: (state) => {
    return {
      ...state,
      ...getRuleBlockState(),
      isEditEnabled: false,
      showErrorState: false,
      mode: "create",
    };
  },
});

export default qaCaseAssignmentReducer;
