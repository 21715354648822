import React from 'react';
import { UserMessageWrapper } from "./wrappers";
import { ReactComponent as AgentIcon } from "./../assets/AgentIconLatest.svg";
import { ReactComponent as CustomerIcon } from "./../assets/Customer.svg";

export const UserMessage = (props) => {
  return (
    <UserMessageWrapper>
      {props?.Speaker === "assistant" ? <AgentIcon id = "conversation-history-agent-icon"/> : <CustomerIcon id = "conversation-history-customer-icon"/> }
      <div className="message-details">
        <div
          className="message-description"
          dangerouslySetInnerHTML={{ __html: props?.Text }}
        />

      </div> {/* end message-details div */}
    </UserMessageWrapper> /* end UserMessageWrapper */
  );
};
