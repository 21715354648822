import styled from "@emotion/styled/macro";
import { StyledTable } from "./../components/common/styled-components";
import { keyframes } from "emotion";

export const DashboardWrapper = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 42px);
`;

export const DashboardHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 23px;
`;

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.setMarginBottom ? -15 : 0}px;
`;

export const DashboardTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #828282;
  margin-top: 9px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  background:#fafafa;
`;

export const AnalyticMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 162px);
  overflow: auto;
  padding: 8px 16px;
  width: 66%;
`;

export const MetricsWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.isManager ? 291 : 438)}px;
  min-height: ${(props) => (props.isManager ? 291 : 438)}px;
`;

export const EvaluationBlockWrapper = styled.div`
  display: flex;
  min-height: 190px;
`

export const LoadingAnalyticMetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 162px);
  width: 66%;
`;

export const LevelMetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
`;

export const ScoreCardWrapper = styled.div`
  margin: 0 6px;
  width: 100%;
`;

export const LineSeparator = styled.div`
  width: calc(100% - 48px);
  margin: 8px auto;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const InteractionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  padding: 16px 24px;
  width: 34%;
  overflow: auto;
  height: calc(100vh - 162px);
`;

export const InteractionReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  position: relative;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
`;

export const InteractionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  pointer-events: ${props => props.paginatedLoading ? "none": "all"};
  opacity: ${props => props.paginatedLoading ? "0.5": "1"};
  scroll-behavior: smooth;
`;

export const InteractionsListLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ReloadAnimation= keyframes `
0%   {transform: rotate(0deg)}
25%  {transform: rotate(90deg)}
50%  {transform: rotate(120deg)}
75%  {transform: rotate(180deg)}
100% {transform: rotate(360deg)}
`;

export const ReloadIcon = styled.span `
  // .icon-loading > svg {
  //   transform: rotate(0deg);
  //   animation: ${ReloadAnimation} 4s infinite;
  //   -webkit-animation: ${ReloadAnimation} 4s infinite;
  // }
  margin-left: 5px;
  svg {
    fill: black;
    width: 20px;
    height: 13px;
  }
`
export const TitleWrapper = styled.div`
  height: 36px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 12px 21px;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;

export const DashboardChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  min-height: 400px;
  height: 400px;
`;

export const ChartWrapper = styled.div`
  display: flex;
  padding: 30px 40px 40px;
  height: 400px;
`;

export const MultiChartWrapper = styled.div`
  display: flex;
  padding: 30px 40px 40px;
  height: 500px;
`;

export const LoadingChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px 40px;
  height: 400px;
`;

export const DashboardChartFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 50px;
  position: absolute;
  top: 70px;
  z-index: 1;
  &&& .ant-select-selection {
    border-color: #e0e0e0;
  }

  &&& .ant-select-selection-selected-value {
    color: #828282;
  }
`;

export const DashboardFiltersWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
  width: 100%;
`;

export const ListAggregatedata = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #828282;
  span {
    color: #bdbdbd;
    margin-left: 3px;
  }
`;

export const QAWrapper = styled.div`
  width:100%;
  position:relative;
  background:#fafafa;
  padding: 15px;
`;

export const StyledConversationTable = styled(StyledTable)`
  margin-top: 13px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 20%) !important;
`;
export const QATableWrapper = styled(StyledConversationTable)`
  .ant-table-column-title {
    color: #bdbdbd;
    white-space: nowrap;
  }
  td {
    font-size: 12px;
  }

  .ant-table-row {
    height: 64px;
  }
  .ant-table-title {
    padding: 15px;
    font-weight: 500;
    background: white;
    border-bottom: 2px solid #F2F2F2;
  }
`;

export const DescendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #6099d9;
    }
  }
`;

export const AscendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    path {
      fill: #6099d9;
    }
  }
`;

export const SortIconWrapper = styled.div`
  margin-left: 1px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #bdbdbd;
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const SortableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 21px;
  cursor: pointer;
  color: ${(props) =>
    props.order === "dsc" || props.order === "asc" ? "#6099D9" : "#BDBDBD"};
`;

export const EmptyStateWrapper= styled.div`
      height:100%;
      display:flex;
      align-items:center;
      justify-content: center;
`;
