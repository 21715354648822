import React, { Component } from 'react';
import { message, Modal } from 'antd';
import WorkspaceSetup from "./workspace-setup";
import PopUp from "./../commons/pop-up";
import SetupHeader from "../commons/SetupHeader";
import WorkspaceList from "./workspace-list";
import SearchComponent from "./../../../components/common/SearchComponent";
import ConfirmPopup from "./../../components/commons/ConfirmPopup";
import { 
  PrimaryStyledButton, 
  StyledCheckbox
} from "./../../../components/common/styled-components";
import { 
  getWorkspaceList,
  updateWorkspaceStatus,
  addUsersToWorkspace,
  deleteWorkspace,
} from "./../../api/api";
import { 
  SetupWrapper, 
  FilterWrapper, 
  SpaceBetweenWrapper,
  LineSeparator,  
} from "./../commons/wrappers";
import { connect } from "react-redux";
// import SetupHelper from "../commons/SetupHelper";
// import appConfig from "../../../config";
export class WorkspaceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaceData: null,
      tableData: [],
      is_active: undefined,
      isLoading: true,
      showConfirm: false,
      page: 1,
      showInfo: false,      
    };
    this.dataSource = [];
  }
  componentDidMount() {
    this.fetchWorkspacesList();
    // try {
    //   if(!window.localStorage.getItem('workspace-management')) {
    //     window.localStorage.setItem('workspace-management', true);
    //     this.setState({ showInfo: true })
    //   }
    // } catch(e) {}
  }
  trackScrolling = () => {
    const tabDocument = document.getElementsByClassName("setup-tab-workspace")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0]
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    }
    if(node && isBottom(node)) {
      node.removeEventListener('scroll', this.trackScrolling);
      this.setState({
        page: this.state.page + 1,
        isLoading: true
      }, () => {
        this.fetchWorkspacesList();
      })
    }
  }
  fetchWorkspacesList = (userFilters) => {
    const { page, is_active, name } = userFilters ? userFilters : this.state;
    const filters = {
      page,
      is_active: is_active ? true : undefined,
      name: name ? name : undefined,
    };
    console.log({ filters });
    const tabDocument = document.getElementsByClassName("setup-tab-workspace")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener('scroll', this.trackScrolling);
    getWorkspaceList(filters).then(res => {
      if(res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if(node) {
          node.addEventListener('scroll', this.trackScrolling);
        }
      }
      let workspacesList = res.results.filter((item) => item.subdomain !== "global") || [];
      let data = this.state.page === 1 ? workspacesList : this.dataSource.concat(workspacesList);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });      
    });
  }
  showDeleteConfirm = (data) => {
    this.setState({ showConfirm: true, workspaceData: data });
  }
  applyFilters = () => {
    this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
      this.dataSource = [];
      this.fetchWorkspacesList();
    })    
  }

  handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    this.setState({ is_active: isChecked }, this.applyFilters);
  }

  handleSearchValue = (value) => {
    this.setState({ "name" : value }, this.applyFilters);
  }

  handleDropdownState = (type, value) => {
    this.setState({ [type]: value }, this.applyFilters);
  }

  addWorkspace = () => {
    this.setState({ createWorkspace: true, showInfo: false });
  }

  updateWorkspace  = () => {

  }

  deleteWorkspace = (id) => {
    deleteWorkspace(id).then((res) => {
      message.success("Workspace is deleted!");
      this.setState({ isLoading: true, tableData: [], page: 1, workspaceData: null }, () => {
        this.dataSource = [];
        this.fetchWorkspacesList();
      })
    })
  }

  editWorkspace = (record) => {
    console.log(record);
    this.setState({ editWorkspace: true, workspaceData: record });
  }

  changeWorkspaceStatus = (id, isActive) => {
    updateWorkspaceStatus(id, isActive).then((res) => {
      this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
        this.dataSource = [];
        this.fetchWorkspacesList();
      })    
    })
  }

  handleSubmit = () => {
    const { name, desc, id } = this.state.workspaceData;
    const data = {
      name,
      desc,
      is_active: true,
      avatar: "",
      id,
      users: [],
    };
    addUsersToWorkspace(data).then((res) => {
      message.success("Workspace is added!");
      this.setState({ editWorkspace: false, isLoading: true, tableData: [], page: 1 }, () => {
        this.dataSource = [];
        this.fetchWorkspacesList();
      });
    });
  }

  render() {
    const workspaceData = this.state.workspaceData || {};
    const tableData = this.state.tableData;    
    return (
      <SetupWrapper className="setup-tab-workspace">
        <SetupHeader 
          title="Workspace Management" 
          icon="workspace" 
          description="Manage multiple projects from one place" 
          openInfo={() => this.setState({ showInfo: true })}
        />
        <SpaceBetweenWrapper>
          <FilterWrapper>
            <SearchComponent 
                placeholder="Search workspaces..."
                value=""
                handleSearchValue={this.handleSearchValue}
            />         
            {/* <StyledCheckbox onChange={this.handleCheckbox.bind(this)}>Hide Inactive</StyledCheckbox> */}
          </FilterWrapper>
          <PopUp
            isOpen={this.state.editWorkspace || this.state.createWorkspace}
            trigger={PrimaryStyledButton}
            component={WorkspaceSetup}
            triggerProps={{
              title:"+ Add Workspace"              
            }}
            componentProps={{
              width: 650,
              roles: [],
              workspaceData: workspaceData || {},
              updateWorkspaceData: (data) => {
                this.setState({ workspaceData: data });
              }
            }}
            primaryBtnName={this.state.editWorkspace ? "Update" : "Save"}
            secondaryBtnName="Cancel"
            onCancel={() => {
              this.setState({ workspaceData: null, editWorkspace: false });
            }}
            onSubmit={() => {
              this.handleSubmit();                            
            }}
            onSubmitValidated={
                workspaceData.name 
                && workspaceData.desc
            }
          />
        </SpaceBetweenWrapper>
        <LineSeparator />
        <WorkspaceList 
          tableData={tableData}  
          isLoading={this.state.isLoading}       
          roles={this.state.roles}
          editWorkspace={this.editWorkspace}
          updateWorkspace={this.updateWorkspace}
          deleteWorkspace={this.showDeleteConfirm}  
          changeWorkspaceStatus={this.changeWorkspaceStatus}        
        />
        <ConfirmPopup 
            show={this.state.showConfirm}
            title="Delete Workspace"
            message={`Are you sure you would like to permantly delete this workspace`}
            onClose={(done) => {
                if(done){
                  this.deleteWorkspace(workspaceData.id);                    
                }
                this.setState({ showConfirm: false, workspaceData: null });
            }}
        />   
        {/* <Modal
          visible={this.state.showInfo}
          header = {null}
          closable={false}
          title={null}
          width={500}
          footer={null}
        >
          <SetupHelper 
            title="Workspaces"
            description="Manage multiple projects from one place"
            buttonName="Add a Workspace"
            illustration={UserManagementIllustration}
            onCancel={() => this.setState({ showInfo: false })} 
            onOpen={this.addUser} 
          />          
        </Modal> */}
      </SetupWrapper>
    )
  }
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps, null)(WorkspaceManagement);
// appConfig.showWorkspaceTab
