import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Radio } from 'antd';

const PropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
`;

const TagTypeProperty = (props) => {
    const [tagType, setTagType] = React.useState(props.ruleProperties?.tagType);
    const [showValidations, toggleShowValidations] = React.useState(false);
    const handleChange = (event) => {
        const value = event.target.value;
        setTagType(value);
        props.handleChange({
            type: value,
            label: props.options.find((item) => item.value === value).label,
        });
    }
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])
    return (
        <PropertyWrapper>
            <Radio.Group className="radio-group" name="radiogroup" defaultValue={tagType} onChange={handleChange}>
                {props.options.map((item) => {
                    return <Radio value={item.value}>{item.label}</Radio>
                })}                
            </Radio.Group>            
            {showValidations && !tagType && <div className="error-message">Please choose tag type to continue.</div>}     
        </PropertyWrapper>
    )
}

export default TagTypeProperty;