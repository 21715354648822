import { createReducer } from "../util";
import { TenantActionTypes } from "./../../constants/ReduxActionConstants";

const initialState = {
  schemaName : "level",
  tenantList: []
};
const tenantReducer = createReducer(initialState, {
  [TenantActionTypes.SET_SCHEMA]: (state, action) => {
    return {
      ...state,
      schemaName: action.payload
    };
  },
  [TenantActionTypes.SET_TENANT_LIST]: (state, action) => {
    return {
      ...state,
      tenantList: [...new Set(
        [...state.tenantList, ...action.payload]
      )]
    }
  }
});

export default tenantReducer;
