import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";
import { useSelector } from "../../store";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";
import { UserRoles } from "../../CompanySetup/components/user-management/config";

const QaAuditorFilter = ({ value, multiSelect, ...rest }) => {
  const [qaAuditor, updateqaAuditor] = useState(value);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    updateqaAuditor(value);
  }, [value]);
  
  return !multiSelect ? (
    <FilterComponent
      width={rest.width || 200}
      placeholder="Select QA Auditor"
      value={qaAuditor}
      maxTagCount={1}
      multiSelect={multiSelect}
      showSearchIcon={true}
      showSearch={true}
      hideClear={false}
      loading={fetching}
      {...rest}
    />
  ) : (
    <CustomFilterComponent
      width={200}
      placeholder="Select QA Auditor"
      value={qaAuditor}
      maxTagCount={1}
      multiSelect={multiSelect}
      showSearchIcon={true}
      {...rest}
    />
  );
};

export default QaAuditorFilter;
