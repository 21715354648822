import React, { useState, useEffect } from "react"
import { List, Input, Modal, Spin, Tag } from 'antd';
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import store, { useSelector } from "../../store";
import { StyledButton } from "../../components/common/styled-components";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";

export const AgentPopupWrapper = styled.div`
    height: 50vh;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: scroll;
    h1 {
        margin-left: 20px;
        margin-top: 20px;
    }
    .searchWrapper {
        padding: 20px
    }
`;

export const TagListWrapper = styled.div`
    margin-bottom: 10px;
`;

const StyledModal = styled(Modal)`
    .ant-modal-close-x { margin-right: 20px; }
`

const HeaderWrapper = styled.div`
    padding: 20px;
`;

const UserWrapper = styled.div`
    color: #6099d9;
    cursor: pointer;
    &:hover {
        transition: 0.3s ease;
        opacity: 0.7;
    }
`;

export const AgentPopup = ({isComparison, onSelectMultiple, onCancel}) => {
    const agents = useSelector((state) => state.dashboard.segments?.agents)
    const [filteredAgents, setfilteredAgents] = useState(agents)
    const [visible, setVisible] = useState(true);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [searchString, setSearchString] = useState("");
    const headerText = isComparison ? "Select agents for comparison" : "Select agent"

    const getAgentName = (agent) => {
        return `${agent.first_name} ${agent.last_name}`
    }

    useEffect(() => {
        setfilteredAgents(agents)
    }, [agents])

    const selectAgent = (agentSelection) => {
        store.dispatch({
            type: "SET_AGENT_ANALYTICS",
            payload: {
                value: agentSelection
            }
        })

        store.dispatch({
            type: ReduxActionTypes.SET_GLOBAL_FILTERS,
            payload: {
                agents: agentSelection,
                user: agentSelection,
            }
        })
    }

    const selectMultipleAgents = (agents) => {
        store.dispatch({
            type: ReduxActionTypes.SET_GLOBAL_FILTERS,
            payload: {
                agents,
                user: agents,
            }
        })
        onSelectMultiple(agents)
    }

    const onSearch = (searchString) => {
        setSearchString(searchString)
        if (searchString === "") {
            setfilteredAgents(agents) 
            return;
        }

        setfilteredAgents(
            agents.filter(agent => {
                return getAgentName(agent).toLowerCase().includes(searchString.toLowerCase())
            })
        )
    }
    
    return (
        <StyledModal
          visible={visible}
          header = {
              null
          }
          closable={filteredAgents !== undefined}
          title={null}
          width={900}
          footer={
            <>
            {
                isComparison ?
                <StyledButton 
                    disabled={selectedAgents.length === 0}
                    onClick={()=>{
                        selectMultipleAgents([...selectedAgents.map(a=>String(a.id))])
                    }}
                    >
                        Apply
                </StyledButton>
                :
                null
            }
            </>
          }
          onCancel={() => {
              if (!isComparison) {
                const agentSelection = [String(agents[0].id)]
                selectAgent(agentSelection)
              } else {
                  onCancel()
              }
          }}
        >
            <HeaderWrapper>
                <h1>{headerText}</h1>
                {
                    isComparison ?
                    <TagListWrapper>
                        {
                            selectedAgents.map((agent) => (
                                <Tag closable onClose={(agent) => {
                                    setSelectedAgents([
                                        ...selectedAgents.filter(a => a.id !== agent.id)
                                    ])
                                }}>
                                    {agent.first_name} {agent.last_name}
                                </Tag>
                            ))
                        }
                    </TagListWrapper>
                    :
                    null
                }
                
                {
                    (
                        <Input.Search placeholder={"Search"} disabled={!agents} 
                        value={searchString}
                        onChange={(e)=>{
                            const searchString = e.currentTarget.value
                            onSearch(searchString)
                        }}/>
                    )
                }
            </HeaderWrapper>
            <AgentPopupWrapper>
                {
                    agents ?
                    <List
                        size="large"
                        bordered
                        dataSource={filteredAgents}
                        renderItem={(item, index) => (
                            <List.Item>
                                <UserWrapper onClick={(e) => {
                                    if (isComparison) {
                                        setSelectedAgents([
                                            ...new Set([...selectedAgents, filteredAgents[index]])
                                        ])
                                    } else {
                                        const agentSelection = [String(filteredAgents[index].id)]
                                        selectAgent(agentSelection)
                                    }
                                    onSearch("")
                                }}>{getAgentName(item)}</UserWrapper>
                            </List.Item>
                        )}
                    />
                    :
                    <Spin />
                }
                
            </AgentPopupWrapper>
        </StyledModal>
    )
}