import React from "react";
import { Spin } from "antd";
import styled from "@emotion/styled/macro";

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const GlobalSpinner=()=>{
    return(
        <AppWrapper>
            <Spin/>
        </AppWrapper>
    )
}

export default GlobalSpinner;