import React from "react";
import { TeamQA } from "./tabs/team-qa";
import { AgentQA } from "./tabs/agent-qa";
import { TeamEfficiency } from "./tabs/team-efficiency";
import { Dashboard } from "./tabs/dashboard";
import { AgentEfficiency } from "./tabs/agent-efficiency";
import { QueryBuilder } from "./tabs/query-builder";
import { QueriesList } from "./tabs/queries-list";
import store from "../store";
import GlobalDashboard from "../GlobalDashboard";
import { getSchemaName } from "../api/api";
import { SCREEN_NAMES } from "../Filters/configs";

export const hasManagerRights = () => {
  const getCurrentUser = () => {
    return store.getState().app_user;
  };
  return getCurrentUser().is_manager || getCurrentUser().is_superuser;
};

export const hasAdminRights = () => {
  if (getSchemaName() === "weave") {
    return false;
  }
  const getCurrentUser = () => {
    return store.getState().app_user;
  };

  return getCurrentUser().is_superuser;
};
export const hasQAAuditorRights = () => {
  const getCurrentUser = () => {
    return store.getState().app_user;
  };
  return getCurrentUser().is_qa_auditor || getCurrentUser().is_superuser;
};
export const hasAgentRights = () => {
  const getCurrentUser = () => {
    return store.getState().app_user;
  };
  return getCurrentUser().is_agent;
};

const AgentEfficiencyConfigurations = {
  tabType: 1,
  key: "agent-efficiency",
  title: "Efficiency",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Agent Efficiency",
  agentMultiSelect: false,
  showTimeGranularity: true,
  screenName: SCREEN_NAMES.ANALYTICS_AGENT_VIEW,
  renderComponent: (props) => {
    return (
      <>
        <AgentEfficiency {...props} />
      </>
    );
  },
};

const AgentQAConfigurations = {
  tabType: 1,
  key: "agent-qa",
  title: "QA Score",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Agent QA Score",
  agentMultiSelect: false,
  showTimeGranularity: true,
  screenName: SCREEN_NAMES.ANALYTICS_AGENT_VIEW,
  renderComponent: (props) => {
    return (
      <>
        <AgentQA {...props} />
      </>
    );
  },
};

const TeamEfficiencyConfigurations = {
  tabType: 1,
  key: "team-efficiency",
  title: "Efficiency",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Team Efficiency",
  showTimeGranularity: true,
  agentMultiSelect: true,
  screenName: SCREEN_NAMES.ANALYTICS_TEAM_VIEW,
  renderComponent: (props) => {
    return (
      <>
        <TeamEfficiency {...props} />
      </>
    );
  },
};

const TeamQAConfigurations = {
  tabType: 1,
  key: "team-qa",
  title: "QA Score",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Team QA Score",
  showTimeGranularity: true,
  agentMultiSelect: true,
  screenName: SCREEN_NAMES.ANALYTICS_TEAM_VIEW,
  renderComponent: (props) => {
    return (
      <>
        <TeamQA {...props} />
      </>
    );
  },
};

const DashboardConfigurations = {
  tabType: 1,
  key: "dashboard",
  title: "Dashboard",
  icon: "user",
  description:
    "Add agents and managers and organize into teams to keep manager dashboards focused on the right agents.",
  fullTitle: "Dashboard",
  showTimeGranularity: false,
  agentMultiSelect: true,
  renderComponent: (props) => {
    return (
      <>
        <Dashboard {...props} />
      </>
    );
  },
};

const QueryBuilderConfigurations = {
  tabType: 1,
  key: "query-builder",
  title: "Query Builder",
  rawTemplate: true,
  renderComponent: () => {
    return (
      <>
        <QueryBuilder />
      </>
    );
  },
};

const QueriesListConfigurations = {
  tabType: 1,
  key: "queries-list",
  title: "Saved Queries",
  rawTemplate: true,
  renderComponent: () => {
    return (
      <>
        <QueriesList />
      </>
    );
  },
};

const GlobalDashboardConfigurations = {
  tabType: 1,
  key: "custom-dashboard",
  title: "Custom Dashboard",
  rawTemplate: true,
  renderComponent: () => {
    return (
      <>
        <GlobalDashboard />
      </>
    );
  },
};

const analyticsTabs = [
  TeamEfficiencyConfigurations,
  TeamQAConfigurations,
  AgentEfficiencyConfigurations,
  AgentQAConfigurations,
  DashboardConfigurations,
  QueryBuilderConfigurations,
  QueriesListConfigurations,
  GlobalDashboardConfigurations,
];

const analyticsCategories = [
  {
    name: "TEAM",
    items: [TeamEfficiencyConfigurations, TeamQAConfigurations],
  },
  {
    name: "AGENTS",
    items: [AgentEfficiencyConfigurations, AgentQAConfigurations],
  },
  {
    name: "CUSTOM",
    items: [
      GlobalDashboardConfigurations,
      QueryBuilderConfigurations,
      QueriesListConfigurations,
    ],
  },
];

export const getAnalyticsTab = () => {
  if (hasManagerRights() || hasQAAuditorRights()) {
    return analyticsTabs;
  }
  return [AgentEfficiencyConfigurations, AgentQAConfigurations];
};

export const getAnalyticsCategories = () => {
  if (hasManagerRights() || hasQAAuditorRights()) {
    return analyticsCategories;
  }
  return [
    {
      name: "AGENTS",
      items: [AgentEfficiencyConfigurations, AgentQAConfigurations],
    },
  ];
};
