import React from "react";
import { Modal } from "antd"; 
import styled from "@emotion/styled/macro";
import { StyledButton } from "./../../../components/common/styled-components";

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #BDBDBD;
  button.secondary {
    margin-right: 4px;
    background: #ffffff;
    border: 1px solid #0B4B93;
    color: #0B4B93;    
  }
  button.primary, button.secondary {
    width: 96px;
    height: 32px;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`;

const PopupWrapper = styled.div`
  position: relative;
`;

const TriggerWrapper = styled.div`
`;

class PopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        isOpen: false,      
    };
  }
  componentDidUpdate(prevProps) {
    if(this.props.isOpen && !prevProps.isOpen && !this.state.isOpen) {
      this.setState({ isOpen: true });
    }    
  }
  onSubmit = () => {
    this.setState({ isOpen: false }, () => {
      this.props.onSubmit();
    });
  }
  onCancel = () => {
    this.setState({ isOpen: false }, () => {
      this.props.onCancel();
    });    
  }  
  render() {
    const {
      component: Component,
      trigger: Button,
      primaryBtnName, 
      secondaryBtnName,
      triggerProps,
      componentProps,
      onSubmitValidated,
    } = this.props;
    const {
      width: popupWidth,
      height: popupHeight,
      ...componentRestProps
    } = componentProps;    
    return (
      <PopupWrapper>
        {triggerProps ? <TriggerWrapper>
          <Button 
            disabled = {componentProps.viewOnly}
            type="default" 
            onClick={() => {
                if(!this.state.isOpen) {
                  this.setState({ isOpen: true });
                }
            }}
          >{triggerProps.title}</Button>
        </TriggerWrapper> : null}
        <Modal
          visible={this.state.isOpen}
          header = {componentProps.modalHeader || null}
          closable={componentProps.closable ? true : false}
          title={componentProps.modalTitle || null}
          width={popupWidth}
          footer={null}
          onCancel={componentProps.closable && this.onCancel}
        >
          <ModalWrapper>
            <Component {...componentRestProps}/>
            <FooterWrapper>
              <StyledButton onClick={this.onCancel} className="secondary">{secondaryBtnName}</StyledButton>
              <StyledButton disabled={!onSubmitValidated || componentProps.viewOnly} onClick={this.onSubmit} className="primary">{primaryBtnName}</StyledButton>
            </FooterWrapper>
          </ModalWrapper>
        </Modal>
      </PopupWrapper> 
    )
  }
}

export default PopUp;
