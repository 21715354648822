import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";

const timeGranularityOptions = [
    {
        value: "day",
        label: "Day"
    },
    {
        value: "week",
        label: "Week"
    },
    {
        value: "month",
        label: "Month"
    }
]

const TimeGranularityFilter = ({value, handleChange}) => {
    const [selected, updateSelected] = useState(value);
    useEffect(() => {
        updateSelected(value);
    }, [value]);
    return (
        <FilterComponent
            hideClear
            options={timeGranularityOptions}
            placeholder="Time Granularity"
            value={selected}
            capitalize={true}
            handleChange={(value) => {
                updateSelected(value)
                handleChange(value);
            }}
        />    
    );
}

export default TimeGranularityFilter;