import React, { Component } from "react";
import { connect } from "react-redux";
import {
  SetupWrapper,
  FilterWrapper,
  LineSeparator,
} from "./../commons/wrappers";
import SetupHeader from "../commons/SetupHeader";
import styled from "@emotion/styled/macro";
import { Select, message, Button } from "antd";
import SearchComponent from "./../../../components/common/SearchComponent";
import ConversationCategorisationList from "./conversation-categories-list";
import {
  getlistCategories,
  dropdownCategories
} from "./../../api/api";
//import { reportListApi, deleteReportApi, updateReportApi } from "../../api/api";
import { debounce } from "lodash";
import ConversationModal from "./conversation-modal";
import {CategoryWrapper, CategoryTypeDropdown,CategoryFiltersWrapper,ButtonWrapper, TrainButtonWrapper} from './wrappers'
import TrainModel from './trainModel';
class ConversationCategorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_type: undefined,
      title: undefined,
      tableData: [],
      isLoading: true,
      page: 1,
      showInfo: false,
      isModalVisible: false,
      order_by: "",
      sort_order: "",
      categoryTypeOptions:[]
    };
    this.dataSource = [];
  }

  handleChangeDropdown = (value) => {
    this.setState({ category_type: value }, this.applyFilters);
  };

  handleSearchValue = (value) => {
    const text = value;
    //this.setState({ name: value }, this.applyFilters);
    this.debouncingFunction(text);
  };
  debouncedSearchValue = (text) => {
    this.setState({ title: text }, this.applyFilters);
  };

  applyFilters = () => {
    this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
      this.dataSource = [];
      this.getCategoriesList();
    });
  };

  debouncingFunction = debounce(this.debouncedSearchValue, 500);

  trackScrolling = () => {
    const tabDocument = document.getElementsByClassName(
      "setup-tab-conversation-categorization"
    )[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    };
    if (node && isBottom(node)) {
      node.removeEventListener("scroll", this.trackScrolling);
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => {
          this.getCategoriesList();
        }
      );
    }
  };

  getCategoriesList = () => {
    const { page, title, category_type, order_by, sort_order } =
      this.state;
    const filters = {
      page,
      title: title ? title : undefined,
      category_type: category_type !== "Category type" ? category_type : undefined,
      order_by: order_by !== "" ? (sort_order==="dsc"?"-"+order_by:order_by) : undefined,
      //sort_order: sort_order !== "" ? sort_order : undefined,
    };
    const tabDocument = document.getElementsByClassName(
      "setup-tab-conversation-categorization"
    )[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener("scroll", this.trackScrolling);
    getlistCategories(filters).then((res) => {
      if (res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if (node) {
          node.addEventListener("scroll", this.trackScrolling);
        }
      }

      let categoriesList = res.results;
      let data =this.state.page === 1 ? categoriesList : this.dataSource.concat(categoriesList);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });
    });
  };

  handleCloseModal = () => {
    this.setState({ isModalVisible: false });
  };

  updateSortable = (key, order) => {
    this.setState({ order_by: key, sort_order: order, isLoading: true }, () => {
      this.getCategoriesList();
    });
  };

  handleSetState = (messageVal) => {
    this.setState({ isLoading: true, tableData: [] }, () => {
      this.getCategoriesList();
    });
    message.success(messageVal);
  };

  getCategoryDropdownOptions=()=>{
    dropdownCategories().then(res=>{
      this.setState({categoryTypeOptions:res})
    }).catch(err=>message.error(err.message))
  }
  componentDidMount() {
    this.getCategoriesList();
    this.getCategoryDropdownOptions()
  }
  render() {
    const { Option } = Select;
    const sortable = {
      key: this.state.order_by,
      order: this.state.sort_order,
    };
    return (
      <SetupWrapper className="setup-tab-conversation-categorization">
        <SetupHeader
          title={this.props.fullTitle}
          icon={this.props.icon}
          description={this.props.description}
          openInfo={() => this.setState({ showInfo: true })}
        />
        <CategoryWrapper>
          <CategoryFiltersWrapper>
            <CategoryTypeDropdown
              value={this.state.category_type}
              onChange={this.handleChangeDropdown}
              placeholder="Category type"
              allowClear
            >
              {
                  this.state.categoryTypeOptions.length>0 && this.state.categoryTypeOptions.map((item,index)=>(
                    <Option value={item}>{item}</Option>
                  ))
              }
            </CategoryTypeDropdown>
            <FilterWrapper>
              <SearchComponent
                placeholder="Search Category"
                value=""
                handleSearchValue={this.handleSearchValue}
              />
            </FilterWrapper>
          </CategoryFiltersWrapper>
          <div style={{display:"flex"}}>
         <TrainModel tableData={this.state.tableData} handleSetState={this.handleSetState}/>
          <ButtonWrapper
            onClick={() => this.setState({ isModalVisible: true })}
          >
            <span style={{ marginRight: "7px" }}>+</span> Create Category
          </ButtonWrapper>
          </div>
        </CategoryWrapper>
        <LineSeparator />
        <ConversationCategorisationList
          tableData={this.state.tableData}
          isLoading={this.state.isLoading}
          deleteCategory={this.deleteCategory}
          updateCategory={this.updateCategory}
          sortable={sortable}
          updateSortable={this.updateSortable}
          handleEditTitleClick={this.handleEditTitleClick}
          handleSetState={this.handleSetState}
        />
        {this.state.isModalVisible && (
          <ConversationModal
            isModalVisible={this.state.isModalVisible}
            handleCloseModal={this.handleCloseModal}
            flag="Create"
            title="Custom Category"
            modalBody={"Phrases"}
            modalBodyText={"What statement would be part of this category?"}
            handleSetState={this.handleSetState}
            buttonText={"New phrase"}
          />
        )}
      </SetupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app_user: state.app_user,
  selectedWorkspace: state.app_user.selectedWorkspace,
});

export default connect(mapStateToProps, null)(ConversationCategorization);
