import { Select, Tooltip } from 'antd'
import React from 'react'
import { useSelector } from "../../store";


const QuestionFilter = ({value,handleChange}) => {
  const options = useSelector(
    (state) => state.dashboard?.segments?.questions
  );

  const { Option } = Select

  return (
    <Select value={value} onChange={(val) => handleChange(val)} placeholder="All Question Option">
      {
        options?.length > 0 &&
          options.map((option, index) => (
            <Option key={index} value={option}>
              <Tooltip placement="left" title={option}>
                {option}
              </Tooltip>
            </Option>
          ))
      }
    </Select>
  )
}

export default QuestionFilter