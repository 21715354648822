import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as MenuIcon } from "../../../assets/menu-icon.svg";
import { Menu, Dropdown } from "antd";

const MenuItemWrapper = styled(Menu.Item)`
    display: flex;
    align-items: center;
    svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;    
    }
    &:hover {
        svg {
            path {
                fill:  ${props => props.hoverColor ? props.hoverColor : "#6099D9"};
                transition: fill 0.2s ease;
            }
        }
    }
`;

const MenuIconWrapper = styled.div`
    display: flex;
    cursor: pointer;
    & svg {
        width: 20px;
        height: 20px;
        & path {
          fill : ${props => props.iconColor || "rgb(189, 189, 189)"};
        }
    }
`;

const MenuWrapper = styled(Menu)`
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
    border-radius: 3px;
    width: 175px;
`;

export const ActionMenu = (props) => {
    const [showMenu, toggleMenu] = useState(false);
    const onVisibleChange = (flag) => {
        toggleMenu(flag);
    }
    const handleMenuClick = (e) => {
        toggleMenu(false);
        props.handleMenuClick(e.key);  
    }
    return (
        <Dropdown 
            overlay={
                <MenuWrapper selectable={false} onClick={handleMenuClick}>
                    {props.menuItems.map(({title, icon: Icon, key, hoverColor}) => (
                        <MenuItemWrapper hoverColor={hoverColor} key={key}>
                            {Icon ? <Icon /> : null} {title}
                        </MenuItemWrapper>
                    ))}
                </MenuWrapper> 
            } 
            onVisibleChange={onVisibleChange}
            visible={showMenu}
            trigger={['click']}
        >
            <MenuIconWrapper style = {props.style || {}} iconColor = {props.iconColor || "rgb(189, 189, 189)"}>
                <MenuIcon />
            </MenuIconWrapper>
        </Dropdown>
    )
}

export default ActionMenu;
