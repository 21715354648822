import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router";
import SetupHeader from "../CompanySetup/components/commons/SetupHeader";
import {
  LineSeparator,
  SetupWrapper,
} from "../CompanySetup/components/commons/wrappers";
import { AutomationConfiguration } from "../CompanySetup/utils/config";
import SaveWorkflow from "./components/SaveWorkflow";
import useStore from "./store";
import DnDFlow from "./SubPage/DragAndDrop/draganddrop";
import ListAutomation from "./SubPage/ListAutomation";

export default () => {
  const location = useLocation();
  const fetchAutomationAuthToken = useStore(
    (state) => state.fetchAutomationAuthToken
  );
  const loadingAuthToken = useStore((state) => state.loadingAuthToken);
  const [breadCrumb, setBreadCrumb] = useState([
    { title: "Home", link: "/" },
    { title: "Settings", link: "/setup" },
    { title: "Automation", link: "/setup/automation" },
  ]);

  useEffect(() => {
    fetchAutomationAuthToken();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/automation/create")) {
      setBreadCrumb((p) => [...p, { title: "Create" }]);
    } else if (location.pathname.includes("/automation")) {
      setBreadCrumb((p) => {
        let clone = [...p];
        let lastElement = clone.pop();
        if (lastElement.title === "Create") return clone;
        return p;
      });
    }
  }, [location.pathname]);

  let renderActionButtons = null;

  if (breadCrumb[breadCrumb.length - 1].title === "Create") {
    renderActionButtons = <SaveWorkflow />;
  }
  if (loadingAuthToken) {
    return <Spin />;
  }
  return (
    <div style={{ width: "100%" }}>
      <SetupWrapper>
        <div className="d-flex justify-content-between align-items-start">
          <SetupHeader
            title={AutomationConfiguration.title}
            icon={AutomationConfiguration.icon}
            description={AutomationConfiguration.description}
            breadCrumbItems={breadCrumb}
          />
          {renderActionButtons}
        </div>

        <LineSeparator className="my-3" />
        <Switch>
          <Route
            path="/setup/automation"
            exact={true}
            component={ListAutomation}
          />
          <Route
            path="/setup/automation/create"
            exact={true}
            component={DnDFlow}
          />
        </Switch>
      </SetupWrapper>
    </div>
  );
};
