import styled from "@emotion/styled/macro";
import { Button, Input } from "antd";
import { EmptyStateWrapper } from '../../../user-dashboard/wrappers';

export const IntentPopupWrapper = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const IntentPopupLoadingWrapper = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const ConditionTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #4f4f4f;
  margin-bottom: 8px;
`;

export const ConditionSubTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
`;

export const LineSeparator = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 24px;
  .add-btns {
    button:last-of-type {
      margin-left: 4px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px;
`;

export const PrimaryStyledButton = styled(Button)`
  border: 1px solid #6099d9;
`;

export const NoSimilarIntents = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 174, 96, 0.1);
  color: #27ae60;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 12px;
  border-radius: 100px;
  width: 210px;
  margin-bottom: 32px;
  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
`;

export const RuleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  input {
    width: calc(100% - 48px);
    padding: 4px 10px;
    height: 47px;
    border: 1px solid #bdbdbd;
    margin-top: 8px;
    font-size: 16px;
    color: #4f4f4f;
  }
  .ant-input[disabled] {
    color: #4f4f4f;
  }
`;

export const SubLineSeparator = styled.div`
  width: calc(100% - 48px);
  position: relative;
  margin: 0 24px;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const IntentsMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const AnnotatedPhrasesWrapper = styled.div`
    padding: 0 24px 24px;
    display: flex;
    width 100%;
`;

export const PhraseTabsWrapper = styled.div`
    padding: 16px 24px 24px 24px;
    display: flex;
    width 100%;
`;

export const EmptyStatesWrapper = styled(EmptyStateWrapper)`
   height: 40vh;
   flex-direction: column;
  .custom-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .custom-button {
      width: 20%;
      color: dimgray;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      &:hover {
        transition: 0.4s ease;
        color: #0B4B93;
        border: 1px solid #0B4B93;
      }
    }
  }
`;

export const StylePhraseInputWrapper = styled.div`
  width: calc(100% - 42px);
  position: relative;
`;

export const StyledPhraseInput = styled(Input)`
  width: 100%;
  padding: 4px 10px;
  border: 1px solid;
  border-color: ${(props) => (props.conflict ? "#E89244" : "#BDBDBD")};
  position: relative;
  &:hover {
    border-color: ${(props) => props.conflict && "#E89244"};
  }
  &.ant-input[disabled] {
    color: #4f4f4f;
  }
`;

export const ConflictsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CollapseIconWrapper = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  .title {
    font-size: 14px;
    line-height: 16px;
    color: #4f4f4f;
    margin-left: 20px;
    padding: 0;
  }
  svg {
    transform: ${(props) =>
      props.isCollapsed ? "rotate(0deg)" : "rotate(90deg)"};
    transition: transform 0.3s;
  }
`;

export const ConflictsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 42px);
  margin-top: 8px;
  margin-bottom: 24px;
  background: rgba(232, 146, 68, 0.05);
  border-radius: 6px;
  padding: 16px;
`;

export const ConflictRules = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  &:first-of-type {
    margin-top: 0;
  }
  .conflict-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    background: #ffffff;
    margin-top: 12px;
    padding: 16px;
  }
  .rule-meta-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .rule-title {
      color: #6099d9;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
    }
  }
  .rule-phrases {
    display: flex;
    width: 100%;
    flex-direction: column;
    .rule-phrase {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 8px;
      background: rgba(232, 146, 68, 0.05);
      border-radius: 3px;
      padding: 10px 12px;
      .rule-phrase-title {
        font-size: 14px;
        line-height: 16px;
        color: #4f4f4f;
      }
    }
  }
  .conflict-icon-wrapper {
    background: rgba(232, 146, 68, 0.1);
    border-radius: 100px;
    padding: 0 6px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ConflictIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  color: #e89244;
  font-size: 11px;
  background: rgba(232, 146, 68, 0.1);
  border-radius: 100px;
  padding: 0 6px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 10px;
    height: 10px;
  }
`;
