import React, { useState } from "react";
import styled from "@emotion/styled/macro";

const StringShortenerWrapper=styled.div`
        .read-or-hide{
            cursor:pointer;
        }   
        .show-more{
                color:${props => props.showMoreColor || "dodgerblue"};
        }  
        .show-less{
                color:${props => props.showLessColor || "lightcoral"};
        }
`
const StringShortener = ({ children, shortenLimit, showMoreText, showLessText, showMoreColor, showLessColor,onMoreClick }) => {
  //after shortenLimit user sees read more or show less
  const text = children;
  const [readMoreEnabled, setReadMoreEnabled] = useState(true);
  const toggleReadMore = () => {
    if(readMoreEnabled) {
      onMoreClick && onMoreClick()
    }
    setReadMoreEnabled(!readMoreEnabled);
  };

  const showMore_LessFunction=(text)=>{
    if(text.length > shortenLimit){
        if(readMoreEnabled){
            return <span className="show-more">{showMoreText || "...read more"}</span>;
        }else{
            return <span className="show-less">{showLessText || "show less"}</span>;
        }
    }
  }
  return (
    <StringShortenerWrapper className="string-shortener-wrapper" showMoreColor= {showMoreColor} showLessColor= {showLessColor}>
        {readMoreEnabled ? text.slice(0, shortenLimit + 1) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {showMore_LessFunction(text)}
        </span>
    </StringShortenerWrapper>
  );
};

export default StringShortener;
