import React, { useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import FilterComponent from "./FilterComponent";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";
import { instaReviewtopics } from "../../conversation-list/utilities";

const InstaReviewFilter = ({value, handleChange, instaReviewMultiSelect, width, style = {}, disabled = false, ...rest}) => {
    const [instaReviewtopic, updateinstaReviewTopic] = useState(value);
    const _instaReviewtopics=["Negative Conversation","Rich Conversation"]

    return (
        !instaReviewMultiSelect ? <FilterComponent
            width = {width}
            options={_instaReviewtopics}
            placeholder="InstaReview"
            value={instaReviewtopic}
            maxTagCount= {1}
            showSearchIcon={true}
            capitalize={true}
            handleChange={(value) => {
                if (value?.length > 5 && instaReviewMultiSelect) {
                    message.warning("More than 5 values cannot be selected.")
                    value.pop();
                    return;
                  }
                updateinstaReviewTopic(value);
                handleChange(value);
            }}
            showSearch={true}
            hideClear={false}
            disabled = {disabled}
            style = {style}
            {...rest}
        />  :  
         <CustomFilterComponent
          options={instaReviewtopics}
          placeholder="Select InstaReview"
          value={instaReviewtopic}
          width = {width || 180}
          maxTagCount= {1}
          showSearchIcon={true}
          disabled = {disabled}
          capitalize={true}
          multiSelect={instaReviewMultiSelect}
          handleChange={(value) => {
              if (value?.length > 5 && instaReviewMultiSelect) {
                  message.warning("More than 5 values cannot be selected.")
                  value.pop();
                  return;
                }
              updateinstaReviewTopic(value);
              handleChange(value);
          }}
          {...rest}
        />
    );
}

const mapStateToProps = state => ({ selectedWorkspace: state.app_user.selectedWorkspace });
export default connect(mapStateToProps)(InstaReviewFilter);
