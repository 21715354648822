import produce from "immer";
import {
  axiosInstance,
  BASE_URL,
  getSchemaName,
  getToken,
} from "../../api/api";
import { getAutomationAuthToken } from "../colossusFetcher";
import generateFlowObject from "../generateFlowObject";

export const createAutomationMetaSlice = (set, get) => ({
  title: "",
  description: "",
  trigger_type: "",
  automationAuthToken: "",
  loadingAuthToken: true,
  fetchAutomationAuthToken: async () => {
    let token = await getAutomationAuthToken();
    token = token.data.jwt;
    set(
      produce((state) => {
        state.automationAuthToken = token;
        state.loadingAuthToken = false;
      })
    );
  },
  setTitle: (title) =>
    set(
      produce((state) => {
        state.title = title;
      })
    ),
  setDescription: (description) =>
    set(
      produce((state) => {
        state.description = description;
      })
    ),
  setTriggerType: (trigger_type) =>
    set(
      produce((state) => {
        state.trigger_type = trigger_type;
      })
    ),
  setMeta: ({ title, description, trigger_type }) =>
    set(
      produce((state) => {
        state.title = title;
        state.description = description;
        state.trigger_type = trigger_type;
      })
    ),
  getMeta: () => {
    const trigger_type = get().trigger_type;
    const title = get().title;
    const description = get().description;
    if (title && trigger_type && description)
      return { title, trigger_type, description };
    return null;
  },
  saveWorkflow: async (id) => {
    const flowError = get().flowError;
    if (flowError) {
      alert(`Cannot save changes ${flowError}"`);
    }
    const elements = get().elements;
    const nextNodeId = get().nextNodeId;
    const { title, trigger_type, description } = get().getMeta();
    const flowObject = generateFlowObject(elements);
    const data = {
      ...flowObject,
      title,
      description,
      trigger_type,
      react_flow_json: { elements, nextNodeId },
    };
    const url = id
      ? `/automation/${id}/`
      : `/automation/create/`;
    await axiosInstance({
      method: id ? "PATCH" : "POST",
      url,
      data
    });
  },
});
