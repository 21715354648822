import React, { useState } from 'react';
import { Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { revokeCloudApp } from "./../../api/api";
import {ModalWrapper, BodyWrapper, HeadingWrapper, FieldsWrapper, ButtonWrapper} from './wrappers';
import { setItemInStore } from '../../../utils';

const ZendeskPrompt = ({ showPrompt, handleShowPrompt, zenDesk, getIntegrations }) => {
    const [fieldValue, setFieldValue] = useState("");
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        setFieldValue(event.target.value);
    }

    const openZendeskUrl = () => {
        if(fieldValue === "") {
            message.error('Please fill the required field.');
            return;
        }
        setItemInStore('subdomain', fieldValue);
        setLoading(true);
        if (!zenDesk.href) {
            revokeCloudApp({cloudapp_name: zenDesk.name}).then(()=>{
                getIntegrations();
                //handleShowPrompt();
            }).catch(err => setLoading(false));
            setLoading(false);
            return;
        }
        let newWindow = window.open(zenDesk.href(fieldValue), "_blank");
        newWindow.focus();
        setLoading(false);
        //handleShowPrompt();
    }
    return (
        <>
            <ModalWrapper
            visible = {showPrompt}
            header = {null}
            title={<h2>Zendesk</h2>}
            footer={null}
            onCancel = {() => {
                setFieldValue("");
                handleShowPrompt()
            }}
            >   
                <BodyWrapper>
                    <HeadingWrapper>Your Zendesk subdomain</HeadingWrapper>
                    <FieldsWrapper>
                        <Input type="text" onPressEnter = {openZendeskUrl} placeholder = "subdomain" addonAfter = ".zendesk.com" value = {fieldValue} onChange = {handleChange} />
                        <ButtonWrapper onClick ={openZendeskUrl}>{loading && <LoadingOutlined/>} Authenticate with Zendesk</ButtonWrapper>
                    </FieldsWrapper>
                </BodyWrapper>
            </ModalWrapper>
        </>
    )
}

export default ZendeskPrompt;
