import React from "react";
import { List, Badge } from "antd";
import { Link } from "react-router-dom";
import SetupHeader from "../CompanySetup/components/commons/SetupHeader"
import styled from "@emotion/styled";
import { CustomTable } from "../CompanySetup/components/commons/table";
import moment from "moment";
import { useNotifications } from "./hooks";
import { readNotification } from "./api";
import { getIconFromNotification, linkGenerator } from "./utils";


const NotificationPageWrapper = styled.div`
    padding: 15px;

    .table-row-light {
        background-color: #ffffff;
    }
    .table-row-dark {
        background-color: #fbfbfb;
    }
    .timestamp {

    }
    .ant-table-wrapper {
        max-width: 800px;
    }
`
const LinkWrapper = styled.div`
    cursor: pointer;
    &:hover span{
        color: #6CA5E5;
        transition: 0.4s ease;
    }
    
`;
const NotificationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const InAppNotifications = () => {
    const [notifications, loading, loadNextPage, paginating, nextPage] = useNotifications();

    const columns = [
        {
            title: 'Notifications',
            dataIndex: 'message',
            key: 'message',
            render: (value, record, index) => {
                const link = linkGenerator(record.notifications);
                return (
                <List.Item>
                    <List.Item.Meta
                    title={
                        <>
                            <LinkWrapper
                            onClick={() => {
                                !record.is_read && readNotification(record.id)
                                window.open(link, "_self");
                            }}
                            >
                                <NotificationWrapper>
                                <div>
                                    {getIconFromNotification(record)}
                                    <span>
                                        {record.message}
                                    </span>
                                </div>
                                {
                                    !record.is_read && 
                                    <Badge dot color="#78b8ff"></Badge>
                                }
                                </NotificationWrapper>
                            </LinkWrapper>
                        </>
                    }
                    description={
                        <span style={{marginLeft: 20}}>{moment(record.last_notifcation_at).fromNow()}</span>
                    }
                    />
                </List.Item>
                )
            }
        },
    ];

    return (
        <NotificationPageWrapper>
            <SetupHeader 
                    title="Notifications"
                    icon="user" 
                    description="User Notifications" 
                    breadCrumbItems={[]}
                    openInfo={() => this.setState({ showInfo: true })}
            />
            <br />
            <CustomTable 
                showMoreButton={!!nextPage}
                showMoreButtonLoading={paginating}
                fetchNextPage={loadNextPage}
                rowClassName={(record, index) => record.is_read ? 'table-row-light' :  'table-row-dark'}
                isLoading={loading}
                tableData={notifications.map((notification) => {
                    return {
                        key: notification.id,
                        ...notification
                    }
                })}
                columns={columns}
            />
        </NotificationPageWrapper>
    )
}