import React from "react";
import { Button, Modal, message } from 'antd';
import styled from "@emotion/styled/macro";
import { ReactComponent as RunnerIcon } from "./../../assets/historical-icon.svg";
import FilterComponent from "../../components/common/FilterComponent";
import { runHistoricalReplay } from '../api/api';

const TitleWrapper = styled.div`
    font-size: 20px;
    line-height: 23px;
    color: #4F4F4F;
`;

const DescriptionWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    margin-top: 8px;
    text-align: center;
    width: 100%;
`;

const StyledButton = styled(Button)`
    width: 166px;
    font-size: 16px;
    text-align: center;
    width: 100%;
`;

const IconWrapper = styled.div`
    svg {
        width: 70px;
        height: 60px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
`;

const OuterWrapper = styled.div`
    width: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .action-buttons {
        display: flex;
        width: 100%;
        margin-top: 24px;
        justify-content: center;
        button {
            font-size: 16px;
            line-height: 19px;
            height: 43px;
            border: 1px solid #0B4B93;
            width: 166px;
        }
        button:last-of-type {
            margin-left: 4px;
            border: none;
        }
    }
`;

const DropdownWrapper = styled.div`
    .ant-select {
        width: 250px;
        margin-top: 32px;
    }
`;

const ConversationCountOptions = [
    {
        label: "Last 100 Interactions",
        value: 100
    },
    {
        label: "Last 250 Interactions",
        value: 250
    },
    {
        label: "Last 500 Interactions",
        value: 500
    },
    {
        label: "Last 1000 Interactions",
        value: 1000
    },
    // {
    //     label: "Last 20000 Interactions",
    //     value: 20000
    // }
];

const HistoricalRunner = (props) => {
    const [value, setValue] = React.useState("100");
    const onClose = () => {
        props.onCancel();
    }
    const onRun = () => {
        runHistoricalReplay(props.ruleId, value).then(() => {
            message.success("Started running conversation over selected range with latest model");
            props.onCancel();
        }, () => {
            message.error("Failed to run this conversations with latest model. Please try again.");
        });
    }
    return (
        <Modal
            visible={props.visible}
            header = {null}
            closable={false}
            title={null}
            width={465}
            footer={null}
            >
            <OuterWrapper>      
                <IconWrapper><RunnerIcon /></IconWrapper>
                <TitleWrapper>Run Historical Interactions</TitleWrapper>
                <DescriptionWrapper>This will run all conversations in the range selected below through the current setup to add new tags due to recently defined rules or due to improvements in our model.</DescriptionWrapper>
                <DropdownWrapper>
                    <FilterComponent 
                        options={ConversationCountOptions}
                        placeholder=""
                        hideClear={true}
                        value={value}
                        handleChange={(value) => {
                            setValue(value);
                        }}
                    />
                </DropdownWrapper>
                <div className="action-buttons">
                    <StyledButton onClick={() => onClose(false)}>
                        Cancel
                    </StyledButton>
                    <StyledButton type="primary" onClick={onRun}>
                        Run
                    </StyledButton>
                </div>    
            </OuterWrapper>
        </Modal>
    )
}

export default HistoricalRunner;
