import React, { Component } from 'react';
import { message, Modal } from 'antd';
import UserProfile from "./user-profile";
import PopUp from "./../commons/pop-up";
import SetupHeader from "../commons/SetupHeader";
import UsersList from "./users-list";
import SearchComponent from "./../../../components/common/SearchComponent";
import ConfirmPopup from "./../../components/commons/ConfirmPopup";
import { 
  PrimaryStyledButton, 
  StyledCheckbox
} from "./../../../components/common/styled-components";
import { ReactComponent as UserManagementIllustration } from "../../assets/illustrations/UserManagement.svg";
import { 
  getUsersList,
  createUser,
  updateUser, 
  deleteUser,
  getRolesList,
  getWorkspaceList,
  resendPassword 
} from "./../../api/api";
import { 
  SetupWrapper, 
  FilterWrapper, 
  SpaceBetweenWrapper,
  LineSeparator,  
} from "./../commons/wrappers";
import { connect } from "react-redux";
import SetupHelper from "../commons/SetupHelper";
import { UserRoles } from './config';
import { hasManagerRights } from '../../../AnalyticsDashboard/config';
import OktaSaml from './okta-saml';
import styled from '@emotion/styled';
import Axios from 'axios';
import { getItemFromStore, setItemInStore } from '../../../utils';

const ButtonWrapper = styled.div`
display: flex;
column-gap: 10px;
`;

let axiosCancelToken;
export class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      organization: "all",
      group: "all",
      name: undefined,
      roles: [],
      tableData: [],
      is_active: undefined,
      isLoading: true,
      showConfirm: false,
      page: 1,
      showInfo: false,      
    };
    this.dataSource = [];
  } 
  componentDidMount() {
    axiosCancelToken = Axios.CancelToken.source()
    this.getUsersList();
    this.getRolesList();
    this.getWorkspaceList();
    try {
      if(!getItemFromStore('user-management')) {
        setItemInStore('user-management', true);
        this.setState({ showInfo: true })
      }
    } catch(e) {}
  }
  componentDidUpdate(prevProps, nextProps) {
    if(prevProps.selectedWorkspace !== this.props.selectedWorkspace) {
        this.setState({
          userData: null,
          organization: "all",
          group: "all",
          name: undefined,
          tableData: [],
          is_active: undefined,
          isLoading: true,
          showConfirm: false,
          page: 1,
          showInfo: false,
        }, () => {
          this.dataSource = [];
          this.getUsersList();
        });
    }
  }
  componentWillUnmount() {
    if(axiosCancelToken) {
      axiosCancelToken.cancel('')
    }
   
  }
  trackScrolling = () => {
    const tabDocument = document.getElementsByClassName("setup-tab-user")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0]
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    }
    if(node && isBottom(node)) {
      node.removeEventListener('scroll', this.trackScrolling);
      this.setState({
        page: this.state.page + 1,
        isLoading: true
      }, () => {
        this.getUsersList();
      })
    }
  }
  getUsersList = (userFilters) => {
    const { page, is_active, group, name } = userFilters ? userFilters : this.state;
    const filters = {
      page,
      is_active: is_active ? true : undefined,
      groups: group === "all" ? undefined : group,
      name: name ? name : undefined,
    };
    const tabDocument = document.getElementsByClassName("setup-tab-user")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener('scroll', this.trackScrolling);
    getUsersList(filters, axiosCancelToken).then(res => {
      if(res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if(node) {
          node.addEventListener('scroll', this.trackScrolling);
        }
      }
      let usersList = res.results || [];
      usersList = usersList.map((user)=>{
        user.user_name = `${user.first_name}${user.last_name ? " " + user.last_name : ""}`;
        if(!user.user_name) {
          user.user_name = user.email;
        }
        return user;
      });
      let data = this.state.page === 1 ? usersList : this.dataSource.concat(usersList);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });      
    })
  }
  getWorkspaceList = () => {
    getWorkspaceList({ is_active: true, list_all: true}).then(res => {
      const workspaces = res.results.map((workspace, index) => {
          return {
              value: workspace.id,
              label: workspace.name
          }
      });
      this.setState({ workspaceList: workspaces });
    });
  }
  getRolesList = () => {
    getRolesList().then(res => {
      // if(!this.props.app_user.is_superuser) {
      //   res = res.filter(item => item.role !== UserRoles.Admin)
      // }
      const roles = res.map(item => {
        return {
          label: item.display_name,
          value: item.role
        }
      })
      this.setState({ roles: roles });
    });
  }
  resendPassword = (data) => {
    resendPassword(data.email).then((res) => {
      message.success("New password is sent to user!");
    });
  }
  showDeleteConfirm = (data) => {
    this.setState({ showConfirm: true, userData: data });
  }
  deleteUser = (id) => {
    this.setState({ isLoading: true }, () => {
      deleteUser(id).then((res) => {
        message.success("User is deleted from Organisation");
        this.setState({ userData: null, editProfile: false, page: 1, isLoading: true }, () => {
          this.dataSource = [];
          this.getUsersList();
        });
      }, () => {
        this.setState({ isLoading: false });
      })
    });    
  } 
  updateUser = (userData) => {
    this.setState({ isLoading: true }, () => {
      updateUser(userData).then(res => {
        //error from BE, need to check with Daniel
        message.success("User's details are updated!");
        this.setState({ userData: null, editProfile: false, page: 1, isLoading: true }, () => {
          this.dataSource = [];
          this.getUsersList();
        });
      }, (err) => {
        try{
          message.error(Object.values(err.response.data).join(", "));
        } catch(e) {}
        this.setState({ isLoading: false, userData: null, editProfile: false });
      })
    });
  }
  handleSubmit = () => {
    const { first_name, last_name, is_active, email, id, role, client_name, is_superuser, organization, phone_number, workspace } = this.state.userData;
    const userData = {
      id,
      first_name,
      last_name,
      is_active,
      email,
      client_name,
      add_to_twilio: client_name ? true : false,
      role,
      organization,
      phone_number,
      //is_superuser,
      workspace: workspace && Array.isArray(workspace) ? workspace.map((item) => !isNaN(item) ? Number(item) : item) : undefined,
    };
    if (userData.client_name) {
      userData.twilio_client_name = userData.client_name;
    }
    if(this.state.editProfile) {
      const isAdminUser = this.state.tableData.find(user => user.id === userData.id);
      //if the user whose record we are viewing is an admin user, then a user with agent/manager position cannot edit that user
      if(isAdminUser.role.includes(UserRoles.Admin) && !this.props.app_user.is_superuser) {
        message.error('You cannot edit an Admin user!');
        this.setState({ userData: null, editProfile: false });
        return;
      }
      this.updateUser(userData);
    }
    else {
      createUser(userData).then(res => {
        message.success('User is successfully created!');
        this.setState({ userData: null, editProfile: false, page: 1, isLoading: true }, () => {
          this.dataSource = [];
          this.getUsersList();
        });
      }, (err) => {
        try {
          message.error(Object.values(err.response.data).join(", "));
        } catch(e) {}
      })
    }
  }
  
  editProfile = (data) => {
    let roles = Array.isArray(data.role) ? data.role : [];
    /* if the logged in user is not an admin and he/she tries to open a record with admin permission then they should be able to see admin in antd select */
    if(!this.props.app_user.is_superuser) {
      if(roles.includes(UserRoles.Admin)) { 
        roles = roles.map(item => item === UserRoles.Admin ? item.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase() + m2.toLowerCase()) : item);
        data.role = roles;
      }
    }

    if(data.client_name) {
      data.twilio_client_name = data.client_name;
    }
    this.setState({ editProfile: true, userData: data });
  }
  applyFilters = () => {
    if(axiosCancelToken) {
      axiosCancelToken.cancel('');
    }
    axiosCancelToken = Axios.CancelToken.source()
    this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
      this.dataSource = [];
      this.getUsersList();
    })    
  }

  handleSearchValue = (value) => {
    this.setState({ "name" : value }, this.applyFilters);
  }

  handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    this.setState({ is_active: isChecked }, this.applyFilters);
  }

  handleDropdownState = (type, value) => {
    this.setState({ [type]: value }, this.applyFilters);
  }

  addUser = () => {
    this.setState({ createUser: true, showInfo: false });
  }

  getUserRoles = () =>  this.props.app_user.is_superuser ? [...this.state.roles] : this.state.roles.filter(item => item.value !== UserRoles.Admin);

  render() {
    const userData = this.state.userData || {};
    const tableData = this.state.tableData;    
    return (
      <SetupWrapper className="setup-tab-user">
        <SetupHeader 
          title="User Management" 
          icon="user" 
          description="Organize your teams to keep manager dashboards focused on the right agents." 
          openInfo={() => this.setState({ showInfo: true })}
        />
        <SpaceBetweenWrapper>
          <FilterWrapper>
            <SearchComponent 
                placeholder="Search users..."
                value=""
                handleSearchValue={this.handleSearchValue}
            />         
            <StyledCheckbox onChange={this.handleCheckbox.bind(this)}>Hide Inactive</StyledCheckbox>
          </FilterWrapper>
          <ButtonWrapper>
          <OktaSaml/>
          <PopUp
            isOpen={this.state.editProfile || this.state.createUser}
            trigger={PrimaryStyledButton}
            component={UserProfile}
            triggerProps={this.props.app_user.business_type ? {
              title:"+ Add User"              
            } : null}
            componentProps={{
              width: 500,
              viewOnly: hasManagerRights() ? false : true,
              roles: this.getUserRoles(),
              showWorkspace: this.props.app_user.business_type === 1,
              workspaceList: this.state.workspaceList,
              userData: userData || {},
              updateUserProfile: (data) => {
                this.setState({ userData: data });
              }
            }}
            primaryBtnName={this.state.editProfile ? "Save" : "Send Invite"}
            secondaryBtnName="Cancel"
            onCancel={() => {
              this.setState({ userData: null, editProfile: false });
            }}
            onSubmit={() => {
              if(!hasManagerRights()) {
                return;
              }
              this.handleSubmit();                            
            }}
            onSubmitValidated={
              userData.first_name 
                && userData.email 
                //&& userData.client_name
                && userData.role
                && userData.role.length
            }
          />
         </ButtonWrapper>
        </SpaceBetweenWrapper>
        <LineSeparator />
        <UsersList 
          tableData={tableData}  
          isLoading={this.state.isLoading}       
          roles={this.state.roles}
          viewOnly={hasManagerRights() ? false : true}
          editProfile={this.editProfile}
          updateUser={this.updateUser}
          deleteUser={this.showDeleteConfirm}
          resendPassword={this.resendPassword}
        />
        <ConfirmPopup 
            show={this.state.showConfirm}
            title="Delete User"
            message={`Are you sure you would like to permantly delete this user`}
            onClose={(done) => {
                if(done){
                  this.deleteUser(userData.id);                    
                }
                this.setState({ showConfirm: false, userData: null });
            }}
        />   
        <Modal
          visible={this.state.showInfo}
          header = {null}
          closable={false}
          title={null}
          width={500}
          footer={null}
        >
          <SetupHelper 
            title="User Management"
            description="Add agents and managers and organize into teams to keep manager dashboards focused on the right agents."
            buttonName={ hasManagerRights() ? "Add a User" : null }
            illustration={UserManagementIllustration}
            onCancel={() => this.setState({ showInfo: false })} 
            onOpen={this.addUser} 
          />          
        </Modal>
      </SetupWrapper>
    )
  }
}

const mapStateToProps = state => ({ 
  app_user: state.app_user, 
  selectedWorkspace: state.app_user.selectedWorkspace 
});

export default connect(mapStateToProps, null)(UserManagement);
