import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../assets/close-round.svg";
import FlagIcon from "../../assets/flag-tag.svg";

const TagWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 100px;
    background: ${props => props.isSelected ? "#DB554D" : "transparent"};
    cursor: pointer;
    color: ${props => props.isSelected ? "#FFFFFF" : "#828282"};
    font-size: 11px;
    line-height: 13px;
    border: 1px solid #6099D9;
    border: ${props => props.isSelected && "none"};
    svg {
        opacity: ${props => props.isSelected ?  0.7 : 1};
        width: 10px;
        height: 10px;
        transform: ${props => props.isSelected ? "rotate(0deg)" : "rotate(45deg)"};
        margin: ${props => props.isSelected ? "0 0 0 4px" : "0 4px 0 0"};
        path {
            fill: ${props => props.isSelected && "#FFFFFF"};
        }
    }
    img {
        margin-right: 4px;
    }
`;

const EscalationTag = ({isSelected, onSelect}) => {
    if(isSelected) {
        return (
            <TagWrapper onClick={() => onSelect(false)} isSelected>
                <img alt="" src={FlagIcon} />
                Flag if Missed 
                <CloseIcon className="close-icon" />
            </TagWrapper>
        );
    }
    else {
        return (
            <TagWrapper onClick={() => onSelect(true)}><CloseIcon /> Add Flag</TagWrapper>
        );
    }
}

export default EscalationTag;