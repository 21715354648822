import styled from '@emotion/styled';
import React from 'react';
import { getRandomColor } from '../../utils/utilities';

export const StyledDefaultImage = styled.div`
    min-height: ${({radius})=>radius || '100%'};
    min-width: ${({radius})=>radius||'100%'};
    border-radius: 50%;
    margin: ${({margin})=>margin||"0 0.5rem 0 0"};
    background: ${({background}) => background||'#7D9ED6'};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({color}) => color||'#FFFFFF'};
    font-size: 12px;
    font-weight: normal;
    font-family: Roboto;
`
const UserInitialsAvatar = ({name =" ",radius = "2rem", margin, background}) => {
    const time= new Date().getTime()
    let shortname = name[0];
    const lastnameind = name.indexOf(' ')+1;
    if(lastnameind < name.length) {
      shortname = shortname+name[lastnameind];
    }
    return (
        <StyledDefaultImage radius={radius} margin={margin} background={()=> background ||  getRandomColor(name + Math.random() + time)}>
            {shortname.toUpperCase()}
        </StyledDefaultImage>
    );
    
}

export const MemoizedAvatar =  React.memo(UserInitialsAvatar);
