import { Select } from 'antd'
import React from 'react'
import { useSelector } from "../../store";

const SectionFilter = ({value,handleChange}) => {
  const options = useSelector(
    (state) => state.dashboard?.segments?.sections
  );

  const { Option } = Select

  return (
    <Select value={value} onChange={(val) => handleChange(val)} placeholder="All Section Option">
      {
        options?.length > 0 &&
          options.map((option, index) => (
            <Option key={index} value={option}>{option}</Option>
          ))
      }
    </Select>
  )
}

export default SectionFilter