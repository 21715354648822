import React from 'react';
import { TIMELINE_TYPES } from "./Constants";
import { ReactComponent as FollowedIcon } from "./../assets/timeline/followed-icon.svg";
import { ReactComponent as NotFollowedIcon } from "./../assets/timeline/not-followed-icon.svg";
import { ReactComponent as FollowAmbigousIcon } from "./../assets/timeline/ambiguous-follow.svg";
import appConfig from "../config";

const FollowCategories = [];

if(appConfig.SHOW_TIP_FOLLOW) {
    FollowCategories.push(TIMELINE_TYPES.HINT);
}
if(appConfig.SHOW_RESOURCE_FOLLOW) {
    FollowCategories.push(TIMELINE_TYPES.KNOWLEDGE);
}

const FollowIcon = ({ followed, category }) => {
    if(!FollowCategories.includes(category)) {
        return null;
    }
    else if(followed === "Followed") {
        return <FollowedIcon />;
    }
    else if(followed === "Not Followed") {
        return <NotFollowedIcon />;
    }
    else {
        return <FollowAmbigousIcon />;
    }    
}

export default FollowIcon;