export const userHistory = [
    {
         title: "Home"
    }
 ];

 export const omit = (obj, props) => {
     let result = JSON.parse( JSON.stringify(obj) );
     props.forEach(e => delete result[e]);
     if (result.date && Array.isArray(result.date)) {
       result.date = JSON.stringify(result.date);
     }
     return result;
};

export const agentFeedbackOptions = ["REJECT", "NONE", "ACCEPT"];