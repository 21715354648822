import { axiosInstance, BASE_URL, getSchemaName, getToken } from "../api/api";
import { ReduxActionTypes, NotificationActionTypes } from "../constants/ReduxActionConstants";


export const getNewCount = () => (dispatch) => {
    return axiosInstance({
        method: "GET",
        url: `/notifications/new/count/`,
    }).then(response => {
        dispatch({
            type: NotificationActionTypes.SET_UNREAD_NOTIFICATIONS,
            payload: response.data
        })
        return response.data;
    });
};


export const readNotification = (id) => {
    return axiosInstance({
        method: "PUT",
        url: `/notifications/bulk/read/${id}`,
        data: {
            is_read: true,
        }
    }).then(response => {
        return response.data;
    });
};


export const newNotifications = () => (dispatch) => {
    return axiosInstance({
        method: "PUT",
        url: `/notifications/new/`,
    }).then(response => {
        dispatch(getNewCount())
        return response.data;
    });
};

export const requestBulkNotifications = () => (dispatch) => {
    dispatch({
        type: NotificationActionTypes.REQUEST_NOTIFICATIONS,
    })
    return axiosInstance({
        method: "GET",
        url: `/notifications/bulk/list/`,
    }).then(response => {
        dispatch({
            type: NotificationActionTypes.SET_NOTIFICATIONS,
            payload: response.data.results
        })
        dispatch({
            type: NotificationActionTypes.SET_NEXT_PAGE_NOTIFICATIONS,
            payload: response.data.next
        })
        return response.data;
    });
};

export const fetchNotification = (id) => {
    return axiosInstance({
        method: "GET",
        url: `/notifications/${id}/`,
    })
};


export const requestNextPageBulkNotifications = (page) => (dispatch) => {
    dispatch({
        type: NotificationActionTypes.START_PAGINATING
    })
    return axiosInstance({
        method: "GET",
        url: `/notifications/bulk/list/?page=${page}`,
    }).then(response => {
        dispatch({
            type: NotificationActionTypes.PAGINATION_SET_NOTIFICATIONS,
            payload: response.data.results
        })
        dispatch({
            type: NotificationActionTypes.SET_NEXT_PAGE_NOTIFICATIONS,
            payload: response.data.next
        })
        return response.data;
    });
}
