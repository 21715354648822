import React from "react";
import * as Sentry from '@sentry/react';
import styled from "@emotion/styled/macro";

const RetryLink = styled.span`
    color: rgb(96, 153, 217);
    cursor: pointer;
`;

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log({ error, errorInfo });
      Sentry.captureException(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <p>
                Sorry, Something went wrong. 
                <br/>
                <RetryLink onClick={() => {
                  window.location.reload();
                  this.setState({ hasError: false })}}>
                    Click here to retry
                </RetryLink>
            </p>
        );
      }  
      return this.props.children; 
    }
}
