import React, { useEffect, useReducer } from 'react'
import { useSelector } from '../../store';
import { getMeasureText, queryAggregateCubeJS, roundOff } from '../utils'
import { AnalyticsSummaryWrapper, AnalyticsSummaryCardWrapper } from './wrapper'


const metricsReducer = (metrics, action) => {
    const { index, value } = action.payload;
    let newValues = [...metrics]
    switch(action.type) {
        case "SET_TITLE": {
            newValues[index].title = value;
            break
        }
        case "SET_VALUE": {
            newValues[index].value = roundOff(value);
            break
        }
    }
    return newValues;
}

export const AnalyticsSummary = (props) => {
    const [ metrics, dispatch ] = useReducer(metricsReducer, props.queries.map((q) => ({title: q.title || getMeasureText(q.measure), value:"-"})));
    const globalFilters = useSelector(state => state.dashboard.globalFilters);
    
    useEffect(() => {
        props.queries.forEach((query, index) => {
            queryAggregateCubeJS(
                query, 
                globalFilters,
                (value) => {
                    dispatch({
                        type: "SET_VALUE",
                        payload: {
                            index,
                            value
                        }
                    })
                }
            )
        });
    }, [globalFilters])

    return (
        <AnalyticsSummaryWrapper>
                {
                    props.queries.map((query, index) => (
                        <AnalyticsSummaryCardWrapper>
                            <div className={"header"}>{metrics[index].title}</div>
                            <div className={"value"}>{metrics[index].value}</div>
                        </AnalyticsSummaryCardWrapper>
                    ))
                }
        </AnalyticsSummaryWrapper>
    )
}