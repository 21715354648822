import { Modal } from "antd";
import styled from "@emotion/styled/macro";

export const PlayGroundPopupWrapper = styled.div`
  width: 100%;
  padding: 25px;
  font-family: Roboto;
  .playground-heading {
    font-weight: 400;
    font-size: 28px;
    .debugger-text {
      cursor: pointer;
      color: #bb6bd9;
      margin-left: 10px;
      font-size: 12px;
    }
    .debugger-text:hover {
      color: black;
      transition: 0.3s ease-in;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  mark {
    background: #bb6bd9;
    color: white;
  }
`;

export const Wrapper = styled.div `
  display: flex;
  margin-bottom: 0px;
  align-items: center;
  .anticon-close-circle {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  & .ant-input {
    padding: 4px 10px;
  }
`;
export const PlayGroundTestingFieldWrapper = styled.div`
  border: 1px solid #e0e0e0;
  width: 630px;
  height: 161px;
  margin-bottom: 10px;

  .playground-testing-field-heading {
    border-bottom: 1px solid #e0e0e0;
    padding: 13px 20px 3px 20px;
    .playground-testing-field-heading-p {
      font-size: 11px;
      font-weight: 400;
    }
  }

  .playground-testing-field-button-container {
    padding: 13px 20px 3px 20px;
    .playground-display-phrase {
      padding-left: 8px;
      font-size: 12px;
      color: #6099d9;
      overflow-y: scroll;
      max-height: 45px;
      .playground-display-phrase-div {
        padding-left: 5px;
        font-size: 12px;
        color: #6099D9;
      }
    }
  }
`;

export const PlayGroundTestingResultsWrapper = styled.div`
  width: 630px;
  height: 329px;
  overflow-y: auto;
`;

export const PlayGroundTriggeredScenariosWrapper = styled.div`
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  .triggered-scenarios-container {
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e0e0e0;
  }

  h2 {
    color: #4f4f4f;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  p {
    font-size: 12px;
    color: #828282;
    font-weight: normal;
  }
`;

export const PlaygroundButtonContainer = styled.div`
  position: relative;
  margin-top: 33px;
  .closeButton {
    top: -15px;
    float: right;
  }
`;

export const PlayGroundDebuggerData = styled.div`
  padding: 15px;
  color: black;
  overflow-y: auto;
  width: 500px;
  max-height: 500px;
  .debugger-data-wrapper {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .empty-debugger-data-wrapper {
    color: gray;
    display: flex;
    justify-content: center;
  }
`;

export const PlaygroundNoScenariosFoundWrapper = styled.div`
  width: 630px;
  height: 329px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 18px;
`;

export const ShowSpinner = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
`;
