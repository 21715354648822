import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../components/common/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import { InternalDashboardWrapper } from './styles';
import TriggerableRoute from './TriggerableRoute';
import { isAuthorized, isDashboardUser, mapStateToProps } from './utilities';

const InternalToolsRoute = ({ component, ...rest }) => {
  //Check if user is authorized
  if(isAuthorized(rest.app_user)) {
      //Check if annotation tool user 
      if(!isDashboardUser(rest.app_user)) {
        return <TriggerableRoute component={component} {...rest} />    
      }
      else {
        return <Redirect to="/" />  
      }
  }
  else {
      return <Redirect to="/annotation/login" />        
  }
}

const InternalToolsRouteWrapper = connect(mapStateToProps, null)(({ component: Component, ...rest }) => {
  return (
    <InternalToolsRoute {...rest} 
        render={(props) => {
            return (
              <ErrorBoundary>
                <InternalDashboardWrapper>                      
                    <Header {...rest} {...props} />
                    <Component {...props}/>                      
                </InternalDashboardWrapper>
              </ErrorBoundary>
            )
        }}
    />
  )
});

export default InternalToolsRouteWrapper