import styled from "@emotion/styled";
import { Tabs, Collapse, Radio, Spin, notification, Select } from 'antd';

const { Option } = Select;

export const QaCalibrationWrapper = styled.div`
background: #fafafa;
display: flex;
flex-direction: column;
height: calc(100vh - 42px);
.span-completed {
  color: #27AE60;
}
.span-pending {
  color: #E89244;
}
`;

export const CalibrationSubHeaderWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: baseline;
`;

export const CalibrationHeader = styled.div`
width: 100%;
border-bottom: 1px solid #e0e0e0;
padding: 15px 23px;
`;

export const CalibrationListTitle = styled.div`
font-weight: bold;
font-size: 24px;
line-height: 28px;
color: #828282;
margin-top: 9px;
`;

export const NewSessionWrapper = styled.div`
`;
export const ModalBodyWrapper = styled.div`
  padding: 20px;
  .header-message {
    width: 350px;
    height: 64px;
    font-weight: bold;
    font-size: 20px;
    color: #4F4F4F;
  }
  .modal-footer {
    display: flex;
    margin-top: 10px;
  }
`;

export const RowWrapper = styled.div`
padding: 16px 24px;
max-height: 100%;
`;

export const Label = styled.div`
margin-bottom: 4px;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: black;
letter-spacing: 0.0025em;
line-height: 20px;
`;
export const DateTimeWrapper= styled.div`
margin-bottom: 25px;
max-width: 368px;
`;

export const ConversationIDWrapper = styled.div`
margin-bottom: 25px;
max-width: 368px;
`;

export const QAAuditorWrapper = styled.div`
max-width: 368px;
`;

export const ModalFooter = styled.div`
display: flex;
padding: 20px;
margin-top: -10px;
`;

export const CancelButton = styled.div`
background: ${props => props.background || "#ffffff" };
border: 1px solid #0b4b93;
box-sizing: border-box;
border-radius: 4px;
//width: 106px;
height: 32px;
padding: 8px 12px;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
cursor: pointer;
color: ${props => props.color || "black"};
pointer-events: ${props => props.disabled ? "none" : "all"};
opacity: ${props => props.disabled ? "0.5" : "1"};
margin: ${props => props.margin || "0px" }
`;

export const SendInviteButton = styled.div`
height: 32px;
background: ${props => props.background || "#0b4b93" } ;
border: 1px solid #0b4b93;
color: white;
box-sizing: border-box;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
margin: 0px 6px 0px 0px;
font-size: 14px;
padding: 8px 16px;
cursor: pointer;
pointer-events: ${props => props.disabled ? "none" : "all"};
opacity: ${props => props.disabled ? "0.5" : "1"};
`;

export const StyledSelect = styled(Select)`

`

// export const BodyWrapper = styled('body')`
//   .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item.select-all {
//     .ant-select-selected-icon {
//       display: none !important;
//     }
//   }
// `;

export const ViewWrapper = styled(QaCalibrationWrapper)`
`;

export const ViewHeaderWrapper = styled(CalibrationHeader)`
`;

export const ViewWrapperTitle = styled(CalibrationListTitle)`
display: flex;
align-items: baseline;
column-gap: 12px;
& .status {
font-weight: normal;
font-size: 14px;
line-height: 20px;
}
`;

export const SpinnerWrapper = styled.div`
height: 100%;
width: 100%;
justify-content: center;
display: flex;
align-items: center;
`;

export const SessionSummaryWrapper = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`
export const SessionButtons = styled.div`
display: flex;
width: 30%;
justify-content: flex-end;
`;


export const SummaryWrapper = styled.div`
display: flex;
align-items: center;
width: 70%;
svg {
  height: 48px;
  width: 25px;
  margin-top: 5px;
  margin-right: 10px;
  path {
    fill: #BDBDBD;
  }
}
`;

export const GenerateSummaryWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const SummaryMetaDetailsWrapper = styled.div`
display: flex;
align-items: center;
color: #bdbdbd;
font-size: 12px;
line-height: 14px;
margin-top: 3px;
span {
  border-right: 1px solid #bdbdbd;
  padding: 0 12px;
}
span:first-of-type {
  padding-left: 0;
}
span:last-of-type {
  border: none;
}
.summary-channel-convo {
  & > span {
    display: flex;
    align-items: center;
  }
}
svg{
  height: 18px;
  width: 18px;
  margin: -2px 2px;
}
`;

export const CompleteSessionButton = styled(SendInviteButton)`
`;

export const GenerateReportWrapper = styled.div`
font-family: Roboto;
background: #fafafa;
`;

export const MetricViewWrapper = styled.div`
padding: 16px;
position: relative;
width: 100%;
height: ${props => props.height};
`;

export const CalibrationScoreWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    //margin: 8px;   
    min-height: 130px;
`;

export const TitleWrapper = styled.div`
    height: 36px;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    box-sizing: border-box;
    padding: 12px 15px;
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    color: #828282;
`;

export const ScoreCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    row-gap: 15px;
    padding: 12px 15px;
`
export const Score = styled.div`
font-weight: normal;
font-size: 32px;
line-height: 48px;
color: #333333;
`;

export const EmptyStateWrapper = styled.div`
width: 100%;
    height: 100%;
    align-items: center;
    position: absolute;
    top: 10%;
    display: flex;
    justify-content: center;
    span {
      width: 329px;
      color: #BDBDBD;
      font-weight: normal;
      font-size: 12px;
      text-align: center;
    }
`;
export const ResponseViewWrapper = styled.div`
padding: 16px;
max-height: calc(82vh - 42px);
overflow-y: scroll;
`;

export const ParticipantResponses = styled.div`
margin-top: 8px;
width: 100%;
background: #FFFFFF;
border: 1px solid #F0F0F0;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
border-radius: 4px;
.participant-responses-heading {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}
`;
export const ParticipantScoreList = styled.div`
border-bottom: 1px solid #E0E0E0;
margin-top: 8px;
min-height: 40px;
`;
export const ParticipantScoreListSubWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: baseline;
cursor: ${props => props.disable ? "default" : "pointer"};
padding: 5px 15px;
margin-bottom: 5px;
transition: 0.4s ease;
${props => props.disable ? '' : `&:hover {
background: #FAFAFA;
  .name {
    color: #6099D9 !important;
  }
  .participant-score {
    color: #6099D9 !important;
    & svg {
      path {
        fill: #6099D9;
      }
  }
}`
}

`;
export const NameWrapper = styled.div`
display: flex;
align-items: baseline;
justify-content: flex-start;
.name {
font-weight: normal;
font-size: 14px;
color: #828282;
}
`;

export const ParticipantScore = styled.div`
display: flex;
align-items: center;
color: #828282;
font-weight: normal;
font-size: 14px;
& svg {
  width: 20px;
  height: 12px;
  path {
    fill: #828282;
  }
}
.no-score {
  padding-right: 5px;
  color: #BDBDBD;
}
`;
export const AverageQAScoreWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 12px 35px 12px 21px;
width: 100%;
`;
export const QAScoreTitle = styled.div`
display: flex;
align-items: center;
.qa-score-span {
  font-weight: normal;
  font-size: 14px;
  color: #828282;
}
`;
export const QaScore = styled(ParticipantScore)`
`;

export const CommentWrapper = styled.div`
width: 100%;
`;

export const ButtonWrapper = styled.div`
display: flex;
justify-content: space-around;;
align-items: center;
padding: 6px 16px;
width: 141px;
height: 35px;
background: ${(props) => (props.disable ? "#f5f5f5" : "white")};
border: 1px solid #d9d9d9;
box-sizing: border-box;
border-radius: 4px;
font-size: 14px;
color: ${(props) => (props.disable ? "#bfbfbf" : "black")};
cursor: pointer;
pointer-events: ${(props) => (props.disable ? "none" : "all")};
transition: 0.4s ease;
span {

}
&:hover {
  //background: ${(props) => (props.disable ? "#f5f5f5" : "whitesmoke")};
  border: ${(props) => (props.disable ? "1px solid #d9d9d9" : "1px solid #6099D9")};
}
`;

export const PostCommentWrapper = styled.div`
  width: 100%;
  padding: 5px;

  & .ant-input {
    padding: 6px;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const PostButtonWrapper = styled.div`
  cursor: pointer;
  padding: 5px;
  background: ${(props) => (props.loading || props.disable ? "#f5f5f5" : "white")};
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${(props) => (props.loading || props.disable ? "#bfbfbf" : "black")};
  pointer-events: ${(props) => (props.loading || props.disable ? "none" : "all")};
  transition: 0.4s ease;
  &:hover {
    //background: ${(props) => (props.loading || props.disable ? "#f5f5f5" : "whitesmoke")};
    border: ${(props) => (props.disable ? "1px solid #d9d9d9" : "1px solid #6099D9")};
  }
`;
export const CancelButtonWrapper = styled(PostButtonWrapper)`
  margin-left: 8px;
`;

export const EditCommentWrapper = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: baseline;
`;

export const CommentView = styled.div`
display: flex;
align-items: baseline;
width: 90%;
`;

export const Comment = styled.div`
font-weight: normal;
font-size: 14px;
letter-spacing: 0.0025em;
color: #828282;
`;

export const TabHeader = styled.div`
height: 100%;
overflow-y: scroll;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
    &::-webkit-scrollbar { 
      width: 0;
      height: 0;
  }
`;

export const TabViewWrapper= styled.div`
    width: 100%;
    display: flex;
    //flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    min-height: 165px;
    .ant-tabs {
      width: 100%;
      & .ant-tabs-bar {
        margin: 0 0 16px 10px;
      }

      .ant-collapse {
        border: none;
        width: 100%;
        & .ant-collapse-header {
          background: white;
        }

        & .ant-collapse-content {
          border: none;
        }
      }
    }
`;

export const PanelHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: baseline;
`;

export const QuestionBlock= styled.div`
width: 100%;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
margin-bottom: 16px;
`;

export const QuestionTitleWrapper = styled.div`
padding: 16px;
    font-size: 14px;
    font-weight: normal;
    color: black;
    letter-spacing: 0.0025em;
    line-height: 20px;
    border-bottom: 1px solid #E0E0E0;
`;

export const QuestionBodyWrapper = styled.div`
.ant-radio-group {
  width: 100%;
}
`;

export const QuestionResponseWrapper = styled.div`
width: 100%;
display: flex;
padding: 16px;
justify-content: space-between;
align-items: baseline;
border-bottom: 1px solid #E0E0E0;
background: #FAFAFA;
min-height: 76px;
`;

export const QuestionResponse = styled.div`
width: 60%;
display: flex;
flex-direction: column;
row-gap: 8px;
`;

export const OptionTitle = styled.div`
color: #828282;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.0025em;
`;

export const ParticipantWrapper = styled.div`
display: flex;
max-width: 100%;
flex-wrap: wrap;
column-gap: 8px;
row-gap: 5px;
`;

export const Participants = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: #FAFAFA;
border: 1px solid #E0E0E0;
box-sizing: border-box;
border-radius: 4px;
padding: 4px 8px;
pointer-events: none;
`;

export const CommentWrappers = styled.div`
width: 100%;
display: flex;
padding: 16px;
`;

export const TabFooter = styled.div`
width: 100%;
background: white;
border: 1px solid #E0E0E0;
margin : 0px 8px 0px 2px;
padding: 16px;
`;

export const CalculateScoreButton = styled.div`
display: flex;
width: 100%;
background:  #0B4B93;
border-radius: 4px;
justify-content: center;
align-items: center;
padding: 6px 16px;
color: white;
cursor: pointer;
transition: 0.4s ease;
opacity: ${props => props.disabled || props.loading ? "0.5" : "1"};
pointer-events: ${props => props.disabled || props.loading ? "none" : "all"};
${props => props.disabled || props.loading ? `` : `&: hover {
  opacity: 0.7;
}`}
`;

export const Spinner = styled(SpinnerWrapper)`
height: auto;
`;
