import React from "react";
import GenericNode from "./genericNode";
import { StyledNodeHead } from "./styles";
import { ReactComponent as ManipulationIcon } from "../../assets/manipulation.svg";

const ManipulationNode = ({ data }) => {
  return (
    <GenericNode nodeData={data} nodeType="manipulation">
      <StyledNodeHead>
        <div className="manipulation">
          <ManipulationIcon />
        </div>
        {data.display_name}
      </StyledNodeHead>
    </GenericNode>
  );
};

export default ManipulationNode;
