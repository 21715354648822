import React, { useCallback } from 'react'
// import { HorizontalButtonWrapper } from '../../../../components/common/styled-components'
// import AddButton from "../../../../components/common/AddButton";
// import RemovableEntry from "./../../RemovableEntry"
import { Button, Input } from 'antd';
import { debounce } from "lodash";
import styled from "@emotion/styled";

const StylePhraseInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    button {
        width: 172px;
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const StyledPhraseInput = styled(Input)`
    width: 100%;
    padding: 4px 10px;
    border: 1px solid;
    border-color: #BDBDBD;
    position: relative;    
    margin-bottom: 12px;
`;

const InputPhrase = ({onChange, phrase, index }) => {
    const debounceHandleChange = useCallback(debounce(onChange, 800), []);
    const handleChange = (event) => {
        const text = event.currentTarget.value;
        debounceHandleChange(index, text);
    }
    return (
        <StyledPhraseInput 
            name="phrase" 
            placeholder={"Enter word or phrase"}
            onChange={handleChange} 
            defaultValue={phrase}                    
        />                             
    )
}

export default class InputPhrases extends React.Component {
    constructor(props) {
        super(props);
        const phrases = props.phrases.length ? props.phrases : [{
            id: "0",
            text: ""
        }];
        this.state = {
            phrases: phrases,
            showValidations: !!props.showValidations
        };
    }
    componentDidUpdate(prevProps) {
        if(this.props.showValidations !== prevProps.showValidations) {
            this.setState({ showValidations: this.props.showValidations });
        }
    }
    updatePhrases = (phrases) => {
        this.setState({
            phrases: phrases
        }, () => {
            const updatedPhrase = this.state.phrases.map((item) => item.text).join(",");
            this.props.onChange(updatedPhrase);
        }); 
    }
    handleChange = (index, phrase) => {
        const updatedPhrases = [...this.state.phrases];
        updatedPhrases[index].text = phrase;
        this.updatePhrases(updatedPhrases);        
    }
    addPhrase = () => {
        const { phrases } = this.state;
        const updatedPhrases = [
            ...phrases,
            ...[{
                id: `${phrases.length}`,
                text: ""
            }]
        ];
        this.updatePhrases(updatedPhrases);
    }
    render () {
        const { phrases, showValidations } = this.state;
        const invalidPhrases = showValidations && !!phrases.filter((item) => !item).length;
        return (
            <StylePhraseInputWrapper>
                {phrases.map((phrase, index) => {
                    return (
                        <InputPhrase
                            key={phrase.id}
                            index={index}
                            onChange={this.handleChange} 
                            phrase={phrase.text}                    
                        />                     
                    )
                })}
                {invalidPhrases ? 
                    <div className="error-message">Please enter word or phrase</div> :
                    <Button type="secondary" onClick={this.addPhrase}>+ Add Word or Phrase</Button> 
                }
            </StylePhraseInputWrapper>        
        )
    }
}
