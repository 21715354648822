
export const isValueExists = (optionValue, _value) => {
  let flag = false;
  if ((!_value || !_value?.length) && (_value !== 0 || _value !== "0")) {
    return flag;
  }
  _value.forEach((val) => {
    if (val == optionValue) {
      flag = true;
    }
  });
  return flag;
};

export const handleSearch = (event, _options , options, setSearchEmptyState, setSearch, setSearchOptions) => {
  const value = event.target.value;
  setSearch(value);
  if (!value) {
    return setSearchOptions(_options);
  }
  const updatedOptions =
    _options?.filter((option) =>
    typeof option !== "object" ? option.toLowerCase().includes(value.toLowerCase()) : option.label?.toLowerCase().includes(value.toLowerCase())
    ) || [];

  setSearchOptions(updatedOptions);

  if (!updatedOptions.length) {
    setSearchEmptyState(true);
  } else {
    setSearchEmptyState(false);
  }
};

export const checkArrayOrString = (val, setValue) => {
  let value = [];
  if (val && Array.isArray(val) && val.length) {
    setValue(val);
    value = val;
  } else if ((val || val === "0" || val === 0) && typeof val === "string") {
    setValue([val]);
    value = [val];
  } else {
    setValue([]);
    value = [];
  }

  return value;
};
