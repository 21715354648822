import axios from "axios";
import { getSchemaName } from "../api/api";
import useStore from "./store";

export const useAutomationFetcher = () => {
  const token = useStore((state) => state.automationAuthToken);
  const automationAxios = axios.create({
    baseURL: "https://stage-automation.thelevel.ai",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-DTS-SCHEMA": getSchemaName(),
    },
  });

  return {
    fetcher: (url) => automationAxios.get(url).then((res) => res.data),
    automationAxios,
  };
};
