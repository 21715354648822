import React, {Suspense} from "react";
import { Route,Redirect } from "react-router-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import GlobalSpinner from '../components/common/GlobalSpinner'
import TriggerableRoute from "./TriggerableRoute";
import ProtectedRoutes from './protectedRoutes';
import {PrivateRouteWrapper} from './privateRoutes';
import InternalToolsRouteWrapper from "./InternalToolsRoute";
import { publicRoutes } from "./publicRoutes/data";
import NotificationRedirect from "./privateRoutes/NotificationRedirect";
import { componentLoader } from "./utilities";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";

const Annotation=React.lazy(() => componentLoader(() => import('../annotation')))
const Generic404Component= React.lazy(() => componentLoader(() => import('../components/common/Generic_404')));

const AppRouter = () => (
  <BrowserRouter>
     <ErrorBoundary FallbackComponent={ErrorFallback}>   
     <Suspense fallback={<GlobalSpinner/>}>
        <Switch>
          <Route exact path="/404" component={Generic404Component} />
          {publicRoutes.map(route => <TriggerableRoute exact={route.exact} path={route.path} component={route.component} key={route.path}/>)}
          <InternalToolsRouteWrapper exact path="/annotation/:type?" component={Annotation} showHeader/>
          <PrivateRouteWrapper exact path="/notifications/:id" component={NotificationRedirect} />
          <ProtectedRoutes />
          <Redirect to="/404"/>
        </Switch>
      </Suspense>
     </ErrorBoundary>
  </BrowserRouter>   
);

export default AppRouter;
