import { Tabs } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PrimaryStyledButton } from "../../../components/common/styled-components";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { DASHBOARD_OPTIONS } from "../../../user-dashboard/utils";
import { getConversationCount, getConversationCountGraphQl } from "../../../conversation-list/api";
import appConfigs from "../../../config";
import { getApiFilters, openNotification } from "../../../utils/utilities";
import {
  ParentWrapper,
  RenderFilterWrapper,
  TabsWrapper,
  OptionsWrapper,
  FilterWrapper,
  Wrapper,
  Footer,
  ResetAllButton,
  CustomTabPane,
  TabTitleWrapper
} from "../wrappers";
import { filterReducer } from "../reducer";
import { setGlobalFilters } from "../../utils";
import { REDUCER_CONSTANTS } from "../../configs";
import EmptyState from "../../../components/common/EmptyState";
import Constants from "../../../CompanySetup/utils/constants";
import { EmptyStateWrapper } from "../../../user-dashboard/wrappers";
import { spliceText } from "../../../components/common/CustomFilterComponent/utils";
import { omit } from "../../../user-dashboard/configs";

const RenderFilters = ({
  additionalFilters,
  handleModal,
  open
}) => {
  const dispatch = useDispatch();
  const globalFilters = useSelector((state) => state.dashboard.globalFilters);

  const [filterState, dispatchReducer] = useReducer(filterReducer, {
    _additionalFilters: [],
    globalFilterObject: {},
    localFilterObject: {},
    resetAllEnabled: false,
    showButtonEnabled: false,
  });
  const [activeKey, setActiveKey] = useState(additionalFilters?.length ? additionalFilters[0]?.key : "");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const showConversations = () => { 
    dispatch(setGlobalFilters(filterState)).then(() => {
        handleModal();
        dispatchReducer({
          type: REDUCER_CONSTANTS.ENABLE_SHOW_BUTTON,
          payload: { status: false },
        });
        openNotification("bottomLeft", "Filter applied successfully.");
      })
      .catch((err) => {});
  };
  const handleFilterValueChange = (key, value) => {
    dispatchReducer({
      type: REDUCER_CONSTANTS.UPDATE_FILTER_VALUE,
      payload: {
        key,
        value,
      },
    });
  };

  const resetSelected = (key) => {
    dispatchReducer({
      type: REDUCER_CONSTANTS.RESET_SELECTED_FILTER,
      payload: { key },
    });
  };
  const resetAll = () => {
    const initialFilters = {};
    Object.entries(filterState.localFilterObject).forEach(([key, value]) => {
      initialFilters[key] = [];
    });

    dispatchReducer({
      type: REDUCER_CONSTANTS.RESET_ALL_FILTERS,
      payload:  { ...initialFilters },
    });
  };

  const checkResetAllEabled = () => {
    if(filterState.localFilterObject && Object.keys(filterState.localFilterObject).length) {
      const enabled = Object.entries(filterState.localFilterObject).some(([key, value]) => value?.length || value === true || (typeof(value) === 'number'));
      return enabled;
    }
    return false;
  };

  const setupLocalFilterObject = () => {
      let localFilters = {};
      const _globalFilters = globalFilters && Object.keys(globalFilters)?.length ?  Object.entries({ ...globalFilters }) : []
      _globalFilters.forEach(([key, value]) => {
        if(value !==null && value !== undefined && value !== "") {
          localFilters[key] = Array.isArray(value) ? value?.map(String) : (key !== "tagged_for_qa" ?  `${value}` : value) ;
        }
      });

      localFilters = omit(localFilters, ["dateRange", "dateRangeValues", "date", "timeGranularity"]);

      if(localFilters && Object.keys(localFilters)?.length) {
        Object.keys(localFilters).forEach(key => {
          if(localFilters[key] === null || localFilters[key] === undefined || localFilters[key] === "" || (Array.isArray(localFilters[key]) && !localFilters[key]?.length)) {
            delete localFilters[key];
          }
        })
      }

      dispatchReducer({
        type: REDUCER_CONSTANTS.UPDATE_LOCAL_FILTER_OBJECT,
        payload: { ...localFilters },
      });
  };

  const checkResetSelectEnabled = (filter) => {
    return filterState?.localFilterObject?.hasOwnProperty(
      filter.key
    ) && (filterState?.localFilterObject[filter.key]?.length || filterState?.localFilterObject[filter.key] === true  || typeof(filterState?.localFilterObject[filter.key]) === 'number')
  }

  useEffect(() => {
    dispatchReducer({
      type: REDUCER_CONSTANTS.UPDATE_GLOBAL_FILTER_OBJECT,
      payload: { ...globalFilters },
    });
  }, [globalFilters]);

  useEffect(() => {
    if(open) {
        setupLocalFilterObject();
        setActiveKey(activeKey);
        dispatchReducer({
          type: REDUCER_CONSTANTS.UPDATE_GLOBAL_FILTER_OBJECT,
          payload: { ...globalFilters },
        });
    }
    else {
      //setActiveKey(additionalFilters[0].key);
    }
  }, [open, globalFilters]);

  useEffect(() => {
    dispatchReducer({
      type: REDUCER_CONSTANTS.UPDATE_ADDITIONAL_FILTER,
      payload: additionalFilters,
    });
  }, [additionalFilters]);
  

  return (
    <ParentWrapper className="d-flex flex-column">
      <RenderFilterWrapper className="d-flex flex-column">
        <TabsWrapper filterCount={filterState._additionalFilters?.length} className="col-12">
          {filterState?._additionalFilters?.length ? (
            <Tabs
              onChange={(key) => {
                handleTabChange(key);
              }}
              activeKey = {activeKey}
              defaultActiveKey={activeKey || filterState?._additionalFilters[0]?.key}
              tabPosition={"left"}
              className="scrollbar-css"
              style={{ height: "100%" }}
            >
              {filterState._additionalFilters.map(
                (filter) =>
                  filter.show(true) && (
                    <CustomTabPane tab={<TabTitleWrapper><div className="label-css">{ spliceText(filter.name, 20, true) }</div> { checkResetSelectEnabled(filter) && <CheckCircleIcon className = "check-circle"/> }</TabTitleWrapper>} key={filter.key}>
                      <FilterWrapper>
                        {filter.additionalFilterConfig?.component({
                          options: filter.options,
                          value: filterState.localFilterObject[filter.key] || undefined,
                          searchStyle: { width: "95%", marginTop: "10px", padding: "4px 0px" },
                          checkBoxStyle: { marginTop: "15px" },
                          placeholder: `Search ${ spliceText(filter.name, 20) }`,
                          multiSelect: filter.multiSelect,
                          onChange: (e) =>
                            filter.additionalFilterConfig.onChange(
                              e,
                              filter.key,
                              handleFilterValueChange
                            ),
                        })}
                      </FilterWrapper>
                      <Wrapper
                        disabled={!checkResetSelectEnabled(filter)}
                        className="d-flex"
                      >
                        <div className="reset-button">
                          <span
                            onClick={() => {
                              checkResetSelectEnabled(filter) &&
                              resetSelected(filter.key);
                            }}
                          >
                            Reset { spliceText(filter.name, 20) }
                          </span>
                        </div>
                      </Wrapper>
                    </CustomTabPane>
                  )
              )}
            </Tabs>
          ) : (
            <EmptyStateWrapper><EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} /></EmptyStateWrapper>
          )}
        </TabsWrapper>
      </RenderFilterWrapper>
      <Footer>
        <PrimaryStyledButton
          onClick={showConversations}
          type="primary"
          className="add-more-button"
          style={{ marginRight: "4px" }}
          disabled={false}
        >
          Apply
        </PrimaryStyledButton>
        <ResetAllButton
          onClick={() => {
            checkResetAllEabled() && resetAll();
          }}
          disabled={checkResetAllEabled() ? false : true}
        >
          Reset All
        </ResetAllButton>
      </Footer>
    </ParentWrapper>
  );
};

export default RenderFilters;
