import { Drawer, Spin } from "antd";
import React, { useState, useEffect } from "react";
import Filters from "./Filters";
import { Card, Seperator } from "./styles";
import { ReactComponent as CheckIcon } from "../../../assets/check_circle.svg";
import { ReactComponent as CancelIcon } from "../../../assets/cancel.svg";
import { colossusFetcher } from "../../colossusFetcher";
import moment from "moment";

const WorkflowHistory = ({ setShowDrawer, showDrawer }) => {
  const [workflowHistory, setWorkflowHistory] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let queryParams = "?";
    Object.keys(filters).map((key) => {
      if (filters[key]) queryParams += `${key}=${filters[key]}&`;
    });
    queryParams = queryParams.slice(0, -1);
    setLoading(true);
    colossusFetcher("/automation/list/execution-history/" + queryParams)
      .then((data) => {
        setWorkflowHistory(data.results);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [filters]);

  return (
    <Drawer
      width="640px"
      title="Execution History"
      placement="right"
      onClose={() => setShowDrawer(false)}
      visible={showDrawer}
      bodyStyle={{ padding: 0 }}
      headerStyle={{ borderBottom: "none", paddingBottom: "0" }}
    >
      <Filters setFilters={setFilters} filters={filters} />
      <Seperator />
      {loading ? (
        <div
          style={{
            height: "400px",
            display: "grid",
            placeContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : workflowHistory.length ? (
        workflowHistory.map((item) => (
          <Card key={item.id}>
            <div className="icon">
              {item.is_success ? <CheckIcon /> : <CancelIcon />}
            </div>

            <div>
              <h3>{item.workflow.title}</h3>
              <p className="d-flex">
                <span>
                  {moment(item.created).format("MMMM Do YYYY, h:mm a")}
                </span>{" "}
                <li>{item.workflow.trigger_type}</li>
              </p>

              {!item.is_success && <p className="error-log">{item.log}</p>}
            </div>
          </Card>
        ))
      ) : (
        <p style={{ margin: "1rem" }}>No workflow executed</p>
      )}
    </Drawer>
  );
};

export default WorkflowHistory;
