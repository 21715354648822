import { useState, useRef } from "react";

export const usePagination2 = (api) => {
  const pageRef = useRef(1);
  const loadingRef = useRef(false);
  const [page, setPage] = useState(1);
  const [loading,setLoading] = useState(false);

  const startPagination = (nodeId) => {
    let node = document.getElementById(nodeId)
    setLoadingFn(true);
    api(1,() =>setLoadingFn(false));
    node.addEventListener("scroll", trackScrolling);
  }
  const setLoadingFn = (val) => {
    setLoading(val);
    loadingRef.current = val;
  }
  const setPageFn = (val) => {
    setPage(val);
    pageRef.current = val;
  }
  const trackScrolling= (ele) => {
    let node = ele.target
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    };
    if (node && isBottom(node) && !loadingRef.current) {
      setLoadingFn(true);
      api(pageRef.current+1,()=>setLoadingFn(false));
      setPageFn(pageRef.current + 1);
    }
  }

  const stopPagination = (nodeId) => {
    let node = document.getElementById(nodeId)
    if(node) {
      node.removeEventListener("scroll", trackScrolling);
    }
  }
  return {page,stopPagination,startPagination,loading,setLoading : setLoadingFn}
}
