import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { InputAdornment, Slider, TextField, Tooltip } from "@material-ui/core";
import { useReq } from "../../custom-hooks/useDispatchReq";
import { getApiFilters } from "../../utils/utilities";
import { SliderFilterWrapper, InputWrapper, InputNumberWrapper } from '../wrappers';

const ValueLabelComponent = (props) => {
  const { children, value } = props;
  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const SliderFilter = ({
  apiFunction,
  value,
  onChange,
  sliderStyle,
  textFieldStyle,
  showTextField,
}) => {
  const debouncedOnChange = useCallback(onChange, []);

  const globalFilters = useSelector((state) => state.dashboard.globalFilters);
  const [callApi, cancelRequests] = useReq();

  const [maximum, setMax] = useState(0);
  const [minimum, setMin] = useState(0);
  const [unit, setUnit] = useState("");
  const [step, setStep]= useState(1);
  const [input1, setInput1] = useState(value && value.length ? Number(value[0]) : minimum);
  const [input2, setInput2] = useState(value && value.length ? Number(value[1]) : maximum);
  const [disabled, setDisabled] = useState(true);
  const handleSliderChange = (event, newValue) => {
    if (disabled) {
      return;
    }
    setInput1(newValue[0]);
    setInput2(newValue[1]);
    debouncedOnChange(newValue);
  };

  const checkDefinedValue = (val) => {
    return val !== null && val !== undefined && val !== "";
  }

  const handleInputChange = (val, type) => {
    if (type === "input1") {
      if(!checkDefinedValue(val)) {
        setInput1("");
        return;
      }
      val = Math.floor(val);
      if(val > maximum) {
        val = input1;
      }
      else if(val < minimum) {
        val = input1;
      }
      setInput1(val?.toString());
      !disabled && debouncedOnChange([val, input2]);
    } 
    else if (type === "input2") {
      if(!checkDefinedValue(val)) {
        setInput2("");
        return;
      }
      val = Math.ceil(val);
      if(val > maximum) {
        val = input2;
      }
      else if(val < minimum) {
        val = input2;
      }
      setInput2(val);
      !disabled && debouncedOnChange([input1, val]);
    }
  };

  const onFocusOut = () => {
    let val1 = input1;
    let val2 = input2;
    if(val1 === "") {
      val1 = minimum;
    }
    else if(val2 === "") {
      val2 = maximum;
    }

    if(val1 > val2) {
      val1 = minimum;
    }
    !disabled && debouncedOnChange([val1, val2]);
  }

  useEffect(() => {
    setDisabled(true);
    const filters = getApiFilters({ date: globalFilters?.date });
    callApi(
      apiFunction,
      (res) => {
        setDisabled(false);
        setMax(res.upperBound);
        setMin(res.lowerBound);
        setUnit(res.unit);
        if(res.upperBound === 0 && res.lowerBound === 0) {
          setDisabled(true);
        }
      },
      (err) => {
        setMax(0);
        setMin(0);
        setUnit("");
        setDisabled(true);
      },
      {
        filters,
      },
      () => {}
    );
    return () => cancelRequests();
  }, [globalFilters?.date]);

  useEffect(() => {
    if(value && value.length) {
      setInput1(Number(value[0]));
      setInput2(Number(value[1]));
    }
    else {
      setInput1(Number(minimum));
      setInput2(Number(maximum));
    }
  }, [value, minimum, maximum]);
  return (
    <SliderFilterWrapper>
      <Slider
        style={sliderStyle || { color: "#6099D9" }}
        disabled={disabled}
        step={step}
        value={[Number(input1), Number(input2)]}
        max={maximum}
        min={minimum}
        valueLabelDisplay="auto"
        ValueLabelComponent={ValueLabelComponent}
        onChange={(event, newValue) => {
          handleSliderChange(event, newValue);
        }}
      />
      {
        showTextField && <InputWrapper className="d-flex">
          <InputNumberWrapper>
            <TextField
              type="number"
              InputProps={{
                inputProps: {
                  max: maximum,
                  min: minimum,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }}
              disabled={disabled}
              onBlur = {onFocusOut}
              value={input1}
              style={textFieldStyle || { width: "100px" }}
              onChange={(e) => {
                e.persist();
                handleInputChange(e.target.value, "input1");
              }}
            />
          </InputNumberWrapper>
          <div className="text d-flex">To</div>
          <InputNumberWrapper>
            <TextField
              type="number"
              InputProps={{
                inputProps: {
                  max: maximum,
                  min: minimum,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }}
              disabled={disabled}
              onBlur = {onFocusOut}
              value={input2}
              style={textFieldStyle || { width: "100px" }}
              onChange={(e) => {
                e.persist();
                handleInputChange(e.target.value, "input2");
              }}
            />
          </InputNumberWrapper>
        </InputWrapper>
      }
    </SliderFilterWrapper>
  );
};

export default SliderFilter;
