import React, { useState, useEffect } from "react"
import {
    FlexWrapper,
    StyledTimeStamp,
    TimeLineItemWrapper,
    ItemIconWrapper,
    ItemContent,
    ItemPrimaryRow,
    ItemSecondaryRow,
    StyledTitle,
    StyledAnchor,
    PopupDescriptionWrapper,
    ModalWrapper,
    ActionsWrapper,
    CustomCardWrapper
} from './StyledComponents'
import { CopyOutlined } from '@ant-design/icons'
import { Modal, Button } from 'antd';
import ReadMoreText from '../components/common/ReadMoreText'
import Feedback from './Feedback'
import { TIMELINE_TYPES } from "./Constants";
import { createFeedback, updateFeedback } from './api/api';
import FeedbackReasons from "./FeedbackReasons";
import SalesforceRecentCases from "./SalesforceRecentCases";
import { unescape } from "he";
import { getIcon } from "./Icons";
import FollowIcon from "./FollowIcon";
import { secondsToTime, addAnalyticsEvent } from '../utils'
import StyledCheckbox from './styledCheckbox';
import moment from "moment";

const getFilteredDescription = (description) => {
    return description?.replace(/\n/g, '<br>')?.replace(/\*/g,'')?.replace(/#/g, '');
}

const CardTimeStamp = (props) => {
    const { category, timestamp, created, channel, id, is_checkmarked } = props;
    let text = timestamp !== null && timestamp !== undefined ? secondsToTime(timestamp) : moment(created).format("h:mm:ss");
    if(channel && channel === "email") {
        text = moment(created).format("MMM DD h:mm a")
    }
    const createFeedbackAPI = (value) => {
        let data = {
            is_checkmarked: value,
            reason: null,
            assist_card: id,
        };   
        createFeedback(data).then((res) => {
            //console.log('res', res);     
        }).catch(err => {
            //message.error("Please try again.")
        }); 
    }
    return (
        <StyledTimeStamp category={category}>
            <span>{text}</span>
            <FollowIcon followed={props.followed} category={category} />
            <StyledCheckbox category = {category} is_checkmarked = {is_checkmarked} apiExecutionFunction = {createFeedbackAPI}/>
        </StyledTimeStamp>
    )
}



export const PageLink = ({link, title, onClick}) => {
    return (
        <StyledAnchor className="styled-anchor" onClick={() => {
          onClick && onClick()
          window.open(link,'_blank')
        }}>
            {title}
        </StyledAnchor>
    )
}

const ItemIcon = ({ category, link, event_name }) => {
    return (
        <ItemIconWrapper>
            {getIcon(category, link, event_name)}
        </ItemIconWrapper>
    )    
}

const TimelineItemPopup = (props) => {
    const handleCopy = () => {
        addAnalyticsEvent('Live Conversation', { conversationId: props.conversationId, type: "COPY_CARD", category: props.category, conversationStatus: props.conversation_status,title : props.title });
        const el = document.createElement('textarea');
        // remove html tags
        el.value = unescape(props.filteredDescription.replace(/<[a-zA-Z]+(>|.*?[^?]>)/g, "").replace(/((<\/)\w+(>))/g, ""));
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return (
        <Modal
          visible
          header={null}
          closable={false}
          title={null}
          footer={null}
        >
            <ModalWrapper>
                <TimeLineItemWrapper category={props.category}>
                    <CardDetails {...props}>               
                        <PopupDescriptionWrapper searchOnly={props.searchOnly} className="description" dangerouslySetInnerHTML={{__html: props.filteredDescription}} />
                    </CardDetails>
                </TimeLineItemWrapper>
                <ActionsWrapper>
                    <Button key="back" onClick={handleCopy}><CopyOutlined />Copy Text</Button>
                    <Button key="submit" type="primary" onClick={props.onOk}>Done</Button>
                </ActionsWrapper>
            </ModalWrapper>
        </Modal>
    )
}

const CardDetails = (props) => {
    const { 
        user_feedback, 
        id, 
        isDebug, 
        category, 
        title, 
        text, 
        link, 
        created, 
        timestamp, 
        followed, 
        updateUserFeedback, 
        searchOnly, 
        event_name, 
        isAgent,
        conversationId,
        conversation_status,
        channel,
        moment_type,
    } = props;
    const [ currentFeedback, setFeedback ] = useState( user_feedback ? user_feedback.feedback : null);
    const [ showFeedbackReason, toggleShowFeedBackReason ] = useState(false);
    
    const handleFeedback = (feedback, reason) => {
          addAnalyticsEvent('Live Conversation', { 
            conversationId: conversationId, 
            conversationStatus: conversation_status,
            type: "USER_FEEDBACK", 
            feedback: feedback, 
            category: category,
            reason: reason,
            event_name: event_name,
            title: title,
        });
        let data = {
            feedback,
            reason,
            assist_card: id,
        };
        const feedbackUserData = {
            id: id,            
            feedback: feedback,
            reason: reason,
            comment: currentFeedback?.comment || null,
            is_flagged:  currentFeedback?.is_flagged || false,
            is_highlighted: currentFeedback?.is_highlighted || false,            
        };
        if(currentFeedback?.id) {
            if(currentFeedback.feedback === data.feedback 
                && (data.feedback === "UP" || (data.feedback === "DN" && currentFeedback.reason === data.reason))) {
                feedbackUserData.feedback = "";    
                feedbackUserData.reason = "";
                data.feedback = null;
                data.reason = null;
            }
        }
        if(feedbackUserData.feedback === "UP") {
            feedbackUserData.reason = "";
            data.reason = null;
        }        
        updateUserFeedback(feedbackUserData, id);
        if (currentFeedback?.id) {                  
            updateFeedback(data, currentFeedback.id).then((res) => {
                updateUserFeedback(res.data, id);
            });            
        } else {
            createFeedback(data).then((res) => {
                updateUserFeedback(res.data, id);        
            });            
        } 
    };

    const captureClick = () => {
        const type = title.includes("Verify customer identity") ? "RETOOL_LINK_OPEN" : "CLICK_LINK";
        addAnalyticsEvent('Live Conversation', { 
            conversationId: conversationId, 
            conversationStatus: conversation_status,
            type: type, 
            title: title,
            category: category,
        });
        window.open(link,'_blank')
        return true;
    }


    useEffect(() => {
        setFeedback(user_feedback ? user_feedback : null);
    }, [user_feedback]);
    const cardTitle = event_name === "SF_ACCOUNT_LIMIT_ALERT" ? "Customer had been zeroed" : title;
    return (
        <React.Fragment>      
            <FlexWrapper>
                <ItemIcon category={category} link={link} event_name={moment_type} />
                <ItemContent>
                    <ItemPrimaryRow>
                        {link
                        ? <PageLink onClick={captureClick} title={cardTitle}/>
                        : <StyledTitle category={category}>{cardTitle}</StyledTitle>
                        }
                        {!searchOnly && <CardTimeStamp 
                            id={id}
                            category={category}
                            created={created}
                            followed={followed}       
                            timestamp={timestamp}             
                            channel={channel}  
                            is_checkmarked={user_feedback?.is_checkmarked}      
                        />}                        
                    </ItemPrimaryRow>
                    <ItemSecondaryRow category={category} searchOnly={searchOnly}>
                        {!!event_name ? (<CustomCardWrapper> 
                            <SalesforceRecentCases
                                title={title}
                                category={category}
                                eventName={event_name} 
                                isAgent={isAgent} 
                                cardData={JSON.parse(props.description)} 
                                {...JSON.parse(props.description)} 
                                conversation_status={conversation_status}/>
                        </CustomCardWrapper>)
                        : props.children }
                        {!searchOnly && <Feedback
                            user_feedback={user_feedback}
                            showFeedbackReason={showFeedbackReason}
                            showReasons={() => toggleShowFeedBackReason(true)}
                            hideReasons={() => toggleShowFeedBackReason(false)}
                            updateUserFeedback={updateUserFeedback}     
                            handleFeedback={handleFeedback}     
                            isFlag={category === TIMELINE_TYPES.FLAG}                  
                        />}
                    </ItemSecondaryRow>                                        
                </ItemContent>
            </FlexWrapper>     
            {isDebug && <p className="description">{text}</p>}
            <FeedbackReasons
                isFlag={category === TIMELINE_TYPES.FLAG}
                reason={currentFeedback?.reason}
                showFeedbackReason={showFeedbackReason}                
                handleReasonSelection={(reason) => {
                    handleFeedback("DN", reason);
                    toggleShowFeedBackReason(false);
                }}
            />
        </React.Fragment>
    )
}

const TimelineItem = (props) => {
    const { category, title, description, link, isSelected, conversationId, fade, highlightItem, conversation_status } = props;
    const [ isPopup, setPopup ] = useState(false)    
    const filteredDescription = getFilteredDescription(description, category, link);

    return (
        <TimeLineItemWrapper category={category} isSelected={isSelected} fade={fade} clickable={highlightItem} onClick={() => highlightItem && highlightItem(props.moment_id)} className="timeline-item">
            {isPopup && <TimelineItemPopup filteredDescription={filteredDescription} {...props} onOk={() => {setPopup(false)}}/>}            
            <CardDetails 
                {...props}
                filteredDescription={filteredDescription}
                updateUserFeedback={props.updateUserFeedback}                
            >  
                <ReadMoreText 
                    category={category} 
                    text={filteredDescription} 
                    className="description" 
                    onMore={(open) => {
                        addAnalyticsEvent('Live Conversation', { 
                          conversationId: conversationId, 
                          type: "CLICK_MORE", 
                          title: title,
                          category: category,
                          conversationStatus: conversation_status,
                        });
                        setPopup(open);
                    }} 
                />                          
            </CardDetails>                
        </TimeLineItemWrapper>
    )
}

export default TimelineItem;
