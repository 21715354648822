import React from "react";
import useSWR from "swr";

const GenericTab = ({ onDragStart, apiEndpoint, nodeType, renderIcon }) => {
  const { data, error } = useSWR(apiEndpoint);

  if (!data) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {data.map((item) => {
        let data = { type: nodeType, ...item };
        data = JSON.stringify(data);
        return (
          <div
            className={`dndnode ${nodeType}`}
            onDragStart={(event) => onDragStart(event, data)}
            draggable
            key={item.functionName || item.condition}
          >
            {renderIcon()}
            {item.display_name}
          </div>
        );
      })}
    </>
  );
};

export default GenericTab;
