import React from "react";
import { Breadcrumb } from 'antd';
import styled from "@emotion/styled/macro";
import { ReactComponent as SelectIcon } from "./../../assets/select-icon.svg";
import { Link } from "react-router-dom";

const StyledBreadCrumb = styled(Breadcrumb)`
  font-size: 12px;
  line-height: 14px;
  .ant-breadcrumb-separator {
    margin: 0 4px;
  }
  .navigation-item {
    color: #BDBDBD;
  }
`;

const StyledSelectIcon = styled(SelectIcon)`
  transform: rotate(-90deg);
  width: 8px;
  height: 8px;
  top: 1px;
  position: relative;
`;

const BreadCrumbComponent = (props) => {
  return (
    <StyledBreadCrumb separator={<StyledSelectIcon />}>
      {props.items.map((item, index) => {
        if(!item.link) {
          return (
            <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item> 
          );
        }
        else {
          return (
            <Breadcrumb.Item key={index} className="navigation-item"><Link to={item.link}>{item.title}</Link></Breadcrumb.Item>
          );
        }
      })}      
    </StyledBreadCrumb>
  );
}

export default BreadCrumbComponent;