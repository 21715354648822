import React from "react";
import { message } from "antd";
import { connect } from "react-redux";
import { checkTrainCategoryModelStatus, trainCategoryModel } from '../../api/api';
import { PrimaryButton } from "../../../components/common/styled-components";
import {TrainButtonWrapper} from './wrappers';
// import moment from "moment";

class TrainModel extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.timeCounter = -1;
  }
  handleModalTraining = () => {
    //if the number of custom categories are smaller than 3 then show this warning.
    if(this.props.tableData.filter(item=>item.category_type==="custom").length < 3){
      return message.warn("Atleast 3 Custom Categories should be added to enable Training.");
    } 
    trainCategoryModel().then(res => {
      this.setState({ loading: true }, this.checkTrainCategoryModelStatus);
    }).catch(err=>message.error(err.message));
  }
  checkTrainCategoryModelStatus = (flag) => {
    checkTrainCategoryModelStatus().then(res => {
      // if (res.last_trained_at && document.getElementById("training-timestamp")) {
      //   document.getElementById("training-timestamp").innerHTML = moment(res.last_trained_at).format("MM/DD/YY h:mma");
      // }
      if(res.status === "Training") {
        this.setState({ loading: true }, () => {
          this.timeCounter = setTimeout(() => {
            this.checkTrainCategoryModelStatus();
          }, 8000);
        });
      }
      else {
        this.setState({ loading: false });
        !flag && this.props.handleSetState("Training completed successfully.");
      }
    }, () => (err) => {
      message.error("Failed to start model training, please retry");
    })
  }
  componentDidMount() {
    this.checkTrainCategoryModelStatus("hide");
  }
  componentDidUpdate(prevProps) {
    if(prevProps.selectedWorkspace !== this.props.selectedWorkspace) {
      this.setState({ loading: true }, () => {
        clearTimeout(this.timeCounter);
        this.checkTrainCategoryModelStatus("hide");
      });
    }
  }
  render() {
    const buttonName = this.state.loading ? `Training...` : 'Retrain';
    return (
      <TrainButtonWrapper
        loading={this.state.loading}
        onClick={this.handleModalTraining}
      >{buttonName}</TrainButtonWrapper>
    )
  }
}

const mapStateToProps = state => ({ selectedWorkspace: state.app_user.selectedWorkspace });
export default connect(mapStateToProps)(TrainModel);