import React, { useReducer, useEffect } from 'react'
import { Tabs } from 'antd';
import { Category } from "./section";
import styled from '@emotion/styled';

const { TabPane } = Tabs;

const RubricWrapper = styled.div`
    .ant-tabs-content {
        background-color: white;
    }
    .ant-input {
        padding: 4px 10px;
    }
` 

const categoriesReducer = (categories, action) => {
    if(action.type === "UPDATE") {
        const { i, key, value} = action.payload;
        let _categories = [...categories]

        _categories.splice(i, 1, {
            ...categories[i],
            [key]: value
        })

        action.onChange({
            key: "categories",
            value: _categories
        })
        return _categories;
    }

    if(action.type === "SET_CATEGORIES") {
        const _categories = [...action.payload];
        return _categories;
    }
}

export const Rubric = (props) => {
    const [ categories, dispatch ] = useReducer(categoriesReducer, [...props.categories])
    const { onChange } = props;

    useEffect(() => {
        dispatch({
            type: "SET_CATEGORIES",
            payload: [...props.categories],
            onChange
        })
    }, [props.categories])

    return (
        <RubricWrapper>
            <Tabs defaultActiveKey="0" onChange={() => {}}>
                {   
                    categories.map((category, i) => (
                        <TabPane tab={category.title} key={i}>
                            <Category
                            {...props}
                            onChange={
                                (e) => {
                                    dispatch({
                                        type: "UPDATE",
                                        payload: {i, key: e.key, value: e.value},
                                        onChange
                                    })
                                }
                            }
                            data={category}
                            />
                        </TabPane>
                    ))
                }
            </Tabs>
        </RubricWrapper>
    )
}