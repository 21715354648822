import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../assets/close-round.svg";

const TagWrapper = styled.div`
    display: inline-block;
    padding: 4px 10px;
    border-radius: 100px;
    background: ${props => props.isSelected ? "#F2F2F2" : "transparent"};
    cursor: pointer;
    color: ${props => props.isSelected ? "#828282" : "#E0E0E0"};
    font-size: 11px;
    line-height: 13px;
    &:hover {
        background: #F2F2F2;
    }
    svg {
        width: 12px;
        height: 12px;
        top: 2px;
        position: relative;
        transform: ${props => props.isSelected ? "rotate(0deg)" : "rotate(45deg)"};
        margin: ${props => props.isSelected ? "0 0 0 4px" : "0 4px 0 0"};
    }
`;

const Tag = ({tagName, isSelected, onSelect}) => {
    if(isSelected) {
        return (
            <TagWrapper onClick={() => onSelect(false)} isSelected>{tagName} <CloseIcon /></TagWrapper>
        );
    }
    else {
        return (
            <TagWrapper onClick={() => onSelect(true)}><CloseIcon />{tagName}</TagWrapper>
        );
    }
}

export default Tag;