import { getSchemaName } from "../../api/api";
import { queryOptions } from "../dynamic-query-builder";

const { groupByOptions } = queryOptions;

export const applyGroupBy = (
  query,
  groupBy,
  _filters,
  stackBy,
  addDimensions
) => {
  // Fix the code below
  let dimensions = groupBy ? [groupBy] : [];
  switch (groupBy) {
    case groupByOptions.ORGANIZATION: {
      dimensions = ["Organization.name"];
      break;
    }
    case groupByOptions.RESOLVED: {
      dimensions = ["LevelAsrAsrlogtocasemapping.isResolved"];
      break;
    }
    case groupByOptions.IS_FLAGGED: {
      dimensions = ["Metrics.isFlagged"];
      break;
    }
    case groupByOptions.CHANNEL: {
      dimensions = ["LevelAsrAsrlog.channel"];
      break;
    }
    case groupByOptions.OUTCOME: {
      dimensions = ["CFAnswer.outcome"];
      break;
    }
    case groupByOptions.AGENT: {
      dimensions = ["AccountsUser.id"];
      break;
    }
    case groupByOptions.QA_CATEGORY: {
      dimensions = ["CFDAnswer.category"];
      break;
    }
    case groupByOptions.SECTION: {
      dimensions = ["CFDAnswer.section"];
      break;
    }
    case groupByOptions.CATEGORY: {
      dimensions = ["LevelNlpIntenttopics.topics"];
      break;
    }
    case groupByOptions.CONVERSATION_TAG_ID: {
      dimensions = ["MomentConversationTag.rule_id"];
      break;
    }
    case groupByOptions.METRIC_RULE: {
      dimensions = ["MomentMetricTag.title"];
      break;
    }
    case groupByOptions.NEGATIVE_SENTIMENT: {
      dimensions = ["MomentNegativeSentiment.title"];
      break;
    }
    case groupByOptions.TAGS_TITLE: {
      dimensions = ["MFTags.title"];
      break;
    }
    case groupByOptions.TOPICS_TOPICS: {
      dimensions = ["MFTopics.topics"];
      break;
    }
    case groupByOptions.QUESTION: {
      dimensions = ["CFDAnswer.question"];
      break;
    }
    case groupByOptions.SELECTED_OPTION: {
      dimensions = ["CFDAnswer.selectedOption"];
      break;
    }
    case groupByOptions.NONE: {
      dimensions = [];
      break;
    }
    default: {
      dimensions = groupBy ? [groupBy] : [];
      break;
    }
  }

  let filters = [..._filters];

  dimensions.forEach((dimension) => {
      filters = [
        ...filters,
        {
          member: `${dimension}`,
          operator: "set",
        },
      ];
  });

  switch (stackBy) {
    case groupByOptions.RESOLVED: {
      dimensions = [...dimensions, "LevelAsrAsrlogtocasemapping.isResolved"];
      break;
    }
    case groupByOptions.IS_FLAGGED: {
      dimensions = [...dimensions, "Metrics.isFlagged"];
      break;
    }
    case groupByOptions.CHANNEL: {
      dimensions = [...dimensions, "LevelAsrAsrlog.channel"];
      break;
    }
    default: {
      break;
    }
  }
  if (addDimensions?.length) {
    if(dimensions?.length)
      dimensions = [...dimensions, ...addDimensions];
    else
      dimensions = [...addDimensions]
  }

  dimensions.forEach((dimension, i) => {
    dimensions[i] = dimension;
  });

  query.dimensions = dimensions;
  query.filters = filters;

  return query;
};

export const applyMultiGroupBy = (
  query,
  groupBy,
  _filters,
  stackBy,
  addDimensions
) => {
  console.log(_filters);
  let filters = [..._filters];
  let dimensions = [];

  groupBy.forEach(dimension => {
    switch (dimension) {
      case groupByOptions.ORGANIZATION: {
        dimensions = [...dimensions, "Organization.name"];
        break;
      }
      case groupByOptions.RESOLVED: {
        dimensions = [...dimensions, "LevelAsrAsrlogtocasemapping.isResolved"];
        break;
      }
      case groupByOptions.IS_FLAGGED: {
        dimensions = [...dimensions, "Metrics.isFlagged"];
        break;
      }
      case groupByOptions.CHANNEL: {
        dimensions = [...dimensions, "LevelAsrAsrlog.channel"];
        break;
      }
      case groupByOptions.OUTCOME: {
        dimensions = [...dimensions, "CFAnswer.outcome"];
        break;
      }
      case groupByOptions.AGENT: {
        dimensions = [...dimensions, "AccountsUser.id"];
        break;
      }
      case groupByOptions.QA_CATEGORY: {
        dimensions = [...dimensions, "CFDAnswer.category"];
        break;
      }
      case groupByOptions.SECTION: {
        dimensions = [...dimensions, "CFDAnswer.section"];
        break;
      }
      case groupByOptions.CATEGORY: {
        dimensions = [...dimensions, "LevelNlpIntenttopics.topics"];
        break;
      }
      case groupByOptions.CONVERSATION_TAG_ID: {
        dimensions = [...dimensions, "MomentConversationTag.rule_id"];
        break;
      }
      case groupByOptions.METRIC_RULE: {
        dimensions = [...dimensions, "MomentMetricTag.title"];
        break;
      }
      case groupByOptions.NEGATIVE_SENTIMENT: {
        dimensions = [...dimensions, "MomentNegativeSentiment.title"];
        break;
      }
      case groupByOptions.TAGS_TITLE: {
        dimensions = [...dimensions, "MFTags.title"];
        break;
      }
      case groupByOptions.TOPICS_TOPICS: {
        dimensions = [...dimensions, "MFTopics.topics"];
        break;
      }
      case groupByOptions.QUESTION: {
        dimensions = [...dimensions, "CFDAnswer.question"];
        break;
      }
      case groupByOptions.SELECTED_OPTION: {
        dimensions = [...dimensions, "CFDAnswer.selectedOption"];
        break;
      }
      case groupByOptions.TEAM: {
        dimensions = [...dimensions, "Team.id"];
        break;
      }
      case groupByOptions.NONE: {
        dimensions = [...dimensions];
        break;
      }
      default: {
        dimensions = [...dimensions, dimension];
        break;
      }
    }

    if(dimension === groupByOptions.AGENT) {
      let roleFilter = filters.filter(el => el.dimension === "AccountsUser.role")[0];

      if(!roleFilter) {
        filters = [
          ...filters,
          {
            dimension: "AccountsUser.role",
            operator: "equals",
            values: ["AGENT"],
          }
        ]
      }
    }
  });

  dimensions.forEach((dimension) => {
    filters = [
      ...filters,
      {
        member: `${dimension}`,
        operator: "set",
      },
    ];
  });

  switch (stackBy) {
    case groupByOptions.RESOLVED: {
      dimensions = [...dimensions, "LevelAsrAsrlogtocasemapping.isResolved"];
      break;
    }
    case groupByOptions.IS_FLAGGED: {
      dimensions = [...dimensions, "Metrics.isFlagged"];
      break;
    }
    case groupByOptions.CHANNEL: {
      dimensions = [...dimensions, "LevelAsrAsrlog.channel"];
      break;
    }
    default: {
      break;
    }
  }

  if (addDimensions?.length) {
    if(dimensions?.length)
      dimensions = [...dimensions, ...addDimensions];
    else
      dimensions = [...addDimensions]
  }

  dimensions.forEach((dimension, i) => {
    dimensions[i] = dimension;
  });

  query.dimensions = dimensions;
  query.filters = filters;


  return query
}
