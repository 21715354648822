import React from "react";
import styled from "@emotion/styled/macro";
import SetupIcon from "../../utils/icon";
import BreadCrumbComponent from "../../../components/common/BreadCrumbComponent";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 8px;
        svg {
            width: 48px;
            height: 48px;
        }
        .name-wrapper {
            display: flex;
            margin-left: 12px;
            flex-direction: column;
            .title {
                padding: 0;
                font-size: 24px;
                line-height: 28px;
                color: #4F4F4F;
                svg {
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;
                    cursor: pointer;
                    position: relative;
                    top: -2px;
                }
            }
            .description {
                font-size: 16px;
                line-height: 19px;
                color: #828282;
                margin-top: 3px;
            }
        }
    }
`;

const SetupHeader = ({ title, icon, info, description, openInfo, illustration, breadCrumbItems}) => {
    return (
        <HeaderWrapper>
            <BreadCrumbComponent items={breadCrumbItems || [{ title: "Home", link: "/" }, { title: "Settings", link: "/setup" }, { title: title }]}/>
            <div className="header-wrapper">
                {icon && <SetupIcon icon={icon} active />}
                <div className="name-wrapper">
                    <div className="title">{title} {illustration ? <InfoIcon onClick={openInfo} /> : null}</div>
                    {description && <div className="description">{description}</div>}
                </div>
            </div>
        </HeaderWrapper>
    )
}

export default SetupHeader;
