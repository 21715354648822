import create from "zustand";
import { devtools } from "zustand/middleware";
import { createAutomationMetaSlice } from "./createAutomationMetaSlice";
import { createDndEventSlice } from "./createDndEventsSlice";
import { createElementSlice } from "./createElementsSlice";
import { createNodeSlice } from "./createNodeSlice";
import { createNodeUpdateSlice } from "./createNodeUpdateSlice";
import { createReactFlowSlice } from "./createReactFlowSlice";
import { createSeedOperationsSlice } from "./createSeedOperationsSlice";

const useStore = create(
  devtools((set, get) => ({
    ...createAutomationMetaSlice(set, get),
    ...createSeedOperationsSlice(set, get),
    ...createReactFlowSlice(set, get),
    ...createDndEventSlice(set, get),
    ...createElementSlice(set, get),
    ...createNodeSlice(set, get),
    ...createNodeUpdateSlice(set, get),
  }))
);

export default useStore;
