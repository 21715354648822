import React, { Component } from 'react';
import styled from "@emotion/styled/macro";
import { StyledInput } from "./../../../components/common/styled-components";
// import { ReactComponent as ProfileIcon } from "../../assets/account.svg";
import { UserStates, Organizations } from "./config";
import { CustomSelect } from "./../commons/select";
import WorkspaceList from '../workspace-management/workspace-list';

const UserProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const BasicDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #BDBDBD;
`;

const NameWrapper = styled.div`
  width: 50%;
  margin: 0 6px;
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }
`;

// const UploadWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   color: #6099D9;
//   font-size: 12px;
//   line-height: 14px;
//   white-space: nowrap;
//   cursor: pointer;
//   position: relative;
//   top: -8px;
//   svg {
//     width: 34px;
//     height: 34px;
//     path {
//       fill: #6099D9;
//     }
//   }
// `;

class UserProfile extends Component {
  updateValues = (field, value) => {
    this.props.updateUserProfile({
      ...this.props.userData,
      [field]: value
    });
  }
  handleChange = (field, event) => {
    this.updateValues(field, event.target.value);
  }
  handleDropdownState = (type, value) => {
    if(type === "is_active") {
      this.updateValues(type, value === "active" ? true : false);
    }
    else {
      this.updateValues(type, value);
    }    
  }
  render() {
    const { userData, roles: Roles, showWorkspace, workspaceList, viewOnly } = this.props;
    const { first_name, last_name, email, phone_number, organization, role, id, is_active, client_name, workspace } = userData; 
    const selectedWorkspaces = workspace ? workspace.map((item) => item.toString()) : [];

    return (
      <UserProfileWrapper>
        <BasicDetailsWrapper>
          {/* <UploadWrapper>
            <ProfileIcon/>
            <div>Add Photo</div>
          </UploadWrapper> */}
          <NameWrapper>
            <StyledInput
              disabled={viewOnly}
              placeholder="First Name"
              onChange={this.handleChange.bind(this, 'first_name')}
              defaultValue={first_name}
              value={first_name}
            />
          </NameWrapper>
          <NameWrapper>
            <StyledInput
              disabled={viewOnly}
              placeholder="Last Name"
              onChange={this.handleChange.bind(this, 'last_name')}  
              defaultValue={last_name}    
              value={last_name}      
            />
          </NameWrapper>
        </BasicDetailsWrapper>
        <StyledInput
          disabled={viewOnly}
          placeholder="Client Name"
          onChange={this.handleChange.bind(this, 'client_name')}       
          defaultValue={client_name}
          value={client_name}     
        />
        <StyledInput
          disabled={viewOnly}
          placeholder="Work Email"
          onChange={this.handleChange.bind(this, 'email')}       
          defaultValue={email}
          value={email}     
        />
        <StyledInput
          disabled={viewOnly}
          placeholder="Work Phone"
          onChange={this.handleChange.bind(this, 'phone_number')}            
          defaultValue={phone_number}
          value={phone_number}
        />
        <CustomSelect
          disabled={viewOnly}
          allowClear={true}
          options={Organizations}
          handleChange={this.handleDropdownState.bind(this, "organization")}              
          placeholder="Organization"
          margin="0 0 16px 0"
          width="100%"
          height="42px"
          value={organization}
        />
        <CustomSelect
          disabled={viewOnly}
          allowClear={true}
          options={Roles}
          handleChange={this.handleDropdownState.bind(this, "role")}              
          placeholder="Role"
          margin="0 0 16px 0"
          width="100%"
          height="42px"
          value={role}
          mode="multiple"
        />
        {
          showWorkspace && (
            <CustomSelect
              disabled={viewOnly}
              options={workspaceList}
              handleChange={this.handleDropdownState.bind(this, "workspace")}              
              placeholder="Workspace"
              margin="0 0 16px 0"
              width="100%"
              height="42px"
              value={selectedWorkspaces}
              mode="multiple"
            />
          )
        }
        {
          id && (<CustomSelect
            disabled={viewOnly}
            options={UserStates}
            handleChange={this.handleDropdownState.bind(this, "is_active")}              
            placeholder="Active"
            margin="0 0 16px 0"
            width="100%"
            height="42px"
            value={is_active ? "active" : "inactive"}
          />)
        }
      </UserProfileWrapper>
    )
  }
}

export default UserProfile;
