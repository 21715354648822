import styled from "@emotion/styled/macro";
import { Spin } from "antd";
import { StyledTable } from "./../components/common/styled-components";

export const ConversationListWrapper = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 42px);
`;

export const ListTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #828282;
  margin-top: 9px;
`;

export const ListHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 23px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: ${props => props.componentWidth || 80}%;
`;

export const ListTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  padding: 16px 24px;
  position: relative;
  background: #fafafa;
`;

export const ListSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ListAggregatedata = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #828282;
  span {
    color: #bdbdbd;
    margin-left: 3px;
  }
`;

export const FilterComponentWrapper = styled.div`
  margin-right: 4px;
  margin-top: 10px;
  &&& input {
    border-color: #bdbdbd;
  }
`;

export const StyledConversationTable = styled(StyledTable)`
  box-shadow: none;
  margin-top: 13px;
`;

export const DescendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #6099d9;
    }
  }
`;

export const AscendingIconWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    path {
      fill: #6099d9;
    }
  }
`;

export const SortIconWrapper = styled.div`
  margin-left: 1px;
  display: flex;
  svg {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
    path {
      fill: #bdbdbd;
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const SortableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 21px;
  cursor: pointer;
  color: ${(props) =>
    props.order === "dsc" || props.order === "asc" ? "#6099D9" : "#BDBDBD"};
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .ant-table-tbody > tr > td {
    font-size: 12.5px;
  }
`;
export const FlexibleColumnMenu = styled.div`
  position: absolute;
  right: 45px;
  width: 0px;
  top: 20px;
  .flexible-column-icon {
    cursor: pointer;
  }
  .flexible-column-blue-icon {
    position: relative;
    cursor: pointer;
  }
`;
export const MenuWrapper = styled.div`
  width: 280px;
  background: white;
  z-index: 10;
  transition: 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));
  border-radius: 4px;
  max-height: 95%;
  justify-content: space-between;
  margin-top: 0.8%;

  .flexible-column-button-wrapper{
    padding: 15px;
    position: relative;
    bottom: 0px;
  }
`;

export const ColumnsWrapper = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 8px;
  display:flex;
  flex-direction:column;
}
`;
export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
  .column-name {
    color: #8b8b8d;
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-switch-checked {
    background: #2d9cdb;
  }
`;

export const ButtonWrapper = styled.span`
  border: 1px solid #0b4b93;
  border-radius: 4px;
  color: black !important;
  padding: 8px 12px;
  width: 140px;
  height: 32px;
  cursor: pointer;
  position: relative;
`;

export const ButtonDisabled = styled(ButtonWrapper)`
  opacity: 0.5;
  padding: 8px 5px;
  cursor: default;
  user-select: none;
`;
export const ConvoIDWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;
`;

export const CaseSummary = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #4f4f4f;
`;
export const CaseSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CaseSummaryID = styled.div``;
export const CaseSummaryTime = styled.div``;

export const CaseSummaryBody = styled.div`
  margin-top: 15px;
`;

export const TranscriptResultsWrapper = styled.div`
    max-width: 65%;
    display: flex;
    flex-direction: column;
    padding-left: 6%;
`;

export const UserMessageWrapper = styled.div`
display: flex;
width: 100%;
align-items: flex-start;
justify-content: flex-start;
margin-top: 18px;
&:first-of-type {
    margin-top: 0;
}
.message-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;   
    width: 100%;     
    .message-meta {
        display: flex;
        align-items: center;
        width: 100%;
        //justify-content: space-between;
        .user-title {
            font-size: 12px;
            line-height: 14px;
            color: #4F4F4F;
            text-transform: capitalize;                
        }
        .message-time {
            font-size: 12px;
            line-height: 14px;
            margin-left: 20px;
            color: #BDBDBD;
        }   
    }
    .message-description {
        font-size: 12px;
        line-height: 150%;
        color: #828282;
        margin-top: 1px;
        em {
            color: #6099D9;
            font-weight: bold;
            font-style: normal;
        }
    }
}
`;

export const ConversationsTableWrapper = styled(StyledConversationTable)`
   .ant-table-column-title {
    color: #bdbdbd;
    white-space: nowrap;
  }

  // .ant-table .ant-table-tbody> tr:hover:not(.ant-table-expanded-row)> td{
  //   background : yellow;
  // }
  
  .ant-table-row {
    height: 64px;
    border: none;
    .ant-table-row-cell-break-word{
      border-bottom: ${props => props.parentRowBorder ? "1px solid #e8e8e8 !important" : "none"} !important;
    }
  }

  .ant-table-expand-icon-th{
    display: table-column;
  }

  .ant-table-expanded-row {
    background: white;
    td {
         border-bottom: 1px solid #e8e8e8 !important;
       }

    td: hover {
          background: #ffffff !important;
       }
  }

  .ant-table-row-expand-icon-cell{
       display: table-column;
  }

`;

export const SpinnerWrapper = styled.div`
  width:100%;
  text-align: center;
  marginTop: 20px;
`;

export const TableSpinner = styled(Spin) `
  position: relative; 
  top: -40%;
`
export const InstaReview = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-size: 11px;
  line-height: 150%;
  color: #4f4f4f;
`;
export const InstaReviewHeader = styled.div`
  font-weight: bold;
`;

export const InstaReviewBody = styled.div`
  margin: 5px 0;
`;

export const InstaReviewMetrics = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;
  .metrics-heading {
    display: flex;
    flex-direction: column;
    max-width: 190px;
  }
  .metrics-value {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-left: 10px;
  }
`;
