import { Button } from "antd";
import React,{useState} from "react";
import { SWRConfig } from "swr";
import { colossusFetcher } from "../../colossusFetcher";
import CreateAutomationModal from "../../components/CreateAutomationModal";
import WorkflowHistory from "../../components/WorkflowHistory";
import AutomationTable from "./AutomationTable";

const ListAutomation = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDrawer,setShowDrawer] = useState(false);

  return (
    <div>  
      <SWRConfig
        value={{
          fetcher: colossusFetcher,
        }}
      >
        <div className="d-flex justify-content-end mb-3">
          <Button className="me-2" onClick={()=>setShowDrawer(true)}>
            Execution History
          </Button>
          {showDrawer && <WorkflowHistory showDrawer={showDrawer} setShowDrawer={setShowDrawer}/>}
          <Button type="primary" onClick={() => setShowModal(true)}>
            New Workflow
          </Button>
          <CreateAutomationModal
            visible={showModal}
            onCancel={() => setShowModal(false)}
          />
        </div>
        <AutomationTable />
      </SWRConfig>
    </div>
  );
};

export default ListAutomation;
