
export const TIMELINE_TYPES = {
    KEYWORD: "KEYWORD",
    HINT: "HINT",
    WARNING: "WARNING",
    FLAG: "FLAG",
    KNOWLEDGE: "KNOWLEDGE",
    FAQ: "FAQ",
    NEGATIVE_SENTIMENT: "NEGATIVE_SENTIMENT",
    COMPLIANCE: "COMPLIANCE",
    SF_ALERT: "SF_ALERT",
    UPSELL: "UPSELL",
    NONE: "NONE",
};


export const MOMENT_TYPES = {
    KNOWLEDGE: "KNOWLEDGE",
    ETIQUETTE: "ETIQUETTE",
    COMPLIANCE: "COMPLIANCE",
    AUTOSCORING: "AUTOSCORING"
};
