import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as QueryString from "query-string";
import { HeapAnalyticsEvent } from "../components/common/HeapAnalyticsEvent";
import { useSelector } from "../store";
import { addAnalyticsEvent } from "../utils";
import { handleCubeFilterUpdate } from "../utils/utilities";
import AdditionalFilters from "./AdditionalFilters";
import { storeFilters } from "../conversation-list/api";
import { FiltersWrapper } from "../conversation-list/wrappers";
import { useHistory } from "react-router";
import { useFilterConfig } from "./AdditionalFilters/hooks";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import appConfigs from "../config";

const EnhancedFilterComponent = ({ configuration }) => {
  const dispatch = useDispatch();
  
  const {
    defaultFilterKeys,
    additionalFilterKeys,
    componentWidth
  } = configuration;

  const filters = useSelector((state) => state.dashboard.globalFilters);
  const filtersSegmentList = useSelector((state) => state.dashboard.filterSegments);
  const customFilters = useSelector((state) => state.dashboard.customFilters);
  const selectedWorkspace = useSelector((state) => state.app_user.selectedWorkspace);
  const { filterList }= useFilterConfig(filters, handleChange, filtersSegmentList, configuration);

  const history = useHistory();
  const [_additionalFilterKeys, setAdditionalFilterKeys] = useState(additionalFilterKeys);

  function handleChange(key, value, label, idValues, expectationtype)  {
    if (value !== undefined && value.length !== 0) {
      addAnalyticsEvent(HeapAnalyticsEvent.Detect_Filters, { filterName: key }); //used to handle heap analytics
    }
    //remove idVlues once filter by id is supported on BE
    const filterValue = label ? label : value;
    const globalQAFilters = handleCubeFilterUpdate(
      key,
      filterValue,
      idValues,
      expectationtype
    );
    //updateFilters(key, filterValue)
    dispatch(storeFilters(globalQAFilters));
  };

  const renderFiltersWrapper = () => {
    return filterList.map((filter) => {
      if (defaultFilterKeys?.includes(filter.key)) {
        filter.isIncluded = true;
        return (
          filter.show(filter.isIncluded) && filter.component(filter)
        );
      }
    });
  };

  useEffect(() => {
    let queryParams = {
      ...filters,
      ...(selectedWorkspace && { workspace: selectedWorkspace }),
    };
    if (!filters) {
      return;
    }
    if (queryParams.date && Array.isArray(queryParams.date)) {
      queryParams.date = JSON.stringify(queryParams.date);
    }

    if (queryParams.evaluation_date && Array.isArray(queryParams.evaluation_date)) {
      queryParams.evaluation_date = JSON.stringify(queryParams.evaluation_date);
    }
    delete queryParams.dateRangeValues;
    delete queryParams.dateRange;
    
    history.push({
      search: QueryString.stringify(queryParams),
    });
  }, [filters, selectedWorkspace]);

  useEffect(() => {
    const updatedAdditionalFilterKeys=[...additionalFilterKeys];
      if(customFilters?.length) {
        customFilters.forEach((item) => {
          updatedAdditionalFilterKeys.push(item.field_name);
        })
        return setAdditionalFilterKeys(updatedAdditionalFilterKeys)
      }
  }, [customFilters]);

  return (
    <>
      <FiltersWrapper componentWidth={componentWidth || 80}>
        {renderFiltersWrapper()}
        <AdditionalFilters
          additionalFilters={[
            ...filterList.filter(
              (filter) =>
                _additionalFilterKeys?.includes(filter.key)
            ),
          ]}
          configuration={configuration}
        />
      </FiltersWrapper>
    </>
  );
};

const FiltersComponent = (props) => <ErrorBoundary FallbackComponent={ErrorFallback}><EnhancedFilterComponent {...props}/></ErrorBoundary>

export default FiltersComponent;
