import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportIcon from '@material-ui/icons/Report';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import SmsIcon from '@material-ui/icons/Sms';
import { ReactComponent as ChatIcon } from "./../assets/chat-icon2.svg";
import { ReactComponent as CallIcon } from "./../assets/call-icon2.svg";
import { ReactComponent as EmailIcon } from "./../assets/email-icon.svg";


export const userHistory =(filters)=>{ 
     return [
    {
         title: "Home", 
         link: "/"
    },
    {
         //title: !filters.query? "History" : "Search Results"
         title: "History"
    }
 ];}

 export const userSearchHistory = [
     {
          title: "Home", 
          link: "/"
     },
     {
          title: "Interaction History",
          link: "/conversations"
     },
     {
          title: "Search Results"
     }
];

//statusType is in sync with BE
export const EvaluationStatus = {
     PENDING_EVALUATION : {
          label: "Evaluation Pending", 
          statusType: "PENDING_EVALUATION",
          icon: <AssignmentReturnedIcon style = {{color: "#6099D9"}}/>
     },
     EVALUATED : {
          label: "Evaluation Done",
          statusType: "EVALUATED",
          icon: <WatchLaterIcon style = {{color: "#E89244"}}/>
     },
     DISPUTED: {
          label: "Evaluation Disputed",
          statusType: "DISPUTED",
          icon: <ReportIcon style = {{color: "#DB554D"}}/>
     },
     ACCEPTED: {
          label: "Evaluation Accepted", 
          statusType: "ACCEPTED",
          icon: <CheckCircleIcon style = {{color: "#27AE60"}}/>
     }
 }

 export const fetchChannelConfig = {
  "1": {
    icon: (styles = {}) => <CallIcon style = {{ ...styles }} className="int-history-call-icon" />,
    value: "call"
  },
  "2": {
    icon: (styles = {}) => <ChatIcon style = {{ ...styles }} className = "int-history-chat-icon" />,
    value: "chat"
  },
  "3": {
    icon: (styles = {}) => <SmsIcon style = {{ color: "rgb(189, 189, 189)", ...styles }} />,
    value: "sms"
  },
  "4": {
    icon: (styles = {}) => <EmailIcon style = {{ ...styles }} className="int-history-email-icon" />,
    value: "email"
  },
 }
