import styled from "@emotion/styled/macro";
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-modal-content {
        height: 250px;
        width: 450px;
        .ant-modal-body {
            width: 100%;
            height: 80%;
        }
    }
`;

export const BodyWrapper = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    padding: 20px;
`;

export const HeadingWrapper = styled.div`
    margin-bottom: 15px;
    padding-left: 25px;
    font-size: 13px;
    font-weight: 700;
`;

export const FieldsWrapper = styled.div`   
    width: 100%;
    padding: 0px 25px 0px 25px;
`;

export const ButtonWrapper = styled.div`
    color: white;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 8px;
    //background: #32de84;
    margin-top: 15px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 13px;
    background-color: #63d471;
    background-image: linear-gradient(180deg,#63d471 20%,#233329 190%);

    &:hover {
        transition: 0.4s ease;
        opacity: 0.8;
    }
`;
