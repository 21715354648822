import React from 'react';
import { Tooltip } from "antd";

export const handleClickOutside = (event, ref, cb) => {
  if (ref.current && !ref.current.contains(event.target)) {
    if(cb) {
      cb();
    }
  }
};

export const spliceText = (text, spliceLimit = 20, showTooltip = false) => {
  if (text?.length > spliceLimit) {
    return showTooltip ?  <Tooltip title = {text}>{ text.slice(0, spliceLimit) + "..." }</Tooltip> : text.slice(0, spliceLimit) + "..." ;
  }
  return text;
};
