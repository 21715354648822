const MULTISELECT_KEYS={
  agentMultiSelect: false,
  channelMultiSelect: false,
  organizationMultiSelect: false,
  caseStatusMultiSelect: false,
  qaStatusMultiSelect: false,
  categoryMultiSelect: false,
  metricMultiSelect: false,
  conversationTagMultiSelect: false,
  instaReviewMultiSelect: false,
  customFilterMultiSelect: false,
  moderatorMultiSelect: false,
  calibrationStatusMultiSelect: false,
  timeGranularityMultiSelect: false,
  qaAuditorMultiSelect: false,
  agentTypeMultiSelect: false
}

export const SCREEN_NAMES={
  INTERACTION_HISTORY: "INTERACTION_HISTORY",
  ANALYTICS_TEAM_VIEW: "ANALYTICS_TEAM_VIEW",
  ANALYTICS_AGENT_VIEW: "ANALYTICS_AGENT_VIEW",
  USER_DASHBOARD: "USER_DASHBOARD",
  CALIBRATION_VIEW: "CALIBRATION_VIEW",
  MY_TASKS_VIEW: "MY_TASKS_VIEW"
};

//do  not add date, searchValue to additionalFilterKeys
export const FILTER_CONFIGURATION = {
  [SCREEN_NAMES.INTERACTION_HISTORY]: {
    defaultFilterKeys: ["date", "searchValue", "user", "conversation_tag", "followed", "insta_review_topics"],
    additionalFilterKeys: ["channel", "organization", "qa_status", "case_status", "category", "metric_rule", "tagged_for_qa", "call_duration", "negative_sentiment", "agent_type"],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      channelMultiSelect: true,
      organizationMultiSelect: true,
      caseStatusMultiSelect: true,
      qaStatusMultiSelect: false,
      categoryMultiSelect: true,
      metricMultiSelect: true,
      conversationTagMultiSelect: true,
      instaReviewMultiSelect: true,
      customFilterMultiSelect: true,
      callDurationMultiSelect: true,
      agentTypeMultiSelect: true
    },
    showCustomFilters: true,
    ENABLE_MORE_FILTERS: true
  },

  [SCREEN_NAMES.ANALYTICS_TEAM_VIEW]: {
    defaultFilterKeys: ["date", "user", "conversation_tag", "followed"],
    additionalFilterKeys: ["channel", "organization", "category", "metric_rule", "timeGranularity"],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      conversationTagMultiSelect: true,
      channelMultiSelect: true,
      organizationMultiSelect: true,
      categoryMultiSelect: true,
      metricMultiSelect: true,
      //timeGranularityMultiSelect: true
    },
    showCustomFilters: false,
    ENABLE_MORE_FILTERS: true
  },

  [SCREEN_NAMES.ANALYTICS_AGENT_VIEW]: {
    defaultFilterKeys: ["date", "searchValue", "user", "conversation_tag", "followed"],
    additionalFilterKeys: ["channel", "organization", "category", "metric_rule", "timeGranularity"],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      conversationTagMultiSelect: true,
      channelMultiSelect: true,
      organizationMultiSelect: true,
      categoryMultiSelect: true,
      metricMultiSelect: true,
      //timeGranularityMultiSelect: true
    },
    showCustomFilters: false,
    ENABLE_MORE_FILTERS: true
  },

  [SCREEN_NAMES.USER_DASHBOARD]: {
    defaultFilterKeys: ["date", "user", "conversation_tag", "followed"],
    additionalFilterKeys: ["channel", "organization", "category", "metric_rule"],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      conversationTagMultiSelect: true,
      channelMultiSelect: true,
      organizationMultiSelect: true,
      categoryMultiSelect: true,
      metricMultiSelect: true
    },
    showCustomFilters: false,
    ENABLE_MORE_FILTERS: true
  },

  [SCREEN_NAMES.CALIBRATION_VIEW]: {
    defaultFilterKeys: ["date", "searchValue", "user", "channel", "moderator", "calibration_status"],
    additionalFilterKeys: [],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      channelMultiSelect: true,
      moderatorMultiSelect: true,
    },
    showCustomFilters: false,
    ENABLE_MORE_FILTERS: true
  },

  [SCREEN_NAMES.MY_TASKS_VIEW]: {
    defaultFilterKeys: ["searchValue", "user", "qa_auditor", "qa_status"],
    additionalFilterKeys: [],
    multiSelectKeys: {
      ...MULTISELECT_KEYS,
      agentMultiSelect: true,
      channelMultiSelect: true,
      moderatorMultiSelect: true,
      qaAuditorMultiSelect: true
    },
    showCustomFilters: false,
    ENABLE_MORE_FILTERS: true
  },
};

export const REDUCER_CONSTANTS={
  UPDATE_ADDITIONAL_FILTER: "UPDATE_ADDITIONAL_FILTERS",
  UPDATE_GLOBAL_FILTER_OBJECT: "UPDATE_GLOBAL_FILTER_OBJECT",
  UPDATE_LOCAL_FILTER_OBJECT: "UPDATE_LOCAL_FILTER_OBJECT",
  UPDATE_FILTER_VALUE: "UPDATE_FILTER_VALUE",
  UPDATE_RESET_KEY: "UPDATE_RESET_KEY",
  RESET_ALL_FILTERS: "RESET_ALL_FILTERS",
  RESET_SELECTED_FILTER: "RESET_SELECTED_FILTER",
  ENABLE_RESET_ALL: "ENABLE_RESET_ALL",
  ENABLE_SHOW_BUTTON: "ENABLE_SHOW_BUTTON"
};

export const dateOptions = [
  {
    label: "Today",
    value: "0",
  },
  {
    label: "Yesterday",
    value: "1",
  },
  {
    label: "Last 7 days",
    value: "7",
  },
  {
    label: "Last 30 days",
    value: "30",
  },
  {
    label: "Last 3 months",
    value: "90",
  },
  {
    label: "Last 6 months",
    value: "180",
  },
  {
    label: "Last 1 year",
    value: "365",
  },
  {
    label: "Custom",
    value: "custom"
  }
];
