import React, { useState, useReducer, useEffect } from "react";
import { Collapse, Button, Input, Radio } from 'antd';
import styled from "@emotion/styled/macro";
import { Option, optionsReducer, AddOption } from "./question";
import { outcomes, criticalQuestions } from "../constants";
import { StyledCheckbox, StyledRadioGroup } from "../../../../components/common/styled-components";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { 
    PrimaryStyledButton, 
} from "../../../../components/common/styled-components";

const { Panel } = Collapse;

const HeaderWrapper = styled.div`
    .title {
        font-size: 15px;
        padding: 0;
        margin: 0;
    }
    .description {
        font-size: 12px;
        color: #828282;
        margin: 0;
    }
`;

const CollapseWraper = styled.div`
    .ant-collapse {
        background-color: white;
        padding: 0;
        border: none;
    }

    .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        border: 0;
    }

    .ant-collapse-header {
        border: none;
        padding-bottom: 0px;
    }

    .heading {
        font-size: 11px;
        padding-top: 10px;
    }

    .points_warning {
        color: #BDBDBD;
        font-size: 10px;
    }
    .ant-input-affix-wrapper {
        height: 32px;
    }
    .ant-collapse-content {
        border-top: none;
    }
    .ant-collapse-content-box {
        padding-left: 40px;
        padding-top: 0px;
    }
`

const QuestionSettingWrapper = styled.div`
    p {
        margin: 0px;
        //padding-top: 15px;
        padding-bottom: 5px;
    }
`

const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    * {
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .ant-input-affix-wrapper {
        height: 32px;
    }
`

const OutcomeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    input { 
        padding-left: 10px;
    }

    .item {
        flex: 1;
    }
    .ant-input-suffix {
        color: #adadad
    }

    .range {
        display: flex;
        flex-direction: row;

        .from_to {
            font-size: 10px;
            color: #4F4F4F;
            margin: auto 5px;
            min-width: 24px;
        }
        .ant-input {
            width: 75px;
            padding: 10px;
            // margin-right: 10px;
        }
        .ant-input-affix-wrapper {
            height: 32px;
            width: 80px;
        }
    }
`

const Header = ({title, description}) => (
    <HeaderWrapper>
        <p className="title">{title}</p>
        <p className="description">{description}</p>
    </HeaderWrapper>
)


const categoryBuilderReducer = (categories, action) => {
    let _categories;
    switch (action.type) {
        case "SET_CATEGORY" : 
              const { _categoriesList } = action.payload;
              return _categoriesList;

        case "ADD_CATEGORY":
            _categories = [...categories, {
                title: ``,
                sections: [{
                    questions: []
                }]
            }]
            _categories = _categories.map((category) => (
                {
                    ...category,
                    weightage: Math.round(100/(_categories.length)*100)/100,
                }
            ))
            action.onChange({categories: _categories})
            return _categories
        
        case "UPDATE":
            _categories = [...categories]
            const { i, value, key } = action.payload;

            _categories.splice(i, 1, {
                ..._categories[i],
                [key]: value
            });
    
            action.onChange({categories: _categories})
            return _categories
        
        case "DELETE":
            _categories = [...categories]
            _categories.splice(action.payload.i, 1);
            _categories = _categories.map((category) => (
                {
                    ...category,
                    weightage: Math.round(100/(_categories.length)*100)/100,
                }
            ))
            action.onChange({
                categories: _categories
            })
            return _categories;
        default:
            break;
    }
}

export const CategoryBuilder = (props) => {
    const { onChange } = props;
    const [categories, dispatch] = useReducer(categoryBuilderReducer, [...props.categories])

    useEffect(() => {
      dispatch({
        type: "SET_CATEGORY",
        payload: {
            _categoriesList: [...props.categories]
        }
    })
    }, [props.categories]);

    return (
        <CollapseWraper>
            <Collapse defaultActiveKey={['1']} >
                <Panel header={
                    <Header title={"Categories"} description={"Manage the categories and weighting."} />
                } key="1">
                    {
                        categories.map((category, i) => (
                            <CategoryWrapper>
                                <Input value={category.title}
                                    placeholder={`Category name (i.e. Etiquette, Compliance, Accuracy)`}
                                    onChange={(e) => {
                                    dispatch({
                                        type: "UPDATE",
                                        payload: {
                                            value: e.currentTarget.value,
                                            i,
                                            key: 'title',
                                        },
                                        onChange
                                    })
                                }}></Input>
                                <Input id="weightage" value={`${category.weightage}`} onChange={(e) => {
                                    dispatch({
                                        type: "UPDATE",
                                        payload: {
                                            value: e.currentTarget.value,
                                            i,
                                            key: 'weightage',
                                        },
                                        onChange
                                    })
                                }}
                                suffix={"%"}
                                ></Input>
                                <Button style={{border: "none", boxShadow: "none"}} onClick={(e) => {
                                    dispatch({
                                        type: "DELETE",
                                        payload: {
                                            i
                                        },
                                        onChange
                                    })
                                }}>
                                    <DeleteIcon />
                                </Button>
                            </CategoryWrapper>
                        ))
                    }
                    <PrimaryStyledButton onClick={() => {
                        dispatch({
                            type: "ADD_CATEGORY",
                            onChange
                        })
                    }}>+ Add Category</PrimaryStyledButton>
                </Panel>
            </Collapse>
        </CollapseWraper>
    )
}

export const ResultsBuilder = (props) => {
    const [ count, setCount ] = useState(0)
    const getThresholds = (outcome, scoringMethod, isInitial) => {

        let maxScore = scoringMethod == "points" ? props.maxScore : 100;
        // Use thresholds coming from backend
        if (props.results.thresholds.length !== 0 && isInitial) {
            return props.results.thresholds.map((threshold, i) => {
                if (i === 0 && scoringMethod == "points") {
                    return {
                        ...threshold,
                        to_value: maxScore
                    }
                } else {
                    return threshold
                }
            })
        } else {
            return outcomes[outcome].map((outcome, i) => ({
                outcome: outcome.key,
                from_value: 0,
                to_value: i == 0 ? maxScore : 0
            }))
        }
    }

    const shouldShowWarning = (scoringMethod) => {
        return scoringMethod != "percentage" && props.maxScore == 0;
    }

    const [ scoringMethod, setScoringMethod ] = useState(props.results.scoringMethod)
    const [ outcome, setOutcome ] = useState(props.results.outcome)
    
    const [ thresholds, setThresholds ] = useState([...getThresholds(outcome, scoringMethod, true)])
    
    useEffect(() => {
        if (count !== 0) {
            setThresholds(getThresholds(outcome, scoringMethod, false));
        } else {
            setThresholds(getThresholds(outcome, scoringMethod, true));
            setCount(count+1)
        }
        
    }, [props.maxScore, props.results.scoringMethod])

    const handleChange = (e) => {
        switch(e.target.name) {
            case "scoringMethod":
                setScoringMethod(e.target.value)
                break
            case "outcomes":
                setOutcome(e.target.value)
                setThresholds(getThresholds(e.target.value, scoringMethod, false))
                break
        }
    }

    useEffect(()=> {
        props.onChange(
            {
                results: {
                    scoringMethod,
                    outcome,
                    thresholds
                }
            }
        )
    }, [scoringMethod, outcome, thresholds])
    return (
        <CollapseWraper>
            <Collapse defaultActiveKey={['1']} >
                <Panel header={
                    <Header title={"Results"} description={"Define how you would like results to be presented."} />
                } key="1">
                    <p className="heading">Scoring Method</p>
                    <StyledRadioGroup value={scoringMethod} onChange={handleChange} name="scoringMethod" buttonStyle="solid">
                        <Radio.Button value="percentage">Percentage</Radio.Button>
                        <Radio.Button value="points">Points</Radio.Button>
                    </StyledRadioGroup>
                    <br />
                    <p className="heading">Outcomes</p>
                    <StyledRadioGroup value={outcome} onChange={handleChange} name="outcomes" buttonStyle="solid">
                        <Radio.Button value="pass_fail">Pass/Fail</Radio.Button>
                        <Radio.Button value="buckets">Buckets</Radio.Button>
                        <Radio.Button value="raw">Raw Score</Radio.Button>
                    </StyledRadioGroup>


                    <p className="heading">Threshold</p>
                    {
                        shouldShowWarning(scoringMethod) ?
                        (
                            <p className="points_warning">You must add questions with point values before you can set a point-based threshold.</p>
                        )
                        :
                        (
                            <>
                            {thresholds.map((threshold, i) => {
                                const pointLabels = i == 0 && scoringMethod == "percentage" ? ["Over"] : ["From", "to"]
                                return (
                                    <OutcomeWrapper>
                                        <p className="item">{threshold.outcome.charAt(0) + threshold.outcome.slice(1).toLowerCase()}</p>
                                        <div className="item range">
                                        {
                                            pointLabels.map((label, j) => (
                                                <>
                                                    <p className="from_to">{label}</p>
                                                    <Input disabled={j == 1} onChange={(e) => {
                                                        let _thresholds = [...thresholds]
                                                        _thresholds.splice(i, 1, {
                                                            ..._thresholds[i],
                                                            from_value: e.currentTarget.value
                                                        })
                                                        if (i < _thresholds.length - 1) {
                                                            _thresholds.splice(i+1, 1, {
                                                                ..._thresholds[i+1],
                                                                to_value: e.currentTarget.value
                                                            })
                                                        }
                                                        setThresholds(_thresholds)
                                                    }}
                                                    value={j == 1 ? Math.round(thresholds[i].to_value*100)/100 : thresholds[i].from_value}
                                                    suffix={scoringMethod == "percentage" ? "%" : "pts"}
                                                    />
                                                </>
                                            ))
                                        }
                                        </div>
                                    </OutcomeWrapper>
                                )
                            })}
                            </>
                        )
                    }
                </Panel>
            </Collapse>
        </CollapseWraper>
    )
}

export const QuestionSettingsBuilder = (props) => {
    const { onChange } = props;
    //const [ criticalQuestion, setCriticalQuestion ] = useState(props.criticalQuestion);
    let [ options, dispatch ] = useReducer(optionsReducer, props.defaultOptions);
    return (
        <CollapseWraper>
            <Collapse defaultActiveKey={['1']} >
                <Panel header={
                    <Header title={"Question Settings"} description={"Define default and available settings for questions."} />
                } key="1">
                    {/* <QuestionSettingWrapper>
                        <p>Critical Questions</p>
                        <StyledRadioGroup defaultValue={criticalQuestion} buttonStyle="solid" onChange={(e) => {
                            setCriticalQuestion(e.target.value)
                            onChange({
                                key: "critical_questions",
                                value: e.target.value
                            })
                        }}>
                            {
                                criticalQuestions.map((option) => (
                                    <Radio.Button value={option.key}>
                                        {option.label}
                                    </Radio.Button>
                                ))
                            }
                        </StyledRadioGroup>
                    </QuestionSettingWrapper> */}
                    <QuestionSettingWrapper>
                        <p>Default Answers</p>
                        {
                            options.map((option, i) => (
                                <Option option={option} 
                                showNumbering={false}
                                showPoints={true}
                                showPrefillAnswerRadioBtn={false}
                                onChange={(option) => {
                                    dispatch({
                                        type: "UPDATE",
                                        i,
                                        value: option,
                                        onChange
                                    })
                                }}
                                canDelete={i>1}
                                onDelete={() => {
                                    dispatch({
                                        type: "DELETE",
                                        i,
                                        onChange: props.onChange
                                    })
                                }}
                                />
                            ))
                        }
                        

                        <AddOption onAddEmpty={() => {
                            dispatch({
                                type: "ADD_OPTION",
                                onChange
                            })
                        }}
                        canAddOption={true}
                        ></AddOption>

                    </QuestionSettingWrapper>

                </Panel>
            </Collapse>
        </CollapseWraper>
    )
}
