import { rejects } from "assert";
import { resolve } from "path";
import React from "react";
import CSVDownloadButton from "../CSVDownloadButton/CSVDownloadButton";

const measureCsvCol = {
  "Raw Score": "QA Score",
};
const measurePrefix = {
  "Pass Rate": "%",
  "Fail Rate": "%",
  "Poor Rate": "%",
  "Good Rate": "%",
  "Outstanding Rate": "%",
  "Percent Score": "%",
};

const ExportDynamicChart = ({ fetchData, groupBy, formatXValue, alreadyParsed=false }) => {
  const getTitleAndType = (color, colorKeys, measureText) => {
    if (colorKeys.length === 1) {
      return {
        title: measureCsvCol[measureText] || measureText,
        type: colorKeys[0].type
      };
    }
    let colorKey = colorKeys?.find((colorKey) =>
      color.includes(colorKey.key)
    );
    let text = colorKey?.shortTitle
    return {
      title: measureCsvCol[text] || text || "",
      type: colorKey?.type
    };
  };

  const exportData = () => {
    const tableData = [];
    let index = 0;
    const uniqueX = {};

    if(alreadyParsed) {
      return fetchData();
    }

    return fetchData().then((chartData) => {
      chartData.forEach((data, ind) => {
        if (uniqueX[data.x] === undefined) {
          uniqueX[data.x] = index;
          tableData.push({
            [groupBy]: formatXValue(data.x, false),
          });
          index++;
        }
        let {title,type} = getTitleAndType(data.color, data.colorKeys, data.measureText);
        let value = data.measure;
        if(type === 'number' && Math.ceil(value) !== value) {
          value = value?.toFixed(2);
        }
        value = value === undefined || value === null
        ? "-"
        : value.toString() + (measurePrefix[title] || "")
        tableData[uniqueX[data.x]] = {
          ...tableData[uniqueX[data.x]],
          [title]: value,
        };
      });
      return tableData;
    });
  };
  
  return (
    <div>
      <CSVDownloadButton
        alreadyParsed={alreadyParsed}
        dataFunc={exportData}
        getTotalCount={() => 5000}
        chartButton={true}
        maxLimit={5000}
      />
    </div>
  );
};

export default ExportDynamicChart;
