import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";
import { useSelector } from "../../store";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";
import { UserRoles } from "../../CompanySetup/components/user-management/config";

const QaAuditorFilter = ({ value, multiSelect, handleChange }) => {
    const options = useSelector(state => state.dashboard.segments.qa_auditors);
    const [qaAuditor, updateqaAuditor] = useState(value);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        updateqaAuditor(value);
    }, [value]);
    
    return (
        <CustomFilterComponent
            width={200}
            placeholder="Select QA Auditor"
            value={qaAuditor}
            maxTagCount={1}
            multiSelect={multiSelect}
            handleChange={handleChange}
            options={options}
        />
    );
};

export default QaAuditorFilter;
