import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";
import { useSelector } from "../../store";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";
import { UserRoles } from "../../CompanySetup/components/user-management/config";

const ModeratorFilter = ({ value, handleChange, multiSelect }) => {
  const [moderator, updateModerator] = useState(value);
  const [fetching, setFetching] = useState(true);
  const filtersSegmentList = useSelector(
    (state) => state.dashboard.filterSegments
  );

  useEffect(() => {
    updateModerator(value);
  }, [value]);
  return !multiSelect ? (
    <FilterComponent
      width={200}
      options={
        filtersSegmentList.globalUserRoleList?.length > 0
          ? filtersSegmentList.globalUserRoleList
              .filter((item) => item.first_name !== "" && (item.role.includes(UserRoles.Admin) || item.role.includes(UserRoles.Manager)))
              .map((item) => {
                return {
                  label: item.first_name.concat(" " + item.last_name),
                  value: item.id,
                };
              })
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          : []
      }
      placeholder="All Moderators"
      value={moderator}
      maxTagCount={1}
      multiSelect={multiSelect}
      showSearchIcon={true}
      handleChange={(value, option) => {
        if (value === undefined) {
          //getStatuses();
        }
        updateModerator(value);
        handleChange(value);
      }}
      showSearch={true}
      hideClear={false}
      loading={fetching}
    />
  ) : (
    <CustomFilterComponent
      width={200}
      options={
        filtersSegmentList.globalUserRoleList?.length > 0
          ? filtersSegmentList.globalUserRoleList
              .filter((item) => item.first_name !== "")
              .map((item) => {
                return {
                  label: item.first_name.concat(" " + item.last_name),
                  value: item.id,
                };
              })
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          : []
      }
      placeholder="All Moderators"
      value={moderator}
      maxTagCount={1}
      multiSelect={multiSelect}
      showSearchIcon={true}
      handleChange={(value, option) => {
        if (value === undefined) {
          //getStatuses();
        }
        updateModerator(value);
        handleChange(value);
      }}
    />
  );
};

export default ModeratorFilter;
