import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  PrimaryButton,
} from "../../../../components/common/styled-components";
import { useColumns } from "../hooks";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { useReq } from "../../../../custom-hooks/useDispatchReq";
import { REQUEST_TYPE } from "../../../../custom-hooks/config";
import { encryptAndSendToStorage, openNotification } from "../../../../utils/utilities";
import ErrorFallback from "../../../../components/ErrorFallback";
import { LineSeparator, SetupWrapper } from "../../commons/wrappers";
import SetupHeader from "../../commons/SetupHeader";
import {
  deleteQaAssignmentData,
  editQaAssignmentData,
  getQaAssignmentTableData,
} from "../api";
import { usePagination } from "../../SelfServingCustomFilters/hooks";
import { HeaderWrapper, TableWrapper } from '../wrappers';

const Table = ({ height, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableData = useSelector((state) => state.qaCaseAssignmentData.tableData);
  const { columns } = useColumns(handleToggle, handleDelete);
  const [loading, setLoading] = useState(true);

  const [callApi, cancelRequests] = useReq();
  const [page, setPage, data, trackScrolling, getTableData] = usePagination(
    ({ data }) => {
      dispatch({
        type: ReduxActionTypes.SET_QA_CASE_ASSIGNMENT_TABLE_DATA,
        payload: data,
      });
      sessionStorage.setItem(
        "qaAssignmentData",
        JSON.stringify(data)
      );
      setLoading(false);
    },
    () => {
      setLoading(true);
    },
    REQUEST_TYPE.DISPATCH_REQUEST,
    getQaAssignmentTableData,
    ""
  );

  function handleToggle(val, key, record) {
    let _tableData = produce(tableData, _data => {
      _data.map((data) => {
        if (data.id === record.id) {
          data[key] = val;
          return data;
        }
        return data;
      });
    })
    dispatch({
      type: ReduxActionTypes.SET_QA_CASE_ASSIGNMENT_TABLE_DATA,
      payload: [..._tableData],
    });
    sessionStorage.setItem(
      "qaAssignmentData",
      JSON.stringify(_tableData)
    );
    handleEditFilter(key, val, record.id);
  }

  const handleEditFilter = (key, value, id) => {
    callApi(
      editQaAssignmentData,
      () => {},
      (err) => {},
      {
        id,
        data: { [key]: value },
      },
      () => {},
      REQUEST_TYPE.DISPATCH_REQUEST
    );
  };
  const openRuleView = () => {
    history.push(`/setup/qa-case-assignment/create-rule`);
  };

  function handleDelete(record) {
    setLoading(true);
    callApi(deleteQaAssignmentData, (res) => {
      getTableData({}, 1);
      openNotification("bottomLeft", "Record deleted successfully.");
    }, (err) => {
      setLoading(false);
      },
      {
        id: record.id,
      },
      () => {
      },
      REQUEST_TYPE.DISPATCH_REQUEST
    );
  }

  return (
    <>
      <SetupWrapper className="setup-tab-qa-case-assignment">
        <HeaderWrapper>
          <SetupHeader
            title={rest.title}
            icon={rest.icon}
            description={rest.description}
            breadCrumbItems={[
              { title: "Home", link: "/" },
              { title: "Settings", link: "/setup" },
              { title: "QA assignment" },
            ]}
          />
          <PrimaryButton onClick={openRuleView} type="primary">
            New rule
          </PrimaryButton>
        </HeaderWrapper>
        <LineSeparator />
        <TableWrapper
          rowKey="id"
          dataSource={tableData?.length ? tableData : []}
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ y: height, x: 900 }}
        />
      </SetupWrapper>
    </>
  );
};

const TableComponent = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Table {...props} />
  </ErrorBoundary>
);

export default TableComponent;
