export const dataSourceOptions = [{
  value: 'salesforce', 
  label: 'Salesforce'
}, {
  value: 'zendesk',
  label: 'Zendesk'
}];

export const enableFiltersOptions = [{
  value: '1',
  label: 'Yes'
}, {
  value: '0',
  label: 'No'
}];

export const ERROR_OPTONS = {
  NON_FIELD_ERROR: "NON_FIELD_ERROR"
}
