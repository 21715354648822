import React, { useState } from "react";
import {
  StyledTable,
  TitleDescriptionWrapper,
} from "./../../../components/common/styled-components";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as AutoDiscovered } from "../../../assets/computer.svg";
import { ReactComponent as MergeIcon } from "../../../assets/MergeIconNew.svg";
import ActionMenu from "./../commons/ActionMenu";
//import styled from "@emotion/styled/macro";
import { message, Input, Spin, Tooltip } from "antd";
import ConversationModal from "./conversation-modal";
import { ReactComponent as SortIcon } from "../../../assets/positive-icon.svg";
import { editTitle, deleteCategory } from "../../api/api";
import {ConversationsWrapper,ExampleWrapper,AutoDiscoveredWrapper,CategoryNameWrapper,AutoGeneratedWrapper,DescendingIconWrapper, AscendingIconWrapper, SortIconWrapper, SortableHeaderWrapper,Spinner, EditTitleWrapper, LinkWrapper} from './wrappers'
import { CheckOutlined , CloseOutlined } from '@ant-design/icons';
import TrainingStatus from '../../../components/chips/TrainingStatus';
import { Link } from 'react-router-dom';

const sortableHeaderColumn = (title, itemKey, sortable, updateSortable) => {
  const handleSorting = () => {
    if (sortable.key !== itemKey) {
      updateSortable(itemKey, "dsc");
    } else {
      if (sortable.order === "dsc") {
        updateSortable(itemKey, "asc");
      } else {
        updateSortable();
      }
    }
  };
  const { order, key } = sortable;
  return (
    <SortableHeaderWrapper
      order={itemKey === key ? order : ""}
      onClick={handleSorting}
    >
      {title}
      {itemKey === key && order === "dsc" && (
        <DescendingIconWrapper>
          <SortIcon />
        </DescendingIconWrapper>
      )}
      {itemKey === key && order === "asc" && (
        <AscendingIconWrapper>
          <SortIcon />
        </AscendingIconWrapper>
      )}
      {(itemKey !== key || !order) && (
        <SortIconWrapper>
          <SortIcon />
        </SortIconWrapper>
      )}
    </SortableHeaderWrapper>
  );
};
const ConversationCategorisationList = (props) => {
  const [showUpdateCategoryModal, setshowUpdateCategoryModal] = useState(false);
  const [showMergeCategoryModal, setshowMergeCategoryModal] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const [showEditID, setShowEditID] = useState("");
  const [mergeRecord, setMergeRecord] = useState({});
  const [editFieldValue, setEditFieldValue] = useState("");
  const [disbale_input, setDisable]=useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleshowhideCustomCategoryModal = () => {
    showUpdateCategoryModal? setshowUpdateCategoryModal(false): setshowUpdateCategoryModal(true); 
  };

  const handleshowhideMergeCategoryModal = () => {
    showMergeCategoryModal? setshowMergeCategoryModal(false): setshowMergeCategoryModal(true);
  };

  const handleEditFieldValueChange = (e) => {
    setEditFieldValue(e.target.value);
  };

  const handleEnterClick = (record) => {
    setShowSpinner(true);
    setDisable(true)
    if (editFieldValue === "") {
      setShowSpinner(false);
      return message.error("Category title field is empty.");
    }
    editTitle(record.id, editFieldValue !== record.title ? editFieldValue:"").then((res) => {
        props.handleSetState("Title successfully changed.");
        setShowEditID("");
        setEditFieldValue("");
      })
      .catch((err) => {
        //message.error(err.response.data.title[0])
      }).finally(() => {
        setShowSpinner(false);
        setDisable(false);
      });
  };

  const deleteCategoryRecord = ({ id }) => {
    setShowSpinner(true);
    deleteCategory(id)
      .then((res) => {
        props.handleSetState("Category deleted successfully.");
      })
      .catch((err) => {
        //message.error(err.message)
      })
      .finally(() => setShowSpinner(false));
  };

  const handleClick = (title) => {
      const newWindow= window.open(`/conversations?category=${encodeURIComponent(title)}`, "_blank");
      newWindow.focus();
  }
  const columns = [
    {
      title: sortableHeaderColumn(
        "Categories",
        "title",
        props.sortable,
        props.updateSortable
      ),
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (value, record) => {
        return (
          <TitleDescriptionWrapper>
            {record.category_type === "auto_discovered" ? (
              record.id !== showEditID && (
                <CategoryNameWrapper>
                  <div className="category-name">{record.title}</div>
                  {record.title_auto_generated && (
                    <AutoGeneratedWrapper>Auto-generated</AutoGeneratedWrapper>
                  )}
                  <div
                    onClick={() => {
                      setShowEditID(record.id);
                      setEditFieldValue(record.title);
                    }}
                    className="edit-title"
                  >
                    edit title 
                  </div>
                </CategoryNameWrapper>
              )
            ) : (
              <EditTitleWrapper><span className="title">{record.title}</span> {!record.category_trained && <TrainingStatus status={"Needs Training"}/>} </EditTitleWrapper>
            )}
            {record.id === showEditID && (
              <Input
                defaultValue={record.title}
                value={editFieldValue}
                onChange={(e) => {
                  e.persist();
                  handleEditFieldValueChange(e);
                }}
                placeholder="Edit Title"
                disabled={disbale_input}
                onPressEnter={() => handleEnterClick(record)}
                suffix={
                  <div>
                      <CheckOutlined onClick={() => handleEnterClick(record)} style={{color:"green", marginRight:"5px"}}/> <CloseOutlined onClick={()=>{ setShowEditID(""); setEditFieldValue("");}} style={{color:"red"}}/>
                  </div>
                }
              />
            )}
          </TitleDescriptionWrapper>
        );
      },
    },
    {
      title: "Example Terms",
      dataIndex: "example-terms",
      key: "example-terms",
      width: "35%",
      render: (text, record) => (
        <ExampleWrapper>
          {record.example_terms.length > 0
            ? record.example_terms.slice(0, 3).map((item, index) => (
                <div className="example-terms" key={index}>
                  {item}
                </div>
              ))
            : ""}
        </ExampleWrapper>
      ),
    },
    {
      title: sortableHeaderColumn(
        "Conversations",
        "count",
        props.sortable,
        props.updateSortable
      ),
      dataIndex: "count",
      key: "count",
      align: "center",
      width: "15%",
      render: (value, record) => {
        return <ConversationsWrapper>{ record.count > 0 ? <LinkWrapper onClick={() => { handleClick(record.title) }} target = "_blank" >{record.count}</LinkWrapper> : <div style={{userSelect:"none"}}>{record.count}</div>}</ConversationsWrapper>;  //the one with count 0 are not clickable, as the one with 0 are not visible in the category filter
      },
    },
    {
      title: "Auto-discovered",
      dataIndex: "auto-discovered",
      key: "auto-discovered",
      width: "15%",
      align: "center",
      render: (value, record) => {
        return (
          <AutoDiscoveredWrapper title="Auto-discovered category">
            {record.category_type === "auto_discovered" && <AutoDiscovered />}
          </AutoDiscoveredWrapper>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      align: "center",
      render: (value, record) => {
        return (
          <ActionMenu
            menuItems={
              record.category_type === "auto_discovered"
                ? [
                    {
                      title: "Merge",
                      key: "merge",
                      icon: MergeIcon,
                    },
                    {
                      title: "Delete",
                      key: "delete",
                      icon: DeleteIcon,
                    },
                  ]
                : [
                    {
                      title: "Edit",
                      key: "edit",
                      icon: EditIcon,
                    },
                    {
                      title: "Delete",
                      key: "delete",
                      icon: DeleteIcon,
                    },
                  ]
            }
            handleMenuClick={(key) => {
              if (key === "delete") {
                deleteCategoryRecord(record);
              } else if (key === "edit") {
                setUpdateRecord(record);
                handleshowhideCustomCategoryModal();
              } else if (key == "merge") {
                setMergeRecord(record);
                handleshowhideMergeCategoryModal();
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      {/* <Spinner>{showSpinner && <Spin />}</Spinner> */}
      <StyledTable
        dataSource={props.tableData}
        columns={columns}
        loading={props.isLoading || showSpinner}
        rowKey="id"
        scroll={{ y: "calc(100vh - 290px)" }}
        pagination={false}
      />
      {showUpdateCategoryModal && (
        <ConversationModal
          isModalVisible={showUpdateCategoryModal}
          handleCloseModal={handleshowhideCustomCategoryModal}
          flag="Update"
          title="Custom Category"
          updateData={updateRecord}
          modalBody={"Phrases"}
          modalBodyText={"What statement would be part of this category?"}
          buttonText={"New phrase"}
          handleSetState={props.handleSetState}
        />
      )}
      {showMergeCategoryModal && (
        <ConversationModal
          isModalVisible={showMergeCategoryModal}
          handleCloseModal={handleshowhideMergeCategoryModal}
          flag="Merge"
          title="Merge Categories"
          updateData={mergeRecord}
          modalBody={"Merge categories"}
          modalBodyText={
            "What auto-discovered catagories do you want to merge into this category?"
          }
          buttonText={"Merge another category"}
          handleSetState={props.handleSetState}
        />
      )}
    </>
  );
};

export default ConversationCategorisationList;
