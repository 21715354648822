import React from "react"
import styled from "@emotion/styled/macro";
import { ReactComponent as VerifiedIcon} from "../../assets/verified-icon.svg";
import { ReactComponent as SavedIcon} from "../../assets/saved-icon.svg";

const TrainingStatusWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TrainingStatusDataWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
    padding: 4px 10px;
    border-radius: 100px;
    min-width: 72px;
    font-size: 11px;
    height: 24px;
    svg {
        width: 10px;
        height: 10px;
        margin-right: 4px;
        position: relative;
        top: -1px;
    }
`;  

const TrainingStatus = ({ status }) => {
    const getStatusIcon = (status) => {
        let icon, color, backgroundColor;
        switch(status) {
            case "Trained":
                icon = VerifiedIcon;
                backgroundColor = "rgba(39, 174, 96, 0.1)";
                color = "#27AE60";
                break;
            default:
                icon = SavedIcon;
                backgroundColor = "rgba(130, 130, 130, 0.1)";
                color = "#828282";
                break;            
        }
        return {
            icon,
            color,
            backgroundColor,
        }
    }
    const { icon: Icon, color, backgroundColor } = getStatusIcon(status);
    return (
        <TrainingStatusWrapper>
            <TrainingStatusDataWrapper color={color} backgroundColor={backgroundColor}>
                <Icon />
                {status}
            </TrainingStatusDataWrapper>
        </TrainingStatusWrapper>
    )
}

export default TrainingStatus;