import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Checkbox, Radio, Spin } from "antd";
import { StyledSearchInput } from "../../../components/common/styled-components";
import EmptyState from "../../../components/common/EmptyState";
import Constants from "../../../CompanySetup/utils/constants";
import { SearchOutlined } from '@ant-design/icons';
import { CheckMarkFilterWrapper, SearchWrapper } from '../wrappers'; 
import { spliceText } from "../../../components/common/CustomFilterComponent/utils";
import { isValueExists } from "../../../components/common/CustomFilterComponent/helpers";

const CheckMarkFilter = ({
  options,
  value,
  onChange,
  searchStyle={},
  placeholder,
  checkBoxStyle={},
  radioBoxStyle={},
  multiSelect,
  groupDirection,
  componentStyle={}
}) => {
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState(value);
  const [optionsList, setOptionsList] = useState([]);
  const [_options, setOptions] = useState([]);
  const [loading,setLoading] = useState(true);
  const debouncedOnChange = useCallback(debounce(onChange, 150), []);

  const handleCheckboxChange = (e) => {
    let updatedValue = filterValue?.length ? [...filterValue] : [];
    if(isValueExists(e?.target?.value, filterValue)) {
      updatedValue = updatedValue?.filter((item) => item != e?.target?.value);
    }
    else {
      updatedValue.push(e?.target?.value);
    }

    setFilterValue(updatedValue);
    debouncedOnChange(updatedValue);
  };

  const handleRadioBoxChange = (event) => {
    const value= event.target.value;
    setFilterValue(value);
    debouncedOnChange(value);
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
    if (!value) {
      return setOptionsList(_options);
    }

    const updatedOptions = _options?.filter((option) =>
      option.label?.toLowerCase().includes(value.toLowerCase())
    );
    setOptionsList(updatedOptions);
  };

  const renderRadioWrapper = () => {
    const radioGroupStyle={...radioBoxStyle, ...(_options.length > 7 ? {} : checkBoxStyle)}
    return (
      <Radio.Group style={radioGroupStyle} className={`d-flex ${groupDirection || `flex-column`}`} onChange={handleRadioBoxChange} value={filterValue}>
              {
                generateTopItems()
              }
              {
                optionsList?.filter(option => _options?.length > 7 ?  ![filterValue]?.map(String)?.includes(option.value?.toString()) : option)?.map(option => {
                  return <Radio value = {option.value} key = {option.value}> { spliceText(option.label, 40, true) } </Radio>
                })
              }
      </Radio.Group>
    );
  };

  const generateTopItems = () => {
    if(_options?.length > 7) {
      const _filterValue = filterValue !== undefined && filterValue !== null && filterValue !== "" ?  (!Array.isArray(filterValue) ? [filterValue] : filterValue) : [];
      const selectedItems = _filterValue?.filter(val => optionsList?.find(option => option.value == val)).map(val => optionsList?.find(option => option.value == val));
      return selectedItems?.length ? selectedItems.map(item => multiSelect ? <Checkbox onChange={handleCheckboxChange} value = {item.value} key = {item.value}> { spliceText(item.label, 40, true) } </Checkbox> : <Radio value = {item.value} key = {item.value}> { spliceText(item.label, 40, true) } </Radio>) : null;
    }
    else {
      return null;
    }
  }

  useEffect(() => {
    if(typeof options === 'function') {
      options().then(res => {
        setOptions(res)
        setLoading(false);
      })
    } else {
      setOptions(options)
      //setOptionsList(options);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(multiSelect) {
      if((value || value === 0) && !Array.isArray(value)) {
        setFilterValue([value]);
        return;
      }  
    }
    setFilterValue(value);
  }, [value])

  useEffect(() => {
    setOptionsList(_options);
  }, [_options]);
  if(loading) {
    return <div style={{height:'100%',width:'100%',display:'grid',placeContent:'center'}}><Spin /></div>
  }
  return (
    <>
      {_options.length > 7 && (
        <SearchWrapper>
          <StyledSearchInput
            placeholder={placeholder}
            style={searchStyle || {}}
            value={search}
            size="medium"
            prefix={<SearchOutlined />}
            onChange={handleSearch}
            allowClear
          />
        </SearchWrapper>
      )}
      <CheckMarkFilterWrapper className={_options?.length > 7 ? "scrollbar-css" : ""} style={componentStyle ? componentStyle : {}}>
        {optionsList.length ? (
          multiSelect ? (
            <Checkbox.Group
              style={_options?.length > 7 ? {} : checkBoxStyle}
              className="d-flex flex-column"
              value={filterValue || undefined}
              //onChange={handleCheckboxChange}
            >
              {
                generateTopItems()
              }
              {
                optionsList?.filter(option => _options?.length > 7 ?  !filterValue?.map(String)?.includes(option.value?.toString()) : option)?.map(option => {
                  return <Checkbox onChange={handleCheckboxChange} value = {option.value} key = {option.value}> { spliceText(option.label, 40, true) } </Checkbox>
                })
              }
            </Checkbox.Group>
          ) : (
            renderRadioWrapper()
          )
        ) : (
          <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
        )}
      </CheckMarkFilterWrapper>
    </>
  );
};

export default CheckMarkFilter;
