import React, { useState, useCallback, useEffect } from 'react';
import { Input } from 'antd';
import styled from "@emotion/styled/macro";
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ClearIcon } from "../../assets/clear-icon.svg";
import { debounce } from "lodash";

export const StyledInput = styled(Input)`
    width: ${props => props.width ? props.width : 220}px;
    position: relative;
    .ant-input-prefix {
        left: 10px;
        top: 17px;
        svg {
            path {
                fill: #BDBDBD
            }
        }
    }
    input {
        border-color: #E0E0E0;
        color: #828282;
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 12px;
    } 
`;

const StyledClearIcon = styled(ClearIcon)`
    cursor: pointer;
`;

const SearchComponent = (props) => {
    const debouncedOnChange = useCallback(debounce(props.handleSearchValue, 400), []);
    const [searchValue, updateSearchValue] = useState(props.value);
    const handleChange = (event) => {
        const value = event.target.value;
        updateSearchValue(value);
        debouncedOnChange(value);
    }
    const handleClear = () => {
        updateSearchValue("");
        props.handleSearchValue("");
    }
    useEffect(() => {
        if(!props.value) {
            updateSearchValue(props.value);
        }
    }, [props.value]);
    return (
        <StyledInput
            defaultValue={props.value}
            placeholder={props.placeholder}
            onChange={handleChange} 
            value={searchValue}
            prefix={<SearchOutlined />}   
            width={props.width}     
            suffix={searchValue ? <StyledClearIcon onClick={handleClear} /> : null}    
        />
    );
}

export default SearchComponent;