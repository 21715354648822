import React, { Component } from 'react';
import SetupHeader from "../commons/SetupHeader";
import { Modal, Button } from "antd";
import {   
  getPhrasesList,
  approvePhrase,
  rejectPhrase,
  exportPhrases,
} from "./../../api/api";
import { 
  SetupWrapper,
  LineSeparator,  
} from "./../commons/wrappers";
import { StyledTable, TitleDescriptionWrapper } from "./../../../components/common/styled-components";
import FaqPopup from '../../components/FaqPopup';
import IntentPopup from "../../components/IntentPopup";
import { connect } from "react-redux";
import Constants from "../../utils/constants";
import styled from "@emotion/styled/macro";

const ApproveButton = styled(Button)`
  background: #27AE60;
  color: white;
  border: none;  
`;

const PhrasesList = ({tableData, isLoading, openRule, handleApprove, handleDelete }) => {
    const columns = [
        {
            title: "Name",
            dataIndex: 'intent',
            key: 'intent',
            width: "40%",
            render: (value, record) => {
                return (
                    <TitleDescriptionWrapper>
                        <div className="rule-title" onClick={() => openRule(record.rule)}>{record.title}</div>
                    </TitleDescriptionWrapper>
                )
            }
        },
        {
            title: "Utterance",
            dataIndex: 'utterance',
            key: 'utterance',
            width: "40%",            
        },
        {
          title: "",
          dataIndex: 'approve',
          key: 'approve',
          width: "10%",            
          render: (value, record, index) => {
            return (
              <ApproveButton className="approve" onClick={() => {handleApprove(record, index)}}>Approve</ApproveButton>
            )
          }
        },
        {
          title: "",
          dataIndex: 'reject',
          key: 'reject',
          width: "10%",  
          render: (value, record, index) => {
            return (
              <Button type="danger" onClick={() => {handleDelete(record, index)}}>Delete</Button>
            )
          }          
        },
    ];
    return (
      <StyledTable 
        dataSource={tableData} 
        columns={columns} 
        loading={isLoading} 
        rowKey="id" 
        scroll={{y: "calc(100vh - 290px)"}}  
        pagination={false} 
      />    
    )      
}

export class PhraseAnnotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      isLoading: true,
      showRule: false,
      page: 1,      
    };
    this.dataSource = [];
  }
  componentDidMount() {
    this.getPhrasesList();    
  }
  trackScrolling = () => {
    const tabDocument = document.getElementsByClassName("setup-tab-phrases")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0]
    let isBottom = (el) => {
      return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    }
    if(node && isBottom(node)) {
      node.removeEventListener('scroll', this.trackScrolling);
      this.setState({
        page: this.state.page + 1,
        isLoading: true
      }, () => {
        this.getPhrasesList();
      })
    }
  }
  getPhrasesList = (userFilters) => {
    const { page } = userFilters ? userFilters : this.state;
    const filters = {
      page
    };
    const tabDocument = document.getElementsByClassName("setup-tab-phrases")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener('scroll', this.trackScrolling);
    getPhrasesList(this.props.tabType, filters).then(res => {
      if(res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if(node) {
          node.addEventListener('scroll', this.trackScrolling);
        }
      }
      let phrasesList = res.results.filter((item) => !item.is_approved) || [];
      phrasesList = phrasesList.map((item) => {
          if(item.faq) {
              return {
                  ...item,
                  rule: item.faq,
                  title: item.faq.title,
              }
          }
          else if(item.intent) {
            return {
                ...item,
                rule: item.intent,
                title: item.intent.description
            }
        }
      })
      let data = this.state.page === 1 ? phrasesList : this.dataSource.concat(phrasesList);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });      
    })
  }
  openRule = (record) => {
      this.setState({ popupId: record.id, showRule: true });
  }
  onCancel = () => {
    this.setState({ popupId: null, showRule: false });
  } 
  handleApprove = (record, index) => {
    approvePhrase(this.props.tabType, record.id).then((res) => {
      const tabType = this.props.tabType;
      let type = tabType === "intent-phrase" ? "export-phrases-intent" : "export-phrases-faq";
      exportPhrases(type).then((result) => {
        this.setState({
          tableData: [],
          isLoading: true,
          page: 1,
        }, () => {
          this.dataSource = [];
          this.getPhrasesList();
        })
      })      
    })
  }
  handleDelete = (record, index) => {
    console.log({ record, index });
    rejectPhrase(this.props.tabType, record.id).then((res) => {      
      this.setState({
        tableData: [],
        isLoading: true,
        page: 1,
      }, () => {
        this.dataSource = [];
        this.getPhrasesList();
      })
    })
  }
  render() {
    console.log(this.props, this.state);
    return (
      <SetupWrapper className="setup-tab-phrases">
        <SetupHeader 
          title={this.props.fullTitle}
          icon={this.props.icon}
        />
        <LineSeparator style={{marginTop: "24px"}} />       
        <PhrasesList
            tableData={this.state.tableData} 
            isLoading={this.state.isLoading}
            openRule={this.openRule}
            handleApprove={this.handleApprove}
            handleDelete={this.handleDelete}
        />
        <Modal
            visible={this.state.showRule}
            header = {null}
            closable={false}
            title={null}
            footer={null}
            width={875}
        >
            {this.props.tabType === Constants.TAB_TYPE.FAQ_PHRASES ? <FaqPopup 
                    viewOnly
                    isHistorical 
                    onCancel={this.onCancel}
                    id={this.state.popupId}
                /> : null}
            {this.props.tabType === Constants.TAB_TYPE.INTENT_PHRASES ? <IntentPopup 
                    viewOnly
                    onCancel={this.onCancel}                     
                    id={this.state.popupId}                    
                    isHistorical
                /> : null}                        
        </Modal>
      </SetupWrapper>
    )
  }
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps, null)(PhraseAnnotation);
