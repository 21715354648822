import React, {useState, useReducer, useEffect} from "react";
import { Collapse, Input, Select, Button, Popover, Radio } from "antd";
import { questionScoring, NA, criticalOptions, normalOptions } from "../constants";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import styled from "@emotion/styled/macro";
import { PrimaryStyledButton } from "../../../../components/common/styled-components";
import { ReactComponent as AutoFixNormal } from '../../../assets/auto_fix_normal.svg'
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import { ReactComponent as IntentIcon } from '../../../assets/IntentIcon.svg'
import PopoverDetailsBox from "./PopoverDetailsBox";
import {
  PRECONFIGURED_QUESTIONS,
  METRIC_TAG,
  CONVERSATION_TAG,
} from "./constants";

const { Panel } = Collapse;

const QuestionWrapper = styled.div`
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    overflow: hidden;
    background: #FEFAF6;
    margin-bottom: 10px;
    textarea { 
        resize: none;
    }
    .ant-collapse-borderless > .ant-collapse-item {
        border: 0;
    }

    .description-container {
      margin-top: 0.6rem;
    }

    .preset-label {
      margin-top: 0.6rem;
    }

`;

const BlankQuestionWrapper = styled.div`
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 10px;
`
const OptionParentWrapper = styled.div`
display: flex;
align-items: baseline;
width: 100%;
& .css-option-numbering {
  margin-right: 8px;
}
`;

const OptionWrapper = styled.div`
    width: 100%;
    .ant-btn {
        margin-right: 10px
    }
    .ant-input {
        margin-right: 10px;
        padding-left: 10px;
    }
    textarea {
        resize: none;
    }
    .ant-input-suffix {
        color: #adadad
    }
    padding-top: 10px;
    padding-left: 0px;
    //padding-bottom: 10px;
    margin: 0px 0px 16px 0px;
    .answer-input-points-container {
      display: flex;
      position: relative;
      & .rubric-delete-icon {
        position: absolute;
        right: -35px;
      }
    }

    .large-item {
      width: 75%;
      margin-top: 0.5rem;
    }
`;

const QuestionHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    .ant-select {
        margin-right: 10px;
    }
    .ant-input {
        margin-right: 10px;
    }
    .ant-collapse-header {
        padding: 0;
    }
    .item {
        flex: 2;
    }
    .small-item {
        flex: 0.3;
    }

`;

const QuestionContentWrapper = styled.div`
    border-top: 1px solid #E0E0E0;
    padding: 10px 40px 0px 40px;
`

export const Option = (props) => {
    const {
      canDelete,
      onDelete,
      _key,
      showPoints,
      preset,
      showNumbering,
      prefillOptionIndex,
      setPrefillOptionIndex,
      showPrefillAnswerRadioBtn,
    } = props;
    const [title, setTitle] = useState(props.option.title)
    const [description, setDescription] = useState(props.option.description)
    const [score, setScore] = useState(props.option.score)
    const [isNA, setIsNA] = useState(props.option.isNA)
    const [isPrefilledAnswer, setIsPrefilledAnswer] = useState(false);

    const handleChange = (e) => {
        switch(e.currentTarget.name) {
            case "title":
                setTitle(e.currentTarget.value)
                break
            case "description":
                setDescription(e.currentTarget.value)
                break
            case "points":
                setScore(e.currentTarget.value)
                break
            default:
              break
        }
    }

    useEffect(() => {
        props.onChange({
            title,
            description,
            score
        })
    }, [title, description, score])
    
    useEffect(() => {
        setTitle(props.option.title)
        setDescription(props.option.description)
        setScore(props.option.score)
    }, [props.option.title, props.option.description, props.option.score])

    useEffect(() => {
      setIsPrefilledAnswer(_key == prefillOptionIndex);
    }, [prefillOptionIndex]);

    return (
      <OptionParentWrapper>
        {showNumbering && (
          <div className="css-option-numbering">{_key + 1}.</div>
        )}
        <OptionWrapper>
          <div className="answer-input-points-container">
            <Input.TextArea
              className={"small-item"}
              autoSize
              key={_key + "title"}
              onChange={handleChange}
              placeholder="Option"
              value={title}
              name="title"
              disabled={isNA || preset}
            />
            {showPoints ? (
              <div className={"smallest-item"}>
                <Input
                  key={_key + "points"}
                  onChange={handleChange}
                  value={score}
                  name="points"
                  className="points"
                  disabled={isNA}
                  suffix={"pts"}
                />
              </div>
            ) : null}
            <div className="rubric-delete-icon" style={{ flex: 0.3 }}>
              {canDelete ? (
                <Button
                  style={{
                    border: "none",
                    boxShadow: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  disabled={preset}
                  onClick={() => {
                    onDelete();
                  }}
                >
                  <DeleteIcon />
                </Button>
              ) : null}
            </div>
          </div>
          {!preset && (
            <Input.TextArea
              className={"large-item"}
              autoSize
              key={_key + "description"}
              onChange={handleChange}
              placeholder="Write answer description (optional)"
              value={description}
              name="description"
              disabled={preset}
            />
          )}
          {!props.preset && showPrefillAnswerRadioBtn && (
            <>
              <Radio
                checked={isPrefilledAnswer}
                onChange={() => setPrefillOptionIndex(parseInt(_key))}
                style={{ margin: "5px" }}
              >
                Set as prefilled answer
              </Radio>
              {isPrefilledAnswer && (
                <a onClick={() => setPrefillOptionIndex(null)}>Clear</a>
              )}
            </>
          )}
        </OptionWrapper>
      </OptionParentWrapper>
    );
}

export const BlankQuestion = ({onAdd}) => {
    const [question, setQuesiton] = useState("")
    return (
        <BlankQuestionWrapper>
            <QuestionHeaderWrapper>
                <Input placeholder="Add a question here" onChange={(e) =>{
                    e.persist();
                    setQuesiton(e.currentTarget.value)
                }}
                value={question}
                />
                <Button type="primary" onClick={() => {
                    onAdd(question)
                    setQuesiton("")
                }} disabled={question === ""}>Add Question</Button>
            </QuestionHeaderWrapper>
        </BlankQuestionWrapper>
    )
}

export const AddOption = ({onAddEmpty, onAddNA, canAddNA, canAddOption, preset}) => (
    <OptionWrapper>
        {
            canAddOption ?
            <PrimaryStyledButton onClick={onAddEmpty} className="item" disabled={preset}>+ Add Answer</PrimaryStyledButton>
            :
            null
        }
        
        {
            canAddNA ?
            <PrimaryStyledButton onClick={onAddNA} className="item" disabled={preset}>+ Add {NA.title}</PrimaryStyledButton>
            :
            null
        }
    </OptionWrapper>
)

const updateNAScore = (_options) => {
    let max_score = Math.max(0, ..._options.map((x)=>Number(x.score)))
    // Temporarily set NA to max score
    _options.forEach((option, i) => {
        if ((option.isNA || option.title === NA.title)) {
          _options[i].isNA = true
          if(i === 1) {
            _options[i].score = 0;
          }
          else {
            _options[i].score = max_score
          }
        }
    })
    return [_options, max_score]
}

export const optionsReducer = (options, action) => {
    let max_score = 0;
    if(action.type === "SET_OPTIONS") {
        const { value } = action.payload;
        if (action.onChange) {
            action.onChange({
                key: "options",
                value
            })
        }
        return value;
    }

    if(action.type === "ADD_OPTION") {
        const _options = [...options, {
            title: "",
            description: "",
            score: 0,
            isNA: false
        }]
        action.onChange({
            key: "options",
            value: _options
        })
        return _options
    }

    if (action.type === "ADD_NA_OPTION") {
        // Set score to max_score of all options
        max_score = Math.max(0, ...options.map((x)=>Number(x.score)))
        const _options = [...options, {
            title: NA.title,
            description: "",
            score: max_score,
            isNA: true
        }]

        action.onChange({
            key: "options",
            value: _options
        })
        return _options
    }

    if (action.type === "UPDATE") {
        let {i, value, onChange} = action;
        let _options = [...options]
        _options.splice(i, 1, value)
        [_options, max_score] = updateNAScore(_options)
        
        onChange({
            key: "options",
            value: _options
        })

        onChange({
            key: "max_score",
            value: max_score
        })

        return _options;
    }

    if (action.type === "DELETE") {
        let {i, onChange} = action;

        let _options = [...options]
        _options.splice(i, 1)
        [_options, max_score] = updateNAScore(_options)

        onChange({
            key: "options",
            value: _options
        })
        onChange({
            key: "max_score",
            value: max_score
        })
        
        return _options;
    }
}

export const Question = (props) => {
    const [ question, setQuestion ] = useState({...props.question})
    const [ options, dispatch ] = useReducer(optionsReducer, [...question.options]);
    let [ selectedScoring, setSelectedScoring ] = useState(questionScoring.find(item => item.key == props.question.scoring) || questionScoring[0])
    let [ showPoints, setShowPoints ] = useState(true)
    let maxScore = Math.max(0, ...props.question.options.map((x)=>Number(x.score)));

    const onSelectedScoringChange = (selectedScoring, shouldSetOptions) => {
        props.onChange({
            key: "scoring",
            value: selectedScoring.key
        })
        setShowPoints(true)
        // Check for critical
        if (selectedScoring.key === 2 || selectedScoring.key === 3) {
            setShowPoints(false)
            if (shouldSetOptions) {
                dispatch({
                    type: "SET_OPTIONS",
                    payload: {value: [
                        ...criticalOptions
                    ]},
                    onChange: props.onChange
                })
            }
        }

        else if(selectedScoring.key === 0) {
          if (shouldSetOptions) {
              dispatch({
                  type: "SET_OPTIONS",
                  payload: {value: [
                      ...normalOptions
                  ]},
                  onChange: props.onChange
              })
          }
      }
    }

    useEffect(() => {
      function searchPresetQuestion(id) {
        // Search in all types of preset questions one by one since all three are spread over different arrays
        // It is assured only one of them will actually give the actual result
        const presetQuestionsList = [
          ...props.presetQuestions[CONVERSATION_TAG],
          ...props.presetQuestions[METRIC_TAG],
          ...props.presetQuestions[PRECONFIGURED_QUESTIONS],
        ];
        return presetQuestionsList.find((item) => item.id === id);
      }

      const automated_scoring = props.question?.automated_scoring || null;
      if (!automated_scoring) return setQuestion((prev) => ({
        ...prev,
        preset: false,
      }));

      const presetQuestion = searchPresetQuestion(automated_scoring);

      setQuestion((prev) => ({
        ...prev,
        ...presetQuestion,
        preset: true,
      }));
    }, [props.question, props.presetQuestions]);

    useEffect(() => {
        setQuestion(prev => ({
          ...prev,
          ...props.question
        }))
        let _questionScoring = questionScoring.find(item => item.key == props.question.scoring) || null;
        setSelectedScoring(_questionScoring ? _questionScoring : questionScoring[0])
        if (props.question.scoring !== selectedScoring?.key) {
            onSelectedScoringChange(_questionScoring ? _questionScoring : questionScoring[0])
        }
    }, [props.question])

    useEffect(() => {
        let _questionScoring = questionScoring.find(item => item.key == props.question.scoring) || null;
        onSelectedScoringChange(_questionScoring ? _questionScoring : questionScoring[0], false)
    }, [])

    useEffect(() => {
        dispatch({
            type: "SET_OPTIONS",
            payload: {value: [...props.question.options]}
        })
    }, [props.question.options])

    return (
      <QuestionWrapper>
        <Collapse ghost bordered={false}>
          <Panel
            header={
              <>
                <QuestionHeaderWrapper>
                  <Input.TextArea
                    autoSize
                    rows={1}
                    className="item"
                    placeholder={"Enter the question here."}
                    value={question?.title || ""}
                    onChange={(e) => {
                      e.persist();
                      setQuestion((prev) => ({
                        ...prev,
                        title: e?.currentTarget?.value || "",
                      }));
                      props.onChange({
                        key: "title",
                        value: e?.currentTarget?.value || "",
                      });
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Select
                      id="rubric-builder-question-options"
                      className="item"
                      dropdownClassName="css-rubric-builder-question-options"
                      value={selectedScoring?.key}
                      disabled={question?.preset}
                      onChange={(e) => {
                        if (question?.preset) {
                          return;
                        }
                        let _questionScoring = questionScoring.find(
                          (item) => item.key == e
                        );
                        setSelectedScoring(_questionScoring);
                        onSelectedScoringChange(_questionScoring, true);
                      }}
                    >
                      {questionScoring.map((option, i) => (
                        <Select.Option value={option.key}>
                          {option.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {/* {
                            showPoints ?
                            <Input className="small-item" onClick={(e) => {
                                e.stopPropagation()
                            }}
                            disabled
                            value={maxScore + " pts"}
                            />
                            :
                            null
                        } */}
                  <Button
                    style={{
                      border: "none",
                      boxShadow: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    onClick={(e) => {
                      props.onDelete();
                      e.stopPropagation();
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </QuestionHeaderWrapper>
                <div className="description-container">
                  <Input.TextArea
                    autoSize
                    placeholder="Add Description (Optional)"
                    value={question?.description || ""}
                    onChange={(e) => {
                      setQuestion((prev) => ({
                        ...prev,
                        description: e?.currentTarget?.value || "",
                      }));
                      props.onChange({
                        key: "description",
                        value: e?.currentTarget?.value || "",
                      });
                    }}
                  />
                </div>
                {question?.preset && (
                  <div className="preset-label">
                    <AutoFixNormal /> Autoscore based on {question.type} -{" "}
                    {question.name}
                    &nbsp;
                    <Popover
                      color="#ffffff"
                      placement="left"
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IntentIcon />
                          &nbsp;&nbsp;{question.name}
                        </div>
                      }
                      content={<PopoverDetailsBox question={question} />}
                    >
                      <InfoIcon />
                    </Popover>
                  </div>
                )}
              </>
            }
            key={props.key}
          >
            <QuestionContentWrapper>
              {options.map((option, i) => (
                <Option
                  showNumbering={true}
                  _key={i}
                  option={option}
                  showPrefillAnswerRadioBtn={true}
                  prefillOptionIndex={
                    typeof question.prefill_option_index === "number"
                      ? question.prefill_option_index
                      : null
                  }
                  setPrefillOptionIndex={(prefill_option_index) => {
                    setQuestion((prev) => ({
                      ...prev,
                      prefill_option_index,
                    }));
                    props.onChange({
                      key: "prefill_option_index",
                      value: prefill_option_index,
                    });
                  }}
                  onChange={(option) => {
                    dispatch({
                      type: "UPDATE",
                      i,
                      value: option,
                      onChange: props.onChange,
                    });
                  }}
                  showPoints={showPoints}
                  canDelete={i > 1}
                  onDelete={() => {
                    dispatch({
                      type: "DELETE",
                      i,
                      onChange: props.onChange,
                    });
                  }}
                  preset={question?.preset}
                />
              ))}

              {!question?.preset && (
                <AddOption
                  preset={question?.preset}
                  onAddEmpty={() => {
                    dispatch({
                      type: "ADD_OPTION",
                      onChange: props.onChange,
                    });
                  }}
                  onAddNA={() => {
                    dispatch({
                      type: "ADD_NA_OPTION",
                      onChange: props.onChange,
                    });
                  }}
                  canAddNA={options.filter((option) => option.isNA).length < 1}
                  canAddOption={showPoints}
                ></AddOption>
              )}
            </QuestionContentWrapper>
          </Panel>
        </Collapse>
      </QuestionWrapper>
    );
}
