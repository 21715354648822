import React from "react";
import { componentLoader } from "../utilities";
import AssistPlugin from "../../AgentAssist/AssistPlugin";

const Login = React.lazy(() =>
  componentLoader(() => import("../../components/Login"))
);
const QAPlugin = React.lazy(() =>
  componentLoader(() => import("../../AgentAssist/QAPlugin"))
);
const ResetPassword = React.lazy(() =>
  componentLoader(() => import("../../components/ResetPassword"))
);
const OktaAuthorization = React.lazy(() =>
  componentLoader(() => import("../../components/OktaAuthorization"))
);
const TalkdeskLogin = React.lazy(() => 
  componentLoader(()=> import("../../talkdesk/login"))
)
const SlackLandingPage = React.lazy(() =>
  componentLoader(() => import("../../Slack"))
);

export const publicRoutes = [
  {
    exact: true,
    path: "/plugin/login",
    component: Login,
  },
  {
    exact: true,
    path: "/plugin/assist",
    component: AssistPlugin,
  },
  {
    exact: true,
    path: "/qa/plugin",
    component: QAPlugin,
  },
  {
    exact: true,
    path: "/qa/login",
    component: Login,
  },
  {
    exact: true,
    path: "/",
    component: Login,
  },
  {
    exact: true,
    path: "/accounts/reset/",
    component: ResetPassword,
  },
  {
    exact: true,
    path: "/okta/sso",
    component: OktaAuthorization,
  },
  {
    exact: true,
    path: "/annotation/login",
    component: Login,
  },
  {
    exact: true,
    path: "/talkdesk/login",
    component: TalkdeskLogin
  },
  {
    exact: true,
    path: "/slack/auth",
    component: SlackLandingPage,
  },
  {
    exact: true,
    path: "/qa/plugin/assist",
    component: QAPlugin,
  },
]
