import React from 'react';
import IntentDropdown from './../../IntentDropdown';
import styled from "@emotion/styled/macro";

const SelectIntentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;    
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
    .ant-select {
        margin-left: 0 !important;
    }
`;

const SelectIntent = (props) => {
    const selectedIntent = React.useRef(props.value);
    const onValuesChange = (e) => {
        const value = e.currentTarget.value;
        selectedIntent.current = value;
        props.onChange(value);
    }
    const showError = props.showValidations && !selectedIntent.current;
    return (
        <SelectIntentWrapper>
            <IntentDropdown 
                name={props.name} 
                handleChange={onValuesChange} 
                showIntentPopup={() => props.showIntentPopup && props.showIntentPopup()} 
                initialValue={props.value} 
                hideAddNewOption
            />
            {showError && <div className="error-message">An intent must be selected to continue.</div>}
        </SelectIntentWrapper>
    )
}

export default SelectIntent;
