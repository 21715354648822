export const agentTypeOptions = [
  {
    value: "unassigned",
    label: "Unspecified"
  },
  {
    value: "expert",
    label: "Expert"
  },
  {
    value: "specialist",
    label: "Specialist"
  }
];

export const errorTypes = {
  AGENT_TYPE_ERROR: "AGENT_TYPE_ERROR",
  CHANNEL_ERROR: "CHANNEL_ERROR"
}
