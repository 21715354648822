import React, { useEffect, useState } from "react";
import { CustomSwitchWrapper, RoundSlider, TextWrapper } from '../../wrappers';

const CustomSwitch = ({ value = "", handleChange, icon }) => {
  const [_value, setValue] = useState("Followed");

  const onSwitchChange = (e) => {
    if(e?.currentTarget?.value === "Followed") {
      setValue("Not Followed");
      handleChange("Not Followed");
    }
    else {
      setValue("Followed");
      handleChange("Followed");
    }
  }
  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <CustomSwitchWrapper>
      <label className="switch">
        <input type="checkbox" value = {_value} onChange = {(e) => {
          e.stopPropagation();
          onSwitchChange(e);
          }} checked={_value === "Followed" ? true : false} />
        <RoundSlider className="slider round">

        </RoundSlider>
      </label>
      <TextWrapper>
        Tag {_value}
      </TextWrapper>
    </CustomSwitchWrapper>
  );
};

export default CustomSwitch;
