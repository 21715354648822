import React, { useEffect, useState } from "react";
import { getQuery } from "../AnalyticsDashboard/api";
import { ChartWrapper } from "../AnalyticsDashboard/components/wrapper";
import { getDateRangeValues } from "../utils/utilities";
import { Spin } from "antd";
import { QueryChart } from "../AnalyticsDashboard/components/query-chart";

export const ChartDashboard = (props) => {
    const [ query, setquery ] = useState({timeGranularity: "week"})
    const [ chartType, setchartType ] = useState()
    const [ isLoading, setisLoading ] = useState(true)
    const [ dateFilter, setDateFilter ] = useState(props.dateFilter)
    const [ dateRangeValues, setdateRangeValues ] = useState(getDateRangeValues(props.dateFilter))
    const [ title, settitle ] = useState("")

    useEffect(() => {
        if (dateFilter !== props.dateFilter) {
            setDateFilter(props.dateFilter);
            setdateRangeValues(getDateRangeValues(props.dateFilter));
        }
    }, [props.dateFilter])
    
    useEffect(() => {
        if (props.chartId) {
            setisLoading(true)
            getQuery(props.chartId).then((res) => {
                settitle(res.title)
                setquery({
                    id: res.id,
                    measure: res.measure.replace("BankingBankingconversation", "Conversation"),
                    groupBy: res.dimension,
                    timeGranularity: res.time_granilarity,
                    isTimeSeries: res.is_time_series,
                    filter: res.filters
                })
                setisLoading(false)
            })
        }
    }, [])

    if (isLoading) return (
        <ChartWrapper width={props.width} padding={props.padding} margin = {props.margin}>
            <Spin />
        </ChartWrapper>
    )

    return (
        <>
            <QueryChart 
                title={title}
                width={props.width} 
                padding={props.padding} 
                margin = {props.margin}
                query={query}
                date={dateFilter}
                chartType={chartType}
                dateRangeValues={dateRangeValues}
                actionMenu={props.actionMenu}
            />
        </>
    )
}