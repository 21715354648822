import React from "react";
import { TIMELINE_TYPES } from "./Constants";
import Icon from "../components/common/icon-square";
import NegativeEmotionIcon from "../assets/common/round/sad.png";

export const getIcon = (category, link, event_name) => {
    if(event_name === "HS_SALESFORCE_CASE_PUSH" || event_name === "SF_PUSH_ACCOUNT_NOTES") {
        return <Icon type="salesforce" />;
    }
    else if(event_name === TIMELINE_TYPES.NEGATIVE_SENTIMENT) {
        return <img src={NegativeEmotionIcon} alt="" />;
      }
    else if(category === TIMELINE_TYPES.KNOWLEDGE) {
        link = link || "";
        let type = ""
        if (link.includes(".pdf")) {
            type = "pdf";
        } else if (link.includes(".doc")) {
            type = "word-doc";        
        } else if (link.includes("drive.")) {
            type = "drive";        
        }  else if (link.includes("zendesk")) {
            type = "zendesk";        
        } else if (link.includes("force.com")) {
            type = "salesforce";        
        } else if (link.includes("guru")) {
            type = "guru";
        } else {
            type = "web";
        }
        return <Icon type={type} />;
    }
    else {
        return <Icon type={category !==undefined? category : event_name} />;
    }
}