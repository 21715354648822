import React, { useState, useEffect } from "react";
import FilterComponent from "./FilterComponent";
import { message } from "antd";
import { useSelector } from "../../store";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

const MetricTagsFilter = ({
  value,
  handleChange,
  metricMultiSelect,
  width,
  style,
  disabled = false,
  ...rest
}) => {
  //const [metricTags, updateMetricTags] = useState([]);
  const [metricTag, updateMetricTag] = useState(value);
  const filtersSegmentList = useSelector(
    (state) => state.dashboard.filterSegments
  );

  useEffect(() => {
    updateMetricTag(value);
  }, [value]);

  return !metricMultiSelect ? (
    <FilterComponent
      width={width || 180}
      options={
        filtersSegmentList.metricTags?.length > 0
          ? filtersSegmentList.metricTags
              .filter((item) => item.title !== "")
              .map((item) => {
                return {
                  value: item.title.trim(),
                  label: item.title.trim(),
                };
              })
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          : []
      }
      placeholder="Metric Tags"
      value={metricTag}
      maxTagCount={1}
      showSearchIcon={true}
      capitalize={false}
      multiSelect={metricMultiSelect}
      handleChange={(value) => {
        updateMetricTag(value);
        handleChange(value, value);
      }}
      showSearch={true}
      hideClear={false}
      disabled={disabled}
      style={style}
      {...rest}
    />
  ) : (
    <CustomFilterComponent
      width={width || 180}
      options={
        filtersSegmentList.metricTags?.length > 0
          ? filtersSegmentList.metricTags
              .filter((item) => item.title !== "")
              .map((item) => {
                return {
                  value: item.title.trim(),
                  label: item.title.trim(),
                };
              })
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          : []
      }
      placeholder="Metric Tags"
      value={metricTag}
      maxTagCount={1}
      multiSelect={metricMultiSelect}
      handleChange={(value) => {
        updateMetricTag(value);
        handleChange(value, value);
      }}
      disabled={disabled}
      style={style}
      {...rest}
    />
  );
};

export default MetricTagsFilter;
