import { CALIBRATION_STATUS_TYPES } from "../../QaCalibration/config";
import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "./../../constants/ReduxActionConstants";


const initialState = {
  sessionDetails: {},
  calibrationMetrics: {
    calibrationScore: null,
    calibrationStatus: ""
  },
  localRoleList: []
};
const qaCalibrationReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_SESSION_DETAILS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      sessionDetails: action.payload
    };
  },
  [ReduxActionTypes.SET_CALIBRATION_SCORE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      calibrationMetrics: {
        ...state.calibrationMetrics,
        calibrationScore: action.payload
      }
    };
  },
  [ReduxActionTypes.SET_CALIBRATION_STATUS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      calibrationMetrics: {
        ...state.calibrationMetrics,
        calibrationStatus: action.payload
      }
    };
  },
  [ReduxActionTypes.SET_LOCAL_ROLES]: (
    state,
    action,
  ) => {
    return {
      ...state,
      localRoleList: action.payload
    };
  }
});

export default qaCalibrationReducer;
