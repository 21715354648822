import React from "react";
import GenericNode from "./genericNode";
import { ReactComponent as ActionIcon } from "../../assets/settings.svg";
import { StyledNodeHead } from "./styles";

const ActionNode = ({ data }) => {
  return (
    <GenericNode nodeData={data} nodeType="action">
      <StyledNodeHead>
        <div className="action">
          <ActionIcon />
        </div>
        {data.display_name}
      </StyledNodeHead>
    </GenericNode>
  );
};

export default ActionNode;
