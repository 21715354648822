import React from "react";
import { ReactComponent as UserIcon } from "./../../assets/setup/User.svg";
import { ReactComponent as FaqIcon } from "./../../assets/setup/Faq.svg";
import { ReactComponent as FlagIcon } from "./../../assets/setup/Flag.svg";
import { ReactComponent as IntentIcon } from "./../../assets/setup/Intent.svg";
import { ReactComponent as ResourceIcon } from "./../../assets/setup/Resource.svg";
import { ReactComponent as TipIcon } from "./../../assets/setup/Tip.svg";
import { ReactComponent as IntegrationIcon } from "./../../assets/setup/Integration.svg";
import { ReactComponent as MetricTagIcon } from "./../../assets/setup/MetricTag.svg";
import { ReactComponent as RubricBuilderIcon } from "./../../assets/setup/Rubric.svg";
import { ReactComponent as ConversationTagIcon } from "./../../assets/setup/ConversationTag.svg";
import { ReactComponent as WorkspaceIcon } from "./../../assets/setup/Workspace.svg";
import { ReactComponent as CategorizationIcon } from "./../../assets/setup/conversation-categorization.svg";
import { ReactComponent as ReportShareIcon } from "./../../assets/setup/ReportShare.svg";
import { ReactComponent as NotificationsIcon } from "./../../assets/setup/notifications.svg";
import { ReactComponent as ExternalDataIcon } from "./../../assets/setup/external-data-icon.svg";
import { ReactComponent as QaCaseAssignmentIcon } from "./../assets/qa-case-assignment.svg";

const SetupIcon = ({ icon }) => {
  switch (icon) {
    case "faq":
      return <FaqIcon />;
    case "flag":
      return <FlagIcon />;
    case "intent":
      return <IntentIcon />;
    case "resource":
      return <ResourceIcon />;
    case "tip":
      return <TipIcon />;
    case "integration":
      return <IntegrationIcon />;
    case "metric-tag":
      return <MetricTagIcon />;
    case "rubric":
      return <RubricBuilderIcon />;
    case "conversation-tag":
      return <ConversationTagIcon />;
    case "workspace":
      return <WorkspaceIcon />;
    case "categorization":
      return <CategorizationIcon />;
    case "report-share":
      return <ReportShareIcon />;
    case "external-data": 
      return <ExternalDataIcon/>;
    case "notifications":
      return <NotificationsIcon />;
    case "qa-case-assignment": 
      return <QaCaseAssignmentIcon />;
    default:
      return <UserIcon />;
  }
};

export default SetupIcon;
