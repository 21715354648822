import React from "react";
import SetupHeader from "../commons/SetupHeader";
import { LineSeparator, SetupWrapper } from "../commons/wrappers";
import { NotificationsConfiguration } from "../../utils/config";
import RolesStatus from "./RoleStatus/RolesStatus";

const NotificationsSettings = () => {
  return (
    <SetupWrapper>
      <SetupHeader
        title={NotificationsConfiguration.title}
        icon={NotificationsConfiguration.icon}
        description={NotificationsConfiguration.description}
      />
      <LineSeparator className="my-3" />
      <RolesStatus />
    </SetupWrapper>
  );
};

export default NotificationsSettings;
