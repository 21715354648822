import { getSchemaName } from "../../api/api"

export const Constants = {
    QA: {
        RAW_SCORE: "CFAnswer.raw_score",
        PERCENT_SCORE: "CFAnswer.percent_score",
        CATEGORY_SCORE: "CFDAnswer.categoryScore",
        SECTION_SCORE: "CFDAnswer.sectionScore",
        QUESTION_SCORE: "CFDAnswer.score",
        QUESTION_PERCENT: "CFDAnswer.questionScorePercent",
        OUTCOME: "CFAnswer.outcome"
    },
    CASE_COUNT: "LevelAsrAsrlogtocasemapping.count",
    PASS_RATE: "CFAnswer.pass_rate",
    AVERAGE_CSAT: "CSAT.csatScore",
    FOLLOWED_RATE: "MomentConversationTag.followed_rate",
    IS_RESOLVED: "LevelAsrAsrlogtocasemapping.isResolved",
    USER: {
        id: "AccountsUser.id"
    },
    CONVERSATION: {
        created: "LevelAsrAsrlog.created",
        count: "Conversation.count",
        unflaggedCount: "Conversation.unflaggedCount",
        flaggedCount: "Conversation.flaggedCount",
        callCount: "Conversation.callCount",
        chatCount: "Conversation.chatCount",
        smsCount: "Conversation.smsCount",
        emailCount: "Conversation.emailCount",
        slackCount: "Conversation.slackCount",
        channel: "LevelAsrAsrlog.channel"
    },
    QA_EVALUATIONS: {
        count: "QAEvaluations.count",
        callCount: "QAEvaluations.callCount",
        chatCount: "QAEvaluations.chatCount",
        smsCount: "QAEvaluations.smsCount",
        emailCount: "QAEvaluations.emailCount",
        slackCount: "QAEvaluations.slackCount",
    },
    CONVERSATION_TAG: {
        rule_id: "MomentConversationTag.rule_id"
    },
    METRICS: {
        AVERAGE_HANDLING_TIME: "Conversation.handling_time",
        AVERAGE_AGENT_REPLY_COUNT: "Metrics.average_agent_reply_count",
        AVERAGE_CUSTOMER_REPLY_COUNT: "Metrics.average_customer_reply_count",
        CUSTOMER_TO_AGENT_EMAIL_RATIO: "Metrics.customerToAgentEmailRatio",
        FIRST_RESPONSE_TIME: "Metrics.first_response_time",
        DEAD_AIR_RATIO: "Metrics.dead_air_ratio",
        LISTEN_TO_TALK_RATIO: "Metrics.listen_to_talk_ratio",
        AVERAGE_WORDS_PER_MINUTE: "Metrics.average_words_per_minute",
        AVERAGE_CASE_RESOLUTION_TIME: "Metrics.average_case_resolution_time",
        FLAGGED_RATE: "Metrics.flagged_rate",
        IS_FLAGGED: "Metrics.isFlagged",
    },
    MOST_FREQUENT_CONVERSATION_TAG: {
      MOST_TRIGGERED_TAG: "MFTags.expectationCount"
    }
}

export const addSchemaName = (parameter) => {
    return `${parameter}`
}
