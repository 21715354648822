import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
  } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import store from '../store';
import appConfigs from '../config';

const httpLink = createHttpLink({
    uri: appConfigs.HASURA_BASE_URL,
});

const getHasuraToken = () => {
    if (store.getState().app_user.hasura_jwt_exp < Date.now()) {
        return;
    }
    return store.getState().app_user.hasura_jwt
};
  
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getHasuraToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        }
    }
});
  
export const graphqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: true,
    cache: new InMemoryCache(),
    queryDeduplication: false
});
