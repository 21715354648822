import React, { useState, useEffect } from "react";
import { message } from "antd";
import FilterComponent from "./FilterComponent";
import { useSelector } from "../../store";

const StatusFilter = ({value, handleChange, caseStatusMultiSelect}) => {
    //const [statuses, updateStatuses] = useState([]);
    const [status, updateStatus] = useState(value);
    const [fetching, setFetching] = useState(true);
    const filtersSegmentList = useSelector(state => state.dashboard.filterSegments);

    useEffect(() => {
        updateStatus(value);
    }, [value]);
    return (
        <FilterComponent
        width={200}    
        options={filtersSegmentList.caseStatusList?.length > 0 ? [...new Set(filtersSegmentList.caseStatusList.filter((item)=>item.status !== "").map((item) => item.status.trim()).sort((a,b)=>a.toLowerCase().localeCompare(b.toLowerCase())))] : []}
        placeholder="All Case Statuses"
        value={status}
        maxTagCount= {1}
        multiSelect={caseStatusMultiSelect}
        showSearchIcon={true}
        handleChange={(value, option) => {
              if(value === undefined) {
                //getStatuses();
              }
              updateStatus(value);
              handleChange(value);
        }}
        showSearch={true}
        hideClear={false}
        loading={fetching}
    /> 
    );
}

export default StatusFilter;