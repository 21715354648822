import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Input, Button, Spin, message } from 'antd';
import AddButton from '../../../components/common/AddButton';
import ValidationInput from '../../../components/common/ValidationInput';
import { getInitialState, faqActions, faqReducer } from "./reducer";
import styled from "@emotion/styled/macro";
import { getFaqDetails, updateFaq, postFaq, verifyFaq } from '../../api/api';
import RemovableEntry from "./../RemovableEntry"
import { connect } from "react-redux";
import VerificationChip from "../../../components/chips/VerificationChip";
import CreatedDetails from "../commons/CreatedDetails";
import PopupMenu from "../commons/PopupMenu";
//import moment from "moment";
import { HorizontalButtonWrapper } from '../../../components/common/styled-components'
import AnnotatedPhrases from "../IntentPopup/AnnotatedPhrases";

const PhraseWrapper = styled.div`
    display: flex;
    margin-top: 6px;
    width: 100%;
`;

const PhraseInput = styled(Input)`
    width: calc(100% - 42px);    
    padding: 4px 10px;
    border: 1px solid #BDBDBD;
    &.ant-input[disabled] {
        color: #4f4f4f;
    }
`;

const RuleTitleWrapper = styled.div`
    display: flex;
    width: calc(100% - 48px);
    margin: 32px 24px;
    input {
        height: 47px;
        padding: 4px 10px;
        border: 1px solid #BDBDBD;
        font-size: 16px;
        color: #4F4F4F;
    }
`;

const ConditionTitleWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #4F4F4F;      
    margin-top: 16px;  
`;

const LineSeparator = styled.div`
    width: 100%;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
`;

const FaqPopup = styled.div`
    display: flex;
    width: 100%; 
    height: 591px;
`;

const PopupDetails = styled.div`
    display: ${props => props.loading ? "none" : "flex"};
    flex-direction: column;
    width: 100%;    
    height: 591px;
`;

const InnerDetailsWrapper = styled.div`
    display: ${props => !props.show ? "none" : "flex"};
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    height: 376px;   
    min-height: 376px;  
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 24px 0px;
    margin-bottom: 24px;
`;

const History  = styled.div`
    display: ${props => !props.show ? "none" : "flex"};
    padding: 32px 40px;
    width: 100%;
    justify-content: flex-start;
    min-height: 400px; 
    height: 400px;    
    overflow-y: auto;
    overflow-x: hidden;   
`;

const FaqPopupLoadingWrapper = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 400px; 
    height: 400px; 
    display: ${props => props.loading ? "flex" : "none"};
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px;
    .buttons {
        display: flex;
        align-items: center;
        button.submit, button.verify {
            margin-left: 4px;
        }
        button.verify {
            background: #27AE60;
            color: white;
            border: none;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px;
`;

const AnnotatedPhrasesWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
`;

const RTEWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    min-height: 300px;
    margin-top: 16px;
    .tox-tinymce {
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        width: 100%;
    }
`;

const FaqRule = (props) => {
    const [ ruleData , dispatch ] = useReducer(faqReducer, getInitialState());
    const [ isCollapsed, setIsCollapsed ] = useState(true);
    const editorInstance = useRef(null);
    useEffect(() => {
        if (props.isHistorical) {
            getFaqDetails(props.id).then((res) => {
                faqActions.setData({
                    title: res.title,
                    phrases: res.phrases,
                    answer: res.answer,
                    rule_audit: res.rule_audit,
                    annotated_phrases: res.annotated_phrases,
                }, dispatch);
                editorInstance.current && editorInstance.current.setContent(res.answer, { format: "html" });                
            })
        } else {
            initEditor();            
        }        
    }, [props.id, props.isHistorical, editorInstance]);

    useEffect(() => {
        initEditor();
    }, []);

    const onChange = (value) => {
        faqActions.setAnswer(value, dispatch);
    } 
    const initEditor = () => { 
        window.tinyMCE.init({
            height: "100%",
            selector: `#faq-rte`,
            menubar: false,
            branding: false,
            resize: false,
            init_instance_callback: (editor) => {
                faqActions.endLoading(dispatch);
                ruleData.answer && editor.setContent(ruleData.answer, { format: "html" });                
                editor.mode.set(props.viewOnly ? "readonly" : "design");
            },
            setup: (editor) => {
                editor
                    .on("Change", () => {
                        onChange(editor.getContent());
                    })
                    .on("Undo", () => {
                        onChange(editor.getContent());
                    })
                    .on("Redo", () => {
                        onChange(editor.getContent());
                    })
                    .on("KeyUp", () => {
                        onChange(editor.getContent());
                    });
                editorInstance.current = editor;
            },
            plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help",
            ],
            toolbar:
                "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
        });        
    };
    const handleChange = (e) => {
        switch(e.currentTarget.name) {
            case "title": 
                faqActions.setTitle(e.currentTarget.value, dispatch)
                break;
            case "phrase":
                faqActions.setPhrase(e.currentTarget.value, e.currentTarget.id, dispatch)
                break;
            case "answer":
                faqActions.setAnswer(e.currentTarget.value, dispatch)
                break;
            default: 
                break;
        }        
    }
    const removePhrase = (props) => {
        faqActions.removePhrase(props.index, dispatch);
    }
    const removeAnnotatedPhrase = (props) => {
        faqActions.removeAnnotatedPhrase(props.index, dispatch);
    }
    const addPhrase = () => {
        faqActions.addPhrase(dispatch);
    }
    const onVerify = () => {
        const data = validate();
        if(data) {
            if(props.id) {
                if(!ruleData.modified) {
                    verifyFaq(props.id).then((res) => {
                        removeInstance();
                        props.onSubmit();
                    })
                }
                else {
                    updateFaq(props.id, data).then((res) => {
                        verifyFaq(props.id).then((res) => {
                            removeInstance();
                            props.onSubmit();
                        })
                    });
                }
            } else {
                postFaq(data).then((res) => {
                    verifyFaq(res.id).then((res) => {
                        removeInstance();
                        props.onSubmit();
                    })
                });
            }
        }
    }
    const validate = () => {
        let validation = [];
        const { title, answer, phrases } = ruleData;
        if(title === "") {
            validation.push("title");
        }
        if(answer === "") {
            validation.push("answer");
        }
        if (phrases.length === 0) {
            validation.push("phrases");
        }
        phrases.forEach(phrase => {
            if (phrase === "") {
                validation.push("phrases");
            }
        });
        if (validation.includes("phrases")) {
            message.error("Please enter all phrases");
        }
        if (validation.length) {
            faqActions.setValidation(validation, dispatch);
            return null;      
        }
        else {
            const data = { title, answer, annotatedPhrases };
            data.phrases = phrases.map((item) => item.text);   
            data.annotated_phrases = annotatedPhrases.map((item) => item.text);
            return data;
        }
    }
    const removeInstance = () => {
        window.tinymce.remove('#faq-rte');
    }
    const onSubmit = () => {
        const data = validate();
        if(data) {
            if(props.id) {
                updateFaq(props.id, data).then((res) => {
                    removeInstance();
                    props.onSubmit();
                });
            } else {
                postFaq(data).then((res) => {
                    removeInstance();
                    props.onSubmit();
                });
            }
        }
    }

    const selectMenu = (menuItem) => {
        faqActions.selectMenu(menuItem, dispatch);
    }

    const { loading, title, validation, phrases, selectedMenuItem, rule_audit, annotatedPhrases } = ruleData;
    console.log({ annotatedPhrases });
    return (
        <FaqPopup>
            <PopupMenu disableHistory={!props.id} selectMenu={selectMenu} selectedMenuItem={selectedMenuItem}/>                
            <FaqPopupLoadingWrapper loading={loading}><Spin /></FaqPopupLoadingWrapper>
            <PopupDetails loading={loading}>
                <RuleTitleWrapper>
                    <ValidationInput 
                        placeholder="FAQ Name" 
                        name="title" 
                        onChange={handleChange} 
                        defaultValue={title}
                        value={title} 
                        error={validation} 
                        disabled={props.viewOnly}
                    />
                </RuleTitleWrapper>
                <LineSeparator />
                <InnerDetailsWrapper show={selectedMenuItem === "edit"}>
                    <ConditionTitleWrapper>Example Question</ConditionTitleWrapper>
                    {
                        phrases.slice(0, isCollapsed && props.isHistorical ? 3 : phrases.length).map((phrase, index) => (
                            <PhraseWrapper key={index}>
                                <RemovableEntry disabled={props.viewOnly} onRemove={removePhrase} index={index}>
                                    <PhraseInput 
                                        id={phrase.id} 
                                        placeholder="Add example phrase" 
                                        name="phrase"
                                        onChange={handleChange} 
                                        defaultValue={phrase.text}
                                        value={phrase.text} 
                                        disabled={props.viewOnly}
                                    />
                                </RemovableEntry>
                            </PhraseWrapper>
                        ))
                    }

                    {!props.viewOnly ? <HorizontalButtonWrapper>
                        <AddButton onClick={(e) => { addPhrase(e); setIsCollapsed(false);}}>Example question</AddButton>
                        {
                            phrases.length > 3 && props.isHistorical ?
                            (isCollapsed ?
                            <Button className="add-btn right" type="link" onClick={() => setIsCollapsed(false)}>See all {phrases.length} questions</Button>
                            :
                            <Button className="add-btn right" type="link" onClick={() => setIsCollapsed(true)}>Collapse</Button>)
                            :
                            null
                        }
                    </HorizontalButtonWrapper> : null}
                    {annotatedPhrases?.length ? <AnnotatedPhrasesWrapper>
                        <AnnotatedPhrases phrases={annotatedPhrases} removePhrase={removeAnnotatedPhrase} />
                    </AnnotatedPhrasesWrapper> : null}
                    <ConditionTitleWrapper>Answer</ConditionTitleWrapper>
                    <RTEWrapper>
                        <textarea disabled={props.viewOnly} id="faq-rte"></textarea>
                    </RTEWrapper>                    
                </InnerDetailsWrapper>
                <History show={selectedMenuItem === "history"}>
                    <CreatedDetails 
                        heading="Last Edited"
                        user={rule_audit?.modified_by}
                        created={rule_audit?.rule_modified_at || null}
                        isEdited
                    />
                    <CreatedDetails 
                        heading="Last Verified"
                        user={rule_audit?.verified_by}
                        created={rule_audit?.rule_verified_at || null}
                    />
                </History> 
                <LineSeparator />
                {!props.viewOnly ? <ActionButtonsWrapper>
                    <VerificationChip 
                        verified={rule_audit?.is_verified}
                        verifiedTime={rule_audit?.rule_verified_at || null}
                        verifiedBy={rule_audit?.verified_by}
                    />
                    <div className="buttons">
                        <Button style={{float: "right"}} onClick={() => {
                            removeInstance();
                            props.onCancel();
                        }}>Cancel</Button>                
                        <Button style={{float: "right"}} className="submit" onClick={onSubmit}>Save</Button>
                        {props.app_user.is_superuser && <Button style={{float: "right"}} className="verify" onClick={onVerify}>Save & Verify</Button>}
                    </div>
                </ActionButtonsWrapper> : <ButtonWrapper>
                    <Button className="submit" style={{float: "right"}} onClick={props.onCancel}>Close</Button>                       
                </ButtonWrapper>}            
            </PopupDetails>
        </FaqPopup>
    )
}

const mapStateToProps = state => ({ app_user: state.app_user });

export default connect(mapStateToProps)(FaqRule);