import React, { useState } from "react"
import { Input } from "antd";
import styled from "@emotion/styled/macro";
import TimelineItem from "../TimelineItem";
import { getSearchResults } from "../../api/api";
import { addAnalyticsEvent } from "../../utils";

const SearchResults = styled.div`
    padding: 0 15px 15px;
    max-height: calc(100vh - 200px);
    height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
    min-height: ${props => props.isAgent && "100vh"};
    display: ${props => props.hide && "none"};
`;

const StyledSearch = styled(Input.Search)`
    margin: 20px 0;
    width: 100%;
    height: 47px;
`;

const NoResultsMessage = styled.div`
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 14px;  
    color: #4F4F4F;
`;


const SearchTab = ({ show, conversationId, conversation_status }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [loading, toggleLoader] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const updateUserFeedback = (userFeedback, itemId) => {
        // console.log(userFeedback, itemId);
    }
    const onSearch = (value, e) => {
        toggleLoader(true);
        setSearchResults([]);
        if(conversation_status === "1") {
            addAnalyticsEvent('Live Conversation', { conversationId: conversationId, conversationStatus: conversation_status, type: "SHOW_SEARCH_RESULTS", value: value });
        }
        getSearchResults(value).then((res) => {
            const { matches } = res;
            const results = matches.map((item) => {
                return {
                    category: item.category.toUpperCase(),
                    title: item.title,
                    description: item.description,
                    timestamp: item.timestamps ? item.timestamps.median : 0,
                    link: item.link,
                    text: item.text,
                    user_feedback: item.user_feedback,
                    id: item.id,
                    created: item.created,
                    followed: item.followed,
                }
            });
            setSearchResults(results);
            if(results.length === 0) {
                setNoResults(true);
            }
            else {
                setNoResults(false);
            }
            toggleLoader(false);
        }, () => {
            toggleLoader(false);
        })
    }
    return (
        <SearchResults hide={!show} className="seach-tab-results">
            <StyledSearch onSearch={onSearch} placeholder="Search knowledge base..." loading={loading} />
            {searchResults.map((item, index) => (
                <TimelineItem 
                    {...item}
                    searchOnly 
                    updateUserFeedback={updateUserFeedback}
                />
            ))}
            {noResults && <NoResultsMessage>No Result Found</NoResultsMessage>}
        </SearchResults>
    )
}

export default SearchTab;