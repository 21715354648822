import { Modal } from "antd";
import styled from "@emotion/styled";

export const SelfServingCustomFiltersWrapper = styled.div`
  width: 100%;
  margin: 16px 0 22px 0;
  display: flex;
  justify-content: space-between;
`;

export const ModalWrapper = styled.div``;

export const AddFieldModal = styled(Modal)`
  & .ant-modal-header {
    border-bottom: none;
    & .ant-modal-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px !important;
      line-height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0015em;
      color: #000000;
    }
  }

  & .ant-modal-content {
    & .ant-modal-close {
      top: 5px;
    }
  }
`;
export const FieldWrapper = styled.div`
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 18px;
`;

export const DataSourceWrapper = styled.div``;

export const FieldSourceWrapper = styled.div``;

export const DisplayNameWrapper = styled.div``;

export const EnableFiltersWrapper = styled.div``;

export const LabelWrapper = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #828282;
`;

export const RadioItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #4f4f4f;
`;

export const DataSourceIcon = styled.div`
display: flex;
align-items: center;
margin-left: 25%;
column-gap: 5px;

`;

export const EmptySpan = styled.span`
color: #bfbfbf;
`;
