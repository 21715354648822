import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Radio } from 'antd';

const ExpectationsPropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .radio-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px; 
        label {
            font-size: 12px;
        } 
        .label {
            margin-right: 12px;
        }
    }
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const ExpectationsProperty = (props) => {
    const [expectation, setExpectation] = React.useState(props?.ruleProperties?.expectation_type);
    const [showValidations, toggleShowValidations] = React.useState(false);
    const handleChange = (event) => {
        const value = event.target.value;
        setExpectation(value);
        props.handleChange({
            type: value,
            label: props.options.find((item) => item.value === value).label,
        });
    }
    useEffect(() => {
        toggleShowValidations(props.showValidations);
    }, [props.showValidations])
    return (
        <ExpectationsPropertyWrapper>
            <Radio.Group className="radio-group" name="radiogroup" defaultValue={expectation} onChange={handleChange}>
                {props.options.map((item) => {
                    return <Radio value={item.value}>{item.label}</Radio>
                })}                
            </Radio.Group>
            {showValidations && !expectation && <div className="error-message">Please choose expectation type</div>}
        </ExpectationsPropertyWrapper>
    )
}

export default ExpectationsProperty;