import React, { useState } from "react";
import FilterComponent from "./FilterComponent";
import { message } from "antd";
import { useSelector } from "../../store";
import CustomFilterComponent from "./CustomFilterComponent/CustomFilterComponent";

const OrganizationFilter = ({
  value,
  handleChange,
  organizationMultiSelect,
  width,
  style = {},
  disabled = false,
  ...rest
}) => {
  //const [organizations, updateOrganizations] = useState([]);
  const [organization, updateOrganization] = useState(value);
  const filtersSegmentList = useSelector(
    (state) => state.dashboard.filterSegments
  );

  return !organizationMultiSelect ? (
    <FilterComponent
      width={width || 200}
      placeholder="All Organizations"
      value={organization}
      maxTagCount={1}
      showSearchIcon={true}
      capitalize={false}
      multiSelect={organizationMultiSelect}
      handleChange={(value) => {
        updateOrganization(value);
        handleChange(value);
      }}
      options={
        filtersSegmentList.organizations?.length > 0
          ? [
              ...new Set(
                filtersSegmentList.organizations
                  .filter((item) => item.name !== "")
                  .map((item) => item.name.trim())
                  .sort((a, b) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                  )
              ),
            ]
          : []
      }
      showSearch={true}
      hideClear={false}
      disabled={disabled}
      style={style}
      {...rest}
    />
  ) : (
    <CustomFilterComponent
      width={width || 200}
      placeholder="All Organizations"
      value={organization}
      maxTagCount={1}
      multiSelect={organizationMultiSelect}
      handleChange={(value) => {
        updateOrganization(value);
        handleChange(value);
      }}
      options={
        filtersSegmentList.organizations?.length > 0
          ? [
              ...new Set(
                filtersSegmentList.organizations
                  .filter((item) => item.name !== "")
                  .map((item) => item.name.trim())
                  .sort((a, b) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                  )
              ),
            ]
          : []
      }
      disabled={disabled}
      style={style}
      {...rest}
    />
  );
};

export default OrganizationFilter;
