import React from 'react';
import SearchComponent from "../components/common/SearchComponent";
import AgentsFilter from "../components/common/AgentsFilter";
import AgentsTypeFilter from "../components/common/AgentsTypeFilter";
import ConversationTagFilter from "../components/common/ConversationTagFilter";
import TopicsFilter from "../components/common/TopicsFilter";
import MetricTagsFilter from "../components/common/MetricTagsFilter";
import { ChannelFilter } from "../components/common/ChannelFilter";
import OrganizationFilter from "../components/common/OrganizationFilter";
import InstaReviewFilter from "../components/common/InstaReviewFilter";
import QuestionOptionsFilter from "../components/common/QuestionOptionsFilter";
import QuestionFilter from "../components/common/QuestionFilter";
import SectionFilter from "../components/common/SectionFilter";
import QACategory from '../components/common/QACategory';
import NegativeSentiments from '../components/common/NegativeSentiments';
import CampaignFilter from '../components/common/CampaignFilter';
import SiteFilter from '../components/common/SiteFilter';
import QAAuditorsFilter from '../components/common/QAAuditorsFilter';

// FETCH DATA FROM API OR GET FROM REDUX
const MEASURE_OPTIONS = [
    {
        key: "RAW_SCORE",
        value: "CFAnswer.raw_score",
        label: "QA Score"
    },
    {   
        key: "PERCENT_SCORE",
        value: "CFAnswer.percent_score",
        label: "QA Score (%)"
    },
    {
        key: "AUTO_INSTASCORE",
        value: "AutoScore.auto_instascore",
        label: "Average Auto Instascore"
    },
    {
        key: "EVALUATIONS",
        value: "QAEvaluations.count",
        label: "QA Evaluations"
    },
    {
        key: "CATEGORY_SCORE",
        value: "CFDAnswer.categoryScore",
        label: "Category Score"
    },
    {
        key: "SECTION_SCORE",
        value: "CFDAnswer.sectionScore",
        label: "Section Score"
    },
    {
        key: "QUESTION_SCORE",
        value: "CFDAnswer.score",
        label: "Question Score"
    },
    {
        key: "QUESTION_PERCENT",
        value: "CFDAnswer.questionScorePercent",
        label: "Question Score (%)"
    },
    {
        key: "AVERAGE_HANDLING_TIME",
        value: "Conversation.handling_time",
        label: "Average Handling Time"
    },
    {
        key: "CONVERSATION_COUNT",
        value: "Conversation.count",
        label: "Total Conversations"
    },
    {
        key: "CASE_COUNT",
        value: "LevelAsrAsrlogtocasemapping.count",
        label: "No of Cases"
    },
    {
        key: "AVERAGE_CSAT",
        value: "CSAT.csatScore",
        label: "Average CSAT Score"
    },
    {
        key: "AVERAGE_AGENT_REPLY_COUNT",
        value: "Metrics.average_agent_reply_count",
        label: "Average Agent Reply Count"
    },
    {
        key: "AVERAGE_CUSTOMER_REPLY_COUNT",
        value: "Metrics.average_customer_reply_count",
        label: "Average Customer Reply Count"
    },
    {
        key: "CUSTOMER_TO_AGENT_EMAIL_RATIO",
        value: "Metrics.customerToAgentEmailRatio",
        label: "Customer to Agent Emails Ratio"
    },
    {
        key: "FIRST_RESPONSE_TIME",
        value: "Metrics.first_response_time",
        label: "Average First Response Time"
    },
    {
        key: "DEAD_AIR_RATIO",
        value: "Metrics.dead_air_ratio",
        label: "Dead Air Ratio"
    },
    {
        key: "LISTEN_TO_TALK_RATIO",
        value: "Metrics.listen_to_talk_ratio",
        label: "Listen to Talk Ratio"
    },
    {
        key: "AVERAGE_WORDS_PER_MINUTE",
        value: "Metrics.average_words_per_minute",
        label: "Talk Speed"
    },
    {
        key: "AVERAGE_CASE_RESOLUTION_TIME",
        value: "Metrics.average_case_resolution_time",
        label: "Average Case Resolution Time"
    },
    {
        key: "FOLLOWED_RATE",
        value: "MomentConversationTag.followed_rate",
        label: "Conversation Tag Follow Rate (%)"
    },
    {
        key: "FLAGGED_RATE",
        value: "Metrics.flagged_rate",
        label: "Conversation Flag Rate (%)"
    }
];

const GROUPBY_OPTIONS = [
    {
        key: "AGENT",
        value: "AccountsUser.id",
        label: "Agents",
        drilldown: true
    },
    {
        key: "CHANNEL",
        value: "LevelAsrAsrlog.channel",
        label: "Channels",
        drilldown: true
    },
    {
        key: "OUTCOME",
        value: "CFAnswer.outcome",
        label: "QA Outcome",
        drilldown: false
    },
    {
        key: "QA_CATEGORY",
        value: "CFDAnswer.category",
        label: "QA Category",
        drilldown: false
    },
    {
        key: "SECTION",
        value: "CFDAnswer.section",
        label: "Section",
        drilldown: false
    },
    {
        key: "CATEGORY",
        value: "LevelNlpIntenttopics.topics",
        label: "Categories",
        drilldown: true
    },
    {
        key: "RESOLVED",
        value: "LevelAsrAsrlogtocasemapping.isResolved",
        label: "Resolved",
        drilldown: true
    },
    {
        key: "IS_FLAGGED",
        value: "Metrics.isFlagged",
        label: "Flagged",
        drilldown: true
    },
    {
        key: "CONVERSATION_TAG_ID",
        value: "MomentConversationTag.rule_id",
        label: "Conversation Tag",
        drilldown: true
    },
    {
        key: "METRIC_RULE",
        value: "MomentMetricTag.title",
        label: "Metric Tag",
        drilldown: true
    },
    {
        key: "NEGATIVE_SENTIMENT",
        value: "MomentNegativeSentiment.title",
        label: "Negative Sentiment",
        drilldown: false
    },
    {
        key: "ORGANIZATION",
        value: "Organization.name",
        label: "Organization",
        drilldown: true
    },
    {
        key: "TAGS_TITLE",
        value: "MFTags.title",
        label: "Tags Title",
        drilldown: true
    },
    {
        key: "TOPICS_TOPICS",
        value: "MFTopics.topics",
        label: "Topics",
        drilldown: true
    },
    {
        key: "QUESTION",
        value: "CFDAnswer.question",
        label: "Question",
        drilldown: true
    },
    {
        key: "SELECTED_OPTION",
        value: "CFDAnswer.selectedOption",
        label: "Selected option",
        drilldown: true
    },
    {
        key: "QA_AUDITOR",
        value: "QAAuditor.qa_auditor_id",
        label: "QA Auditor",
        drilldown: true
    },
    {
        key: "CAMPAIGN",
        value: "AccountsUser.campaign",
        label: "Campaign",
        drilldown: false
    },
];

const CUSTOM_GROUPBY_OPTIONS = [
    {
        key: "AGENT_INFO",
        field_name: "Agent Type",
        value: "CustomColumns.Agent_Type",
        label: "Agent info",
        drilldown: true
    },
    {
        key: "MANAGERS",
        field_name: "Manager",
        value: "CustomColumns.Manager",
        label: "Managers",
        drilldown: true
    },
    {
        key: "CALL_CENTER_NAME",
        field_name: "call_center_name",
        value: "CustomColumns.call_center_name",
        label: "Call center name",
        drilldown: true
    },
    {
        key: "CALL_TYPE",
        field_name: "call_type",
        value: "CustomColumns.call_type",
        label: "Call type",
        drilldown: true
    },
]

/**
 * CUSTOM FILTERS
 *      Put object of filter in following array, as according to keys,it will added to the system.
 *      If filter requires more complex logic, need to do that in /query-builder/filters.js, and put it in filters skew 
 *      and logic under SPECIFIC FILTERS APPLY. Example - converstaion_tag, insta_review
 */
const FILTERS_OPTIONS = [
    {
        key: "agents",
        value: "AccountsUser.id",
        label: "Agents",
        renderComponent: (props) => <AgentsFilter agentMultiSelect {...props} />
    },
    {
        key: "category",
        value: "LevelNlpIntenttopics.topics",
        label: "Category",
        renderComponent: (props) => <TopicsFilter {...props} />
    },
    {
        key: "metric_rule",
        value: "MomentMetricTag.title",
        label: "Metric Tags",
        renderComponent: (props) => <MetricTagsFilter {...props} />
    },
    {
        key: "conversation_tag_id",
        value: "LevelAsrAsrlog.conversationTags",
        label: "Conversation Tags",
        renderComponent: (props) => <ConversationTagFilter {...props} />
    },
    {
        key: "channel",
        value: "LevelAsrAsrlog.channel",
        label: "Channel",
        renderComponent: (props) => <ChannelFilter {...props} />
    },
    {
        key: "transcript",
        value: "LevelAsrAsrTranscriptionLog.text_search_vector",
        label: "Search Transcript",
        renderComponent: (props) => 
          <SearchComponent
            handleSearchValue={props.handleChange}
            {...props}
            placeholder="Search Transcript"
          />
    },
    {
        key: "organization",
        value: "Organization.name",
        label: "Organization",
        renderComponent: (props) => <OrganizationFilter {...props} />
    },
    {
        key: "insta_review",
        label: "Insta Review",
        value: "",
        renderComponent: (props) => <InstaReviewFilter {...props} />
    },
    {
        key: "selected_option",
        value: "CFDAnswer.selectedOption",
        label: "Selected Option",
        renderComponent: (props) => <QuestionOptionsFilter {...props}/>
    },
    {
        key: "agent_type",
        value: "AccountsUser.agent_type",
        label: "Agent Type",
        renderComponent: (props) => <AgentsTypeFilter {...props}/>
    },
    {
        key: "question",
        value: "CFDAnswer.question",
        label: "Question",
        renderComponent: (props) => <QuestionFilter {...props}/>
    },
    {
        key: "sections",
        value: "CFDAnswer.section",
        label: "Section",
        renderComponent: (props) => <SectionFilter {...props}/>
    },
    {
        key: "qa_category",
        value: "CFDAnswer.category",
        label: "QA category",
        renderComponent: (props) => <QACategory {...props}/>
    },
    {
        key: "negative_sentiments",
        value: "MomentNegativeSentiment.isNegativeSentiment",
        label: "Negative Sentiments",
        renderComponent: (props) => <NegativeSentiments {...props}/>
    },
    {
        key: "campaign",
        value: "AccountsUser.campaign",
        label: "Campaign",
        renderComponent: (props) => <CampaignFilter {...props}/>
    },
    {
        key: "location",
        value: "AccountsUser.location",
        label: "Site",
        renderComponent: (props) => <SiteFilter {...props}/>
    },
    {
        key: "qa_auditors",
        value: "QAAuditor.qa_auditor_id",
        label: "QA auditor",
        renderComponent: (props) => <QAAuditorsFilter multiSelect {...props}/>
    }
];

const DRILLDOWN_OPTINS = [
    {
        key: "USER",
        value: "user"
    },
    {
        key: "DATE",
        value: "date"
    },
    {
        key: "ORGANIZATION",
        value: "organization"
    }
]

// GENERATORS
const generateMeasureOptions = () => {
    const measureOptions = {};

    MEASURE_OPTIONS.forEach((option, index) => {
        if (!measureOptions[option.key]) {
            measureOptions[option.key] = option.value;
        }
    });

    return measureOptions;
};

const generateGroupByOptions = () => {
    let groupByOptions = {};
    let GROUPBY = [...GROUPBY_OPTIONS, ...CUSTOM_GROUPBY_OPTIONS];

    GROUPBY.forEach((option, index) => {
        if(!groupByOptions[option.key]) {
            groupByOptions[option.key] = option.value
        }
    });

    return groupByOptions;
};

const generateCustomGroupByOptions = () => {
    let groupByOptions = {};

    CUSTOM_GROUPBY_OPTIONS.forEach((option, index) => {
        if(!groupByOptions[option.key]) {
            groupByOptions[option.key] = option.value
        }
    });

    return groupByOptions;
}

const generateGroupBy = () => {
    let groupBy = {};
    let GROUPBY = [...GROUPBY_OPTIONS, ...CUSTOM_GROUPBY_OPTIONS];

    GROUPBY.forEach((option, index) => {
        if(!groupBy[option.key]) {
            groupBy[option.key] = {
                label: option.label,
                drilldown: option.drilldown
            }
        }
    });

    return groupBy;
}

const generateGroupbyList = () => {
    let GROUPBY = [...GROUPBY_OPTIONS, ...CUSTOM_GROUPBY_OPTIONS];
    return GROUPBY.map(option => option.value);
}

const generateFilterOptions = () => {
    const filterOptions = {};

    FILTERS_OPTIONS.forEach((option, index) => {
        if (!filterOptions[option.key]) {
            filterOptions[option.key] = option.value;
        }
    });

    return filterOptions;
}

const generateDrilldownOptions = () => {
    let drilldown = {};

    let groupByDrilldown = {
        ...Object.keys(generateGroupByOptions()).reduce((acc, key) => {
            acc[key] = key.toLowerCase();
            return acc;
          }, {})
    };

    DRILLDOWN_OPTINS.forEach((option, index) => {
        if(!drilldown[option.key]) {
            drilldown[option.key] = option.value
        }
    });

    return {
        ...drilldown,
        ...groupByDrilldown
    }
}

// MAPS
const measureOptions = {
    ...generateMeasureOptions()
};

const queryBuilderMeasureOptions = {
    ...generateMeasureOptions()
};

const groupByOptions = {
    ...generateGroupByOptions()
};

const customGroupByOptions = {
    ...generateCustomGroupByOptions()
};

const groupBy = {
    ...generateGroupBy()
}

const groupByList = [
    ...generateGroupbyList()
]

const filterOptions = {
    ...generateFilterOptions()
}

const drilldownByOptions = {
    ...generateDrilldownOptions()
}

// EXPORTS
export const getSelectMeasureOptions = () => {
    MEASURE_OPTIONS.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (b.label > a.label) {
          return -1;
        }
        return 0;
      }
    );

    return MEASURE_OPTIONS;
};

export const getSelectGroupByOptions = () => {
    GROUPBY_OPTIONS.sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (b.label > a.label) {
            return -1;
        }

        return 0;
    });

    return GROUPBY_OPTIONS;
}

export const getSelectFilterOptions = () => {
    FILTERS_OPTIONS.sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (b.label > a.label) {
            return -1;
        }

        return 0;
    });

    return FILTERS_OPTIONS;
}

const queryConfigs = {
    MEASURE_OPTIONS,
    GROUPBY_OPTIONS,
    CUSTOM_GROUPBY_OPTIONS,
    FILTERS_OPTIONS,
    measureOptions,
    queryBuilderMeasureOptions,
    groupByOptions,
    customGroupByOptions,
    groupBy,
    drilldownByOptions,
    filterOptions,
    groupByList
}

export { queryConfigs };
