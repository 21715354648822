import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { ReactComponent as PositiveIcon } from "./../../assets/positive-icon.svg";
import {HeapAnalyticsEvent} from '../common/HeapAnalyticsEvent';

const SelectableScoreCardWrapper = styled.div`
    background: #FFFFFF;
    display: flex;
    width: 33.33%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100/3%);
    min-width: calc(100/3%);
    padding: 15px 24px;
    height: 123px;
    cursor: pointer;    
    border-bottom: ${props => props.selected ? "1px solid #0B4B93" : "none"};
`;

const SelectableScoreCardTitle = styled.div`
    color: ${props => props.selected ? "#4F4F4F" : "#BDBDBD"};
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    transition: color 0.3s;
`;

const SelectableScoreCardValue = styled.div`
    color: #585858;
    font-weight: ${props => props.selected ? "500" : "300"};
    font-size: 24px;
    line-height: 28px;
    display: flex;
    margin-top: 10px;
    transition: font-weight 0.3s;
    span {
        font-weight: 300;
        color: #8B8B8D;
        font-size: 14px;
        top: 4px;
        left: 10px;
        position: relative;
    }
`;

const SelectableScoreImprovement = styled.div`
    display: ${props => props.showDetails ? "flex" : "none"};
    align-items: center;
    margin-top: 13px;
`;

const IconWrapper = styled.div`
    svg {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
    }
`;

const SelectableScoreImprovementValue  = styled.div`
    color: #8B8B8D;
    font-size: 16px;
    line-height: 19px;    
`;

const SelectableScoreCard = (props) => {
    const [selected, updateSelection] = useState(props.selected);
    const changeSelection = () => {
        props.addAnalyticsEvent(HeapAnalyticsEvent.Switch_Metric,{ metric_name : props.title});
        if(!selected && props.selectable) {
            updateSelection(selected);
            props.updateSelection();
        }
    }
    useEffect(() => {
        updateSelection(props.selected);
    }, [props.selected])
    return (
        <SelectableScoreCardWrapper width={props.width} onClick={changeSelection} selected={selected}>
            <SelectableScoreCardTitle selected={selected}>{props.title}</SelectableScoreCardTitle>
            <SelectableScoreCardValue selected={selected}>{props.scoreValue}<span>{props.goalScore}</span></SelectableScoreCardValue>
            <SelectableScoreImprovement showDetails={props.showDetails}>
                <IconWrapper isImproved={props.isImproved}>
                    <PositiveIcon />
                </IconWrapper>
                <SelectableScoreImprovementValue>{props.differenceValue}</SelectableScoreImprovementValue>
            </SelectableScoreImprovement>            
        </SelectableScoreCardWrapper>
    );
}

export default SelectableScoreCard;