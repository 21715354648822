import React, { useReducer, useState, useEffect } from "react";
import DynamicChartRenderer from "./dynamic-chart";
import DynamicMultiChartRenderer from "./dynamic-multi-chart";
import {
  ChartSettingsWrapper,
  ChartTypeToggleWrapper,
  SelectWrapper,
} from "./components/wrapper";
import { Select } from "antd";
import { queryOptions } from "./dynamic-query-builder";
import { ReactComponent as BarSelected } from "./assets/bar-icon-selected.svg";
import { ReactComponent as BarUnselected } from "./assets/bar-icon-unselected.svg";
import { ReactComponent as LineUnselected } from "./assets/line-icon-unselected.svg";
import { ReactComponent as LineSelected } from "./assets/line-icon-selected.svg";
import {ReactComponent as TableSelected} from "./assets/table-selected.svg";
import { ReactComponent as TableUnselected } from "./assets/table-unselected.svg"; 
import { TimeSeriesSelect } from "./components/time-series-select";
import { addAnalyticsEvent } from "../../src/utils";
import { HeapAnalyticsEvent } from "../components/common/HeapAnalyticsEvent";
import { decideAnalyticsObj } from "./utils";
import { useSelector } from "../store";

const filterReducer = (filters, action) => {
  if (action.type === queryOptions.filterByOptions.AGENT) {
    return {
      ...filters,
      agents: action.payload,
    };
  }

  if (action.type === queryOptions.filterByOptions.CATEGORY) {
    return {
      ...filters,
      categories: action.payload,
    };
  }

  if (action.type === queryOptions.filterByOptions.SECTION) {
    return {
      ...filters,
      sections: action.payload,
    };
  }

  if (action.type === queryOptions.filterByOptions.TOPICS) {
    return {
      ...filters,
      topics: action.payload,
    };
  }
};

const LocalFilter = (props) => {
  const { filterBy } = props;
  const { filterByOptions } = queryOptions;
  const filterByParser = {
    [filterByOptions.CATEGORY]: { key: "categories", label: "Category" },
    [filterByOptions.AGENT]: { key: "agents", label: "Agent" },
    [filterByOptions.TOPICS]: { key: "topics", label: "Topic" },
    [filterByOptions.SECTION]: { key: "sections", label: "Section" },
  };
  const segments = useSelector((state) => state.dashboard.segments);

  return (
    <>
      {filterBy &&
      filterBy !== filterByOptions.NONE &&
      segments &&
      segments[filterByParser[filterBy].key] ? (
        <Select
          style={{ width: 200, paddingRight: 20 }}
          placeholder={"Select " + filterByParser[filterBy].label}
          onChange={props.handleFilterChange}
        >
          {segments[filterByParser[filterBy].key]?.map((option) => {
            const filterByLabel = filterByParser[filterBy].label.toLowerCase();
            const { value, label } =
              typeof option !== "object"
                ? { value: option, label: option }
                : {
                    value: option[filterByLabel],
                    label: option[filterByLabel],
                  };
            return <Select.Option value={value}>{value}</Select.Option>;
          })}
        </Select>
      ) : null}
    </>
  );
};

export const ChartTypeToggle = (props) => {
  const [chartType, setChartType] = useState(props.chartType);
  const [isCompareDateRange, setIsCompareDateRange] = useState();
  const { isFixedToInterval } = props;

  useEffect(() => {
    setChartType(props.chartType);
  }, [props.chartType]);

  useEffect(() => {
    setIsCompareDateRange(props.isCompareDateRange);
  }, [props.isCompareDateRange]);

  useEffect(() => {
    if (isFixedToInterval) {
      setChartType("interval");
    } else {
      setChartType(props.chartType);
    }
  }, [isFixedToInterval]);

  return (
    <ChartTypeToggleWrapper>
      {
        chartType === "line" && (
          <>
            <BarUnselected
              onClick={() => {
                props.onChange("interval");
                setChartType("interval");
              }}
            ></BarUnselected>

            {
              !isCompareDateRange && 
                <LineSelected></LineSelected>
            }
            
            <TableUnselected onClick={() => {
                props.onChange("table");
                setChartType("table");
              }} 
            />
          </>
        )
      } 
      {
        chartType === "interval" && (
          <>
            <BarSelected></BarSelected>
            {!isFixedToInterval && !isCompareDateRange && (
              <LineUnselected
                onClick={() => {
                  props.onChange("line");
                  setChartType("line");
                }}
              ></LineUnselected>
            )}
            <TableUnselected onClick={() => {
                props.onChange("table");
                setChartType("table");
              }} 
            />
          </>
        )
      }
      {
        chartType === "table" && (
          <>
            <BarUnselected
              onClick={() => {
                props.onChange("interval");
                setChartType("interval");
              }}
            ></BarUnselected>
            {!isFixedToInterval && !isCompareDateRange && (
              <LineUnselected
                onClick={() => {
                  props.onChange("line");
                  setChartType("line");
                }}
              ></LineUnselected>
            )}
            <TableSelected />
          </>
        )
      }
    </ChartTypeToggleWrapper>
  );
};

export const Chart = (props) => {
  const [chartType, setChartType] = useState(props.chartType);
  const { filterBy, allowGrouping, isChartTypeEditable } = props;
  const [filters, dispatch] = useReducer(filterReducer, {});
  const [groupBy, setGroupBy] = useState(props.query.groupBy);
  const [measures, setMeasures] = useState(props.query.measures);
  const [isCompareDateRange, setIsCompareDateRange] = useState(props.isCompareDateRange);
  const [sortBy, setSortBy] = useState(queryOptions.sortByOptions.TOP_50.key);
  const [isTimeSeries, setIsTimeSeries] = useState(props.query.isTimeSeries);
  const [isFixedToInterval, setisFixedToInterval] = useState(false);
  const [timeDimension, setTimeDimension] = useState(props.query.timeDimension);

  useEffect(() => {
    setSortBy(queryOptions.sortByOptions.TOP_50.key);
  }, [props.filters]);

  useEffect(() => {
    console.log(props.isTimeSeries);

    setIsTimeSeries(props.isTimeSeries);

    if(props.isTimeSeries) {
      setChartType("line");
    } else {
      setChartType("interval");
    }
  }, [props.isTimeSeries])

  const handleFilterChange = (e) => {
    let analyticsObj = decideAnalyticsObj(props.filterBy, e);
    if (Object.keys(analyticsObj).length > 0) {
      addAnalyticsEvent(`${analyticsObj.event}`, {
        filterValue: analyticsObj.value,
        type: analyticsObj.type,
      });
    }
    dispatch({
      type: props.filterBy,
      payload: [e],
    });
  };

  const handleGroupBy = (selection) => {
    setGroupBy(selection);
  };

  const handleSortingChange = (sortBy) => {
    addAnalyticsEvent(HeapAnalyticsEvent.Sort_Agents_By, {
      sort_Agents_By: sortBy === "asc" ? "Bottom 50" : "Top 50",
    });
    setSortBy(sortBy);
  };

  useEffect(() => {
    if (props.isConsolidated) {
      setIsTimeSeries(false);
    }
  }, [props.isConsolidated]);

  useEffect(() => {
    setIsTimeSeries(props.query.isTimeSeries);

    if(props.query.isTimeSeries) {
      setChartType("line");
    } else {
      setChartType("interval");
    }
  }, [props.query.isTimeSeries]);

  useEffect(() => {
    setChartType(props.chartType);
  }, [props.chartType]);

  useEffect(() => {
    setGroupBy(props.query.groupBy);
    setTimeDimension(props.query.timeDimension);
  }, [props]);

  return (
    <>
      <ChartSettingsWrapper>
        <p>{props.title}</p>
        
        <SelectWrapper>
          <LocalFilter
            segments={props.segments}
            filterBy={filterBy}
            handleFilterChange={handleFilterChange}
          />

          {!isTimeSeries ? (
            <Select
              style={{ width: 120 }}
              onChange={handleSortingChange}
              value={sortBy}
            >
              {Object.keys(queryOptions.sortByOptions).map((key) => (
                <Select.Option value={queryOptions.sortByOptions[key].key}>
                  {queryOptions.sortByOptions[key].label}
                </Select.Option>
              ))}
            </Select>
          ) : null}

          {allowGrouping ? (
            <Select
              style={{ width: 200 }}
              placeholder={"Group By"}
              onChange={handleGroupBy}
            >
              {Object.keys(queryOptions.groupByOptions).map((key) => (
                <Select.Option value={key}>
                  {queryOptions.groupByOptions[key]}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          {props.query.timeSeriesSelectable ? (
            <TimeSeriesSelect
              {...props}
              value={isTimeSeries}
              onChange={(value) => {
                setIsTimeSeries(value);
              }}
            />
          ) : null}

          {isChartTypeEditable ? (
            <ChartTypeToggle
              isFixedToInterval={isFixedToInterval}
              chartType={chartType}
              onChange={(val) => {
                addAnalyticsEvent(HeapAnalyticsEvent.Detect_Chart, {
                  chartType: val,
                });
                setChartType(val);
              }}
              isTimeSeries={isTimeSeries}
              isCompareDateRange={props.isCompareDateRange}
            />
          ) : null}

          {props.actionMenu ? (
            <props.actionMenu chartTitle={props.title} />
          ) : null}
        </SelectWrapper>
      </ChartSettingsWrapper>

      {
        (Array.isArray(props.query.measure) && props.query.measure.length > 1) || (Array.isArray(props.query.groupBy) && props.query.groupBy.length > 1) || props.isCompareDateRange ?
          <DynamicMultiChartRenderer
            {...props}
            query={{ ...props.query, isTimeSeries }}
            ordering={sortBy}
            chartType={isFixedToInterval ? "interval" : chartType}
            filters={filters}
            groupBy={groupBy}
            timeDimension={timeDimension}
            fixIntervalChart={(e) => setisFixedToInterval(e)}
            isCompareDateRange={props.isCompareDateRange}
          />
        :
          <DynamicChartRenderer
            {...props}
            query={{ ...props.query, isTimeSeries }}
            ordering={sortBy}
            chartType={isFixedToInterval ? "interval" : chartType}
            filters={filters}
            groupBy={Array.isArray(groupBy) ? groupBy[0] : groupBy}
            timeDimension={timeDimension}
            fixIntervalChart={(e) => setisFixedToInterval(e)}
          />
      }
      
    </>
  );
};
