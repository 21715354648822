import { getSalesforceUrl, is_sandbox_enabled } from "./salesforceConfig";

const salesforceUrl = getSalesforceUrl();

const zendeskUrl = (subdomainValue) => `https://${subdomainValue}.zendesk.com/`;

export default {
  SALESFORCE: {
    CLIENT_ID:
      "3MVG9n_HvETGhr3DNxJDn9EiOPg1Ghut18falp0wqUCCj7Fy_n7HXNi0XoKuY5SRufs1FMjUZAg==",
    URL: salesforceUrl,
    IS_SANDBOX: is_sandbox_enabled(),
    SCOPE: "refresh_token%20api%20full",
  },
  ZENDESK: {
    CLIENT_ID: "zdg-level_ai_agent_assist_app",
    URL: zendeskUrl,
    SCOPE: "read",
  },
  RING_CENTRAL: {
    IS_ENABLED: true,
  },
  CONVERSATION_SEARCH: {
    showConversationSearchInFilter: true,
    showConversationTranscriptSearch: true,
  },
  SHOW_ORGANIZATION_FILTER: window.location.hostname.includes("bpo"),
  //DEFAULT_CHANNEL : window.location.hostname.includes("andromeda") ? "2" : "", //default channel for andromeda is removed for now(release for 14 july 2021)
  DEFAULT_CHANNEL: "",
  DEFAULT_DATE_FILTER: window.location.hostname.includes("bpo") ? "" : "30",
  SHOW_TIP_FOLLOW: true,
  versionNumber: "v2",
  HEAP_APPLICATION_ID: "3361627701",
  OKTA_BASE_URL: "https://dev-7105105.okta.com/oauth2/default/v1/authorize",
  OKTA_CLIENT_ID: "0oajcbcrpwOeOx6gD5d5",
  SHOW_RESOURCE_FOLLOW: false,
  showIntentConflict: true,
  showWorkspaceTab: true,
  enablePlaygroundUI: true,
  enableMetricTagFilter: true,
  CUBEJS_BASE_URL: "http://34.127.87.26:4000/cubejs-api/v1",
  HASURA_BASE_URL: "http://34.83.164.23:8080/v1/graphql",
  ENABLE_CUSTOM_COLUMNS: true,
  ENABLE_INSTA_SCORE: true,
  SHOW_QA_EVAL_TABLE: false,
  USE_GRAPHQL_API: true,
  ENABLE_QA_CALIBRATION: true,
  ENABLE_OKTA: true,
  showSlackIntegration: true,
  showAutomationTab: true,
  ENABLE_FILTER_UI: true, //used for new filter UI,
  ENABLE_CHANNEL_BASED_CONVERSATION_TAGS: true,
  ENABLE_SELF_SERVE_FILTERS: true,
  ENABLE_SCREEN_RECORDING: false,
  ENABLE_SAMPLING_TASK_QUEUE: true,
  ENABLE_RUBRIC_AGENT_TYPE: true,
  ENABLE_AUTH0: true,
  ENABLE_ONE_LOGIN: true,
  ENABLE_CUSTOM_FIELDS_V2_API: false,
  ENABLE_IS_CHECKMARKED: true
};
