import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "antd/dist/antd.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { interceptor } from "./api/api";
import * as Sentry from "@sentry/react";
import appConfigs from "./config";
import { Provider } from "react-redux";
import store from "./store";
import { addPollyfills } from "./utils";
require("typeface-roboto");
addPollyfills();
if (appConfigs.SENTRY_KEY) {
  Sentry.init({ dsn: appConfigs.SENTRY_KEY });
}
interceptor();

// (function(){
//   var csp = document.getElementsByTagName('meta')['csp'];
//   console.log(csp)
//   csp.content = csp.content.split("style-src")[0]
// })()
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
