import React from 'react';
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import { ReactComponent as FlexibleColumnIcon } from "./../assets/FlexibleColumnIcon.svg";
import { ReactComponent as FlexibleBlueColumnIcon } from "./../assets/SelectBlueIcon.svg";
import { Spin, Popover, message, Switch, Tooltip } from "antd";
import { ReactComponent as SelectIcon } from "./../assets/SelectIcon.svg";
import {
  SelectWrapper,
  TranscriptResultsWrapper,
  SpinnerWrapper,
} from "./wrappers";
import { sortableHeaderColumn } from '../utils/utilities';
import { createCustomColumns } from './api';
import appConfigs from "../config";
import { UserMessage } from './components';
import { getLatestCfAnswer } from '../conversation-details/rubric-review/utils';
import { REQUEST_TYPE } from '../custom-hooks/config';
import { spliceText } from '../components/common/CustomFilterComponent/utils';
import { setItemInStore } from '../utils';

export const hoursToTime = (hrs) => {
  const secs = hrs * 3600;
  if (!secs) {
    return "0 hrs";
  }
  var hours = Math.floor(secs / (60 * 60));
  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);
  return `${hours ? hours + "hrs " : ""}${minutes ? minutes + "mins " : ""}`;
};

export const qaStatusMap = {
  0: "PENDING_EVALUATION",
  1: "EVALUATED",
  2: "DISPUTED",
  3: "ACCEPTED",
};

export const getQAStatus = (conversation) => {
  var qa_status = "";
  if (
    conversation &&
    conversation.asr_log &&
    conversation.asr_log.custom_qa_score &&
    conversation.asr_log.custom_qa_score.length > 0
  ) {
    var agent_feedback = null;
    if (conversation.asr_log.common_metrics) {
      agent_feedback = conversation.asr_log.common_metrics.agent_feedback || null;
    }
    if (
      getLatestCfAnswer(conversation.asr_log?.custom_qa_score)?.is_autoscored
    ) {
      qa_status = "PENDING_EVALUATION";
    } else if (agent_feedback === null || agent_feedback == "NONE") {
      qa_status = "EVALUATED";
    } else if (agent_feedback === "ACCEPT") {
      qa_status = "ACCEPTED";
    } else if (agent_feedback === "REJECT") {
      qa_status = "DISPUTED";
    }
  } else {
    qa_status = "PENDING_EVALUATION";
  }
  return {
    ...conversation,
    qa_status: qa_status,
  };
};

export const channelOptions = [
  {
    label: "Call",
    value: "1",
  },
  {
    label: "Chat",
    value: "2",
  },
  {
    label: "Sms",
    value: "3",
  },
  {
    label: "Email",
    value: "4",
  },
  {
    label: "Slack",
    value: "5",
  },
];

export const timeGranularityOptions = [
  {
      label: "Day",
      value: "day"
  },
  { 
      label: "Week",
      value: "week" 
  },
  {
      label: "Month",
      value: "month"
  }
];

export const instaReviewtopics=[
  {
    label: "Negative Conversation",
    value: "Negative Conversation"   
  }, 
  {
    label: "Rich Conversation",
    value: "Rich Conversation",   
  }
];

export const handleFlexibleColumnIcon = (filters, showFlexibleMenu, handleFlexibleMenuClick, iconRef) => {
  if (!filters?.query) {
    if (showFlexibleMenu) {
      return (
        <FlexibleBlueColumnIcon
          ref={iconRef}
          className="flexible-column-blue-icon"
          onClick={handleFlexibleMenuClick}
        />
      );
    } else {
      return (
        <FlexibleColumnIcon
          ref={iconRef}
          className="flexible-column-icon"
          onClick={handleFlexibleMenuClick}
        />
      );
    }
  }
};

/*deep cloning is being done so that if we change the switch from on to off in the flexible menu, then it does not
  directly hides the column from table view i.e column should only hide when save changes is clicked */
  
export const handleSwitchClick = (checked, key, menuColumns, setMenuColumns) => {
    let temp = menuColumns.map((item) => {
      if (item.key !== key) {
        return item;
      } else {
        item.checked = checked;
        return item;
      }
    });
    setMenuColumns(temp);
};

export const setupFlexibleMenuDropdownColumns = (menuColumns, setMenuColumns) => {
  if (menuColumns.length > 0) {
    return menuColumns.map((item) => (
      <SelectWrapper key={item.key}>
        <span style={{ display: "flex", width: "65%" }}>
          <SelectIcon />
          <span title = {item.name} className="column-name"> {item.name}</span>
        </span>
        <Switch
          size="small"
          defaultChecked={item.checked}
          onChange={(checked) => handleSwitchClick(checked, item.key, menuColumns, setMenuColumns)}
        />
      </SelectWrapper>
    ));
  } else {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    );
  }
};
  
export const setDataToLocalStorage = (data) => {
  if(data && data.length) {
    const idMappedData = {}
    data.forEach((item) => {
      idMappedData[item.id] = {
        asr_log:{
          id:item?.asr_log?.id,
          custom_fields: item?.asr_log?.custom_fields,
          channel: item?.asr_log?.channel,
          audio: item?.asr_log?.audio,
          transcript_action_log: item?.asr_log?.transcript_action_log,
          assist_card: item?.asr_log?.assist_card,
          insta_review: item?.asr_log?.insta_review,
          speaker_timestamps: item?.asr_log?.speaker_timestamps,
          speaker_utterances: item?.asr_log?.speaker_utterances
        },
        case_status: item.case_status,
        user: item.user,
      }
    })
    setItemInStore('conversationList',idMappedData);
  }
}

export const handleSorting = (columns, sortable, updateSortable, setColumns) => {
  let copyColumnsTemp = [];
  columns.forEach((item) =>
    typeof item.title === "object" && !item.custom_col ? copyColumnsTemp.push({
          ...item,
          title: sortableHeaderColumn(
            item.name,
            item.key,
            sortable,
            updateSortable
          ),
        })
      : copyColumnsTemp.push(item)
  );
  setColumns(copyColumnsTemp);
};

export  const handleClickOutside = (event, iconRef, dropdownRef, setShowFlexibleMenu) => {
  if ((iconRef.current && !iconRef.current.contains(event.target)) && (dropdownRef.current && !dropdownRef.current.contains(event.target))) {
      setShowFlexibleMenu(false);
  }
}

export const deepClone = (originalArr) => {
  let deepclonedArr = originalArr.map((a) => {
    return { ...a };
  });
  return deepclonedArr;
};

export const handleSetDefault = (menuColumns, user, setShowColumnSpinner, setColumns, setShowFlexibleMenu, callApi) => {
  setShowColumnSpinner(true);
  let temp = deepClone(menuColumns);
  let columns;
  columns = [];
  columns = temp?.map((item) => {
  const data = {
        pretty_name: item.name,
        field_name: item.key,
        is_active: item.checked,
        source: item.source
      }
      return data;
  });
  
  callApi(createCustomColumns, (res) => {
    setColumns(temp);
    message.success("Column view successfully set!");
  }, (err) => {

  }, {
    id: user.id, columns
  }, () => {
    setShowColumnSpinner(false);
    setShowFlexibleMenu(false);
  }, REQUEST_TYPE.DISPATCH_REQUEST);
};

export const renderTranscriptResults = (record) => {
  return (
    <TranscriptResultsWrapper>
      { 
        record?.content?.map( (message, index) => <UserMessage {...message} key={index} /> ) 
      }
    </TranscriptResultsWrapper>
  );
};

/*this function will compare the existing columns with the one's which are returned from the custom column api, and hence will put fixed column 
and custom column in 2 different array, lastly will concatenate them and sort them*/

export const concatFixedCustomCol = (availableColumnKeys, apiColumns, columns) => {
  //remove once feature gating for self serve is done
  let fixedCol = [];
  let customCol = [];
  apiColumns.forEach((item) => {
      availableColumnKeys.includes(item.field_name) && item.source?.toLowerCase().includes("fixed") ? fixedCol = columns.map(content => {
        if(content.key === item.field_name) {
            content.checked = item.is_active;
            return content;
        }
        else {
          return content
        }
      }) 
      : customCol.push({
          title: spliceText(item.pretty_name, 25, true) ,
          dataIndex: item.field_name,
          key: item.field_name,
          render: (value, record) => (
            <div>
              {
                Object.keys(record.asr_log.custom_fields).length > 0 
                ? record.asr_log.custom_fields[item.field_name] !== undefined
                  ? record.asr_log.custom_fields[item.field_name]?.length > 15 ? spliceText(record.asr_log.custom_fields[item.field_name], 15, true) : record.asr_log.custom_fields[item.field_name]
                  : "-"
                : "-"
              }
            </div>
          ),
          value: (record) => Object.keys(record.asr_log.custom_fields).length > 0 
            ? record.asr_log.custom_fields[item.field_name] !== undefined
              ? record.asr_log.custom_fields[item.field_name]
              : "-"
            : "-",
          align: "center",
          checked: item.is_active,
          name: item.pretty_name,
          custom_col: true,
          source: item.source
        });
      
    });
    if(!fixedCol?.length) {
      fixedCol = columns.map(content => content);
    }
  return fixedCol.sort((a, b) => a.order - b.order).concat(customCol.sort((a, b) =>a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())));  
};
