import React from "react";
import styled from "@emotion/styled/macro";
import moment from "moment";
import { Collapse } from 'antd';
import TimelineItem from "../TimelineItem"
import { TIMELINE_TYPES } from "../Constants";
import { ReactComponent as OpenCardsIcon } from "../../assets/open-cards.svg";
import { ReactComponent as CloseCardsIcon } from "../../assets/close-cards.svg";
import { ReactComponent as AgentContextIcon } from "../../assets/agent-context.svg";
import { ReactComponent as CustomerContextIcon } from "../../assets/customer-context.svg";
import { ReactComponent as CaseContextIcon } from "../../assets/case-context.svg";
import { GroupedAssists } from "./assist-timeline";
const { Panel } = Collapse;

const TimelineWrapper = styled.div`
    display: ${props => props.hide ? "none" : "flex"};
    flex-direction: column;
    padding: 0;
    padding-bottom: 16px;
    max-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: auto;
    min-height: ${props => props.isAgent && "100vh"};
    display: ${props => props.hide && "none"};
    .ant-collapse-content, .ant-collapse, .ant-collapse > .ant-collapse-item {
        border: none;
        background-color: #ffffff;
    }
    .ant-collapse-content-box, .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
        padding: 0;
    }    
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 0 0 18px !important;
        border-left: 1px solid #bdbdbd;
        margin-left: 20px;
        position: relative;
        left: -12px;
        .timeline-item:first-of-type {
            margin-top: 0;
        }
    }
`;

const CardsGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    margin-top: 8px;
    background: #FAFAFA;
    .group-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: -12px;
        .details {
            display: flex;
            align-items: center;
            padding: 0;
            height: 34px;
            width: 100%;
            svg {
                path {
                    fill: #BDBDBD;
                }
            }
            .group-heading {
                font-size: 16px;
                line-height: 19px;
                color: #4F4F4F;
            }
        }
        .group-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: calc(100% - 40px);
            padding-left: 18px;
            height: 47px;
            border-left: 1px solid #bdbdbd;
            .context-icon {
                width: 24px;
                height: 24px;
                margin-right: 10px;                
            }
            .group-sub-heading {
                font-size: 16px;
                line-height: 19px;
                color: #4F4F4F;
                margin-right: 5px;
                width: 115px;
            }
            .card-category {
                margin-left: 2px;
                background: #6099D9;
                border-radius: 21px;
                height: 18px;
                line-height: 18px;
                font-size: 12px;
                color: #ffffff;
                padding: 0 10px;
                width: auto;
                white-space: nowrap;
            }
        }
    }
`;

const getPluralName = (name, count) => {
    return count > 1 ? `${name}s` : name;
};

const getCategoryCountString = (name, count) => {
    return (count ? `${count} ${getPluralName(name, count)}` : '');
}

const CardCategories = [
    { filter: TIMELINE_TYPES.HINT, name: "Hint"},
    { filter: TIMELINE_TYPES.FLAG, name: "Flag"},
    { filter: TIMELINE_TYPES.FAQ, name: "FAQ"},
    // { filter: TIMELINE_TYPES.WARNING, name: "Warning"},
    // { filter: TIMELINE_TYPES.KNOWLEDGE, name: "Resource"},
];

const getContextIcon = (context) => {
    switch(context) {
        case "Case":
            return <CaseContextIcon />;
        case "Customer":
            return <CustomerContextIcon />;
        case "Assistant":
        case "Agent":
            return <AgentContextIcon />;
    }
}

const ShowGroupCards = ({ groups, context, contextTitle, created, defaultOpen, conversationId, isAgent, isLiveConversation, updateUserFeedback, hide, groupResults, conversation_status }) => {
    const [expand, toggleExpand] = React.useState(!!defaultOpen);
    let cardCategoriesList = [];
    let cardCategories = [...CardCategories];
    // if(context === "Customer") {
    //     cardCategories = [
    //         { filter: TIMELINE_TYPES.HINT, name: "Hint"},
    //         { filter: TIMELINE_TYPES.FAQ, name: "FAQ"},
    //         { filter: TIMELINE_TYPES.WARNING, name: "Warning"},
    //     ];
    // }
    for(let i = 0; i< cardCategories.length; i++) {
        const category = cardCategories[i];
        let count = 0;
        for(let group=0; group<groups.length; group++) {
            const cards = groups[group].cards || [];
            console.log({ cards, item: groups[group] });
            count += cards.filter((item) => item.category === category.filter).length;
        }
        if(count) {
            console.log({ name: category, count });
            const value = getCategoryCountString(category.name, count);
            cardCategoriesList.push(value);
        }
    }
    return (
        <CardsGroupWrapper isAgent={isAgent || isLiveConversation} hide={hide}>
            <Collapse onChange={() => toggleExpand(!expand)}  defaultActiveKey={defaultOpen ? ['1']: []}>
                <Panel 
                    showArrow={false} 
                    key="1"
                    header={
                        <div className="group-container">
                            <div className="details">
                                {expand ? <CloseCardsIcon /> : <OpenCardsIcon />}
                                <div className="group-heading">{moment(created).format("MMM DD h:mm a")}</div>
                            </div>                            
                            <div className="group-details">
                                <div className="context-icon">{getContextIcon(context)}</div>
                                <div className="group-sub-heading">{contextTitle}</div>
                                {cardCategoriesList.map((categoryItem, index) => (
                                    <div className="card-category">{categoryItem}</div>
                                ))}
                            </div>
                        </div>
                    }
                >
                    {groups.map((cardGroup, index) => {
                        const { cards } = cardGroup;
                        const showGroup = cards.length > 1 && groupResults;
                        if(showGroup) {
                            return (
                                <GroupedAssists 
                                    cards={cards} 
                                    isLiveConversation={isLiveConversation} 
                                    isDebug={false} 
                                    isAgent={isAgent}
                                    updateUserFeedback={updateUserFeedback}
                                    conversationId={conversationId}
                                    conversation_status={conversation_status}
                                    channel="email"
                                />
                            )
                        }
                        return cards.map((item, index) => (
                            <TimelineItem
                                {...item} 
                                isLiveConversation={isLiveConversation} 
                                key={index} 
                                isDebug={false} 
                                isAgent={isAgent}
                                updateUserFeedback={updateUserFeedback}
                                conversationId={conversationId}        
                                conversation_status={conversation_status}         
                                channel="email"                                
                            />
                        ))                     
                    })}
                </Panel>
            </Collapse>
        </CardsGroupWrapper>
    )
}

const ContextBasedTimeLine = ({ isAgent, isLiveConversation, data, conversationId, updateUserFeedback, hide, groupResults, conversation_status }) => {
    return (
        <TimelineWrapper hide={hide}>
            {data.map((item, index) => {
                if(item.groups && item.groups.length) {
                    return(
                        <ShowGroupCards {...item} 
                            key={index}
                            updateUserFeedback={updateUserFeedback} 
                            conversationId={conversationId} 
                            isAgent={isAgent} 
                            isLiveConversation={isLiveConversation} 
                            groupResults={groupResults}
                            conversation_status={conversation_status}
                        />
                    )
                }
            })}
        </TimelineWrapper>
    )
}

export default ContextBasedTimeLine;