import React, { useState, useReducer, useEffect } from 'react';
import styled from "@emotion/styled/macro";
import { Input, Button, Menu } from "antd";
import { Question } from "./question";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { 
    PrimaryStyledButton
} from "./../../../../components/common/styled-components";
import AutoscoreEnabledQuestionModal from './AutoscoreEnabledQuestionModal'
import { getAutoscoredQuestions } from "../api";
import ClipboardImage from '../../../assets/clipboard.png';
import {ReactComponent as MagicSvg} from '../../../assets/magic.svg'

import { CONVERSATION_TAG , METRIC_TAG, PRECONFIGURED_QUESTIONS} from "./constants";
import { normalOptions } from '../constants';

const SectionPanelWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 0px;
    .title {
        flex: 3;
    }
    .points {
        flex: 1;
    }
    .ant-btn.disabled, .ant-btn[disabled] {
        background-color: #f5f5f500 !important;
    }

`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    input {
        margin-right: 10px
    }
    flex: 2;
    padding: 10px;
    margin: 0;
    .ant-collapse .ant-collapse-item .ant-collapse-header {
        margin: 0px;
    }
    .ant-collapse-borderless {
        background: white;
    }

    .buttons-panel {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;

      .rubric-builder__add-autoscore-enabled-question {
        cursor: pointer;
      }
    }
`;

const CategoryWrapper = styled.div`
    display: flex;
    width: 100%;
    // * {
    //     margin-left: 10px;
    //     margin-top: 10px;
    // }
    .ant-tabs-content {
        background-color: #FAFAFA;
    }
`;

const SectionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    input {
        margin-left: 10px
    }
    .add-button {
        margin-top: 10px;
        max-width: 40%;
        margin-left: 30%;
        padding: 0;
    }
    flex: 1;
    .ant-menu-item { 
        height: 60px;
        margin: 0px;
        padding: 10px;
        border-bottom: 1px solid #E0E0E0;
    }
    margin-top: 10px;
    padding-bottom: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    overflow: hidden;
    .ant-menu-item-selected {
        border-left: 3px solid #6099D9;
    }
    .ant-menu-item::after {
        border: 0;
    }
    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin: 0px;
    }
`

const EmptyQuestionsSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 30rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    margin-bottom: 0.7rem;
  }

  strong {
    font-weight: 700;
    color: black;
    font-size: 16px;
  }

  .rubric-builder__add-autoscore-enabled-question {
    cursor: pointer;
    :hover {
      color: #757474;
    }
  }
`;

const AddQuestionButtonWrapper = styled.button`
    margin: 1rem 0;
    border: 1px solid #6099d9;
    padding: 6px 16px 6px 16px;
    background: white;
    color: black;
    border-radius: 0.2rem;
    cursor: pointer;

    :hover {
      border: 1px solid #4989d1;
      color: #6099d9;
    }
`;

const AddQuestionButton = (props) => {
  return (
    <AddQuestionButtonWrapper
      onClick={props.onClick}
    >
      {props.children}
    </AddQuestionButtonWrapper>
  );
}

const sectionsListReducer = (sections, action) => {
    let _sections = [...sections]
    if (action.type === "UPDATE") {
        const { i, key, value} = action.payload;
        _sections.splice(i, 1, {
            ..._sections[i],
            [key]: value
        });
        action.onChange({
            key: "title",
            value: value,
        })
        return _sections;
    }
    if (action.type == "SET_SECTIONS") {
        return [...action.payload];
    }
}


const SectionsList = ({sections, onAdd, onSelect, onChange, onDelete, selectedKeys}) => {
    const sum = list => list.reduce((prev, curr) => prev + curr)
    const sectionTotal = (section) => sum([0, ...section.questions.map(x=>{
        if (x.scoring != 0) return 0; 
        return Math.max(0, ...x.options.map((x)=>Number(x.score)))
    })])
    const [ _sections, dispatch ] = useReducer(sectionsListReducer, [...sections])

    useEffect(() => {
        dispatch({
            type: "SET_SECTIONS",
            payload: sections
        })
    }, [sections])
    return (
        <SectionsListWrapper>
            <Menu
                onClick={(e) => {
                    onSelect(e.key)
                }}
                defaultSelectedKeys={'0'}
                mode="inline"
                selectedKeys={`${selectedKeys}`}
            >
                    {
                        _sections.map((section, i) => (
                                <Menu.Item key={i}>
                                    <SectionPanelWrapper>
                                        <Input required className="title" placeholder="Section name" value={section.title} onChange={e => {
                                            dispatch({
                                                type: "UPDATE",
                                                payload: {
                                                    i,
                                                    key: "title",
                                                    value: e.currentTarget.value
                                                },
                                                onChange
                                            })
                                        }}/>
                                        <Input className="points" disabled value={sectionTotal(section) + " pts"}/>
                                        <Button disabled={i==0} style={{border: "none", boxShadow: "none"}} onClick={(e) => {
                                            onDelete(i)
                                            e.stopPropagation()
                                        }}>
                                            <DeleteIcon />
                                        </Button>
                                    </SectionPanelWrapper>
                                </Menu.Item>
                            )
                        )
                    }
            </Menu>
            <PrimaryStyledButton className="add-button" onClick={() => {onAdd()}}>+ Add Section</PrimaryStyledButton>
        </SectionsListWrapper>
    )
}

const questionsReducer = (questions, action) => {
    let _questions = [...questions]
    if(action.type === "UPDATE") {
        const { i, key, value} = action.payload;
        _questions.splice(i, 1, {
            ..._questions[i],
            [key]: value
        });

        action.onChange({
            key: "questions",
            value: _questions
        })
        return _questions;
    }

    if(action.type === "ADD_QUESTION") {
        const { title } = action.payload;
        const max_score = Math.max(0, [...action.defaultOptions].map((x)=>Number(x.score)))
        _questions.push({
            title,
            scoring: 0,
            description: "",
            options: [...action.defaultOptions],
            max_score
        })

        action.onChange({
            key: "questions",
            value: _questions
        })

        return _questions;
    }

    if(action.type === "ADD_PRESET_QUESTION") {
      const { title, scoring, description, options, id, ...rest } = action.payload;
      const max_score = Math.max(
        0,
        [...action.defaultOptions].map((x) => Number(x.score))
      );
      _questions.push({
        title,
        scoring: scoring || 0,
        description: description || "",
        options: options || [...action.defaultOptions],
        max_score,
        preset: true,
        automated_scoring: id,
        ...rest
      });

      action.onChange({
        key: "questions",
        value: _questions,
      });

      return _questions;
    }

    if(action.type === "SET_QUESTIONS") {
        const { value } = action.payload;
        return value;
    }

    if(action.type === "DELETE") {
        const { i } = action.payload;
        _questions.splice(i, 1);

        action.onChange({
            key: "questions",
            value: _questions
        })

        return _questions;
    }
}

const Section = (props) => {
    const { onChange } = props;
    const [ questions, dispatch ] = useReducer(questionsReducer, [...props.questions]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    let { defaultOptions } = props;

    if (!defaultOptions) {
        defaultOptions = [...normalOptions]
    }

    useEffect(() => {
        dispatch({
            type: "SET_QUESTIONS",
            payload: {value: [...props.questions]}
        })
    }, [props.questions])

    function toggleModal() {
      setIsModalVisible(prev => !prev);
    }

    function addQuestion(question) {
      dispatch({
        type: "ADD_PRESET_QUESTION",
        payload: {
          ...question,
          scoring: 0
        },
        defaultOptions: [],
        onChange
      });
    }

    return (
      <SectionWrapper>
        <AutoscoreEnabledQuestionModal
          isModalVisible={isModalVisible}
          toggleModal={toggleModal}
          addQuestion={addQuestion}
          presetQuestions={props.presetQuestions}
        />

        {questions?.length > 0 ? (
          <>
            {questions.map((question, i) => {
              return (
                <Question
                  {...props}
                  key={i}
                  onChange={(e) => {
                    // console.log({e})
                    // e contains the key and value of the question to be updated
                    dispatch({
                      type: "UPDATE",
                      payload: {
                        i,
                        key: e.key,
                        value: e.value,
                      },
                      onChange,
                    });
                  }}
                  onDelete={() => {
                    dispatch({
                      type: "DELETE",
                      payload: {
                        i,
                      },
                      onChange,
                    });
                  }}
                  question={question}
                />
              );
            })}

            <div className="buttons-panel">
              <AddQuestionButton
                onClick={() => {
                  dispatch({
                    type: "ADD_QUESTION",
                    payload: {
                      title: "",
                      description: "",
                      options: [],
                      scoring: 0,
                    },
                    defaultOptions,
                    onChange,
                  });
                }}
              >
                + Add question
              </AddQuestionButton>

              <div
                className="rubric-builder__add-autoscore-enabled-question"
                onClick={toggleModal}
              >
                <MagicSvg />
                Add autoscore enabled question
              </div>
            </div>
          </>
        ) : (
          <EmptyQuestionsSectionWrapper>
            <div>
              <img
                src={ClipboardImage}
                alt="No questions added"
                height="105px"
                width="74px"
              />
              <div>
                <strong>No questions added.</strong>
              </div>
              <p>Once you have added a question it will appear here</p>
              <AddQuestionButton
                onClick={() => {
                  dispatch({
                    type: "ADD_QUESTION",
                    payload: {
                      title: "",
                      description: "",
                      options: [],
                      scoring: 0,
                    },
                    defaultOptions,
                    onChange,
                  });
                }}
              >
                + Add question
              </AddQuestionButton>
              <div
                className="rubric-builder__add-autoscore-enabled-question"
                onClick={toggleModal}
              >
                <MagicSvg /> Add autoscore enabled question
              </div>
            </div>
          </EmptyQuestionsSectionWrapper>
        )}
      </SectionWrapper>
    );
}


const sectionsReducer = (sections, action) => {
    let _sections = [...sections]
    if(action.type === "UPDATE") {
        const { i, key, value} = action.payload;
        _sections.splice(i, 1, {
            ..._sections[i],
            [key]: value
        });

        action.onChange({
            key: "sections",
            value: _sections
        })
        return _sections;
    }

    if(action.type === "DELETE") {
        const { i } = action.payload;
        _sections.splice(i, 1);
        action.onChange({
            key: "sections",
            value: _sections
        })
        return _sections;
    }

    if(action.type === "ADD_NEW") {
        _sections.push({
            questions: [],
            title: ""
        })

        action.onChange({
            key: "sections",
            value: _sections
        })
        return _sections;
    }
}

export const Category = ({data, onChange, ...restProps}) => {
    let [ section, setSection ] = useState(0);
    let [ sections, dispatch ] = useReducer(sectionsReducer, [...data.sections]);

    const [presetQuestions, setPresetQuestions] = useState({
      [CONVERSATION_TAG]: [],
      [METRIC_TAG]: [],
      [PRECONFIGURED_QUESTIONS]: [],
    });

    useEffect(() => {
      [METRIC_TAG, CONVERSATION_TAG, PRECONFIGURED_QUESTIONS].forEach((tag) => {
        getAutoscoredQuestions(tag)
        .then(response => response?.results)
        .then((results = []) => {
          setPresetQuestions(prev => ({
          ...prev,
          [tag]: results || []
        }))})
        .catch(console.error)
      })
    }, []);

    return (
        <CategoryWrapper>
            <SectionsList selectedKeys={section} className="sections-list" sections={sections} onAdd={(e) => {
                dispatch({
                    type: "ADD_NEW",
                    onChange
                })
            }}
            onDelete={(i) => {
                if (section == sections.length - 1) {
                    setSection(Math.max(0, sections.length-2))
                }
                dispatch({
                    type: "DELETE",
                    payload: {i},
                    onChange
                })
            }}
            onChange={(e) => {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        i: section,
                        key: e.key,
                        value: e.value
                    },
                    onChange
                })
            }}
            onSelect={(key) => {
                setSection(key)
            }}
            />

            <Section {...restProps} presetQuestions={presetQuestions} className="section" questions={[...sections[section]?.questions]} onChange={(e) => {
                // e contains key and value of the section to be updated
                dispatch({
                    type: "UPDATE",
                    payload: {
                        i: section,
                        key: e.key,
                        value: e.value
                    },
                    onChange
                })
            }} />
        </CategoryWrapper>
    )
}
