import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as QueryString from "query-string";

import {mapStateToProps,isAuthorized,checkReload} from './utilities.js';

const TriggerableRoute = connect(mapStateToProps, null)(({ component, ...rest }) => {
  //Check if user is authorized
  if(isAuthorized(rest.app_user)) {
    if (rest.path === "/") {
      return <Redirect to={rest.redirectUrl} />
    }
    else if (rest.path === "/plugin/login") {
      const queryParams = QueryString.parse(window.location.search);
      const params = {
        platform: queryParams?.platform,
        platformId: queryParams?.platformId,
      };
      return <Redirect to={`/plugin/assist/?${QueryString.stringify(params)}`} />
    }
    else if (rest.path === "/qa/login") {
      const queryParams = QueryString.parse(window.location.search);
      return <Redirect to={`/qa/plugin?${QueryString.stringify(queryParams)}`} />
    }
    else if (rest.path === "/annotation/login") {
      return <Redirect to={`/annotation/`} />
    }
  }  
  checkReload();
  return <Route component={component} {...rest} />;
});

export default TriggerableRoute
