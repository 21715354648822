import React, { useState, useEffect } from "react";
import { Badge, Modal, Spin } from "antd";
import { useSelector } from "../../store";
import { PrimaryStyledButton } from "../../components/common/styled-components";
import RenderFilters from "./components/RenderFilters";
import { OverlayWrapper, AdditionalFilterWrapper } from './wrappers';

const AdditionalFilters = ({ additionalFilters, configuration }) => {
  const { showCustomFilters } = configuration;
  const globalFilters = useSelector((state) => state.dashboard.globalFilters);

  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const handleModal = () => {
    open ? setOpen(false) : setOpen(true);
  }

  useEffect(() => {
    if(globalFilters && Object.keys(globalFilters).length) {
      let count= 0;
      Object.entries(globalFilters).forEach(([key, value]) => {
        additionalFilters.forEach(filter => {
          if(filter.key === key && (value?.length || value === true || typeof(value) === 'number')) {
            count++;
          }
        })
      })
      setFilterCount(count);
    }
  }, [globalFilters, additionalFilters]);

  return (
    additionalFilters?.length ?
     <AdditionalFilterWrapper>
      {
        <Badge style={{ background: "#214A8E" }} count={filterCount}>
        <PrimaryStyledButton
          className="add-more-button"
          style={{ marginRight: "4px", marginTop: "10px" }}
          onClick={handleModal}
        >
          More filters
        </PrimaryStyledButton>
      </Badge>
      }
      <Modal style={{position: "relative"}} width={640} title="Add Filter" onCancel={handleModal} visible={open} footer={null} closable={true}>
          <RenderFilters handleModal={handleModal} additionalFilters={additionalFilters?.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))} showCustomFilter={showCustomFilters} open={open}/>
          {/* {(filterSegmentsLoading) && <OverlayWrapper>
            <Spin/>
          </OverlayWrapper>} */}
      </Modal>
    </AdditionalFilterWrapper> : ""
  );
};

export default AdditionalFilters;
