import React from "react";
import { Select } from 'antd';
import styled from "@emotion/styled/macro";
import { ReactComponent as SelectIcon } from "./../../assets/select-icon.svg";
const { Option } = Select;

const StyledSelect = styled(Select)`
  width: ${props => props.width};
  margin: ${props => props.margin};
  .ant-select-selection{
    width: ${props => props.width};
    height: ${props => props.height};
  }
  .ant-select-selection__rendered {
    line-height: ${props => props.height};
  }
  .ant-select-selection__placeholder {
    display: ${props => props.defaultValue || props.value ? "none" : "block !important"};
  }
  .ant-select-arrow {
    top: ${props => props.iconPosition ? props.iconPosition : 24}px;     
  }
  .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 32px;
    margin-top: 4px;
    line-height: 22px;
    line-height: 28px;
  }
  ${props => props.showBoth ? `
    .ant-select-selection__placeholder {
      display: block !important;
      top: 10px;
      font-size: 10px;
    } 
    .ant-select-selection-selected-value {
      top: 6px;
      position: relative;
    }
    .ant-select-arrow {
      top: 30px;
    }
  ` : ``}
`;

export const CustomSelect = (props) => {
  return (
    <StyledSelect
      disabled = {props.disabled}
      allowClear={props.allowClear}
      mode={props.mode ? props.mode : ""}
      placeholder={props.placeholder}
      defaultValue={props.value || ""}
      onChange={props.handleChange}
      width={props.width}
      height={props.height}
      margin={props.margin}
      value={props.value}
      showBoth={props.showBoth}
      iconPosition={props.iconPosition}
      suffixIcon={<SelectIcon />}
    >
    {props.options.map((option) => 
      <Option key={option.value}>{option.label}</Option>
    )}
    </StyledSelect>
  );
}