import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import { withRouter } from "react-router-dom";
import {
  StyledTable,
  TitleDescriptionWrapper,
} from "./../../../components/common/styled-components";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as HistoricalIcon } from "../../assets/historical.svg";
import { ReactComponent as UniversalScenarioIcon } from "../../assets/universalScenarioIconLatest.svg";
import ActionMenu from "./../commons/ActionMenu";
import SetupHeader from "../commons/SetupHeader";
import {
  getConversationTagsList,
  patchConversationTagDetails,
  deleteConversationTagDetails,
} from "./../../api/api";
import VerificationChip from "../../../components/chips/VerificationChip";
import SearchComponent from "./../../../components/common/SearchComponent";
import ConversationTagPopup from "../ConversationTagPopup";
import {
  PrimaryStyledButton,
  StyledCheckbox,
} from "./../../../components/common/styled-components";
import Tag from "../../components/commons/Tag";
import HintTag from "../../components/commons/AgentAssistTag";
import FlagTag from "../../components/commons/EscalationTag";
import {
  SetupWrapper,
  FilterWrapper,
  SpaceBetweenWrapper,
  LineSeparator,
} from "./../commons/wrappers";
import HistoricalRunner from "../HistoricalRunner";

const RulesList = ({
  tableData,
  isLoading,
  onClick,
  onItemPatch,
  onItemDelete,
  onItemRun,
}) => {
  const columns = [
    {
      title: "Tag Details",
      dataIndex: "rule_json",
      key: "rule_json",
      width: "45%",
      render: (value, record, index) => {
        return (
          <TitleDescriptionWrapper>
            <div className="rule-title" onClick={() => onClick(record)}>
              {record.rule_json.title}
            </div>
            <div className="setup-tags">
              <Tag
                isSelected={record.rule_json.is_tagged_for_qa}
                tagName="Tag for Rubric"
                onSelect={(selected) => {
                  const item = tableData[index];
                  onItemPatch({
                    ...item.rule_json,
                    id: item.id,
                    is_tagged_for_qa: !!selected,
                  });
                }}
              />
            </div>
          </TitleDescriptionWrapper>
        );
      },
    },
    {
      title: "Agent Assist",
      dataIndex: "rule_json",
      key: "agent_assist",
      width: "18%",
      align:"center",
      render: (value, record, index) => {
        const isTaggedForHint = !!record.rule_json?.actions?.find(
          (item) => item.category === "hint"
        );
        return (
          <HintTag
            isSelected={isTaggedForHint}
            onSelect={() => onClick(record)}
          />
        );
      },
    },
    {
      title: "Escalation",
      dataIndex: "rule_json",
      key: "escalation",
      width: "18%",
      align:"center",
      render: (value, record, index) => {
        const isTaggedForFlag = !!record.rule_json?.actions?.find(
          (item) => item.category === "flag"
        );
        return (
          <FlagTag
            isSelected={isTaggedForFlag}
            onSelect={() => onClick(record)}
          />
        );
      },
    },
    {
      title: "Verification Status",
      dataIndex: "rule_audit",
      key: "rule_audit",
      width: "25%",
      align:"center",
      render: (value) => {
        if (value) {
          return (
            <VerificationChip
              verified={value.is_verified}
              verifiedTime={value.rule_verified_at}
            />
          );
        }
      },
    },
    {
      title: "Universal Tag",
      dataIndex: "universal_tag",
      key: "universal_tag",
      width: "18%",
      align:"center",
      render: (value, record) => record.rule_type ?.toLowerCase() === "universal" && <UniversalScenarioIcon/>
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (value, record) => {
        return (
          <ActionMenu
            menuItems={[
              // {
              //   title: "Edit",
              //   key: "edit",
              //   icon: EditIcon,
              // },
              {
                title: "Delete",
                key: "delete",
                icon: DeleteIcon,
              },
              {
                title: "Run Historically",
                key: "run",
                icon: HistoricalIcon,
                hoverColor: "#ff4d4f",
              },
            ]}
            handleMenuClick={(key) => {
              if (key === "delete") {
                onItemDelete(record);
              } else if (key === "run") {
                onItemRun(record);
              }
            }}
          />
        );
      },
    },
  ];
  return (
    <StyledTable
      dataSource={tableData}
      columns={columns}
      loading={isLoading}
      rowKey="id"
      scroll={{ y: "calc(100vh - 290px)" }}
      pagination={false}
    />
  );
};

const initialState = {
  tableData: [],
  isLoading: true,
  page: 1,
  showInfo: false,
  addConversationTag: false,
  conversationTagDetails: {},
};

export class ConversationTagsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      showRunner: false,
      popupId: null,
    };
    this.dataSource = [];
  }
  trackScrolling = (e) => {
    const tabDocument = document.getElementsByClassName("setup-tab-conversation-tags")[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    let isBottom = (el) => {
        return el.offsetHeight + el.scrollTop >= el.scrollHeight - 100;
    }
    if (node && isBottom(node)) {
        node.removeEventListener('scroll', this.trackScrolling);
        this.setState({ page: this.state.page + 1, isLoading: true }, () => {
            this.getConversationTagsList();            
        })                        
    }
}
  componentDidMount() {
    this.getConversationTagsList();
    // if(!localStorage.getItem('conversation-tags')) {
    //   localStorage.setItem('conversation-tags', true);
    //   this.setState({ showInfo: true })
    // }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedWorkspace !== this.props.selectedWorkspace) {
      this.setState(
        {
          ...initialState,
        },
        () => {
          this.dataSource = [];
          this.getConversationTagsList();
        }
      );
    }
  }
  getConversationTagsList = (userFilters) => {
    const { page, title, tag_type } = userFilters ? userFilters : this.state;
    const filters = {
      page,
      title,
      tag_type,
    };
    const tabDocument = document.getElementsByClassName(
      "setup-tab-conversation-tags"
    )[0];
    let node = tabDocument.getElementsByClassName("ant-table-body")[0];
    node.removeEventListener("scroll", this.trackScrolling);
    getConversationTagsList(filters).then((res) => {
      if (res.next) {
        let node = tabDocument.getElementsByClassName("ant-table-body")[0];
        if (node) {
          node.addEventListener("scroll", this.trackScrolling);
        }
      }
      let results = res.results
        ? res.results.map((item) => {
            return {
              ...item,
              id: item.id,
            };
          })
        : [];
      let data =
        this.state.page === 1 ? results : this.dataSource.concat(results);
      this.dataSource = data;
      this.setState({ tableData: data, isLoading: false });
    }).catch(err => {
        this.setState({tableData: [], isLoading: false});
    });
  };
  applyFilters = () => {
    this.setState({ isLoading: true, tableData: [], page: 1 }, () => {
      this.dataSource = [];
      this.getConversationTagsList();
    });
  };
  handleSearchValue = (value) => {
    this.setState({ title: value }, this.applyFilters);
  };
  handleCustomerTags = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { tag_type: isChecked ? "customer" : undefined },
      this.applyFilters
    );
  };
  handleAgentTags = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { tag_type: isChecked ? "agent" : undefined },
      this.applyFilters
    );
  };
  addConversationTag = () => {
    this.setState({ addConversationTag: true });
  };
  handleSubmit = (data) => {
    this.props.history.push(`/setup/conversation-tags/${data.id}`);
  };
  onItemPatch = (record) => {
    const { id, ...data } = record;
    this.setState({ isLoading: true }, () => {
      patchConversationTagDetails(id, { rule_json: data }).then(
        (res) => {
          message.success("Conversation tag is updated");
          this.setState({ tableData: [], page: 1, isLoading: true }, () => {
            this.dataSource = [];
            this.getConversationTagsList();
          });
        },
        (err) => {
          this.setState({ isLoading: false });
        }
      );
    });
  };
  onItemDelete = (record) => {
    this.setState({ isLoading: true }, () => {
      deleteConversationTagDetails(record.id).then(
        (res) => {
          message.success("Conversation tag is deleted");
          this.setState({ tableData: [], page: 1, isLoading: true }, () => {
            this.dataSource = [];
            this.getConversationTagsList();
          });
        },
        (err) => {
          this.setState({ isLoading: false });
        }
      );
    });
  };

  onItemRun = (record) => {
    this.setState({
      showRunner: true,
      popupId: record.id,
    });
  };
  render() {
    const tableData = this.state.tableData;
    return (
      <SetupWrapper className="setup-tab-conversation-tags">
        <SetupHeader
          title="Conversation Tags"
          icon="conversation-tag"
          description="Tell Level AI what to look out for to help annotate conversations and guide agents."
          openInfo={() => this.setState({ showInfo: true })}
        />
        <SpaceBetweenWrapper>
          <FilterWrapper>
            <SearchComponent
              placeholder="Search Tag Name or Intent"
              value=""
              handleSearchValue={this.handleSearchValue}
            />
            <StyledCheckbox
              checked={this.state.tag_type === "customer"}
              onChange={this.handleCustomerTags.bind(this)}
            >
              Customer Tags
            </StyledCheckbox>
            <StyledCheckbox
              checked={this.state.tag_type === "agent"}
              onChange={this.handleAgentTags.bind(this)}
            >
              Agent Tags
            </StyledCheckbox>
          </FilterWrapper>
          <PrimaryStyledButton type="primary" onClick={this.addConversationTag}>
            + Add Conversation Tag
          </PrimaryStyledButton>
        </SpaceBetweenWrapper>
        <LineSeparator />
        <RulesList
          tableData={tableData}
          isLoading={this.state.isLoading}
          onClick={this.handleSubmit}
          onItemPatch={this.onItemPatch}
          onItemDelete={this.onItemDelete}
          onItemRun={this.onItemRun}
        />
        <Modal
          visible={this.state.addConversationTag}
          closable={false}
          width={815}
          title={null}
          header={null}
          footer={null}
        >
          <ConversationTagPopup
            data={this.state.conversationTagDetails}
            onSubmit={this.handleSubmit}
            onClose={() => {
              this.setState({ addConversationTag: false });
            }}
          />
        </Modal>
        <HistoricalRunner
          visible={this.state.showRunner}
          ruleId={this.state.popupId}
          onCancel={() => {
            this.setState({ showRunner: false, popupId: null });
          }}
        />
      </SetupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app_user: state.app_user,
  selectedWorkspace: state.app_user.selectedWorkspace,
});

export default connect(mapStateToProps, null)(withRouter(ConversationTagsList));
