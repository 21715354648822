import { Select } from 'antd'
import React from 'react'
import { useSelector } from "../../store";

const CampaignFilter = ({value,handleChange}) => {
    const options = useSelector(state => state?.dashboard?.segments?.campaign);

    const { Option } = Select

    return (
        <Select value={value} onChange={(val) => handleChange(val)} placeholder="Select Campaign">
            {
                options?.length > 0 &&
                    options.map((option, index) => (
                        <Option key={index} value={option}>{option}</Option>
                    ))
            }
        </Select>
    )
}

export default CampaignFilter
