import React, { useEffect, useState } from 'react';
import { StyledInput } from '../../../../../../components/common/styled-components';
import { ReactComponent as DeleteIcon } from "../../../../../assets/delete.svg";
import {
  InteractionsWrapper,
  InteractionsSubWrapper,
  SampleCountWrapper,
  ConditionGroupWrapper,
  AddConditionGroupWrapper,
  ErrorState,
  SampleCount} from '../../../wrappers';
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import ConditionGroupComponent from '../../../../../../components/common/ConditionGroupComponent';
import produce from 'immer';

const ConditionGroupParent = ({ data, textOnly = false, showErrorState = false, showSampleCount = false ,filterOptions = [], fn = {} }) => {
  const [parentData, setParentData] = useState([]);

  const addConditionGroup = (interactionID, logicalOperator) => {
    let _filtersMeta = produce(data, interactions => {
      interactions.map((interaction) => {
        if (interaction.id === interactionID) {
          let lastConditionGroupID = interaction.filter_groups?.length
            ? interaction.filter_groups[interaction.filter_groups.length - 1].id +
              1
            : 1;
          const conditionGroupData = {
            id: lastConditionGroupID,
            logical_op: logicalOperator,
            filters: [
              {
                id: 1,
                val: null,
                filterKey: "",
                operator: "",
              },
            ],
          };
          interaction.filter_groups = [
            ...interaction.filter_groups,
            conditionGroupData,
          ];
          return interaction;
        } else {
          return interaction;
        }
      });
    });
    fn.addConditionGroup(_filtersMeta);
  }
  useEffect(() => {
    if(data?.length) {
      setParentData([...data]);
    }
  }, [data]);
  
  return (
    <InteractionsWrapper>
            {parentData?.map((interaction, interactionIdx) => (
              (interaction?.filter_groups?.length ? 
                <InteractionsSubWrapper
                key={interaction.id}
                className="d-flex flex-column"
                >
                {showSampleCount ? <SampleCountWrapper>
                  <SampleCount>
                    <div>
                      Select {" "}
                      {!textOnly ? <StyledInput
                        iserror = {showErrorState && interaction.limit === ""}
                        style={{
                          width: "40px",
                          height: "32px",
                          textAlign: "center",
                          marginBottom: "0"
                        }}
                        value={interaction.limit}
                        onChange={(e) => {
                          fn.onInteractionLimitChange(e, interaction.id);
                        }}
                      /> : interaction.limit}{" "}
                      interactions if
                    </div>
                    {
                      showErrorState && interaction.limit === "" ? <ErrorState>
                        <ErrorRoundedIcon className="css-qa-error-state-icon" />
                        <span>Interaction limit value is required</span>
                      </ErrorState> : null
                    }
                  </SampleCount>
                  {interactionIdx !== 0 && (!textOnly) ? (
                    <DeleteIcon
                      className="css-interaction-delete-icon"
                      onClick={() => {
                        fn.onDeleteInteraction(interaction.id);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </SampleCountWrapper> : null}
                <ConditionGroupWrapper className="d-flex flex-column">
                  {interaction.filter_groups?.map(
                    (conditionGroup, condtionGroupIdx) =>
                      conditionGroup.filters?.length ? (
                        <ConditionGroupComponent key = {conditionGroup.id} conditionGroupData = {conditionGroup} filterOptions = {filterOptions} textOnly = {textOnly} showErrorState = {showErrorState} condtionGroupIdx = {condtionGroupIdx} interactionIdx = {interactionIdx} interaction = {interaction} fn = {fn} />
                      ) : (
                        ""
                      )
                  )}
                </ConditionGroupWrapper>
                {interaction.filter_groups?.length < 2 && (!textOnly) ? (
                  <AddConditionGroupWrapper
                    onClick={() => {
                      addConditionGroup(interaction.id, interaction.logical_op);
                    }}
                  >
                    + Condition group
                  </AddConditionGroupWrapper>
                ) : null}
              </InteractionsSubWrapper> 
              
              : "")
            ))}
          </InteractionsWrapper>
  )
};

export default ConditionGroupParent;
