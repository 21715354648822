import {
    AgentFilterCategoryMap,
    DashboardFilterCategoryMap,
    AgentFiltersList,
    DashboardFiltersList
} from "../config";
import { TIMELINE_TYPES } from "../Constants";
import { groupBy, isNil } from "lodash";

const getAgentFilterData = (data, filter, showCommonTab) => {
    if(!filter || showCommonTab) {
        return [...data];
    }
    return data.filter((item) => {
        return AgentFilterCategoryMap[filter].includes(item.category);
    })        
}

const getDashboardFilterData = (data, filters) => {
    const showAll = DashboardFiltersList.filter(item => filters[item.filter]).length === 0;
    let filterCategories = [];
    if(!showAll) {
        DashboardFiltersList.forEach((item) => {
            if(filters[item.filter]) {
                filterCategories = filterCategories.concat(DashboardFilterCategoryMap[item.filter]);
            }
        })            
    }
    return data.filter((item) => {
        return showAll || filterCategories.includes(item.category);
    })
}

const getAgentNotifications = (data, agentFilter, state) => {
    const { filterNotificationCount, filterAppliedTimestamp, showCommonTab } = state; 
    const updatedNotificationsCount = {
        ...filterNotificationCount,
    };
    const cardTypes = AgentFiltersList.filter((item) => {
        if(showCommonTab && item.filter === TIMELINE_TYPES.KNOWLEDGE) return false;
        return true;
    }).map((i) => i.filter);
    for(let i=0; i<cardTypes.length; i++) {
        const cardType = cardTypes[i];
        if(cardType === agentFilter) {
            updatedNotificationsCount[i] = 0;
        }
        else {
            updatedNotificationsCount[cardType] = data.filter((item) => {
                return item.clientAddedTime && item.clientAddedTime > filterAppliedTimestamp && AgentFilterCategoryMap[cardType].includes(item.category);
            }).length;
        }
    }
    return updatedNotificationsCount;
}

const getContextByGroupedCard = (data) => {
    const conversationContextCards = data.filter((item) => !!item.event_name).sort((a, b) => {
        const diff = (new Date(b.created)).getTime() - (new Date(a.created).getTime());
        if(diff === 0) {
            return b.id - a.id;
        }
        return diff;
    });
    const speakerContextCards = data.filter((item) => !!!item.event_name);
    //Group by transcript id
    const groupByTranscriptionId = groupBy(speakerContextCards, "transcription_id"); 
    let speakerTimeGroupCards = [];
    for(var i in groupByTranscriptionId) {
        let context;
        let timestamp;
        const cards = groupByTranscriptionId[i];
        for(var index = 0; index< cards.length; index++) {
            const card = cards[index];
            context = card.speaker;
            if(index === 0) {
                timestamp = card.transcriptCreatedAt;
            }
            else if(timestamp < card.transcriptCreatedAt) {
                timestamp = card.transcriptCreatedAt;
            }
        }
        const searchByIdResults = groupBySearchId(cards);
        speakerTimeGroupCards.push({
            groups: searchByIdResults,
            context: context,
            contextTitle: context === "Customer" ? "Customer email" : "Agent response",
            created: timestamp,
        })
    }
    speakerTimeGroupCards = speakerTimeGroupCards.sort((a, b) => {
        return (new Date(b.created)).getTime() - (new Date(a.created).getTime());
    });
    if(speakerTimeGroupCards.length) {
        speakerTimeGroupCards[0].defaultOpen = true;
    }
    const contextGroupedCards = [];
    if(conversationContextCards.length) {
        contextGroupedCards.push({
            groups: [{
                cards: conversationContextCards,
            }],
            context: "Case",
            contextTitle: "Case Context",
            defaultOpen: true,
            created: conversationContextCards.length ? conversationContextCards[0].transcriptCreatedAt : 0,   
        });
    }
    return [
        ...contextGroupedCards,
        ...speakerTimeGroupCards
    ];
}

const groupBySearchId = (data) => {
    //search_group_id can be null or undefined 
    const searchResults = data.filter((item) => !isNil(item.search_group_id));
    const remainingResults = data.filter((item) => isNil(item.search_group_id));
    const groupBySearchMap = groupBy(searchResults, "search_group_id");    
    let searchByIdResults = [];
    for(var i in groupBySearchMap) {
        let timestamp;
        const cards = groupBySearchMap[i];
        for(var index = 0; index< cards.length; index++) {
            const card = cards[index];
            if(index === 0) {
                timestamp = card.created;
            }
            else if(timestamp < card.created) {
                timestamp = card.created;
            }
        }
        searchByIdResults.push({
            cards: cards,
            created: timestamp,
        })
    }
    for(let i=0; i<remainingResults.length; i++) {
        const card = remainingResults[i];
        searchByIdResults.push({
            cards: [card],
            created: card.created,
        });
    }
    searchByIdResults = searchByIdResults.sort((a, b) => {
        return (new Date(b.created)).getTime() - (new Date(a.created).getTime());
    });
    return searchByIdResults;
}

export const getInitialState = (data, isAgent, groupCardsByContext, showCommonTab, groupResults) => {
    const filteredData = isAgent ? getAgentFilterData(data, "ALL", showCommonTab) : [...data];
    const searchByIdResults = groupBySearchId(filteredData);
    return {
        data: data,
        filters: {},
        agentFilter: "ALL",
        filterNotificationCount: {},
        filterAppliedTimestamp: -1,
        showSearchResults: false,     
        filteredData: searchByIdResults,
        groupCardsByContext,
        showCommonTab, 
        groupResults,
        contextGroupedData: groupCardsByContext ? getContextByGroupedCard(filteredData) : [],        
    };    
}

const timelineReducer = (state, action) => {
    if(action.type === "APPLY_AGENT_FILTER") {
        const { filterNotificationCount, data, groupCardsByContext, showCommonTab } = state;
        const agentFilter = action.payload.agentFilter;
        const filteredData = getAgentFilterData(data, agentFilter, showCommonTab);;
        filterNotificationCount[agentFilter] = 0;        
        const searchByIdResults = groupBySearchId(filteredData);
        return {
            ...state,
            filteredData: searchByIdResults, 
            agentFilter, 
            filterAppliedTimestamp: (new Date()).getTime(),    
            filterNotificationCount,   
            showSearchResults: false,
            contextGroupedData: groupCardsByContext ? getContextByGroupedCard(filteredData) : [],        
        }
    }
    else if(action.type === "APPLY_DASHBOARD_FILTER") {
        const { key, value } = action.payload;
        const { data } = state;
        const filters = { 
            ...state.filters,
            [key]: value
        };
        return {
            ...state,
            filters, 
            filteredData: getDashboardFilterData(data, filters),  
            showSearchResults: false,         
        }
    }
    else if(action.type === "UPDATE_DATA") {
        const { agentFilter, filters, showCommonTab } = state;
        const { isAgent, data, groupCardsByContext } = action.payload;
        if(isAgent) {             
            const filteredData = getAgentFilterData(data, agentFilter, showCommonTab);
            const searchByIdResults = groupBySearchId(filteredData);
            return {
                ...state,
                data,
                filteredData: searchByIdResults,
                groupCardsByContext,
                filterNotificationCount: getAgentNotifications(data, agentFilter, state), 
                contextGroupedData: groupCardsByContext ? getContextByGroupedCard(filteredData) : [],       
            }
        }
        else {
            return {
                ...state,
                data,
                filteredData: getDashboardFilterData(data, filters),                
            }
        }        
    }
    else if(action.type === "SHOW_SEARCH_RESULTS") {
        return {
            ...state,
            showSearchResults: true,
            agentFilter: ""
        }        
    }
 }

export default timelineReducer;
