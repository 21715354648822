import React, { useState } from "react";
import { Modal, Input } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import { PrimaryStyledButton, StyledButton } from "../../../../components/common/styled-components";
import { useDispatch } from "../../../../store";
import { oktaSaml } from "../../../api/api";
import { ModalWrapper, ButtonSeperator, TextFieldWrapper, FieldWrapper, FooterWrapper, HeaderWrapper, HeadingWrapper, Heading, DescriptionWrapper } from './wrappers';
import { openNotification } from "../../../../utils/utilities";

const OktaSamlMetadata = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [metadata, setMetadata] = useState("");
  const [loading, setLoading] = useState(false);

  const { TextArea } = Input;

  const handleModal = () => {
    open ? setOpen(false) : setOpen(true);
    setUrl("");
    setMetadata("");
  };

  const sendReponse = () => {
    setLoading(true);
    dispatch(oktaSaml(url ? "url" : "text", url ? url : metadata )).then(res => {
      setUrl("");
      setMetadata("");
      setOpen(false);
      openNotification('bottomLeft', "Setup completed successfully.");
    }).catch(err => {

    }).finally(() => {
      setLoading(false);
    });
  };

  const handleMetadata = (event) => {
    setMetadata(event.target.value);
  };

  const handleUrl = (event) => {
    setUrl(event.target.value);
  };

  const buttonSeperator = () => {
    return <ButtonSeperator>
      <div className="lines"></div>
      <div className="text">OR</div>
      <div className="lines"></div>
    </ButtonSeperator>
  }
  return (
    <>
      <PrimaryStyledButton type="default" onClick={handleModal}>
        SAML 2.0 Setup
      </PrimaryStyledButton>
      <Modal
        visible={open}
        closable={false}
        //onCancel={() => { !loading && handleModal() }}
        title={null}
        width={500}
        footer={null}
      >
        <ModalWrapper>
        <HeaderWrapper>
          <HeadingWrapper>
            <Heading>SAML 2.0 Setup</Heading>
            <DescriptionWrapper>
              Provide your Identity Provider metadata/URL for application configuration.
            </DescriptionWrapper>
          </HeadingWrapper>
          <CloseIcon onClick={() => { !loading && handleModal() }}/>
          </HeaderWrapper>
          <TextFieldWrapper>
              <FieldWrapper>
                <label className="field-label">Embed link</label>
                <Input className="input-field-saml" disabled={loading} placeholder="Enter url.." value={url} onChange={handleUrl} />
              </FieldWrapper>
              { buttonSeperator() }
              <FieldWrapper>
                <label className="field-label">Metadata</label>
                <TextArea
                  className="text-area-field-saml"
                  autoSize={{minRows: 4, maxRows: 8}}
                  disabled={loading}
                  placeholder="Enter metadata.."
                  allowClear
                  onChange={handleMetadata}
                  value={metadata}
                />
              </FieldWrapper>
        </TextFieldWrapper>
        <FooterWrapper>
             <StyledButton
                disabled={(url === "" && metadata === "")}
                loading={loading}
                onClick={sendReponse}
                className="primary"
              >
                Send
              </StyledButton>
              <StyledButton disabled={loading} onClick={() => { !loading && handleModal() }} className="secondary">
                Cancel
              </StyledButton>
        </FooterWrapper>
      </ModalWrapper>
      </Modal>
    </>
  )
}
;

export default OktaSamlMetadata;
