import {
  axiosInstance,
  BASE_URL,
  getSchemaName,
  getToken,
} from "../../../api/api";

export const NOTIFICATION_ACCESS = `/notifications/notification-access/`;

export const updateAccess = (id, checked) => {
  return axiosInstance.patch(
    `${NOTIFICATION_ACCESS}${id}/`,
    {
      send_notification: checked,
    }
  );
};
