import React, { useState, useEffect } from 'react';
import { HeapAnalyticsEvent } from '../../components/common/HeapAnalyticsEvent';
import { agentFeedbackUpdate } from '../../user-dashboard/api';
import { addAnalyticsEvent } from '../../utils';
import moment from 'moment';

export const EVALUATION_STATES = {
    Accept: "ACCEPT",
    Reject: "REJECT",
    None: "NONE"
}

export const createDisputedCommentsList = (comments) => {
    let commentsList = comments?.map(comment => {
      return {
        first_name: comment?.accounts_user?.first_name,
        last_name: comment?.accounts_user?.last_name,
        speaker: comment?.accounts_user?.id,
        cf_answer: comment.cf_answer_id,
        question:comment.question_id,
        created:comment.created,
        is_dispute:false,
        asr_log:comment.asr_log_id,
        comment:comment.comment,
        id: comment?.id,
        modified: comment?.modified,
        deleted: comment?.deleted,
      }
    })
    return commentsList?.length ? commentsList : [];
}


export const useFeedback = () => {
    const [evaluated, setEvaluated] = useState({ agent_feedback: null, feedback_at: null, feedback_by: null});
    const [evaluationLoading, setEvaluationLoading] = useState({agent_feedback: null, loading: false});

    const sendFeedback = (type,props) => {
        setEvaluationLoading({agent_feedback: type, loading: true});
        agentFeedbackUpdate(props.conversationId , {agent_feedback: type, feedback_by: props.user.id , feedback_at: new Date()}).then(res => {
            setEvaluated({ agent_feedback: res.agent_feedback, feedback_at: res.feedback_at, feedback_by: res.feedback_by });
            setEvaluationLoading({agent_feedback: type, loading: false});
            addAnalyticsEvent(HeapAnalyticsEvent.Set_Evaluation_Status, {
              status: type,
            });
        }).catch(err => {
            //setEvaluated({agent_feedback: null, feedback_at: null, feedback_by: null});
            setEvaluationLoading({agent_feedback: null, loading: false});
        })
    }
    
    return [evaluated, evaluationLoading, sendFeedback, setEvaluated];
};

export const checkIfComplete = (rubricResponse) => {
  if (!rubricResponse.categories) {
    return false;
  }

  for (let category of rubricResponse.categories) {
    for (let section of category.sections) {
      for (let question of section.questions) {
        if (!question.options.some((option) => option.is_selected)) {
          return false;
        }
      }
    }
  }
  return true;
};

export const getSectionScore = (section, ruleMetaData) => {
  let score = 0;
  for (let question of section.questions) {
    if (question.scoring == 0) {
      // In some questions, score is undefined. Adding undefined to a number will give NaN (Not a number). This ruins the overall section scoring.
      // Make sure to add 0 in such a case.
      score += question?.score || 0;
    } else {
      if (
        (ruleMetaData?.critical_question === "zero_out_section" || question.scoring == 2 || question.scoring == 3) &&
        question.score === 0
      ) {
        // zero out section score
        return 0;
      }
    }
  }
  return score;
};

export const getSectionMaxScore = (section) => {
  let score = 0;
  for (let question of section.questions) {
    if (question.scoring == 0) {
      score += Math.max(...question.options.map((x) => x.score));
    }
  }
  return score;
};

export const getCategoryMaxScore = (category) => {
  let score = 0;
  for (let section of category.sections) {
    score += getSectionMaxScore(section);
  }
  return score;
};


export const getLatestCfAnswer = (arr) => {
  return arr?.length ? arr.reduce(
    (prev, current) => {
      if (!prev) return current;
      // Find the latest generated cfanswer in the array on the basis of created timestamp.
      return moment(prev?.created).isAfter(current?.created) ? prev : current;
    },
 ): null
}
