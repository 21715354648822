import { ReduxActionTypes } from "../constants/ReduxActionConstants";

export const setGlobalFilters = (filterState) => (dispatch) => {
  return new Promise((resolve, reject) => {
    resolve(dispatch({
    type: ReduxActionTypes.SET_GLOBAL_FILTERS,
    payload: { ...filterState?.globalFilterObject, ...filterState?.localFilterObject },
  }))}).then(() => {
    return "ok";
  })
}
