import { Table, Input, Button, Checkbox, Timeline, Switch, Radio, Select } from "antd";
import styled from "@emotion/styled/macro";

export const StyledButton = styled(Button)`
  background: #0B4B93;
  color: white;
  font-weight: 400;
  font-size: 16px;
  border: none;
  .ant-btn {
    height: 35px;
  }
  span {
    text-align: center;
    width: 100%;
  }
  &:hover, &:active, &:focus {
    background: #0B4B93;
    color: white;
  }  
`;

export const StyledRadioGroup = styled(Radio.Group)`
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #0B4B93;
  }
  .ant-radio-button-wrapper {
    font-size: 11px;
  }
  .ant-radio-button-wrapper-checked {
    font-weight: bold;
  }
`

export const PrimaryStyledButton = styled(Button)`
  border: 1px solid #6099D9;
`;

export const PrimaryButton = styled(Button)`
  margin-left: 6px;
  font-weight: normal;
`;

export const StyledDetailsTable = styled(Table)`
  width: 100%;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #F2F2F2;
  color: #828282;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto;
  .ant-table {
    color: #828282;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 10px;
  }
  .ant-pagination {
    display: none;
  }
  .ant-table-column-title {
    color: #828282;
    font-size: 11px;
    line-height: 13px;    
  }  
  .ant-table-thead > tr > th {
    background: #ffffff;
    padding: 11px 10px;
    color: #BDBDBD;
  }
  .ant-table-placeholder {
    display: ${props => props.showLoading && "none"};
  }  
`;

export const StyledTable = styled(StyledDetailsTable)`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  .ant-table-row  {
    height: 67px;
  }
  td {
    font-size: 14px;
    line-height: 16px;
    color: #4F4F4F;
  }
`;

export const StyledSearch = styled(Input.Search)`
  .ant-input-search {
    width: 220px;
  }
  input {
    height: 42px;
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 16px;
  height: 42px;  
  padding: 4px 10px;
  border: ${props => props.iserror ? "1px solid #DB554D" : "1px solid #d9d9d9"};
`;

export const StyledSelect = styled(Select)`
  // & .ant-select-selection {
  //   border: ${props => props.iserror ? "1px solid #DB554D" : "1px solid #d9d9d9"};
  // }
`;


export const StyledTextArea = styled(Input.TextArea)`
  margin-bottom: 16px;
  padding: 4px 10px;
`;

export const StyledSearchInput = styled(Input)`
  margin-bottom: 16px;
  height: 32px;  
  width: 220px;
  padding: 4px 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: ${props => props.marginLeft ?? 16}px;
  white-space: nowrap;  
`;


export const StyledTimeline = styled(Timeline)`
  padding: 0 15px 15px;
  max-height: calc(100vh - 200px);
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
  min-height: ${props => props.isAgent && "100vh"};
  display: ${props => props.hide && "none"};
`;

export const StyledDateRangePickerWrapper = styled.div`
  .DateRangePickerInput__withBorder {
    display: flex;
    border: none;
    border-bottom-width: 2px;
    border-bottom-color: #2580ef;
    border-bottom-style: solid;
    width: 160px;
  }
  .DateInput_input {
    font-size: 12.5px;
    line-height: 10px;
    padding: 7px 0 5px;
    text-align: center;
  }
  .DateInput_input__focused {
    border: none;
  }
  .DateRangePickerInput_arrow::before {
    content: "to";
    font-size: 12.5px;
    display: inline-block;
    margin-top: 6px;
    color: black;
  }
  .DateRangePickerInput_arrow_svg {
    display: none;
  }
  @media (max-width: 1250px) {
    margin-left: 10px;
  }
`;

export const KeywordsWrapper = styled.div`
    height: 26px;
    border-radius: 20px;
    background: rgb(120, 184, 255, 0.2);
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    color: #2580ef;
    line-height: 26px;
    margin-left: 7px;
    margin-bottom: 8px;
    padding: 2px 15px;
    box-sizing: content-box;
`;

export const StyledLinkButton = styled(Button)`
    font-size: 12px;
    line-height: 14px;
    padding: 0;
    height: 14px;
    color: #6099D9;
`;

export const TitleDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .description {
      margin-top: 2px;
      font-size: 12px;
      line-height: 14px;
      color: #BDBDBD;
    }
    .setup-tags {
      margin-top: 10px;
    }
    .rule-title, .rule-title a {
      color: #4F4F4F;
    }
    .user-name {
      text-transform: capitalize;
    }
    &:hover .rule-title, &:hover .rule-title a {
      color: #6099D9;
      cursor: pointer;
    }
`;

export const StyledSwitch = styled(Switch)`
  &&&[aria-checked="false"] {
    background: #E0E0E0;
  }
`;

export const InnerHtmlWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ResetPasswordWrapper = styled.div`
    max-width: 400px;
    width: 80%;
    margin: auto;
    padding-top: ${props => props.paddingTop};
    svg {
      margin: auto 0;
    }
`

export const ReenterPasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;

    background: #DFEBF7;
    border-radius: 4px;
`

export const HorizontalButtonWrapper = styled.div`
    display: flex;
    .right {
        margin-left: calc(82% - 130px);
        min-width: 100px;
        text-align: right;
    }
`
