import { all, takeLatest } from "redux-saga/effects";
import {
  ReduxActionTypes,  
} from "../constants/ReduxActionConstants";


function* handleCreateUser(action) {
  
}

export default function* userManagementSagas() {
  yield all([
    takeLatest(ReduxActionTypes.CREATE_USER, handleCreateUser),    
  ]);
}
