import React from "react";
import { Modal } from "antd"; 
import styled from "@emotion/styled/macro";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`;

const PopupWrapper = styled.div`
  position: relative;
`;

const TriggerWrapper = styled.div`
`;

class PopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        isOpen: false
    };
  }
  componentDidUpdate(prevProps) {
    if(this.props.isOpen && !prevProps.isOpen && !this.state.isOpen) {
      this.setState({ isOpen: true });
    }    
  }
  onSubmit = () => {
    this.setState({ isOpen: false });    
    this.props.onSubmit();
  }
  onCancel = () => {
    this.setState({ isOpen: false });
    this.props.onCancel();
  }  

  render() {
    const {
      component: Component,
      trigger: Button,
      triggerProps,
      componentProps,
      onSubmit,
    } = this.props;
    const {
      width: popupWidth,
      height: popupHeight,
      ...componentRestProps
    } = componentProps;    
    return (
      <PopupWrapper>
        <TriggerWrapper>
          <Button 
            disabled = {this.props?.isButtonDisabled || false}
            type="default" 
            onClick={() => {
                if(!this.state.isOpen) {
                  this.setState({ isOpen: true });
                }
            }}
          >{triggerProps.title}</Button>
        </TriggerWrapper>
        <Modal
          visible={this.state.isOpen}
          header = {null}
          closable={false}
          onCancel={this.onCancel}
          title={null}
          width={popupWidth}
          footer={null}
        >
          <ModalWrapper>
            <Component {...componentRestProps} isOpen={this.state.isOpen} onCancel={this.onCancel} onSubmit={(e) => {
                onSubmit(e);
                this.setState({
                    isOpen: false
                })
            }}/>
          </ModalWrapper>
        </Modal>
      </PopupWrapper> 
    )
  }
}

export default PopUp;
