import React, { useState, useEffect, useRef } from "react";
import { Chart, Axis, Geom, Interaction, Legend } from "bizcharts";
import { cubeJSApi } from "./api";
import {
  createStackedChart,
  getMeasureText,
  getTooltipText,
  roundOff,
  shouldEnableDrilldown,
} from "./utils";
import {
  CustomTooltip,
  TooltipWrapper,
  TooltipSubWrapper,
  TextWrapper,
  ButtonWrapper,
  EmptyStateWrapper,
} from "./components/wrapper";
import { message, Spin } from "antd";
import { qualityAssessmentQueryBuilder } from "./query-builder";
import {
  formatRawValue,
  getColor,
  getLabel,
  placeHolderChartData,
  sortChartData,
  stackedChartData,
} from "./chart-renderer/config";
import EmptyState from "../components/common/EmptyState";
import { handleDrilldown } from "./utils";
import { queryOptions } from "./dynamic-query-builder";
import { useSelector } from "../store";
import { addAnalyticsEvent } from "../../src/utils";
import { HeapAnalyticsEvent } from "../components/common/HeapAnalyticsEvent";
import Constants from "../CompanySetup/utils/constants";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/ErrorFallback";
import { getSchemaName } from "../api/api";
import styled from "@emotion/styled/macro";
import ExportDynamicChart from "../components/common/ExportDynamicChart";
import ExportReportButton from "./components/commons/ExportReportButton";
import { renderTable } from "./components/query-table";

const MeasureTooltipRowWrapper = styled.div`
  width: 100%;
  display: flex;
  min-width: 100px;
  margin-top: 0.5rem;
`;

const MeasureTooltip = ({ data, type }) => {
  const postfix = type === queryOptions.tooltipOptions.OUTCOME_PERCENT && "%";
  const getTooltipData = () => {
    if (type === queryOptions.tooltipOptions.OUTCOME_PERCENT) {
      return data.filter((x) => {
        return x.measure > 0;
      });
    } else {
      return data;
    }
  };
  
  return (
    <>
      {getTooltipData()
        .filter((x) => x.measure !== 0)
        .map((x) => (
          <MeasureTooltipRowWrapper>
            <div className="me-1">{getTooltipText(x.color)}</div>:
            <div className="ms-1">
              {x.measure}
              {postfix}
            </div>
          </MeasureTooltipRowWrapper>
        ))}
    </>
  );
};

const DynamicChart = (props) => {
  // STORE STATES
  const segments = useSelector((state) => state.dashboard.segments);
  const globalFilters = useSelector((state) => {
    if (props.overrideFilters) return props.overrideFilters;
    return state.dashboard?.globalFilters;
  });
  const filterSegments = useSelector((state) => state.dashboard.filterSegments);
  const selectedWorkspace = useSelector(
    (state) => state.app_user?.selectedWorkspace
  );
  const customFilters = useSelector(state => state.dashboard.customFilters);


  // STATES
  const [data, setData] = useState([]);
  const [resultSet, setResultSet] = useState([]);
  const [tooltipData, setTooltipData] = useState([]);
  const [filters, setFilters] = useState(props.filters);
  const [groupBy, setGroupBy] = useState(props.groupBy);
  const [query, setQuery] = useState();
  
  
  const [chartType, setChartType] = useState(props.chartType);
  const [drilldownQueries, setDrillDownQueries] = useState([]);
  const [isChartValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [measureText, setMeasureText] = useState(
    getMeasureText(props.query.measure)
  );
  const [tickCount, setTickCount] = useState(30);
  const additional_filters = props.query.filters;
  const [pivotConfig, setPivotConfig] = useState();
  const [timeDimension, setTimeDimension] = useState(props.timeDimension);

  // SIDE EFFECTS
  useEffect(() => {
    setGroupBy(props.groupBy);
    setFilters(props.filters);
    setTimeDimension(props.timeDimension);
  }, [props]);

  useEffect(() => {
    setMeasureText(getMeasureText(props.query.measure));
    queryAndReload();
  }, [
    props.chartType,
    filters,
    groupBy,
    props.selectedChart,
    globalFilters,
    selectedWorkspace,
    props.ordering,
    props.timeDimension
  ]);

  const fixResponse = (chartData, unfilteredChartData) => {
    let data = [...chartData];
    if (unfilteredChartData) {
      data.forEach((el, i) => {
        let unfilteredVal = unfilteredChartData.filter((unfilteredEl) => {
          return el.x === unfilteredEl.x && el.color == unfilteredEl.color;
        })[0];
        if (unfilteredVal) {
          data[i].unfilteredValue = unfilteredVal.measure;
        }
      });
    }
    data = data.filter((x) => !!x.measure);
    if (data.length !== 0) {
      setData(data);
      setMeasureText(data[0]?.measureText);
      setIsValid(true);
    } else {
      // Placeholder to avoid the bug with empty charts
      setIsValid(false);
      setData(placeHolderChartData);
      setMeasureText("Conversations");
    }
    setTickCount(chartData?.length || 30);
    setIsLoading(false);
  };

  const getPercentageOfTotalData = (chartData) => {
    if (props.query.showPercentOfFilter) {
      let xValues = chartData.map((value) => value.x).filter(x=>x!='∅');
      let _query = qualityAssessmentQueryBuilder({
        ...props.query,
        groupBy,
        filterbyValues: {
          ...filters,
          agents: xValues,
        },
        globalFilters: {
          date: globalFilters.date,
          dateRangeValues: globalFilters.dateRangeValues,
          timeGranularity: globalFilters.timeGranularity,
        },
        ordering: props.ordering,
        removeLimit: true,
      });
      cubeJSApi().then((api) => {
        api.load(_query).then((resultSet) => {
          let unfilteredChartData = stackedChartData(
            resultSet,
            getMeasureText(props.query.measure),
            props.query.isTimeUnit,
            props.onTimeUnitUpdate
          );
          fixResponse(chartData, unfilteredChartData);
        });
      });
    }
  };

  const prepareQuery = (groupBy, _cubeJSQuery) => {
    const _groupBy = ((groupBy) => {
      if (!groupBy) {
        return props.query.groupBy;
      } else {
        return groupBy;
      }
    })(groupBy);
    setGroupBy(state => Array.isArray(_groupBy) ? _groupBy[0] : _groupBy);
    
    let cubeJSQuery =
      _cubeJSQuery ||
      qualityAssessmentQueryBuilder({
        ...props.query,
        groupBy: _groupBy,
        filterbyValues: { ...filters },
        globalFilters,
        ordering: props.ordering,
        timeDimension: props.timeDimension,
        limit: props.chartType == "table" ? 5000 : null
      });
    if (additional_filters) {
      cubeJSQuery.filters = [...cubeJSQuery.filters, ...additional_filters];
    }
    cubeJSQuery.measures = cubeJSQuery.measures.map(measure => {
      // support queries having old measure names
      return measure.replace("BankingBankingconversation", "Conversation")
    })
    return cubeJSQuery;
  };

  const queryAndReload = (groupBy, _cubeJSQuery) => {
    let cubeJSQuery = prepareQuery(groupBy, _cubeJSQuery);

    if (
      JSON.stringify(query) !== JSON.stringify(cubeJSQuery) ||
      props.chartType !== chartType
    ) {
      setChartType(props.chartType);
      setIsLoading(true);
      setQuery(cubeJSQuery);

      cubeJSApi().then((api) => {
        api
          .load(cubeJSQuery)
          .then((resultSet) => {
            setResultSet(resultSet);

            setPivotConfig({
              "x": cubeJSQuery?.dimensions,
              "y": [
                  "measures"
              ],
              "fillMissingDates": true,
              "joinDateRange": false
            });

            let chartData = stackedChartData(
              resultSet,
              getMeasureText(props.query.measure),
              props.query.isTimeUnit,
              props.onTimeUnitUpdate
            ).filter((x) => {
              const colors = x.color.split(", ");
              if (colors.includes(`${props.query.measure}`)) {
                return true;
              }
              if (props.query.stackMeasures) {
                let filter = false;
                props.query.stackMeasures.forEach((measure) => {
                  if (colors.includes(`${measure}`)) {
                    filter = true;
                  }
                });
                if (filter) {
                  return true;
                }
              }
              return false;
            });

            // code to fix issue with ordering for preaggregations. need to find a better solution for this
            if (props.query.stackMeasures) {
              chartData = chartData.filter((element) => {
                return props.query.stackMeasures.includes(element.color)
              })
            }

            fixResponse(chartData);
            getPercentageOfTotalData(chartData);
            setTooltipData(
              stackedChartData(
                resultSet,
                getMeasureText(props.query.measure),
                props.query.isTimeUnit,
                props.onTimeUnitUpdate
              ).filter((x) =>
                props.query.tooltipMeasures
                  ?.map(
                    (tooltipMeasure) => `${tooltipMeasure}`
                  )
                  .includes(x.color)
              )
            );
          })
          .catch((e) => {
            console.log(e);
            message.error("Invalid Query");
          });
      });
    }
  };

  const getChartDataToExport = () => {
    let cubeJSQuery = prepareQuery();
    cubeJSQuery.limit = 5000;
    return cubeJSApi().then((api) => {
      return api
        .load(cubeJSQuery)
        .then((resultSet) => {
          let chartData = stackedChartData(
            resultSet,
            getMeasureText(props.query.measure),
            props.query.isTimeUnit,
            props.onTimeUnitUpdate
          )
          return chartData;
        })
        .catch((e) => {
          console.log(e);
          message.error("Invalid Query");
          return -1;
        });
    });
  };

  const formatXValue = (val, ellipses = true) => {
    return formatRawValue(
      val,
      groupBy,
      props.query.isTimeSeries,
      { ...segments },
      ellipses
    );
  }

  const formatYValue = (val) => {
    return val > 1000 ? `${val / 1000}K` : roundOff(val);
  };

  const labelFormatter = (text, item, index) =>
    getLabel(text, data, { ...segments });
  // const labelFormatter = (text) => formatRawValue(text, groupBy, props.query.isTimeSeries, {...segments})

  const segmentsLoaded = () => {
    return segments !== undefined && segments !== {};
  };

  const shouldShowFilter = () => {
    const filters = { ...globalFilters };
    delete filters.date;
    delete filters.dateRangeValues;
    delete filters.timeGranularity;
    delete filters.dateRange;
    filters.channel === "" && delete filters.channel;
    return (
      props.query.showPercentOfFilter &&
      Object.values(filters).filter((x) => x).length > 0
    );
  };

  return (
    <div style={{ paddingTop: "0.8rem" }}>      
      {isChartValid && !isLoading && segmentsLoaded() ? (
          chartType === "table" ?
            renderTable({resultSet, pivotConfig, segments})
            :
            <Chart
              animate={false}
              scale={{
                x: {
                  tickCount: tickCount,
                },
              }}
              padding={[30, 0, 115, 70]}
              autoFit
              height={340}
              style={{ margin: "1rem" }}
              data={props.query.isTimeSeries ? data : sortChartData(data)}
            >
              {!props.query.isTimeSeries && (
                <div
                  className="d-flex justify-content-end"
                  style={{ position: "absolute", top: "-10px", right: 0 }}
                >
                  {
                    props.title === "Average Questions Score" && 
                      <ExportReportButton
                        report="question"
                      />
                  }

                  {
                    props.title === "Average Sections Score" && 
                      <ExportReportButton
                        report="section"
                      />
                  }

                  <ExportDynamicChart
                    fetchData={getChartDataToExport}
                    groupBy={groupBy}
                    formatXValue={formatXValue}
                    isTimeSeries={props.query.isTimeSeries}
                  />
                </div>
              )}

              <Axis
                name="x"
                line={{
                  style: {
                    stroke: "#000",
                    fill: "#ffffff",
                    // lineDash: [2, 2, 2],
                    lineWidth: 1,
                  },
                }}
                label={{
                  formatter: formatXValue,
                  style: {
                    fill: "#000000", // 文本的颜色
                    fontSize: 10,
                    textAlign: "right",
                  },
                  rotate: -Math.PI / 3,
                  autoHide: false,
                  autoEllipsis: false,
                  autoRotate: false,
                }}
                grid={{
                  line: {
                    // 当line为null时则不展示网格线
                    type: "line", // 网格线类型 line circle polygon
                    style: {
                      stroke: "#ffffff", // 网格线的颜色
                      lineWidth: 1, // 网格线的宽度复制代码
                      // lineDash: [4, 4] // 网格线的虚线配置，第一个参数描述虚线的实部占多少像素，第二个参数描述虚线的虚部占多少像素
                    },
                  },
                }}
              />
              {segments ? (
                <Legend
                  name="color"
                  position="top-left"
                  offsetY={"100px"}
                  // offsetX='100px'
                  itemName={{ formatter: labelFormatter }}
                />
              ) : (
                <Legend name="color" visible={false} />
              )}

              <CustomTooltip
                visible={true}
                enterable={true}
                lock={true}
                title={measureText + " "}
              >
                {(title, items) => {
                  return (
                    <TooltipWrapper>
                      {items.map((item, index) => {
                        return (
                          <TooltipSubWrapper>
                            <TextWrapper>
                              {props.showPropOnX && item?.data?.x && (
                                <h4 className="ms-3">
                                  {formatXValue(item.data.x, false)}
                                </h4>
                              )}
                              <span
                                style={{
                                  backgroundColor: item.color,
                                  width: 8,
                                  height: 8,
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  marginRight: 8,
                                }}
                              ></span>
                              {shouldShowFilter() && (
                                <>
                                  Percent Of Total :{" "}
                                  {`${
                                    roundOff(
                                      item.value / item.data.unfilteredValue
                                    ) * 100
                                  }`.substring(0, 5)}
                                  % <br />
                                </>
                              )}
                              {measureText} : {roundOff(item.value)}
                            </TextWrapper>
                            <div className="ms-3">
                              {tooltipData.length !== 0 && (
                                <MeasureTooltip
                                  data={tooltipData.filter(
                                    (x) => x.x === item.data.x
                                  )}
                                  type={props.tooltip}
                                />
                              )}
                              {shouldEnableDrilldown(props, globalFilters) && (
                                <ButtonWrapper
                                  className="mt-2 px-2"
                                  bgColor={item.color}
                                  onClick={() => {
                                    let drilldownFilters = { ...globalFilters };
                                    let propsData = { ...props };
                                    handleDrilldown(
                                      drilldownFilters,
                                      item,
                                      props.query.isTimeSeries
                                        ? queryOptions.drilldownByOptions.DATE
                                        : groupBy?.toLowerCase(),
                                      filters,
                                      segments,
                                      data,
                                      propsData,
                                      filterSegments,
                                      customFilters
                                    );
                                    addAnalyticsEvent(
                                      HeapAnalyticsEvent.Detect_Chart_Click,
                                      { chartName: propsData.title }
                                    );
                                  }}
                                >
                                  {" "}
                                  View{" "}
                                </ButtonWrapper>
                              )}
                            </div>
                          </TooltipSubWrapper>
                        );
                      })}
                    </TooltipWrapper>
                  );
                }}
              </CustomTooltip>
              <Axis
                name={measureText}
                label={{ formatter: formatYValue }}
                title={{
                  style: {
                    fontSize: 10,
                    textAlign: "center",
                    fill: "#999",
                    fontWeight: "bold",
                    margin: 10,
                  },
                }}
                line={{
                  style: {
                    stroke: "#000",
                    fill: "#ffffff",
                    // lineDash: [2, 2, 2],
                    lineWidth: 1,
                  },
                }}
                grid={{
                  line: {
                    // 当line为null时则不展示网格线
                    type: "line", // 网格线类型 line circle polygon
                    style: {
                      stroke: "#EFEFEF", // 网格线的颜色
                      lineWidth: 1, // 网格线的宽度复制代码
                      // lineDash: [4, 4] // 网格线的虚线配置，第一个参数描述虚线的实部占多少像素，第二个参数描述虚线的虚部占多少像素
                    },
                  },
                }}
              />
              <Geom
                type={chartType}
                adjust={
                  props.query.stackMeasures || Array.isArray(props.query.measure)
                    ? "stack"
                    : chartType === "interval"
                    ? [{ type: "dodge", marginRatio: 0 }]
                    : undefined
                }
                position={`x*${measureText}`}
                color={[
                  "color",
                  (...args) => {
                    return getColor(args, data[0].colorKeys);
                  },
                ]}
                style={{ cursor: "pointer" }}
              />
              <Interaction type="active-region" />
            </Chart>
      ) : isLoading || !segmentsLoaded() ? (
        <EmptyStateWrapper>
          <Spin />
        </EmptyStateWrapper>
      ) : (
        <EmptyStateWrapper>
          <EmptyState type={Constants.EMPTY_STATE_VIEWS.BASIC} />
        </EmptyStateWrapper>
      )}

      {drilldownQueries.map((x) => {
        return <DynamicChartRenderer {...props} {...x} />;
      })}
    </div>
  );
};

const DynamicChartRenderer = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <DynamicChart {...props} />
  </ErrorBoundary>
);

export default DynamicChartRenderer;
