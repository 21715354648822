import React, { useState } from "react";
import { getItemList } from "../api/api"
import Constants from "../utils/constants"
import FilterComponent from "../../components/common/FilterComponent";
import { Spin } from 'antd';
import styled from "@emotion/styled/macro";

const IntentDropdownWrapper = styled.div`
    .ant-select {
        width: 425px;
        margin-left: 10px;
    }
`;

const IntentDropdown = ({name, handleChange, showIntentPopup, initialValue, hideAddNewOption}) => {
    const intentOptions = hideAddNewOption ? [] : [{
        label: "+ New Intent",
        value: "new-intent",
        is_action: true,
    }];
    const [ options, setOptions ] = useState(intentOptions)
    const [ value, setValue ] = useState(initialValue);
    const [ loading, setLoading ] = useState(true)

    if (loading) {
        getItemList(Constants.TAB_TYPE.INTENT, {list_all: true}).then(res => {
            const results = res.results.map((intent) => {
                return {
                    label: intent.description,
                    value: intent.description
                }
            });
            setOptions([
                ...options,
                ...results,
            ]);        
        })
        setLoading(false)
    }
    
    if (loading) {
        return (
            <Spin />
        )
    }
    return (
        <IntentDropdownWrapper>
            <FilterComponent 
                options={options}
                placeholder="Select Intent"
                value={value}
                showSearch = {true}
                hideClear
                handleChange={(value) => {
                    if (value === "new-intent") {
                        showIntentPopup(name).then((description) => {
                            setValue(description)
                        })
                    } else {
                        setValue(value)
                        handleChange({currentTarget: {value, name}})
                    }
                }}
            />
        </IntentDropdownWrapper>
    )
}

export default IntentDropdown;
