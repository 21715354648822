import {
  BASE_URL,
  getToken,
  getSchemaName,
  axiosInstance,
} from "../../api/api";
import Constants from "../utils/constants";
import appConfigs from "./../../config";
import { setupEditCategoryAPIObject } from "../utils/config";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import caseStatuses from "../../conversation-list/case_status.json";
import {
  getCalibrationStatusesList,
  getRoleSpecificList,
} from "../../QaCalibration/api";
import { UserRoles } from "../components/user-management/config";
import { getInitFilters } from "../../graphqlAPI/graphqlAPI";
import { dashboard } from '../../AnalyticsDashboard/components/index';
import { DASHBOARD_OPTIONS } from "../../user-dashboard/utils";
import Axios from "axios";

export const postRule = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/level-nlp/rules/`,
    
  });
  return response;
};

export const getRulesList = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/rules/`,
    
  });
  return response;
};

export const getRuleDetails = async (id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/rules/${id}`,
    
  });
  return response;
};

export const updateRule = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data,
    url: `/level-nlp/rules/${id}`,
    
  });
  return response;
};

export const verifyRule = async (id) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: {
      is_verified: true,
    },
    url: `/level-nlp/rules/verify/${id}`,
    
  });
  return response;
};

export const deleteRule = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    data,
    url: `/level-nlp/rules/${id}`,
    
  });
  return response;
};

export const postFaq = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/level-nlp/faq/`,
    
  });
  return response;
};

export const getFaqsList = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/faq/`,
    
  });
  return response;
};

export const getFaqDetails = async (id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/faq/${id}`,
    
  });
  return response;
};

export const updateFaq = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data,
    url: `/level-nlp/faq/${id}`,
    
  });
  return response;
};

export const deleteFaq = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    data,
    url: `/level-nlp/faq/${id}`,
    
  });
  return response;
};

export const verifyFaq = async (id) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: {
      is_verified: true,
    },
    url: `/level-nlp/faq/verify/${id}`,
    
  });
  return response;
};

export const postIntent = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/level-nlp/intent/`,
    
  });
  return response;
};

export const getDuplicateIntents = async (phrases) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data: {
      id: -1,
      description: "phrase check",
      phrases: phrases,
    },
    url: `/level-nlp/duplicate-intents/`,
    
  });
  return response;
};

export const getItemList = async (type, filters) => {
  const filterString = filters ? createFilterString(filters) : "";
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/${type}/${
      filterString ? "?" + filterString : ""
    }`,
    
  });
  return response;
};

export const createFilterString = (filtersObj) => {
  let filters = [];
  for (var i in filtersObj) {
    if (filtersObj[i] !== undefined) {
      filters.push(`${i}=${filtersObj[i]}`);
    }
  }
  return filters.join("&");
};

export const getItemDetails = async (type, id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/${type}/${id}`,
    
  });
  return response;
};

export const getIntentDetailsAnnotator = async (id, schema) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/annotation/get-intent-details/${id}`,
    headers: {
      Authorization: `Token ${getToken()}`,
      "X-DTS-SCHEMA": "annotation",
      "QUERY-SCHEMA": schema,
    },
  });
  return response;
};

export const getUniversalScenarios = (type, id) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/level-nlp/${type}/universal/${id}`,
    
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error();
    });
};

export const getItemPhraseConflicts = async (phrases) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/bulk-duplicate-intents`,
    data: {
      id: -1,
      description: "phrase check",
      phrases: phrases,
    },
    
  });
  return response;
};

export const updateItem = async (type, id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data,
    url: `/level-nlp/${type}/${id}`,
    
  });
  return response;
};

export const patchItem = async (type, id, data) => {
  const { data: response } = await axiosInstance({
    method: "PATCH",
    data,
    url: `/level-nlp/${type}/update-partial/${id}`,
    
  });
  return response;
};

export const activeItem = async (type, id, isActive) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: {
      is_active: isActive,
    },
    url: `/level-nlp/${type}/active/${id}`,
    
  });
  return response;
};

export const deleteItem = async (type, id, data) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    data,
    url: `/level-nlp/${type}/${id}`,
    
  });
  return response;
};

export const postItem = async (type, data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/level-nlp/${type}/`,
    
  });
  return response;
};

export const getUsersList = async (filters, cancelToken) => {
  const filterString = filters ? createFilterString(filters) : "";
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/accounts/users/list/${
      filterString ? "?" + filterString : ""
    }`,
    
    cancelToken: cancelToken?.token
  });
  return response;
};

export const getRolesList = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/accounts/roles/list/`,
    
  });
  return response;
};

export const createAgentUser = async (userData) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/agent/signup/`,
    data: userData,
    
  });
  return response;
};

export const createAdminUser = async (userData) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/manager/signup/`,
    data: userData,
    
  });
  return response;
};

export const createUser = async (userData) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/signup/`,
    data: userData,
    
  });
  return response;
};

export const updateUser = async (userData) => {
  const { id, ...updatedUserData } = userData;
  const { data: response } = await axiosInstance({
    method: "PUT",
    url: `/accounts/users/update/${id}`,
    data: updatedUserData,
    
  });
  return response;
};

export const deleteUser = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/accounts/users/${id}`,
    
  });
  return response;
};

export const checkModalTrainingStatus = async () => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/train/status/`,
    
  });
  return response;
};

export const startModalTraining = async () => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/train/`,
    
  });
  return response;
};

export const uploadResource = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/customer-resource/uploads/upload/`,
    
  });
  return response;
};

export const savePublicUrl = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/customer-resource/uploads/public_url/`,
    
  });
  return response;
};

export const getResourcesList = async (filter) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/customer-resource/uploads/?${filter}`,
    
  });
  return response;
};

export const deleteResource = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/customer-resource/uploads/${id}/`,
    
  });
  return response;
};

export const updateResource = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PATCH",
    data,
    url: `/level-nlp/customer-resource/uploads/${id}/`,
    
  });
  return response;
};

export const getGoogleDriveIntegrationURL = () => {
  const hostname = window.location.origin;
  const redirect_uri = `${hostname}${Constants.REDIRECT_URI}`;
  const base = "https://accounts.google.com/o/oauth2/v2/auth?";
  const scope = "https%3A//www.googleapis.com/auth/drive.readonly";
  const client_id = Constants.INTEGRATIONS[Constants.GOOGLE_DRIVE].CLIENT_ID;
  const params = `include_granted_scopes=true&response_type=code&state=${Constants.GOOGLE_DRIVE}&access_type=offline`;
  return `${base}scope=${scope}&redirect_uri=${redirect_uri}&${params}&client_id=${client_id}`;
};

export const getSalesForceIntegrationURL = () => {
  const hostname = window.location.origin;
  const redirect_uri = `${hostname}${Constants.REDIRECT_URI}`;
  const base = `${appConfigs.SALESFORCE.URL}services/oauth2/authorize?`;
  const scope = appConfigs.SALESFORCE.SCOPE;
  const client_id = Constants.INTEGRATIONS[Constants.SALESFORCE].CLIENT_ID;
  const params = `response_type=code&state=${Constants.SALESFORCE}&display=popup`;
  let url = `${base}scope=${scope}&redirect_uri=${redirect_uri}&${params}&client_id=${client_id}`
  return url;
};

export const getZendeskIntegrationURL = (subdomainValue) => {
  const hostname = window.location.origin;
  const redirect_uri = `${hostname}${Constants.REDIRECT_URI}`;
  const base = `${appConfigs.ZENDESK.URL(
    subdomainValue
  )}oauth/authorizations/new?`;
  const scope = appConfigs.ZENDESK.SCOPE;
  const client_id = Constants.INTEGRATIONS[Constants.ZENDESK].CLIENT_ID;
  const params = `response_type=code&state=${Constants.ZENDESK}`;
  return `${base}scope=${scope}&redirect_uri=${redirect_uri}&${params}&client_id=${client_id}`;
};

export const getSlackIntegrationURL = () => {
  const hostname = window.location.origin;
  const redirect_uri = `${hostname}${Constants.SLACK_REDIRECT_URL}`;
  const base = "https://slack.com/oauth/v2/authorize?";
  const scope = "files:write,incoming-webhook,chat:write";
  const client_id = "83304731892.2533599274180";
  return `${base}client_id=${client_id}&scope=${scope}&user_scope&redirect_uri=${redirect_uri}`;
};
export const SLACK_REDIRECT_URI = `${window.location.origin}${Constants.SLACK_REDIRECT_URL}`;
export const configureCloudApp = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/customer-resource/cloudapps/configure/`,
    
  });
  return response;
};

export const revokeCloudApp = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/customer-resource/cloudapps/revoke/`,
    
  });
  return response;
};

export const getCloudApps = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/customer-resource/cloudapps/`,
    
  });
  return response;
};

export const resendPassword = async (email) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/accounts/forgot-password/`,
    data: {
      email: email,
    },
    
  });
  return response;
};

export const updateMetricTag = async (id, metricData) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    url: `/level-nlp/metric-tag/${id}`,
    data: {
      id: id,
      ...metricData,
    },
    
  });
  return response;
};

export const runHistoricalReplay = async (ruleId, count) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/rules/historical-conversation-update/${ruleId}?num=${count}`,
    
  });
  return response;
};

export const getPhrasesList = async (type, filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/annotation/${type}/`,
    params: filters,
    
  });
  return response;
};

export const approvePhrase = async (type, phraseId) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    url: `/annotation/${type}/${phraseId}`,
    data: {
      is_approved: true,
      id: phraseId,
    },
    
  });
  return response;
};

export const rejectPhrase = async (type, phraseId) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/annotation/${type}/${phraseId}`,
    
  });
  return response;
};

export const exportPhrases = async (type) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/annotation/${type}/`,
    
  });
  return response;
};

export const getConversationTagsList = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/rules/list/`,
    params: filters,
    
  });
  return response;
};

export const addConversationTag = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/rules/`,
    data: data,
    
  });
  return response;
};

export const getConversationTagDetails = async (id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/rules/${id}`,
    
  });
  return response;
};

export const updateConversationTagDetails = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: data,
    url: `/level-nlp/v2/rules/${id}`,
    
  });
  return response;
};

export const patchConversationTagDetails = async (id, data) => {
  const { data: response } = await axiosInstance({
    method: "PATCH",
    data: data,
    url: `/level-nlp/v2/rules/${id}`,
    
  });
  return response;
};

export const deleteConversationTagDetails = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/level-nlp/v2/rules/${id}`,
    
  });
  return response;
};

export const verifyConversationTagRule = async (id) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: {
      is_verified: true,
    },
    url: `/level-nlp/v2/rules/verify/${id}`,
    
  });
  return response;
};

export const getMemberWorkspaceList = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/workspace/member_list/`,
    params: filters,
    
  });
  return response;
};

export const addUsersToWorkspace = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/workspace/add_workspace_user/`,
    data: data,
    
  });
  return response;
};

export const getWorkspaceList = async (filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/workspace/list/`,
    params: filters,
    
  });
  return response;
};

export const updateWorkspaceStatus = async (id, isActive) => {
  const { data: response } = await axiosInstance({
    method: "PATCH",
    url: `/workspace/${id}`,
    data: {
      is_active: isActive,
    },
    
  });
  return response;
};

export const getPlayGroundScenarios = async (data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/scenarios-playground/`,
    data,
    
  });
  return response;
};

export const reportListApi = async (filters) => {
  const filterString = filters ? createFilterString(filters) : "";
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/banking/analytics-report/list/${
      filterString ? "?" + filterString : ""
    }`,
    
  });
  return response;
};

export const updateReportApi = async (data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data: {
      id: data.id,
      report_name: data.report_name,
      frequency: data.frequency,
      sender: data.sender,
      is_active: data.is_active,
      recipients: data.recipients,
      filter_set: data.filter_set,
    },
    url: `/banking/analytics-report/update/${data.id}`,
    
  });
  return response;
};

export const deleteReportApi = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/banking/analytics-report/delete/${id}`,
    
  });
  return response;
};

export const getWorkspaceUsersList = async (workspace, filters) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/workspace/workspace_user_list/`,
    params: filters,
    headers: {
      Authorization: `Token ${getToken()}`,
      workspace: workspace,
      "X-DTS-SCHEMA": getSchemaName(),
    },
  });
  return response;
};

export const deleteWorkspace = async (id) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/workspace/${id}`,
    
  });
  return response;
};


export const getlistCategories = async (filters) => {
  const filterString = filters ? createFilterString(filters) : "";
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/category/${
      filterString ? "?" + filterString : ""
    }`,
    
  });
  return response;
};

export const createCategory = async (
  categoryTitle,
  phrasesArr,
  categoryType
) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/category/`,
    data: {
      title: categoryTitle,
      phrases: phrasesArr,
      category_type: categoryType,
    },
    
  });
  return response;
};

export const deleteCategory = async (categoryID) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/level-nlp/v2/category/${categoryID}`,
    
  });
  return response;
};

export const editCategory = async (
  categoryID,
  categoryTitle,
  phrasesArr,
  categoryType
) => {
  // let dataObj= categoryTitle===""? {phrases:phrasesArr} : {title:categoryTitle,phrases:phrasesArr};
  // phrasesArr.length===0 && delete dataObj.phrases;
  const { data: response } = await axiosInstance({
    method: "PATCH",
    url: `/level-nlp/v2/category/${categoryID}`,
    //data: categoryTitle===""?{phrases:phrasesArr,category_type:categoryType}:{title:categoryTitle,phrases:phrasesArr,category_type:categoryType},
    data: setupEditCategoryAPIObject(categoryTitle, phrasesArr),
    
  });
  return response;
};

export const editTitle = async (categoryID, categoryTitle) => {
  const { data: response } = await axiosInstance({
    method: "PATCH",
    url: `/level-nlp/v2/category/${categoryID}/`,
    data: categoryTitle === "" ? {} : { title: categoryTitle },
    
  });
  return response;
};

//api to fill the dropdown present on categorization page
export const dropdownCategories = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/category/_types`,
    
  });
  return response;
};

export const listAllCategories = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/category/?list_all=True`,
    
  });
  return response;
};

export const mergeCategories = async (targetCategoryID, sourceCategoryArr) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/category/${targetCategoryID}/_merge/`,
    data: {
      source_categories: sourceCategoryArr,
    },
    
  });

  return response;
};

export const trainCategoryModel = async () => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/level-nlp/v2/category/_train/`,
    
  });
  return response;
};

export const checkTrainCategoryModelStatus = async () => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/category/_train-status`,
    
  });
  return response;
};

export const getQAStatusList = () => (dispatch, getState) => {
  axiosInstance({
    method: "GET",
    url: `/qa/status_list`,
    
  })
  .then((response) => {
    const filterKey = Constants.FILTER_NAMES.QA_STATUS;
    dispatch({
      type: ReduxActionTypes.CACHE_FILTER_DATA,
      payload: { [filterKey]: response.data },
    });
    return response.data
  })
  .catch((err) => {
    const filterKey = Constants.FILTER_NAMES.QA_STATUS;
    dispatch({
      type: ReduxActionTypes.CACHE_FILTER_DATA,
      payload: { [filterKey]: [] },
    });
    return []
  });
};

export const singleRoleList = (role) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/accounts/users/list/?role=${role}&list_all=true`,
     
  }).then(response => {
    const filterKey = Constants.FILTER_NAMES.AGENTS_LIST;
    dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: response.data }});
    return response.data;
  }).catch(err => {
    const filterKey = Constants.FILTER_NAMES.AGENTS_LIST;
    dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: [] }});
    //throw new Error();
  });
}

export const storeFiltersSegment = () => async (dispatch, getState) => {
  const selectedWorkspace = getState().app_user.selectedWorkspace;
  dispatch({type: ReduxActionTypes.START_CACHING});
  
  try {
    const response = await getInitFilters(selectedWorkspace);
    analyticsSegment(response,dispatch);
    conversationTags(response,dispatch);
    metricList(response,dispatch);
    organizationList(response,dispatch);
    topicsList(response,dispatch);
    statusList(response,dispatch);
    dispatch({type: ReduxActionTypes.END_CACHING});
  } catch(e) {
    const {FILTER_NAMES: {CONVERSATION_TAGS,METRIC_TAGS,ORGANIZATIONS,CATEGORIES_LIST,CASE_STATUS_LIST}} = Constants
    dispatch({
      type: ReduxActionTypes.CACHE_FILTER_DATA,
      payload: { [CONVERSATION_TAGS]: [],[METRIC_TAGS]: [],[ORGANIZATIONS]: [],[CATEGORIES_LIST]:[],[CASE_STATUS_LIST]:[] },
    });
  }
};
const analyticsSegment = (response,dispatch) => {
  let data = response.data;
  let analytics = {
    scripting_rules: data.scripting_rules,
    categories: data.categories.map(item => item.category),
    questions: data.questions.map(item => item.question),
    sections: data.sections.map(item => item.section),
    topics: data.topics.map(item => item.topics),
    agents: data.users?.filter(user => user.role.includes(UserRoles.Agent)),
    selected_options: data.selected_options?.map(item => item.selected_option)
  }
  dispatch({
    type: "SET_ANALYTICS_SEGMENTS",
    payload: { value: analytics },
  });
  //let roleBasedUser = data.users?.filter(user => user.role.includes(UserRoles.Manager)||user.role.includes(UserRoles.Admin)) || []
  let roleBasedUser = data.users?.map(user => ({...user,role:user.role?.split(",")||[]}))
  dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [Constants.FILTER_NAMES.GLOBAL_USER_ROLE_LIST]: roleBasedUser }});
  return response.data;
}
const conversationTags = (response,dispatch) => {
  const filterKey = Constants.FILTER_NAMES.CONVERSATION_TAGS;
  dispatch({
    type: ReduxActionTypes.CACHE_FILTER_DATA,
    payload: { [filterKey]: response?.data?.scripting_rules || [] },
  });
}
const metricList = (response,dispatch) => {
  const filterKey = Constants.FILTER_NAMES.METRIC_TAGS;
  dispatch({
    type: ReduxActionTypes.CACHE_FILTER_DATA,
    payload: { [filterKey]: response?.data?.metrictagrule || [] },
  });
}
const organizationList = (response,dispatch) => {
  const filterKey = Constants.FILTER_NAMES.ORGANIZATIONS;
  dispatch({
    type: ReduxActionTypes.CACHE_FILTER_DATA,
    payload: { [filterKey]: response?.data?.organizations || [] },
  });
}
const topicsList = (response,dispatch) => {
  const filterKey = Constants.FILTER_NAMES.CATEGORIES_LIST;
  dispatch({type: ReduxActionTypes.CACHE_FILTER_DATA, payload: { [filterKey]: response.data.results }})
}
const statusList = (response,dispatch) => {
  const filterKey = Constants.FILTER_NAMES.CASE_STATUS_LIST;
  dispatch({
    type: ReduxActionTypes.CACHE_FILTER_DATA,
    payload: {
      [filterKey]: response?.data?.casestatus || caseStatuses,
    },
  });
}
export const addSlackUser = (code, redirect_uri) => {
  return axiosInstance({
    method: "POST",
    url: `/slack/auth/`,
    data: {
      code,
      redirect_uri,
    },
    
  });
};

export const oktaSaml = (type, oktaData) => () => {
  return axiosInstance({
    method: "POST",
    url: `/business/okta/saml/metadata/`,
    data: {
      type,
      data: oktaData,
    },
    
  }).then(res => {
    return res;
  }).catch(err => {
    throw new Error();
  });
}

export const getAgentGroupOptions = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/accounts/users/profile/list`,
    
    cancelToken: cancelToken?.token
  }).then(res => {
    return res.data;
  }).catch(err => {
    if (Axios.isCancel(err)) {
      throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
    }
    throw new Error();
  });
}
