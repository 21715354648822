import styled from "@emotion/styled/macro";
import { Select, Timeline } from "antd";

export const ConversationDetailsWrapper = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 42px);
`;

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  svg.icon {
    margin-left: 6px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`;

export const MultiElementWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const ConversationTypeDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
  min-width: 55%;
  //column-gap: 8px;
  // width: 65%;
  // svg {
    // height: 48px;
    // width: 48px;
  // }
  .meta-details {
    display: flex;
    flex-direction: column;
  }

  // & .conversation-details-icon-wrapper {
  //   & svg {
  //     height: 32px;
  //     width: 32px;
  //   }
  // }
`;

export const ConversationMetaDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  margin-top: 3px;
  span {
    border-right: 1px solid #bdbdbd;
    padding: 0 12px;
  }
  span:first-of-type {
    padding-left: 0;
  }
  span:last-of-type {
    border: none;
  }
  span.nav-icons {
    display: flex;
    margin: 0 5px;
    padding: 0;
  }
  span.nav-icons svg {
    height: 14px;
    width: 14px;
    margin: 0 2px;
  }
  span.nav-icons .icon {
    color: #6099d9;
    padding: 0;
    border: none;
    display: flex;
    cursor: pointer;
    margin: 0 5px;
    text-decoration: none;
  }
  a.case-id {
    font-size: 12px;
    line-height: 14px;
    color: #6099d9;
    border: none;
    padding: 0 0 0 12px;
  }
`;

export const DetailsTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #828282;
  margin-left: 0px;
  text-transform: capitalize;
`;

export const DetailsHeader = styled.div`
  width: 100%;
  height: 96px;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 23px;
  position: relative;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const MomentsListWrapper = styled.div`
  width: calc(50% - 8px);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f2f2f2;
  box-sizing: border-box;
  margin-right: 16px;
  min-height: ${(props) =>
    props.channel === "call" ? "calc(100vh - 480px)" : "calc(100vh - 260px)"};
  
  & .show-icon {
    visibility: visible;
    }
`;

export const TitleWrapper = styled.div`
  height: 36px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 12px 21px;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
`;

export const MomentHeaderWrapper = styled.div`
  height: auto;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 16px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MomentTitleWrapper = styled.div`
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  color: #828282;
  display: flex;
`;

export const MomentItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 13px 14px;
  position: relative;
  height: ${(props) =>
    props.channel === "call" ? "calc(100vh - 496px)" : "calc(100vh - 271px)"};
  overflow: auto;
`;

export const MomentCardWrapper = styled.div`
  margin-top: 4px;
  &:first-of-type {
    margin: 0;
  }
`;

export const UpArrow = styled.div`
position: absolute;
text-align: center;
display: flex;
top: 13%;
left: 30%;
justify-content: center;
visibility: hidden;
z-index: 1;
`;
export const DownArrow = styled(UpArrow)`
bottom: 2%;
top: unset;
`;

export const ArrowStyle = styled.div`
background: #333333;
border-radius: 32px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 4px 12px;
width: 150px;
height: 28px;
opacity: 0.9;
color: #ffffff;
font-family: Roboto;
font-style: normal;
font-weight: normal;
cursor: default;
font-size: 12px;
& .rubric-arrow-icon {
  width: 16px;
  height: 16px;
}
`;

export const ConversationCardWrapper = styled.div`
  margin-top: 18px;
  &:first-of-type {
    margin: 0;
  }
`;

export const ConversationMetricsWrapper = styled.div`
  width: 36vw;
  height: calc(100vh - 138px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  border-right: 1px solid #e0e0e0;
  min-width: 435px;
  margin: 0 auto;
  @media screen and (min-width: 320px) and (max-width: 600px) {
    min-width: 100%;
    border-right: none;
    width: 100%;
    max-width: 600px;
    height: calc(100vh - 60px);
    padding: 12px 0 20px;
  }
`;

export const LoadingConversationMetricsWrapper = styled.div`
  width: 36vw;
  height: calc(100vh - 138px);
  border-right: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 435px;
  @media screen and (min-width: 320px) and (max-width: 600px) {
    min-width: 100%;
    border-right: none;
    width: 100%;
    max-width: 600px;
    height: calc(100vh - 60px);
    padding: 12px 0 20px;
  }
`;

export const LoadingConversationTimelinesWrapper = styled.div`
  width: calc(64vw - 72px);
  height: calc(100vh - 138px);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 773px;
`;

export const ReviewComponentWrapper = styled.div`
  margin-top: 16px;
`;

export const StyledSeparator = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
`;

export const ConversationTimelinesWrapper = styled.div`
  width: calc(64vw - 72px);
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 773px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 24px;
  position: relative;
  align-items: center;
`;

export const ConversationTimeLineDetailsWrapper = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 188px);
  overflow: auto;
`;

export const TimelinesWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: ${(props) => (props.showAudio ? "1px solid #E0E0E0" : "none")};
`;

export const ConversationTimeLineFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 30px;
  .ant-checkbox-wrapper {
    font-size: 12px;
    color: #828282;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-color: #6099d9;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6099d9;
  }
`;

export const FilterComponentWrapper = styled.div`
  margin-left: 4px;
`;

export const StyledTimeline = styled(Timeline)`
  padding: 5px 14px 13px;
  height: auto;
  min-height: ${(props) =>
    props.channel === "chat" || props.channel === "email"
      ? "calc(100vh - 260px)"
      : "calc(100vh - 480px)"};
  .ant-timeline-item-last > div {
    margin-bottom: 8px;
  }
`;

export const CallStartDisplay = styled.div`
  background: rgba(82, 161, 166, 0.1);
  border: 1px solid #52a1a6;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  width: 100%;
  height: 27px;
  padding: 6px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #52a1a6;
  span {
    font-weight: 900;
    margin-right: 5px;
  }
`;

export const AssistListWrapper = styled.div`
  width: calc(50% - 8px);
  height: ${(props) =>
    props.channel === "chat" || props.channel === "email"
      ? "calc(100vh - 220px)"
      : "calc(100vh - 445px)"};
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  margin: 16px 16px 0 0;
`;

export const KeywordsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  //right: 19px;
  bottom: 4px;
  //max-width: 400px;
  overflow-x: scroll;
  height: 37px;
  //flex-direction: row-reverse;
  //user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const KeywordInnerWrapper = styled.div`
  display: flex;
`;

export const KeywordWrapper = styled.div`
  margin-left: 8px;
`;

export const SentenceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 70vh;
  padding: 24px;
`;

export const SentenceListLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

export const TranscriptBtnWrapper = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #6099d9;
  cursor: pointer;
`;

export const TranscriptWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 2px;
  margin-left: 42px;
`;

export const LanguageSelectWrapper = styled.div`
  width: 100%;
  padding: 4px 14px;
  & .css-transcript-locale-icon {
    width: 14px;
    height: 14px;
    fill: #828282;
    position: relative;
    top: -1px;
  }
`;

export const StyledSelect = styled(Select)`
& .ant-select-selection--single {
  border: none;
  box-shadow: none;
  background: inherit;
  color: #828282;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
`;

export const ConversationTranscriptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px 14px;
  height: ${(props) =>
    props.channel === "call" ? "calc(100vh - 496px)" : "calc(100vh - 271px)"};
  overflow: auto;
`;

export const ConversationTranscriptCardWrapper = styled.div`
  margin-top: 8px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 16px;
  background: #ffffff;
  border: ${(props) => props.isSelected && "2px dashed #e0e0e0"};
`;

export const Wrapper = styled.div`
    display: flex;
    & > svg{
            width: 16px;
            height: 16px;
            margin-left: 6px;
            cursor: pointer;
            path {
                fill : ${props => props.bookmarkedVal ? "#6099D9" : "rgb(130, 130, 130)" };
            }
        }
`;

export const ModalWrapper = styled.div`

`;

export const ModalBodyWrapper = styled.div`
    position: relative;
    padding : 25px;

    .modal-heading {
        font-size: 16px;
        font-weight: 400;
        //line-height: 16px;
        color: #4F4F4F;
        margin: 0px 0px 16px 0px;
    }
    .field-wrapper {
        margin: 0px 0px 16px 0px;
    }
    .ant-input-affix-wrapper {
        margin: 16px 0px 0px 0px;
    }
    .modal-buttons {
        display: flex;
        justify-content: flex-end;
    }

    .ant-spin{
        position: absolute !important;
        top: 40%;
        left: 50%;
        z-index: 2;
    }
`
export const MetricWrapper = styled.div`
  margin-left: 6px;
  margin-top: -1px;
  pointer-events: ${props => props.visible ? "all" : "none"};
  svg {
    width: 11px;
    fill: ${props => props.visible ? "black" : "rgb(130, 130, 130)"}
  }
`;

export const MetricReviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;
  font-size: 12px;
  .metrics-heading {
    display: flex;
    flex-direction: column;
    max-width: 190px;
    font-weight: bold;
  }
  .metrics-value {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
`;
