export const ReduxActionTypes = {
  USER_LOGGED_IN: "USER_LOGGED_IN",
  CREATE_USER: "CREATE_USER",
  STORE_USER_DETAILS: "STORE_USER_DETAILS",
  STORE_NOTIFICATION_COUNTS: "STORE_NOTIFICATION_COUNTS",
  STORE_LIST_FILTERS: "STORE_LIST_FILTERS",
  REMOVE_LIST_FILTERS: "REMOVE_LIST_FILTERS",
  SET_REDIRECT: "SET_REDIRECT",
  LOGOUT_USER: "LOGOUT_USER",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_END: "LOGIN_END",
  CHANGE_SELECTED_WORKSPACE: "CHANGE_SELECTED_WORKSPACE",
  SET_CUBE_JWT: "SET_CUBE_JWT",
  SET_HASURA_JWT: "SET_HASURA_JWT",
  SET_SCREENRECORD_JWT: "SET_SCREENRECORD_JWT",
  SET_CUSTOM_COLUMNS: "SET_CUSTOM_COLUMNS",
  SET_CUSTOM_COLUMNS_MEASURES: "SET_CUSTOM_COLUMNS_MEASURES",
  SET_ANALYTICS_SEGMENTS: "SET_ANALYTICS_SEGMENTS",
  SET_AGENT_ANALYTICS: "SET_AGENT_ANALYTICS",
  CHART_DASHBOARD_EDIT: "CHART_DASHBOARD_EDIT",
  SET_CUSTOM_FILTERS: "SET_CUSTOM_FILTERS",
  SET_KEY_INTERACTION_DATA: "SET_KEY_INTERACTION_DATA",
  SET_GLOBAL_FILTERS: "SET_GLOBAL_FILTERS",
  REMOVE_GLOBAL_FILTER: "REMOVE_GLOBAL_FILTER",
  RESET_GLOBAL_FILTERS: "RESET_GLOBAL_FILTERS",
  RETRY_WEBSOCKET: "RETRY_WEBSOCKET",
  REQUEST_COUNT_DURATION: "REQUEST_COUNT_DURATION",
  RESPONSE_COUNT_DURATION: "RESPONSE_COUNT_DURATION",
  CACHE_FILTER_DATA: "CACHE_FILTER_DATA",
  START_CACHING: "START_CACHING",
  END_CACHING: "END_CACHING",
  SET_CONVERSATION_METRICS: "CONVERSATION_METRICS/UPDATE_CONVERSATION_METRICS",
  SET_ASR_LOG_ID: "SET_ASR_LOG_ID",
  SET_SESSION_DETAILS: "SET_SESSION_DETAILS",
  SET_CALIBRATION_SCORE: "SET_CALIBRATION_SCORE",
  SET_CALIBRATION_STATUS: "SET_CALIBRATION_STATUS",
  SET_LOCAL_ROLES: "SET_LOCAL_ROLES",
  SET_INTEGRATION_CONFIG: "SET_INTEGRATION_CONFIG",
  START_CUSTOM_FILTER_LOADING: "START_CUSTOM_FILTER_LOADING",
  END_CUSTOM_FILTER_LOADING: "END_CUSTOM_FILTER_LOADING",
  CACHE_CUSTOM_FILTERS: "CACHE_CUSTOM_FILTERS",
  SET_DATASOURCE_OPTIONS: "SET_DATASOURCE_OPTIONS",
  SET_SELF_SERVE_CUSTOM_FILTER_DATA: "SET_SELF_SERVE_CUSTOM_FILTER_DATA",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  SET_QA_CASE_ASSIGNMENT_TABLE_DATA: "SET_QA_CASE_ASSIGNMENT_TABLE_DATA",
  SET_QA_FREQUENCY_OBJECT: "SET_QA_FREQUENCY_OBJECT",
  SET_QA_AGENTS: "SET_QA_AGENTS",
  SET_QA_FILTERS_META: "SET_QA_FILTERS_META",
  SET_QA_QA_AUDITORS_LIST: "SET_QA_QA_AUDITORS_LIST",
  SET_QA_RULE_NAME: "SET_QA_RULE_NAME",
  SET_QA_ERROR_STATE: "SET_QA_ERROR_STATE",
  RESET_QA_STATE: "RESET_QA_STATE",
  SET_SHOW_EVIDENCE: "SET_SHOW_EVIDENCE",
  SET_QA_CASE_ASSIGNMENT_MODE: "SET_QA_CASE_ASSIGNMENT_MODE",
  SET_QA_IS_EDIT_ENABLED: "SET_QA_IS_EDIT_ENABLED",
  SET_MY_TASKS: "SET_MY_TASKS",
  RESET_TASK_QUEUE: "RESET_TASK_QUEUE",
  SET_TASK_QUEUE_COUNT: "SET_TASK_QUEUE_COUNT",
  SET_TASK_QUEUE_TABLE_DATA: "SET_TASK_QUEUE_TABLE_DATA",
  SET_AGENT_TYPE_FILTERS: "SET_AGENT_TYPE_FILTERS",
  SET_CAMPAIGN_SEGMENT: "SET_CAMPAIGN_FILTERS",
  SET_LOCATION_SEGMENT: "SET_LOCATION_FILTERS",
  SET_QAAUDITOR_SEGMENT: "SET_QAAUDITOR_SEGMENT"
};

export const NotificationActionTypes = {
  SET_UNREAD_NOTIFICATIONS: "SET_UNREAD_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  REQUEST_NOTIFICATIONS: "REQUEST_NOTIFICATIONS",
  PAGINATION_SET_NOTIFICATIONS: "PAGINATION_SET_NOTIFICATIONS",
  SET_NEXT_PAGE_NOTIFICATIONS: "SET_NEXT_PAGE_NOTIFICATIONS",
  NEW_NOTIFICATION: "NEW_NOTIFICATION",
  START_PAGINATING: "START_PAGINATING",
};

export const ReduxActionErrorTypes = {
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
};

export const TenantActionTypes = {
  SET_SCHEMA: "TENANT/SET_SCHEMA",
  SET_TENANT_LIST: "TENANT/SET_TENANT_LIST",
};
