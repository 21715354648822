import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { message } from "antd";
import { SetupWrapper, LineSeparator } from "./../commons/wrappers";
import SetupHeader from "../commons/SetupHeader";
import SearchComponent from "../../../components/common/SearchComponent";
import ErrorFallback from "../../../components/ErrorFallback";
import {
  PrimaryButton,
} from "../../../components/common/styled-components";
import TableComponent from "./TableComponent";
import { ERROR_OPTONS } from "./config";
import {
  SelfServingCustomFiltersWrapper,
  ModalWrapper,
  AddFieldModal,
} from "./wrappers";
import { openNotification } from "../../../utils/utilities";
import { useReq } from "../../../custom-hooks/useDispatchReq";
import { usePagination } from "./hooks";
import { REQUEST_TYPE } from "../../../custom-hooks/config";
import { createExternalData, editExternalData, getDataSources, getSelfServeFilterTableData } from "./api";
import { useDispatch, useSelector } from "react-redux";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import FieldComponents from "./components/FieldsComponent";

const SelfServingCustomFiltersComponent = ({ fullTitle, icon, description }) => {
  const dispatch = useDispatch();
  const isEditMode = useSelector(state => state.selfServeExternalData.isEditMode)
  const dataSourceOptions = useSelector(state => state.selfServeExternalData.dataSourceOptions);
  const tableData = useSelector(state => state.selfServeExternalData.tableData);

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [id, setID] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [enableFilter, setEnableFilter] = useState("");
  const [sourceField, setSourceField] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sourceFieldOptions, setSourceFieldOptions] = useState([]);

  const [callApi, cancelRequests] = useReq();
  const [page, setPage, data, trackScrolling, getTableData] = usePagination(({ data }) => {
      dispatch({
        type: ReduxActionTypes.SET_SELF_SERVE_CUSTOM_FILTER_DATA,
        payload: data,
      });
      setLoading(false);
    },
    () => {
      setLoading(true);
    },
    REQUEST_TYPE.DISPATCH_REQUEST,
    getSelfServeFilterTableData,
    filters
  ); //new one

  const setState = () => {
    setVisible(!visible);
    setButtonLoader(false);
    setDataSource("");
    setEnableFilter("");
    setSourceField("");
    setDisplayName("");
    setSourceFieldOptions([]);
    if(isEditMode.is_enabled) {
      dispatch({ type: ReduxActionTypes.SET_EDIT_MODE, payload: {
        is_enabled: false,
        data: {}
      }});
    }
  };

  const handleSetVisible = () => {
    setState();
  };

  const handleSearchValue = (val) => {
    setSearch(val);
    applyFilters({
      "search": val
    });
  };
  
  const applyFilters = (_filter) => {
    const updatedFilter = {
      ...filters,
      ..._filter
    };
    setFilters(updatedFilter);
  }

  const isDisabled = () => {
    if (!dataSource || !sourceField || !displayName || !displayName?.trim() || !enableFilter || buttonLoader) {
      return true;
    }
    return false;
  }
  const handleAddClick = () => {
    if (isDisabled()) {
      return;
    }
    setButtonLoader(true);
    const data = {
      field_name: sourceField,
      pretty_name: displayName,
      source: dataSource?.toLowerCase(),
      is_filter: enableFilter === '0' ? false : true,
      is_active: true,
      field_type: "str",
    }
    let node = document.getElementsByClassName("ant-table-body")[0];
    node.scrollTop = 0;
    if(isEditMode.is_enabled) {
      data.id = id;
      return handleSave({...data}, id);
    }
    callApi(createExternalData, (res) => {
      setState();
      setLoading(true);
      setPage(0);
      getTableData({ ...filters }, 1);
      openNotification("bottomLeft", "Field added successfully.");
    }, (err) => {
      if(err && err.message === ERROR_OPTONS.NON_FIELD_ERROR) {
        return message.error("The field at source should be unique");
      }
    }, {
      data, id
    }, () => {
      setButtonLoader(false);
    });
  };

  const setEditModeConfigurations = () => {
    setVisible(!visible);
    setID(isEditMode?.data.id);
    setDataSource(isEditMode?.data.source?.toUpperCase());
    setEnableFilter(isEditMode?.data.is_filter ? "1" : "0");
    setSourceField(isEditMode?.data.field_name);
    setSourceFieldOptions(dataSourceOptions ? dataSourceOptions[isEditMode?.data.source?.toUpperCase()] : [] );
    setDisplayName(isEditMode?.data.pretty_name);
  };

  const handleSave = (data, id) => {
      let _tableData = [...tableData];
      _tableData = _tableData.map(_data => _data.id === id ? { ...data } : _data);
      dispatch({ type: ReduxActionTypes.SET_SELF_SERVE_CUSTOM_FILTER_DATA, payload: _tableData });
      callApi(editExternalData, (res) => {
        if(isEditMode.is_enabled) {
          setState();
          openNotification("bottomLeft", "Field edited successfully.");
        }
      }, (err) => {

      }, {
        data, id
      }, () => {
        setButtonLoader(false);
      }, REQUEST_TYPE.DISPATCH_REQUEST);
  }
  const handleToggle = (val, key, record) => {
    let data = {
      ...record,
      [key]: val,
      ...(key === 'is_active' && !val ? { is_filter: false } : {})
    }
    handleSave(data, record.id);
  };

  const renderHeader = () => {
    return (
      <>
        <SetupHeader
          title={fullTitle}
          icon={icon}
          description={description}
          breadCrumbItems={[
            { title: "Home", link: "/" },
            { title: "Settings", link: "/setup" },
            { title: "Data sources" },
          ]}
        />
        <SelfServingCustomFiltersWrapper>
          <SearchComponent
            placeholder="Search field name"
            value={search}
            width={320}
            handleSearchValue={handleSearchValue}
          />
          <PrimaryButton onClick={handleSetVisible} type="primary">
            Add Field
          </PrimaryButton>
        </SelfServingCustomFiltersWrapper>
        <LineSeparator />
      </>
    );
  };

  useEffect(() => {
    if(dataSourceOptions) {
      return;
    }
    callApi(getDataSources, (res) => {
      dispatch({ type: ReduxActionTypes.SET_DATASOURCE_OPTIONS, payload: res });
    }, (err) => {
      dispatch({ type: ReduxActionTypes.SET_DATASOURCE_OPTIONS, payload: {} });
    }, {}, () => {}, REQUEST_TYPE.DISPATCH_REQUEST)

    return () => {
      cancelRequests();
      dispatch({ type: ReduxActionTypes.SET_EDIT_MODE, payload: {
        is_enabled: false,
        data: {}
      }});
      dispatch({ type: ReduxActionTypes.SET_SELF_SERVE_CUSTOM_FILTER_DATA, payload: [] });
    };
  }, []);

  useEffect(() => {
    if(isEditMode.is_enabled) {
      setEditModeConfigurations();
    }
  }, [isEditMode]);

  return (
    <SetupWrapper className="setup-tab-self-serving-cutom-filters">
      {renderHeader()}
      <TableComponent
        height="calc(100vh - 290px)"
        loading={loading}
        handleToggle={handleToggle}
      />
      <ModalWrapper>
        <AddFieldModal
          title={!isEditMode.is_enabled ? "Add Field" : "Edit Field"}
          okText={!isEditMode.is_enabled  ? "Add" : "Save"}
          onOk={handleAddClick}
          cancelButtonProps = {{ disabled: buttonLoader }}
          okButtonProps={{ disabled: isDisabled(), loading: buttonLoader }}
          onCancel={() => {
            !buttonLoader && handleSetVisible()
          }}
          visible={visible}
        >
          <FieldComponents dataSourceOptions = {dataSourceOptions} buttonLoader = {buttonLoader} dataSource = {dataSource} sourceFieldOptions = {sourceFieldOptions} sourceField = {sourceField} displayName = {displayName} enableFilter = {enableFilter} setDataSource = {setDataSource} setSourceField = {setSourceField} setSourceFieldOptions = {setSourceFieldOptions} setEnableFilter = {setEnableFilter} setDisplayName = {setDisplayName}/>
        </AddFieldModal>
      </ModalWrapper>
    </SetupWrapper>
  );
};

const SelfServingCustomFilters = (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <SelfServingCustomFiltersComponent {...props} />
  </ErrorBoundary>
);

export default SelfServingCustomFilters;
