import { QueryRenderer } from '@cubejs-client/react';
import { message, Spin } from 'antd';
import React from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Row, Col, Statistic, Table } from 'antd';
import store from '../../store';
import appConfig from "../../config";
import cubejs from '@cubejs-client/core';
import { formatRawValue } from '../chart-renderer/config';
import { getMeasureText, getTableColumnText } from '../utils';
import ExportDynamicChart from "../../components/common/ExportDynamicChart";
import { cubeJSApi, cubeApi } from "../api";

const cubejsApi = (cancelToken) => {
    const getCubeToken = () => {
        if (store.getState().app_user.cubejs_jwt_exp < Date.now()) {
          return;
        }
        return store.getState().app_user.cubejs_jwt;
    };
    let token = getCubeToken();
    
    return cubejs(token, { apiUrl: appConfig.CUBEJS_BASE_URL });
};

const formatTableData = (columns, data, pivotConfig, segments, isCompareDateRange=false, ellipses=true) => {
    const dimensions = pivotConfig.x;

    function flatten(columns = []) {
        return columns.reduce((memo, column) => {
            if (column.children) {
                return [...memo, ...flatten(column.children)];
            }

            return [...memo, column];
        }, []);
    }

    const typeByIndex = flatten(columns).reduce((memo, column) => {
        return { ...memo, [column.dataIndex]: column };
    }, {});

    function formatValue(value, { type, format } = {}, column, ellipses) {
        if(dimensions.includes(column)) {
            return formatRawValue(
                value,
                column,
                false,
                { ...segments },
                ellipses
            )
        }

        if(column.includes("LevelAsrAsrlog.created")) {
            let dateValue = new Date(value).toDateString().split(" ");
            return `${dateValue[1]} ${dateValue[2]}, ${dateValue[3]}`;
        }

        if(column === "compareDateRange") {
            let dateRange = value?.split(' - ');
            let dateValueStart = new Date(dateRange[0]).toDateString().split(" ");
            let dateValueEnd = new Date(dateRange[1]).toDateString().split(" ");
            return [`${dateValueStart[1]} ${dateValueStart[2]}, ${dateValueStart[3]}`, `${dateValueEnd[1]} ${dateValueEnd[2]}, ${dateValueEnd[3]}`].join(' - ');
        }

        if (value == undefined) {
            return value;
        }

        if (type === 'boolean') {
            if (typeof value === 'boolean') {
                return value.toString();
            } else if (typeof value === 'number') {
                return Boolean(value).toString();
            }

            return value;
        }

        if (type === 'number' && format === 'percent') {
            return [parseFloat(value).toFixed(2), '%'].join('');
        }

        if(type == 'number' && (value - Math.floor(value)) !== 0) {
            return parseFloat(value).toFixed(2);
        }

        if(type == 'number') {
            return Math.floor(value);
        }

        return value.toString();
    }

    function format(row) {
        return Object.fromEntries(
            Object.entries(row).map(([dataIndex, value]) => {
                return [dataIndex, formatValue(value, typeByIndex[dataIndex], dataIndex, ellipses)];
            })
        );
    }

    return data.map(format);
};

const formatCompareRangeQuery = (set1, set2, compareColumns) => {
    // set2 > set1
    let joinSet = [];
    let uniqueSet1 = [];
    let uniqueSet2 = [];

    let finalSet = [];

    set1.forEach((row, i) => {
        let flag = 0;

        set2.forEach((row2, j) => {
            if(compareColumns.every((el) => row[el] == row2[el])) {
                flag = 1;
                joinSet.push(row);
                finalSet.push(row);
                finalSet.push(row2);
            } else {
                
            }
        })

        if(flag === 0) {
            uniqueSet1.push(row);
        }
    });

    set2.forEach(row => {
        let flag = 0;
        joinSet.forEach(row2 => {
            if(compareColumns.every(el => row[el] == row2[el])) {
                flag = 1;
            }
        })

        if(flag === 0) {
            uniqueSet2.push(row);
        }
    })

    if(uniqueSet1.length) {
        finalSet = [...finalSet, ...uniqueSet1];
    }

    if(uniqueSet2.length) {
        finalSet = [...finalSet, ...uniqueSet2];
    }

    return finalSet;
}

const TableRenderer = ({ query, resultSet, pivotConfig, segments, isCompareDateRange }) => {
    const [tableColumns, dataSource] = useDeepCompareMemo(() => {
        let columns = resultSet?.tableColumns(pivotConfig)?.map(column => {
            return {
                ...column,
                title: getTableColumnText(column.dataIndex)
            }
        });

        let rows = [];

        if(isCompareDateRange) {
            resultSet.decompose().forEach(set => {
                rows = [...rows, formatTableData(columns, set.rawData(), pivotConfig, segments, isCompareDateRange)]
            });
            
            rows = rows[0].length < rows[1].length ? formatCompareRangeQuery(rows[0], rows[1], pivotConfig.x) : formatCompareRangeQuery(rows[1], rows[0], pivotConfig.x);
            // rows = rows.flat();
        } else {
            rows = formatTableData(columns, resultSet.tablePivot(pivotConfig), pivotConfig, segments);
        }
        
        return [
            columns,
            rows
        ];
    }, [resultSet, pivotConfig]);

    return (
        <>
            <div
                className="d-flex justify-content-end"
            >
                <ExportDynamicChart
                    fetchData={() => exportCSV({ query, pivotConfig, segments, isCompareDateRange })}
                    alreadyParsed={true}
                />
            </div>
            <Table pagination={false} columns={tableColumns} dataSource={dataSource} />
        </>
    );
};

export const exportTableData = ({ resultSet, pivotConfig, segments, isCompareDateRange }) => {
    const columns = resultSet.tableColumns(pivotConfig).map(column => {
        return {
            ...column,
            title: getTableColumnText(column.dataIndex)
        }
    });

    let data = [];

    if(isCompareDateRange) {
        resultSet.decompose().forEach(set => {
            data = [...data, formatTableData(columns, set.rawData(), pivotConfig, segments, isCompareDateRange)];
        });
        data = data.flat();
    } else {
        data = formatTableData(columns, resultSet.tablePivot(pivotConfig), pivotConfig, segments, isCompareDateRange, false);
    }

    const header = columns.map(el => el.title);

    const rows = data.map(el => {
        return columns.map(column => {
            return el[column.key]
        });
    });

    return [
        header,
        rows
    ];
};

const exportCSV = ({ query, pivotConfig, segments, isCompareDateRange }) => {
    query.limit = 5000;
    return cubeJSApi().then((api) => {
        return api
          .load(query)
          .then((resultSet) => {
            const [header, rows] = exportTableData({resultSet, pivotConfig, segments, isCompareDateRange});
            let csv = header?.join(',') + '\r\n';
  
            rows.forEach(row => {
              csv += row?.join(',') + '\r\n';
            });
  
            return [csv, rows.length];
          })
          .catch((e) => {
            console.log(e);
            message.error("Invalid Query");
            return -1;
          });
    });
}

export const renderTable = ({ query, resultSet, error, pivotConfig, segments, isCompareDateRange }) => {
    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
        return <Spin />;
    }

    return <TableRenderer query={query} resultSet={resultSet} pivotConfig={pivotConfig} segments={segments} isCompareDateRange={isCompareDateRange} />;

};

const TableChartRenderer = () => {
    return (
        <QueryRenderer
            query={{
                "measures": [
                "CFAnswer.count"
                ],
                "timeDimensions": [],
                "dimensions": [
                "AccountsUser.id",
                "LevelAsrAsrlog.conversationTags"
                ],
                "order": {
                "CFAnswer.count": "desc"
                },
                "limit": 20
            }}
            cubejsApi={cubejsApi()}
            resetResultSetOnChange={true}
            render={(props) => renderTable({
                ...props,
                chartType: 'table',
                // pivotConfig: {
                //     "x": [
                //     "AccountsUser.id",
                //     "LevelAsrAsrlog.conversationTags"
                //     ],
                //     "y": [
                //     "measures"
                //     ],
                //     "fillMissingDates": true,
                //     "joinDateRange": false
                // }
            })}
        />
    );
};

export default TableChartRenderer;
