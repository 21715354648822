import React from "react";
import { debounce } from "../../../utils";
import useStore from "../../store";
import ExpectedPayload from "./ExpectedPayload";
import { CustomInputWrapper } from "./styles";

const CustomInput = () => {
  const updateNode = debounce(
    useStore((store) => store.updateNode),
    300
  );
  return (
    <CustomInputWrapper>
      <ExpectedPayload updateNode={updateNode} />
    </CustomInputWrapper>
  );
};

export default CustomInput;
