import { Input } from "antd";
import React from "react";
import { InputWrapper } from "./styles";

const DefaultInput = ({ item, nodeParams, handleChange }) => {
  return (
    <InputWrapper>
      <label>{item.name}</label>
      <Input
        placeholder={`Add your input`}
        name={item.name}
        value={nodeParams ? nodeParams[item.name] : ""}
        onChange={(e) => handleChange(e.target.value, item.name)}
        onMouseDown={(e) => e.stopPropagation()}
      />
    </InputWrapper>
  );
};

export default DefaultInput;
