import React, { Component } from "react";
import { Link } from "react-router-dom";
import NotificationCountChip from "./../chips/NotificationCountChip";
import { connect } from "react-redux";
import styled from "@emotion/styled/macro";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";

export const MenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 72px;
  height: 72px;
  background: ${props => props.active ? "rgba(255, 255, 255, 0.2)" : "none"};  
  transition: background 0.1s ease-in;
  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
  }
  svg {
    path, circle {
      opacity: ${props => props.active ? 1 : 0.6};
      color: white;
    }
  }
`;

class MenuItems extends Component {
  isCurrent = path => {
    return path && window.location.pathname.split('/')[1] === path;
  };
  render() {
    const { items } = this.props;
    return items.map(({ title, path, onClick, icon: MenuIcon}, index) => {
      const active = this.isCurrent(path);
      return (
        <Link key={index} to={`/${path}`} onClick={onClick} style={{ textDecoration: "none" }}>
          <MenuItemWrapper active={active} title={title}>
            <MenuItem>
              <MenuIcon />
              {path === "dashboard" && this.props.notificationCount 
                ? <NotificationCountChip count ={this.props.notificationCount} /> 
                : null}
            </MenuItem>          
          </MenuItemWrapper>          
        </Link>
      );
    });
  }
}

const mapStateToProps = state => ({ notificationCount: state.app_user.notificationCount });

export default connect(mapStateToProps)(MenuItems);
