import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "../../constants/ReduxActionConstants";

const initialState = {
 autoscoreEvidenceObject: {
   id: null, 
   momentID: [],
   isEnabled: false
 }
};

const rubricReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_SHOW_EVIDENCE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      autoscoreEvidenceObject: { ...action.payload },
    };
  }
});

export default rubricReducer;
