import React, { useState } from "react";
import { Input, Collapse, message } from "antd";
import {
  ModalBulkImportButton,
  ModalBulkImportContent,
  ModalBulkImportText,
  ModalCollapsiblePanel,
} from "./styles";

import { validateEmail } from "../../../utils";

const CollapsiblePanel = ({ emails, setEmails }) => {
  const [mulEmailsText, setMulEmailsText] = useState("");

  const { Panel } = Collapse;
  const { TextArea } = Input;

  const handleParseMultipleEmails = () => {
    if (mulEmailsText === "") {
      return message.error("Email field is empty");
    }
    let mulEmailsArr = mulEmailsText.split(",");
    let oldEmailsArr = [];
    let oldEmailsObjArr = [];
    let flag = 0;
    const validatingEmail = validateEmail(mulEmailsArr);
    if (validatingEmail === false) {
      return message.error("Email ID is invalid");
    }
    //check if the existing email array is empty or not
    emails.forEach((item) => {
      if (item.text !== "") {
        flag = 1;
        oldEmailsArr.push(item.text);
        oldEmailsObjArr.push(item);
      }
    });
    if (flag === 0) {
      let tempArr = [];
      let tempMulArr = [];
      let initialID = 0;
      mulEmailsArr.map((item) => {
        if (!tempMulArr.includes(item)) {
          tempArr.push({
            id: `${initialID}`,
            text: item,
          });
          tempMulArr.push(item);
          initialID = initialID + 1;
        }
      });
      setEmails(tempArr);
      message.success("Email ID successfully parsed!");
    } else {
      let tempArr = [...oldEmailsObjArr];
      let existingLastID =
        parseInt(oldEmailsObjArr[oldEmailsObjArr.length - 1].id) + 1;
      mulEmailsArr.map((newEmail) => {
        if (!oldEmailsArr.includes(newEmail)) {
          tempArr.push({
            id: `${existingLastID}`,
            text: newEmail,
          });
          existingLastID = existingLastID + 1;
        }
      });
      setEmails(tempArr);
      message.success("Email ID successfully parsed!");
    }
    setMulEmailsText("");
  };

  const handleMultipleEmailsChange = (e) => {
    setMulEmailsText(e.target.value);
  };
  return (
    <ModalCollapsiblePanel>
      <Collapse ghost={true}>
        <Panel header="Bulk Import (optional)" key="1">
          <ModalBulkImportContent>
            <ModalBulkImportText>
              Copy and paste comma seperated email addresses here
            </ModalBulkImportText>
            <TextArea
              placeholder="Email addresses"
              className="modal-bulk-import-text-area"
              allowClear
              value={mulEmailsText}
              onChange={handleMultipleEmailsChange}
            />
          </ModalBulkImportContent>
          <ModalBulkImportButton onClick={handleParseMultipleEmails}>
            Parse email addresses
          </ModalBulkImportButton>
        </Panel>
      </Collapse>
    </ModalCollapsiblePanel>
  );
};

export default CollapsiblePanel;
