import {
  axiosInstance,
  getSchemaName,
  getToken,
  getHeaders,
  BASE_URL,
} from "./../api/api";
import * as QueryString from "query-string";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import Constants from "../CompanySetup/utils/constants";
import {
  fetchTotalDurationAndCount,
  getConversationsList,
} from "../graphqlAPI/graphqlAPI";
import { cloneDeep } from "lodash";
import { getQAStatus } from "./utilities";
import Axios from "axios";
import { DASHBOARD_OPTIONS } from "../user-dashboard/utils";
import { getApiFilters } from "../utils/utilities";
import store from "../store";
import appConfigs from "../config";
import { getLatestCfAnswer } from "../conversation-details/rubric-review/utils";
import { setItemInStore } from "../utils";

export const getConversations = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/${rest.version ? rest.version + "/" : ""}list`,
    params: rest.userFilters,
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      throw new Error();
    });
};

export const getConversationsGraphQL = async (cancelToken, rest) => {
  const offset = (rest.limit || 20) * (rest.page - 1);
  const { results, prev, next } = await getConversationsList(
    BASE_URL(),
    rest.version,
    rest.userFilters,
    offset,
    rest.controller,
    rest.limit || "20"
  );
  const conversations = results;
  const arr = conversations.map(function (conversation) {
    const converstation_tag_count = conversation.asr_log.conversation_tag_count
      ? conversation.asr_log.conversation_tag_count.conversation_tag_count
      : 0;
    const negative_moments = conversation.asr_log.negative_moments
      ? conversation.asr_log.negative_moments.negative_moments
      : 0;
    conversation = cloneDeep(conversation);
    if (conversation && conversation.asr_log 
        && conversation.asr_log.custom_qa_score 
        && (conversation.asr_log.custom_qa_score.length > 0)) {
          if(!conversation.asr_log.common_metrics) {
            conversation.asr_log.common_metrics = {};
          }
          
          conversation.asr_log.common_metrics.custom_qa_score = getLatestCfAnswer(conversation.asr_log.custom_qa_score);
    }
    if (
      conversation &&
      conversation.asr_log &&
      conversation.asr_log.insta_review &&
      conversation.asr_log.insta_review.length > 0
    ) {
      conversation.asr_log.insta_review = conversation.asr_log.insta_review[0];
      delete conversation.asr_log.insta_review["__typename"];
    }
    if (
      conversation &&
      conversation.asr_log &&
      conversation.asr_log.case_status &&
      conversation.asr_log.case_status.length > 0
    ) {
      conversation.case_status = conversation.asr_log.case_status[0].status;
    }
    conversation = getQAStatus(conversation);
    return {
      ...conversation,
      conversation_tag_count: converstation_tag_count,
      negative_moments: negative_moments,
      case_summary: conversation.asr_log.summary,
      user: conversation.asr_log.user,
    };
  });

  const res = {
    next: next,
    prev: prev,
    results: arr,
  };
  const ret = { data: res };
  return ret.data;
};

export const getSearchConversations = async (filters) => {
  const response = await axiosInstance({
    method: "GET",
    url: `/level-nlp/conversation-search/`,
    params: filters,
    
  });
  return response;
};

export const getCustomColumns =  (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/custom_columns/${rest.id}/`,
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      throw new Error();
    });
};

export const createCustomColumns = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "PUT",
    url: `/banking/custom_columns/${rest.id}/`,
    data: {
      columns: rest.columns,
    },
    
  }).then(res => {
    return res;
  }).catch(err => {
    throw new Error();
  })
};

export const getCustomFilters= (cancelToken, rest) => (dispatch, getState) => {
  dispatch({type: ReduxActionTypes.START_CUSTOM_FILTER_LOADING});
  return axiosInstance({
    method: "GET",
    url: appConfigs.ENABLE_CUSTOM_FIELDS_V2_API ? `/banking/v2/custom_filters/` : `/banking/custom_filters/`,
    cancelToken: cancelToken?.token
  }).then(res => {  
    dispatch({ type: ReduxActionTypes.SET_CUSTOM_FILTERS, payload: { value : !res.data.message ? res.data : [] }});
    !res.data.message && setItemInStore("customFilterList", res.data,true);
    return res.data;
  }).catch(err => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      dispatch({ type: ReduxActionTypes.SET_CUSTOM_FILTERS, payload: { value : [] } });
      setItemInStore("customFilterList", [],true);
  });
}

export const storeFilters = (globalQAFilters) => (dispatch, getState) => {
  dispatch({
    type: ReduxActionTypes.SET_GLOBAL_FILTERS,
    payload: { ...globalQAFilters },
  });
};

export const getConversationCount = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/${
      rest.version ? rest.version + "/" : ""
    }list-duration/`,
    params: rest.filters,
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      throw new Error();
    });
};

export const getCallDuration = (cancelToken, rest) => () => {
  const filterKey = Constants.FILTER_NAMES.CALL_DURATION;
  return axiosInstance({
    method: "GET",
    url: `/banking/v2/call-duration`,
    params: rest.filters,
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      throw new Error();
    });
};

export const getNegativeSentiment = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/banking/v2/negative-moments/range`,
    params: rest.filters,
    cancelToken: cancelToken?.token,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error(DASHBOARD_OPTIONS.CANCEL_REQUEST);
      }
      throw new Error();
    });
};

export const getConversationCountGraphQl = (controller, rest) => () => {
  //feature gated self serve
  const customFilterList = store.getState().dashboard.customFilters?.map(item => { return { field_name: item.field_name, field_type: item.field_type } });
  return fetchTotalDurationAndCount(
    { ...rest.filters, customFilterList },
    rest.controller
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error();
    });
};
