import React, { useState, useEffect } from "react"
import { Input } from 'antd';
import styled from "@emotion/styled/macro";

const ValidationInputWrapper = styled(Input)`
    border-color: #BDBDBD;
    &.error {
        border: 1px solid red;
    }
    &.ant-input[disabled] {
        color: #4f4f4f;
    }
`;

const ValidationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1;
`;

const ValidationTextArea = styled(Input.TextArea)`
    &.ant-input {
        top: 0;
        resize: none;
        border-color: #BDBDBD;    
        display: flex;
        flex: 1 1;
        width: 100%;
        margin-top: 12px;
        padding: 4px 10px;
        height: 72px;
        min-height: 72px;
    }
    &.ant-input[disabled] {
        color: #4f4f4f;
    }
    &.error {
        border: 1px solid red;
    }
`;

const ValidationMessage = styled.p`
    color: red;
    font-size: 10px;    
    margin: 0;
`;

const ValidationInput = (props) => {
    const [error, setError] = useState(false)
    useEffect(() => {
        let nextError = props.error ? props.error.includes(props.name) : false
        if (error !== nextError) {
            setError(props.error ? props.error.includes(props.name) : false)
        }         
    }, [props.error, props.name, error]);
    return (
        <React.Fragment>
            {
                error ?
                <ValidationWrapper>
                    {props.textarea 
                        ? <ValidationTextArea {...props} className="error" />
                        : <ValidationInputWrapper {...props} className="error" />
                    }
                    <ValidationMessage>This field is required</ValidationMessage>
                </ValidationWrapper>
                :
                <ValidationWrapper>
                    {props.textarea 
                        ? <ValidationTextArea {...props} />
                        : <ValidationInputWrapper {...props} />
                    }
                </ValidationWrapper>
            }
        </React.Fragment>
    )
}

export default ValidationInput;