import React from "react";
import * as QueryString from "query-string";
import "./css/common.scss";
import {
  NotificationActionTypes,
  ReduxActionTypes,
} from "./constants/ReduxActionConstants";
import { notification, Button } from "antd";
import {
  authorizeUser,
  BASE_URL,
  getSchemaName,
  getIntegrationConfig,
} from "./api/api";
import appConfigs from "./config";
import { connect } from "react-redux";
import AppRouter from "./routes";
import GlobalSpinner from "./components/common/GlobalSpinner";
import { getNewCount, readNotification } from "./InAppNotifications/api";
import { filtersFromURLParams } from "./utils/utilities";
import { storeFiltersSegment } from "./CompanySetup/api/api";
import { getCustomColumns } from "./AnalyticsDashboard/utils";
import store from "./store";
import {  getItemFromStore, setItemInStore } from "./utils";
//import store from './store';

window.heap.load(appConfigs.HEAP_APPLICATION_ID, {
  secureCookie: true,
});

function initializeHeap(user) {
  if (user.id) {
    if (window.heap.identify) {
      window.heap.identify(user.id);
    }
    if (window.heap.addUserProperties) {
      window.heap.addUserProperties({
        Name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        tenant: user.schema_name,
        userType: user.is_superuser
          ? "Admin"
          : user.is_manager
          ? "Manager"
          : user.is_qa_auditor
          ? "QA Auditor"
          : "Agent",
      });
    }
  }
}

const getWebsocket = (userDetails) => {
  const local = "ws://localhost:8000";
  const dev = BASE_URL().replace("https://", "wss://");

  const base_url =
    BASE_URL().includes("localhost") || BASE_URL().includes("dev.dashboards")
      ? local
      : dev;
  return new WebSocket(
    `${base_url}/ws/notifications/?token=${
      userDetails.token
    }&schema_name=${getSchemaName()}`
  );
};

class App extends React.Component {
  state = {
    isLoading: true,
  };

  connectWebsocket(user) {
    if (!user.token) {
      return;
    }
    this.websocket = getWebsocket(user);
    this.websocket.onmessage = (event) => {
      const notificationObject = JSON.parse(JSON.parse(event.data).payload);
      notification.open({
        message: "New Notification",
        description: (
          <Button
            type="link"
            style={{
              whiteSpace: "normal",
              height: "auto",
              color: "black",
              textAlign: "left",
              padding: 0,
            }}
          >
            {JSON.parse(event.data).message}
          </Button>
        ),
        onClick: () => {
          readNotification(notificationObject.id).then(() => {
            window.open(`/notifications/`, "_self");
          });
        },
      });
      this.props.newNotification(JSON.parse(JSON.parse(event.data).payload));
    };

    this.websocket.onopen = () => {
      console.log("connection successful");
    };

    this.websocket.onclose = (e) => {
      delete this.websocket;
      console.log("connection closed");
      // console.log("connection closed, retrying");
      // setTimeout(() => {
      //   this.connectWebsocket(user)
      // }, 3000)
    };
  }

  componentDidMount() {
    setItemInStore("redirect-url", window.location.pathname);
    this.props.startLogin();
    if (window.location.href.includes("localhost") && this.props.user.token) {
      if (window.location.search) {
        filtersFromURLParams(QueryString.parse(window.location.search));
      }
      this.props.endLogin();
    } else {
      authorizeUser()
        .then(
          (response) => {
            const userDetails = response.data;
            if (userDetails.token) {
              this.props.storeUser(userDetails);
             
              if (window.location.search) {
                filtersFromURLParams(QueryString.parse(window.location.search));
              }
              if (window.heap) {
                initializeHeap(userDetails);
              } else {
                document.addEventListener("heap.ready", (e) => {
                  initializeHeap(userDetails);
                });
              }
            } else {
              this.props.endLogin();
            }
          },
          () => {
            this.props.endLogin();
          }
        )
        .catch((err) => {
          this.props.endLogin();
        });
    }

    if (!this.props.user.token) {
      store.dispatch(getIntegrationConfig());
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      this.setState({ isLoading: false });
    }
    if (this.props.user.token) {
      if (prevProps.user.token !== this.props.user.token) {
        //if (!this.websocket) {
        this.props.storeFilterSegments();
        this.connectWebsocket(this.props.user);
        this.props.getNewCount();
        getCustomColumns();
        //}
      }
    }
  }

  render() {
    if (this.state.isLoading) {
      return <GlobalSpinner />;
    } else {
      return <AppRouter />;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.app_user,
  isLoading: state.app_user.isLoading,
  websocket: state.app_user.websocket,
  filterSegments: state.dashboard.filterSegments,
});

const mapDispatchToProps = (dispatch) => ({
  startLogin: () => {
    dispatch({
      type: ReduxActionTypes.LOGIN_USER,
    });
  },
  storeUser: (userDetails) => {
    let selectedWorkspace;
    try {
      selectedWorkspace = getItemFromStore("selectedWorkspace") || "";
    } catch (err) {}
    dispatch({
      type: ReduxActionTypes.STORE_USER_DETAILS,
      payload: {
        userDetails,
        selectedWorkspace: userDetails?.workspace?.includes(selectedWorkspace)
          ? selectedWorkspace
          : "",
      },
    });
  },
  endLogin: () => {
    dispatch({
      type: ReduxActionTypes.LOGIN_END,
    });
  },
  newNotification: (data) => {
    dispatch({
      type: NotificationActionTypes.NEW_NOTIFICATION,
      payload: data,
    });
  },
  setNotifications: (data) => {
    dispatch({
      type: NotificationActionTypes.SET_NOTIFICATIONS,
      payload: data,
    });
  },
  getNewCount: () => {
    dispatch(getNewCount());
  },
  storeFilterSegments: () => {
    // To fetch init filters after dashboard api calls are made
    setTimeout(() => {
      dispatch(storeFiltersSegment());
    }, 2000);
  },
  getIntegrationConfig: () => {
    dispatch(getIntegrationConfig());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
