import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { REQUEST_TYPE } from './config';

export const useReq = () => {
  const dispatch = useDispatch();
  const cancelToken = Axios.CancelToken.source();
  const callApi = (apiFunction, onComplete, onError, restParams, onFinally, requestType = REQUEST_TYPE.DISPATCH_REQUEST) => {
        let _apiFunction = requestType === REQUEST_TYPE.DISPATCH_REQUEST ? dispatch(apiFunction(cancelToken, restParams)) : apiFunction(cancelToken, restParams);
        _apiFunction.then(res => {
          onComplete(res)
        }).catch(err => {
          onError(err);
        }).finally(() => {
          if(onFinally) {
            onFinally();
          }
        })
  }

  const cancelRequests = () => {
    if(cancelToken) {
      cancelToken.cancel();
    }
  };

  return [callApi, cancelRequests];
};
