import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Input } from "antd";
import { StyledTable } from "./../components/common/styled-components";

// export const TableWrapper = styled.div `
//   width:100%;
//   position:relative;
//   background:#fafafa;
//   padding: 15px;
// `;

export const StyledConversationTable = styled(StyledTable)`
  margin-top: 13px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 20%) !important;
`;
export const PaginatedTableWrapper = styled(StyledConversationTable)`
  .ant-table-column-title {
    color: #bdbdbd;
    white-space: nowrap;
  }
  td {
    font-size: 12px;
  }

  .ant-table-row {
    height: 64px;
    .ant-table-row-cell-break-word{
      border-bottom: ${props => props.parentRowBorder ? "1px solid #e8e8e8 !important" : "none"} !important;
    }
  }
  .ant-table-title {
    padding: ${props => props.tableHeading ? "15px" : "0" };
    display: ${props => props.tableHeading ? "block" : "none" };
    font-weight: 500;
    background: white;
    border-bottom: 2px solid #F2F2F2;
  }

  & .ant-table-expand-icon-th{
    display: none;
  }

  .ant-table-expanded-row {
    background: white;
    td {
         border-bottom: 1px solid #e8e8e8 !important;
       }

    td: hover {
          background: #ffffff !important;
       }
  }

  .ant-table-row-expand-icon-cell{
       display: none;
  }

  & .ant-table-expand-icon-col {
    display: none;
  }

  & .ant-table-row-cell-ellipsis {
    & .ant-table-header-column {
      vertical-align: inherit;
    }
  }
`

export const StyledInput = styled(Input)`
width: 500px;
position: relative;
.ant-input-prefix {
  left: 10px;
  top: 17px;
  svg {
    path {
      fill: #bdbdbd;
    }
  }
}
input {
  border-color: #e0e0e0;
  color: #828282;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
}
`;

export const SearchButtonComponentWrapper = styled.div`
display: flex;
align-items: center;
button {
  margin-left: 8px;
}
`;

export const GlobalComponent = styled.div`
position: relative;
.dropdown-container {
  position: absolute;
  width: 500px;
  //height: 80px;
  left: 0px;
  top: 31px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  z-index: 2;
  .filters-section {
    //width: 471px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    margin-bottom: 45px;
    //padding: 10px;
    .filterStyle {
      cursor: pointer;
      transition: 0.4s ease; 
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .labelHeader {
        color: #828282;
        font-weight: bold;
      }
      .labelDescription {
        color: #828282;
      }
      .phraseText {
        opacity: 0.4;
        font-size: 12px;
      }
      &: hover {
        background: whitesmoke;
      }
    }
  }

  .footer {
    background: rgba(232, 146, 68, 0.05);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex; 
    align-items: center;
    font-size: 12px;
    color: #828282;
    & > .bulb-icon {
      height: 18px; 
      width: 18px;
      fill: #828282;
      margin-right: 6px;
    } 
  }
}
`;

export const SliderFilterWrapper = styled.div`
  width: 90%;
  & .ant-slider {
  }

  & .text {
    align-items: center;
    font-size: 14px;
    color: #bdbdbd;
  }

  & .MuiSlider-thumb {
    color: white;
    border: 2px solid #6099D9;
    margin-top: -7px;
    width: 16px;
    height: 16px;
  }
`;

export const InputWrapper = styled.div`
  justify-content: space-between;
  margin-left: 20px;
`;

export const InputNumberWrapper = styled.div`
  position: relative;
  .ant-input-number-handler-wrap {
    display: none;
  }

  & .ant-input-number {
    width: 125px;
  }

  & .units-wrapper {
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 1;
    color: #bdbdbd;
    font-size: 12px;
  }

  & .MuiTextField-root {
    width: 125px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0px 5px 0px 5px;

    & .MuiInput-root {
      font-size: 14px;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  & .MuiInputAdornment-positionEnd .MuiTypography-colorTextSecondary {
    color: #bdbdbd;
    font-size: 12px;
  }
`;


export const VideoWrapper = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 635px;
  z-index: 1000;
  margin: 2rem;
  .convoId {
    font-size: 12px;
    color: #828282;
    text-align: left;
    width: 100%;
    margin: 8px 0;
  }
  & .header-controls {
    display:none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: flex-end;
    background: linear-gradient(180deg, #2F2F2F 0%, rgba(196, 196, 196, 0) 100%);
    padding: 1rem;
    & .icon-wrapper {
      background: #272727;
      border-radius: 2px;
      padding: 3px;
      display: grid;
      place-content:center;
      cursor:pointer;
      & svg {
        height: 13px;
        width: 13px;
      }
    }
  }
  &:hover .header-controls {
    display:flex;
  }
  ${({openInNewTab}) => openInNewTab && css`
    width: 100vw;
    height: 100vh;
    padding: 16px 32px;
    margin:0;
    background: black;
    & .header-controls {
      display: none !important;
    }
    & video {
      width: calc(100vw - 64px)
    }
  `}
`;
