import styled from "@emotion/styled/macro";

export const DashboardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;  
  position: relative;
  margin-top: ${props => props.marginTop};
  .component-layout {
    display: flex;
    flex-direction: column;
    width: calc(100% - 72px);
    margin-left: 72px;
    height: 100%;    
    min-width: 1208px;
  }
`;

export const InternalDashboardWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;  
  position: relative;
  .component-layout {
    display: flex;
    flex-direction: column;
    width: calc(100% - 72px);
    margin-left: 72px;
    height: 100%;    
    min-width: 1208px;
  }
`

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 400px;
  max-height: 100vh;
`
