import { REDUCER_CONSTANTS } from "../configs";


export const filterReducer = (state, action) => {
  const { payload } = action;
  if (action.type === REDUCER_CONSTANTS.UPDATE_ADDITIONAL_FILTER) {
    return {
      ...state,
      _additionalFilters: payload,
    };
  }

  if (action.type === REDUCER_CONSTANTS.UPDATE_GLOBAL_FILTER_OBJECT) {
    const { payload } = action;
    return {
      ...state,
      globalFilterObject: {
        ...payload
      },
    };
  }

  if (action.type === REDUCER_CONSTANTS.UPDATE_LOCAL_FILTER_OBJECT) {
    const { payload } = action;
    return {
      ...state,
      localFilterObject: {
        ...payload,
      },
    };
  }

  if (action.type === REDUCER_CONSTANTS.UPDATE_FILTER_VALUE) {
    const { key, value } = action.payload;
    return {
      ...state,
      localFilterObject: {
        ...state.localFilterObject,
        [key]: value,
      },
      showButtonEnabled: true
    };
  }

  if (action.type === REDUCER_CONSTANTS.RESET_SELECTED_FILTER) {
    const { key } = action.payload;
    let filterObj = { ...state.localFilterObject };
    filterObj[key] = [];
    return {
      ...state,
      localFilterObject: filterObj,
      showButtonEnabled: true
    };
  }

  if (action.type === REDUCER_CONSTANTS.RESET_ALL_FILTERS) {
    const { payload } = action;
    return {
      ...state,
      localFilterObject: { ...payload },
      showButtonEnabled: true
    };
  }

  if (action.type === REDUCER_CONSTANTS.ENABLE_RESET_ALL) {
    const { status } = action.payload;
    return {
      ...state,
      resetAllEnabled: status,
    };
  }

  if (action.type === REDUCER_CONSTANTS.ENABLE_SHOW_BUTTON) {
    const { status } = action.payload;
    return {
      ...state,
      showButtonEnabled: status,
    };
  }
};
