import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { StyledLinkButton } from "../common/styled-components"; 
import { unescape } from "he";

const InnerHtmlWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`;

const unescapedText = (text) => {
    return unescape(text.replace(/<[a-zA-Z]+(>|.*?[^?]>)/g, "").replace(/((<\/)\w+(>))/g, ""));
}

const ReadMoreText = ({text, className, onMore, category}) => {
    const ref = useRef();
    const [showMore, toggleMore] = useState(false);
    const handleResize = () => {
        const element = ref.current;
        if(element && element.offsetHeight !== element.scrollHeight) {
            toggleMore(true);
        }
        else if(element && element.offsetHeight === element.scrollHeight){
            toggleMore(false);
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);    
        handleResize();    
        return () => window.removeEventListener("resize", handleResize);
    }, [text]); 
    const handleShowPopup = (e) => {
        if (onMore) {
            onMore(true)
        } else {
            alert(text);
        }
        e.stopPropagation();
    }
    return (
        <div className={className}>
            {category === "FAQ" ? <InnerHtmlWrapper ref={ref}>{unescapedText(text)}</InnerHtmlWrapper>
              : <InnerHtmlWrapper ref={ref} dangerouslySetInnerHTML={{__html: text}} /> }
            {showMore && (<StyledLinkButton type="link" onClick={handleShowPopup}>More</StyledLinkButton>)}
        </div>
    )
}

export default ReadMoreText;