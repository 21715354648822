import React, { useState, useEffect, useRef } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import { OpenInNew, Close } from "@material-ui/icons";
import { getScreenRecordSignedUrl } from "../conversation-details/api";
import { VideoWrapper } from "./wrappers";

const ScreenRecordingPlayer = ({
  caseID,
  agentId,
  convoId,
  show,
  openInNewTab,
  closePlayer,
}) => {
  const [videoUrl, setVideoUrl] = useState();
  const playerRef = useRef();

  useEffect(() => {
    console.log("HERE ", { caseID, agentId });
    getScreenRecordSignedUrl(caseID, agentId)
      .then((res) => {
        console.log('signed url ',res);
        setVideoUrl(res.data);
      })
      .catch((e) => {
        console.log("Error", e);
        setVideoUrl(null);
      });
  }, [caseID, agentId]);

  useEffect(() => {
    playerRef.current.disablePictureInPicture = true;
  }, []);

  return (
    <VideoWrapper
      show={show === undefined ? true : show}
      openInNewTab={openInNewTab}
    >
      {openInNewTab && <p className="convoId">Convo ID #{convoId}</p>}
      <div style={{ position: "relative" }}>
        <ReactHlsPlayer
          playerRef={playerRef}
          src={videoUrl}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
          // onDurationChange={(e) => setDuration(formatTimeDuration(playerRef.current.duration))}
        />
        <div className="header-controls">
          <p
            className="icon-wrapper me-2"
            onClick={() =>
              window.open(
                `${window.location.origin}/screen-recording/${convoId}/${agentId}/${caseID}`,
                "_blank"
              )
            }
          >
            <OpenInNew color="white" />
          </p>
          <p className="icon-wrapper" onClick={closePlayer}>
            {" "}
            <Close color="white" />{" "}
          </p>
        </div>
      </div>
    </VideoWrapper>
  );
};

export default ScreenRecordingPlayer;
