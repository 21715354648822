import React from "react";
import { Tabs, Button } from "antd";
import GenericTab from "./GenericTab";
import useStore from "../../store";
import { ReactComponent as ActionIcon } from "../../../assets/settings.svg";
import { ReactComponent as ConditionIcon } from "../../../assets/condition.svg";
import { ReactComponent as ManipulationIcon } from "../../../assets/condition.svg";
import { ReactComponent as OperationIcon } from "../../../assets/condition.svg";

const { TabPane } = Tabs;

const onDragStart = (event, nodeData) => {
  event.dataTransfer.setData("application/reactflow", nodeData);
  event.dataTransfer.effectAllowed = "move";
};

const Sidebar = () => {
  const resetElements = useStore((state) => state.resetElements);
  return (
    <>
      <Button className="ms-2" onClick={(e) => resetElements()}>
        Reset Nodes
      </Button>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Logical Operators" key="2">
          <GenericTab
            onDragStart={onDragStart}
            nodeType="condition"
            apiEndpoint="/getconditions"
            renderIcon={() => <ConditionIcon />}
          />
        </TabPane>
        <TabPane tab="Operations" key="3">
          <GenericTab
            onDragStart={onDragStart}
            nodeType="operation"
            apiEndpoint="/getoperations"
            renderIcon={() => <OperationIcon />}
          />
        </TabPane>
        <TabPane tab="Manipulations" key="4">
          <GenericTab
            onDragStart={onDragStart}
            nodeType="manipulation"
            apiEndpoint="/getmanipulations"
            renderIcon={() => <ManipulationIcon />}
          />
        </TabPane>
        <TabPane tab="Actions" key="5">
          <GenericTab
            onDragStart={onDragStart}
            nodeType="action"
            apiEndpoint="/getactions"
            renderIcon={() => <ActionIcon />}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Sidebar;
