const measureOptions = {
  RAW_SCORE: "CFAnswer.raw_score",
  PERCENT_SCORE: "CFAnswer.percent_score",
  QUESTION_PERCENT: "CFDAnswer.questionScorePercent",
  EVALUATIONS: "QAEvaluations.count",
  CATEGORY_SCORE: "CFDAnswer.categoryScore",
  SECTION_SCORE: "CFDAnswer.sectionScore",
  QUESTION_SCORE: "CFDAnswer.score",
  AVERAGE_HANDLING_TIME: "Conversation.handling_time",
  CONVERSATION_COUNT: "Conversation.count",
  CASE_COUNT: "LevelAsrAsrlogtocasemapping.count",
  PASS_RATE: "CFAnswer.pass_rate",
  FAIL_RATE: "CFAnswer.fail_rate",
  POOR_RATE: "CFAnswer.poor_rate",
  GOOD_RATE: "CFAnswer.good_rate",
  OUTSTANDING_RATE: "CFAnswer.outstanding_rate",
  AVERAGE_CSAT: "CSAT.csatScore",
  AVERAGE_AGENT_REPLY_COUNT: "Metrics.average_agent_reply_count",
  AVERAGE_CUSTOMER_REPLY_COUNT: "Metrics.average_customer_reply_count",
  CUSTOMER_TO_AGENT_EMAIL_RATIO: "Metrics.customerToAgentEmailRatio",
  FIRST_RESPONSE_TIME: "Metrics.first_response_time",
  DEAD_AIR_RATIO: "Metrics.dead_air_ratio",
  LISTEN_TO_TALK_RATIO: "Metrics.listen_to_talk_ratio",
  AVERAGE_WORDS_PER_MINUTE: "Metrics.average_words_per_minute",
  AVERAGE_CASE_RESOLUTION_TIME: "Metrics.average_case_resolution_time",
  FOLLOWED_RATE: "MomentConversationTag.followed_rate",
  FLAGGED_RATE: "Metrics.flagged_rate",
  TOTAL_EVALUATIONS: "Total Evaluations",
  MOST_FREQUENT_CONVERSATION_TAGS: "MFTags.count",
  MOST_FREQUENT_TOPICS: "MFTopics.count",
};

const queryBuilderMeasureOptions = {
  RAW_SCORE: "CFAnswer.raw_score",
  PERCENT_SCORE: "CFAnswer.percent_score",
  AUTO_INSTASCORE: "AutoScore.auto_instascore",
  EVALUATIONS: "QAEvaluations.count",
  CATEGORY_SCORE: "CFDAnswer.categoryScore",
  SECTION_SCORE: "CFDAnswer.sectionScore",
  QUESTION_SCORE: "CFDAnswer.score",
  QUESTION_PERCENT: "CFDAnswer.questionScorePercent",
  AVERAGE_HANDLING_TIME: "Conversation.handling_time",
  CONVERSATION_COUNT: "Conversation.count",
  CASE_COUNT: "LevelAsrAsrlogtocasemapping.count",
  AVERAGE_CSAT: "CSAT.csatScore",
  AVERAGE_AGENT_REPLY_COUNT: "Metrics.average_agent_reply_count",
  AVERAGE_CUSTOMER_REPLY_COUNT: "Metrics.average_customer_reply_count",
  CUSTOMER_TO_AGENT_EMAIL_RATIO: "Metrics.customerToAgentEmailRatio",
  FIRST_RESPONSE_TIME: "Metrics.first_response_time",
  DEAD_AIR_RATIO: "Metrics.dead_air_ratio",
  LISTEN_TO_TALK_RATIO: "Metrics.listen_to_talk_ratio",
  AVERAGE_WORDS_PER_MINUTE: "Metrics.average_words_per_minute",
  AVERAGE_CASE_RESOLUTION_TIME: "Metrics.average_case_resolution_time",
  FOLLOWED_RATE: "MomentConversationTag.followed_rate",
  FLAGGED_RATE: "Metrics.flagged_rate",
};

const filterByOptions = {
  TOPICS: "TOPICS",
  CATEGORY: "CATEGORY",
  AGENT: "AGENT",
  SECTION: "SECTION",
  CHANNEL: "CHANNEL",
  CONVERSATION_TAG: "CONVERSATION_TAG",
  ORGANIZATION: "ORGANIZATION",
  NONE: "NONE",
};

const groupByOptions = {
  AGENT: "AGENT",
  CHANNEL: "CHANNEL",
  OUTCOME: "OUTCOME",
  QA_CATEGORY: "QA_CATEGORY",
  SECTION: "SECTION",
  CATEGORY: "CATEGORY",
  RESOLVED: "RESOLVED",
  IS_FLAGGED: "IS_FLAGGED",
  CONVERSATION_TAG_ID: "CONVERSATION_TAG_ID",
  METRIC_RULE: "METRIC_RULE",
  NEGATIVE_SENTIMENT: "NEGATIVE_SENTIMENT",
  ORGANIZATION: "ORGANIZATION",
  TAGS_TITLE: "TAGS_TITLE",
  TOPICS_TOPICS: "TOPICS_TOPICS",
  QUESTION: "QUESTION",
  SELECTED_OPTION: "SELECTED_OPTION",
  NONE: "NONE",
};

export const groupBy = {
  [groupByOptions.AGENT]: { label: "Agents", drilldown: true },
  [groupByOptions.CHANNEL]: { label: "Channels", drilldown: true },
  [groupByOptions.OUTCOME]: { label: "QA Outcome", drilldown: false },
  [groupByOptions.QA_CATEGORY]: { label: "QA Category", drilldown: false },
  [groupByOptions.SECTION]: { label: "Section", drilldown: false },
  [groupByOptions.CATEGORY]: { label: "Categories", drilldown: true },
  [groupByOptions.RESOLVED]: { label: "Resolved", drilldown: true },
  [groupByOptions.IS_FLAGGED]: { label: "Flagged", drilldown: true },
  [groupByOptions.CONVERSATION_TAG_ID]: {
    label: "Conversation Tag",
    drilldown: true,
  },
  [groupByOptions.METRIC_RULE]: { label: "Metric Tag", drilldown: true },
  [groupByOptions.NEGATIVE_SENTIMENT]: {
    label: "Negative Sentiment",
    drilldown: false,
  },
  [groupByOptions.ORGANIZATION]: { label: "Organization", drilldown: true },
  [groupByOptions.NONE]: { label: "None", drilldown: true },
};

const sortByOptions = {
  TOP_50: {
    key: "desc",
    label: "Top 50",
  },
  BOTTOM_50: {
    key: "asc",
    label: "Bottom 50",
  },
};

const timeGranularityOptions = {
  DAY: "day",
  WEEK: "week",
};

const drilldownByOptions = {
  USER: "user",
  DATE: "date",
  ORGANIZATION: "organization",
  ...Object.keys(groupByOptions).reduce((acc, key) => {
    acc[key] = groupByOptions[key].toLowerCase();
    return acc;
  }, {}),
};

const tooltipOptions = {
  OUTCOME_PERCENT: "OUTCOME_PERCENT",
};

const queryOptions = {
  measureOptions,
  queryBuilderMeasureOptions,
  filterByOptions,
  groupByOptions,
  timeGranularityOptions,
  sortByOptions,
  drilldownByOptions,
  tooltipOptions,
};

export { queryOptions };
