import React,{useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import GlobalSpinner from '../../components/common/GlobalSpinner';
import { fetchNotification } from '../../InAppNotifications/api';
import { linkGenerator } from '../../InAppNotifications/utils';

const NotificationRedirect = ({...rest}) => {
  const history = useHistory()
  useEffect(() => {
    fetchNotification(rest.match.params.id).then((res) => {
      history.push(linkGenerator(res.data.notifications));
    })
  }, [])
  return (
    <GlobalSpinner />
  )
}

export default NotificationRedirect;