import React, { useState, useEffect } from "react";
import { Select, DatePicker, Modal } from "antd";
import { ReactComponent as SelectIcon } from "./../../assets/select-icon.svg";
import { ReactComponent as CalendarIcon } from "./../../assets/calendar-icon.svg";
import styled from "@emotion/styled";
import moment from "moment";
import { dateOptions } from "../../Filters/configs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const StyledSelectWrapper = styled.div`
  position: relative;
  width: ${({ w }) => w || "180"}px;
  margin-top: ${({ mt }) => mt || "10px"};
  margin-right: ${({ mr }) => mr || "4px"};
  .ant-select-selection__placeholder {
    display: ${(props) => props.showDateRange && "block !important"};
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection {
    width: auto;
    height: 32px;
    min-width: ${(props) => (props.minWidth ? props.minWidth : 150)}px;
    max-width: ${(props) => (props.minWidth ? props.minWidth : 150)}px;
    border-color: ${(props) =>
      props.defaultValue || props.value ? "#BDBDBD" : "#BDBDBD"};
  }
  .ant-select-selection-selected-value {
    position: absolute;
    top: 20%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    line-height: 18px;
  }
  .ant-select-selection__rendered {
    line-height: 32px;
    margin-right: 24px;
    margin-left: 32px;
  }
  .ant-select-selection__placeholder {
    line-height: 18px;
    display: ${(props) =>
      props.defaultValue || props.value ? "none" : "block !important"};
  }
  .ant-select-arrow {
    top: 16px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const RangePickerWrapper = styled(RangePicker)`
  .ant-calendar-picker-input {
    border: none;
    border-radius: 0;
    padding: 4px 12px 4px 10px;
  }
`;

const RangeValueWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #828282;
  line-height: 18px;
`;

const IconWrapped = styled(CalendarIcon)`
  width: 12px;
  height: 12px;
  z-index: 1;
  position: absolute;
  top: 30%;
  left: 12px;
`;

const ShowRange = ({ value }) => {
  return (
    <RangeValueWrapper>
      {moment(value[0]).format("MM/DD/YY")}-
      {moment(value[1]).format("MM/DD/YY")}
    </RangeValueWrapper>
  );
};

const DateFilter = ({ value, handleChange, w, mt, mr }) => {
  const [filterValue, updateFilter] = useState(value);
  const [openRangePicker, toggleOpenRangePicker] = useState(false);
  useEffect(() => {
    updateFilter(value);
  }, [value]);
  const showDateRange = Array.isArray(filterValue);
  const dateValue = !showDateRange ? filterValue : null;
  return (
    <StyledSelectWrapper showDateRange={showDateRange} w={w} mt={mt} mr={mr}>
      <IconWrapped />
      <StyledSelect
        placeholder={
          showDateRange ? <ShowRange value={filterValue} /> : "Any Date"
        }
        value={dateValue}
        allowClear
        suffixIcon={<SelectIcon />}
        minWidth={w || "180"}
        onChange={(value) => {
          updateFilter(value);
          if (value === "custom") {
            toggleOpenRangePicker(true);
          } else {
            handleChange(value || "");
          }
        }}
      >
        {dateOptions.map((option) => (
          <Option title={option.label} key={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
      <Modal
        visible={openRangePicker}
        header={null}
        closable={true}
        title={null}
        footer={null}
      >
        <RangePickerWrapper
          open={openRangePicker}
          format="MM/DD/YY"
          value={Array.isArray(filterValue) ? filterValue : null}
          onChange={(value) => {
            let dateRange = [...value];
            value[0].set({ h: 0, m: 0, s: 0 });
            value[1].set({ h: 23, m: 59, s: 59 });
            updateFilter(dateRange);
            handleChange(dateRange);
            if (dateRange.length === 2) {
              toggleOpenRangePicker(false);
            }
          }}
        />
      </Modal>
    </StyledSelectWrapper>
  );
};

export default DateFilter;
