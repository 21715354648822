import React, { useState } from "react";
import {
  StyledTable,
  TitleDescriptionWrapper,
} from "./../../../components/common/styled-components";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import ActionMenu from "./../commons/ActionMenu";
import { CheckOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import UpdateReportModal from "./updateReportModal";

// const StyledLockOutlined = styled(LockOutlined)`
//   &&& svg {
//     fill: #bdbdbd;
//     width: 12px;
//     height: 12px;
//     position: relative;
//     left: 2px;
//     margin-right: 4px;
//   }
// `;

const FrequencyWrapper = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #bdbdbd;
  margin: 0px 4px;
`;

const ActiveWrapper = styled.div`
  font-size: 16px;
`;

const RecipientWrapper = styled.div`
  color: #bdbdbd;
  font-size: 11px;
`;

const ReportsList = ({ tableData, isLoading, deleteReport, updateReport }) => {
  const [showUpdateReportModal, setshowUpdateReportModal] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const handleshowUpdateReportModal = () => {
    setshowUpdateReportModal(true);
  };
  const handlehideUpdateReportModal = () => {
    setshowUpdateReportModal(false);
  };
  const columns = [
    {
      title: "Report",
      dataIndex: "report_name",
      key: "report_name",
      width: "60%",
      render: (value, record) => {
        return (
          <TitleDescriptionWrapper>
            <div>{record.report_name}</div>
          </TitleDescriptionWrapper>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "10%",
      render: (text, record) => (
        <ActiveWrapper>
          {record.is_active && <CheckOutlined style={{ color: "#27AE60" }} />}
        </ActiveWrapper>
      ),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      width: "15%",
      render: (value, record) => {
        const freqData = {
          0: "Just Once",
          1: "Daily",
          2: "Weekly",
          3: "Monthly",
        };
        return (
          <FrequencyWrapper>{freqData[record.frequency]}</FrequencyWrapper>
        );
      },
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      width: "10%",
      render: (value, record) => {
        return (
          <RecipientWrapper>
            <div>{record.recipients?.length}</div>
          </RecipientWrapper>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (value, record) => {
        return (
          <ActionMenu
            menuItems={[
              {
                title: "Edit Report",
                key: "edit",
                icon: EditIcon,
              },
              {
                title: "Delete Report",
                key: "delete",
                icon: DeleteIcon,
              },
            ]}
            handleMenuClick={(key) => {
              if (key === "delete") {
                deleteReport(record);
              } else if (key === "edit") {
                setUpdateRecord(record);
                handleshowUpdateReportModal();
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <StyledTable
        dataSource={tableData}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        scroll={{ y: "calc(100vh - 290px)" }}
        pagination={false}
      />
      {showUpdateReportModal && (
        <UpdateReportModal
          visible={showUpdateReportModal}
          recordUpdate={updateRecord}
          handlehideUpdateReportModal={handlehideUpdateReportModal}
          updateReport={updateReport}
        />
      )}
    </>
  );
};

export default ReportsList;
