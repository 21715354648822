import React, { useState } from 'react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { message, Spin, Empty } from 'antd';
import AdvanceEmptyStateIcon from '../../assets/advanced_search_icon_latest.svg';
import { ReactComponent as RedirectIcon } from "../../CompanySetup/assets/redirectIcon.svg";
import { hasManagerRights, hasQAAuditorRights } from "../../AnalyticsDashboard/config";
import Constants from '../../CompanySetup/utils/constants';

const AdvanceStateWrapper=styled.div`
        display: flex;
        flex-direction:column;
        align-items:center;
        .no-data-text{
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.25);
            margin:0.8em 0 0 0;
        }
        .no-data-sub-text{
            font-size: 14px;
            line-height: 22px;
            color: ${props => props.color || "rgba(0, 0, 0, 0.25)"};
            margin:0.8em 0 0 0;
            width: ${props => props.width || "max-content"};
            height: ${props => props.height || "max-content"};
            .link-icon{
                margin-left: 5px;
            }
        }
        & .no-search-data-text {
          color: #4F4F4F;
          font-weight: normal;
        }
        & .no-search-data-sub-text {
          margin:0.4em 0 0 0;
          font-weight: normal;
          font-size: 12px;
          color: #828282;
          text-align: center;
        }
        .ant-empty-normal {
          margin: 0;
        }
        .ant-empty-description {
          display: none;
        }
`;

const BasicWrapper = styled.div`
font-size: 14px;
color: rgba(0, 0, 0, 0.25);
`;

const EmptyState=({ type, parentText, childText , link, icon })=>{
    const [typeIs]= useState(type);
    const emptyStateView={
        [Constants.EMPTY_STATE_VIEWS.BASIC]: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        [Constants.EMPTY_STATE_VIEWS.RUBRIC_VIEW]: <AdvanceStateWrapper><img width="50" height="50" src={`${AdvanceEmptyStateIcon}`} alt="Empty State"/><div className="no-data-text">{parentText}</div>{( hasManagerRights() || hasQAAuditorRights() ) && <div className="no-data-sub-text">{childText}<Link to={link || "/"} className="link-icon"><RedirectIcon/></Link></div>}</AdvanceStateWrapper>,
        [Constants.EMPTY_STATE_VIEWS.CUSTOM_DASHBOARD_VIEW]: <AdvanceStateWrapper type={type}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />{<div className="no-data-sub-text">{parentText}<Link to={link || "/"} className="link-icon"><RedirectIcon/></Link></div>}</AdvanceStateWrapper>,
        [Constants.EMPTY_STATE_VIEWS.CALIBRATION_REPORT_VIEW]: <AdvanceStateWrapper color="#828282" width="315px" height="40px" type={type}>{icon}{<div className="no-data-sub-text">{parentText}</div>}</AdvanceStateWrapper>,
        [Constants.EMPTY_STATE_VIEWS.BASIC_TEXT]: <BasicWrapper>{parentText}</BasicWrapper>,
        [Constants.EMPTY_STATE_VIEWS.EMPTY_SEARCH_VIEW]: <AdvanceStateWrapper type={type}>{icon}{<div className="no-search-data-text no-data-sub-text">{parentText}</div>}<div className="no-search-data-sub-text no-data-sub-text">{childText}</div></AdvanceStateWrapper>,
    }
    return(
        <>
                { emptyStateView[typeIs] }
        </>
    );
};

export default EmptyState;
