import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Input, Button, Modal, message, Tooltip } from "antd";
import { changePassword } from "../../api/api";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
} from "@ant-design/icons";

const ModiyPasswordWrapper = styled.div`
  width: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 24px;
    button {
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #0b4b93;
    }
    button:last-of-type {
      margin-left: 4px;
      border: none;
    }
  }
`;

const StyledInput = styled(Input.Password)`
  padding: 4px 10px;
  border: 1px solid #bdbdbd;
  margin-top: 17px;
`;

const StrengthChecker = styled.div`
  display: flex;
  justify-content: center;
  .strength {
    display: none;
    height: 1vh;
    margin-top: 10px;
    align-items:center;
    width:80%;
  }

  .weak {
    background-color: red;
    width: 33%;
    padding: 2px;
    margin-right: 5px;
  }
  .strong {
    background-color: green;
    width: 33%;
    padding: 2px;
    margin-right: 5px;
  }
  .medium {
    background-color: orange;
    width: 33%;
    padding: 2px;
    margin-right: 5px;
  }
  .status{
    font-size:12px;
    font-weight:bold;
  }
`;

const ModifyPassword = (props) => {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const handleChange = (e) => {
    e.target.name === "current_password"? setCurrentPassword(e.target.value): e.target.name === "password"? setNewPassword(e.target.value): setConfirmPassword(e.target.value);
    e.target.name === "password" && strengthChecker(e.target.value);
  };

  const strengthChecker = (value) => {
    if (value === "") {
      document.querySelector(".strength").style.display = "none";
    } else {
      document.querySelector(".strength").style.display = "flex";
      let strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})");
      let mediumPassword = new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
      );
      if (strongPassword.test(value)) {
        document.querySelector(".weak").style.backgroundColor = "red";
        document.querySelector(".medium").style.backgroundColor = "orange";
        document.querySelector(".strong").style.backgroundColor = "green";
        document.querySelector(".status").innerHTML = "Strong";
      } else if (mediumPassword.test(value)) {
        document.querySelector(".weak").style.backgroundColor = "red";
        document.querySelector(".medium").style.backgroundColor = "orange";
        document.querySelector(".strong").style.backgroundColor = "lightgray";
        document.querySelector(".status").innerHTML = "Medium";
      } else {
        document.querySelector(".weak").style.backgroundColor = "red";
        document.querySelector(".medium").style.backgroundColor = "lightgray";
        document.querySelector(".strong").style.backgroundColor = "lightgray";
        document.querySelector(".status").innerHTML = "Weak";
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let reg =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{12,}$/;
    if (!current_password || !password || !confirm_password) {
      return message.error("You need to fill required fields.");
    } else if (!password.match(reg)) {
      return message.error({
        content:
          "New Password should have atleast 12 characters, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        duration: 7,
      });
    } else if (current_password === password) {
      return message.error(
        "Current password and New password should not be same."
      );
    } else if (password !== confirm_password) {
      return message.error("New password and Confirm password does not match.");
    }

    changePassword(password, current_password)
      .then((res) => {
        message.success("Password changed Successfully!");
        props.onSubmit(password);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        document.querySelector(".strength").style.display = "none";
      })
      .catch((err) => {
        err.response.status === 403? message.error(err.response.data.detail): err.response.status === 400? message.error("Invalid Current Password."): message.error(err.message);
      });
  };
  const tooltipTitle = (
    <div style={{ fontSize: "11px", marginLeft: "-15px", fontWeight: "bold" }}>
      <ul>
        <li>Password should have atleast 12 characters.</li>
        <li>It should contain at least one lowercase letter.</li>
        <li>It should contain at least one Uppercase letter.</li>
        <li>It should contain at least one numeric digit.</li>
        <li>It should contain at least one special character.</li>
      </ul>
    </div>
  );

  return (
    <Modal
      visible={props.show}
      header={null}
      closable={false}
      title={null}
      width={500}
      footer={null}
      style={{ marginTop: "10px" }}
    >
      <ModiyPasswordWrapper>
        <StyledInput
          name="current_password"
          type="password"
          id="current_password"
          placeholder="Current Password"
          onChange={(e) => {
            e.persist();
            handleChange(e);
          }}
          autocomplete="new-password"
          value={current_password}
          style={{ padding: "0", border: "none" }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        <div style={{ width: "100%" }}>
          <StyledInput
            name="password"
            id="new_password"
            placeholder="New Password"
            onChange={(e) => {
              e.persist();
              handleChange(e);
            }}
            value={password}
            style={{ padding: "0", border: "none" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            prefix={
              <Tooltip title={tooltipTitle} placement="bottom">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
          <StrengthChecker>
            <div className="strength">
                <div className="weak"></div>
                <div className="medium"></div>
                <div className="strong"></div>
                <span className="status"></span>
            </div>
          </StrengthChecker>
        </div>
        <StyledInput
          name="confirm_password"
          id="confirm_password"
          placeholder="Confirm Password"
          onChange={(e) => {
            e.persist();
            handleChange(e);
          }}
          value={confirm_password}
          style={{ padding: "0", border: "none" }}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          prefix={
            <Tooltip title={tooltipTitle} placement="bottom">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <div className="action-buttons">
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="primary" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </ModiyPasswordWrapper>
    </Modal>
  );
};

export default ModifyPassword;
