import React from "react";
import FaqIcon from "./../../assets/common/square/faq.png";
import FlagIcon from "./../../assets/common/square/flag.png";
import WarningIcon from "./../../assets/common/square/warning.png";
import TipIcon from "./../../assets/common/square/hint.png";
import InfoIcon from "./../../assets/common/square/info.png";
import UpsellIcon from "./../../assets/common/square/upsell.svg";
import SfAlertIcon from "./../../assets/common/square/warning.svg";
import WebIcon from "./../../assets/common/square/web.png";
import SalesforceIcon from "./../../assets/common/square/salesforce.png";
import GuruIcon from "./../../assets/common/square/guru.png";
import HelpshiftIcon from "./../../assets/common/square/helpshift.png";
import ZendeskIcon from "./../../assets/common/square/zendesk.png";
import PdfIcon from "./../../assets/common/square/pdf.png";
import DriveIcon from "./../../assets/common/square/drive.png";
import WordDocIcon from "./../../assets/common/square/word-doc.png";
import ConversationTagIcon from "./../../assets/common/round/conversation-tag.png";
import { TIMELINE_TYPES } from "./Constants";

const SquareIcon = ({ type }) => {
  switch (type) {
    case TIMELINE_TYPES.FAQ:
      return <img src={FaqIcon} alt="" />;
    case TIMELINE_TYPES.FLAG:
      return <img src={FlagIcon} alt="" />;
    case TIMELINE_TYPES.WARNING:
      return <img src={WarningIcon} alt="" />;
    case TIMELINE_TYPES.HINT:
      return <img src={TipIcon} alt="" />;
    case TIMELINE_TYPES.COMPLIANCE:
      return <img src={ConversationTagIcon} alt=""/>
    case TIMELINE_TYPES.SF_ALERT:
      return <img src={SfAlertIcon} alt=""/>
    case TIMELINE_TYPES.UPSELL:
      return <img src={UpsellIcon} alt=""/>
    case "info":
      return <img src={InfoIcon} alt="" />;
    case "web":
      return <img src={WebIcon} alt="" />;
    case "salesforce":
      return <img src={SalesforceIcon} alt="" />;
    case "guru":
      return <img src={GuruIcon} alt="" />;
    case "helpshift":
      return <img src={HelpshiftIcon} alt="" />;
    case "zendesk":
      return <img src={ZendeskIcon} alt="" />;
    case "pdf":
      return <img src={PdfIcon} alt="" />;
    case "drive":
      return <img src={DriveIcon} alt="" />;
    case "word-doc":
      return <img src={WordDocIcon} alt="" />;
    default:
      return null;
  }
};

export default SquareIcon;
