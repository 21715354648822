import { Select } from "antd";
import React from "react";
import useSWR from "swr";
import DateFilter from "../../../components/common/DateFilter";
const url = "/automation/list/";

const Filters = ({ setFilters }) => {
  const { Option } = Select;
  const { data, error } = useSWR(url);
  const { data: triggers } = useSWR("/gettriggers");
  const handleFilterChange = (val, name) => {
    setFilters((p) => ({
      ...p,
      [name]: val,
    }));
  };
  const removeCreated = () => {
    setFilters((p) => {
      let nFilters = { ...p };
      delete nFilters["created"];
      return nFilters;
    });
  };
  return (
    <div className="d-flex align-items-center m-3 mx-4">
      <DateFilter
        handleChange={(value) => {
          if (!value) {
            removeCreated();
            return;
          }
          let d = new Date();
          d.setDate(d.getDate() - value);
          handleFilterChange(d.toISOString(), "created");
        }}
      />
      <Select
        style={{ width: "180px", marginTop: "10px" }}
        className="mx-2"
        loading={!data || !data.results}
        onChange={(val) => handleFilterChange(val, "title")}
        placeholder="Workflow Name"
      >
        {data?.results?.map((item) => (
          <Option key={item.id} value={item.title}>
            {item.title}
          </Option>
        ))}
      </Select>
      <Select
        style={{ width: "180px", marginTop: "10px" }}
        loading={!triggers}
        onChange={(val) => handleFilterChange(val, "trigger_type")}
        placeholder="Trigger Type"
      >
        {triggers?.map((item) => (
          <Option key={item.trigger_type} value={item.trigger_type}>
            {item.display_name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Filters;
