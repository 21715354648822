import Constants from '../../utils/constants';
import { getRuleDetails, postRule, updateRule, verifyRule } from '../../api/api';
// import { resolve } from 'path';
import { message } from "antd";

export default class Rule {
    constructor() {
        this.title = ''
        this.description = ''
        this.condition_category = null
        this.condition_value = null
        this.expectation_category=Constants.EXPECTATION_CATEGORIES[0]
        this.expectation_value=""
        this.time_restriction = null
        this.negate = false        
        this.hint_enabled = true
        this.flag_enabled = true
        this.warning_enabled = true        
        this.rule_audit = null
        this.modified = false
        this.agent_assist_enabled = true
        this.selectedMenuItem = "edit"
    }
    getRuleState = () => {
        let trigger = null
        let showIntentDropdown = {}

        if (this.condition_category !== null && this.condition_value !== null) {
            trigger = {
                type: this.condition_category,
                value: this.condition_value
            }
        }

        if (this.condition_category === Constants.INTEND) {
            showIntentDropdown = {...showIntentDropdown, condition_category: true}
        }
        if (this.expectation_category === Constants.INTEND) {
            showIntentDropdown = {...showIntentDropdown, expectation_category: true}
        }

        return {
            title: this.title,
            description: this.description,
            trigger,
            expectation: {
                type: this.expectation_category,
                value: this.expectation_value
            },
            timeRestricton: this.time_restriction,
            flagCount: this.flag_count,
            actions: this.getActions(),
            loading: false,
            hint_enabled: this.hint_enabled,
            flag_enabled: this.flag_enabled,
            warning_enabled: this.warning_enabled,
            rule_audit: this.rule_audit,
            selectedMenuItem: this.selectedMenuItem,
            agent_assist_enabled: this.agent_assist_enabled,
            showIntentDropdown
        }
    }

    generateActions = (actions) => {
        this.flag_enabled = false;
        this.hint_enabled = false;
        this.warning_enabled = false;
        if (actions.includes(Constants.FLAG)) {
            this.flag_count = 1
            this.flag_enabled = true
            this.negate = true
        }

        if (actions.includes(Constants.HINT)) {
            this.hint_enabled = true
        }

        if (actions.includes(Constants.WARNING)) {
            this.warning_enabled = true
            this.negate = true
        }
    }

    getActions = () => {
        let actions = []
        if (this.hint_enabled) {
            let customMessage = this.hint_action_message === this.generateMessage() ? null : this.hint_action_message
            actions.push({
                category: Constants.HINT,
                value: customMessage ? customMessage : this.generateMessage(),
                after: 1,
                customMessage
            })
        }
        if (this.warning_enabled) {
            let customMessage = this.warning_action_message === this.generateMessage() ? null : this.warning_action_message
            actions.push({
                category: Constants.WARNING,
                value: customMessage ? customMessage : this.generateMessage(),
                after: 1,
                customMessage
            })
        }
        if (this.flag_enabled) {
            let customMessage = this.flag_action_message === this.generateMessage() ? null : this.flag_action_message
            actions.push({
                category: Constants.FLAG,
                value: customMessage ? customMessage : this.generateMessage(),
                after: this.flag_count,
                customMessage
            })
        }
        return actions
    }

    setValue(value, name) {
        this[name] = value;
        this.modified = true;
        return this.getRuleState()
    }
    setConditionCategory(value) {
        this.condition_category = value
        this.modified = true;
        return this.getRuleState()
    }
    setConditionValue(value) {
        this.condition_value = value
        this.modified = true;
        return this.getRuleState()
    }
    setTimeRestriction(value) {
        this.time_restriction = value
        this.modified = true;
        return this.getRuleState()
    }
    setFlagCount(value) {
        this.flag_count = value
        return this.getRuleState()
    }
    selectMenu(menuItem) {
        this.selectedMenuItem = menuItem;
        return this.getRuleState()
    }
    generateMessage = () => {
        let negate = this.negate ? ` not` : ``
        let messageExpectation = `You should${negate} ${Constants.MESSAGE_GENERATOR.AGENT[this.expectation_category]} ${this.expectation_value} in the conversation`
        let messageCondition = this.condition_category ? `if the customer ${Constants.MESSAGE_GENERATOR.CUSTOMER[this.condition_category]} ${this.condition_value}` : ``
        let timeRestricton = this.time_restriction ? `within ${this.time_restriction} seconds.`: ``

        return `${messageExpectation} ${messageCondition} ${timeRestricton}`.trim()
    }
    conditionExists = () => {
        return this.condition_category && this.condition_value && this.condition_value !== ""
    }
    ruleRequest = () => {
        let conditions = []
        if (this.conditionExists()) {
            conditions = [
                {
                    category: Constants.CATEGORIES[this.condition_category],
                    value: this.condition_value,
                    negate_subrule: false
                },
                {
                    category: "speaker",
                    value: "customer",
                    negate_subrule: false
                }
            ]
        }
        let expectations = [
            {
                category: Constants.CATEGORIES[this.expectation_category],
                value: this.expectation_value,
                negate_subrule: false
            },
            {
                category: "speaker",
                value: "assistant",
                negate_subrule: false
            }
        ]
        let actions = this.getActions()
        let description = actions?.length ? actions[0].value : this.generateMessage();
        
        return {
            title: this.title,
            description: description,
            time_restriction: this.time_restriction || 0,
            is_active: this.is_active,
            is_hidden: false,
            agent_assist_enabled: this.agent_assist_enabled,
            conditions,
            expectations,
            actions
        }
    }

    validate = () => {
        let error = [];
        if(this.title === "") {
            error.push("title")
        }
        // if(this.description === "") {
        //     error.push("description")
        // }
        if (this.expectation_value === "") {
            error.push("expectation_value")
        }
        return error
    }
    createRule = () => {
        return new Promise((resolve, reject) => {
            let error = this.validate()
            if (error.length === 0) {
                postRule(this.ruleRequest()).then(res => {
                    resolve(res)
                }, (err) => {
                    try {
                        message.error(Object.values(err.response.data).join(", "));
                    } catch(e) {}
                })
            } else {
                reject(error)
            }
        })
    }
    updateRule = (id) => {
        return new Promise((resolve, reject) => {
            let error = this.validate()
            if (error.length === 0) {
                updateRule(id, this.ruleRequest()).then(res => {
                    resolve(res)
                }, (err) => {
                    try {
                        message.error(Object.values(err.response.data).join(", "));
                    } catch(e) {}
                })
            } else {
                reject(error)
            }
        })
    }
    verifyRule = (id) => {
        return new Promise((resolve, reject) => {
            if(id) {
                if(this.modified) {
                    this.updateRule(id).then((res) => {
                        verifyRule(id).then((res) => {
                            resolve(res);
                        });
                    }, (err) => {
                        try {
                            message.error(Object.values(err.response.data).join(", "));
                        } catch(e) {}
                    });
                }
                else {
                    verifyRule(id).then((res) => {
                        resolve(res);
                    });
                }
            }
            else {
                this.createRule().then((res) => {
                    verifyRule(res.id).then((res) => {
                        resolve(res);
                    });
                }, (err) => {
                    try {
                        message.error(Object.values(err.response.data).join(", "));
                    } catch(e) {}
                });
            }
        });
    }
    isNegative = () => {
        return this.negate
    }
    retrieve = (id) => {
        return new Promise((resolve, reject) => {
            getRuleDetails(id).then(res => {
                // conversion of response to form data
                this.title = res.title
                this.description = res.description
                this.time_restriction = res.time_restriction === 0 ? null : res.time_restriction;
                this.is_active = res.is_active
                this.rule_audit = res.rule_audit
                this.agent_assist_enabled = res.agent_assist_enabled
    
                for (let condition of res.conditions) {
                    // currently only supports a single condition except for the speaker
    
                    if (condition.category !== "speaker") {
                        this.condition_category = Constants.INVERT_CATEGORIES[condition.category]
                        this.condition_value = condition.value
                    }
                }
    
                for (let expectation of res.expectations) {
                    // currently only supports a single expectation except for the speaker
    
                    if (expectation.category !== "speaker") {
                        this.expectation_category = Constants.INVERT_CATEGORIES[expectation.category]
                        this.expectation_value = expectation.value
                    }
                }
                
                for (let action of res.actions) {
                    switch(action.category) {
                        case Constants.FLAG: 
                            this.flag_count = action.after
                            this.negate = true
                            this.flag_action_message = action.value ? action.value : ""
                            break
    
                        case Constants.HINT: 
                            this.hint_action_message = action.value ? action.value : ""
                            break
                        
    
                        case Constants.WARNING: {
                            this.warning_action_message = action.value ? action.value : ""
                            break
                        }

                        default:
                            break;
                    }
                }
                resolve(this.getRuleState())
            })
        })
        
    }
}