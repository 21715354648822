import axios from 'axios';
import { message } from "antd";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
import { axiosInstance, getSchemaName, getToken, BASE_URL, getScreenrecordToken } from "./../api/api";

const setASRLOGID = (conversationId) => (dispatch, getState) => {

}

export const getConversationSummary =  (conversationId, filters) => (dispatch, getState) =>{
    return axiosInstance({
      method: "GET",
      url: `/banking/conversation-summary/${conversationId}/`,
      params: filters
  }).then(response => {
    dispatch({ type: ReduxActionTypes.SET_ASR_LOG_ID, payload: response.data.asr_log});
    return response.data;
  }).catch(response => {
    throw new Error();
  });
}

export const getConversationDetails = (conversationId, version) => (dispatch, getState) => {
  return axiosInstance({
      method: "GET",
      url: `/banking/${version ? version + "/" : "" }retrieve/${conversationId}/`,
      
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
};

export const getConversationMoments = (asrId, version, page) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/level-nlp/${version ? version + "/" : "" }moments/list/${asrId}?page=${page}`,
     
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
};

export const getConversationMetrics = async (asrId) => {
    const { data: response } = await axiosInstance({
      method: "GET",
      url: `/qa/metrics/retrieve/${asrId}`,
      
    });
    return response;
};

export const getConversationFeeback = async (asrId) => {
    const { data: response } = await axiosInstance({
      method: "GET",
      url: `/qa/retrieve/${asrId}`,
      
    });
    return response;
};

export const addConversationReview = async(reviewData) => {
    const { data: response } = await axiosInstance({
        method: "POST",
        url: `/qa/create/`,
        data: reviewData,
    });
    return response;
}

export const addConversationQAScore = async(asrId, qaScore) => {
    const { data: response } = await axiosInstance({
        method: "PUT",
        url: `/qa/qa-score/${asrId}`,
        data: { qa_score: qaScore }
    });
    return response;
}

//api name was changed
export const getConversationTopicClassification = (asrId) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/level-nlp/v2/categorization_topics/${asrId}`,
     
    }).then(response => {
      return response.data;
    }).catch(err => {
      throw new Error();
    });
}

export const getConversationTranscript = (conversationId) => (dispatch, getState) => {
  return axiosInstance({
    method: "GET",
    url: `/banking/analyze/${conversationId}/`,
     
  }).then(response => {
    return response.data;
  }).catch(err => {
    throw new Error();
  });
}

export const createBookmark = async(data, version) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    url: `/banking/v2/bookmark/`,
    data: data,
     
  });
  return response;
}

export const deleteBookmark = async(conversationId, version) => {
  const { data: response } = await axiosInstance({
    method: "DELETE",
    url: `/banking/v2/bookmark/${conversationId}/`,
     
  });
  return response;
}

export const getReviewRubric = async(asr_log_id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/qa/custom-forms/review/retrieve/${asr_log_id}`,
     
  });
  return response;
}

export const getReviewRubricResponse = async(asr_log_id) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/qa/custom-forms/answer/retrieve/${asr_log_id}`,
     
  });
  return response;
}

export const submitReviewRubricResponse = async(data) => {
  const { data: response } = await axiosInstance({
    method: "POST",
    data,
    url: `/qa/custom-forms/answer/submit/`,
     
  });
  return response;
}

export const updateReviewRubricResponse = async(id, data) => {
  const { data: response } = await axiosInstance({
    method: "PUT",
    data,
    url: `/qa/custom-forms/answer/update/${id}`,
     
  });
  return response;
}

export const getCSATScore = async(asrId) => {
  const { data: response } = await axiosInstance({
    method: "GET",
    url: `/level-asr/csat-score/${asrId}`,
     
  });
  return response; 
}

export const postComments = (payload) => (dispatch, getState) => {
  return axiosInstance({
    method: "POST",
    url: `/qa/question/comment/`,
    data: payload,
  })
    .then((response) => {
      message.success("Comment posted successfully!");
      return response.data;
    })
    .catch((response) => {
      throw new Error();
    });
};

export const patchComment = async ({ editedComment, commentId }) => {
  const { data } = await axiosInstance({
    method: "PATCH",
    url: `/qa/question/comment/${commentId}/`,
    data: {
      comment: editedComment,
    },
  });
  return data;
};

export const deleteComment = async ({ commentId }) => {
  const { data } = await axiosInstance({
    method: "DELETE",
    url: `/qa/question/comment/${commentId}/`,
  });
  return data;
};

export const getCalibrationRubricResponse = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "GET",
    url: `/qa_calibration/response/${rest.session}/`,
    params: {
      participant: rest.participant,
      conversation: rest.conversation
    },
     
    cancelToken: cancelToken?.token
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const sendCalibrationFormResponse = (cancelToken, rest) => () => {
  return axiosInstance({
    method: "POST",
    url: `/qa_calibration/form/${rest.session}`,
    data : rest.data,
     
    cancelToken: cancelToken?.token
}).then(response => {
  return response.data;
}).catch(err => {
  throw new Error();
});
}

export const getScreenRecordSignedUrl = async (caseID, extEmpId) => {
  return axios.post(
    "https://screen-createm3u8.thelevel.ai/createm3u8",
    {
      owner_id: extEmpId,
      case_id: caseID,
    },
    {
      headers: {
        authorization: `Token ${getScreenrecordToken()}`,
      },
    }
  );
};
