export const HeapAnalyticsEvent = {
  Sort_Agents_By: "Sort Agents By",
  Detect_Chart: "Detect Chart",
  Detect_Category: "Detect Category",
  Detect_Section: "Detect Section",
  Detect_View: "Detect View",
  Switch_Metric: "Switch Metric",
  Detect_Filters: "Detect Filters",
  Detect_Conversation_History_Page_Visit:
    "Detect_Conversation_History_Page_Visit",
  Detect_Dashboard_Page_Visit: "Detect_Dashboard_Page_Visit",
  Detect_Analytics_Page_Visit: "Detect_Analytics_Page_Visit",
  Detect_Team_QA_Score_Page_Visit: "Detect_Team_QA_Score_Page_Visit",
  Detect_Team_QA_Efficiency_Page_Visit: "Detect_Team_QA_Efficiency_Page_Visit",
  Detect_Agent_QA_Score_Page_Visit: "Detect_Agent_QA_Score_Page_Visit",
  Detect_Agent_QA_Efficiency_Page_Visit:
    "Detect_Agent_QA_Efficiency_Page_Visit",
  Detect_Phrase_Search_Click: "Detect_Phrase_Search_Click",
  Detect_Chart_Click: "Detect_Chart_Click",
  Detect_Overdue_Payment_Click: "Detect_Overdue_Payment_Click",
  Analyse_Evaluations: "Analyse Evaluations",
  Set_Evaluation_Status: "Set Evaluation Status",
  Detect_Key_Interactions: "Detect_Key_Interactions",
  Detect_Brex_Card_Checked: "Detect_Brex_Card_Checked",
  Detect_Calibration_Page_Visit: "Calibration Page Visit",
  Detect_Report_View_Visit: "Report View Page Visit",
  Detect_New_Session_Button: "Detect New Session Button",
  Detect_Send_Invite_Button: "Detect Send Invite Button",
  Detect_Calibration_Response_Page_Visit: "Calibration Response Page Visit",
  Detect_Send_Response_Button: "Detect Send Response Button",
  Detect_Delete_Session_Button: "Detect Delete Session Button",
  Detect_Complete_Session_Button: "Detect Complete Session Button",
  Detect_Calibration_Score_Button: "Detect Calibration Score Button",
  Detect_Add_Response_Button: "Detect Add Response Button",
  Detect_Report_View_Tab: "Detect Report View Tab",
  Detect_Add_Comment_Button: "Detect Add Comment Button",
  Detect_Post_Comment_Button: "Detect Post Comment Button",
  Detect_Unique_Participants: "Detect Unique Participants",
  Detect_Particpant_Clickthrough: "Detect Particpant Clickthrough",
  Detect_Right_Response_Checkbox: "Detect Right Response Checkbox",
  Detect_Interaction_Review_Page_Visit: "Detect Interaction Review Page Visit",
  Detect_Conversation_Evaluated: "Detect Conversation Evaluated",
  Detect_Conversation_Tags_Generated: "Detect Conversation Tags Generated",
  Detect_Dispute: "Detect_Dispute",
  Detect_QA_Rubrics_Built: "Detect QA Rubrics Built",
  QA_Calibration_Session_Initiated: "QA Calibration Session Initiated",
  QA_Calibration_Session_Completed: "QA Calibration Session Completed",
  DOWNLOAD_CSV_INTERACTION_HISTORY: "DOWNLOAD_CSV_INTERACTION_HISTORY"
};
