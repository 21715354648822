import React, { useState, useCallback } from "react"
import { Checkbox } from 'antd';
import { CheckboxWrapper } from './StyledComponents';
import { TIMELINE_TYPES } from "./Constants";
import { debounce } from 'lodash';
import { addAnalyticsEvent } from "../utils";
import { HeapAnalyticsEvent } from "../components/common/HeapAnalyticsEvent";
import appConfigs from '../config';

const StyledCheckbox = ({ category, is_checkmarked, apiExecutionFunction }) => {
    const [checked_val, setCheckedVal] = useState(is_checkmarked ? true : false);
    const debounceApiCall = useCallback(debounce(apiExecutionFunction, 800), []);
    const handleCheck = (event) => {
        //need to remove comments
        if(!window.location.pathname.includes("/plugin/assist") && !appConfigs.ENABLE_IS_CHECKMARKED) {
            return;
        }
        const value = event.target.checked;
        setCheckedVal(value);
        debounceApiCall(value);
        addAnalyticsEvent(HeapAnalyticsEvent.Detect_Brex_Card_Checked, { value, category });
    }

    return (
        <>
        { category === TIMELINE_TYPES.SF_ALERT || category === TIMELINE_TYPES.UPSELL ? <CheckboxWrapper> <Checkbox onChange = { handleCheck } checked={ checked_val }/> </CheckboxWrapper> : null }
        </>
    );
};

export default StyledCheckbox;
