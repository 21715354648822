import React from "react";
import Constants from './constants';
import RulePopup from './../components/RulePopup';
import FaqPopup from './../components/FaqPopup';
import ResourcePopup from './../components/ResourcePopup';
import IntentPopup from './../components/IntentPopup';
import MetricTagPopup from "./../components/MetricTagPopup";
import PlayGroundPopup from "./../components/playground-popup";

const SetupPopup = ({ tabType, popupId, isHistorical, onSubmit, onPopupCancel, actionTypes, negate, rules, ruleData}) => {
    if (tabType === Constants.TAB_TYPE.FAQ)  {
        return (
            <FaqPopup 
                onCancel={onPopupCancel} 
                onSubmit={onSubmit} 
                isHistorical={isHistorical} 
                id={popupId}
            />
        )
    }
    else if (tabType === Constants.TAB_TYPE.RESOURCE) {
        return (
            <ResourcePopup 
                onCancel={onPopupCancel} 
                onSubmit={onSubmit} 
            />
        );
    }
    else if (tabType === Constants.TAB_TYPE.INTENT) {
        return (
            <IntentPopup 
                viewOnly={ruleData?.intent_type?.toLowerCase() === Constants.UNIVERSAL}
                intentType={ruleData?.intent_type?.toLowerCase()}
                onCancel={onPopupCancel} 
                onSubmit={onSubmit} 
                id={popupId}
                tabType={tabType}
                isHistorical={isHistorical}                
                rules={rules}
            />
        ) 
    }
    else if (tabType === Constants.TAB_TYPE.METRIC_TAGS) {
        console.log({ popupId, tabType, isHistorical });
        return (
            <MetricTagPopup 
                onCancel={onPopupCancel} 
                onSubmit={onSubmit} 
                id={popupId}
                tabType={tabType}
                isHistorical={isHistorical}
                ruleData={ruleData}
            />
        ) 
    }
    else if(tabType===Constants.TAB_TYPE.INTENT_PLAYGROUND){
        return(
                <PlayGroundPopup
                onCancel={onPopupCancel} 
                />
        )
    }
    else {
        return (
            <RulePopup 
                onCancel={onPopupCancel} 
                onSubmit={onSubmit} 
                isHistorical={isHistorical} 
                ruleId={popupId} 
                actionTypes={actionTypes}   
                negate={negate}             
            />
        );    
    }       
};

export default SetupPopup;