import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "../../constants/ReduxActionConstants";

const initialState = {
  keyInteractionData: [],
};

const keyInteractionReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_KEY_INTERACTION_DATA]: (
    state,
    action,
  ) => {
    return {
      ...state,
      keyInteractionData: action.payload,
    };
  },
});

export default keyInteractionReducer;