import styled from "@emotion/styled/macro";

export const Seperator = styled.div`
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 10px 0;
`;

export const Card = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
  & .icon {
    width: 35px;
    text-align: center;
  }
  & .error-log {
    margin-top: 0.2rem;
    color: #cb5e53;
  }
  & h3 {
    font-size: 14px;
  }
  & p {
    font-size: 12px;
    color: #828282;
    margin-bottom: 0;
  }
  & li {
    padding: 0 0.2rem;
    margin: 0 0.5rem;
  }
  margin: 1rem;
`;
