import React, { useState, useEffect } from "react";
import { message } from "antd";
import FilterComponent from "./FilterComponent";
import { useDispatch, useSelector } from "../../store";
import { getCalibrationStatusesList } from "../../QaCalibration/api";

const CalibrationStatusFilter = ({value, handleChange, multiSelect}) => {
  const [calibrationStatus, updateCalibrationStatus] = useState(value);
  const [options,setOptions] = useState();
  const filtersSegmentList = useSelector(state => state.dashboard.filterSegments);
  const dispatch = useDispatch();
  useEffect(() => {
    updateCalibrationStatus(value);
  }, [value]);
  useEffect(()=>{
    if(filtersSegmentList.calibrationStatusList){
      setOptions(filtersSegmentList.calibrationStatusList);
    } else {
      dispatch(getCalibrationStatusesList()).then(res => {
        console.log("WORED", res);
        setOptions(res);
      }).catch(e => setOptions([]))
    }
  },[])
  return (
      <FilterComponent
      width={200}    
      options={options?.length > 0 ? [...new Set(filtersSegmentList.calibrationStatusList.map((item) => {
        return {
          label: item.display_name,
          value: item.id,
        }
      }).sort((a,b)=>a.label.toLowerCase().localeCompare(b.label.toLowerCase())))] : []}
      placeholder="All Statuses"
      value={calibrationStatus}
      maxTagCount= {1}
      multiSelect={multiSelect}
      showSearchIcon={true}
      handleChange={(value, option) => {
            if(value === undefined) {
              //getStatuses();
            }
            updateCalibrationStatus(value);
            handleChange(value);
      }}
      showSearch={true}
      hideClear={false}
      loading={!!options}
  /> 
  );
}

export default CalibrationStatusFilter;
