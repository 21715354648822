import styled from "@emotion/styled";

export const CustomFilterWrapper = styled.div`
  position: relative;
  user-select: none;
  & .add-filter-hover-style {
    box-shadow: 0 0 0 2px rgb(96 153 217 / 20%);
  }

  & .add-background-color {
    transition: 0.1s ease-in;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
  & .scrollbar-css {
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #fff;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 15px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: auto;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: darkgray;
      border-radius: 15px;
    }
  }
`;

export const FilterWrapper = styled.div`
  height: auto;
  min-width: ${props => props.minWidth ? props.minWidth : 150}px;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? "none" : "all"};
  max-width: 100%;
  border: 1px solid #bdbdbd;
  //box-shadow: 0 0 0 2px rgb(96 153 217 / 20%);
  border-right-width: 1px !important;
  box-sizing: border-box;
  background-color: ${props => props.disabled ? "#F5F5F5" : "#fff"};
  border: ${props => props.disabled ? "1px solid #d9d9d9" : props.iserror ? "1px solid #DB554D" : "1px solid #bdbdbd"};
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  & .placeholder-span {
    color: ${props => props.disabled ? "rgba(0, 0, 0, 0.25)" : "#4F4F4F"};
    & .placeholder {
      color: ${props => props.disabled ? "rgba(0, 0, 0, 0.25)" : "#bfbfbf"};
    }
  }

  & .search-arrow {
    & > .expand-icon {
      width: 20px;
      height: 17px;
      margin-top: 7px;
      & > path {
        fill: ${props => props.disabled ? "rgba(0, 0, 0, 0.25)" : "rgb(151, 151, 151)"};
      }
    }
  }
`;
export const DropdownWrapper = styled.div`
  position: absolute;
  max-height: 350px;
  width: 400px;
  padding: 4px 0;
  z-index: 10;
  margin-top: 2px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SearchWrapper = styled.div`
  & .searchInput {
    width: 100%;
    & .ant-input-prefix {
      top: 20px;
      margin-left: 8px;
    }
    & .ant-input-suffix {
      top: 20px;
      margin-right: 8px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  margin-bottom: 10px;
  overflow-y: auto;
  & .ant-checkbox-group {
    width: 100%;
    & .ant-checkbox-group-item {
      padding: 4px 10px 6px 10px;
      width: 100%;
    }
    & .ant-checkbox-group-item:hover {
      background: #fafafa;
      transition: 0.4s ease;
    }
  }

  & .option-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    cursor: pointer;
    & .option-check-icon {
      & .option-check-icon-svg {
        width: 15px;
        height: 15px;
        margin-top: 2px;
        & path {
          fill: #6099d9;
        }
      }
    }
  }
  & .option-items:hover {
    transition: 0.4s ease;
    background: #fafafa;
  }
  & .option-items.option-items-background {
    background: #fafafa;
    font-weight: 600;
  }
`;

export const OptionItem = styled.div``;

export const FooterWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-top: 1px solid #e0e0e0;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  width: 100%;
`;

export const FooterSubWrapper = styled.div``;

export const ClearAllButton = styled.div`
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  color: ${(props) => (props.disabled ? "#BDBDBD" : "#4F4F4F")};
`;

export const EmptyStateWrapper = styled.div`
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextOnlyWrapper = styled.div`
  display: ${props => props.format === "inline" ? "inline" : "flex"};
  flex-direction: column;
`;
