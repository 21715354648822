import React, { useEffect, useState, useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
//import styled from "@emotion/styled/macro";
import { Select, message, Spin } from "antd";
import AddButton from "../../../components/common/AddButton";
import RemovableEntry from "../RemovableEntry";
//import { debounce } from "lodash";
import {
  createCategory,
  editCategory,
  listAllCategories,
  mergeCategories,
} from "../../api/api";
import {
  StyledModal,
  ModalWrapper,
  ModalHeaderWrapper,
  ConversationModalHeading,
  ConversationModalFieldContainer,
  CategoryTitleField,
  ModalBodyWrapper,
  ModalBodyHeading,
  ModalBodyText,
  ModalPhrases,
  StylePhraseInputWrapper,
  StyledPhraseInput,
  ModalFooter,
  CancelButton,
  CreateButton,
} from "./wrappers";

const ConversationModal = (props) => {
  const [categoryID, setCategoryID] = useState("");
  const [previousCategoryTitle, setPreviousCategoryTitle] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [previousPhrasesArr, setPreviousPhrasesArr] = useState([]);
  const [phrasesArr, setPhrasesArr] = useState([]);
  const [optionValues, setOptionValues] = useState([]);
  const [categoryDropdownArr, setCategoryDropdownArr] = useState([
    {
      id: "0",
      categoryValue: "",
    },
  ]);
  const { Option } = Select;
  const mapDetailedPhrases = (phrases, index) => ({
    id: `${index}`,
    text: phrases,
  });
  const getInitialPhrases = () => new Array(3).fill("").map(mapDetailedPhrases);
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const handleRemoveEntry = (propsRemove) => {
    let updatedPhrases = phrasesArr.filter(
      (item) => propsRemove.id !== item.id
    );
    setPhrasesArr(updatedPhrases);
  };

  const handleRemoveMergeEntries = (propsRemove) => {
    let updatedCategories = categoryDropdownArr.filter(
      (item) => propsRemove.id !== item.id
    );
    setCategoryDropdownArr(updatedCategories);
  };

  const handleAddEntry = () => {
    if (phrasesArr.length !== 0) {
      const id = parseInt(phrasesArr[phrasesArr.length - 1].id) + 1;
      setPhrasesArr([
        ...phrasesArr,
        {
          id: `${id}`,
          text: "",
        },
      ]);
    } else {
      setPhrasesArr([
        {
          id: `0`,
          text: "",
        },
      ]);
    }
  };

  const handlePhraseChange = (e) => {
    const text = e.target.value;
    const id = e.target.id;
    let updatedPhrases = phrasesArr.map((item) => {
      if (item.id === id) {
        item.text = text;
        return item;
      } else {
        return item;
      }
    });
    setPhrasesArr(updatedPhrases);
  };

  const handleAddMergeCategories = () => {
    if (categoryDropdownArr.length !== 0) {
      const id =
        parseInt(categoryDropdownArr[categoryDropdownArr.length - 1].id) + 1;
      setCategoryDropdownArr([
        ...categoryDropdownArr,
        {
          id: `${id}`,
          categoryValue: "",
        },
      ]);
    } else {
      setCategoryDropdownArr([
        ...categoryDropdownArr,
        {
          id: "0",
          categoryValue: "",
        },
      ]);
    }
  };

  const handleCategoryDropdownChange = (value, id) => {
    let updatedCategoryDropdownArr = categoryDropdownArr.map((item) => {
      if (item.id === id) {
        item.categoryValue = value;
        return item;
      } else {
        return item;
      }
    });
    setCategoryDropdownArr(updatedCategoryDropdownArr);
  };

  const hasDuplicates = (array, flag) => {
    if (flag === "removeSpace") {
      let updatedArr = array.map((item) => item.replace(/\s*$/, ""));
      return new Set(updatedArr).size !== updatedArr.length;
    }
    return new Set(array).size !== array.length;
  };

  const handleCreateClick = () => {
    let tempArr = [];
    setDisableButton(true);
    if (categoryTitle === "") {
      setDisableButton(false);
      return message.error("Category Title is a required field.");
    }
    phrasesArr.map((item) => item.text !== "" && tempArr.push(item.text));
    if (tempArr.length === 0) {
      setDisableButton(false);
      message.error("No phrase added.");
    } else {
      if (hasDuplicates(tempArr, "removeSpace")) {
        setDisableButton(false);
        return message.error("Duplicate phrases found.");
      }
      createCategory(categoryTitle, tempArr, "custom")
        .then(() => {
          props.handleCloseModal();
          props.handleSetState("Category created successfully.");
          setDisableButton(false);
        })
        .catch((err) => {
          err.response.status === 400
            ? message.error(err.response.data.title[0])
            : message.error(err.message);
          setDisableButton(false);
        });
    }
  };

  const comparePhrases = () => {
    let oldPhrasesArr = [];
    let newPhrasesArr = [];
    previousPhrasesArr.forEach(
      (item) => item.text !== "" && oldPhrasesArr.push(item.text)
    );
    phrasesArr.forEach(
      (item) => item.text !== "" && newPhrasesArr.push(item.text)
    );
    if (
      JSON.stringify(
        oldPhrasesArr.sort(function (a, b) {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        })
      ) !==
      JSON.stringify(
        newPhrasesArr.sort(function (a, b) {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        })
      )
    ) {
      return newPhrasesArr;
    } else {
      return [];
    }
  };
  const handleSaveClick = () => {
    let newPhrasesArr = [];
    setDisableButton(true);
    if (categoryTitle === "") {
      setDisableButton(false);
      return message.error("Category Title is a required field.");
    }

    phrasesArr.forEach((item) => item.text !== "" && newPhrasesArr.push(item.text));
    if (newPhrasesArr.length === 0) {
      setDisableButton(false);
      message.error("No phrase added.");
    } else {
      if (hasDuplicates(newPhrasesArr, "removeSpace")) {
        setDisableButton(false);
        return message.error("Duplicate phrases found.");
      }
      editCategory(
        categoryID,
        categoryTitle !== previousCategoryTitle ? categoryTitle : "",
        comparePhrases(),
        "custom"
      )
        .then(() => {
          props.handleCloseModal();
          props.handleSetState("Category updated successfully.");
          setDisableButton(false);
        })
        .catch((err) => {
          err.response.status === 400
            ? message.error(err.response.data.title[0])
            : message.error(err.message);
          setDisableButton(false);
        });
    }
  };

  const handleMergeClick = () => {
    let tempArr = [];
    let catgoryIDArr = [];
    setDisableButton(true);
    if (categoryTitle === "") {
      setDisableButton(false);
      return message.error("Category Title is a required field");
    }
    categoryDropdownArr.map(
      (item) => item.categoryValue !== "" && tempArr.push(item.categoryValue)
    );
    if (tempArr.length === 0) {
      setDisableButton(false);
      message.error("No category selected.");
    } else {
      tempArr.map((item) => {
        optionValues.map(
          (options) =>
            item === options.categoryOptionValue &&
            catgoryIDArr.push(options.optionID)
        );
      });
      if (hasDuplicates(catgoryIDArr)) {
        setDisableButton(false);
        return message.error("Duplicate category values selected.");
      }
      mergeCategories(categoryID, catgoryIDArr)
        .then((res) => {
          props.handleCloseModal();
          props.handleSetState("Category merged successfully.");
          setDisableButton(false);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  const buttonType = {
    Merge: {
      addButtonType: (
        <AddButton className="add-btn" onClick={handleAddMergeCategories}>
          {props.buttonText}
        </AddButton>
      ),
      createButtonType: (
        <CreateButton onClick={handleMergeClick} disabled={disableButton}>
          {disableButton && <Spin indicator={antIcon} size={"small"} />}
          Merge
        </CreateButton>
      ),
    },
    Create: {
      addButtonType: (
        <AddButton className="add-btn" onClick={handleAddEntry}>
          {props.buttonText}
        </AddButton>
      ),
      createButtonType: (
        <CreateButton onClick={handleCreateClick} disabled={disableButton}>
          {disableButton && <Spin indicator={antIcon} size={"small"} />}Create
        </CreateButton>
      ),
    },
    Update: {
      addButtonType: (
        <AddButton className="add-btn" onClick={handleAddEntry}>
          {props.buttonText}
        </AddButton>
      ),
      createButtonType: (
        <CreateButton onClick={handleSaveClick} disabled={disableButton}>
          {disableButton && <Spin indicator={antIcon} size={"small"} />}
          Save
        </CreateButton>
      ),
    },
  };

  useEffect(() => {
    if (props.flag === "Create") {
      setPhrasesArr(getInitialPhrases());
    } else if (props.flag === "Update") {
      if (Object.keys(props.updateData).length > 0) {
        const phrasesList = [];
        props.updateData.phrases.map((terms, index) =>
          phrasesList.push({ id: `${index}`, text: terms })
        );
        setPreviousCategoryTitle(props.updateData.title);
        setCategoryTitle(props.updateData.title);
        setCategoryID(props.updateData.id);
        setPhrasesArr(phrasesList);
        setPreviousPhrasesArr(phrasesList);
      } else {
        setPhrasesArr(getInitialPhrases());
        setPreviousPhrasesArr(getInitialPhrases());
      }
    } else if (props.flag === "Merge") {
      setPreviousCategoryTitle(props.updateData.title);
      setCategoryTitle(props.updateData.title);
      setCategoryID(props.updateData.id);
      listAllCategories()
        .then((res) => {
          let optionArr = [];
          res.map(
            (item, index) =>
              props.updateData.id !== item.id &&
              optionArr.push({
                id: index,
                categoryOptionValue: item.title,
                optionID: item.id,
              })
          );
          setOptionValues(optionArr);
        })
        .catch((err) => message.error(err.message));
    }
  }, []);

  return (
    <>
      <StyledModal
        visible={props.isModalVisible}
        width={680}
        closable={false}
        footer={null}
      >
        <ModalWrapper>
          <ModalHeaderWrapper>
            <ConversationModalHeading>{props.title} </ConversationModalHeading>
            <ConversationModalFieldContainer>
              <CategoryTitleField
                placeholder="Category title"
                style={{
                  padding: "10px 12px",
                  borderRadius: "0px",
                  height: "36px",
                }}
                value={categoryTitle}
                onChange={(e) => setCategoryTitle(e.target.value)}
                disabled={props.flag === "Merge" && true}
              />
            </ConversationModalFieldContainer>
          </ModalHeaderWrapper>
          <ModalBodyWrapper>
            <ModalBodyHeading>{props.modalBody}</ModalBodyHeading>

            <ModalBodyText>{props.modalBodyText}</ModalBodyText>
            <ModalPhrases>
              {props.flag !== "Merge" ? (
                phrasesArr.length > 0 &&
                phrasesArr.map((item, index) => (
                  <RemovableEntry
                    index={index}
                    id={item.id}
                    onRemove={handleRemoveEntry}
                    key={item.id}
                  >
                    <StylePhraseInputWrapper>
                      <StyledPhraseInput
                        name="phrases"
                        id={item.id}
                        defaultValue={item.text}
                        value={item.text}
                        placeholder={`Example Phrase ${index + 1}`}
                        onChange={(e) => {
                          e.persist();
                          handlePhraseChange(e);
                        }}
                      />
                    </StylePhraseInputWrapper>
                  </RemovableEntry>
                ))
              ) : (
                <div>
                  {categoryDropdownArr.length > 0 &&
                    categoryDropdownArr.map((item, index) => (
                      <RemovableEntry
                        index={index}
                        id={item.id}
                        onRemove={handleRemoveMergeEntries}
                        key={item.id}
                      >
                        <Select
                          style={{ width: "90%", marginBottom: "2px" }}
                          showSearch
                          onChange={(value) =>
                            handleCategoryDropdownChange(value, item.id)
                          }
                          placeholder="Choose category"
                          key={item.id}
                        >
                          {optionValues.length > 0 &&
                            optionValues.map((item) => (
                              <Option
                                value={item.categoryOptionValue}
                                key={item.id}
                              >
                                {item.categoryOptionValue}
                              </Option>
                            ))}
                        </Select>
                      </RemovableEntry>
                    ))}
                </div>
              )}
            </ModalPhrases>
            {buttonType[props.flag].addButtonType}
          </ModalBodyWrapper>

          <ModalFooter>
            <CancelButton
              onClick={() => props.handleCloseModal()}
              disabled={disableButton}
            >
              Cancel
            </CancelButton>
            {buttonType[props.flag].createButtonType}
          </ModalFooter>
        </ModalWrapper>
      </StyledModal>
    </>
  );
};

export default ConversationModal;
