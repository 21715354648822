import React, { useEffect, useState } from "react";
import { Input, Spin, Button, message } from "antd";
import { useSelector } from "react-redux";
import { PrimaryStyledButton } from "./../../../components/common/styled-components";
import SetupIcon from "../../utils/icon";
import { getPlayGroundScenarios } from "../../api/api";
import { 
        PlayGroundPopupWrapper, Wrapper, PlayGroundTestingFieldWrapper, PlayGroundTestingResultsWrapper, PlayGroundTriggeredScenariosWrapper,
        PlaygroundButtonContainer, PlayGroundDebuggerData, PlaygroundNoScenariosFoundWrapper, ShowSpinner, StyledModal
       } from './wrappers';
import IntentInnerPopup from "../IntentPopup/intent-popup";
import { getItemFromStore, setItemInStore } from "../../../utils";

const PlayGroundPopup = ({ onCancel }) => {
  const [phrase, setPhrase] = useState("");
  const [resData, setResData] = useState([]);
  const [debuggerData, setDebuggerData] = useState([]);
  const [show, setShow] = useState(false);
  const [showTempPhrase, setShowTempPhrase] = useState("");
  const [intentID, setintentID] = useState("");
  const [intentType, setintentType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDebugger, setShowDebugger] = useState(false);
  const isLevelAi = useSelector((state) => state.app_user.is_levelai);
  const handleChange = ({ currentTarget }) => {
    setPhrase(currentTarget.value);
  };
  const handleSendPhrase = () => {
    if (phrase == "") {
      return message.warning("Please enter the required field.") ;
    }
    setItemInStore("sentence", phrase);
    setShowTempPhrase(phrase);
    callPlaygroundApi(phrase);
  };

  const setErrorStates = () => {
    setResData([]);
    setDebuggerData([]);
    setPhrase("");
    setIsLoading(false);
  }

  const callPlaygroundApi = (phrase, flag) => {
    setIsLoading(true);
    getPlayGroundScenarios({
      sentence: phrase,
    }).then((data) => {
        if (Object.keys(data).length) {
          setResData(data.triggered_intents);
          setDebuggerData(data.debug);
          flag !== "showPhrase" && setPhrase("");
          setIsLoading(false);
        } else {
          setErrorStates();
        }
      })
      .catch((err) =>{
        setErrorStates();
      });
  };

  const openScenario = ({ id , intent_type}) => {
    setintentID(id);
    setintentType(intent_type);
    setShow(true);
  };

  const closeScenario = () => {
    setShow(false);
  };

  const manageDebugger = () => {
    showDebugger ?  setShowDebugger(false) : setShowDebugger(true);
  };

  useEffect(() => {
    if (getItemFromStore("sentence")) {
      //setPhrase(getItemFromStore("sentence"));
      setShowTempPhrase(getItemFromStore("sentence"));
      callPlaygroundApi(getItemFromStore("sentence"), "showPhrase");
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <PlayGroundPopupWrapper>
      <h1 className="playground-heading">
        Scenario Testing
        {isLevelAi && (
          <span onClick={manageDebugger} title="Debug" className="debugger-text">
            Debug
          </span>
        )}
      </h1>
      <PlayGroundTestingFieldWrapper>
        <div className="playground-testing-field-heading">
          <p className="playground-testing-field-heading-p">Test phrase</p>
        </div>
        <div className="playground-testing-field-button-container">
          <Wrapper>
            <Input
              name="phrase"
              placeholder="Type test phrase here..."
              onChange={handleChange}
              onKeyPress = {(event) => {
                event.key === "Enter" && handleSendPhrase();
              }}
              value={phrase}
              style={{ padding: "8px 5px", borderRadius: "0" }}
              allowClear
            />
            <PrimaryStyledButton
              onClick={handleSendPhrase}
              className="right"
              style={{ margin: "0px 8px" }}
            >
              Send test phrase
            </PrimaryStyledButton>
          </Wrapper>
          <div className="playground-display-phrase">
            {showTempPhrase !== "" && (
              <div className="playground-display-phrase-div">
                <span title={showTempPhrase}>{showTempPhrase}</span>
              </div>
            )}
          </div>
        </div>
      </PlayGroundTestingFieldWrapper>
      <PlayGroundTestingResultsWrapper>
        <ShowSpinner show={isLoading}>
          <Spin />
        </ShowSpinner>
        {resData.length !== 0 && isLoading == false
          ? resData.map((item, idx) => (
              <PlayGroundTriggeredScenariosWrapper key={idx}>
                <h2>Phrase & triggered scenario</h2>
                <p dangerouslySetInnerHTML={{ __html: item.context_sent }}></p>
                <div className="triggered-scenarios-container">
                  <SetupIcon icon="intent" />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => openScenario(item.top_intent)}
                    value={item.top_intent.id}
                  >
                    {item.top_intent.description}
                  </span>
                </div>
              </PlayGroundTriggeredScenariosWrapper>
            ))
          : isLoading === false && (
              <PlaygroundNoScenariosFoundWrapper>
                {getItemFromStore("sentence")
                  ? "No Scenario Detected"
                  : "Type a phrase above to see results"}
              </PlaygroundNoScenariosFoundWrapper>
            )}
      </PlayGroundTestingResultsWrapper>

      <PlaygroundButtonContainer>
        <Button
          className="closeButton"
          onClick={onCancel}
        >
          Close
        </Button>
      </PlaygroundButtonContainer>

      {show && <StyledModal visible={show} footer={null} closable={true}>
        <IntentInnerPopup
          isHistorical={true}
          id={intentID}
          viewOnly={true}
          intentType={intentType?.toLowerCase()}
          onCancel={closeScenario}
        />
      </StyledModal>}

      <StyledModal
        visible={showDebugger}
        onCancel={manageDebugger}
        closable={true}
        okButtonProps={{ style: { display: "none" } }}
      >
        <PlayGroundDebuggerData>
          {debuggerData.length !== 0 ? (
            debuggerData.map((item, idx) => (
              <div
                className="debugger-data-wrapper"
                key={idx}
              >
                <div>
                  <pre>{JSON.stringify(item, null, 2)}</pre>
                </div>
              </div>
            ))
          ) : (
            <div className="empty-debugger-data-wrapper">
              No Debugger data present
            </div>
          )}
        </PlayGroundDebuggerData>
      </StyledModal>
    </PlayGroundPopupWrapper>
  );
};

export default PlayGroundPopup;
