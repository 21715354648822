import styled from "@emotion/styled";

export const CustomInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  min-width: 250px;
  cursor: auto;
`;

export const InputTypesWrapper = styled.div`
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .icon {
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: purple;
    display: grid;
    place-content: center;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: ${({ error }) => (error ? "1rem" : "0.5rem")};
  & label {
    margin-right: 0.5rem;
  }
  & input {
    border: ${({ error }) => error && "1px solid red"};
  }
  & .error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
    color: red;
  }
`;
