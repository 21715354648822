import produce from "immer";
import {
  calculateUpdatedTarget,
  getChildrenAtVariableFromNode,
  getKeysOfVariable,
} from "./utils";

export const createNodeSlice = (set, get) => ({
  nextNodeId: 0,
  selectedNode: null,
  getId: () => {
    const nextNodeId = get().nextNodeId;
    set(
      produce((state) => {
        state.nextNodeId += 1;
      })
    );
    return `dndnode_${nextNodeId}`;
  },
  selectNode: (nodeId) => {
    const findNodeById = get().findNodeById;
    let node = findNodeById(nodeId);
    set(
      produce((state) => {
        state.selectedNode = node;
      })
    );
  },
  extarctKeysOfVariableFromNode: (nodeId, variable) => {
    const findNodeById = get().findNodeById;
    let node = findNodeById(nodeId);

    const obj = node.data.generatedPayload[variable];
    return getKeysOfVariable(obj);
  },
  generateCustomPayload: (
    sourceId,
    name,
    variableName,
    nodeId,
    object_keys
  ) => {
    let generatedPayload = {};
    const updateGeneratedPayload = get().updateGeneratedPayload;
    const findNodeById = get().findNodeById;
    const updateNode = get().updateNode;
    let node = findNodeById(nodeId);
    let sourceNode = findNodeById(sourceId);
    generatedPayload[name] = {};
    if (Array.isArray(object_keys)) {
      object_keys.forEach((key) => {
        generatedPayload[name] = {
          ...generatedPayload[name],
          ...getChildrenAtVariableFromNode(node, variableName, key, name),
        };
      });
    } else {
      generatedPayload[name] = getChildrenAtVariableFromNode(
        node,
        variableName,
        object_keys,
        name
      );
    }
    updateGeneratedPayload(sourceId, generatedPayload);
    updateNode(null, sourceId, {
      kwargs: { ...sourceNode.kwargs, name },
    });
    return generatedPayload;
  },
});
