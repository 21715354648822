import React, { Component, useEffect, useState } from "react";
import { SetupWrapper, LineSeparator } from "./../commons/wrappers";
import { PrimaryStyledButton } from "./../../../components/common/styled-components";
import SetupHeader from "../commons/SetupHeader";
import PopUp from "./popup";
import RubricPopup from "./rubric-popup";
import { Rubric } from "./components/rubric";
import { Button, Spin, message, Table } from "antd";
import styled from "@emotion/styled";
import {
  createRubric,
  listRubrics,
  updateRubricItem,
  updateChannel,
  getRubric,
  updateRubric,
  deleteRubric,
} from "./api";
import { Link } from "react-router-dom";
import ActionMenu from "../commons/ActionMenu";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import ConfirmPopup from "../../components/commons/ConfirmPopup";
import { addAnalyticsEvent } from "../../../utils";
import { HeapAnalyticsEvent } from "../../../components/common/HeapAnalyticsEvent";
import { agentTypeOptions, errorTypes } from "./config";
import CustomFilterComponent from "../../../components/common/CustomFilterComponent/CustomFilterComponent";
import ErrorStates from "./components/ErrorStates";
import {
  RubricHeaderWrapper,
  RubricDetailsWrapper,
  TableWrapper,
  RubricChannelsWrapper,
  ActionButtonsWrapper,
} from "./wrappers";
import { connect } from "react-redux";
import { getAgentGroupOptions } from "../../api/api";
import appConfigs from '../../../config';

const RubricChannel = ({
  title,
  isLive,
  rubricId,
  item,
  onChange,
  onError,
  startLoading,
  endLoading,
}) => {
  const [_isLive, setIsLive] = useState(isLive);

  useEffect(() => {
    setIsLive(isLive);
  }, [isLive]);

  return (
    <Button
      className={`d-flex align-items-center justify-content-center ${
        _isLive ? `isLive` : ""
      }`}
      onClick={() => {
        startLoading();
        let apiFunction = appConfigs.ENABLE_RUBRIC_AGENT_TYPE ? updateRubricItem : updateChannel
        apiFunction(rubricId, {
          [item]: !_isLive,
        })
          .then(() => {
            setIsLive(!_isLive);
            onChange();
          })
          .catch((err) => {
            endLoading();
            if (
              err?.response?.data?.message &&
              err.response.data.message === "cannot_set_rubric" && appConfigs.ENABLE_RUBRIC_AGENT_TYPE
            ) {
              onError(err.response.data);
            }
          });
      }}
    >
      {title}
    </Button>
  );
};

export class RubricBuilder extends Component {
  state = {
    isCreating: false,
    isLoading: true,
    isButtonLoading: false,
    rubrics: [],
    agentTypeOptions: [],
    errorState: {
      show: false,
      data: {},
      type: "",
    },
  };
  handleContinue = (rubricOverview) => {
    this.setState({
      ...rubricOverview,
      isCreating: true,
    });
  };

  handleOpenDetails = (rubricId) => {
    getRubric(rubricId).then((res) => {
      this.setState({
        ...res.data,
        isCreating: true,
        isEditing: true,
      });
    });
  };

  handleDelete = (id) => {
    this.setState({
      isLoading: true
    });
    deleteRubric(id).then((res) => {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.getData();
        }
      );
    }).catch(err => {
      this.setState({
        isLoading: false
      });
    });
  };

  validationError = (errorDict) => {
    let error = [];
    for (let key in errorDict) {
      if (Array.isArray(errorDict[key])) {
        if (errorDict[key].every((x) => typeof x === "string")) {
          errorDict[key].forEach((err) => {
            error.push(`${key}: ${err}`);
          });
        } else {
          error.push(...this.validationError(errorDict[key]));
        }
      } else {
        error.push(...this.validationError(errorDict[key]));
      }
    }
    return error;
  };

  validate = (categories) => {

    return new Promise((resolve, reject) => {
      let errors = [];
      categories.forEach((category, k) => {
        if (category.sections.length === 0) {
          errors.push("One of the categories is empty");
        }
        category.sections.forEach((section, i) => {
          if (section.questions.length === 0) {
            errors.push(
              `Add questions to the section: ${i + 1} of category: ${
                category.title
              }`
            );
          }
          if (!section.title) {
            errors.push(
              `Add title to the section: ${i + 1} of category: ${
                category.title
              }`
            );
          }
          section.questions.forEach((question, j) => {
            if (question.options.length === 0) {
              errors.push(
                `Add options to the question: ${j + 1} in section: ${
                  i + 1
                } of category: ${category.title}`
              );
            }
          });
        });
      });
      errors.length !== 0 ? reject(errors.join(", ")) : resolve();
    });
  };

  handleSubmit = () => {
    if (this.state.isEditing) {
      updateRubric(this.state.id, this.state)
        .then(() => {
          this.setState(
            {
              isLoading: true,
              isEditing: false,
              isCreating: false,
            },
            () => {
              this.getData();
            }
          );
        })
        .catch((err) => {
          this.validationError(err.response.data).forEach((error) => {
            message.error(error);
          });
        })
        .finally(() => {
          this.setState({ isButtonLoading: false });
        });
      return;
    }

    createRubric(this.state)
      .then((res) => {
        addAnalyticsEvent(HeapAnalyticsEvent.Detect_QA_Rubrics_Built, {});
        this.setState(
          {
            isCreating: false,
            isLoading: true,
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.validationError(err.response.data).forEach((error) => {
          message.error(error);
        });
      })
      .finally(() => {
        this.setState({ isButtonLoading: false });
      });
  };

  handleCancel = () => {
    this.reset();
  };

  // constructor(props) {
  //     super(props);
  // }

  getData = () => {
    listRubrics()
      .then((res) => {
        this.setState({
          isLoading: false,
          rubrics: res.data.results,
        });
      })
      .catch(() => {
        this.setState({
          rubrics: [],
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  _getAgentTypeOptions = () => {
    this.props
      ._getAgentTypeOptions()
      .then((res) => {
        if (res?.agent_type?.length) {
          return this.setState({
            agentTypeOptions: [
              ...res.agent_type?.filter(option => option)?.map((option) => {
                return {
                  value: option,
                  label: option?.charAt(0)?.toUpperCase() + option?.slice(1),
                };
              }),
              {
                value: "unassigned",
                label: "Unspecified",
              },
            ],
          });
        }
        return this.setState({
          agentTypeOptions: [
            {
              value: "unassigned",
              label: "Unspecified",
            },
          ],
        });
      })
      .catch((err) => {
        this.setState({ agentTypeOptions: [] });
      });
  };

  componentDidMount() {
    this.getData();
    this._getAgentTypeOptions();
  }

  reset = () => {
    this.setState(
      {
        isEditing: false,
        isCreating: false,
        isLoading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  componentDidUpdate() {}

  reload = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  onChangeAgentType = (val, record) => {
    this.setState({ isLoading: true });
    updateRubricItem(record.id, {
      agent_type: val || [],
    })
      .then(() => {
        this.reload();
      })
      .catch((err) => {
        if (
          err?.response?.data?.message &&
          err.response.data.message === "cannot_set_rubric"
        ) {
          this.setState({
            errorState: {
              show: true,
              data: {
                ...err.response.data,
              },
              type: errorTypes.AGENT_TYPE_ERROR,
            },
            isLoading: false,
          });
        }
      })
      .finally(() => {});
  };

  onRubricChannelError = (data) => {
    this.setState({
      errorState: {
        show: true,
        data: {
          ...data,
        },
        type: errorTypes.CHANNEL_ERROR,
        isLoading: false,
      },
    });
  };

  onCloseErrorState = () => {
    this.setState({
      errorState: {
        show: false,
        data: {},
        type: "",
      },
    });
  };

  render() {
    return (
      <SetupWrapper>
        <RubricHeaderWrapper>
          <SetupHeader
            title={this.state.isCreating ? this.state.title : "Rubric Builder"}
            icon="rubric"
            description={
              this.state.isCreating
                ? this.state.description
                : "Design and deseminate your own custom grading rubric."
            }
          />
          {!this.state.isCreating ? (
            <PopUp
              isOpen={false}
              trigger={PrimaryStyledButton}
              triggerProps={{
                title: "+ Add Rubric",
              }}
              component={RubricPopup}
              componentProps={{
                width: 900,
              }}
              primaryBtnName={"Submit"}
              secondaryBtnName="Cancel"
              onCancel={() => {
                // this.setState(
                //   {
                //     isEditing: false,
                //     isCreating: false,
                //   })
              }}
              onSubmit={this.handleContinue}
              onSubmitValidated={true}
            />
          ) : (
            <ActionButtonsWrapper>
              <PrimaryStyledButton disabled = {this.state.isButtonLoading} onClick={this.reset}>
                Cancel
              </PrimaryStyledButton>
              <PopUp
                isOpen={false}
                trigger={PrimaryStyledButton}
                triggerProps={{
                  title: "Settings",
                }}
                component={RubricPopup}
                componentProps={{
                  width: 900,
                  ...this.state,
                }}
                primaryBtnName={"Submit"}
                isButtonDisabled = {this.state.isButtonLoading}
                secondaryBtnName="Cancel"
                onCancel={() => {
                  //this.reset()
                }}
                onSubmit={this.handleContinue}
                onSubmitValidated={true}
              />

              <Button
                loading = {this.state.isButtonLoading}
                onClick={() => {
                  this.validate(this.state.categories)
                    .then(() => {
                      this.setState({ isButtonLoading: true });
                      this.handleSubmit();
                    })
                    .catch((err) => {
                      message.error(err);
                      this.setState({ isButtonLoading: false });
                    });
                }}
                type="primary"
              >
                Save Changes
              </Button>
            </ActionButtonsWrapper>
          )}
        </RubricHeaderWrapper>

        {this.state.isCreating ? (
          <Rubric
            {...this.state}
            onChange={(e) => {
              this.setState({
                ...this.state,
                [e.key]: e.value,
              });
            }}
          />
        ) : null}

        <br />
        <LineSeparator />

        {!this.state.isCreating && (
          <RubricDetailsWrapper>
            <TableWrapper
              loading={this.state.isLoading}
              rowKey="id"
              scroll={{ y: "calc(100vh - 250px)" }}
              title={""}
              pagination={false}
              dataSource={this.state.rubrics?.length ? this.state.rubrics : []}
              columns={[
                {
                  title: "Rubric name",
                  dataIndex: "title",
                  key: "title",
                  width: appConfigs.ENABLE_RUBRIC_AGENT_TYPE ? "70%" : "100%",
                  render: (value, record) => (
                    <Link
                      className="title"
                      onClick={() => {
                        this.handleOpenDetails(record.id);
                      }}
                    >
                      {record.title}
                    </Link>
                  ),
                },
                ...(appConfigs.ENABLE_RUBRIC_AGENT_TYPE ?  [{
                  title: "Agent type",
                  dataIndex: "agent_type",
                  key: "agent_type",
                  width: "50%",
                  render: (value, record) => (
                    <CustomFilterComponent
                      //dropdownStyle = {{ position: "absolute" }}
                      disabled={false}
                      type={"local"}
                      showFooter={false}
                      maxTagCount={3}
                      options={
                        this.state.agentTypeOptions?.length
                          ? this.state.agentTypeOptions
                          : []
                      }
                      placeholder="Select agent type"
                      multiSelect={true}
                      style={{ width: "85%" }}
                      value={record.agent_type}
                      handleChange={(val) => {
                        this.onChangeAgentType(val, { ...record });
                      }}
                    />
                  ),
                }] : []),
                {
                  title: "Live channels",
                  dataIndex: "channel",
                  key: "channel",
                  width: appConfigs.ENABLE_RUBRIC_AGENT_TYPE ? "40%" : "27%",
                  render: (value, record) => (
                    <RubricChannelsWrapper>
                      <RubricChannel
                        item={"is_call"}
                        isLive={record.is_call}
                        title={"Call"}
                        rubricId={record.id}
                        startLoading={() => {
                          this.setState({ isLoading: true });
                        }}
                        endLoading={() => {
                          this.setState({ isLoading: false });
                        }}
                        onError={this.onRubricChannelError}
                        onChange={this.reload}
                      ></RubricChannel>
                      <RubricChannel
                        item={"is_chat"}
                        isLive={record.is_chat}
                        title={"Chat"}
                        rubricId={record.id}
                        startLoading={() => {
                          this.setState({ isLoading: true });
                        }}
                        endLoading={() => {
                          this.setState({ isLoading: false });
                        }}
                        onError={this.onRubricChannelError}
                        onChange={this.reload}
                      ></RubricChannel>
                      <RubricChannel
                        item={"is_email"}
                        isLive={record.is_email}
                        title={"Email"}
                        rubricId={record.id}
                        startLoading={() => {
                          this.setState({ isLoading: true });
                        }}
                        endLoading={() => {
                          this.setState({ isLoading: false });
                        }}
                        onError={this.onRubricChannelError}
                        onChange={this.reload}
                      ></RubricChannel>
                    </RubricChannelsWrapper>
                  ),
                },
                {
                  title: "",
                  dataIndex: "id",
                  key: "id",
                  align: "center",
                  width: "10%",
                  render: (value, record) => (
                    <ActionMenu
                      menuItems={[
                        {
                          title: "Edit",
                          key: "edit",
                          icon: EditIcon,
                        },
                        {
                          title: "Delete",
                          key: "delete",
                          icon: DeleteIcon,
                          hoverColor: "#ff4d4f",
                          isPopConfirm: true,
                        },
                      ]}
                      handleMenuClick={(key) => {
                        if (key === "edit") {
                          this.handleOpenDetails(record.id);
                        } else if (key === "delete") {
                          this.setState({
                            rubricToDelete: record,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]}
            />
          </RubricDetailsWrapper>
        )}
        <ConfirmPopup
          show={this.state.rubricToDelete}
          title={`Delete Rubric`}
          message={`Are you sure you would like to permantly delete ${this.state.rubricToDelete?.title} ?`}
          onClose={(done) => {
            this.setState({ rubricToDelete: false });
            if (done) {
              this.handleDelete(this.state.rubricToDelete?.id);
            }
          }}
        />
        {
          appConfigs.ENABLE_RUBRIC_AGENT_TYPE ? 
          <ErrorStates
          errorState={this.state.errorState}
          onCloseErrorState={this.onCloseErrorState}
          /> : null
        }
      </SetupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ app_user: state.app_user });

const mapDispatchToProps = (dispatch) => ({
  _getAgentTypeOptions: () => {
    return dispatch(getAgentGroupOptions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RubricBuilder);
