import React from 'react';
import ZendeskIcon from "../../assets/zendesk-icon.png";
import SftpIcon from "../../../assets/sftp_icon.svg";

export const onDataSourceChange = (e, dataSourceOptions, setDataSource, setSourceField, setSourceFieldOptions) => {
  const value = e.target.value;
  setDataSource(value);
  setSourceField("");
  setSourceFieldOptions(dataSourceOptions[value]);
};

export const onEnableFiltersChange = (e, fn) => {
  const value = e.target.value;
  fn(value);
};

export const onDisplayNameChange = (e, fn) => {
  const value = e.target.value;
  fn(value);
};

export const onSourceFieldChange = (val, fn) => {
  fn(val);
};

export const getIcon_Title = (source = "") => {
    if(source?.toLowerCase().includes("salesforce")) {
      return <><img width="32" height="32" alt="" src={"https://res.cloudinary.com/dxirmkc1j/image/upload/v1595589332/salesforce_zdtxnk.svg"} /><span>Salesforce</span></>;
    }
    else if(source?.toLowerCase().includes("zendesk")) {
      return <><img width="20" height="20" alt="" src={ZendeskIcon} style = {{ marginRight: "5px" }} /><span>Zendesk</span></>;
    }
    else if(source?.toLowerCase().includes("sftp")) {
      return <><img width="35" height="35" alt="" src={SftpIcon} /><span>SFTP</span></>;
    }
    else {
      return <img width="25%" alt="" src={""} />;
    }
}
