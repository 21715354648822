import produce from "immer";
import { addEdge, isNode } from "react-flow-renderer";
import { calculateUpdatedTarget, updateIfConditionOnTarget } from "./utils";

export const createDndEventSlice = (set, get) => ({
  onConnect: (params) => {
    const findNodeById = get().findNodeById;
    const elements = get().elements;
    const updateNode = get().updateNode;
    let source = findNodeById(params?.source);
    let target = findNodeById(params?.target);

    let edgeLabel = "";
    let labelStyle = { fill: "red", fontWeight: "700" };
    if (source.type === "condition" && params.sourceHandle === "left") {
      edgeLabel = "YES";
      labelStyle.fill = "green";
    } else if (source.type === "condition" && params.sourceHandle === "right") {
      edgeLabel = "NO";
    }
    set(
      produce((state) => {
        state.elements = addEdge(
          {
            ...params,
            type: "smoothstep",
            animated: true,
            label: edgeLabel,
            labelStyle: labelStyle,
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
          },
          elements
        );
      })
    );

    let updatedTarget = {};

    if (source.data.type === "condition") {
      updatedTarget = updateIfConditionOnTarget(
        params.sourceHandle,
        source,
        target
      );
    } else {
      updatedTarget = calculateUpdatedTarget(source, target);
    }
    updateNode(updatedTarget, target.id);
  },
  onDrop: (event, reactFlowWrapper) => {
    event.preventDefault();
    const reactFlowInstance = get().reactFlowInstance;
    const elements = get().elements;
    let id = get().getId();

    if (reactFlowInstance) {
      let nodeData = event.dataTransfer.getData("application/reactflow");
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      nodeData = JSON.parse(nodeData);
      let name = id;
      if (nodeData.functionName === "variableDeclaration") {
        name = "New Variable";
        if (elements?.length) {
          let existingEle = elements.filter(
            (item) => isNode(item) && item.kwargs.name.includes(name)
          );
          let num = existingEle?.length ? existingEle.length : "";
          if (num) {
            name = name + "(" + num + ")";
            while (
              elements.find((item) => isNode(item) && item.kwargs.name === name)
            ) {
              num++;
              name = name + "(" + num + ")";
            }
          }
        }
      }
      const newNode = {
        id,
        type: nodeData.type,
        position,
        data: {
          id,
          ...nodeData,
          globalVariables: [],
          variableFromLastFunc: [],
        },
        kwargs: {
          name,
        },
      };
      set(
        produce((state) => {
          state.elements.push(newNode);
        })
      );
    }
  },
});
