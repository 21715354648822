import { StyledTimeline } from "../../components/common/styled-components";
//import PendingItem from "../../bankingAssist/components/PendingItem";
import React from "react";
import TimelineItem from "../TimelineItem";
import { ReactComponent as OpenCardsIcon } from "../../assets/open-cards.svg";
import { ReactComponent as CloseCardsIcon } from "../../assets/close-cards.svg";
import styled from "@emotion/styled/macro";

const GroupResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    .similar-cards {
        color: #6099D9;
        font-size: 12px;
        line-height: 14px;
        width: 100%;
        justify-content: flex-start;
        margin-top: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        left: -12px;    
    }
`;

export const GroupedAssists = ({ cards, isLiveConversation, isDebug, isAgent, updateUserFeedback, conversationId, channel }) => {
    const [showAll, toggleShowAll] = React.useState(false);
    const handleClick = () => {
        toggleShowAll(!showAll);
    }
    const count = showAll ? cards.length : 1;
    return (
        <GroupResultsWrapper>
            {cards.slice(0, count).map((item, index) => (
                <TimelineItem 
                    {...item} 
                    isLiveConversation={isLiveConversation} 
                    key={index} 
                    isDebug={isDebug} 
                    isAgent={isAgent}
                    updateUserFeedback={updateUserFeedback}
                    conversationId={conversationId}        
                    channel={channel}                    
                />
            ))}
            <div className="similar-cards" onClick={handleClick}>
                {showAll ?  <CloseCardsIcon /> : <OpenCardsIcon />}
                {showAll ? "Less Results" : "Similar Results"}
            </div>
        </GroupResultsWrapper>
    )
}

const AssistTimeline = ({ data, hide, isAgent, isLiveConversation, isDebug, updateUserFeedback, conversationId, groupResults }) => {
    return (
        <StyledTimeline hide={hide} isAgent={isAgent || isLiveConversation}>
            {/* {isLiveConversation && <PendingItem isAgent={isAgent || isLiveConversation}/>} */}
            {data.map((cardGroup) => {            
                const { cards } = cardGroup;
                const showGroup = cards.length > 1 && groupResults;
                if(showGroup) {
                    return (
                        <GroupedAssists 
                            cards={cards} 
                            isLiveConversation={isLiveConversation} 
                            isDebug={isDebug} 
                            isAgent={isAgent}
                            updateUserFeedback={updateUserFeedback}
                            conversationId={conversationId}
                        />
                    )
                }
                return cards.map((item, index) => (
                    <TimelineItem 
                        {...item} 
                        isLiveConversation={isLiveConversation} 
                        key={index} 
                        isDebug={isDebug} 
                        isAgent={isAgent}
                        updateUserFeedback={updateUserFeedback}
                        conversationId={conversationId}                            
                    />
                ))
            })}
        </StyledTimeline>
    )
}

export default AssistTimeline;