import React, { useCallback } from "react";
import styled from "@emotion/styled/macro";
import { Checkbox, Input } from 'antd';
import { debounce } from "lodash";

const ActionsPropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;   
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 12px;
        text-align: left;
    }
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 10px;
    }
    margin-top: 20px;
    &:first-of-type {
        margin-top: 0;
    }
`;

const StylePhraseInputWrapper = styled.div`
    width: 100%;
    position: relative;
    .error-message {
        color: #DB554D;;
        font-size: 12px;    
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
    }
`;

const StyledPhraseInput = styled(Input)`
    width: 100%;
    padding: 4px 10px;
    border: 1px solid;
    border-color: #BDBDBD;
    position: relative;    
`;

const CustomMessage = ({onChange, message, showValidations }) => {
    const debounceHandleChange = useCallback(debounce(onChange, 800), []);
    const handleChange = (event) => {
        const text = event.currentTarget.value;
        debounceHandleChange(text);
    }
    return (
        <StylePhraseInputWrapper>
            <StyledPhraseInput 
                name="message" 
                placeholder="Custom Message"
                onChange={handleChange} 
                defaultValue={message}                    
            />                     
            {showValidations && <div className="error-message">This field is required</div>}
        </StylePhraseInputWrapper>
    )
}

export default class ActionsProperty extends React.Component {
    constructor(props) {
        super(props);
        const actionMessages = {};
        const actions = props.ruleProperties.actions ? props.ruleProperties.actions.map((item) => {
            actionMessages[item.category] = item.value;
            return item.category;
        }) : [];
        this.state = {
            actions: actions,
            actionMessages: actionMessages,
            showValidations: props.showValidations,
            invalidPropsList: [],
        }
    }
    componentDidUpdate(prevProps) {
        if(this.props.showValidations !== prevProps.showValidations) {
            this.setState({ showValidations: this.props.showValidations });
        }
    }
    onPhraseChange = (action, value) => {
        const { actionMessages } = this.state;
        const updatedActionMessages = {
            ...actionMessages,
            [action]: value
        };
        this.setState({
            actionMessages: updatedActionMessages
        }, this.updateProperty);
    }
    updateProperty = () => {
        const { actionMessages, actions } = this.state;
        let label = "No Hint";
        let invalid = false;
        let invalidPropsList = [];
        let propertyActions = [];
        for(let i=0; i<actions.length; i++) {
            const actionMessage = actionMessages[actions[i]];
            propertyActions.push({
                category: actions[i],
                value: actionMessage,
                after: 1,
            });            
            if(!actionMessage) {
                invalid = true;
                invalidPropsList.push(actions[i]);
            }            
        }
        if(actions.includes("flag") && actions.includes("hint")) {
            label = "Hint & Flag"
        }
        else if(actions.includes("hint")) {
            label = "Hint";            
        }
        else if(actions.includes("flag")) {
            label = "Flag";            
        }
        this.props.handleChange({
            label: label,
            actions: propertyActions,
            invalid,
            invalidPropsList,
        });
        this.setState({ invalidPropsList });
    }
    handleChange = (event) => {
        const { actions } = this.state;
        const value = event.target.name;
        let updatedActions = [...actions];
        if(updatedActions.includes(value)) {
            updatedActions.splice(updatedActions.indexOf(value), 1);
        }
        else {
            updatedActions.push(value);
        }
        this.setState({
            actions: updatedActions
        }, this.updateProperty);
    }
    render() {
        const { actions, actionMessages, showValidations, invalidPropsList } = this.state;
        return (
            <ActionsPropertyWrapper>
                {this.props.options.map((item, index) => {
                    const isSelected = actions.includes(item.value);
                    const isInvalid = showValidations && invalidPropsList.includes(item.value);
                    if(item.showOnly && !item.showOnly(this.props.ruleProperties)) {
                        return null;
                    }
                    return (
                        <CheckboxWrapper key={index}>
                            <Checkbox
                                checked={isSelected}
                                onChange={this.handleChange}
                                name={item.value}                            
                            >
                                {item.label}
                            </Checkbox>
                            <CustomMessage  
                                onChange={(value) => {
                                    this.onPhraseChange(item.value, value);
                                }}
                                message={actionMessages[item.value]}       
                                showValidations={isInvalid}                         
                            />
                        </CheckboxWrapper>
                    )
                })}
                {showValidations && !actions.length && <div className="error-message">Please select action(s)</div>}
            </ActionsPropertyWrapper>
        )
    }
}