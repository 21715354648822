/**
 * @param {object} data - GraphQL queried data  
 */

export function buildSectionScoreReport(data) {
    const { rows } = data;
    let agents = {};

    rows.forEach((row, index) => {
        if(agents[row.agent_id]) {
            if(agents[row.agent_id][row.section]) {
                agents[row.agent_id][row.section]['cummulative_score'] += parseFloat(row.section_score);
                agents[row.agent_id][row.section]['count'] += 1
            } else {
                agents[row.agent_id][row.section] = {
                    cummulative_score: parseFloat(row.section_score),
                    count: 1
                } 
            }
        } else {
            agents[row.agent_id] = {
                name: `${row.asrlog.accounts_user.first_name} ${row.asrlog.accounts_user.last_name}`,
                email: row.asrlog.accounts_user.email,
                [row.section]: {
                    cummulative_score: parseFloat(row.section_score),
                    count: 1
                }
            }
        }
    });
    
    return processAverageAndPercentage(agents);
}

/**
 * @param {object} data - cummulative user processed data as follows
 * {
 *  agent: {
 *   section_a: {
 *            cummulative_score: number,
 *            count: number,
 *            average: number,
 *            percentage: number
 *      }
 *   }
 * }
 */

function processAverageAndPercentage(map_data) {
    let headersCol = [];

    for (const key in map_data) {
        for(const section of Object.keys(map_data[key])) {
            if(!headersCol.includes(section) && !(section === "name" || section === "email")) {
                headersCol.push(section);
            }

            if(!(section === "name" || section === "email")) {
                map_data[key][section]['average'] = map_data[key][section]['count'] ? (map_data[key][section]['cummulative_score'] / map_data[key][section]['count']) : 0;
            }
        }
    }

    return buildCSV(headersCol, map_data);
}

function buildCSV(header, data) {
    // SECTION HEADER ROW
    let headerRow = "Agent,Email,";
    header.forEach((headerCol) => {
        if(headerCol !== "name" && headerCol !== "email") {
            headerRow += headerCol + ',';
        }
    });
    headerRow += '\r\n';

    let csv = headerRow;

    // Data Processing
    Object.keys(data).forEach((key, index) => {
        csv += data[key]['name'] + ',' + data[key]['email'] + ',';

        header.forEach(section => {
            csv += data[key][section]['average'] + ',';
        });

        csv += '\r\n';
    });


    return [csv, Object.keys(data).length];
};