import { message, Spin } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { parse, AsyncParser } from "json2csv";
import { DownloadOutlined } from "@ant-design/icons";
import WarningModal from "./WarningModal";
import { useStateWithCallbackLazy } from "../../custom-hooks/useStateWithCallback";
import { addAnalyticsEvent } from "../../utils";
import { StyledButton } from "./styles";
// import ProgressNotifier from "./ProgressNotifier";

const CSVDownloadButton = ({
  alreadyParsed = false,
  disabled = false,
  dataFunc,
  paginatedDataFun,
  text = "Export",
  filename = "data",
  limit = 5000,
  maxLimit = 10000,
  getTotalCount,
  columns,
  rowName = "rows",
  extraRow,
  heapEvent,
  chartButton,
}) => {
  const [loading, setLoading] = useStateWithCallbackLazy(false);
  const [warning, setWarning] = useState(false);
  const totalRowsExported = useRef(0);

  useEffect(() => {
    if (dataFunc && paginatedDataFun) {
      throw new Error("Data and paginatedDataFun are not allowed together");
    }
    if (!getTotalCount || typeof getTotalCount !== "function") {
      throw new Error("getTotalCount is a required function");
    }
  }, [dataFunc, paginatedDataFun]);

  const checkLimit = (e) => {
    e.stopPropagation();
    addAnalyticsEvent(heapEvent + "_INITIATED", {});
    if (getTotalCount() > maxLimit) {
      setWarning(true);
    } else {
      downloadCsv();
    }
  };
  const downloadCsv = () => {
    if (loading) {
      return;
    }
    setLoading(true, () => {
      message.info("Preparing export");
      if (dataFunc) {
        dataFunc().then((data) => {
          if(alreadyParsed) {
            totalRowsExported.current = data[1];
            if(totalRowsExported.current) {
              downloadFile(data[0]);
            } else {
              message.warn("No data to export");
              setLoading(false);
            }
          }
          else if (data && data[0]) {
            const opts = Object.keys(data[0]).map((key) => key);
            totalRowsExported.current = data.length;
            downloadFile(parse(data, opts));
          }
        }).catch(error => {
          console.log(error);
          setLoading(false);
          message.error("Error while fetching data");
        })
      } else {
        let fields = columns
          .filter((col) => (col.checked === undefined ? true : col.checked))
          .map((col) => col.name);
        if (extraRow) {
          fields.push(extraRow.name);
        }
        const opts = { fields };
        noFieldsWarn(fields);
        downloadDataAsync(opts);
      }
    });
  };
  const noFieldsWarn = (fields) => {
    if (!fields || fields.length === 0) {
      message.warn("No columns to export, Please enable a column!");
      setLoading(false);
      return;
    }
  };
  const formatData = (data) => {
    let formatedData = data.map((item) => {
      if (!item) {
        return null;
      }
      let formatedItem = {};
      columns.forEach((col) => {
        let add = col.checked === undefined ? true : col.checked;
        if (add) {
          formatedItem[col.name] = col.value(item);
        }
        if (extraRow) {
          formatedItem[extraRow.name] = extraRow.value(item);
        }
      });
      return formatedItem;
    });
    return formatedData;
  };

  const downloadDataAsync = (opts) => {
    const transformOpts = { highWaterMark: 8192 };

    const asyncParser = new AsyncParser(opts, transformOpts);

    let csv = "";
    asyncParser.processor
      .on("data", (chunk) => {
        return (csv += chunk.toString());
      })
      .on("end", () => {
        downloadFile(csv);
      })
      .on("error", (err) => console.error(err));
    // asyncParser.transform
    //   .on("header", (header) => console.log(header))
    //   .on("line", (line) => console.log(line))
    //   .on("error", (err) => console.log(err));
    const fetchPageData = (pageNumber = 1, limit) => {
      paginatedDataFun(pageNumber, limit)
        .then((data, formatRequired = true) => {
          if (data.length) {
            let formatedData = data;
            if (formatRequired) formatedData = formatData(data);
            totalRowsExported.current = totalRowsExported.current + data.length;
            asyncParser.input.push(JSON.stringify(formatedData));
            if (
              data.length === limit &&
              totalRowsExported.current + limit <= maxLimit
            ) {
              fetchPageData(pageNumber + 1, limit);
            } else {
              asyncParser.input.push(null);
            }
          } else {
            asyncParser.input.push(null);
          }
        })
        .catch((e) => {
          console.log("ERROR", e);
          message.error("Error while fetching data");
          asyncParser.input.push(null);
          addAnalyticsEvent(heapEvent + "_ERROR", {});
        });
    };
    fetchPageData(1, Math.min(limit, maxLimit));
  };

  const downloadFile = (csv) => {
    var blob = new Blob([csv], { type: "text/csv" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
        message.success(
          `${totalRowsExported.current.toLocaleString(
            "en-US"
          )} ${rowName} exported`
        );
        totalRowsExported.current = 0;
        addAnalyticsEvent(heapEvent + "_COMPLETED", {});
      }
    }
  };
  return (
    <div>
      <StyledButton
        onClick={checkLimit}
        disabled={loading || disabled || getTotalCount() === 0}
        chartButton={chartButton}
      >
        <WarningModal
          show={warning}
          onClose={() => {
            setWarning(false);
          }}
          downloadCsv={downloadCsv}
          maxLimit={maxLimit}
          rowName={rowName}
        />
        <DownloadOutlined style={{ marginTop: "2px" }} />
        {text}
        {loading ? <Spin className="ms-2" size="small" /> : ""}
      </StyledButton>
    </div>
  );
};

export default CSVDownloadButton;
