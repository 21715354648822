import React from "react";
import RemovableEntry from "../../../CompanySetup/components/RemovableEntry";
import AddButton from "../AddButton";
import {
  ModalEmailFields,
  StyledPhraseInput,
  StylePhraseInputWrapper,
} from "./styles";

const EmailFields = ({ emails, setEmails }) => {
  const handleEmailChange = (e) => {
    const text = e.target.value;
    const id = e.target.id;
    //debounceHandleChange({ id, emails }, text);
    let updatedEmails = emails.map((item) => {
      if (item.id === id) {
        item.text = text;
        return item;
      } else {
        return item;
      }
    });
    setEmails(updatedEmails);
  };

  const handleRemoveEntry = (propsRemove) => {
    let updatedEmails = emails.filter((item) => propsRemove.id !== item.id);
    setEmails(updatedEmails);
  };

  const handleAddEntry = () => {
    if (emails.length !== 0) {
      const id = parseInt(emails[emails.length - 1].id) + 1;
      setEmails([
        ...emails,
        {
          id: `${id}`,
          text: "",
        },
      ]);
    } else {
      setEmails([
        {
          id: `0`,
          text: "",
        },
      ]);
    }
  };

  return (
    <>
      <ModalEmailFields>
        {emails.length > 0 &&
          emails.map((item, index) => (
            <RemovableEntry
              index={index}
              id={item.id}
              onRemove={handleRemoveEntry}
              key={item.id}
            >
              <StylePhraseInputWrapper>
                <StyledPhraseInput
                  name="email"
                  type="email"
                  key={item.id}
                  id={item.id}
                  defaultValue={item.text}
                  value={item.text}
                  placeholder={`Enter Email Address ${index + 1}`}
                  onChange={(e) => {
                    e.persist();
                    handleEmailChange(e);
                  }}
                />
              </StylePhraseInputWrapper>
            </RemovableEntry>
          ))}
      </ModalEmailFields>
      <AddButton className="add-btn" onClick={handleAddEntry}>
        {"Add Email"}
      </AddButton>
    </>
  );
};

export default EmailFields;
