import { createReducer } from "../util";
import {
  ReduxActionTypes  
} from "./../../constants/ReduxActionConstants";

const userDetails = {}

const initialState = {
  first_name: "",
  last_name: "",
  groups: [],
  id: "",
  email: "",
  channel: "",
  token: "",
  is_active: undefined,
  is_superuser: undefined,
  is_agent: undefined,
  is_qa_auditor: undefined,
  schema_name: "",
  isLoading: false,
  ...userDetails,
  notificationCount: 0,
  business_type: undefined,
  workspace: [],
  selectedWorkspace: "",
  redirectUrl: "/dashboard",
};

const userReducer = createReducer(initialState, {
  [ReduxActionTypes.STORE_USER_DETAILS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      isLoading: false,
      ...action.payload.userDetails,
      selectedWorkspace: action.payload.selectedWorkspace, 
    };
  },
  [ReduxActionTypes.STORE_NOTIFICATION_COUNTS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      notificationCount: action.payload.notificationCount,
    };
  },
  [ReduxActionTypes.CHANGE_SELECTED_WORKSPACE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      selectedWorkspace: action.selectedWorkspace,
    };
  },
  [ReduxActionTypes.LOGIN_END]: (
    state, 
    action,
  ) =>{
    return {
      ...state,
      isLoading: false,
    }
  },
  [ReduxActionTypes.LOGIN_USER]: (
    state, action
  ) => {
    return {
      ...state,
      isLoading: true,
      first_name: "",
      last_name: "",
      groups: [],
      id: "",
      email: "",
      channel: "",
      token: "",
      is_active: undefined,
      is_superuser: undefined,
      is_agent: undefined,
      is_levelai: undefined,
      is_qa_auditor: undefined,
      schema_name: "",
      notificationCount: 0,
      business_type: undefined,
      workspace: [],
      selectedWorkspace: "",
    }
  }, 
  [ReduxActionTypes.LOGOUT_USER]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      first_name: "",
      last_name: "",
      groups: [],
      id: "",
      email: "",
      channel: "",
      token: "",
      is_active: undefined,
      is_superuser: undefined,
      is_agent: undefined,
      is_qa_auditor: undefined,
      schema_name: "",
      isLoading: false,
      business_type: undefined,
      workspace: [],
      selectedWorkspace: "",
    }
  },
  [ReduxActionTypes.SET_CUBE_JWT]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      cubejs_jwt: action.payload.value,
      cubejs_jwt_exp: action.payload.expiry,
    }
  },
  [ReduxActionTypes.SET_HASURA_JWT]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      hasura_jwt: action.payload.value,
      hasura_jwt_exp: action.payload.expiry,
    }
  },
  [ReduxActionTypes.SET_SCREENRECORD_JWT]: (state, action) => {
    return {
      ...state,
      screenrecord_jwt: action.payload.value,
      screenrecord_jwt_exp: action.payload.expiry,
    };
  },
  [ReduxActionTypes.SET_AGENT_ANALYTICS]: (
    state, 
    action,
  ) => {
    return {
      ...state,
      default_agent_analytics_id: action.payload.value,
    }
  },
  [ReduxActionTypes.SET_REDIRECT]: (
    state,
    action
  ) => {
    return {
      ...state,
      redirectUrl: action.payload,
    }
  }
});

export default userReducer;
