import React, {useState, useEffect, useRef, memo } from 'react';
import { Select } from "antd";
import { connect } from "react-redux";
import CustomFilter from "../../../components/common/CustomFilters";
import { FilterInputWrapper } from "./style";
import { getSelectFilterOptions } from "../../query-config";
import { useSelector } from "../../../store";

const returnCustomFilter = (customFilters) => {
  //feature gated custom filters
  if (!customFilters?.length) return [];
    return customFilters.map((item) => {
      let key = item?.field_name?.trim()?.replaceAll(" ","_") || "";

    return {
      key:`CustomColumns.${key}`,
      label: item.pretty_name,
      renderComponent: (props) => {
        return (
          <>
            <CustomFilter
              key={item.field_name}
              value={props.value}
              handleChange={(value) => {
                props.handleChange(`CustomColumns.${key}`, value);
              }}
              options={item.options?.length ? item.options.map(option => {
                return {
                  label: option,
                  value: option?.toString()
                }
              }) : []}
              placeholder={item.pretty_name}
            />
          </>
        );
      },
    };
    });
};

const Input = (props) => {
    const customFilters = useSelector(state => state.dashboard.customFilters);

    const { onChange, filterValue } = props;
    const [selectedOption, setselectedOption] = useState();
    const [filterOptions, updateFilterOptions] = useState([]);
  
    useEffect(() => {
      setselectedOption(filterValue.key);
    }, [filterValue]);
  
    useEffect(() => {
      if (customFilters) {
        updateFilterOptions(state => [
          ...getSelectFilterOptions(),
          ...returnCustomFilter(customFilters),
        ]);
      }
    }, [customFilters]);
    
    return (
      <FilterInputWrapper>
        <Select
          allowClear
          showSearch = {true}
          filterOption={(value, option) => {
            return option?.props?.children?.toUpperCase().includes(value.toUpperCase());
          }}
          className="filter-type"
          value={selectedOption}
          placeholder="Select Parameter"
          onChange={(e) => {
            if (selectedOption) {
              onChange(selectedOption, undefined);
            }
            onChange(e, undefined);
            setselectedOption(e);
          }}
        >
          {filterOptions?.sort((a, b) => a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())).map((option, index) => (
            <Select.Option key={index} value={option.key}>{option.label}</Select.Option>
          ))}
        </Select>

        <div className="filter-value">
          {selectedOption &&
            filterOptions?.filter((x) => selectedOption == x.key)[0]
              ?.renderComponent({
                value: filterValue.value,
                handleChange: (value, label, id) => {
                  // TODO: Fix this mess
                  if (selectedOption === "conversation_tag_id") {
                    if (id) {
                      onChange(selectedOption, id);
                      return;
                    }
                  }
                  onChange(selectedOption, label || value);
                },
              })}
        </div>
      </FilterInputWrapper>
    );
};
  
export default memo(Input);
