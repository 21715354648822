import React from "react";
import ThumbsUp from "./img/thumbs-up";
import ThumbsDown from "./img/thumbs-down";
import { StyledBtn } from './StyledComponents';
import { ReactComponent as DownVoteSelectedIcon } from "./assets/downvote-selected.svg";
import { ReactComponent as UpVoteSelectedIcon } from "./assets/upvote-selected.svg";
import { ReactComponent as CloseIcon } from "./../assets/close.svg";
import {
  FeedbackButtons
} from './StyledComponents'


const Feedback = ({ isFlag, user_feedback, showFeedbackReason, showReasons, hideReasons, handleFeedback }) => {
  return (
    <React.Fragment>
      <FeedbackButtons isFlag={isFlag}>
        <StyledBtn onClick={(e) => {
            e.stopPropagation();
            handleFeedback("UP")
        }}>
          <UpvoteIcon selected={user_feedback?.feedback === "UP"} isFlag={isFlag} />          
        </StyledBtn>
        {showFeedbackReason && (!user_feedback?.id || user_feedback?.feedback === "UP")
        ? <StyledBtn onClick={(e) => {
            hideReasons();
            e.stopPropagation();
        }}>
            <CloseIcon />
          </StyledBtn>
        : <StyledBtn onClick={(e) => {
            showReasons();
            e.stopPropagation();
        }}>
            <DownVoteIcon selected={user_feedback?.feedback === "DN"} isFlag={isFlag} />
          </StyledBtn> 
        }     
      </FeedbackButtons>               
    </React.Fragment>
  )
}

const UpvoteIcon = ({ selected, isFlag }) => {
  if(!isFlag) {
    return <ThumbsUp fill={selected ? "#6099D9": "#aaa"} />
  }
  else {
    if(!selected) {
      return <ThumbsUp fill="#ffffff" />
    }
    else {
      return <UpVoteSelectedIcon />;
    }
  }
}

const DownVoteIcon = ({ selected, isFlag }) => {
  if(!isFlag) {
    return <ThumbsDown fill={selected ? "#DB554D": "#aaa"} />
  }
  else {
    if(!selected) {
      return <ThumbsDown fill="#ffffff" />
    }
    else {
      return <DownVoteSelectedIcon />;
    }
  }
}

export default Feedback;